import React, { useState } from "react";

import { RootState, useAppDispatch } from "../../../../../redux/store";

import ConfermaAnnulla from "../../../../common/ConfermaAnnulla";
import NuovoPodCommessaForm from "./NuovoPodCommessaForm";
import PodCommessaForm from "./PodCommessaForm";

import { Commessa, saveCommessa } from "../../commesseSlice";
import { Soggetto } from "../../../../anagrafiche/produttori/produttoriSlice";
import Alert from "../../../../common/Alert";

import SoggettoPage from "../../../../anagrafiche/soggetti/SoggettoPage";
import Button from "@mui/material/Button";
import { Box } from "@mui/system";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import AllegatiPage from "../../../../allegati/AllegatiPage";

interface PodTabProps {
	commessa: Commessa;
	produttore: Soggetto | undefined;
	consentiModifica: boolean;
}

const PodTab = ({ commessa, produttore, consentiModifica }: PodTabProps) => {
	const dispatch = useAppDispatch();

	// gestione chiusura modal su scelta "Annulla"
	const [openConferma, setOpenConferma] = useState(false);
	const handleConfermaClose = () => setOpenConferma(false);

	return (
		<Box
			sx={{
				backgroundColor: "#fff",
				elevation: 20,
				p: 2,
			}}
		>
			{!!commessa && !commessa?.pod?.id && (
				<Grid container spacing={2} sx={{ mb: 2 }}>
					<Grid item xs={12} sm={6} md={6} lg={12}>
						<NuovoPodCommessaForm
							commessa={commessa}
							consentiModifica={consentiModifica}
						/>
					</Grid>
				</Grid>
			)}
			{!!commessa?.pod?.id && (
				<PodCommessaForm
					pod={commessa?.pod}
					commessa={commessa}
					produttore={produttore}
					consentiModifica={consentiModifica}
				/>
			)}
			<ConfermaAnnulla
				handleAnnulla={handleConfermaClose}
				handleConferma={() => {
					!!commessa &&
						dispatch(
							saveCommessa({
								commessaToSave: commessa,
								azione: "sgancia_pod_da_commessa",
							})
						);
					handleConfermaClose();
				}}
				domanda={"Sicuro di voler sganciare il Pod da questa commessa"}
				open={openConferma}
			/>
			{!!commessa?.pod && ['A'].includes(commessa.stato||"") && (
				<Stack
					direction="row"
					spacing={2}
					sx={{ mt: 2 }}
					justifyContent="flex-end"
				>
					<Button onClick={() => setOpenConferma(true)}>Cambia Pod</Button>
				</Stack>
			)}

			{!!commessa?.pod && (

				<AllegatiPage
					commessaId={commessa.id || 0}
					consentiModifica={consentiModifica}
					faseCommessa={"POD"}
				/>
			)}
		</Box>
	);
};

export default PodTab;
