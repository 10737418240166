import {useSelector} from "react-redux";
import React, {useEffect, useState} from "react";

import {
    IterConnessione,
} from "components/main/iterConnessioni/iterConnessioneSlice";

import {Enea} from 'components/main/iterConnessioni/iterConnessione/enea/eneaSlice'


import {RootState, useAppDispatch} from "../../../../../redux/store";
import {UtenteLogged} from "../../../../authentication/authenticationSlice";

import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import {Alert, Typography} from "@mui/material";
import AlertTitle from "@mui/material/AlertTitle";

interface AzioniEneaCardProps {
    iterConnessione: IterConnessione;
    enea:Enea
}

const AzioniEneaCard = ({iterConnessione, enea}: AzioniEneaCardProps) => {

    // const dispatch = useAppDispatch();
    //
    // const utente = useSelector<RootState, UtenteLogged>(
    //     (state) => state.authentication.utenteLogged
    // );

    return (
        <Card sx={{mt: 2, backgroundColor: "#ddd"}}>
            <CardContent>
                <Stack sx={{width: "100%"}} spacing={2}>
                    {!enea?.enea_completo?.enea_completo && (
                        <Alert severity="error" sx={{ mt: 1 }}>
                            <AlertTitle>Attenzione manca:</AlertTitle>
                            <Typography
                                variant="body2"
                                sx={{ whiteSpace: "pre-line" }}
                            >
                                {enea?.enea_completo?.dati_mancanti}
                            </Typography>
                        </Alert>
                    )}
                    {["F"].includes(iterConnessione.stato) && (
                        <Button
                            variant="contained"
                            size="large"
                            // disabled={!progetto?.progetto_completo}
                            sx={{width: "100%", mt: 1}}
                            // onClick={() => {
                            // 	progetto?.id && dispatch(chiudiProgetto(progetto));
                            // }}
                        >
                            Segna come conclusa la fase "enea"
                        </Button>

                    )}
                </Stack>
            </CardContent>
        </Card>
    );
};

export default AzioniEneaCard;
