import { handleResponse, handleError, headerset } from "../apiUtils";
import { ServizioAdempimenti } from "../../components/main/adempimenti/adempimentiSlice";
import { DateTime } from "luxon";
import { convertToDateTime } from "components/common/dateUtils/convertToDateTime";

const baseUrl = (servizioAdempimenti_id?: number | null) => {
	if (servizioAdempimenti_id) {
		return (
			process.env.API_URL +
			"/api/adempimenti/servizi_adempimenti/" +
			servizioAdempimenti_id +
			"/"
		);
	} else {
		return process.env.API_URL + "/api/adempimenti/servizi_adempimenti/";
	}
};

export function fetchServiziAdempimenti(
	cliente?: number,
	produttore?: number,
	numeroRecord?: number,
	page?: number,
	search?: string
) {
	let url = baseUrl();
	// data_da.toFormat('yyyy-MM-dd');

	if (
		cliente != undefined ||
		produttore != undefined ||
		numeroRecord != undefined ||
		search != undefined ||
		page != undefined
	) {
		url += "?";
	}
	if (cliente != undefined) {
		url += `cliente=${cliente}&`;
	}
	if (produttore != undefined) {
		url += `produttore=${produttore}&`;
	}
	if (numeroRecord != undefined) {
		url += `numero_record=${numeroRecord}&`;
	}
	if (page != undefined) {
		url += `page=${page}&`;
	}
	if (search != undefined) {
		url += `search=${search}`;
	}

	return fetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}

export function getServizioAdempimenti(servizioAdempimentiId: number) {
	let url = baseUrl(servizioAdempimentiId);
	return fetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}

export function saveServizioAdempimenti(
	servizioAdempimentiToSave: ServizioAdempimenti,
	azione?: string
) {
	let url = baseUrl(servizioAdempimentiToSave.id);
	if (!!azione) {
		url = url + `?azione=${azione}`;
	}
	const data_validita_a = servizioAdempimentiToSave.data_validita_a
		? convertToDateTime(servizioAdempimentiToSave.data_validita_a)?.setLocale("it").toFormat("yyyy-MM-dd")
		: null;
	const data_validita_da = servizioAdempimentiToSave.data_validita_da
		? convertToDateTime(servizioAdempimentiToSave.data_validita_da)?.setLocale("it").toFormat("yyyy-MM-dd")
		: null;
	const servizioAdempimenti = {
		...servizioAdempimentiToSave,
		data_validita_da: data_validita_da,
		data_validita_a: data_validita_a,
	};
	return fetch(url, {
		method: servizioAdempimentiToSave.id ? "PUT" : "POST", // PUT to update when id already exists.
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
		body: JSON.stringify({
			...servizioAdempimenti,
			// Parse authorId to a number (in case it was sent as a string).
			// id: parseInt(progetto.id, 10),
		}),
	})
		.then(handleResponse)
		.catch(handleError);
}

export function deleteServizioAdempimenti(
	servizioAdempimentiToDelete: ServizioAdempimenti
) {
	return fetch(baseUrl(servizioAdempimentiToDelete.id), {
		method: "DELETE",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}
