import React from "react";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../../redux/store";

import { UtenteLogged } from "../../authentication/authenticationSlice";
import {
	ErrorStatusTypes,
	ErrorsStack,
	parseErrorMessage,
} from "../../common/errorsDeclarations";
import SignIn from "../../authentication/SignIn";

import CircularProgress from "@mui/material/CircularProgress";

import home_img from "../../../../static/images/common/mondo.png";
import hero_image from "../../../../static/images/common/heroImage.png";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Parallax from "../../common/Parallax/Parallax";
import Fade from "@mui/material/Fade";
import Slide from "@mui/material/Slide";
import { SelectPeriodo } from "./DashboardCommittentiToolbarOptions";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Link from "@mui/material/Link";
import ProgettiEmessiKpiCard from "./ProgettiEmessiKpiCard";
import ProgettiRilavoratiKpiCard from "./ProgettiRilavoratiKpiCard";
import RichiestePreventiviKpiCard from "./RichiestePreventiviKpiCard";
import RegolamentoEsercizioKpiCard from "./RegolamentoEsercizioKpiCard";
import RichiesteRilavorateKpiCard from "./RichiesteRilavorateKpiCard";
import RegolamentoRilavorateKpiCard from "./RegolamentoRilavorateKpiCard";
import ClienteProgressoPieChart from "../home/ClienteProgressoPieChart";
import ClienteServiziPieChart from "../home/ClienteServiziPieChart";
import HomeMenu from "../home/HomeMenu";

import { theme } from "components/theme";

const periodi: { valore: string; periodo: string }[] = [
	{ valore: "0.1", periodo: "settimana corrente" },
	{ valore: "0.5", periodo: "ultime 2 settimane" },
	{ valore: "1", periodo: "mese corrente" },
	{ valore: "3", periodo: "ultimi 3 mesi" },
];

const DashboardPage = () => {
	const [periodo, setPeriodo] = React.useState<string>("1");

	return (
		<Box sx={{ p: 2 }}>
			<Grid container spacing={4}>
				<Grid item xs={12} sm={12} md={12} lg={8}>
					<Paper elevation={3} sx={{ p: 2, backgroundColor: "WindowFrame" }}>
						<Box sx={{ mb: 2, backgroundColor: "white" }}>
							<SelectPeriodo
								periodo={periodo}
								periodi={periodi}
								setPeriodo={setPeriodo}
							/>
						</Box>
						<Grid container spacing={2}>
							<Grid item xs={12} sm={6} md={4} lg={6}>
								<ProgettiEmessiKpiCard periodo={periodo} />
							</Grid>
							<Grid item xs={12} sm={6} md={4} lg={6}>
								<ProgettiRilavoratiKpiCard periodo={periodo} />
							</Grid>
							<Grid item xs={12} sm={6} md={4} lg={6}>
								<RichiestePreventiviKpiCard periodo={periodo} />
							</Grid>
							<Grid item xs={12} sm={6} md={4} lg={6}>
								<RichiesteRilavorateKpiCard periodo={periodo} />
							</Grid>
							<Grid item xs={12} sm={6} md={4} lg={6}>
								<RegolamentoEsercizioKpiCard periodo={periodo} />
							</Grid>
							<Grid item xs={12} sm={6} md={4} lg={6}>
								<RegolamentoRilavorateKpiCard periodo={periodo} />
							</Grid>
						</Grid>
					</Paper>
				</Grid>

				<Grid item xs={12} sm={6} md={4} lg={4}>
					{/* <Box sx={{ mb: 2 }}>
						<ClienteProgressoPieChart />
					</Box>
					<Box sx={{ mb: 2 }}>
						<ClienteServiziPieChart />
					</Box> */}
				</Grid>
				<Grid item xs={12} sm={6} md={4} lg={12}>
					<Paper elevation={3} sx={{ p: 2, backgroundColor: "#daeefe" }}>
						<HomeMenu />
					</Paper>
				</Grid>
			</Grid>
		</Box>
	);
};

export default DashboardPage;
