import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { NumericFormat } from "react-number-format";

import {
	IterConnessione,
	TipoTensioneTypes,
	saveIterConnessione,
} from "../../iterConnessioneSlice";

import {
	Progetto,
	saveProgetto,
	TipoSchemaTypes,
	SistemaAccumuloTypes,
	AlimentazioneSdaTypes,
} from "./progettiSlice";
import { useAppDispatch, RootState } from "../../../../../redux/store";
import {
	ErrorStatusTypes,
	ErrorsStack,
} from "../../../../common/errorsDeclarations";

import { theme } from "../../../../theme";

import { utenteAutorizzato } from "../../../../authentication/UtenteAutorizzato";
import { UtenteLogged } from "../../../../authentication/authenticationSlice";
import { DateTime } from "luxon";
import { convertToDateTime } from "components/common/dateUtils/convertToDateTime";

import { Cliente } from "../../../../anagrafiche/clienti/clientiSlice";
import { Installatore } from "../../../../anagrafiche/installatori/installatoriSlice";
import { RegimeCommerciale } from "../../../../anagrafiche/regimiCommerciali/regimiCommercialiSlice";
import { TipoPratica } from "../../../../anagrafiche/tipiPratica/tipiPraticaSlice";
import { TipoTensione } from "../../../../anagrafiche/tipiTensione/tipiTensioneSlice";
import { Comune } from "../../../../anagrafiche/comuni/comuniSlice";

import { Gestore } from "../../../../anagrafiche/gestori/gestoriSlice";
import { useForm, SubmitHandler, Controller } from "react-hook-form";

import ReactHookFormSelect from "../../../../common/ReactHookFormSelect";
import MenuItem from "@mui/material/MenuItem";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { Routes_path } from "../../../../routerApp";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Alert from "../../../../common/Alert";
import Typography from "@mui/material/Typography";
import Autocomplete from "@mui/material/Autocomplete";
import ClockIcon from "@mui/icons-material/AccessTime";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import InputAdornment from "@mui/material/InputAdornment";

import { Button, Divider } from "@mui/material";

interface IterConnessioneProgettiFormProps {
	progetto: Progetto;
	consentiModifica: boolean;
}

const IterConnessioneProgettiForm = ({
	progetto,
	consentiModifica = false,
}: IterConnessioneProgettiFormProps) => {
	const {
		handleSubmit,
		register,
		formState: { errors, isDirty, isValid },
		setValue,
		watch,
		getValues,
		control,
		setError,
		clearErrors,
		reset,
	} = useForm<Progetto>();

	const listaCampi = Object.keys(progetto) as Array<keyof Progetto>;

	const dispatch = useAppDispatch();

	// const [editing, setEditing] = useState(!iterConnessione.cliente);
	const [editing, setEditingControlled] = useState(false);

	const setEditing = (switchModifica: boolean) => {
		consentiModifica && setEditingControlled(switchModifica);
	};
	const utente = useSelector<RootState, UtenteLogged>(
		(state) => state.authentication.utenteLogged
	);
	// const clienti = useSelector<RootState, Cliente[]>(
	// 	(state) => state.clientiState.clienti.results
	// );

	// const installatori = useSelector<RootState, Installatore[]>(
	// 	(state) => state.installatoriState.installatori.results
	// );

	// const regimiCommerciali = useSelector<RootState, RegimeCommerciale[]>(
	// 	(state) => state.regimiCommercialiState.regimiCommerciali.results
	// );
	// const tipiPratica = useSelector<RootState, TipoPratica[]>(
	// 	(state) => state.tipiPraticaState.tipiPratica.results
	// );
	// const tipiTensione = useSelector<RootState, TipoTensione[]>(
	// 	(state) => state.tipiTensioneState.tipiTensione.results
	// );

	// const gestori = useSelector<RootState, Gestore[]>(
	// 	(state) => state.gestoriState.gestori.results
	// );

	// const comuni = useSelector<RootState, Comune[]>(
	// 	(state) => state.comuniState.comuni.results
	// );

	// const [comuneCorrente, setComuneCorrente] = useState<Comune | undefined>(
	// 	undefined
	// );

	// useEffect(() => {
	// 	setComuneCorrente(
	// 		comuni.find((comuneScelto) => comuneScelto.id == getValues("comune"))
	// 	);
	// }, [comuni, getValues("comune"), iterConnessione]);

	useEffect(() => {
		reset();
		listaCampi.map((field) => {
			setValue(field, progetto[field]);
		});
	}, []);

	useEffect(() => {
		clearErrors();
		listaCampi.forEach((field) => {
			progetto?.errorsStack?.fieldsErrors &&
				progetto.errorsStack.fieldsErrors[field] &&
				setError(field, {
					type: "server",
					message: progetto.errorsStack.fieldsErrors[field].toString(),
				});
		});
	}, [
		progetto?.errorsStack?.fieldsErrors,
		// iterConnessione.allevamento_pack?.errorsStack?.fieldsErrors,
	]);

	useEffect(() => {
		progetto?.errorsStack?.status == ErrorStatusTypes.SUCCESS &&
			setEditing(false);
	}, [
		progetto,
		// iterConnessione.allevamento_pack?.errorsStack?.fieldsErrors,
	]);

	const handlerSaveProgetto = (progetto: Progetto) => {
		consentiModifica && dispatch(saveProgetto({ progettoToSave: progetto }));
	};

	const onSubmit: SubmitHandler<Progetto> = (progetto) => {
		handlerSaveProgetto(progetto);
	};

	return (
		<Box sx={{ my: 1 }}>
			<form onSubmit={handleSubmit(onSubmit)}>
				{progetto?.errorsStack?.fieldsErrors?.non_field_errors && (
					<Alert severity="error">
						{progetto?.errorsStack?.fieldsErrors?.non_field_errors.toString()}
					</Alert>
				)}
				<Grid container spacing={2} sx={{ mb: 2 }}>
					<Grid item xs={12} sm={6} md={6} lg={4}>
						<Typography
							variant="caption"
							component="div"
							color="text.secondary"
							gutterBottom
							onClick={() => setEditing(true)}
						>
							Potenza Immessa*:
						</Typography>
						{!editing ? (
							<Typography
								variant="body1"
								component="div"
								// color="text.secondary"
								gutterBottom
								onClick={() => setEditing(true)}
								sx={{
									color: progetto.potenza_immessa
										? theme.palette.text.secondary
										: theme.palette.divider,
								}}
							>
								{progetto.potenza_immessa
									? Intl.NumberFormat("it-IT", {
											maximumFractionDigits: 2,
									  })
											.format(progetto.potenza_immessa || 0)
											.toString() + " kW"
									: "inserisci potenza immagazzinata"}
							</Typography>
						) : (
							<Controller
								name="potenza_immessa"
								control={control}
								render={({ field }) => (
									<NumericFormat
										id="potenza_immessa"
										customInput={TextField}
										variant="outlined"
										// label="potenza unitaria"
										InputLabelProps={{
											shrink: true,
										}}
										error={!!errors?.potenza_immessa}
										helperText={errors?.potenza_immessa?.message}
										// {...register("potenza_unitaria")}
										value={field.value}
										autoComplete="off"
										prefix="kW "
										thousandSeparator="."
										decimalSeparator=","
										valueIsNumericString={true}
										onValueChange={(v) => {
											setValue("potenza_immessa", Number(v.value), {
												shouldDirty: true,
											});
										}}
										sx={{ width: "100%" }}
									/>
								)}
							/>
						)}
					</Grid>

					<Grid item xs={12} sm={6} md={6} lg={4}>
						<Typography
							variant="caption"
							component="div"
							color="text.secondary"
							gutterBottom
							onClick={() => setEditing(true)}
						>
							Potenza Nominale*:
						</Typography>
						{!editing ? (
							<Typography
								variant="body1"
								component="div"
								// color="text.secondary"
								gutterBottom
								onClick={() => setEditing(true)}
								sx={{
									color: progetto.potenza_nominale
										? theme.palette.text.secondary
										: theme.palette.divider,
								}}
							>
								{progetto.potenza_nominale
									? Intl.NumberFormat("it-IT", {
											maximumFractionDigits: 2,
									  })
											.format(progetto.potenza_nominale || 0)
											.toString() + " kW"
									: "inserisci potenza nominale"}
							</Typography>
						) : (
							<Controller
								name="potenza_nominale"
								control={control}
								render={({ field }) => (
									<NumericFormat
										id="potenza_nominale"
										customInput={TextField}
										variant="outlined"
										// label="potenza unitaria"
										InputLabelProps={{
											shrink: true,
										}}
										error={!!errors?.potenza_nominale}
										helperText={errors?.potenza_nominale?.message}
										// {...register("potenza_unitaria")}
										value={field.value}
										autoComplete="off"
										prefix="kW "
										thousandSeparator="."
										decimalSeparator=","
										valueIsNumericString={true}
										onValueChange={(v) => {
											setValue("potenza_nominale", Number(v.value), {
												shouldDirty: true,
											});
										}}
										sx={{ width: "100%" }}
									/>
								)}
							/>
						)}
					</Grid>

					<Grid item xs={12} sm={6} md={6} lg={4}>
						<Typography
							variant="caption"
							component="div"
							color="text.secondary"
							gutterBottom
							onClick={() => setEditing(true)}
						>
							Data Progetto:
						</Typography>

						{/* {!editing ? ( */}
						<Typography
							variant="body1"
							component="div"
							// color="text.secondary"
							gutterBottom
							onClick={() => setEditing(true)}
							sx={{
								color: progetto.data_progetto
									? theme.palette.text.secondary
									: theme.palette.divider,
							}}
						>
							{progetto.data_progetto
								? convertToDateTime(progetto.data_progetto)?.setLocale("it").toFormat("EEEE dd LLLL yyyy")
								: "data automatica alla conclusione"}
						</Typography>
						{/* ) : (
							<DesktopDatePicker
								// components={{
								// 	OpenPickerIcon: ClockIcon,
								// }}
								// inputFormat="DD/MM/YY HH:mm"
								// mask={"__/__/__ __:__"}
								// label="Data Progetto"
								// {...register("data_ora_carico_pianificata")}
								value={convertToDateTime(progetto.data_progetto)}
								renderInput={(params: any) => (
									<TextField
										{...params}
										error={!!errors?.data_progetto}
										helperText={errors?.data_progetto?.message}
										sx={{ width: "100%" }}
										autoComplete={"off"}
										// {...register("data_ora_fine_turno")}
									/>
								)}
								onChange={(newValue: any) => {
									setEditing(true);
									setValue(
										"data_progetto",
										newValue ? convertToDateTime(newValue)?.toFormat("yyyy-MM-dd") : "",
										{
											shouldDirty: true,
										}
									);
									clearErrors("data_progetto");
								}}
							/>
						)} */}
					</Grid>
				</Grid>
				<Button
					// disabled={!isDirty}
					variant="outlined"
					onClick={() => {
						setEditing(false);
						// reset();
					}}
					sx={{ ...(!editing && { display: "none" }) }}
				>
					Annulla
				</Button>
				<Button
					disabled={!isDirty}
					variant="outlined"
					type="submit"
					sx={{ ...(!editing && { display: "none" }), ml: 1 }}
				>
					Salva
				</Button>
			</form>
		</Box>
	);
};

export default IterConnessioneProgettiForm;
