import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { Link } from "react-router-dom";

import { RootState, useAppDispatch } from "../../../redux/store";
import { ErrorStatusTypes } from "../../common/errorsDeclarations";

import { Routes_path } from "../../routerApp";
import { Cliente } from "../../anagrafiche/clienti/clientiSlice";
import {
	PreferenzeLista,
	setPreferenzeListaUtenti,
} from "../../userPreference/userPreferenceSlice";
import { Utenti, fetchUtenti } from "./utentiSlice";

import { theme } from "../../theme";
import FilterAltIcon from "@mui/icons-material/FilterAlt";

import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Pagination from "@mui/material/Pagination";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { ComunitaEnergetica } from "components/main/comunitaEnergetiche/comunitaEnergeticheSlice";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

interface UtentiListProps {
	// utenti: Utenti;
	clienti: Cliente[];
	comunita_energetiche: ComunitaEnergetica[];
}

const UtentiList = ({
	// utenti,
	clienti,
	comunita_energetiche,
}: UtentiListProps) => {
	const dispatch = useAppDispatch();

	const [anchorElClienteFilter, setAnchorElClienteFilter] = useState(null);
	const [anchorElCERFilter, setAnchorElCERFilter] = useState(null);

	const handleStatoFilterClose = () => {
		setAnchorElClienteFilter(null);
		setAnchorElCERFilter(null);
		// onClose(selectedValue);
	};
	const preferenzeListaUtenti = useSelector<
		RootState,
		PreferenzeLista | undefined
	>((state) => state.userPreferenceState.preferenzeListaUtenti);

	const [clienteId, setClienteId] = useState<number | undefined>(undefined);
	const [cerId, setCERId] = useState<number | undefined>(undefined);
	const [page, setPage] = useState<number>(preferenzeListaUtenti?.page || 1);
	const [numeroRecord, setNumeroRecord] = useState<number>(
		preferenzeListaUtenti?.numeroRecord || 10
	);
	const [search, setSearch] = useState<string | undefined>(undefined);
	const [refresh, setRefresh] = React.useState<boolean>(false);

	const handleChangeNumeroRecord = (event: SelectChangeEvent) => {
		setNumeroRecord(Number(event.target.value));
	};
	const handlerPager = (event: React.ChangeEvent<unknown>, page: number) => {
		setPage(page);
	};
	useEffect(() => {
		dispatch(
			fetchUtenti({
				cliente: clienteId,
				cer: cerId,
				numeroRecord: numeroRecord,
				page: page,
				search: search,
			})
		);
		dispatch(
			setPreferenzeListaUtenti({
				numeroRecord: numeroRecord,
				page: page,
			})
		);
		setRefresh(false);
	}, [numeroRecord, page, refresh, clienteId, cerId]);

	const utenti: Utenti = useSelector<RootState, Utenti>(
		(state) => state.utentiState.utenti
	);

	return (
		<Box>
			<Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={utenti.errorsStack.status == ErrorStatusTypes.PENDING}
			>
				<CircularProgress color="inherit" />
			</Backdrop>
			<List>
				<ListItem sx={{ backgroundColor: theme.palette.primary.main }}>
					<Grid container>
						<Grid item xs={4} md={2} lg={2}>
							<Typography variant="h6" color="secondary" gutterBottom>
								Utente
							</Typography>
						</Grid>
						<Grid item xs={4} md={2} lg={2}>
							<Typography variant="h6" color="secondary" gutterBottom>
								Nome
							</Typography>
						</Grid>
						<Grid item xs={4} md={2} lg={3}>
							<Typography variant="h6" color="secondary" gutterBottom>
								Email
							</Typography>
						</Grid>

						<Grid item xs={4} md={2} lg={3}>
							<Stack direction="row" spacing={1}>
								<Typography variant="h6" color="secondary" gutterBottom>
									Cliente
								</Typography>
								<IconButton
									color={clienteId ? "error" : "inherit"}
									onClick={(event: any) =>
										setAnchorElClienteFilter(event.currentTarget)
									}
								>
									<FilterAltIcon sx={{ mt: -0.5 }} />
								</IconButton>
							</Stack>

							<Box style={{ display: "flex", justifyContent: "space-between" }}>
								<Menu
									id="simple-menu"
									anchorEl={anchorElClienteFilter}
									keepMounted
									open={Boolean(anchorElClienteFilter)}
									onClose={handleStatoFilterClose}
								>
									{clienti.map((cliente) => (
										<MenuItem
											key={cliente.id}
											onClick={() => {
												setClienteId(cliente.id);
												setAnchorElClienteFilter(null);
											}}
											sx={{
												backgroundColor:
													cliente.id == clienteId ? "#aaa" : "inherit",
											}}
										>
											{cliente.ragione_sociale}
										</MenuItem>
									))}
									<MenuItem
										onClick={() => {
											setClienteId(undefined);
											setAnchorElClienteFilter(null);
										}}
										sx={{
											backgroundColor: !clienteId ? "#aaa" : "inherit",
										}}
									>
										tutti
									</MenuItem>
								</Menu>
							</Box>
						</Grid>
						<Grid item xs={4} md={2} lg={2}>
							<Stack direction="row" spacing={1}>
								<Typography variant="h6" color="secondary" gutterBottom>
									CER
								</Typography>
								<IconButton
									color={cerId ? "error" : "inherit"}
									onClick={(event: any) =>
										setAnchorElCERFilter(event.currentTarget)
									}
								>
									<FilterAltIcon sx={{ mt: -0.5 }} />
								</IconButton>
							</Stack>

							<Box style={{ display: "flex", justifyContent: "space-between" }}>
								<Menu
									id="simple-menu"
									anchorEl={anchorElCERFilter}
									keepMounted
									open={Boolean(anchorElCERFilter)}
									onClose={handleStatoFilterClose}
								>
									{comunita_energetiche.map((cer) => (
										<MenuItem
											key={cer.id}
											onClick={() => {
												setCERId(cer.id);
												setAnchorElCERFilter(null);
											}}
											sx={{
												backgroundColor: cer.id == cerId ? "#aaa" : "inherit",
											}}
										>
											{cer.nome}
										</MenuItem>
									))}
									<MenuItem
										onClick={() => {
											setCERId(undefined);
											setAnchorElCERFilter(null);
										}}
										sx={{
											backgroundColor: !cerId ? "#aaa" : "inherit",
										}}
									>
										tutti
									</MenuItem>
								</Menu>
							</Box>
						</Grid>
					</Grid>
				</ListItem>
				{/* {utentiFiltrati(utenti, clienteId, cerId).map((utente, index) => ( */}
				{utenti.results.map((utente, index) => (
					<ListItem
						key={`utenteEsterno_${utente.id}`}
						component={Link}
						to={{
							pathname: Routes_path.UTENTE + utente.id,
						}}
						state={{
							utente_attuale_id: utente.id,
							clientePreset: utente.cliente,
						}}
						sx={{
							backgroundColor: index % 2 == 0 ? "#aaaaaa55" : "inherit",
							color: "#112211dd",
						}}
					>
						<Grid container>
							<Grid item xs={4} md={2} lg={2}>
								{`${utente.user?.username} (n.${utente.id})`}
							</Grid>
							<Grid item xs={4} md={2} lg={2}>
								{`${utente.user?.first_name} ${utente.user?.last_name}`}
							</Grid>
							<Grid item xs={6} md={4} lg={3}>
								{utente.user?.email}
							</Grid>
							<Grid item xs={4} md={2} lg={3}>
								{
									clienti.find((cliente) => cliente.id == utente.cliente)
										?.ragione_sociale
								}
							</Grid>
							<Grid item xs={4} md={2} lg={2}>
								{comunita_energetiche.find((cer) => cer.id == utente.cer)?.nome}
							</Grid>
						</Grid>
					</ListItem>
				))}
				<ListItem
					sx={{
						alignContent: "right",
						width: "100%",
						mt: 2,
						borderTopStyle: "solid",
						borderTopWidth: 1,
					}}
				>
					<Stack
						direction="row"
						justifyContent="space-between"
						alignItems="center"
						spacing={2}
						sx={{ width: "100%" }}
					>
						<Pagination
							count={utenti.num_pages}
							onChange={handlerPager}
							page={page}
						/>
						{cerId || clienteId ? (
							<Typography noWrap>
								Totale utenti filtrati:{"  "}
								{utenti.count}
							</Typography>
						) : (
							<Typography noWrap>
								Totale utenti:{"  "}
								{utenti.count}
							</Typography>
						)}
						<Box>
							<TextField
								id="input-with-icon-textfield"
								// label="TextField"
								// InputProps={{
								// 	startAdornment: (
								// 		<InputAdornment position="end">
								// 			<SearchIcon />
								// 		</InputAdornment>
								// 	),
								// }}
								variant="standard"
								value={search}
								onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
									setSearch(event.target.value);
								}}
							/>
							<IconButton onClick={() => setRefresh(true)}>
								<SearchIcon />
							</IconButton>
						</Box>

						<FormControl>
							<InputLabel id="demo-simple-select-label">Record</InputLabel>
							<Select
								labelId="demo-simple-select-label"
								id="demo-simple-select"
								value={numeroRecord.toString()}
								label="Age"
								onChange={handleChangeNumeroRecord}
								variant="outlined"
							>
								<MenuItem value={10}>10</MenuItem>
								<MenuItem value={20}>20</MenuItem>
								<MenuItem value={50}>50</MenuItem>
							</Select>
						</FormControl>
					</Stack>
				</ListItem>
			</List>
		</Box>
	);
};

export default UtentiList;
