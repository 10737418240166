import React, { useEffect, useState } from "react";

import { useForm, SubmitHandler } from "react-hook-form";

import { TipoPratica } from "./tipiPraticaSlice";

import { ErrorsStack, ErrorStatusTypes } from "../../common/errorsDeclarations";

import { utenteAutorizzato } from "../../authentication/UtenteAutorizzato";
import { UtenteLogged } from "../../authentication/authenticationSlice";

import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";

import { Box } from "@mui/system";
import { Button } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormHelperText from "@mui/material/FormHelperText";
import FormControlLabel from "@mui/material/FormControlLabel";

import Stack from "@mui/material/Stack";
import { theme } from "../../theme";

interface TipiPraticaFormProps {
	tipoPraticaNuovo: TipoPratica;
	saveTipoPratica: (tipoPratica: TipoPratica) => void;
	annulla: () => void;
}

const TipiPraticaForm = ({
	tipoPraticaNuovo,
	saveTipoPratica,
	annulla,
}: TipiPraticaFormProps) => {
	const {
		handleSubmit,
		register,
		formState: { errors, isDirty },
		setValue,
		watch,
		getValues,
		control,
		setError,
		clearErrors,
		reset,
	} = useForm<TipoPratica>();

	const listaCampi = Object.keys(tipoPraticaNuovo) as Array<keyof TipoPratica>;

	useEffect(() => {
		listaCampi.map((field) => {
			setValue(field, tipoPraticaNuovo[field]);
		});
	}, []);

	const onSubmit: SubmitHandler<TipoPratica> = (tipoPratica) => {
		saveTipoPratica(tipoPratica);
	};

	useEffect(() => {
		listaCampi.forEach((field) => {
			tipoPraticaNuovo.errorsStack?.fieldsErrors &&
				tipoPraticaNuovo.errorsStack.fieldsErrors[field] &&
				setError(field, {
					type: "server",
					message: tipoPraticaNuovo.errorsStack.fieldsErrors[field].toString(),
				});
		});
	}, [tipoPraticaNuovo?.errorsStack?.fieldsErrors]);

	return (
		<Box>
			<form onSubmit={handleSubmit(onSubmit)}>
				<Grid
					container
					// rowSpacing={1}
					columnSpacing={1}
					// justifyContent="space-between"
					// alignItems="flex-start"
				>
					<Grid
						item
						xs={12}
						md={4}
						sx={
							{
								// pt: "16px !important",
								// margin: 1,
							}
						}
					>
						<TextField
							id="nome"
							label="Nome"
							// disabled={getValues("confermato")}
							{...register("nome")}
							InputLabelProps={{
								shrink: true,
							}}
							variant="outlined"
							required={true}
							fullWidth
							type="string"
							autoComplete={"off"}
							error={!!errors?.nome}
							helperText={errors?.nome?.message}
						/>
					</Grid>
					<Grid
						item
						xs={12}
						md={4}
						sx={
							{
								// pt: "16px !important",
								// margin: 1,
							}
						}
					>
						<TextField
							id="descrizione"
							label="Descrizione"
							// disabled={getValues("confermato")}
							{...register("descrizione")}
							InputLabelProps={{
								shrink: true,
							}}
							variant="outlined"
							fullWidth
							type="string"
							autoComplete={"off"}
							error={!!errors?.descrizione}
							helperText={errors?.descrizione?.message}
						/>
					</Grid>
					<Grid
						item
						xs={12}
						md={4}
						sx={
							{
								// pt: "16px !important",
								// margin: 1,
							}
						}
					>
						<FormControlLabel
							value={
								watch("compatibile_iter_semplificato") ||
								tipoPraticaNuovo?.compatibile_iter_semplificato
							}
							checked={watch("compatibile_iter_semplificato") || false}
							control={<Checkbox />}
							label="Iter Semplificato"
							labelPlacement="top"
							onChange={(e, checked) => {
								setValue("compatibile_iter_semplificato", checked, {
									shouldDirty: true,
								});
							}}
							style={{ padding: 0 }}
						/>
						{!!errors?.compatibile_iter_semplificato && (
							<FormHelperText error>
								{errors?.compatibile_iter_semplificato.toString()}
							</FormHelperText>
						)}
					</Grid>
					<Grid item xs={12} md={4}>
						<Stack direction="row" justifyContent="flex-start" spacing={1}>
							<Button disabled={!isDirty} variant="contained" type="submit">
								Salva
							</Button>
							<Button variant="outlined" onClick={annulla}>
								Annulla
							</Button>
						</Stack>
					</Grid>
				</Grid>
			</form>
		</Box>
	);
};

export default TipiPraticaForm;
