import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../../../../redux/store";
import { Link, useParams } from "react-router-dom";
import { Routes_path } from "../../../../routerApp";
import {
	IterConnessione,
	getIterConnessione,
} from "../../iterConnessioneSlice";
import {
	Soggetto,
	getProduttore,
} from "../../../../anagrafiche/produttori/produttoriSlice";
import { Comune } from "../../../../anagrafiche/comuni/comuniSlice";

import { PDFViewer, Page, Document, StyleSheet } from "@react-pdf/renderer";

import { DateTime } from "luxon";


import Stack from "@mui/material/Stack";
import NuovaIstanzaPdf from "./NuovaIstanzaPdf";

import { Box } from "@mui/system";
import Button from "@mui/material/Button";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import SaveAltIcon from "@mui/icons-material/SaveAlt";

import stampaConNome from "../../../../common/pdf/stampaConNome";

const nomeScheda = (iterConnessione: IterConnessione) => {
	const nomescheda: string = `OfficinaElettrica_nuova_istanza_${iterConnessione.codice_pratica}`;
	return nomescheda;
};
interface documentoNuovaIstanzaProps {
	iterConnessione: IterConnessione;
	produttore: Soggetto;
	comune: Comune;
}
const NuovaIstanza = ({
	iterConnessione,
	produttore,
	comune,
}: documentoNuovaIstanzaProps) => {
	return (
		<Document>
			<Page
				size="A4"
				style={styles.page}
				key={"iterConnessione__" + iterConnessione?.id}
			>
				<NuovaIstanzaPdf
					iterConnessione={iterConnessione}
					produttore={produttore}
					comune={comune}
				/>
			</Page>
			{/* <Page
				size="A4"
				style={styles.page}
				// orientation="landscape"
				key={"etich_scheda_carico_" + schedaCarico.id}
			>
				<EtichetteGabbiePdf lotto={lotto} schedaCarico={schedaCarico} />
			</Page> */}
		</Document>
	);
};
// Create styles
const styles = StyleSheet.create({
	page: {
		backgroundColor: "#fff",
		paddingTop: 20,
	},
	section: {
		marginTop: 10,
		marginLeft: 10,
		marginRight: 10,
		padding: 10,
	},
	sectionIntestazione: {
		marginTop: 5,
		marginLeft: 10,
		marginRight: 10,
		padding: 10,
		paddingTop: 10,
		paddingBottom: 7,
		backgroundColor: "#777777",
		color: "#ffffff",
	},
	text: {
		fontSize: 12,
		fontStyle: "normal",
	},
});

const OfficinaElettricaNuovaIstanzaPage = () => {
	
	const dispatch = useAppDispatch();
	const { iterConnessione_id } = useParams();
	// const parametri: Parametri = useSelector<RootState, Parametri>(
	// 	(state) => state.anagrafiche.parametri
	// );

	const iterConnessioneId = Number(iterConnessione_id);

	const iterConnessione: IterConnessione | undefined = useSelector<
		RootState,
		IterConnessione | undefined
	>((state) =>
		state.iterConnessioniState.iterConnessioni.results.find(
			(iterConnessione) => iterConnessione.id == iterConnessioneId
		)
	);
	const produttore: Soggetto | undefined = useSelector<
		RootState,
		Soggetto | undefined
	>((state) =>
		state.produttoriState.produttori.results.find(
			(produttore) => produttore.id == iterConnessione?.produttore
		)
	);
	const comune: Comune | undefined = useSelector<RootState, Comune | undefined>(
		(state) =>
			state.comuniState.comuni.results.find(
				(comune) => comune?.id == produttore?.comune
			)
	);

	useEffect(() => {
		iterConnessioneId && dispatch(getIterConnessione({ iterConnessioneId }));
	}, []);
	useEffect(() => {
		iterConnessione?.produttore &&
			dispatch(getProduttore(iterConnessione.produttore));
	}, [iterConnessione]);

	return (
		<Box>
			<Box sx={{ backgroundColor: "#999", height: "80vh" }}>
				{iterConnessione && produttore && comune && (
					<PDFViewer width={"100%"} height={"100%"}>
						<NuovaIstanza
							iterConnessione={iterConnessione}
							produttore={produttore}
							comune={comune}
						/>
					</PDFViewer>
				)}
			</Box>
			<Stack
				direction="row"
				justifyContent="flex-start"
				alignItems="flex-start"
				mt={2}
				spacing={1}
			>
				<Button
					variant="contained"
					component={Link}
					to={{
						pathname: Routes_path.ITER_CONNESSIONE,
					}}
					endIcon={<KeyboardReturnIcon />}
					// color="success"
				>
					Torna alle schede
				</Button>
				<Button
					variant="contained"
					endIcon={<SaveAltIcon />}
					onClick={() => {
						iterConnessione &&
							produttore &&
							comune &&
							stampaConNome({
								Documento: (
									<NuovaIstanza
										iterConnessione={iterConnessione}
										produttore={produttore}
										comune={comune}
									/>
								),
								nome: nomeScheda(iterConnessione),
							});
					}}
					// color="success"
				>
					Salva
				</Button>
			</Stack>
		</Box>
	);
};

export default OfficinaElettricaNuovaIstanzaPage;
