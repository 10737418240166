import React, {useEffect, useState, KeyboardEvent,} from "react";
import {useSelector} from "react-redux";
import {useLocation, Link} from "react-router-dom";

import {RootState, useAppDispatch} from "../../../redux/store";
import {Routes_path} from "../../routerApp";

import {convertToDateTime} from "components/common/dateUtils/convertToDateTime";

import {
    ErrorStatusTypes,
} from "../../common/errorsDeclarations";
import {
    PreferenzeLista,
    setPreferenzeListaAutorizzazioni,
} from "../../userPreference/userPreferenceSlice";
import {
    IterConnessioniState,
} from "../iterConnessioni/iterConnessioneSlice";

import {
    fetchAutorizzazioneComunale,
    AutorizzazioniComunaliState,
} from "../iterConnessioni/iterConnessione/autorizzazioneComunale/autorizzazioneComunaleSlice";

import {StatoAutorizzazioneComunaleTypes,} from "../../anagrafiche/kpi/kpiSlice";

import {PermissionTypes} from "../../authentication/permissionsGroups";
import {utenteAutorizzato} from "../../authentication/UtenteAutorizzato";
import {
    Utente,
    UtenteApplicativo,
} from "../../authentication/authenticationSlice";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {Box} from "@mui/system";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";

import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import Pagination from "@mui/material/Pagination";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Select, {SelectChangeEvent} from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import ReplayIcon from "@mui/icons-material/Replay";

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";


const AutorizzazioniComunaliPage = () => {

    const dispatch = useAppDispatch();

    type LocationState = {
        statoAutorizzazioneComunaleId?: string;
        clienteIterId?: number;
        operatoreId?: number;
    };
    const location = useLocation();
    const utente = useSelector<RootState, Utente>(
        (state) => state.authentication.utenteLogged
    );

    const preferenzeListaAutorizzazioni = useSelector<
        RootState,
        PreferenzeLista | undefined
    >((state) => state.userPreferenceState.preferenzeListaAutorizzazioni);

    const [statoAutorizzazioneComunaleId, setStatoAutorizzazioneComunaleId] =
        useState(
            location.state
                ? (location.state as LocationState).statoAutorizzazioneComunaleId
                : preferenzeListaAutorizzazioni?.stato
        );

    const [clienteIterId, setClienteIterId] = useState(
        location.state ? (location.state as LocationState).clienteIterId : undefined
    );

    const [operatoreId, setOperatoreId] = useState(
        location.state
            ? (location.state as LocationState).operatoreId
            : preferenzeListaAutorizzazioni?.operatoreId
    );

    const iterConnessioni = useSelector<RootState, IterConnessioniState>(
        (state) => state.iterConnessioniState.iterConnessioni
    );

    const autorizzazioniComunali = useSelector<
        RootState,
        AutorizzazioniComunaliState
    >((state) => state.autorizzazioneComunaleState.autorizzazioniComunali);


    const utentiApplicativo = useSelector<RootState, UtenteApplicativo[]>(
        (state) => state.authentication.utentiApplicativo.results
    );

    const [page, setPage] = useState<number>(
        preferenzeListaAutorizzazioni?.page || 1
    );
    const [numeroRecord, setNumeroRecord] = useState<number>(
        preferenzeListaAutorizzazioni?.numeroRecord || 20
    );
    const [search, setSearch] = useState<string | undefined>(undefined);

    const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "Enter") {
            setRefresh(true);
        }
    };

    const handleChangeNumeroRecord = (event: SelectChangeEvent) => {
        setNumeroRecord(Number(event.target.value));
    };

    const handlerPager = (event: React.ChangeEvent<unknown>, page: number) => {
        setPage(page);
    };

    const [refresh, setRefresh] = useState<boolean>(false);

    const [anchorElStatoFilter, setAnchorElStatoFilter] = useState(null);
    const [anchorElOperatoreFilter, setAnchorElOperatoreFilter] = useState(null);
    useState(null);

    const handleStatoFilterClose = () => {
        setAnchorElStatoFilter(null);
        setAnchorElOperatoreFilter(null);
    };

    useEffect(() => {
        console.log(
            "statoAutorizzazioneComunaleId     -    ",
            statoAutorizzazioneComunaleId
        );
        dispatch(
            fetchAutorizzazioneComunale({
                statoAutorizzazioneComunale: statoAutorizzazioneComunaleId,
                clienteIter: clienteIterId,
                operatore: operatoreId,
                numeroRecord: numeroRecord,
                page: page,
                search: search,
            })
        );
        dispatch(
            setPreferenzeListaAutorizzazioni({
                numeroRecord: numeroRecord,
                page: page,
                stato: statoAutorizzazioneComunaleId,
                // clienteIterId: clienteIterId,
                // iterSemplificato: iterSemplificato,
                operatoreId: operatoreId,
                // progettistaId: progettistaId,
            })
        );
        setRefresh(false);
    }, [
        page,
        numeroRecord,
        search,
        statoAutorizzazioneComunaleId,
        clienteIterId,
        operatoreId,
        refresh,
    ]);

    return (
        <Box
            sx={{
                backgroundColor: "#fff",
                elevation: 20,
                // p: 2,
                "& .campo-editabile": {
                    backgroundColor: "#bbbbbb11",
                    background: "linear-gradient(to right bottom, #f5f7f6, #dce3e0)",
                    // borderWidth: "5px",
                    borderColor: "#ff91003d",
                    // borderColor: "#0000003d",
                    // borderStyle: "outset",
                    borderWidth: 3,
                    borderStyle: "ridge",
                    color: "#1a3e72",
                    fontWeight: "600",
                },
                "& .campo-errore": {
                    backgroundColor: "rgba(124, 3, 0, 0.3)",
                    color: "#ffffff",
                    fontWeight: "600",
                },
            }}
        >
            <List sx={{mt: 2}}>
                <ListItem>
                    <ButtonGroup
                        variant="outlined"
                        aria-label="outlined button group"
                        sx={{mb: 2}}
                    >
                        <Button
                            color="primary"
                            startIcon={<ReplayIcon/>}
                            onClick={() => setRefresh(true)}
                        >
                            aggiorna
                        </Button>
                        <TextField
                            id="input-with-icon-textfield"
                            // label="TextField"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="end">
                                        <SearchIcon/>
                                    </InputAdornment>
                                ),
                            }}
                            variant="standard"
                            value={search}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setSearch(event.target.value);
                            }}
                            onBlur={() => setRefresh(true)}
                            onKeyDown={handleKeyDown}
                            sx={{ml: 2}}
                        />
                    </ButtonGroup>
                </ListItem>

                <ListItemText
                    key={"Lista_richieste_connessione"}
                    sx={{backgroundColor: "#1b75b1", color: "#ddd", mb: 1}}
                >
                    <Grid container spacing={{xs: 0.5, md: 1, lg: 2}} sx={{px: 2}}>
                        <Grid item xs={6} sm={6} md={3} lg={1}>
                            <Typography variant="h5" gutterBottom>
                                Iter
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={3} lg={1}>
                            <Typography variant="h5" gutterBottom>
                                Autoriz.
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={2}>
                            <Typography variant="h5" gutterBottom>
                                Soggetto
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={1}>
                            <Stack direction="row" spacing={1}>
                                <Typography variant="h5">Stato</Typography>
                                <IconButton
                                    color={
                                        statoAutorizzazioneComunaleId ? "secondary" : "inherit"
                                    }
                                    onClick={(event: any) =>
                                        setAnchorElStatoFilter(event.currentTarget)
                                    }
                                >
                                    <FilterAltIcon sx={{mt: -0.5}}/>
                                </IconButton>
                            </Stack>
                            <Box style={{display: "flex", justifyContent: "space-between"}}>
                                <Menu
                                    id="simple-menu"
                                    anchorEl={anchorElStatoFilter}
                                    keepMounted
                                    open={Boolean(anchorElStatoFilter)}
                                    onClose={handleStatoFilterClose}
                                >
                                    {Object.entries(StatoAutorizzazioneComunaleTypes).map(
                                        ([key, value]) => {
                                            return (
                                                <MenuItem
                                                    key={`tipoCliente_${key}`}
                                                    value={key}
                                                    onClick={() => {
                                                        setStatoAutorizzazioneComunaleId(key);
                                                        setAnchorElStatoFilter(null);
                                                    }}
                                                    sx={{
                                                        backgroundColor:
                                                            key == statoAutorizzazioneComunaleId
                                                                ? "#4aaa"
                                                                : "inherit",
                                                    }}
                                                >
                                                    {value}
                                                </MenuItem>
                                            );
                                        }
                                    )}

                                    <MenuItem
                                        onClick={() => {
                                            setStatoAutorizzazioneComunaleId(undefined);
                                            setAnchorElStatoFilter(null);
                                        }}
                                        sx={{
                                            backgroundColor: !statoAutorizzazioneComunaleId
                                                ? "#aaa"
                                                : "inherit",
                                        }}
                                    >
                                        tutti
                                    </MenuItem>
                                </Menu>
                            </Box>
                        </Grid>

                        <Grid item xs={6} sm={6} md={6} lg={1}>
                            <Typography variant="h5" gutterBottom>
                                Rigettata
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={1}>
                            <Typography variant="h5" gutterBottom>
                                avvio
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={1}>
                            <Typography variant="h5" gutterBottom>
                                conclusione
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={1}>
                            <Typography variant="h5" gutterBottom>
                                av.costr.
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={1}>
                            <Typography variant="h5" gutterBottom>
                                compl.imp.
                            </Typography>
                        </Grid>

                        {utenteAutorizzato(utente, [
                            PermissionTypes.GESTORI_ITER,
                            PermissionTypes.OPERATORI,
                            PermissionTypes.PROGETTISTI,
                        ]) && (
                            <Grid item xs={6} sm={6} md={6} lg={2}>
                                <Stack direction="row" spacing={1}>
                                    <Typography variant="h5">Operatore</Typography>
                                    {/* <IconButton
										color={operatoreId ? "secondary" : "inherit"}
										onClick={(event: any) =>
											setAnchorElOperatoreFilter(event.currentTarget)
										}
									>
										<FilterAltIcon sx={{ mt: -0.5 }} />
									</IconButton> */}
                                </Stack>
                                <Box
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <Menu
                                        id="simple-menu"
                                        anchorEl={anchorElOperatoreFilter}
                                        keepMounted
                                        open={Boolean(anchorElOperatoreFilter)}
                                        onClose={handleStatoFilterClose}
                                    >
                                        {utentiApplicativo
                                            // filtra per utenti con permessi di operatore
                                            .filter((utente) =>
                                                utenteAutorizzato(utente.user, [
                                                    PermissionTypes.OPERATORI,
                                                    PermissionTypes.GESTORI_ITER,
                                                ])
                                            )
                                            .map((operatore) => (
                                                <MenuItem
                                                    key={`operatore_${operatore.id}`}
                                                    onClick={() => {
                                                        setOperatoreId(operatore.id);
                                                        setAnchorElOperatoreFilter(null);
                                                    }}
                                                    sx={{
                                                        backgroundColor:
                                                            operatore.id == operatoreId ? "#aaa" : "inherit",
                                                    }}
                                                >
                                                    {operatore.user.full_name}
                                                </MenuItem>
                                            ))}
                                        <MenuItem
                                            onClick={() => {
                                                setOperatoreId(undefined);
                                                setAnchorElOperatoreFilter(null);
                                            }}
                                            sx={{
                                                backgroundColor: !operatoreId ? "#aaa" : "inherit",
                                            }}
                                        >
                                            tutti
                                        </MenuItem>
                                    </Menu>
                                </Box>
                            </Grid>
                        )}
                    </Grid>
                </ListItemText>

                <Backdrop
                    sx={{
                        color: "#fff",
                        zIndex: (theme) => theme.zIndex.drawer + 1,
                    }}
                    open={
                        iterConnessioni.errorsStack.status == ErrorStatusTypes.PENDING
                        // errorsStack.status == ErrorStatusTypes.PENDING
                    }
                >
                    <CircularProgress color="inherit"/>
                </Backdrop>

                {autorizzazioniComunali.results?.map(
                    (autorizzazioneComunale, index) => (
                        <ListItemButton
                            // key={"iter_" + autorizzazioneComunale.id}
                            key={index}
                            component={Link}
                            to={
                                Routes_path.ITER_CONNESSIONE +
                                autorizzazioneComunale.iter_connessione
                            }
                            sx={{
                                color: "black",
                                textDecoration: "none",
                                backgroundColor: index % 2 === 0 ? "#fff" : "#f2f2f2",
                            }}
                        >
                            <Grid
                                container
                                spacing={{xs: 0.5, md: 1, lg: 2}}
                                sx={{mb: 1}}
                            >
                                <Grid item xs={6} sm={6} md={8} lg={1}>
                                    {autorizzazioneComunale.iter_connessione}
                                </Grid>
                                <Grid item xs={6} sm={6} md={8} lg={1}>
                                    {autorizzazioneComunale.id}
                                </Grid>
                                <Grid item xs={6} sm={6} md={11} lg={2}>
                                    {autorizzazioneComunale.produttore}
                                </Grid>
                                {utenteAutorizzato(utente, [
                                    PermissionTypes.ALL,
                                    PermissionTypes.OPERATORI,
                                    PermissionTypes.PROGETTISTI,
                                ]) && (
                                    <>
                                        <Grid item xs={6} sm={6} md={6} lg={1}>
                                            {
                                                Object.entries(StatoAutorizzazioneComunaleTypes).find(
                                                    ([key, value]) => key == autorizzazioneComunale.stato
                                                )?.[1]
                                            }
                                        </Grid>

                                        <Grid item xs={6} sm={6} md={11} lg={1}>
                                            {autorizzazioneComunale.rigettata ? "rigettata" : " "}
                                        </Grid>

                                        <Grid item xs={6} sm={6} md={6} lg={1}>
                                            {autorizzazioneComunale.data_avvio
                                                ? convertToDateTime(autorizzazioneComunale.data_avvio)?.setLocale("it").toFormat("dd/MM/yyyy")
                                                : ""}
                                        </Grid>
                                        <Grid item xs={6} sm={6} md={6} lg={1}>
                                            {autorizzazioneComunale.data_conclusione
                                                ? convertToDateTime(autorizzazioneComunale.data_conclusione)?.setLocale("it").toFormat("dd/MM/yyyy")
                                                : ""}
                                        </Grid>
                                        <Grid item xs={6} sm={6} md={6} lg={1}>
                                            {autorizzazioneComunale.data_avvio_costruzione
                                                ? convertToDateTime(autorizzazioneComunale.data_avvio_costruzione)?.setLocale("it").toFormat("dd/MM/yyyy")
                                                : ""}
                                        </Grid>
                                        <Grid item xs={6} sm={6} md={6} lg={1}>
                                            {autorizzazioneComunale.data_completamento_impianto
                                                ? convertToDateTime(autorizzazioneComunale.data_completamento_impianto)?.setLocale("it").toFormat("dd/MM/yyyy")
                                                : ""}
                                        </Grid>
                                        <Grid item xs={6} sm={6} md={6} lg={2}>
                                            {utentiApplicativo.find(
                                                (utente) =>
                                                    utente.user.id == autorizzazioneComunale.operatore
                                            )?.user.full_name || "-"}
                                        </Grid>
                                    </>
                                )}
                            </Grid>
                        </ListItemButton>
                    )
                )}

                <ListItem
                    sx={{
                        alignContent: "right",
                        width: "100%",
                        mt: 2,
                        borderTopStyle: "solid",
                        borderTopWidth: 1,
                    }}
                >
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        spacing={2}
                        sx={{width: "100%"}}
                    >
                        <Pagination
                            count={autorizzazioniComunali.num_pages}
                            onChange={handlerPager}
                            page={page}
                        />

                        <Typography noWrap>
                            Totale:{"  "}
                            {autorizzazioniComunali.count}
                        </Typography>

                        <FormControl>
                            <InputLabel id="demo-simple-select-label">Record</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={numeroRecord.toString()}
                                label="Age"
                                onChange={handleChangeNumeroRecord}
                                variant="outlined"
                            >
                                <MenuItem value={10}>10</MenuItem>
                                <MenuItem value={20}>20</MenuItem>
                                <MenuItem value={50}>50</MenuItem>
                            </Select>
                        </FormControl>
                    </Stack>
                </ListItem>
            </List>
        </Box>
    );
};

export default AutorizzazioniComunaliPage;
