import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { useAppDispatch, RootState } from "../../../redux/store";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import FormControlLabel from "@mui/material/FormControlLabel";
import ReactHookFormSelect from "../../common/ReactHookFormSelect";
import {
	ErrorStatusTypes,
	ErrorsStack,
	parseErrorMessage,
} from "../../common/errorsDeclarations";

import {
	Soggetto,
	saveProduttore,
	TipoPersoneTypes,
} from "../produttori/produttoriSlice";
import MenuItem from "@mui/material/MenuItem";

import { Routes_path } from "../../routerApp";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

import TextField from "@mui/material/TextField";

import Alert from "../../common/Alert";
import Typography from "@mui/material/Typography";

import { Button } from "@mui/material";

interface NuovoSoggettoFormProps {
	commessa_id?: number;
	iterConnessione_id?: number;

	come_contraente?: boolean;
	come_titolare?: boolean;
	come_produttore?: boolean;
	come_usufruttore?: boolean;
	consentiModifica?: boolean;
}

const NuovoSoggettoForm = ({
	commessa_id,
	iterConnessione_id,
	come_contraente = false,
	come_titolare = false,
	come_produttore = false,
	come_usufruttore = false,
	consentiModifica,
}: NuovoSoggettoFormProps) => {
	const {
		handleSubmit,
		register,
		formState: { errors, isDirty, isValid },
		setValue,
		watch,
		getValues,
		control,
		setError,
		clearErrors,
		reset,
	} = useForm<Soggetto>();

	const nuovoSoggetto: Soggetto = {
		nome: "",
		cognome: "",
		ragione_sociale: "",
		tipo_persona: "PF",
		codice_fiscale: undefined,
		piva: undefined,
	};
	const [pesonaFisica, setPesonaFisica] = useState<boolean>(true);

	const listaCampi = Object.keys(nuovoSoggetto) as Array<keyof Soggetto>;

	const dispatch = useAppDispatch();

	const errorsStack = useSelector<RootState, ErrorsStack>(
		(state) => state.clientiState.clienti.errorsStack
	);

	useEffect(
		() => {
			reset();
			listaCampi.map((field) => {
				setValue(field, nuovoSoggetto[field]);
			});
		},
		[
			// listaCampi.forEach((field) => {
			// 	nuovoSoggetto[field];
			// }),
			// nuovoSoggetto.id,
		]
	);

	useEffect(() => {
		clearErrors();
		listaCampi.forEach((field) => {
			errorsStack?.fieldsErrors &&
				errorsStack.fieldsErrors[field] &&
				setError(field, {
					type: "server",
					message: errorsStack.fieldsErrors[field].toString(),
				});
		});
	}, [
		errorsStack?.fieldsErrors,
		// nuovoSoggetto?.errorsStack?.fieldsErrors,
		// nuovoSoggetto.allevamento_pack?.errorsStack?.fieldsErrors,
	]);

	useEffect(() => {
		setValue("nome", undefined);
		setValue("cognome", undefined);
		setValue("codice_fiscale", undefined);
		setValue("ragione_sociale", undefined);
		setValue("piva", undefined);
		setPesonaFisica(getValues("tipo_persona") == "PF");
	}, [getValues("tipo_persona")]);

	const handlerSaveSoggetto = (nuovoSoggetto: Soggetto) => {
		consentiModifica &&
			dispatch(
				saveProduttore({
					produttoreToSave: nuovoSoggetto,
					commessa_id_da_agganciare_come_contraente: come_contraente
						? commessa_id
						: undefined,
					commessa_id_da_agganciare_come_titolare: come_titolare
						? commessa_id
						: undefined,
					commessa_id_da_agganciare_come_produttore: come_produttore
						? commessa_id
						: undefined,
					iterConnessione_id_da_agganciare_come_produttore: come_produttore
						? iterConnessione_id
						: undefined,
					iterConnessione_id_da_agganciare_come_usufruttore: come_usufruttore
						? iterConnessione_id
						: undefined,
				})
			);
	};

	const onSubmit: SubmitHandler<Soggetto> = (nuovoSoggetto) => {
		handlerSaveSoggetto(nuovoSoggetto);
	};

	return (
		<Box sx={{ mb: 1 }}>
			<form onSubmit={handleSubmit(onSubmit)}>
				{nuovoSoggetto?.errorsStack?.fieldsErrors?.non_field_errors && (
					<Alert severity="error">
						{nuovoSoggetto?.errorsStack?.fieldsErrors?.non_field_errors.toString()}
					</Alert>
				)}
				<Grid container spacing={2} sx={{ mb: 2 }}>
					<Grid item xs={12} sm={6} md={6} lg={2}>
						<ReactHookFormSelect
							name="tipo_persona"
							label="tipo persona"
							disabled={!consentiModifica}
							register={register}
							control={control}
							watch={watch}
							variant="outlined"
							required
							error={errors?.tipo_persona?.message}
							sx={{ width: "100%" }}
						>
							{Object.entries(TipoPersoneTypes).map(([key, value]) => {
								return (
									<MenuItem key={key} value={key}>
										{value}
									</MenuItem>
								);
							})}
						</ReactHookFormSelect>
					</Grid>
				</Grid>
				{getValues("tipo_persona") == "PF" && (
					<Grid container spacing={2}>
						<Grid item xs={12} sm={6} md={6} lg={2}>
							<TextField
								label="nome"
								disabled={!consentiModifica}
								variant="outlined"
								required
								InputLabelProps={{
									shrink: true,
								}}
								{...register("nome")}
								error={!!errors?.nome}
								helperText={errors?.nome?.message}
								autoComplete={"off"}
								sx={{ width: "100%" }}
								fullWidth
								// onBlur={handlerDetailChaingSubmit}
							/>
						</Grid>
						<Grid item xs={12} sm={6} md={6} lg={2}>
							<TextField
								label="cognome"
								required
								disabled={!consentiModifica}
								variant="outlined"
								InputLabelProps={{
									shrink: true,
								}}
								{...register("cognome")}
								error={!!errors?.cognome}
								helperText={errors?.cognome?.message}
								autoComplete={"off"}
								sx={{ width: "100%" }}
								fullWidth
								// onBlur={handlerDetailChaingSubmit}
							/>
						</Grid>
						<Grid item xs={12} sm={6} md={6} lg={2}>
							<TextField
								label="codice_fiscale"
								disabled={!consentiModifica}
								variant="outlined"
								required
								InputLabelProps={{
									shrink: true,
								}}
								{...register("codice_fiscale")}
								error={!!errors?.codice_fiscale}
								helperText={errors?.codice_fiscale?.message}
								autoComplete={"off"}
								sx={{ width: "100%" }}
								fullWidth
								// onBlur={handlerDetailChaingSubmit}
							/>
						</Grid>
					</Grid>
				)}
				{getValues("tipo_persona") == "PG" && (
					<Grid container spacing={2}>
						<Grid item xs={12} sm={6} md={6} lg={4}>
							<TextField
								label="ragione sociale"
								required
								disabled={!consentiModifica}
								variant="outlined"
								InputLabelProps={{
									shrink: true,
								}}
								{...register("ragione_sociale")}
								error={!!errors?.nome}
								helperText={errors?.nome?.message}
								autoComplete={"off"}
								sx={{ width: "100%" }}
								fullWidth
								// onBlur={handlerDetailChaingSubmit}
							/>
						</Grid>
						<Grid item xs={12} sm={6} md={6} lg={2}>
							<TextField
								label="p.iva"
								required
								disabled={!consentiModifica}
								variant="outlined"
								InputLabelProps={{
									shrink: true,
								}}
								{...register("piva")}
								error={!!errors?.piva}
								helperText={errors?.piva?.message}
								autoComplete={"off"}
								sx={{ width: "100%" }}
								fullWidth
								// onBlur={handlerDetailChaingSubmit}
							/>
						</Grid>
					</Grid>
				)}

				<Button
					disabled={!isDirty || !isValid || !consentiModifica}
					variant="outlined"
					type="submit"
					sx={{ mt: 2 }}
				>
					Aggancia
				</Button>
			</form>
		</Box>
	);
};

export default NuovoSoggettoForm;
