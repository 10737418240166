import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, Link } from "react-router-dom";

import { RootState, useAppDispatch } from "../../../redux/store";
import {
	RegimeCommerciale,
	RegimiCommerciali,
	fetchRegimiCommerciali,
	saveRegimeCommerciale,
	deleteRegimeCommerciale,
} from "./regimiCommercialiSlice";
import ConfermaAnnulla from "../../common/ConfermaAnnulla";

import { DateTime } from "luxon";


import {
	ErrorStatusTypes,
	ErrorsStack,
	parseErrorMessage,
} from "../../common/errorsDeclarations";

import { utenteAutorizzato } from "../../authentication/UtenteAutorizzato";
import { UtenteLogged } from "../../authentication/authenticationSlice";

import AddIcon from "@mui/icons-material/Add";

import { Box } from "@mui/system";
import { Button } from "@mui/material";

import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Collapse from "@mui/material/Collapse";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { theme } from "../../theme";

import RegimiCommercialiForm from "./RegimiCommercialiForm";

import {
	DataGrid,
	GridToolbar,
	GridRowsProp,
	GridRowModel,
	GridColDef,
	GridCellEditStopParams,
	GridCellEditStopReasons,
	MuiEvent,
	GridRowId,
	GridPreProcessEditCellProps,
	useGridApiRef,
	GridEditCellProps,
	GridToolbarContainer,
	GridRenderCellParams,
	GridValueFormatterParams,
} from "@mui/x-data-grid";
import { getRowIdFromRowModel } from "@mui/x-data-grid/hooks/features/rows/gridRowsUtils";
import { idID } from "@mui/material/locale";

const RegimiCommercialiGrid = () => {
	
	const dispatch = useAppDispatch();
	const apiRef = useGridApiRef();

	const location = useLocation();
	const utente = useSelector<RootState, UtenteLogged>(
		(state) => state.authentication.utenteLogged
	);

	// caricamento dallo store
	const regimiCommerciali: RegimiCommerciali = useSelector<
		RootState,
		RegimiCommerciali
	>((state) => state.regimiCommercialiState.regimiCommerciali);

	useEffect(() => {
		dispatch(fetchRegimiCommerciali());
	}, []);

	const [refresh, setRefresh] = useState<boolean>(false);

	// gestione chiusura modal su scelta "Annulla"
	const [openConferma, setOpenConferma] = React.useState(false);
	const handleConfermaClose = () => setOpenConferma(false);

	// gestione chiusura form nuovo elemento su azione "Annulla"
	const [formAttivo, setFormAttivo] = React.useState(false);
	const handleCloseForm = () => setFormAttivo(false);

	////////////////////////////////////

	useEffect(() => {
		setRefresh(false);
	}, [refresh]);

	////////////////////////////////////

	// definizione elemento nuovo vuoto con aggancio errorsStack
	const regimeCommercialeNuovoFieldsErrors: ErrorsStack = useSelector<
		RootState,
		ErrorsStack
	>((state) => state.regimiCommercialiState.regimiCommerciali.errorsStack);

	const [regimeCommercialeNuovo, setRegimeCommercialeNuovo] =
		React.useState<RegimeCommerciale>({
			id: undefined,
			nome: "",
			descrizione: "",
			errorsStack: regimeCommercialeNuovoFieldsErrors,
		});

	useEffect(() => {
		setRegimeCommercialeNuovo({
			...regimeCommercialeNuovo,
			errorsStack: regimeCommercialeNuovoFieldsErrors,
		});
	}, [regimeCommercialeNuovoFieldsErrors]);

	/////////////////////////////////////////

	// salvataggio nuovo elemento con hook di chiusura form se esito "success"
	const handlerSaveRegimeCommerciale = (
		regimeCommercialeToSave: RegimeCommerciale
	) => {
		dispatch(saveRegimeCommerciale(regimeCommercialeToSave));
	};

	useEffect(() => {
		if (regimiCommerciali.errorsStack.status == ErrorStatusTypes.SUCCESS)
			setFormAttivo(false);
	}, [regimiCommerciali.errorsStack.status]);

	////////////////////////////////////////

	// gestione cancellazione elemento
	const handlerDeleteRegimeCommerciale = (row_id: number) => {
		const regimeCommercialeToDelete: RegimeCommerciale | undefined =
			regimiCommerciali.results.find(
				(regimeCommerciale) => regimeCommerciale.id == row_id
			);

		if (regimeCommercialeToDelete != undefined) {
			dispatch(deleteRegimeCommerciale(regimeCommercialeToDelete));
			handleConfermaClose();
		}
	};

	const [idSelezionato, setIdSelezionato] = React.useState<number>(0);

	const nomeRegimeSelezionato = (row_id: number) => {
		const regimeCommercialeSelezionato: RegimeCommerciale | undefined =
			regimiCommerciali.results.find(
				(regimeCommerciale) => regimeCommerciale.id == row_id
			);

		return regimeCommercialeSelezionato?.nome;
	};

	const handleModalopenConfermaOpen = (id: number) => {
		setIdSelezionato(id);
		setOpenConferma(true);
	};

	////////////////////////////

	// gestione aggiornamento elemento nella propria row del Grid
	const processRowUpdate = React.useCallback(
		(newRow: GridRowModel, oldRow: GridRowModel) => {
			const regimeCommercialeDaCambiare: RegimeCommerciale | undefined =
				regimiCommerciali.results.find(
					(regimeCommerciale) => regimeCommerciale.id == newRow.id
				);

			if (regimeCommercialeDaCambiare != undefined) {
				const regimeCommercialeToSave = {
					...regimeCommercialeDaCambiare,
					nome: newRow.nome,
					descrizione: newRow.descrizione,
				};
				dispatch(saveRegimeCommerciale(regimeCommercialeToSave));
				// focus;
			}
			return newRow;
		},
		[regimiCommerciali]
	);

	// definizione struttura del Data Grid
	const rows: GridRowsProp = regimiCommerciali.results.map(
		(regimeCommerciale: RegimeCommerciale) => ({
			id: regimeCommerciale.id,
			nome: regimeCommerciale.nome,
			descrizione: regimeCommerciale.descrizione,
			cancella_regime: regimeCommerciale.id,
		})
	);

	const columns: GridColDef[] = [
		{
			field: "nome",
			headerName: "Nome",
			flex: 0.6,
			editable: true,
			type: "string",
			cellClassName: "campo-editabile",
		},
		{
			field: "descrizione",
			headerName: "Descrizione",
			flex: 1.2,
			editable: true,
			type: "string",
			cellClassName: "campo-editabile",
		},
		{
			field: "cancella_regime",
			headerName: " ",
			flex: 0.02,

			renderCell: (params: GridRenderCellParams<number>) => (
				<IconButton
					key={params.value}
					aria-label="cancella regime"
					size="small"
					onClick={() => handleModalopenConfermaOpen(params.value || 0)}
				>
					<DeleteForeverIcon fontSize="inherit" />
				</IconButton>
			),
		},
	];

	const gridAutoHeight = true;

	// creazione Toolbar personalizzata del Data Grid
	function CustomToolbar() {
		const handleOpenFormToAdd = () => {
			setFormAttivo(true);
		};

		return (
			<Stack
				direction="row"
				justifyContent="space-between"
				alignItems="center"
				spacing={2}
			>
				<GridToolbar />
				<Button
					color="primary"
					startIcon={<AddIcon />}
					onClick={handleOpenFormToAdd}
				>
					Aggiungi nuovo regime
				</Button>
			</Stack>
		);
	}

	return (
		<Box
			sx={{
				backgroundColor: "#fff",
				elevation: 20,
				p: 2,
				// "& .campo-editabile": {
				// 	backgroundColor:  "#bbbbbb11",
				// 	background: "linear-gradient(to right bottom, #f5f7f6, #dce3e0)",
				// 	// borderWidth: "5px",
				// 	// borderColor: "#ff91003d",
				// 	borderColor: "#0000003d",
				// 	// borderStyle: "outset",
				// 	borderWidth: 3,
				// 	borderStyle: "ridge",
				// 	color: "#1a3e72",
				// 	fontWeight: "600",
				// },
				"& .campo-errore": {
					backgroundColor: "rgba(124, 3, 0, 0.3)",
					color: "#ffffff",
					fontWeight: "600",
				},
			}}
		>
			<Collapse in={formAttivo}>
				<Box sx={{ padding: "10px" }}>
					{formAttivo && (
						<RegimiCommercialiForm
							regimeCommercialeNuovo={regimeCommercialeNuovo}
							// regimiCommerciali={regimiCommerciali}
							annulla={handleCloseForm}
							// lotto_id={lotto.id || 0}
							// articoli={articoli.filter((articolo) =>
							// 	articolo.tipi_capo.includes(lotto.tipo_capo || 0)
							// )}
							// articoli={articoli}
							saveRegimeCommerciale={handlerSaveRegimeCommerciale}
							// deleteLavorazioneCommittente={deleteLavorazioneCommittente}
						/>
					)}
				</Box>
			</Collapse>
			<DataGrid
				rows={rows}
				// density="compact"
				columns={columns}
				components={{
					Toolbar: CustomToolbar,
				}}
				autoHeight={gridAutoHeight}
				// sx={{
				// 	width: "100%",
				// 	minHeight: gridAutoHeight ? "200px" : undefined,
				// 	padding: 0,
				// 	margin: 0,
				// 	fontSize: "0.75rem",
				// }}
				experimentalFeatures={{
					newEditingApi: true,
					preventCommitWhileValidating: true,
				}}
				processRowUpdate={processRowUpdate}
				onProcessRowUpdateError={(e) => alert(e)}
				onCellEditStop={(params: GridCellEditStopParams, event: MuiEvent) => {
					if (params.reason === GridCellEditStopReasons.cellFocusOut) {
						event.defaultMuiPrevented = true;
					}
				}}
				getRowId={(row) => row.id}
				hideFooterPagination={true}
			/>

			<ConfermaAnnulla
				handleAnnulla={handleConfermaClose}
				handleConferma={() => handlerDeleteRegimeCommerciale(idSelezionato)}
				domanda={
					"Sicuro di voler cancellare il regime commerciale " +
					nomeRegimeSelezionato(idSelezionato)
				}
				open={openConferma}
			/>
		</Box>
	);
};

export default RegimiCommercialiGrid;
