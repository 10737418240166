import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, Link, useParams } from "react-router-dom";

import { RootState, useAppDispatch } from "../../../../redux/store";

import { DateTime } from "luxon";


import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { Routes_path } from "../../../routerApp";

import { PermissionTypes } from "../../../authentication/permissionsGroups";
import { utenteAutorizzato } from "../../../authentication/UtenteAutorizzato";
import { UtenteLogged } from "../../../authentication/authenticationSlice";
import {
	Sopralluogo,
	getSopralluogo,
	saveSopralluogo,
	StatoSopralluogoTypes,
	AccessoTettoTypes,
	TipoTettoTypes,
	TipoCoperturaTypes,
	StatoCoperturaTypes,
	TipoStaffaTypes,
} from "../../sopralluoghi/sopralluoghiSlice";
import {
	VerificaVincoli,
	getVerificaVincoli,
	saveVerificaVincoli,
	StatoVerificaVincoliTypes,
} from "../verificheVincoliSlice";
import {
	IterConnessione,
	getIterConnessione,
} from "../../iterConnessioni/iterConnessioneSlice";
import { Commessa, getCommessa } from "../../commesse/commesseSlice";
import { TipoPratica } from "../../../anagrafiche/tipiPratica/tipiPraticaSlice";
import SopralluogoEdificioTab from "../../sopralluoghi/sopralluogo/sopralluogoEdificio/SopralluogoEdificioTab";
import SopralluogoElettricoTab from "../../sopralluoghi/sopralluogo/sopralluogoElettrico/SopralluogoElettricoTab";

import { Cliente } from "../../../anagrafiche/clienti/clientiSlice";
import { Soggetto } from "../../../anagrafiche/produttori/produttoriSlice";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import AccoppiaIterVerifica from "../verificaVincoli/accoppiaIterVerifica/AccoppiaIterVerifica";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import { getProduttore } from "../../../anagrafiche/produttori/produttoriSlice";
import { Button, Grid, ButtonGroup } from "@mui/material";
import LinkOffIcon from "@mui/icons-material/LinkOff";
import {
	ErrorStatusTypes,
	ErrorsStack,
} from "../../../common/errorsDeclarations";
import VerificaVincoliForm from "./VerificaVincoliForm";

const VerificaVincoliPage = () => {
	
	const dispatch = useAppDispatch();
	const { verifica_id } = useParams();

	const [verificaVincoliId, setVerificaVincoliId] = useState<number>(
		parseInt(verifica_id || "")
	);

	type LocationState = {
		iterConnessioneId?: number;
	};
	const location = useLocation();
	const state = location.state as LocationState;
	const iterConnessioneId = state?.iterConnessioneId;

	const utente = useSelector<RootState, UtenteLogged>(
		(state) => state.authentication.utenteLogged
	);

	const commesse = useSelector<RootState, Commessa[]>(
		(state) => state.commesseState.commesse.results
	);

	const nuovaVerificaVincoliId = useSelector<RootState, number | undefined>(
		(state) => state.verificheVincoliState.nuovaVerificaVincoliId
	);

	useEffect(() => {
		if (verificaVincoliId) {
			dispatch(getVerificaVincoli(verificaVincoliId));
		}
	}, []);

	useEffect(() => {
		nuovaVerificaVincoliId && setVerificaVincoliId(nuovaVerificaVincoliId);
	}, [nuovaVerificaVincoliId]);

	const verifica_vincoli: VerificaVincoli | undefined = useSelector<
		RootState,
		VerificaVincoli | undefined
	>((state) =>
		state.verificheVincoliState.verificheVincoli.results.find(
			(verifica) => verifica?.id == verificaVincoliId
		)
	);

	const verificaStatus = useSelector<RootState, ErrorStatusTypes>(
		(state) => state.verificheVincoliState.verificheVincoli.errorsStack.status
	);

	const nuovaVerificaVincoli: VerificaVincoli = {
		iter_connessione: iterConnessioneId,
	};

	useEffect(() => {
		verifica_vincoli?.iter_connessione &&
			dispatch(
				getIterConnessione({
					iterConnessioneId: verifica_vincoli?.iter_connessione,
				})
			);
	}, [verifica_vincoli?.iter_connessione]);

	const iterConnessione: IterConnessione | undefined = useSelector<
		RootState,
		IterConnessione | undefined
	>((state) =>
		state.iterConnessioniState.iterConnessioni.results.find(
			(iterConnessione) =>
				iterConnessione?.id == (verifica_vincoli?.iter_connessione || 0)
		)
	);

	useEffect(() => {
		verifica_vincoli?.commessa &&
			dispatch(getCommessa({commessaId:verifica_vincoli?.commessa}));
	}, [verifica_vincoli?.commessa]);

	const commessa: Commessa | undefined = useSelector<
		RootState,
		Commessa | undefined
	>((state) =>
		state.commesseState.commesse.results.find(
			(commessa) => commessa?.id == (verifica_vincoli?.commessa || 0)
		)
	);

	const cliente: Cliente | undefined = useSelector<
		RootState,
		Cliente | undefined
	>((state) =>
		state.clientiState.clienti.results.find(
			(cliente) => cliente?.id == verifica_vincoli?.cliente
		)
	);

	const [currentTab, setCurrentTab] = useState("1");
	const handleTabChange = (event: any, newValue: string) => {
		setCurrentTab(newValue);
	};

	const [openRicercaIter, setOpenRicercaIter] = useState(false);

	// useEffect(() => {
	// 	sopralluogo &&
	// 		["A", "B", "C"].includes(sopralluogo.stato) &&
	// 		setCurrentTab("1");
	// 	sopralluogo &&
	// 		["D", "E"].includes(sopralluogo.stato) &&
	// 		setCurrentTab("2");
	// 	sopralluogo &&
	// 		["F"].includes(sopralluogo.stato) &&
	// 		setCurrentTab("3");
	// 	sopralluogo &&
	// 		["G", "H", "I", "L"].includes(sopralluogo.stato) &&
	// 		setCurrentTab("4");
	// 	sopralluogo &&
	// 		["M", "N"].includes(sopralluogo.stato) &&
	// 		setCurrentTab("7");
	// }, [sopralluogo?.stato]);
	return (
		<Box
			sx={{
				backgroundColor: "#fff",
				elevation: 20,
				p: 2,
			}}
		>
			<Backdrop
				sx={{
					color: "#fff",
					zIndex: (theme) => theme.zIndex.drawer + 1,
				}}
				open={verificaStatus == ErrorStatusTypes.PENDING}
			>
				<CircularProgress color="inherit" />
			</Backdrop>
			{/* <Stack
				direction="row"
				justifyContent="flex-start"
				alignItems="flex-start"
				spacing={4}
				sx={{ mb: 1 }}
			> */}
			<Grid container spacing={1}>
				<Grid item xs={6} sm={6} md={6} lg={2}>
					<Typography variant="h4" color="text.secondary" gutterBottom>
						Verifica Vincoli
						<Chip
							color="primary"
							variant="outlined"
							label={verifica_vincoli?.id}
							sx={{ ml: 1 }}
						/>
					</Typography>
				</Grid>
				<Grid item xs={6} sm={6} md={6} lg={3}>
					{iterConnessione ? (
						<ButtonGroup variant="outlined">
							<Button
								component={Link}
								to={Routes_path.ITER_CONNESSIONE + iterConnessione?.id}
								key={`iterConnessione_${iterConnessione?.id}`}
							>
								iter connessione: {iterConnessione?.codice_pratica}
							</Button>
							<Button
								color="warning"
								startIcon={<LinkOffIcon />}
								onClick={() => {
									dispatch(
										saveVerificaVincoli({
											verificaVincoliToSave: {
												...verifica_vincoli,
												iter_connessione: null,
											},
										})
									);
								}}
							></Button>
						</ButtonGroup>
					) : (
						<Button
							variant="outlined"
							color="info"
							startIcon={<ReportGmailerrorredIcon />}
							onClick={() => setOpenRicercaIter(true)}
						>
							collega ad iter connessione
						</Button>
					)}

					{verifica_vincoli && (
						<AccoppiaIterVerifica
							verifica_vincoli={verifica_vincoli}
							openRicercaIter={openRicercaIter}
							setOpenRicercaIter={setOpenRicercaIter}
						/>
					)}
				</Grid>
				<Grid item xs={6} sm={6} md={6} lg={2}>
					{commessa && (
						<Button
							component={Link}
							variant="outlined"
							to={Routes_path.COMMESSA + verifica_vincoli?.commessa}
							key={`commessa_${verifica_vincoli?.commessa}`}
						>
							commessa:{commessa?.commessa_descrizione}
						</Button>
					)}
				</Grid>
				<Grid item xs={4} sm={4} md={3} lg={2}>
					<Typography variant="h5">{cliente?.ragione_sociale}</Typography>
				</Grid>
				<Grid item xs={4} sm={4} md={3} lg={2}>
					<Typography variant="h5">
						{verifica_vincoli?.produttore_descrizione}
					</Typography>
				</Grid>
				<Grid item xs={4} sm={4} md={4} lg={1}>
					<Typography variant="h5">
						{
							Object.entries(StatoVerificaVincoliTypes).find(
								([key, value]) => key == verifica_vincoli?.stato_verifica
							)?.[1]
						}
					</Typography>
				</Grid>
			</Grid>

			{verificaStatus != ErrorStatusTypes.PENDING ? (
				<VerificaVincoliForm
					verifica_vincoli={verifica_vincoli || nuovaVerificaVincoli}
					// produttore={produttore}
					consentiModifica={utenteAutorizzato(utente, [PermissionTypes.GESTORI_ITER, PermissionTypes.PROGETTISTI,])
						|| (utenteAutorizzato(utente, [PermissionTypes.GENERAL_CONTRACTOR, PermissionTypes.BCK_OFFICE_GENERAL_CONTRACTOR,])
							&& verifica_vincoli?.cliente == utente.cliente
							&& !verifica_vincoli?.servizio_i43
						)}
				/>
			) : (
				<CircularProgress color="inherit" />
			)}
		</Box>
	);
};

export default VerificaVincoliPage;
