import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import {
	Sopralluogo,
	SopralluogoElettrico,
	// TipoTensioneTypes,
	// TipologiaEdiliziaTypes,
	// TitoloPossessoTypes,
	saveSopralluogoElettrico,
	StatoSopralluogoTypes,
	AccessoTettoTypes,
	TipoTettoTypes,
	TipoCoperturaTypes,
	StatoCoperturaTypes,
	TipoStaffaTypes,
	TipoConnessioneTypes,
	ConnessioneDatiTypes,
	TipoConnessioneDatiTypes,
} from "../../sopralluoghiSlice";
import { useAppDispatch, RootState } from "../../../../../redux/store";
import {
	ErrorStatusTypes,
	ErrorsStack,
} from "../../../../common/errorsDeclarations";

import { theme } from "../../../../theme";

import {
	IterConnessione,
	TipoTensioneTypes,
	TipologiaEdiliziaTypes,
	TitoloPossessoTypes,
	saveIterConnessione,
} from "../../../iterConnessioni/iterConnessioneSlice";
import Checkbox from "@mui/material/Checkbox";

import FormHelperText from "@mui/material/FormHelperText";
import FormControlLabel from "@mui/material/FormControlLabel";
import { utenteAutorizzato } from "../../../../authentication/UtenteAutorizzato";
import { UtenteLogged } from "../../../../authentication/authenticationSlice";
import { Cliente } from "../../../../anagrafiche/clienti/clientiSlice";
import { Installatore } from "../../../../anagrafiche/installatori/installatoriSlice";
import { RegimeCommerciale } from "../../../../anagrafiche/regimiCommerciali/regimiCommercialiSlice";
import { TipoPratica } from "../../../../anagrafiche/tipiPratica/tipiPraticaSlice";
import { TipoTensione } from "../../../../anagrafiche/tipiTensione/tipiTensioneSlice";
import { Comune } from "../../../../anagrafiche/comuni/comuniSlice";

import { Gestore } from "../../../../anagrafiche/gestori/gestoriSlice";
import { useForm, SubmitHandler, Controller } from "react-hook-form";

import ReactHookFormSelect from "../../../../common/ReactHookFormSelect";
import MenuItem from "@mui/material/MenuItem";
import Switch from "@mui/material/Switch";
import { Routes_path } from "../../../../routerApp";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Alert from "../../../../common/Alert";
import Typography from "@mui/material/Typography";
import Autocomplete from "@mui/material/Autocomplete";
import { NumericFormat } from "react-number-format";

import { Button, Divider } from "@mui/material";
import { Soggetto } from "components/anagrafiche/produttori/produttoriSlice";

import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { DateTime } from "luxon";


function makeid(length: number) {
	let result = "";
	const characters =
		"ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
	const charactersLength = characters.length;
	let counter = 0;
	while (counter < length) {
		result += characters.charAt(Math.floor(Math.random() * charactersLength));
		counter += 1;
	}
	return result;
}
interface SopralluogoElettricoFormProps {
	sopralluogoElettrico: SopralluogoElettrico;
	// produttore: Soggetto;
	consentiModifica: boolean;
}

const SopralluogoElettricoForm = ({
	sopralluogoElettrico,
	// produttore,
	consentiModifica = false,
}: SopralluogoElettricoFormProps) => {
	const {
		handleSubmit,
		register,
		formState: { errors, isDirty, isValid },
		setValue,
		watch,
		getValues,
		control,
		setError,
		clearErrors,
		reset,
	} = useForm<SopralluogoElettrico>();

	const listaCampi = Object.keys(sopralluogoElettrico) as Array<
		keyof SopralluogoElettrico
	>;

	const dispatch = useAppDispatch();

	const [editing, setEditingControlled] = useState(true);

	const setEditing = (switchModifica: boolean) => {
		consentiModifica && setEditingControlled(switchModifica);
	};

	const utente = useSelector<RootState, UtenteLogged>(
		(state) => state.authentication.utenteLogged
	);
	const clienti = useSelector<RootState, Cliente[]>(
		(state) => state.clientiState.clienti.results
	);

	const installatori = useSelector<RootState, Installatore[]>(
		(state) => state.installatoriState.installatori.results
	);

	const regimiCommerciali = useSelector<RootState, RegimeCommerciale[]>(
		(state) => state.regimiCommercialiState.regimiCommerciali.results
	);
	const tipiPratica = useSelector<RootState, TipoPratica[]>(
		(state) => state.tipiPraticaState.tipiPratica.results
	);
	const tipiTensione = useSelector<RootState, TipoTensione[]>(
		(state) => state.tipiTensioneState.tipiTensione.results
	);

	const gestori = useSelector<RootState, Gestore[]>(
		(state) => state.gestoriState.gestori.results
	);

	const comuni = useSelector<RootState, Comune[]>(
		(state) => state.comuniState.comuni.results
	);

	const [comuneCorrente, setComuneCorrente] = useState<Comune | undefined>(
		undefined
	);

	const [keyPod, setKeyPod] = useState<string>(makeid(5));
	const [annulla, setAnnulla] = useState<boolean>(false);

	useEffect(() => {
		reset();
		listaCampi.map((field) => {
			setValue(field, sopralluogoElettrico[field]);
		});
		setKeyPod(makeid(5));
		setAnnulla(false);
	}, [sopralluogoElettrico.id, annulla]);

	useEffect(() => {
		clearErrors();
		listaCampi.forEach((field) => {
			sopralluogoElettrico?.errorsStack?.fieldsErrors &&
				sopralluogoElettrico.errorsStack.fieldsErrors[field] &&
				setError(field, {
					type: "server",
					message:
						sopralluogoElettrico.errorsStack.fieldsErrors[field].toString(),
				});
		});
	}, [
		sopralluogoElettrico?.errorsStack?.fieldsErrors,
		// sopralluogoElettrico.allevamento_pack?.errorsStack?.fieldsErrors,
	]);

	useEffect(() => {
		sopralluogoElettrico?.errorsStack?.status == ErrorStatusTypes.SUCCESS &&
			setEditing(!sopralluogoElettrico.id);
	}, [
		sopralluogoElettrico,
		// sopralluogoElettrico.allevamento_pack?.errorsStack?.fieldsErrors,
	]);

	const handlerSaveSopralluogoElettrico = (
		sopralluogoElettrico: SopralluogoElettrico
	) => {
		consentiModifica &&
			dispatch(
				saveSopralluogoElettrico({
					sopralluogoElettricoToSave: sopralluogoElettrico,
				})
			);
	};

	const onSubmit: SubmitHandler<SopralluogoElettrico> = (
		sopralluogoElettrico
	) => {
		handlerSaveSopralluogoElettrico(sopralluogoElettrico);
	};

	return (
		<Box sx={{ mb: 1 }}>
			<form onSubmit={handleSubmit(onSubmit)} key={keyPod}>
				{sopralluogoElettrico?.errorsStack?.fieldsErrors?.non_field_errors && (
					<Alert severity="error">
						{sopralluogoElettrico?.errorsStack?.fieldsErrors?.non_field_errors.toString()}
					</Alert>
				)}

				<Grid container spacing={2} sx={{ mb: 2 }}>
					<Grid item xs={12} sm={6} md={6} lg={4}>
						<Controller
							name="sezione_linea"
							control={control}
							render={({ field }) => (
								<NumericFormat
									id="sezione_linea"
									customInput={TextField}
									variant="outlined"
									label="Sezione linea da contatore di scambio a DG"
									InputLabelProps={{
										shrink: true,
									}}
									disabled={!consentiModifica}
									error={!!errors?.sezione_linea}
									helperText={errors?.sezione_linea?.message}
									// {...register("potenza_unitaria")}
									value={field.value}
									autoComplete="off"
									prefix="mm "
									thousandSeparator="."
									decimalSeparator=","
									valueIsNumericString={true}
									onValueChange={(v) => {
										setValue("sezione_linea", Number(v.value), {
											shouldDirty: true,
										});
									}}
									sx={{ width: "100%" }}
								/>
							)}
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={6} lg={4}>
						<Controller
							name="lunghezza_linea"
							control={control}
							render={({ field }) => (
								<NumericFormat
									id="lunghezza_linea"
									customInput={TextField}
									variant="outlined"
									label="La linea dal contatore al DG (approssimativamente)"
									InputLabelProps={{
										shrink: true,
									}}
									disabled={!consentiModifica}
									error={!!errors?.lunghezza_linea}
									helperText={errors?.lunghezza_linea?.message}
									// {...register("potenza_unitaria")}
									value={field.value}
									autoComplete="off"
									prefix="m "
									thousandSeparator="."
									decimalSeparator=","
									valueIsNumericString={true}
									onValueChange={(v) => {
										setValue("lunghezza_linea", Number(v.value), {
											shouldDirty: true,
										});
									}}
									sx={{ width: "100%" }}
								/>
							)}
						/>
					</Grid>

					<Grid item xs={12} sm={12} md={12} lg={4}>
						<ReactHookFormSelect
							name="tipo_connessione"
							label="Tipo connessione"
							register={register}
							control={control}
							watch={watch}
							variant="outlined"
							error={errors?.tipo_connessione?.message}
							sx={{ width: "100%" }}
							disabled={!consentiModifica}
						>
							{Object.entries(TipoConnessioneTypes).map(([key, value]) => {
								return (
									<MenuItem key={key} value={key}>
										{value}
									</MenuItem>
								);
							})}
						</ReactHookFormSelect>
					</Grid>
				</Grid>
				<Divider sx={{ mb: 2 }}>Tipo DG</Divider>

				<Grid container spacing={2} sx={{ mb: 2 }}>
					<Grid item xs={12} sm={6} md={4} lg={2}>
						<FormControlLabel
							value={
								watch("presenza_sezionatore") ||
								sopralluogoElettrico?.presenza_sezionatore
							}
							checked={watch("presenza_sezionatore") || false}
							control={<Checkbox />}
							label={
								watch("presenza_sezionatore")
									? "Sezionatore da:"
									: "Sezionatore"
							}
							labelPlacement="end"
							onChange={(e, checked) => {
								setValue("presenza_sezionatore", checked, {
									shouldDirty: true,
								});
							}}
							disabled={!consentiModifica}
							style={{ padding: 0 }}
						/>
						{!!errors?.presenza_sezionatore && (
							<FormHelperText error>
								{errors?.presenza_sezionatore.toString()}
							</FormHelperText>
						)}
					</Grid>

					<Grid item xs={12} sm={6} md={8} lg={2}>
						{!!watch("presenza_sezionatore") && (
							<Controller
								name="misura_sezionatore"
								control={control}
								render={({ field }) => (
									<NumericFormat
										id="misura_sezionatore"
										customInput={TextField}
										variant="outlined"
										label="Corrente Nominale"
										InputLabelProps={{
											shrink: true,
										}}
										disabled={!consentiModifica}
										error={!!errors?.misura_sezionatore}
										helperText={errors?.misura_sezionatore?.message}
										// {...register("potenza_unitaria")}
										value={field.value}
										autoComplete="off"
										prefix="A "
										thousandSeparator="."
										decimalSeparator=","
										valueIsNumericString={true}
										onValueChange={(v) => {
											setValue("misura_sezionatore", Number(v.value), {
												shouldDirty: true,
											});
										}}
										sx={{ width: "100%" }}
									/>
								)}
							/>
						)}
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={2}>
						<FormControlLabel
							value={
								watch("presenza_magnetotermico") ||
								sopralluogoElettrico?.presenza_magnetotermico
							}
							checked={watch("presenza_magnetotermico") || false}
							control={<Checkbox />}
							label={
								watch("presenza_magnetotermico")
									? "Magnetotermico da:"
									: "Magnetotermico"
							}
							labelPlacement="end"
							onChange={(e, checked) => {
								setValue("presenza_magnetotermico", checked, {
									shouldDirty: true,
								});
							}}
							disabled={!consentiModifica}
							style={{ padding: 0 }}
						/>
						{!!errors?.presenza_magnetotermico && (
							<FormHelperText error>
								{errors?.presenza_magnetotermico.toString()}
							</FormHelperText>
						)}
					</Grid>

					<Grid item xs={12} sm={6} md={8} lg={2}>
						{!!watch("presenza_magnetotermico") && (
							<Controller
								name="misura_magnetotermico"
								control={control}
								render={({ field }) => (
									<NumericFormat
										id="misura_magnetotermico"
										customInput={TextField}
										variant="outlined"
										label="Corrente Nominale"
										InputLabelProps={{
											shrink: true,
										}}
										disabled={!consentiModifica}
										error={!!errors?.misura_magnetotermico}
										helperText={errors?.misura_magnetotermico?.message}
										// {...register("potenza_unitaria")}
										value={field.value}
										autoComplete="off"
										prefix="A "
										thousandSeparator="."
										decimalSeparator=","
										valueIsNumericString={true}
										onValueChange={(v) => {
											setValue("misura_magnetotermico", Number(v.value), {
												shouldDirty: true,
											});
										}}
										sx={{ width: "100%" }}
									/>
								)}
							/>
						)}
					</Grid>
				</Grid>

				<Grid container spacing={2} sx={{ mb: 2 }}>
					<Grid item xs={12} sm={4} md={4} lg={2}>
						<FormControlLabel
							value={
								watch("presenza_differenziale") ||
								sopralluogoElettrico?.presenza_differenziale
							}
							checked={watch("presenza_differenziale") || false}
							control={<Checkbox />}
							label={
								watch("presenza_differenziale")
									? "Differenziale da:"
									: "Differenziale"
							}
							labelPlacement="end"
							onChange={(e, checked) => {
								setValue("presenza_differenziale", checked, {
									shouldDirty: true,
								});
							}}
							disabled={!consentiModifica}
							style={{ padding: 0 }}
						/>
						{!!errors?.presenza_differenziale && (
							<FormHelperText error>
								{errors?.presenza_differenziale.toString()}
							</FormHelperText>
						)}
					</Grid>

					<Grid item xs={6} sm={4} md={4} lg={2}>
						{!!watch("presenza_differenziale") && (
							<Controller
								name="misura_differenziale"
								control={control}
								render={({ field }) => (
									<NumericFormat
										id="misura_differenziale"
										customInput={TextField}
										variant="outlined"
										label="Corrente di Differenza Nominale"
										InputLabelProps={{
											shrink: true,
										}}
										disabled={!consentiModifica}
										error={!!errors?.misura_differenziale}
										helperText={errors?.misura_differenziale?.message}
										// {...register("potenza_unitaria")}
										value={field.value}
										autoComplete="off"
										prefix="mA "
										thousandSeparator="."
										decimalSeparator=","
										valueIsNumericString={true}
										onValueChange={(v) => {
											setValue("misura_differenziale", Number(v.value), {
												shouldDirty: true,
											});
										}}
										sx={{ width: "100%" }}
									/>
								)}
							/>
						)}
					</Grid>
					<Grid item xs={6} sm={4} md={4} lg={4}>
						{!!watch("presenza_differenziale") && (
							<TextField
								label="tipo differenziale"
								variant="outlined"
								InputLabelProps={{
									shrink: true,
								}}
								disabled={!consentiModifica}
								{...register("tipo_differenziale")}
								error={!!errors?.tipo_differenziale}
								helperText={errors?.tipo_differenziale?.message}
								autoComplete={"off"}
								sx={{ width: "100%" }}
								fullWidth
								onClick={() => setEditing(true)}
								// onBlur={handlerDetailChaingSubmit}
							/>
						)}
					</Grid>
				</Grid>

				<Grid container spacing={2} sx={{ mb: 2 }}>
					<Grid item xs={12} sm={4} md={4} lg={2}>
						<FormControlLabel
							value={
								watch("presenza_magnetotermico_differenziale") ||
								sopralluogoElettrico?.presenza_magnetotermico_differenziale
							}
							checked={watch("presenza_magnetotermico_differenziale") || false}
							control={<Checkbox />}
							label={
								watch("presenza_magnetotermico_differenziale")
									? "Magnetotermico Differenziale da:"
									: "Magnetotermico Differenziale"
							}
							labelPlacement="end"
							onChange={(e, checked) => {
								setValue("presenza_magnetotermico_differenziale", checked, {
									shouldDirty: true,
								});
							}}
							disabled={!consentiModifica}
							style={{ padding: 0 }}
						/>
						{!!errors?.presenza_magnetotermico_differenziale && (
							<FormHelperText error>
								{errors?.presenza_magnetotermico_differenziale.toString()}
							</FormHelperText>
						)}
					</Grid>

					<Grid item xs={6} sm={4} md={4} lg={2}>
						{!!watch("presenza_magnetotermico_differenziale") && (
							<Controller
								name="misura_magnetotermico_differenziale_amper"
								control={control}
								render={({ field }) => (
									<NumericFormat
										id="misura_magnetotermico_differenziale_amper"
										customInput={TextField}
										variant="outlined"
										label="Corrente nominale"
										InputLabelProps={{
											shrink: true,
										}}
										disabled={!consentiModifica}
										error={!!errors?.misura_magnetotermico_differenziale_amper}
										helperText={
											errors?.misura_magnetotermico_differenziale_amper?.message
										}
										// {...register("potenza_unitaria")}
										value={field.value}
										autoComplete="off"
										prefix="A "
										thousandSeparator="."
										decimalSeparator=","
										valueIsNumericString={true}
										onValueChange={(v) => {
											setValue(
												"misura_magnetotermico_differenziale_amper",
												Number(v.value),
												{
													shouldDirty: true,
												}
											);
										}}
										sx={{ width: "100%" }}
									/>
								)}
							/>
						)}
					</Grid>
					<Grid item xs={6} sm={4} md={4} lg={2}>
						{!!watch("presenza_magnetotermico_differenziale") && (
							<Controller
								name="misura_magnetotermico_differenziale_milliamper"
								control={control}
								render={({ field }) => (
									<NumericFormat
										id="misura_magnetotermico_differenziale_milliamper"
										customInput={TextField}
										variant="outlined"
										label="Corrente di Differenza Nominale"
										InputLabelProps={{
											shrink: true,
										}}
										disabled={!consentiModifica}
										error={
											!!errors?.misura_magnetotermico_differenziale_milliamper
										}
										helperText={
											errors?.misura_magnetotermico_differenziale_milliamper
												?.message
										}
										// {...register("potenza_unitaria")}
										value={field.value}
										autoComplete="off"
										prefix="mA "
										thousandSeparator="."
										decimalSeparator=","
										valueIsNumericString={true}
										onValueChange={(v) => {
											setValue(
												"misura_magnetotermico_differenziale_milliamper",
												Number(v.value),
												{
													shouldDirty: true,
												}
											);
										}}
										sx={{ width: "100%" }}
									/>
								)}
							/>
						)}
					</Grid>

					<Grid item xs={12} sm={12} md={12} lg={2}>
						{!!watch("presenza_magnetotermico_differenziale") && (
							<TextField
								label="Tipo Magnetotermico Differenziale"
								variant="outlined"
								InputLabelProps={{
									shrink: true,
								}}
								disabled={!consentiModifica}
								{...register("tipo_magnetotermico_differenziale")}
								error={!!errors?.tipo_magnetotermico_differenziale}
								helperText={errors?.tipo_magnetotermico_differenziale?.message}
								autoComplete={"off"}
								sx={{ width: "100%" }}
								fullWidth
								onClick={() => setEditing(true)}
								// onBlur={handlerDetailChaingSubmit}
							/>
						)}
					</Grid>
				</Grid>

				<Grid container spacing={2} sx={{ mb: 2 }}>
					<Grid item xs={12} sm={3} md={2} lg={2} textAlign="end">
						Altro:
					</Grid>
					<Grid item xs={12} sm={9} md={10} lg={10}>
						<TextField
							label="Distributore Generale altri dettagli"
							variant="outlined"
							InputLabelProps={{
								shrink: true,
							}}
							disabled={!consentiModifica}
							{...register("distributore_generale_altro_desc")}
							error={!!errors?.distributore_generale_altro_desc}
							helperText={errors?.distributore_generale_altro_desc?.message}
							autoComplete={"off"}
							sx={{ width: "100%" }}
							fullWidth
							onClick={() => setEditing(true)}
							// onBlur={handlerDetailChaingSubmit}
						/>
					</Grid>
				</Grid>

				<Grid container spacing={2} sx={{ mb: 2 }}>
					<Grid item xs={12} sm={4} md={4} lg={2}>
						<FormControlLabel
							value={watch("messa_terra") || sopralluogoElettrico?.messa_terra}
							checked={watch("messa_terra") || false}
							control={<Checkbox />}
							label={
								watch("messa_terra") ? "Messa a terra da:" : "Messa a terra"
							}
							labelPlacement="end"
							onChange={(e, checked) => {
								setValue("messa_terra", checked, {
									shouldDirty: true,
								});
							}}
							disabled={!consentiModifica}
							style={{ padding: 0 }}
						/>
						{!!errors?.messa_terra && (
							<FormHelperText error>
								{errors?.messa_terra.toString()}
							</FormHelperText>
						)}
					</Grid>

					<Grid item xs={12} sm={8} md={8} lg={2}>
						{!!watch("messa_terra") && (
							<Controller
								name="sezione_messa_terra"
								control={control}
								render={({ field }) => (
									<NumericFormat
										id="sezione_messa_terra"
										customInput={TextField}
										variant="outlined"
										label="Sezione Messa a Terra"
										InputLabelProps={{
											shrink: true,
										}}
										disabled={!consentiModifica}
										error={!!errors?.sezione_messa_terra}
										helperText={errors?.sezione_messa_terra?.message}
										// {...register("potenza_unitaria")}
										value={field.value}
										autoComplete="off"
										prefix="mmq "
										thousandSeparator="."
										decimalSeparator=","
										valueIsNumericString={true}
										onValueChange={(v) => {
											setValue("sezione_messa_terra", Number(v.value), {
												shouldDirty: true,
											});
										}}
										sx={{ width: "100%" }}
									/>
								)}
							/>
						)}
					</Grid>
				</Grid>
				<Grid container spacing={2} sx={{ mb: 2 }}>
					<Grid item xs={12} md={6}>
						<ReactHookFormSelect
							name="connessione_dati"
							label="Connessione Dati"
							register={register}
							disabled={!consentiModifica}
							control={control}
							watch={watch}
							variant="outlined"
							error={errors?.connessione_dati?.message}
							sx={{ width: "100%" }}
						>
							{Object.entries(ConnessioneDatiTypes).map(([key, value]) => {
								return (
									<MenuItem key={key} value={key}>
										{value}
									</MenuItem>
								);
							})}
						</ReactHookFormSelect>
					</Grid>
					<Grid item xs={12} md={6}>
						<ReactHookFormSelect
							name="tipo_connessione_dati"
							label="Tipo Connessione Dati"
							register={register}
							disabled={!consentiModifica}
							control={control}
							watch={watch}
							variant="outlined"
							error={errors?.tipo_connessione_dati?.message}
							sx={{ width: "100%" }}
						>
							{Object.entries(TipoConnessioneDatiTypes).map(([key, value]) => {
								return (
									<MenuItem key={key} value={key}>
										{value}
									</MenuItem>
								);
							})}
						</ReactHookFormSelect>
					</Grid>
				</Grid>

				<Grid container spacing={2} sx={{ mb: 2 }}>
					<Grid item xs={12} sm={12} md={12} lg={12}>
						<TextField
							label="Luogo dove posizionare nuovo inverter"
							variant="outlined"
							InputLabelProps={{
								shrink: true,
							}}
							disabled={!consentiModifica}
							{...register("posizione_inverter")}
							error={!!errors?.posizione_inverter}
							helperText={errors?.posizione_inverter?.message}
							autoComplete={"off"}
							sx={{ width: "100%" }}
							fullWidth
							onClick={() => setEditing(true)}
							// onBlur={handlerDetailChaingSubmit}
						/>
					</Grid>
				</Grid>

				<Grid container spacing={2} sx={{ mb: 2 }}>
					<Grid item xs={12} sm={6} md={6} lg={4}>
						<Typography
							variant="body1"
							component="div"
							color="text.secondary"
							gutterBottom
							textAlign="end"
							onClick={() => setEditing(true)}
						>
							Distanza dal quadro elettrico generale (QG):
						</Typography>
					</Grid>
					<Grid item xs={12} sm={6} md={6} lg={2}>
						<Controller
							name="distanza_quadro"
							control={control}
							render={({ field }) => (
								<NumericFormat
									id="distanza_quadro"
									customInput={TextField}
									variant="outlined"
									// label="potenza unitaria"
									InputLabelProps={{
										shrink: true,
									}}
									disabled={!consentiModifica}
									error={!!errors?.distanza_quadro}
									helperText={errors?.distanza_quadro?.message}
									// {...register("potenza_unitaria")}
									value={field.value}
									autoComplete="off"
									prefix="m "
									thousandSeparator="."
									decimalSeparator=","
									valueIsNumericString={true}
									onValueChange={(v) => {
										setValue("distanza_quadro", Number(v.value), {
											shouldDirty: true,
										});
									}}
									sx={{ width: "100%" }}
								/>
							)}
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={6} lg={4}>
						<Typography
							variant="body1"
							component="div"
							color="text.secondary"
							gutterBottom
							textAlign="end"
							onClick={() => setEditing(true)}
						>
							Lunghezza (approssimativa) della linea DC da inverter a pannelli
							FV:
						</Typography>
					</Grid>
					<Grid item xs={12} sm={6} md={6} lg={2}>
						<Controller
							name="distanza_inverter_pannelli"
							control={control}
							render={({ field }) => (
								<NumericFormat
									id="distanza_inverter_pannelli"
									customInput={TextField}
									variant="outlined"
									// label="potenza unitaria"
									InputLabelProps={{
										shrink: true,
									}}
									disabled={!consentiModifica}
									error={!!errors?.distanza_inverter_pannelli}
									helperText={errors?.distanza_inverter_pannelli?.message}
									// {...register("potenza_unitaria")}
									value={field.value}
									autoComplete="off"
									prefix="m "
									thousandSeparator="."
									decimalSeparator=","
									valueIsNumericString={true}
									onValueChange={(v) => {
										setValue("distanza_inverter_pannelli", Number(v.value), {
											shouldDirty: true,
										});
									}}
									sx={{ width: "100%" }}
								/>
							)}
						/>
					</Grid>
				</Grid>
				<Grid container spacing={2} sx={{ mb: 2 }}></Grid>

				<Button
					disabled={!consentiModifica}
					variant="outlined"
					onClick={() => {
						setAnnulla(true);
					}}
				>
					Annulla
				</Button>
				<Button
					disabled={!consentiModifica}
					variant="outlined"
					type="submit"
					sx={{ ml: 1 }}
				>
					Salva
				</Button>
			</form>
		</Box>
	);
};

export default SopralluogoElettricoForm;
