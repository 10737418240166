import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {useAppDispatch, RootState} from "../../../../../../redux/store";
import {
    ErrorStatusTypes,
    ErrorsStack,
} from "../../../../../common/errorsDeclarations";
import {Comune} from "../../../../../anagrafiche/comuni/comuniSlice";

import {theme} from "../../../../../theme";
import {convertToDateTime} from "components/common/dateUtils/convertToDateTime";
import {AdapterLuxon} from "@mui/x-date-pickers/AdapterLuxon";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {DateTime} from "luxon";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";

import IconButton from "@mui/material/IconButton";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";

import {
    UtenteLogged,
    UtenteApplicativo,
} from "../../../../../authentication/authenticationSlice";
import {
    Commessa,
    saveCommessa,
    StatoCommessaTypes,
    TipoIntervento,
} from "../../../commesseSlice";

import ReactHookFormSelect from "../../../../../common/ReactHookFormSelect";
import {
    Checkbox,
    CircularProgress,
    FormControlLabel,
    FormHelperText,
} from "@mui/material";
import {useForm, SubmitHandler, Controller} from "react-hook-form";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import {Card, CardHeader, CardContent, Paper} from "@mui/material";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import {Button, Divider} from "@mui/material";
import {DesktopDatePicker} from "@mui/x-date-pickers/DesktopDatePicker";
import {Alert} from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Switch from "@mui/material/Switch";

interface PreparazioneCantiereFormProps {
    commessa: Commessa;
    consentiModifica: boolean;
}

const PreparazioneCantiereForm = ({
                                      commessa,
                                      consentiModifica = false,
                                  }: PreparazioneCantiereFormProps) => {
    const {
        handleSubmit,
        register,
        formState: {errors, isDirty, isValid},
        setValue,
        watch,
        getValues,
        control,
        setError,
        clearErrors,
        reset,
    } = useForm<Commessa>();

    const listaCampi = Object.keys(commessa) as Array<keyof Commessa>;

    const dispatch = useAppDispatch();

    const [editing, setEditingControlled] = useState(false);
    const setEditing = (switchModifica: boolean) => {
        consentiModifica && setEditingControlled(switchModifica);
    };

    const utente = useSelector<RootState, UtenteLogged>(
        (state) => state.authentication.utenteLogged
    );

    const commesseStatus = useSelector<RootState, ErrorStatusTypes>(
        (state) => state.commesseState.commesse.errorsStack.status
    );

    const utenteApplicativo = useSelector<
        RootState,
        UtenteApplicativo | undefined
    >((state) =>
        state.authentication.utentiApplicativo.results.find(
            (u) => u.id == utente.id
        )
    );
    const utentiApplicativo = useSelector<RootState, UtenteApplicativo[]>(
        (state) =>
            state.authentication.utentiApplicativo.results.filter(
                (u) =>
                    u.cliente == utenteApplicativo?.cliente || utenteApplicativo?.interno
            )
    );

    const tipiIntervento = useSelector<RootState, TipoIntervento[]>(
        (state) => state.commesseState.tipiIntervento.results
    );

    const [annulla, setAnnulla] = useState<boolean>(false);

    useEffect(() => {
        reset(commessa);
        // listaCampi.map((field) => {
        // 	setValue(field, commessa[field]);
        // });
        // setAnnulla(false);
    }, [commessa, reset]);

    useEffect(() => {
        clearErrors();
        listaCampi.forEach((field) => {
            commessa?.errorsStack?.fieldsErrors &&
            commessa.errorsStack.fieldsErrors[field] &&
            setError(field, {
                type: "server",
                message: commessa.errorsStack.fieldsErrors[field].toString(),
            });
        });
    }, [
        commessa?.errorsStack?.fieldsErrors,
        // cliente.allevamento_pack?.errorsStack?.fieldsErrors,
    ]);

    const handlerSaveCommessa = (commessa: Commessa) => {
        consentiModifica &&
        dispatch(
            saveCommessa({
                commessaToSave: commessa,
            })
        );
    };
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const onSubmit: SubmitHandler<Commessa> = (commessa) => {
        handlerSaveCommessa(commessa);
    };

    const handlerDetailChaingSubmit = () => {
        if (isValid && isDirty) {
            const formValues = getValues();
            handlerSaveCommessa(formValues);
        }
    };

    useEffect(() => {
        if (isValid && isDirty) {
            const formValues = getValues();
            handlerSaveCommessa(formValues);
        }
    }, [
        watch("stampa_ddt"),
        watch("stampa_pos"),
        watch("stampa_sopralluogo"),
        watch("stampa_disposizione_ottimizzatori"),
        watch("stampa_progetto"),
    ]);

    return (
        <Box sx={{mb: 1}}>
            <form onSubmit={handleSubmit(onSubmit)}>
                {commessa?.errorsStack?.fieldsErrors?.non_field_errors && (
                    <Alert severity="error">
                        {commessa?.errorsStack?.fieldsErrors?.non_field_errors.toString()}
                    </Alert>
                )}
                <Grid container spacing={2} width={"100%"} sx={{mb: 1}}>
                    <Grid item lg={4}>
                        <Typography variant="h6">il DDT è stato stampato?</Typography>
                        <FormControlLabel
                            label={
                                <Typography fontSize="0.8rem" marginLeft="0.1rem">
                                    {commessa.stampa_ddt ? "SI" : "NO"}
                                </Typography>
                            }
                            control={
                                <Switch
                                    id="edit_stampa_ddt"
                                    disabled={!consentiModifica}

                                    checked={commessa.stampa_ddt || false}
                                    onChange={(e, checked) => {
                                        setValue("stampa_ddt", checked, {
                                            shouldDirty: true,
                                        });
                                    }}
                                    color="info"
                                    size="small"
                                />
                            }
                        />
                        {!!errors?.stampa_ddt && (
                            <FormHelperText error>
                                {errors?.stampa_ddt?.toString()}
                            </FormHelperText>
                        )}
                    </Grid>
                    <Grid item lg={4}>
                        <Typography variant="h6">il POS è stato stampato?</Typography>
                        <FormControlLabel
                            label={
                                <Typography fontSize="0.8rem" marginLeft="0.1rem">
                                    {commessa.stampa_pos ? "SI" : "NO"}
                                </Typography>
                            }
                            control={
                                <Switch
                                    id="edit_stampa_pos"
                                    disabled={!consentiModifica}
                                    checked={commessa.stampa_pos || false}
                                    onChange={(e, checked) => {
                                        setValue("stampa_pos", checked, {
                                            shouldDirty: true,
                                        });
                                    }}
                                    color="info"
                                    size="small"
                                />
                            }
                        />
                        {!!errors?.stampa_ddt && (
                            <FormHelperText error>
                                {errors?.stampa_pos?.toString()}
                            </FormHelperText>
                        )}
                    </Grid>
                    <Grid item lg={4}>
                        <Typography variant="h6">la scheda SOPRALLUOGO è stato stampata?</Typography>
                        <FormControlLabel
                            label={
                                <Typography fontSize="0.8rem" marginLeft="0.1rem">
                                    {commessa.stampa_sopralluogo ? "SI" : "NO"}
                                </Typography>
                            }
                            control={
                                <Switch
                                    disabled={!consentiModifica}
                                    id="edit_stampa_sopralluogo"
                                    checked={commessa.stampa_sopralluogo || false}
                                    onChange={(e, checked) => {
                                        setValue("stampa_sopralluogo", checked, {
                                            shouldDirty: true,
                                        });
                                    }}
                                    color="info"
                                    size="small"
                                />
                            }
                        />
                        {!!errors?.stampa_ddt && (
                            <FormHelperText error>
                                {errors?.stampa_sopralluogo?.toString()}
                            </FormHelperText>
                        )}
                    </Grid>
                    <Grid item lg={4}>
                        <Typography variant="h6">il PROGETTO è stato stampato?</Typography>
                        <FormControlLabel
                            label={
                                <Typography fontSize="0.8rem" marginLeft="0.1rem">
                                    {commessa.stampa_progetto ? "SI" : "NO"}
                                </Typography>
                            }
                            control={
                                <Switch
                                    id="edit_stampa_progetto"
                                    disabled={!consentiModifica}
                                    checked={commessa.stampa_progetto || false}
                                    onChange={(e, checked) => {
                                        setValue("stampa_progetto", checked, {
                                            shouldDirty: true,
                                        });
                                    }}
                                    color="info"
                                    size="small"
                                />
                            }
                        />
                        {!!errors?.stampa_ddt && (
                            <FormHelperText error>
                                {errors?.stampa_progetto?.toString()}
                            </FormHelperText>
                        )}
                    </Grid>
                    <Grid item lg={4}>
                        <Typography
                            variant="h6">{"L'eventuale disposizione degli OTTIMIZZATORI è stata stampata?"}</Typography>
                        <FormControlLabel
                            label={
                                <Typography fontSize="0.8rem" marginLeft="0.1rem">
                                    {commessa.stampa_disposizione_ottimizzatori ? "SI" : "NO"}
                                </Typography>
                            }
                            control={
                                <Switch
                                    id="edit_stampa_disposizione_ottimizzatori"
                                    disabled={!consentiModifica}
                                    checked={commessa.stampa_disposizione_ottimizzatori || false}
                                    onChange={(e, checked) => {
                                        setValue("stampa_disposizione_ottimizzatori", checked, {
                                            shouldDirty: true,
                                        });
                                    }}
                                    color="info"
                                    size="small"
                                />
                            }
                        />
                        {!!errors?.stampa_ddt && (
                            <FormHelperText error>
                                {errors?.stampa_disposizione_ottimizzatori?.toString()}
                            </FormHelperText>
                        )}
                    </Grid>

                    <Grid item sm={12} lg={3} sx={{justifyContent: "center",}}>
                        <Box>
                            {(isDirty ||
                                commesseStatus == ErrorStatusTypes.SUCCESS ||
                                commesseStatus == ErrorStatusTypes.ERROR) && (
                                <Typography
                                    sx={{
                                        color: isDirty
                                            ? theme.palette.warning.main
                                            : commesseStatus == ErrorStatusTypes.SUCCESS
                                                ? theme.palette.success.main
                                                : commesseStatus == ErrorStatusTypes.ERROR
                                                    ? theme.palette.error.main
                                                    : theme.palette.warning.main,
                                    }}
                                    variant="caption"
                                >
                                    {commesseStatus == ErrorStatusTypes.PENDING && (
                                        <CircularProgress size={5}/>
                                    )}

                                    {isDirty
                                        ? "Modifiche non salvate"
                                        : commesseStatus == ErrorStatusTypes.SUCCESS
                                            ? "Salvato"
                                            : commesseStatus == ErrorStatusTypes.ERROR
                                                ? "Errore"
                                                : ""}
                                </Typography>
                            )}
                        </Box>
                    </Grid>
                    <Grid item sm={12} lg={2}>
                        {commessa.stato == "G" &&
                            <Button
                                variant="contained"
                                color="primary"
                                disabled={!consentiModifica || !commessa.stato_completato}
                                sx={{width: "100%"}}
                                onClick={() => {
                                    dispatch(saveCommessa({
                                        commessaToSave: commessa,
                                        azione: "attiva_installazione"
                                    }))
                                }}
                            >
                                Attiva Installazione
                            </Button>
                        }
                    </Grid>
                </Grid>
            </form>
        </Box>
    );
};

export default PreparazioneCantiereForm;
