export enum PermissionTypes {
	GESTORI_ITER = "gestori_iter",
	OPERATORI = "operatori",
	PROGETTISTI = "progettisti",
	CLIENTI = "clienti",
	PRODUTTORI = "produttori",
	GENERAL_CONTRACTOR = "general_contractor",
	BCK_OFFICE_GENERAL_CONTRACTOR = "bck_office_general_contractor",
	ISTALLATORI_GENERAL_CONTRACTOR = "istallatori_general_contractor",
	GRUPPO_UNDERCONSTRUCTION = "gruppo_underconstruction",
	GESTORI_COMUNITA_ENERGETICHE = "gestori_comunita_energetiche",
	UTENTI_COMUNITA_ENERGETICHE = "utenti_comunita_energetiche",
	AMMINISTRATORI_DOCUMENTI = "amministratori_documenti",
	ALL = "all",
}
