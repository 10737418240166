import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {useLocation, Link, useParams} from "react-router-dom";

import {RootState, useAppDispatch} from "../../../../../redux/store";

import {DateTime} from "luxon";


import {PermissionTypes} from "../../../../authentication/permissionsGroups";

import {utenteAutorizzato} from "../../../../authentication/UtenteAutorizzato";
import {UtenteLogged} from "../../../../authentication/authenticationSlice";
import {IterConnessione} from "../../iterConnessioneSlice";

import IterConnessioneSpalla from "../IterConnessioneSpalla";
import AzioniGestoreCard from "../avvioIter/AzioniGestoreCard";
import IterConnessioneGestoreForm from "./IterConnessioneGestoreForm";
import EmailAliasForm from "./EmailAliasForm";

import Grid from "@mui/material/Grid";
import {Box} from "@mui/system";
import {Soggetto} from "components/anagrafiche/produttori/produttoriSlice";
import IterConnessionePraticaPodForm from "./IterConnessionePraticaPodForm";

interface IterConnessioneDatiPraticaTabProps {
    iterConnessione: IterConnessione;
    produttore: Soggetto;
    consentiModifica: boolean;
}

const IterConnessioneDatiPraticaTab = ({
                                           iterConnessione,
                                           produttore,
                                           consentiModifica = false,
                                       }: IterConnessioneDatiPraticaTabProps) => {

    const dispatch = useAppDispatch();

    const utente = useSelector<RootState, UtenteLogged>(
        (state) => state.authentication.utenteLogged
    );
    return (
        <Box>
            <Grid container spacing={2} sx={{mb: 4}}>
                <Grid item xs={12} sm={12} md={8} lg={9} order={{xs: 2, md: 1}}>
                    {iterConnessione &&
                        (iterConnessione.data_richiesta_presa_carico ||
                            iterConnessione.data_presa_carico) && (
                            <IterConnessioneGestoreForm
                                iterConnessione={iterConnessione}
                                consentiModifica={
                                    consentiModifica &&
                                    utenteAutorizzato(utente, [PermissionTypes.GESTORI_ITER])
                                }
                            />
                        )}


                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            {iterConnessione && iterConnessione.pod_commessa && iterConnessione.data_assegnazione && (
                                <IterConnessionePraticaPodForm
                                    iterConnessione={iterConnessione}
                                    pod={iterConnessione.pod_commessa}
                                    consentiModifica={consentiModifica}
                                    // produttore={}
                                />
                            )}
                        </Grid>
                        <Grid item xs={6}>
                            {produttore && iterConnessione.data_assegnazione && (
                                <EmailAliasForm
                                    produttore={produttore}
                                    iterConnessione={iterConnessione}
                                    consentiModifica={consentiModifica}
                                />
                            )}
                        </Grid>

                    </Grid>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3} order={{xs: 1, md: 2}}>
                    <IterConnessioneSpalla iterConnessione={iterConnessione}/>
                    {utenteAutorizzato(utente, [PermissionTypes.GESTORI_ITER]) && (
                        <AzioniGestoreCard iterConnessione={iterConnessione}/>
                    )}
                </Grid>
            </Grid>
        </Box>
    );
};

export default IterConnessioneDatiPraticaTab;
