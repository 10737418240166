import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../../../redux/store";
import { useLocation, Link, useNavigate } from "react-router-dom";

import AllegatiAdempimentiUploadForm from "./AllegatiAdempimentiUploadForm";
import Tooltip from "@mui/material/Tooltip";
import {
	ErrorStatusTypes,
	ErrorsStack,
	parseErrorMessage,
} from "../../../common/errorsDeclarations";

import {
	AllegatoAdempimenti,
	TipoDocumento,
	AllegatiStrutturaState,
	uploadAllegatoAdempimenti,
	fetchAllegati,
	fetchTipiDocumento,
	deleteAllegatoAdempimenti,
} from "./allegatiAdempimentiSlice";

import ConfermaAnnulla from "../../../common/ConfermaAnnulla";
import { DateTime } from "luxon";
import { convertToDateTime } from "components/common/dateUtils/convertToDateTime";

import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";

import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import DeleteIcon from "@mui/icons-material/Delete";
import { Adempimento } from "../adempimentiSlice";

interface AdempimentiDocumentiListProps {
	adempimento: Adempimento;
	consentiModifica: boolean;
}

const AdempimentiDocumentiList = ({
	adempimento,
	consentiModifica = false,
	...props
}: AdempimentiDocumentiListProps) => {
	const dispatch = useAppDispatch();

	useEffect(() => {
		dispatch(fetchTipiDocumento());
	}, []);

	const tipiDocumento: TipoDocumento[] = useSelector<
		RootState,
		TipoDocumento[]
	>((state) => state.allegatiAdempimentiState.tipiDocumento.results);

	const allegatoCorrente: AllegatiStrutturaState | undefined = useSelector<
		RootState,
		AllegatiStrutturaState | undefined
	>((state) => state.allegatiAdempimentiState);

	// const [poiChiudi, setPoiChiudi] = useState(false);

	const [selectedFile, setSelectedFile] = useState<File | null>(null);

	const handleUploadAllegatoAdempimenti = (
		allegato: AllegatoAdempimenti,
		selectedFile: File | null
	) => {
		selectedFile &&
			dispatch(
				uploadAllegatoAdempimenti({
					allegato: allegato,
					file: selectedFile,
				})
			);
	};

	const [allegatoSelezionato, setAllegatoAdempimentiSelezionato] =
		useState<AllegatoAdempimenti>();

	// gestione chiusura modal su scelta "Annulla"
	const [openConferma, setOpenConferma] = useState(false);
	const handleConfermaClose = () => setOpenConferma(false);

	const handleDelete = (allegato: AllegatoAdempimenti) => {
		dispatch(deleteAllegatoAdempimenti(allegato));
		handleConfermaClose();
	};

	const handleModalopenConfermaOpen = (allegato: AllegatoAdempimenti) => {
		setAllegatoAdempimentiSelezionato(allegato);
		setOpenConferma(true);
	};

	return (
		<Box>
			<List>
				<ListItemText
					key={"Lista_saldo_pallet_committente"}
					sx={{ backgroundColor: "#1b75b199", color: "#ddd", mb: 1 }}
				>
					<Grid container spacing={{ xs: 0.5, md: 1, lg: 2 }} sx={{ px: 2 }}>
						<Grid item xs={12} sm={12} md={12} lg={3}>
							<Typography variant="h5" gutterBottom display="inline">
								File
							</Typography>
						</Grid>
						<Grid item xs={12} sm={12} md={12} lg={2}>
							<Stack direction="row" spacing={1}>
								<Typography variant="h5" gutterBottom>
									Tipo documento
								</Typography>
							</Stack>
						</Grid>
						<Grid item xs={12} sm={12} md={12} lg={3}>
							<Typography variant="h5" gutterBottom>
								Commento
							</Typography>
						</Grid>
						<Grid item xs={12} sm={6} md={10} lg={2}>
							<Typography variant="h5" gutterBottom>
								Autore
							</Typography>
						</Grid>
						<Grid item xs={12} sm={6} md={2} lg={1}>
							<Typography variant="h5" gutterBottom>
								Data
							</Typography>
						</Grid>
						<Grid item xs={12} sm={6} md={2} lg={1}>
							{adempimento && consentiModifica && (
								<AllegatiAdempimentiUploadForm
									allegatoAdempimenti={{
										id: null,
										tipo_documento: null,
										descrizione: "",
										adempimento: adempimento.id,
										// progetto: progettoId,
										// richiesta_connessione: richiestaConnessioneId,
										autore: "",
										file: "",
										file_name: "",
									}}
									tipiDocumento={tipiDocumento}
									uploadAllegatoAdempimenti={handleUploadAllegatoAdempimenti}
									uploading={
										allegatoCorrente?.allegatoCorrente.errorsStack.status ==
										ErrorStatusTypes.PENDING
									}
								/>
							)}
						</Grid>
					</Grid>
				</ListItemText>

				{adempimento.allegati_adempimento?.map((allegato, index) => (
					<ListItemButton
						key={"allegato_" + index}
						sx={{
							color: "black",
							textDecoration: "none",
							backgroundColor: index % 2 === 0 ? "#fff" : "#f2f2f2",
						}}
					>
						<Grid container spacing={{ xs: 0.5, md: 1, lg: 2 }} sx={{ mb: 1 }}>
							<Grid item xs={12} sm={12} md={8} lg={3}>
								<Typography
									component={Link}
									to={allegato.file}
									target="_blank"
									variant="body1"
									gutterBottom
									noWrap
									textOverflow={"ellipsis"}
								>
									{allegato.file_name}
								</Typography>
							</Grid>
							<Grid item xs={12} sm={12} md={8} lg={2}>
								<Typography variant="body1" gutterBottom>
									{allegato.tipo_documento_des?.nome}
								</Typography>
								<Typography variant="caption" gutterBottom>
									{allegato.tipo_documento_des?.descrizione}
								</Typography>
							</Grid>
							<Grid item xs={12} sm={10} md={11} lg={3}>
								<Typography
									variant="body1"
									gutterBottom
									noWrap
									textOverflow={"ellipsis"}
								>
									{allegato.descrizione}
								</Typography>
							</Grid>

							<Grid item xs={6} sm={6} md={10} lg={2}>
								{allegato.autore}
							</Grid>
							<Grid item xs={6} sm={6} md={2} lg={1}>
								{allegato.data_documento
									? convertToDateTime(allegato.data_documento)?.setLocale("it").toFormat("dd/MM/yyyy") : ""}
							</Grid>
							<Grid item xs={6} sm={6} md={2} lg={1}>
								<Stack alignContent="flex-end" alignItems="flex-end">
									{consentiModifica && (
										<IconButton
											aria-label="delete"
											size="small"
											// onClick={() => handleDelete(allegato)}
											onClick={() => handleModalopenConfermaOpen(allegato)}
										>
											<DeleteIcon />
										</IconButton>
									)}
								</Stack>
							</Grid>
						</Grid>
					</ListItemButton>
				))}
			</List>

			{allegatoSelezionato && (
				<ConfermaAnnulla
					handleAnnulla={handleConfermaClose}
					handleConferma={() => handleDelete(allegatoSelezionato)}
					domanda={
						"Sicuro di voler cancellare il file " +
						allegatoSelezionato?.file_name
					}
					open={openConferma}
				/>
			)}
		</Box>
	);
};

export default AdempimentiDocumentiList;
