import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";

import {
	ErrorStatusTypes,
	ErrorsStack,
	parseErrorMessage,
} from "../../common/errorsDeclarations";
import { Utente } from "../../authentication/utenti/utentiSlice";
import * as clientiApi from "../../../api/anagrafiche/clientiApi";
import { toast } from "react-toastify";

export interface Cliente {
	id?: number;
	ragione_sociale?: string;
	general_contractor_abilitato_commesse: boolean;
	indirizzo?: string;
	cap?: string;
	comune?: number | null;
	piva?: string;
	codice_fiscale?: string;
	pec?: string;
	codice_sdi?: string;
	numero_abilitazione_professionale?: string;
	nominativo_contatto_operativo?: string;
	telefono_operativo?: number;
	email_operativo?: string;
	nominativo_contatto_amministrativo?: string;
	telefono_amministrativo?: number;
	email_amministrativo?: string;
	utenti?: Utente[];
	errorsStack?: ErrorsStack;
}

export interface Clienti {
	count: number;
	page: number;
	num_pages: number;
	next?: URL;
	previous?: URL;
	results: Cliente[];
	errorsStack: ErrorsStack;
}

export interface ClientiStrutturaState {
	clienti: Clienti;
	clienteCorrenteId?: number;
}

const initialState: ClientiStrutturaState = {
	clienti: {
		count: 0,
		page: 0,
		num_pages: 0,
		next: undefined,
		previous: undefined,
		results: [],
		errorsStack: { status: ErrorStatusTypes.OK },
	},
};

export const fetchClienti = createAsyncThunk(
	"clienti/fetchClienti",
	async (parametri: {
		numeroRecord?: number;
		page?: number;
		search?: string;
	}) => {
		return await clientiApi.fetchClienti(
			parametri.numeroRecord,
			parametri.page,
			parametri.search
		);
	}
);

export const getCliente = createAsyncThunk(
	"clienti/getCliente",
	async (regimeCommercialeId: number) => {
		return await clientiApi.getCliente(regimeCommercialeId);
	}
);

export const saveCliente = createAsyncThunk(
	"clienti/saveCliente",
	async (clienteToSave: Cliente, thunkApi) => {
		return await clientiApi.saveCliente(clienteToSave);
	}
);

export const deleteCliente = createAsyncThunk(
	"clienti/deleteCliente",
	async (regimeCommercialeToDelete: Cliente, thunkApi) => {
		return await clientiApi.deleteCliente(regimeCommercialeToDelete);
	}
);

export const clientiSlice = createSlice({
	name: "clienti",
	initialState,
	reducers: {
		resetClienteCorrente: (state) => {
			state.clienteCorrenteId = undefined;
		},
	},
	extraReducers: (builder) => {
		// fetch Regimi Commerciali
		builder.addCase(fetchClienti.pending, (state, action) => {
			state.clienti.errorsStack = { status: ErrorStatusTypes.PENDING };
		});
		builder.addCase(fetchClienti.fulfilled, (state, action) => {
			state.clienti = action.payload;
			state.clienti.results.sort((a, b) => {
				const nomeA = a.ragione_sociale?.toUpperCase() || "";
				const nomeB = b.ragione_sociale?.toUpperCase() || "";
				if (nomeA < nomeB) return -1;
				if (nomeA > nomeB) return 1;
				return 0;
			});
			state.clienteCorrenteId = undefined;
			state.clienti.errorsStack = { status: ErrorStatusTypes.OK };
		});
		builder.addCase(fetchClienti.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");
			state.clienti.errorsStack = {
				status: ErrorStatusTypes.ERROR,
				fieldsErrors: JSON.parse(action?.error?.message || ""),
			};
		});

		// get Regime Commerciale
		builder.addCase(getCliente.pending, (state, action) => {
			state.clienti.errorsStack = { status: ErrorStatusTypes.PENDING };
		});
		builder.addCase(getCliente.fulfilled, (state, action) => {
			state.clienti.results = state.clienti.results.map((regimeCommerciale) => {
				if (regimeCommerciale.id == action.payload.id) {
					return action.payload;
				} else {
					return regimeCommerciale;
				}
			});
			state.clienteCorrenteId = action.payload.id;
			state.clienti.errorsStack = { status: ErrorStatusTypes.OK };
		});
		builder.addCase(getCliente.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");
			state.clienti.errorsStack = {
				status: ErrorStatusTypes.ERROR,
				fieldsErrors: JSON.parse(action?.error?.message || ""),
			};
		});

		// save Regime cliente
		builder.addCase(saveCliente.pending, (state, action) => {
			state.clienti.errorsStack = { status: ErrorStatusTypes.PENDING };
		});
		builder.addCase(saveCliente.fulfilled, (state, action) => {
			state.clienti.results = state.clienti.results.filter(
				(cliente) => cliente.id != action.payload.id
			);
			state.clienti.results.push({
				...action.payload,
				errorsStack: { status: ErrorStatusTypes.SUCCESS },
			});
			state.clienteCorrenteId = action.payload.id;
			state.clienti.errorsStack = { status: ErrorStatusTypes.SUCCESS };
			state.clienti.results.sort((a, b) => {
				const nomeA = a.ragione_sociale?.toUpperCase() || "";
				const nomeB = b.ragione_sociale?.toUpperCase() || "";
				if (nomeA < nomeB) return -1;
				if (nomeA > nomeB) return 1;
				return 0;
			});

			toast.success("Cliente salvato.");
		});
		builder.addCase(saveCliente.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");

			state.clienti.results = state.clienti.results.map((regimeCommerciale) => {
				if (regimeCommerciale.id == action.meta.arg.id) {
					return {
						...regimeCommerciale,
						errorsStack: {
							status: ErrorStatusTypes.ERROR,
							fieldsErrors: JSON.parse(action?.error?.message || ""),
						},
					};
				} else {
					return regimeCommerciale;
				}
			});
			state.clienti.errorsStack = {
				status: ErrorStatusTypes.ERROR,
			};
		});
		// cancella Regime Commerciale
		builder.addCase(deleteCliente.pending, (state, action) => {
			state.clienti.errorsStack = { status: ErrorStatusTypes.PENDING };
		});
		builder.addCase(deleteCliente.fulfilled, (state, action) => {
			state.clienti.errorsStack = { status: ErrorStatusTypes.SUCCESS };
			state.clienti.results = state.clienti.results.filter(
				(regimeCommerciale) => regimeCommerciale.id != action.meta.arg.id
			);
			state.clienti.results.sort((a, b) => {
				const nomeA = a.ragione_sociale?.toUpperCase() || "";
				const nomeB = b.ragione_sociale?.toUpperCase() || "";
				if (nomeA < nomeB) return -1;
				if (nomeA > nomeB) return 1;
				return 0;
			});
			toast.success(action.payload.message || "Cliente cancellato.");
		});
		builder.addCase(deleteCliente.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");
			state.clienti.errorsStack = {
				status: ErrorStatusTypes.ERROR,
				fieldsErrors: JSON.parse(action?.error?.message || ""),
			};
		});
	},
});

// Action creators are generated for each case reducer function
export const { resetClienteCorrente } = clientiSlice.actions;

export const clientiSliceReducer = clientiSlice.reducer;
