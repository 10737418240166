import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import {
	ErrorStatusTypes,
	ErrorsStack,
	parseErrorMessage,
} from "../../common/errorsDeclarations";
import * as praticheApi from "../../../api/amministrazione/praticheApi";

import { toast } from "react-toastify";
import { DocumentiPerTipo } from "../../allegati/allegatiSlice";
// import { useDispatch } from "react-redux";



export interface Pratica {
	numero_iter: number;
	commessa: string;
	fatturabilità: string;
	tipo_pratica: string;
	tipo_servizio: string;
	cliente: string;
	produttore: string;
	data_richiesta_presa_carico: string | Date;
	data_presa_carico: string | Date;
	data_alaccio: string | Date;
	errorsStack?: ErrorsStack;
}
export interface PraticheState {
	count: number;
	page: number;
	num_pages: number;
	next?: URL;
	previous?: URL;
	results: Pratica[];
	errorsStack: ErrorsStack;
}
export interface AmministrazioneStrutturaState {
	pratiche: PraticheState;
}

const initialState: AmministrazioneStrutturaState = {
	pratiche: {
		count: 0,
		page: 0,
		num_pages: 0,
		next: undefined,
		previous: undefined,
		results: [],
		errorsStack: { status: ErrorStatusTypes.OK },
	},
};

export const fetchPratiche = createAsyncThunk(
	"amministrazione/fetchPratiche",
	async (parametri: {
		data_da?: string | Date;
		data_a?: string | Date;
		clienteIter?: number;
		numeroRecord?: number;
		page?: number;
		search?: string;
	}) => {
		return await praticheApi.fetchPratiche(
			parametri.data_da,
			parametri.data_a,
			parametri.clienteIter,
			parametri.numeroRecord,
			parametri.page,
			parametri.search
		);
	}
);

export const amministrazioneSlice = createSlice({
	name: "amministrazioneState",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// fetch Pratiche
		builder.addCase(fetchPratiche.pending, (state, action) => {
			state.pratiche.errorsStack = { status: ErrorStatusTypes.PENDING };
		});
		builder.addCase(fetchPratiche.fulfilled, (state, action) => {
			state.pratiche = action.payload;
			state.pratiche.errorsStack = { status: ErrorStatusTypes.OK };
		});
		builder.addCase(fetchPratiche.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");
			state.pratiche.errorsStack = {
				status: ErrorStatusTypes.ERROR,
				fieldsErrors: JSON.parse(action?.error?.message || ""),
			};
		});
	},
});

// Action creators are generated for each case reducer function
export const {} = amministrazioneSlice.actions;

export const amministrazioneSliceReducer = amministrazioneSlice.reducer;
