import React, { useEffect, useState } from "react";
import { RootState, useAppDispatch } from "../../../../redux/store";
import { useSelector } from "react-redux";

import ConfermaAnnulla from "../../../common/ConfermaAnnulla";

import { Commessa, saveCommessa, TipoIntervento } from "../commesseSlice";

import SoggettoPage from "../../../anagrafiche/soggetti/SoggettoPage";
import Button from "@mui/material/Button";
import { Box } from "@mui/system";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import AllegatiPage from "../../../allegati/AllegatiPage";
import {prevedeAmpliamento} from "./prevedeAmpliamento";


interface ContraenteTabProps {
	commessa: Commessa;
	consentiModifica?: boolean;
}

const ContraenteTab = ({ commessa, consentiModifica }: ContraenteTabProps) => {
	const dispatch = useAppDispatch();

	// gestione chiusura modal su scelta "Annulla"
	const [openConferma, setOpenConferma] = useState(false);
	const handleConfermaClose = () => setOpenConferma(false);

	const tipi_intervento = useSelector<RootState, TipoIntervento[]>(
		(state) => state.commesseState.tipiIntervento.results
	);

	return (
		<Box
			sx={{
				backgroundColor: "#fff",
				elevation: 20,
				p: 2,
			}}
		>
			<SoggettoPage
				key={`contraente_${commessa?.contraente}`}

				soggetto_id={commessa?.contraente}
				commessa_id={commessa.id}
				come_contraente={true}
				consentiModifica={consentiModifica}
				ampliamento={prevedeAmpliamento(commessa, tipi_intervento)}
			/>
			<ConfermaAnnulla
				handleAnnulla={handleConfermaClose}
				handleConferma={() => {
					!!commessa &&
						dispatch(
							saveCommessa({
								commessaToSave: { ...commessa, contraente: null },
							})
						);
					handleConfermaClose();
				}}
				domanda={"Sicuro di voler sganciare il Contraente da questa commessa"}
				open={openConferma}
			/>
			{!!commessa?.contraente && (
				<Stack
					direction="row"
					spacing={2}
					sx={{ mt: 2 }}
					justifyContent="flex-end"
				>
					<Button onClick={() => setOpenConferma(true)}>
						Cambia Contraente
					</Button>
				</Stack>
			)}
			<Divider />
			<AllegatiPage
				consentiModifica={consentiModifica || false}
				commessaId={commessa.id}
				faseCommessa={"CTR"}
			/>
		</Box>
	);
};

export default ContraenteTab;
