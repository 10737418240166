import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../../../../redux/store";

import { DateTime } from "luxon";


// import SezioniCard from "./sezioni/SezioniCard";
import IterConnessioneSpalla from "../IterConnessioneSpalla";
import { PermissionTypes } from "../../../../authentication/permissionsGroups";
import AzioniConsegnaCard from "./AzioniConsegnaCard";
import AzioniGestoreCard from "../avvioIter/AzioniGestoreCard";

import IterConnessioneDocumentiList from "./IterConnessioneDocumentiList";

import { utenteAutorizzato } from "../../../../authentication/UtenteAutorizzato";
import { UtenteLogged } from "../../../../authentication/authenticationSlice";
// import { Progetto, getProgettoFromIter } from "./progettiSlice";
import { IterConnessione } from "../../iterConnessioneSlice";
import AllegatiPage from "../../../../allegati/AllegatiPage";
// import IterConnessioneProgettoCard from "./IterConnessioneProgettoCard";
import Divider from "@mui/material/Divider";
import { Soggetto } from "../../../../anagrafiche/produttori/produttoriSlice";
import ProduttoreCredenzialiGSEForm from "./ProduttoreCredenzialiGSEForm";

import Grid from "@mui/material/Grid";
import { Box } from "@mui/system";

import Stack from "@mui/material/Stack";
import { theme } from "../../../../theme";
import { Typography } from "@mui/material";

interface IterConnessioneConsegnaTabProps {
	iterConnessione: IterConnessione;
	produttore: Soggetto;
	utente: UtenteLogged;

	consentiModifica: boolean;
}

const IterConnessioneConsegnaTab = ({
	iterConnessione,
	produttore,
	utente,
	consentiModifica = false,
}: IterConnessioneConsegnaTabProps) => {
	
	const dispatch = useAppDispatch();

	return (
		<Box>
			<Grid container spacing={2} sx={{ mb: 2 }}>
				<Grid item xs={12} sm={12} md={8} lg={9}>
					{utenteAutorizzato(utente, [
						PermissionTypes.GESTORI_ITER,
						PermissionTypes.OPERATORI,
					]) ? (
						<ProduttoreCredenzialiGSEForm produttore={produttore} />
					) : (
						<>
							<Typography variant="h5">
								L'iter di connessione è terminato
							</Typography>

							{produttore.gse_id && (
								<>
									<Divider
										textAlign="left"
										sx={{ mb: 2, color: theme.palette.primary.main }}
									>
										Credenziali portale GSE{" "}
									</Divider>
									<Typography variant="body1">
										utente: {produttore.gse_id}
									</Typography>
									<Typography variant="body1">
										password: {produttore.gse_psw}
									</Typography>
								</>
							)}
							{produttore.gaudi_id && (
								<>
									<Divider
										textAlign="left"
										sx={{ mb: 2, color: theme.palette.primary.main }}
									>
										Credenziali portale Gaudì{" "}
									</Divider>
									<Typography variant="body1">
										utente: {produttore.gaudi_id}
									</Typography>
									<Typography variant="body1">
										password: {produttore.gaudi_psw}
									</Typography>
								</>
							)}
						</>
					)}
				</Grid>

				<Grid item xs={12} sm={12} md={4} lg={3}>
					<IterConnessioneSpalla iterConnessione={iterConnessione} />

					{utenteAutorizzato(utente, [
						PermissionTypes.GESTORI_ITER,
						PermissionTypes.OPERATORI,
					]) && <AzioniConsegnaCard iterConnessione={iterConnessione} />}
					{utenteAutorizzato(utente, [PermissionTypes.GESTORI_ITER]) && (
						<AzioniGestoreCard iterConnessione={iterConnessione} />
					)}
				</Grid>
			</Grid>
			<IterConnessioneDocumentiList
				iterConnessione={iterConnessione}
				utente={utente}
				consentiModifica={utenteAutorizzato(utente, [
					PermissionTypes.GESTORI_ITER,
					PermissionTypes.OPERATORI,
				])}
			/>
		</Box>
	);
};

export default IterConnessioneConsegnaTab;
