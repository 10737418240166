import { handleResponse, handleError, headerset } from "../apiUtils";
import { Adempimento } from "../../components/main/adempimenti/adempimentiSlice";
import { DateTime } from "luxon";
import { convertToDateTime } from "components/common/dateUtils/convertToDateTime";


const baseUrl = (adempimento_id?: number | null) => {
	if (adempimento_id) {
		return process.env.API_URL + "/api/adempimenti/" + adempimento_id + "/";
	} else {
		return process.env.API_URL + "/api/adempimenti/";
	}
};

export function fetchTipiAdempimento() {
	return fetch(process.env.API_URL + "/api/adempimenti/tipi_adempimento/", {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}

export function fetchAdempimenti(
	stato_adempimento?: string,
	cliente?: number,
	produttore?: number,
	pod?: number,
	tipo_adempimento?: number,
	numeroRecord?: number,
	page?: number,
	search?: string,
	data_da?: Date,
	data_a?: Date
) {
	let url = baseUrl();
	// data_da.toFormat('yyyy-MM-dd');

	if (
		stato_adempimento != undefined ||
		cliente != undefined ||
		produttore != undefined ||
		pod != undefined ||
		tipo_adempimento != undefined ||
		numeroRecord != undefined ||
		search != undefined ||
		page != undefined
	) {
		url += "?";
	}
	if (stato_adempimento != undefined) {
		url += `stato_adempimento=${stato_adempimento}&`;
	}
	if (cliente != undefined) {
		url += `cliente=${cliente}&`;
	}
	if (produttore != undefined) {
		url += `produttore=${produttore}&`;
	}
	if (pod != undefined) {
		url += `pod=${pod}&`;
	}
	if (tipo_adempimento != undefined) {
		url += `tipo_adempimento=${tipo_adempimento}&`;
	}
	if (numeroRecord != undefined) {
		url += `numero_record=${numeroRecord}&`;
	}
	if (page != undefined) {
		url += `page=${page}&`;
	}
	if (search != undefined) {
		url += `search=${search}`;
	}
	if (data_da != undefined) {
		url += `data_da=${convertToDateTime(data_da)?.setLocale("it").toFormat("yyyy-MM-dd")}&`;
	}
	if (data_a != undefined) {
		url += `data_a=${convertToDateTime(data_a)?.setLocale("it").toFormat("yyyy-MM-dd")}&`;
	}

	return fetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}

export function getAdempimento(adempimentoId: number) {
	let url = baseUrl(adempimentoId);
	return fetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}

export function saveAdempimento(
	adempimentoToSave: Adempimento,
	azione?: string
) {
	let url = baseUrl(adempimentoToSave.id);
	if (!!azione) {
		url = url + `?azione=${azione}`;
	}
	const data_scadenza = adempimentoToSave.data_scadenza
		? convertToDateTime(adempimentoToSave.data_scadenza)?.setLocale("it").toFormat("yyyy-MM-dd")
		: null;
	const data_pianificata = adempimentoToSave.data_pianificata
		? convertToDateTime(adempimentoToSave.data_pianificata)?.setLocale("it").toFormat("yyyy-MM-dd")
		: null;
	const data_completamento = adempimentoToSave.data_completamento
		? convertToDateTime(adempimentoToSave.data_completamento)?.setLocale("it").toFormat("yyyy-MM-dd")
		: null;
	const adempimento = {
		...adempimentoToSave,
		data_scadenza: data_scadenza,
		data_pianificata: data_pianificata,
		data_completamento: data_completamento,
	};
	return fetch(url, {
		method: adempimentoToSave.id ? "PUT" : "POST", // PUT to update when id already exists.
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
		body: JSON.stringify({
			...adempimento,
			// Parse authorId to a number (in case it was sent as a string).
			// id: parseInt(progetto.id, 10),
		}),
	})
		.then(handleResponse)
		.catch(handleError);
}

export function deleteAdempimento(adempimentoToDelete: Adempimento) {
	return fetch(baseUrl(adempimentoToDelete.id), {
		method: "DELETE",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}
