import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";

import {
	ErrorStatusTypes,
	ErrorsStack,
	parseErrorMessage,
} from "../../common/errorsDeclarations";
import * as regimiCommercialiApi from "../../../api/anagrafiche/regimiCommercialiApi";
import { toast } from "react-toastify";

export interface RegimeCommerciale {
	id?: number;
	nome?: string;
	descrizione?: string;
	errorsStack?: ErrorsStack;
}

export interface RegimiCommerciali {
	count: number;
	page: number;
	num_pages: number;
	next?: URL;
	previous?: URL;
	results: RegimeCommerciale[];
	errorsStack: ErrorsStack;
}

export interface RegimiCommercialiStrutturaState {
	regimiCommerciali: RegimiCommerciali;
}

const initialState: RegimiCommercialiStrutturaState = {
	regimiCommerciali: {
		count: 0,
		page: 0,
		num_pages: 0,
		next: undefined,
		previous: undefined,
		results: [],
		errorsStack: { status: ErrorStatusTypes.OK },
	},
};

// export interface AnagraficheStrutturaState {
// 	regimiCommerciali: RegimiCommerciali;
// 	errorsStack: ErrorsStack;
// }

// const initialState: AnagraficheStrutturaState = {
// 	regimiCommerciali: {
// 		count: 0,
// 		page: 0,
// 		num_pages: 0,
// 		next: undefined,
// 		previous: undefined,
// 		results: [],
//         errorsStack: { status: ErrorStatusTypes.OK },
// 	},
// 	errorsStack: { status: ErrorStatusTypes.OK },
// };

export const fetchRegimiCommerciali = createAsyncThunk(
	"regimiCommerciali/fetchRegimiCommerciali",
	async () => {
		return await regimiCommercialiApi.fetchRegimiCommerciali();
	}
);

export const getRegimeCommerciale = createAsyncThunk(
	"regimiCommerciali/getRegimeCommerciale",
	async (regimeCommercialeId: number) => {
		return await regimiCommercialiApi.getRegimeCommerciale(regimeCommercialeId);
	}
);

export const saveRegimeCommerciale = createAsyncThunk(
	"regimiCommerciali/saveRegimeCommerciale",
	async (regimeCommercialeToSave: RegimeCommerciale, thunkApi) => {
		return await regimiCommercialiApi
			.saveRegimeCommerciale(regimeCommercialeToSave)
			.then((response) => {
				thunkApi.dispatch(fetchRegimiCommerciali());
				return response;
			});
	}
);

export const deleteRegimeCommerciale = createAsyncThunk(
	"regimiCommerciali/deleteRegimeCommerciale",
	async (regimeCommercialeToDelete: RegimeCommerciale, thunkApi) => {
		return await regimiCommercialiApi.deleteRegimeCommerciale(
			regimeCommercialeToDelete
		);
	}
);

export const regimiCommercialiSlice = createSlice({
	name: "regimiCommerciali",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// fetch Regimi Commerciali
		builder.addCase(fetchRegimiCommerciali.pending, (state, action) => {
			state.regimiCommerciali.errorsStack = {
				status: ErrorStatusTypes.PENDING,
			};
		});
		builder.addCase(fetchRegimiCommerciali.fulfilled, (state, action) => {
			state.regimiCommerciali = action.payload;

			state.regimiCommerciali.errorsStack = { status: ErrorStatusTypes.OK };
		});
		builder.addCase(fetchRegimiCommerciali.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");
			state.regimiCommerciali.errorsStack = {
				status: ErrorStatusTypes.ERROR,
				fieldsErrors: JSON.parse(action?.error?.message || ""),
			};
		});

		// get Regime Commerciale
		builder.addCase(getRegimeCommerciale.pending, (state, action) => {
			state.regimiCommerciali.errorsStack = {
				status: ErrorStatusTypes.PENDING,
			};
		});
		builder.addCase(getRegimeCommerciale.fulfilled, (state, action) => {
			state.regimiCommerciali.results = state.regimiCommerciali.results.map(
				(regimeCommerciale) => {
					if (regimeCommerciale.id == action.payload.id) {
						return action.payload;
					} else {
						return regimeCommerciale;
					}
				}
			);
			state.regimiCommerciali.errorsStack = { status: ErrorStatusTypes.OK };
		});
		builder.addCase(getRegimeCommerciale.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");
			state.regimiCommerciali.errorsStack = {
				status: ErrorStatusTypes.ERROR,
				fieldsErrors: JSON.parse(action?.error?.message || ""),
			};
		});

		// save Regime Commerciale
		builder.addCase(saveRegimeCommerciale.pending, (state, action) => {
			state.regimiCommerciali.errorsStack = {
				status: ErrorStatusTypes.PENDING,
			};
		});
		builder.addCase(saveRegimeCommerciale.fulfilled, (state, action) => {
			state.regimiCommerciali.results = state.regimiCommerciali.results.map(
				(regimeCommerciale) => {
					if (regimeCommerciale.id == action.payload.id) {
						return {
							...action.payload,
							errorsStack: {
								status: ErrorStatusTypes.OK,
							},
						};
					} else {
						return regimeCommerciale;
					}
				}
			);
			state.regimiCommerciali.errorsStack = {
				status: ErrorStatusTypes.SUCCESS,
			};
			toast.success("Regime commerciale salvato.");
		});
		builder.addCase(saveRegimeCommerciale.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");

			state.regimiCommerciali.results = state.regimiCommerciali.results.map(
				(regimeCommerciale) => {
					if (regimeCommerciale.id == action.meta.arg.id) {
						return {
							...regimeCommerciale,
							errorsStack: {
								status: ErrorStatusTypes.ERROR,
								fieldsErrors: JSON.parse(action?.error?.message || ""),
							},
						};
					} else {
						return regimeCommerciale;
					}
				}
			);
			state.regimiCommerciali.errorsStack = {
				status: ErrorStatusTypes.ERROR,
				fieldsErrors: JSON.parse(action?.error?.message || ""),
			};
		});
		// cancella Regime Commerciale
		builder.addCase(deleteRegimeCommerciale.pending, (state, action) => {
			state.regimiCommerciali.errorsStack = {
				status: ErrorStatusTypes.PENDING,
			};
		});
		builder.addCase(deleteRegimeCommerciale.fulfilled, (state, action) => {
			state.regimiCommerciali.errorsStack = {
				status: ErrorStatusTypes.SUCCESS,
			};
			state.regimiCommerciali.results = state.regimiCommerciali.results.filter(
				(regimeCommerciale) => regimeCommerciale.id != action.meta.arg.id
			);
			toast.success(action.payload.message || "Regime commerciale cancellato.");
		});
		builder.addCase(deleteRegimeCommerciale.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");
			state.regimiCommerciali.errorsStack = {
				status: ErrorStatusTypes.ERROR,
				fieldsErrors: JSON.parse(action?.error?.message || ""),
			};
		});
	},
});

// Action creators are generated for each case reducer function
export const {} = regimiCommercialiSlice.actions;

export const regimiCommercialiSliceReducer = regimiCommercialiSlice.reducer;
