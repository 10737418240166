import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import {
	ErrorStatusTypes,
	ErrorsStack,
} from "../../../../../common/errorsDeclarations";
import * as allacciApi from "../../../../../../api/iterConnessioni/allacciApi";

import { toast } from "react-toastify";
import { DocumentiPerTipo } from "../../../../../allegati/allegatiSlice";
import { getIterConnessione } from "../../../iterConnessioneSlice";
import { fetchRichiesteConnessioneFromIter } from "../richiesteConnessioneSlice";

// import { useDispatch } from "react-redux";



export interface Allaccio {
	id: number;
	iter_connessione?: number;
	alaccio_data?: Date | string;
	data_ultima_taratura_spi?: Date | string;
	data_ultima_taratura_contatori?: Date | string;
	potenza_nominale?: number;
	alaccio_appuntamento: string;

	gse_data_registrazione_produttore?: Date | string;
	gse_data_richiesta_contratto?: Date | string;
	gse_codice_contratto?: string;
	gse_data_accettazione_contratto?: Date | string;
	gse_data_attivazione_contratto?: Date | string;
	gse_note?: string;
	siad_data_comunicazione_modifica?: Date | string;
	siad_data_modifica_accettazione_GSE?: Date | string;
	siad_note?: string;

	gse_completo: boolean;
	siad_completo: boolean;
	allaccio_completo:  {
		allaccio_completo: boolean;
		dati_mancanti: string;
	};

	listaDocumentiPerTipo?: DocumentiPerTipo[];
	errorsStack?: ErrorsStack;
}

export interface AllacciState {
	count: number;
	page: number;
	num_pages: number;
	next?: URL;
	previous?: URL;
	results: Allaccio[];
	errorsStack: ErrorsStack;
}
export interface AllacciStrutturaState {
	allacci: AllacciState;
}

const initialState: AllacciStrutturaState = {
	allacci: {
		count: 0,
		page: 0,
		num_pages: 0,
		next: undefined,
		previous: undefined,
		results: [],
		errorsStack: { status: ErrorStatusTypes.OK },
	},
};

export const fetchAllacci = createAsyncThunk(
	"allacci/fetchAllacci",
	async () => {
		return await allacciApi.fetchAllacci();
	}
);

export const getAllaccio = createAsyncThunk(
	"allacci/getAllaccio",
	async (allacciId: number) => {
		return await allacciApi.getAllaccio(allacciId);
	}
);

export const getAllaccioFromIter = createAsyncThunk(
	"allacci/getAllaccioFromIter",
	async (iterId: number) => {
		return await allacciApi.getAllaccioFromIter(iterId);
	}
);

export const saveAllaccio = createAsyncThunk(
	"allacci/saveAllaccio",
	async (
		parametri: {
			allaccioToSave: Allaccio;
			azione?: string;
		},
		thunkApi
	) => {
		return await allacciApi
			.saveAllaccio(parametri.allaccioToSave, parametri.azione)
			.then((response) => {
				parametri.azione == "rigetta" &&
					thunkApi.dispatch(
						getAllaccioFromIter(parametri.allaccioToSave.iter_connessione || 0)
					);
				thunkApi.dispatch(
					fetchRichiesteConnessioneFromIter(
						parametri.allaccioToSave.iter_connessione || 0
					)
				);
				thunkApi.dispatch(
					getIterConnessione({
						iterConnessioneId: parametri.allaccioToSave.iter_connessione || 0,
					})
				);
				return response;
			});
	}
);

export const deleteAllaccio = createAsyncThunk(
	"allacci/deleteAllaccio",
	async (allacciToDelete: Allaccio, thunkApi) => {
		return await allacciApi.deleteAllaccio(allacciToDelete);
	}
);

export const allacciSlice = createSlice({
	name: "allacciState",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// fetch Allacci
		builder.addCase(fetchAllacci.pending, (state, action) => {
			state.allacci.errorsStack = { status: ErrorStatusTypes.PENDING };
		});
		builder.addCase(fetchAllacci.fulfilled, (state, action) => {
			state.allacci = action.payload;
			state.allacci.errorsStack = { status: ErrorStatusTypes.OK };
		});
		builder.addCase(fetchAllacci.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");
			state.allacci.errorsStack = {
				status: ErrorStatusTypes.ERROR,
				fieldsErrors: JSON.parse(action?.error?.message || ""),
			};
		});

		// get Allacci
		builder.addCase(getAllaccio.pending, (state, action) => {
			state.allacci.errorsStack = { status: ErrorStatusTypes.PENDING };
		});
		builder.addCase(getAllaccio.fulfilled, (state, action) => {
			state.allacci.results = state.allacci.results.filter(
				(allacci) => allacci.id != action.payload.id
			);
			state.allacci.results.push(action.payload);

			state.allacci.errorsStack = { status: ErrorStatusTypes.OK };
		});
		builder.addCase(getAllaccio.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");
			state.allacci.errorsStack = {
				status: ErrorStatusTypes.ERROR,
				fieldsErrors: JSON.parse(action?.error?.message || ""),
			};
		});

		// get Allacci from Iter
		builder.addCase(getAllaccioFromIter.pending, (state, action) => {
			state.allacci.errorsStack = { status: ErrorStatusTypes.PENDING };
		});
		builder.addCase(getAllaccioFromIter.fulfilled, (state, action) => {
			state.allacci.results = state.allacci.results.filter(
				(allacci) => allacci.id != action.payload.id
			);
			state.allacci.results.push(action.payload);

			state.allacci.errorsStack = { status: ErrorStatusTypes.OK };
		});
		builder.addCase(getAllaccioFromIter.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");
			state.allacci.errorsStack = {
				status: ErrorStatusTypes.ERROR,
				fieldsErrors: JSON.parse(action?.error?.message || ""),
			};
		});

		// save Allacci
		builder.addCase(saveAllaccio.pending, (state, action) => {
			state.allacci.errorsStack = { status: ErrorStatusTypes.PENDING };
		});
		builder.addCase(saveAllaccio.fulfilled, (state, action) => {
			state.allacci.results = state.allacci.results.filter(
				(allacci) => allacci.id != action.payload.id
			);
			state.allacci.results.push({
				...action.payload,
				errorsStack: { status: ErrorStatusTypes.SUCCESS },
			});
			state.allacci.errorsStack = { status: ErrorStatusTypes.SUCCESS };
			toast.success("Allacci salvato.");
		});
		builder.addCase(saveAllaccio.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");

			state.allacci.results = state.allacci.results.map((allacci) => {
				if (allacci.id == action.meta.arg.allaccioToSave.id) {
					return {
						...allacci,
						errorsStack: {
							status: ErrorStatusTypes.ERROR,
							fieldsErrors: JSON.parse(action?.error?.message || ""),
						},
					};
				} else {
					return allacci;
				}
			});
			state.allacci.errorsStack = {
				status: ErrorStatusTypes.ERROR,
			};
		});
	},
});

// Action creators are generated for each case reducer function
export const {} = allacciSlice.actions;

export const allacciSliceReducer = allacciSlice.reducer;
