import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, Link, useParams } from "react-router-dom";

import { RootState, useAppDispatch } from "../../../../redux/store";

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

import { PermissionTypes } from "../../../authentication/permissionsGroups";
import { utenteAutorizzato } from "../../../authentication/UtenteAutorizzato";
import { UtenteLogged } from "../../../authentication/authenticationSlice";
import { TipoDocumento, getTipoDocumento } from "../../allegatiSlice";
import TipoDocumentoForm from "./TipoDocumentoForm";

import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import TipoDocumentoPageBreadcrumbs from "./TipoDocumentoPageBreadcrumbs";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import { Button, Grid, ButtonGroup } from "@mui/material";
import {
	ErrorStatusTypes,
	ErrorsStack,
} from "../../../common/errorsDeclarations";
const TipoDocumentoPage = () => {
	const dispatch = useAppDispatch();
	const { tipo_documento_id } = useParams();

	const [tipoDocumentoId, setTipoDocumentoId] = useState<number>(
		parseInt(tipo_documento_id || "")
	);

	const location = useLocation();
	const utente = useSelector<RootState, UtenteLogged>(
		(state) => state.authentication.utenteLogged
	);
	const tipoDocumento = useSelector<RootState, TipoDocumento | undefined>(
		(state) =>
			state.allegati.tipiDocumento.results.find(
				(tipoDocumento) => tipoDocumento.id == tipoDocumentoId
			)
	);

	useEffect(() => {
		tipoDocumentoId && dispatch(getTipoDocumento(tipoDocumentoId));
	}, [tipoDocumentoId]);

	const tipoDocumentoStatus = useSelector<RootState, ErrorStatusTypes>(
		(state) => state.allegati.tipiDocumento.errorsStack?.status
	);

	return (
		<Box
			sx={{
				p: 2,
			}}
		>
			<TipoDocumentoPageBreadcrumbs
				identificativoPosizione={`${tipoDocumento?.nome || "Nuovo"}`}
			/>
			<Backdrop
				sx={{
					color: "#fff",
					zIndex: (theme) => theme.zIndex.drawer + 1,
				}}
				open={tipoDocumentoStatus == ErrorStatusTypes.PENDING}
			>
				<CircularProgress color="inherit" />
			</Backdrop>
			{/* <Stack
				direction="row"
				justifyContent="flex-start"
				alignItems="flex-start"
				spacing={4}
				sx={{ mb: 1 }}
			> */}
			<Card
				sx={{
					backgroundColor: "#fff",
					elevation: 20,
				}}
			>
				<CardHeader
					sx={{ backgroundColor: "#f0f0f0" }}
					title={
						<Grid container spacing={1}>
							<Grid item xs={4} sm={4} md={3} lg={2}>
								<Typography variant="h4" color="text.secondary" gutterBottom>
									TipoDocumento
									<Chip
										color="primary"
										variant="outlined"
										label={tipoDocumento?.id}
										sx={{ ml: 1 }}
									/>
								</Typography>
							</Grid>
							<Grid item xs={4} sm={4} md={3} lg={3}>
								<Typography variant="h5">{tipoDocumento?.nome}</Typography>
							</Grid>
							<Grid item xs={12} sm={12} md={6} lg={7}>
								<Typography variant="h5">
									{tipoDocumento?.descrizione}
								</Typography>
							</Grid>
						</Grid>
					}
				/>
				<CardContent>
					{tipoDocumento && (
						<TipoDocumentoForm
							tipoDocumento={tipoDocumento}
							consentiModifica={utenteAutorizzato(utente, [
								PermissionTypes.AMMINISTRATORI_DOCUMENTI,
							])}
						/>
					)}
				</CardContent>
			</Card>
		</Box>
	);
};

export default TipoDocumentoPage;
