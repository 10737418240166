import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {convertToDateTime} from "components/common/dateUtils/convertToDateTime";

import {FineLavoro, saveFineLavoro} from "../fineLavoriSlice";
import {useAppDispatch, RootState} from "../../../../../../../redux/store";
import {
    ErrorStatusTypes,
    ErrorsStack,
} from "components/common/errorsDeclarations";

import {theme} from "components/theme";

import {UtenteLogged} from "../../../../../../authentication/authenticationSlice";
import {DateTime} from "luxon";


import {useForm, SubmitHandler, Controller} from "react-hook-form";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Alert from "components/common/Alert";
import Typography from "@mui/material/Typography";
import {DesktopDatePicker} from "@mui/x-date-pickers/DesktopDatePicker";

import {Button,} from "@mui/material";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import AllegatiPage from "../../../../../../allegati/AllegatiPage";

interface IterConnessioneFineLavoroGaudiFormProps {
    fineLavoro: FineLavoro;
    consentiModifica: boolean;
    iterSemplificato: boolean;
}

const IterConnessioneFineLavoroGaudiForm = ({
                                                fineLavoro,
                                                consentiModifica = false,
                                                iterSemplificato = true,
                                            }: IterConnessioneFineLavoroGaudiFormProps) => {

    const {
        handleSubmit,
        register,
        formState: {errors, isDirty, isValid},
        setValue,
        watch,
        getValues,
        control,
        setError,
        clearErrors,
        reset,
    } = useForm<FineLavoro>();

    // const listaCampi = Object.keys(fineLavoro) as Array<keyof FineLavoro>;
    const listaCampi: ("gaudi_censimp"| "gaudi_data_registrazione_impianto"| "gaudi_data_registrazione_UP"| "gaudi_note")[] = ["gaudi_censimp", "gaudi_data_registrazione_impianto", "gaudi_data_registrazione_UP", "gaudi_note"]

    const dispatch = useAppDispatch();

    const [editing, setEditingControlled] = useState(false);

    const setEditing = (switchModifica: boolean) => {
        consentiModifica && setEditingControlled(switchModifica);
    };
    const utente = useSelector<RootState, UtenteLogged>(
        (state) => state.authentication.utenteLogged
    );

    useEffect(() => {
        reset();
        listaCampi.map((field) => {
            setValue(field, fineLavoro[field]);
        });
    }, []);

    useEffect(() => {
        clearErrors();
        listaCampi.forEach((field) => {
            fineLavoro?.errorsStack?.fieldsErrors &&
            fineLavoro.errorsStack.fieldsErrors[field] &&
            setError(field, {
                type: "server",
                message: fineLavoro.errorsStack.fieldsErrors[field].toString(),
            });
        });
    }, [
        fineLavoro?.errorsStack?.fieldsErrors,
        // iterConnessione.allevamento_pack?.errorsStack?.fieldsErrors,
    ]);

    useEffect(() => {
        fineLavoro?.errorsStack?.status == ErrorStatusTypes.SUCCESS &&
        setEditing(false);
    }, [
        fineLavoro,
        // iterConnessione.allevamento_pack?.errorsStack?.fieldsErrors,
    ]);

    const handlerSaveFineLavoro = (fineLavoroDaForm: FineLavoro) => {
        const formValues = getValues();
        consentiModifica &&
        dispatch(saveFineLavoro({fineLavoroToSave: {...fineLavoro, ...formValues}}));
    };

    const onSubmit: SubmitHandler<FineLavoro> = (fineLavoro) => {
        handlerSaveFineLavoro(fineLavoro);
    };

    return (

        <Card elevation={3} sx={{mb: 2}}>
            <CardHeader
                sx={{
                    backgroundColor: fineLavoro.gaudi_completo.gaudi_completo
                        ? "#00aa0099"
                        : "#aa000099",
                }}
                // sx={{backgroundColor: "#377199"}}


                title={"Gaudì"}
            />
            <CardContent>
                <form onSubmit={handleSubmit(onSubmit)}>
                    {fineLavoro?.errorsStack?.fieldsErrors?.non_field_errors && (
                        <Alert severity="error">
                            {fineLavoro?.errorsStack?.fieldsErrors?.non_field_errors.toString()}
                        </Alert>
                    )}
                    <Grid container spacing={2} sx={{mb: 2}}>
                        <Grid item xs={12} sm={6} md={6} lg={4}>
                            <Typography
                                variant="caption"
                                component="div"
                                color="text.secondary"
                                gutterBottom
                                onClick={() => setEditing(true)}
                            >
                                codice Impianto*:
                            </Typography>
                            {!editing ? (
                                <Typography
                                    variant="body1"
                                    component="div"
                                    // color="text.secondary"
                                    gutterBottom
                                    onClick={() => setEditing(true)}
                                    sx={{
                                        color: fineLavoro.gaudi_censimp
                                            ? theme.palette.text.secondary
                                            : theme.palette.divider,
                                    }}
                                >
                                    {fineLavoro.gaudi_censimp
                                        ? fineLavoro.gaudi_censimp.toString()
                                        : "inserisci codice Impianto"}
                                </Typography>
                            ) : (
                                <TextField
                                    // label="indirizzo"
                                    // disabled={schedaCarico.numero_capi_pianificati}
                                    variant="outlined"
                                    // type="number"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    {...register("gaudi_censimp")}
                                    error={!!errors?.gaudi_censimp}
                                    helperText={errors?.gaudi_censimp?.message}
                                    autoComplete={"off"}
                                    sx={{width: "100%"}}
                                    fullWidth
                                    // onBlur={handlerDetailChaingSubmit}
                                />
                            )}
                        </Grid>
                        {!iterSemplificato && (
                            <>
                                <Grid item xs={12} sm={6} md={6} lg={4}>
                                    <Typography
                                        variant="caption"
                                        component="div"
                                        color="text.secondary"
                                        gutterBottom
                                        onClick={() => setEditing(true)}
                                    >
                                        data di registrazione impianto*:
                                    </Typography>

                                    {!editing ? (
                                        <Typography
                                            variant="body1"
                                            component="div"
                                            // color="text.secondary"
                                            gutterBottom
                                            onClick={() => setEditing(true)}
                                            sx={{
                                                color: fineLavoro.gaudi_data_registrazione_impianto
                                                    ? theme.palette.text.secondary
                                                    : theme.palette.divider,
                                            }}
                                        >
                                            {fineLavoro.gaudi_data_registrazione_impianto
                                                ? convertToDateTime(fineLavoro.gaudi_data_registrazione_impianto)?.setLocale("it").toFormat("EEEE dd LLLL yyyy")
                                                : "inserisci data registrazione impianto"}
                                        </Typography>
                                    ) : (
                                        <DesktopDatePicker
                                            // components={{
                                            // 	OpenPickerIcon: ClockIcon,
                                            // }}
                                            // inputFormat="DD/MM/YY HH:mm"
                                            // mask={"__/__/__ __:__"}
                                            // label="Data FineLavoro"
                                            // {...register("data_ora_carico_pianificata")}
                                            value={convertToDateTime(fineLavoro.gaudi_data_registrazione_impianto)}
                                            slotProps={{
                                                textField: {
                                                    required: false,
                                                    error: !!errors?.gaudi_data_registrazione_impianto,
                                                    helperText: errors?.gaudi_data_registrazione_impianto?.message,
                                                    sx: {width: "100%"},
                                                    autoComplete: "off",
                                                    // disabled: !!richiestaConnessione.stato
                                                },
                                                field: {
                                                    clearable: true,
                                                },
                                            }}
                                            onChange={(newValue: any) => {
                                                setEditing(true);
                                                setValue(
                                                    "gaudi_data_registrazione_impianto",
                                                    newValue ? convertToDateTime(newValue)?.toFormat("yyyy-MM-dd") : "",
                                                    {
                                                        shouldDirty: true,
                                                    }
                                                );
                                                clearErrors("gaudi_data_registrazione_impianto");
                                            }}
                                        />
                                    )}
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} lg={4}>
                                    <Typography
                                        variant="caption"
                                        component="div"
                                        color="text.secondary"
                                        gutterBottom
                                        onClick={() => setEditing(true)}
                                    >
                                        data di registrazione Unità Prod.*:
                                    </Typography>

                                    {!editing ? (
                                        <Typography
                                            variant="body1"
                                            component="div"
                                            // color="text.secondary"
                                            gutterBottom
                                            onClick={() => setEditing(true)}
                                            sx={{
                                                color: fineLavoro.gaudi_data_registrazione_UP
                                                    ? theme.palette.text.secondary
                                                    : theme.palette.divider,
                                            }}
                                        >
                                            {fineLavoro.gaudi_data_registrazione_UP
                                                ? convertToDateTime(fineLavoro.gaudi_data_registrazione_UP)?.setLocale("it").toFormat("EEEE dd LLLL yyyy")
                                                : "inserisci data registrazione UP"}
                                        </Typography>
                                    ) : (
                                        <DesktopDatePicker
                                            // components={{
                                            // 	OpenPickerIcon: ClockIcon,
                                            // }}
                                            // inputFormat="DD/MM/YY HH:mm"
                                            // mask={"__/__/__ __:__"}
                                            // label="Data FineLavoro"
                                            // {...register("data_ora_carico_pianificata")}
                                            value={convertToDateTime(fineLavoro.gaudi_data_registrazione_UP)}
                                            slotProps={{
                                                textField: {
                                                    required: false,
                                                    error: !!errors?.gaudi_data_registrazione_UP,
                                                    helperText: errors?.gaudi_data_registrazione_UP?.message,
                                                    sx: {width: "100%"},
                                                    autoComplete: "off",
                                                    // disabled: !!richiestaConnessione.stato
                                                },
                                                field: {
                                                    clearable: true,
                                                },
                                            }}
                                            onChange={(newValue: any) => {
                                                setEditing(true);
                                                setValue(
                                                    "gaudi_data_registrazione_UP",
                                                    newValue ? convertToDateTime(newValue)?.toFormat("yyyy-MM-dd") : "",
                                                    {
                                                        shouldDirty: true,
                                                    }
                                                );
                                                clearErrors("gaudi_data_registrazione_UP");
                                            }}
                                        />
                                    )}
                                </Grid>
                            </>
                        )}
                        <Grid item xs={12} sm={6} md={6} lg={12}>
                            <Typography
                                variant="caption"
                                component="div"
                                color="text.secondary"
                                gutterBottom
                                onClick={() => setEditing(true)}
                            >
                                note:
                            </Typography>

                            {!editing ? (
                                <Typography
                                    variant="body1"
                                    component="div"
                                    // color="text.secondary"
                                    gutterBottom
                                    onClick={() => setEditing(true)}
                                >
                                    {fineLavoro.gaudi_note}
                                </Typography>
                            ) : (
                                <TextField
                                    // label="Motivo Rigetto"
                                    // disabled={schedaCarico.numero_capi_pianificati}
                                    variant="outlined"
                                    // type="number"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    {...register("gaudi_note")}
                                    error={!!errors?.gaudi_note}
                                    helperText={errors?.gaudi_note?.message?.toString()}
                                    autoComplete={"off"}
                                    multiline={true}
                                    rows={3}
                                    sx={{width: "100%"}}
                                    fullWidth
                                    // onBlur={handlerDetailChaingSubmit}
                                />
                            )}
                        </Grid>
                    </Grid>
                    <Button
                        // disabled={!isDirty}
                        variant="outlined"
                        onClick={() => {
                            setEditing(false);
                            // reset();
                        }}
                        sx={{...(!editing && {display: "none"})}}
                    >
                        Annulla
                    </Button>
                    <Button
                        disabled={!isDirty}
                        variant="outlined"
                        type="submit"
                        sx={{...(!editing && {display: "none"}), ml: 1}}
                    >
                        Salva
                    </Button>
                </form>

            </CardContent>
            <CardContent>
                <AllegatiPage
                    // listaDocumentiPerTipo={richiestaConnessione.listaDocumentiPerTipo}
                    iterConnessioneId={fineLavoro.iter_connessione || 0}
                    fineLavoriId = {fineLavoro.id}
                    faseIterConnessione="GAU"
                    // richiestaConnessioneId={richiestaConnessione.id || 0}
                    consentiModifica={consentiModifica}
                />

            </CardContent>
        </Card>
    );
};

export default IterConnessioneFineLavoroGaudiForm;
