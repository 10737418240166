import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { NumericFormat } from "react-number-format";

import {
	IterConnessione,
	TipoTensioneTypes,
	saveIterConnessione, savePodIterconnessione,
} from "../../iterConnessioneSlice";
import { useAppDispatch, RootState } from "../../../../../redux/store";
import {
	ErrorStatusTypes,
	ErrorsStack,
} from "../../../../common/errorsDeclarations";

import { theme } from "../../../../theme";

import { utenteAutorizzato } from "../../../../authentication/UtenteAutorizzato";
import { UtenteLogged } from "../../../../authentication/authenticationSlice";
import { Cliente } from "../../../../anagrafiche/clienti/clientiSlice";
import { Installatore } from "../../../../anagrafiche/installatori/installatoriSlice";
import { RegimeCommerciale } from "../../../../anagrafiche/regimiCommerciali/regimiCommercialiSlice";
import { TipoPratica } from "../../../../anagrafiche/tipiPratica/tipiPraticaSlice";
import { TipoTensione } from "../../../../anagrafiche/tipiTensione/tipiTensioneSlice";

import { Gestore } from "../../../../anagrafiche/gestori/gestoriSlice";
import { useForm, SubmitHandler, Controller } from "react-hook-form";

import ReactHookFormSelect from "../../../../common/ReactHookFormSelect";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Alert from "../../../../common/Alert";
import Typography from "@mui/material/Typography";
import InputAdornment from "@mui/material/InputAdornment";

import { Button, Divider } from "@mui/material";
import {Commessa, PodCommessa, savePodCommessa} from "../../../commesse/commesseSlice";
import {Soggetto} from "../../../../anagrafiche/produttori/produttoriSlice";
import {Comune} from "../../../../anagrafiche/comuni/comuniSlice";
import Autocomplete from "@mui/material/Autocomplete";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Checkbox from "@mui/material/Checkbox";
import FormHelperText from "@mui/material/FormHelperText";
import {TipologiaEdiliziaTypes, TitoloPossessoTypes} from "../../../pods/podsSlice";
import CircularProgress from "@mui/material/CircularProgress";

interface IterConnessionePraticaPodFormProps {
	iterConnessione?: IterConnessione;
	pod: PodCommessa;
	produttore?: Soggetto;
	consentiModifica?: boolean;
}

const IterConnessionePraticaPodForm = ({
	iterConnessione,
	pod,
	produttore,
	consentiModifica = false,
}: IterConnessionePraticaPodFormProps) => {
	const {
		handleSubmit,
		register,
		formState: { errors, isDirty, isValid },
		setValue,
		watch,
		getValues,
		control,
		setError,
		clearErrors,
		reset,
	} = useForm<PodCommessa>();

	const listaCampi = Object.keys(pod) as Array<keyof PodCommessa>;

	const dispatch = useAppDispatch();

	const [editing, setEditingControlled] = useState(false);


	const regimiCommerciali = useSelector<RootState, RegimeCommerciale[]>(
		(state) => state.regimiCommercialiState.regimiCommerciali.results
	);

	const comuni = useSelector<RootState, Comune[]>(
		(state) => state.comuniState.comuni.results
	);

	const [comuneCorrente, setComuneCorrente] = useState<Comune | undefined>(
		undefined
	);

	const commesseStatus = useSelector<RootState, ErrorStatusTypes>(
		(state) => state.commesseState.commesse.errorsStack.status
	);
	useEffect(() => {
		setComuneCorrente(
			comuni.find((comuneScelto) => comuneScelto.id == getValues("comune"))
		);
	}, [comuni, getValues("comune"), pod]);


	useEffect(() => {
		reset();
		listaCampi.map((field) => {
			setValue(field, pod[field]);
		});
		// setKeycodice_pod(makeid(5));
	}, [editing, pod, pod.id]);

	useEffect(() => {
		clearErrors();
		listaCampi.forEach((field) => {
			pod?.errorsStack?.fieldsErrors &&
			pod.errorsStack.fieldsErrors[field] &&
			setError(field, {
				type: "server",
				message: pod.errorsStack.fieldsErrors[field].toString(),
			});
		});
	}, [
		pod?.errorsStack?.fieldsErrors,
		// commessa.allevamento_pack?.errorsStack?.fieldsErrors,
	]);

	const handlerSavePod = (pod: PodCommessa) => {
		if (consentiModifica) {
			if (iterConnessione) {
				dispatch(
					savePodIterconnessione({
						iterConnessione: iterConnessione,
						podCommessaToSave: pod,
					})
				);
			}
		}
	};

	const onSubmit: SubmitHandler<PodCommessa> = (pod) => {
		handlerSavePod(pod);
	};


	useEffect(() => {
		if (isValid && isDirty) {
			const formValues = getValues();
			if (formValues.id){
				handlerSavePod(formValues);
			}
		}
	}, [
		watch("regime_commerciale"),
		// watch("destinazione_residenziale"),
	]);


	return (
		<Box sx={{mb: 1}}>
			<form onSubmit={handleSubmit(onSubmit)}>

				<Divider
					textAlign="left"
					sx={{ my: 2, color: theme.palette.primary.main }}
				>
					Regime Commerciale llll
				</Divider>
				{pod?.errorsStack?.fieldsErrors?.non_field_errors && (
					<Alert severity="error">
						{pod?.errorsStack?.fieldsErrors?.non_field_errors.toString()}
					</Alert>
				)}

						<ReactHookFormSelect
							name="regime_commerciale"
							// label="regime_commerciale"
							register={register}
							disabled={!consentiModifica}
							control={control}
							watch={watch}
							variant="outlined"
							error={errors?.regime_commerciale?.message}
							sx={{width: "100%"}}
						>
							<MenuItem value="" key="regime_vuoto">
								<em>Nessuno</em>
							</MenuItem>
							{regimiCommerciali.map((regime_commerciale) => {
								return (
									<MenuItem
										key={regime_commerciale.id}
										value={regime_commerciale.id}
									>
										{regime_commerciale.nome} - {regime_commerciale.descrizione}
									</MenuItem>
								);
							})}
						</ReactHookFormSelect>

				<Box>
					{(isDirty ||
						commesseStatus == ErrorStatusTypes.SUCCESS ||
						commesseStatus == ErrorStatusTypes.ERROR) && (
						<Typography
							sx={{
								color: isDirty
									? theme.palette.warning.main
									: commesseStatus == ErrorStatusTypes.SUCCESS
										? theme.palette.success.main
										: commesseStatus == ErrorStatusTypes.ERROR
											? theme.palette.error.main
											: theme.palette.warning.main,
							}}
							variant="caption"
						>
							{commesseStatus == ErrorStatusTypes.PENDING && (
								<CircularProgress size={5}/>
							)}

							{isDirty
								? "Modifiche non salvate"
								: commesseStatus == ErrorStatusTypes.SUCCESS
									? "Salvato"
									: commesseStatus == ErrorStatusTypes.ERROR
										? "Errore"
										: ""}
						</Typography>
					)}
				</Box>
			</form>
		</Box>
	);
};

export default IterConnessionePraticaPodForm;
