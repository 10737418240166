import React from "react";
import {UtenteLogged} from "../../../../../authentication/authenticationSlice";
import {FineLavoro} from "./fineLavoriSlice";
import {IterConnessione} from "../../../iterConnessioneSlice";

import {Box} from "@mui/system";

import IterConnessioneFineLavoroGaudiForm from "./gaudi/IterConnessioneFineLavoroGaudiForm";
import IterConnessioneFineLavoroRegolamentoEsercizioForm
    from "./regolamentoEsercizio/IterConnessioneFineLavoroRegolamentoEsercizioForm";
import IterConnessioneFineLavoroIterAutorizzativoForm
    from "./iterAutorizzativo/IterConnessioneFineLavoroIterAutorizzativoForm";
import AllegatiPage from "../../../../../allegati/AllegatiPage";

interface IterConnessioneFineLavoriBoxProps {
    iterConnessione: IterConnessione;
    fineLavoro: FineLavoro;
    utente: UtenteLogged;

    consentiModifica: boolean;
}

const IterConnessioneFineLavoriBox = ({
                                          iterConnessione,
                                          fineLavoro,
                                          utente,
                                          consentiModifica = false,
                                      }: IterConnessioneFineLavoriBoxProps) => {

    return (
        <Box>
            <AllegatiPage
                // listaDocumentiPerTipo={richiestaConnessione.listaDocumentiPerTipo}
                iterConnessioneId={fineLavoro.iter_connessione || 0}
                fineLavoriId = {fineLavoro.id}
                faseIterConnessione="FIL"
                // richiestaConnessioneId={richiestaConnessione.id || 0}
                consentiModifica={true}
            />


            {fineLavoro &&
                <IterConnessioneFineLavoroGaudiForm fineLavoro={fineLavoro} consentiModifica={consentiModifica}
                                                    iterSemplificato={iterConnessione.iter_semplificato}/>}
            {fineLavoro && !iterConnessione.iter_semplificato &&
                <IterConnessioneFineLavoroIterAutorizzativoForm fineLavoro={fineLavoro}
                                                                consentiModifica={consentiModifica}
                                                                iterSemplificato={iterConnessione.iter_semplificato}/>}
            {fineLavoro && <IterConnessioneFineLavoroRegolamentoEsercizioForm fineLavoro={fineLavoro}
                                                                              consentiModifica={consentiModifica}
                                                                              iterSemplificato={iterConnessione.iter_semplificato}/>}
        </Box>
    );
};

export default IterConnessioneFineLavoriBox;
