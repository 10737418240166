import React, {useState, useEffect} from "react";
import {useSelector} from "react-redux";
import {RootState, useAppDispatch} from "../../../../redux/store";

// import AllegatiUploadForm from "./AllegatiUploadForm";
import {AllegatiGeneraliCer, ComunitaEnergetica, fetchAllegatiGeneraliCer} from "../comunitaEnergeticheSlice";
import Tooltip from "@mui/material/Tooltip";
import {
    ErrorStatusTypes,
    // ErrorsStack,
    // parseErrorMessage,
} from "components/common/errorsDeclarations";

import ConfermaAnnulla from "components/common/ConfermaAnnulla";

import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import {Link,} from "react-router-dom";

import {Typography, IconButton} from "@mui/material";

import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import DeleteIcon from "@mui/icons-material/Delete";
import Select, {SelectChangeEvent} from "@mui/material/Select";
import {
    Allegati,
    AllegatiState,
    Allegato,
    fetchAllegati,
    TipoDocumento,
    uploadAllegato
} from "../../../allegati/allegatiSlice";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import ZipDowloadButton from "../../../allegati/ZipDowloadButton";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemButton from "@mui/material/ListItemButton";
import {convertToDateTime} from "../../../common/dateUtils/convertToDateTime";
import ListItem from "@mui/material/ListItem";
import AllegatiUploadForm from "../../../allegati/AllegatiUploadForm";
import Pagination from "@mui/material/Pagination";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";

interface AllegatiGeneraliCerPageProps {
    comunitaEnergetica: ComunitaEnergetica;
}

const AllegatiGeneraliCerPage = ({comunitaEnergetica}: AllegatiGeneraliCerPageProps) => {
        const dispatch = useAppDispatch();

        const [page, setPage] = useState<number>(1);
        const [numeroRecord, setNumeroRecord] = useState<number>(10);
        const [search, setSearch] = useState<string | undefined>(undefined);
        const [anchorTipoDocumentoFilter, setAnchorTipoDocumentoFilter] =
            useState(null);

        const handleChangeNumeroRecord = (event: SelectChangeEvent) => {
            setNumeroRecord(Number(event.target.value));
        };

        const handleStatoFilterClose = () => {
            setAnchorTipoDocumentoFilter(null);
            // onClose(selectedValue);
        };

        const allegatoCorrente: AllegatiState | undefined = useSelector<
            RootState,
            AllegatiState | undefined
        >((state) => state.allegati.allegatoCorrente);

        const allegatiGeneraliCer: AllegatiGeneraliCer = useSelector<RootState, AllegatiGeneraliCer>(
            (state) => state.comunitaEnergeticheState.allegatiGeneraliCer
        );

        useEffect(() => {
            dispatch(fetchAllegatiGeneraliCer({
                comunitaEnergetica: comunitaEnergetica,
                numeroRecord: numeroRecord,
                page: page,
                search: search,
            }));

        }, [
            numeroRecord,
            page,
            search,
            allegatoCorrente?.errorsStack.status,
        ]);

        const handleUploadAllegato = (
            allegato: Allegato,
            selectedFile: File | null
        ) => {
            selectedFile &&
            dispatch(
                uploadAllegato({
                    allegato: allegato,
                    file: selectedFile,
                })
            );
        };

        const handlerPager = (event: React.ChangeEvent<unknown>, page: number) => {
            setPage(page);
        };

        return (
            <Box>
                <List>
                    <ListItemText
                        key={"Lista_saldo_pallet_committente"}
                        sx={{backgroundColor: "#1b75b1", color: "#ddd", mb: 1}}
                    >
                        <Grid container spacing={{xs: 0.5, md: 1, lg: 2}} sx={{px: 2}}>
                            <Grid item xs={12} sm={12} md={6} lg={5}>
                                <Typography variant="h5" gutterBottom>
                                    Tipo documento
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={7}>
                                <Typography variant="h5" gutterBottom>
                                    Descrizione
                                </Typography>
                            </Grid>
                        </Grid>
                    </ListItemText>

                    {allegatiGeneraliCer.results.map((allegato) => (
                        <ListItemButton
                            key={"allegato_" + allegato.id}
                            component={Link}
                            to={allegato.file}
                            target="_blank"
                            sx={{color: "black", textDecoration: "none"}}
                        >
                            <Grid container spacing={{xs: 0.5, md: 1, lg: 2}} sx={{mb: 1}}>
                                <Grid item xs={12} sm={12} md={6} lg={5}>
                                    <Typography variant="body1" gutterBottom>
                                        {allegato.tipo_documento_des?.nome}
                                    </Typography>
                                    <Typography variant="caption" gutterBottom>
                                        {allegato.tipo_documento_des?.descrizione}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={7}>
                                    <Typography variant="body1" gutterBottom noWrap>
                                        {allegato.descrizione}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </ListItemButton>
                    ))}

                    {/*<ListItem>*/}
                    {/*    {iterConnessione_id && (*/}
                    {/*        <AllegatiUploadForm*/}
                    {/*            allegato={{*/}
                    {/*                id: null,*/}
                    {/*                tipo_documento: null,*/}
                    {/*                descrizione: "",*/}
                    {/*                iter_connessione: iterConnessione_id,*/}
                    {/*                // progetto: progettoId,*/}
                    {/*                // richiesta_connessione: richiestaConnessioneId,*/}
                    {/*                autore: "",*/}
                    {/*                file: "",*/}
                    {/*                file_name: "",*/}
                    {/*            }}*/}
                    {/*            tipiDocumento={tipiDocumento}*/}
                    {/*            uploadAllegato={handleUploadAllegato}*/}
                    {/*            uploading={*/}
                    {/*                allegatoCorrente?.errorsStack.status == ErrorStatusTypes.PENDING*/}
                    {/*            }*/}
                    {/*        />*/}
                    {/*    )}*/}
                    {/*</ListItem>*/}
                    <ListItem
                        sx={{
                            alignContent: "right",
                            width: "100%",
                            mt: 2,
                            borderTopStyle: "solid",
                            borderTopWidth: 1,
                        }}
                    >
                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            spacing={2}
                            sx={{width: "100%"}}
                        >
                            <Pagination
                                count={allegatiGeneraliCer.num_pages}
                                onChange={handlerPager}
                                page={page}
                            />
                            {/* {statoIterId || clienteIterId ? (
							<Typography noWrap>
								Totale documenti filtrati:{"  "}
								{allegati.count}
							</Typography>
						) : ( */}
                            <Typography noWrap>
                                Totale iter:{"  "}
                                {allegatiGeneraliCer.count}
                            </Typography>
                            {/* )} */}

                            <TextField
                                id="input-with-icon-textfield"
                                // label="TextField"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="end">
                                            <SearchIcon/>
                                        </InputAdornment>
                                    ),
                                }}
                                variant="standard"
                                value={search}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    setSearch(event.target.value);
                                }}
                            />
                            <FormControl>
                                <InputLabel id="demo-simple-select-label">Record</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={numeroRecord.toString()}
                                    label="Age"
                                    onChange={handleChangeNumeroRecord}
                                    variant="outlined"
                                >
                                    <MenuItem value={10}>10</MenuItem>
                                    <MenuItem value={20}>20</MenuItem>
                                    <MenuItem value={50}>50</MenuItem>
                                </Select>
                            </FormControl>
                        </Stack>
                    </ListItem>
                </List>
            </Box>
        );
    }
;

export default AllegatiGeneraliCerPage;
