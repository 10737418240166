import React, {useState} from "react";

import {useAppDispatch} from "../../../../redux/store";

import ConfermaAnnulla from "../../../common/ConfermaAnnulla";

import {Commessa, saveCommessa} from "../commesseSlice";

import SoggettoPage from "../../../anagrafiche/soggetti/SoggettoPage";
import Button from "@mui/material/Button";
import {Box} from "@mui/system";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import AllegatiPage from "../../../allegati/AllegatiPage";

interface TitolareTabProps {
    commessa: Commessa;
    consentiModifica?: boolean;
}

const TitolareTab = ({commessa, consentiModifica}: TitolareTabProps) => {
    const dispatch = useAppDispatch();

    // gestione chiusura modal su scelta "Annulla"
    const [openConferma, setOpenConferma] = useState(false);
    const handleConfermaClose = () => setOpenConferma(false);

    return (
        <Box
            sx={{
                backgroundColor: "#fff",
                elevation: 20,
                p: 2,
            }}
        >
            {commessa?.contraente == commessa.titolare_immobile ? (
                <Typography variant="h4" sx={{mt: 2}}>
                    Uguale a Contraente
                </Typography>
            ) : (
                <SoggettoPage
                    soggetto_id={commessa?.titolare_immobile}
                    commessa_id={commessa.id}
                    come_titolare={true}
                    consentiModifica={consentiModifica}
                />
            )}
            <ConfermaAnnulla
                handleAnnulla={handleConfermaClose}
                handleConferma={() => {
                    !!commessa &&
                    dispatch(
                        saveCommessa({
                            commessaToSave: {...commessa, titolare_immobile: null},
                        })
                    );
                    handleConfermaClose();
                }}
                domanda={
                    "Sicuro di voler sganciare il Titolare dell'immobile da questa commessa"
                }
                open={openConferma}
            />
            {!!commessa?.titolare_immobile && (
                <Stack
                    direction="row"
                    spacing={2}
                    sx={{mt: 2}}
                    justifyContent="flex-end"
                >
                    <Button
                        onClick={() => setOpenConferma(true)}
                        disabled={!consentiModifica}
                    >
                        Cambia Titolare
                    </Button>
                </Stack>
            )}

            {commessa?.contraente != commessa.titolare_immobile && (
                <AllegatiPage consentiModifica={consentiModifica || false} commessaId={commessa.id} faseCommessa={'TIT'}/>
            )}
        </Box>
    );
};

export default TitolareTab;
