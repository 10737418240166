import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import {
	ErrorStatusTypes,
	ErrorsStack,
	parseErrorMessage,
} from "../../common/errorsDeclarations";
import * as scadenzeApi from "../../../api/scadenze/scadenzeApi";

import { toast } from "react-toastify";
import { DocumentiPerTipo } from "../../allegati/allegatiSlice";
import { getIterConnessione } from "../iterConnessioni/iterConnessioneSlice";

// import { useDispatch } from "react-redux";



export interface Scadenza {
	id?: number;
	iter_connessione?: number;
	cliente_id?: number;
	cliente_desc: string;
	produttore_id?: number;
	produttore_desc: string;
	alaccio_data?: string | Date;
	data_ultima_taratura_spi?: string | Date;
	data_ultima_taratura_contatori?: string | Date;
	potenza_nominale?: number;
	scadenza_spi?: string | Date;
	scadenza_contatori?: string | Date;
}

export interface ScadenzeState {
	count: number;
	page: number;
	num_pages: number;
	next?: URL;
	previous?: URL;
	results: Scadenza[];
	errorsStack: ErrorsStack;
}

export interface ScadenzeStrutturaState {
	scadenze: ScadenzeState;
}

const initialState: ScadenzeStrutturaState = {
	scadenze: {
		count: 0,
		page: 0,
		num_pages: 0,
		next: undefined,
		previous: undefined,
		results: [],
		errorsStack: { status: ErrorStatusTypes.OK },
	},
};

export const fetchScadenze = createAsyncThunk(
	"scadenze/fetchScadenze",
	async (parametri: {
		giorni_previsione?: number;
		clienteIter?: number;
		numeroRecord?: number;
		page?: number;
		search?: string;
	}) => {
		return await scadenzeApi.fetchScadenze(
			parametri.giorni_previsione,
			parametri.clienteIter,
			parametri.numeroRecord,
			parametri.page,
			parametri.search
		);
	}
);

export const scadenzeSlice = createSlice({
	name: "scadenzeState",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// fetch Richieste Connessioni
		builder.addCase(fetchScadenze.pending, (state, action) => {
			state.scadenze.errorsStack = {
				status: ErrorStatusTypes.PENDING,
			};
		});
		builder.addCase(fetchScadenze.fulfilled, (state, action) => {
			state.scadenze = action.payload;
			state.scadenze.errorsStack = { status: ErrorStatusTypes.OK };
		});
		builder.addCase(fetchScadenze.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");
			state.scadenze.errorsStack = {
				status: ErrorStatusTypes.ERROR,
				fieldsErrors: JSON.parse(action?.error?.message || ""),
			};
		});
		////////////////////////////////////////////////////////////////////
	},
});

// Action creators are generated for each case reducer function
export const {} = scadenzeSlice.actions;

export const scadenzeSliceReducer = scadenzeSlice.reducer;
