import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {
    ErrorStatusTypes,
    ErrorsStack,
    parseErrorMessage,
} from "../common/errorsDeclarations";

import * as allegatiApi from "../../api/allegati/allegatiApi";
import {toast} from "react-toastify";
import {
    getIterConnessione,
} from "../main/iterConnessioni/iterConnessioneSlice";
import {getProgettoFromIter} from "../main/iterConnessioni/iterConnessione/progetti/progettiSlice";
import {
    getRichiestaConnessione
} from "../main/iterConnessioni/iterConnessione/richiesteConnessione/richiesteConnessioneSlice";
import {getFineLavoro} from "../main/iterConnessioni/iterConnessione/richiesteConnessione/fineLavori/fineLavoriSlice";
import {getAllaccio} from "../main/iterConnessioni/iterConnessione/richiesteConnessione/allaccio/allaccioSlice";
import {getEnea} from "../main/iterConnessioni/iterConnessione/enea/eneaSlice";
import {getOfficinaElettrica} from "../main/iterConnessioni/iterConnessione/officinaElettrica/officinaElettricaSlice";
import {
    getAutorizzazioneComunale
} from "../main/iterConnessioni/iterConnessione/autorizzazioneComunale/autorizzazioneComunaleSlice";
import {getCommessa} from "../main/commesse/commesseSlice";
import {
    getSopralluogo,
    getSopralluogoElettrico,
} from "../main/sopralluoghi/sopralluoghiSlice";

export enum TipoFaseIterConnessioneTypes {
    PRD = "Inserimento Produttore",
    USF = "Inserimento Usufruttore",
    POD = "Inserimento POD",
    AVV = "Avvio Pratica Iter Connessione",
    PRJ = "Progetto",
    DDC = "Domanda di Connessione",
    PRC = "Preventivo Richiesta Connessione",
    PAG = "Pagamento Richiesta Connessione",
    IRC = "Inoltro Richiesta Connessione",
    CRC = "Conferma Richiesta Connessione",
    FIL = "Fine Lavori",
    GAU = 'Richiesta Gaudì',
    ITA = 'Iter Autorizzativo',
    RDE = 'Regolamento di esercizio',
    ALL = "Allaccio",
    ENE = "Enea",
    OFE = "Officina Elettrica",
    AUC = "Autorizzazione Comunale",
    SOP = "Sopralluogo",
}

export enum TipoFaseCommessaTypes {
    CTR = "Inserimento Contraente",
    TIT = "Inserimento Titolare",
    PRD = "Inserimento Produttore",
    POD = "Inserimento POD",
    MAT = "Inserimento Materiali",
    ANP = 'Analisi Preliminari',
    PCA = 'Preparazione Cantiere',
    EXC = 'Fase Cantiere',
}

export enum TipoCasiVisibiliTypes {
    PF = "Visibile solo per persone fisiche",
    PG = "Visibile solo per persone giuridiche",
}

export enum TipoCasiObbligoTypes {
    PF = "Obbligatorio solo per persone fisiche",
    PG = "Obbligatorio solo per persone giuridiche",
}

export enum TipoCasiIterTypes {
    S = "Solo per impianto nuovo",
    //  iter semplificati === impiaanto nuovo
    O = "Solo per ampliamento impianto",
    //  iter ordinario === apliamento impianto
}

export enum TipoVibilitaClienteTypes {
    NO = "non visibile",
    VC = "visibile solo alla consegna",
    SV = "sempre visibile",
    MO = "modificabile",
}

export interface TipoDocumento {
    id: number;
    nome: string;
    sigla?: string;
    descrizione?: string;
    fase_iter_connessione?: TipoFaseIterConnessioneTypes;
    obbligatorio_iter?: boolean;
    casi_visibili?: TipoCasiVisibiliTypes;
    casi_obbligo?: TipoCasiObbligoTypes;
    casi_iter?: TipoCasiIterTypes;
    visibilita_cliente?: TipoVibilitaClienteTypes;
    fase_commessa?: TipoFaseCommessaTypes;
    obbligatorio_commessa?: boolean;
    errorsStack?: ErrorsStack;
}

export interface TipiDocumento {
    count: number;
    page: number;
    num_pages: number;
    next?: URL;
    previous?: URL;
    results: TipoDocumento[];
    errorsStack: ErrorsStack;
}

export interface Allegato {
    id: number | null;
    tipo_documento: number | null;
    tipo_documento_des?: TipoDocumento;
    descrizione: string;
    iter_connessione?: number | null;
    progetto?: number | null;
    richiesta_connessione?: number | null;
    fine_lavori?: number | null;
    allaccio?: number | null;
    enea?: number | null;
    officina_elettrica?: number | null;
    autorizzazione_comunale?: number | null;
    sopralluogo?: number | null;
    sopralluogo_elettrico?: number | null;
    commessa?: number | null;
    autore: string;
    file: string;
    file_name: string;
    data_documento?: Date | string;
}

export interface Allegati {
    count: number;
    page: number;
    num_pages: number;
    next?: URL;
    previous?: URL;
    results: Allegato[];
    errorsStack: ErrorsStack;
}

export enum TipoObbligoTypes {
    OI = "Obbligatorio per iniziare",
    OC = "Obbligatorio per completare",
}

export interface DocumentiPerTipo {
    tipo_id: number | null;
    tipo: string;
    descrizione?: string;
    modificabile: boolean;
    obbligo_tipo: boolean;
    tipo_obbligo: "OI" | "OC";
    casi_obbligo: "PF" | "PG";
    allegati_presenti: Allegato[];
}

export interface DocumentiIterPerFase {
    fase_iter_connessione: string;
    iter_connessione_id: number;
    lista_documenti_per_tipo?: DocumentiPerTipo[];
    lista_completa: boolean;
}

export interface DocumentiCommessaPerFase {
    fase_commessa: string;
    commessa_id: number;
    lista_documenti_per_tipo?: DocumentiPerTipo[];
    lista_completa: boolean;
}

export interface AllegatiState {
    allegato: Allegato;
    errorsStack: ErrorsStack;
}

export interface AllegatiStrutturaState {
    tipiDocumento: TipiDocumento;
    allegati: Allegati;
    allegatoCorrente: AllegatiState;
}

const initialState: AllegatiStrutturaState = {
    tipiDocumento: {
        count: 0,
        page: 0,
        num_pages: 0,
        next: undefined,
        previous: undefined,
        results: [],
        errorsStack: {status: ErrorStatusTypes.OK},
    },
    allegati: {
        count: 0,
        page: 0,
        num_pages: 0,
        next: undefined,
        previous: undefined,
        results: [],
        errorsStack: {status: ErrorStatusTypes.OK},
    },
    allegatoCorrente: {
        allegato: {
            id: null,
            tipo_documento: null,
            descrizione: "",
            iter_connessione: null,
            autore: "",
            file: "",
            file_name: "",
        },
        errorsStack: {status: ErrorStatusTypes.OK},
    },
};

export const fetchTipiDocumento = createAsyncThunk(
    "allegato/fetchTipiDocumento",
    async () => {
        return await allegatiApi.fetchTipiDocumento();
        //   return user;
    }
);

export const getTipoDocumento = createAsyncThunk(
    "allegato/getTipoDocumento",
    async (tipoDocumentoId: number) => {
        return await allegatiApi.getTipoDocumento(tipoDocumentoId);
    }
);

export const saveTipoDocumento = createAsyncThunk(
    "allegato/saveTipoDocumento",
    async (
        parametri: {
            tipoDocumentoToSave: TipoDocumento;
            azione?: string;
        },
        thunkApi
    ) => {
        return await allegatiApi.saveTipoDocumento(
            parametri.tipoDocumentoToSave,
            parametri.azione
        );
    }
);

export const fetchAllegati = createAsyncThunk(
    "allegato/fetchAllegati",
    async (filtriAllegati: {
        iterConnessione_id?: number | null;
        tipo_documento_id?: number;
        numeroRecord?: number;
        page?: number;
        search?: string;
    }) => {
        return await allegatiApi.fetchAllegati(
            filtriAllegati.iterConnessione_id,
            filtriAllegati.tipo_documento_id,
            filtriAllegati.numeroRecord,
            filtriAllegati.page,
            filtriAllegati.search
        );
        //   return user;
    }
);

export const uploadAllegato = createAsyncThunk(
    "allegato/uploadAllegato",
    async (
        parametriAllegato: {
            allegato: Allegato;
            file: File;
            faseIterConnessione?: string;
            faseCommessa?: string;
        },
        thunkApi
    ) => {
        const iterConnessione_id = parametriAllegato.allegato.iter_connessione;
        const faseIterConnessione = parametriAllegato.faseIterConnessione;
        const richiestaConnessione_id = parametriAllegato.allegato.richiesta_connessione;
        const fine_lavori_id = parametriAllegato.allegato.fine_lavori;
        const allaccio_id = parametriAllegato.allegato.allaccio;
        const enea_id = parametriAllegato.allegato.enea;
        const officina_elettrica_id = parametriAllegato.allegato.officina_elettrica;
        const autorizzazione_comunale_id = parametriAllegato.allegato.autorizzazione_comunale;
        const sopralluogo_id = parametriAllegato.allegato.sopralluogo;
        const sopralluogoElettrico_id = parametriAllegato.allegato.sopralluogo_elettrico;
        const commessa_id = parametriAllegato.allegato.commessa;
        const faseCommessa = parametriAllegato.faseCommessa;
        return await allegatiApi
            .uploadAllegato(parametriAllegato.allegato, parametriAllegato.file)
            .then((response) => {
                iterConnessione_id &&
                thunkApi.dispatch(
                    getIterConnessione({
                        iterConnessioneId: iterConnessione_id,
                        faseIterConnessione,
                    })
                );
                iterConnessione_id && thunkApi.dispatch(getProgettoFromIter(iterConnessione_id));
                richiestaConnessione_id && thunkApi.dispatch(getRichiestaConnessione(richiestaConnessione_id));
                fine_lavori_id && thunkApi.dispatch(getFineLavoro(fine_lavori_id));
                allaccio_id && thunkApi.dispatch(getAllaccio(allaccio_id));
                enea_id && thunkApi.dispatch(getEnea(enea_id));
                officina_elettrica_id && thunkApi.dispatch(getOfficinaElettrica(officina_elettrica_id));
                autorizzazione_comunale_id && thunkApi.dispatch(getAutorizzazioneComunale(autorizzazione_comunale_id));
                sopralluogo_id && thunkApi.dispatch(getSopralluogo(sopralluogo_id));
                sopralluogoElettrico_id &&
                thunkApi.dispatch(getSopralluogoElettrico(sopralluogoElettrico_id));

                commessa_id && thunkApi.dispatch(
                    getCommessa({
                        commessaId: commessa_id, faseCommessa
                    })
                );
                return response;
            });
    }
);

export const deleteAllegato = createAsyncThunk(
    "schedaCarico/deleteAllegato",
    async (
        parametri: { allegatoToDelete: Allegato; faseIterConnessione?: string; faseCommessa?: string },
        thunkApi
    ) => {
        const iterConnessione_id = parametri.allegatoToDelete.iter_connessione;

        const richiestaConnessione_id =
            parametri.allegatoToDelete.richiesta_connessione;
        const fine_lavori_id = parametri.allegatoToDelete.fine_lavori;
        const allaccio_id = parametri.allegatoToDelete.allaccio;
        const enea_id = parametri.allegatoToDelete.enea;
        const officina_elettrica_id = parametri.allegatoToDelete.officina_elettrica;
        const autorizzazione_comunale_id =
            parametri.allegatoToDelete.autorizzazione_comunale;
        const sopralluogo_id = parametri.allegatoToDelete.sopralluogo;
        const sopralluogoElettrico_id =
            parametri.allegatoToDelete.sopralluogo_elettrico;
        const commessaId = parametri.allegatoToDelete.commessa

        return await allegatiApi
            .deleteAllegato(parametri.allegatoToDelete)
            .then((response) => {
                iterConnessione_id &&
                thunkApi.dispatch(
                    getIterConnessione({
                        iterConnessioneId: iterConnessione_id,
                        faseIterConnessione: parametri.faseIterConnessione,
                    })
                );

                commessaId &&
                thunkApi.dispatch(
                    getCommessa({
                        commessaId: commessaId,
                        faseCommessa: parametri.faseCommessa,
                    })
                )

                iterConnessione_id &&
                thunkApi.dispatch(getProgettoFromIter(iterConnessione_id));
                richiestaConnessione_id &&
                thunkApi.dispatch(getRichiestaConnessione(richiestaConnessione_id));
                fine_lavori_id && thunkApi.dispatch(getFineLavoro(fine_lavori_id));
                allaccio_id && thunkApi.dispatch(getAllaccio(allaccio_id));
                enea_id && thunkApi.dispatch(getEnea(enea_id));

                officina_elettrica_id &&
                thunkApi.dispatch(getOfficinaElettrica(officina_elettrica_id));

                autorizzazione_comunale_id &&
                thunkApi.dispatch(
                    getAutorizzazioneComunale(autorizzazione_comunale_id)
                );

                sopralluogo_id && thunkApi.dispatch(getSopralluogo(sopralluogo_id));
                sopralluogoElettrico_id &&
                thunkApi.dispatch(getSopralluogoElettrico(sopralluogoElettrico_id));

                return response;
            });
    }
);

export const allegatiSlice = createSlice({
    name: "allegatiSlice",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        // fetch tipiDocumento
        builder.addCase(fetchTipiDocumento.pending, (state, action) => {
            // state.tipiDocumento.errorsStack = { status: ErrorStatusTypes.PENDING };
        });
        builder.addCase(fetchTipiDocumento.fulfilled, (state, action) => {
            state.tipiDocumento = action.payload;
            // riordinati per id decrescente
            state.tipiDocumento.results = state.tipiDocumento.results.sort(
                (a, b) => b.id - a.id
            );
            // state.tipiDocumento.errorsStack = { status: ErrorStatusTypes.OK };
        });
        builder.addCase(fetchTipiDocumento.rejected, (state, action) => {
            toast.error("Errore:" + action?.error?.message || "");
            // state.tipiDocumento.errorsStack = {
            //   status: ErrorStatusTypes.ERROR,
            //   fieldsErrors: JSON.parse(action?.error?.message || ""),
            // };
        });

        // fetch tipoDocumento
        builder.addCase(getTipoDocumento.pending, (state, action) => {
            state.allegatoCorrente.errorsStack = {status: ErrorStatusTypes.PENDING};
        });
        builder.addCase(getTipoDocumento.fulfilled, (state, action) => {
            state.tipiDocumento.results = state.tipiDocumento.results.filter(
                (tipoDocumento) => tipoDocumento.id != action.payload.id
            );
            state.tipiDocumento.results.push({
                ...action.payload,
                errorsStack: {status: ErrorStatusTypes.OK},
            });
            state.tipiDocumento.results = state.tipiDocumento.results.sort(
                (a, b) => b.id - a.id
            );
        });
        builder.addCase(getTipoDocumento.rejected, (state, action) => {
            state.allegatoCorrente.errorsStack = parseErrorMessage(
                action?.error?.message || ""
            );
        });

        // save tipoDocumento
        builder.addCase(saveTipoDocumento.pending, (state, action) => {
            state.tipiDocumento.errorsStack = {status: ErrorStatusTypes.PENDING};
        });
        builder.addCase(saveTipoDocumento.fulfilled, (state, action) => {
            state.tipiDocumento.results = state.tipiDocumento.results.filter(
                (tipoDocumento) => tipoDocumento.id != action.payload.id
            );
            state.tipiDocumento.results.push({
                ...action.payload,
                errorsStack: {status: ErrorStatusTypes.SUCCESS},
            });
            state.tipiDocumento.errorsStack = {status: ErrorStatusTypes.SUCCESS};
            state.tipiDocumento.results = state.tipiDocumento.results.sort(
                (a, b) => b.id - a.id
            );
        });
        builder.addCase(saveTipoDocumento.rejected, (state, action) => {
            state.tipiDocumento.results = state.tipiDocumento.results.map(
                (tipoDocumento) => {
                    if (tipoDocumento.id == action.meta.arg.tipoDocumentoToSave.id) {
                        return {
                            ...tipoDocumento,
                            errorsStack: parseErrorMessage(action?.error?.message || ""),
                        };
                    }
                    return tipoDocumento;
                }
            );
        });

        // fetch Allegati
        builder.addCase(fetchAllegati.pending, (state, action) => {
            state.allegati.errorsStack = {status: ErrorStatusTypes.PENDING};
        });
        builder.addCase(fetchAllegati.fulfilled, (state, action) => {
            // state.allegati.results = [
            // 	...state.allegati.results.filter(
            // 		(allegato) =>
            // 			allegato.iter_connessione != action.meta.arg.iterConnessione_id
            // 	),
            // 	...action.payload.results,
            // ];
            state.allegati = action.payload;

            state.allegati.errorsStack = {status: ErrorStatusTypes.OK};
        });
        builder.addCase(fetchAllegati.rejected, (state, action) => {
            toast.error("Errore:" + action?.error?.message || "");
            state.allegati.errorsStack = parseErrorMessage(
                action?.error?.message || ""
            );
        });
        // fetch Allegati
        builder.addCase(deleteAllegato.pending, (state, action) => {
            state.allegati.errorsStack = {status: ErrorStatusTypes.PENDING};
            state.allegatoCorrente.errorsStack = {status: ErrorStatusTypes.PENDING};
        });
        builder.addCase(deleteAllegato.fulfilled, (state, action) => {
            state.allegati.results = state.allegati.results.filter(
                (allegato) => allegato.id != action.meta.arg.allegatoToDelete.id
            );
            toast.success(action.payload.message || "Allegato cancellato.");
            state.allegati.errorsStack = {status: ErrorStatusTypes.OK};
            state.allegatoCorrente.errorsStack = {status: ErrorStatusTypes.OK};
        });
        builder.addCase(deleteAllegato.rejected, (state, action) => {
            // toast.error("Errore:" + action?.error?.message || "");
            state.allegati.errorsStack = parseErrorMessage(
                action?.error?.message || ""
            );
            state.allegatoCorrente.errorsStack = parseErrorMessage(
                action?.error?.message || ""
            );
        });
        // carica Documento
        builder.addCase(uploadAllegato.pending, (state, action) => {
            state.allegatoCorrente.errorsStack = {status: ErrorStatusTypes.PENDING};
        });
        builder.addCase(uploadAllegato.fulfilled, (state, action) => {
            state.allegatoCorrente.errorsStack = {status: ErrorStatusTypes.OK};
            toast.success("Allegato inserito.");
        });
        builder.addCase(uploadAllegato.rejected, (state, action) => {
            state.allegatoCorrente.errorsStack = {status: ErrorStatusTypes.ERROR};
            toast.error("Errore:" + action?.error?.message || "");
            try {
                const errorMessage = JSON.parse(
                    (action?.error?.message || "").replace(/^Error: /, "")
                );

                state.allegatoCorrente.errorsStack = {
                    status: ErrorStatusTypes.ERROR,
                    fieldsErrors: errorMessage,
                };
            } catch {
                console.log("json errato = ", action?.error?.message);
            }
        });
    },
});

// Action creators are generated for each case reducer function
export const {} = allegatiSlice.actions;

export const allegatiSliceReducer = allegatiSlice.reducer;
