import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { useAppDispatch, RootState } from "../../../../../redux/store";
import { useNavigate } from "react-router-dom";

import { useForm, SubmitHandler, Controller } from "react-hook-form";
import {
	IterConnessione,
	saveIterConnessione,
	resetNuovoIterConnessioneId,
} from "../../../../main/iterConnessioni/iterConnessioneSlice";
import {
	Sopralluogo,
	getSopralluogo,
	StatoSopralluogoTypes,
	AccessoTettoTypes,
	TipoTettoTypes,
	TipoCoperturaTypes,
	StatoCoperturaTypes,
	saveSopralluogo,
	TipoStaffaTypes,
} from "../../sopralluoghiSlice";
import { Routes_path } from "../../../../routerApp";
import IterConnessioniPage from "../../../../main/iterConnessioni/IterConnessioniPage";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";

import Modal from "@mui/material/Modal";

interface AccoppiaIterSopralluogoProps {
	openRicercaIter: boolean;
	setOpenRicercaIter: (value: boolean) => void;
	sopralluogo: Sopralluogo;
}

const AccoppiaIterSopralluogo = ({
	openRicercaIter,
	setOpenRicercaIter,
	sopralluogo,
}: AccoppiaIterSopralluogoProps) => {
	const dispatch = useAppDispatch();
	const handleAccoppiaSopralluogo = (iter: IterConnessione) => {
		iter.id &&
			dispatch(
				saveSopralluogo({
					sopralluogoToSave: { ...sopralluogo, iter_connessione: iter.id },
				})
			);
		setOpenRicercaIter(false);
	};
	const navigate = useNavigate();

	const handleClose = () => setOpenRicercaIter(false);

	const style = {
		position: "absolute" as "absolute",
		top: "30%",
		left: "10%",
		transform: "translate(-5%, -10%)",
		width: "90%",
		bgcolor: "background.paper",
		border: "2px solid #666",
		boxShadow: 24,
		p: 4,
	};
	return (
		<Box sx={{ my: 1, pt: 2 }}>
			<Modal
				open={openRicercaIter}
				onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box
					sx={{
						position: "absolute" as "absolute",
						top: "50%",
						left: "50%",
						transform: "translate(-50%, -50%)",
						width: "90%",
						height: "80%",
						bgcolor: "background.paper",
						border: "2px solid #000",
						boxShadow: 24,
						pt: 2,
						px: 4,
						pb: 3,
						overflowY: "auto",
					}}
				>
					<IterConnessioniPage
						openRicercaIter={true}
						azioneSuIter={handleAccoppiaSopralluogo}
					/>
				</Box>
			</Modal>
		</Box>
	);
};

export default AccoppiaIterSopralluogo;
