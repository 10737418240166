import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch, RootState } from "../../../../../../redux/store";
import {
	ErrorStatusTypes,
} from "../../../../../common/errorsDeclarations";

import { theme } from "../../../../../theme";
import { convertToDateTime } from "components/common/dateUtils/convertToDateTime";

import {
	UtenteLogged,
	UtenteApplicativo,
} from "../../../../../authentication/authenticationSlice";
import {
	Commessa,
	saveCommessa,
	TipoIntervento,
} from "../../../commesseSlice";

import CircularProgress from "@mui/material/CircularProgress";
import { useForm, SubmitHandler,  } from "react-hook-form";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { Alert } from "@mui/material";

interface AvvisoMerceProntaFormProps {
	commessa: Commessa;
	consentiModifica: boolean;
}

const AvvisoMerceProntaForm = ({
	commessa,
	consentiModifica = false,
}: AvvisoMerceProntaFormProps) => {
	const {
		handleSubmit,
		formState: { errors, isDirty, isValid },
		setValue,
		watch,
		getValues,
		setError,
		clearErrors,
		reset,
	} = useForm<Commessa>();

	const listaCampi = Object.keys(commessa) as Array<keyof Commessa>;

	const dispatch = useAppDispatch();

	const commesseStatus = useSelector<RootState, ErrorStatusTypes>(
		(state) => state.commesseState.commesse.errorsStack.status
	);

	useEffect(() => {
		reset(commessa);
	}, [commessa, reset]);

	useEffect(() => {
		clearErrors();
		listaCampi.forEach((field) => {
			commessa?.errorsStack?.fieldsErrors &&
				commessa.errorsStack.fieldsErrors[field] &&
				setError(field, {
					type: "server",
					message: commessa.errorsStack.fieldsErrors[field].toString(),
				});
		});
	}, [
		commessa?.errorsStack?.fieldsErrors,
		// cliente.allevamento_pack?.errorsStack?.fieldsErrors,
	]);

	const handlerSaveCommessa = (commessa: Commessa) => {
		consentiModifica &&
			dispatch(
				saveCommessa({
					commessaToSave: commessa,
				})
			);
	};

	const onSubmit: SubmitHandler<Commessa> = (commessa) => {
		handlerSaveCommessa(commessa);
	};

	useEffect(() => {
		if (isValid && isDirty) {
			const formValues = getValues();
			handlerSaveCommessa(formValues);
		}
	}, [
		watch("data_pronta_per_fattura_amp"),
		watch("data_fattura_amp"),
		watch("data_fattura_amp_pagata"),
		watch("data_cantiere_confermata"),
	]);

	return (
		<Box sx={{ mb: 1 }}>
			<form onSubmit={handleSubmit(onSubmit)}>
				{commessa?.errorsStack?.fieldsErrors?.non_field_errors && (
					<Alert severity="error">
						{commessa?.errorsStack?.fieldsErrors?.non_field_errors.toString()}
					</Alert>
				)}
				<Grid container spacing={2} width={"100%"} sx={{ mb: 1 }}>
					{/*<Grid item xs={12} sm={6} lg={2}>*/}
					{/*	<DesktopDatePicker*/}
					{/*		label="data maturazione fatt. AMP"*/}
					{/*		value={convertToDateTime(commessa.data_pronta_per_fattura_amp)}*/}
					{/*		disabled={!consentiModifica}*/}

					{/*		slotProps={{*/}
					{/*			textField: {*/}
					{/*				required: false,*/}
					{/*				error: !!errors?.data_pronta_per_fattura_amp,*/}
					{/*				helperText: errors?.data_pronta_per_fattura_amp?.message,*/}
					{/*				sx: { width: "100%" },*/}
					{/*				autoComplete: "off",*/}
					{/*				// disabled: !!richiestaConnessione.stato*/}
					{/*			},*/}
					{/*			field: {*/}
					{/*				clearable: true,*/}
					{/*			},*/}
					{/*		}}*/}
					{/*		onChange={(newValue: any) => {*/}
					{/*			setValue(*/}
					{/*				"data_pronta_per_fattura_amp",*/}
					{/*				newValue ? convertToDateTime(newValue)?.toFormat("yyyy-MM-dd") : "",*/}
					{/*				{*/}
					{/*					shouldDirty: true,*/}
					{/*				}*/}
					{/*			);*/}
					{/*			clearErrors("data_pronta_per_fattura_amp");*/}
					{/*		}}*/}
					{/*	/>*/}
					{/*</Grid>*/}
					<Grid item xs={12} sm={6} lg={2}>
						<DesktopDatePicker
							label="data fatt. AMP"
							value={convertToDateTime(commessa.data_fattura_amp)}

							disabled={!consentiModifica}

							slotProps={{
								textField: {
									required: false,
									error: !!errors?.data_fattura_amp,
									helperText: errors?.data_fattura_amp?.message,
									sx: { width: "100%" },
									autoComplete: "off",
									// disabled: !!richiestaConnessione.stato
								},
								field: {
									clearable: true,
								},
							}}
							onChange={(newValue: any) => {
								setValue(
									"data_fattura_amp",
									newValue ? convertToDateTime(newValue)?.toFormat("yyyy-MM-dd") : "",
									{
										shouldDirty: true,
									}
								);
								clearErrors("data_fattura_amp");
							}}
						/>
					</Grid>
					<Grid item xs={12} sm={6} lg={2}>
						<DesktopDatePicker
							label="data pagamento AMP"
							value={convertToDateTime(commessa.data_fattura_amp_pagata)}
							disabled={!consentiModifica}

							slotProps={{
								textField: {
									required: false,
									error: !!errors?.data_fattura_amp_pagata,
									helperText: errors?.data_fattura_amp_pagata?.message,
									sx: { width: "100%" },
									autoComplete: "off",
									// disabled: !!richiestaConnessione.stato
								},
								field: {
									clearable: true,
								},
							}}
							onChange={(newValue: any) => {
								setValue(
									"data_fattura_amp_pagata",
									newValue ? convertToDateTime(newValue)?.toFormat("yyyy-MM-dd") : "",
									{
										shouldDirty: true,
									}
								);
								clearErrors("data_fattura_amp_pagata");
							}}
						/>
					</Grid>
					<Grid item sm={12} lg={6}>
						<Box>
							{(isDirty ||
								commesseStatus == ErrorStatusTypes.SUCCESS ||
								commesseStatus == ErrorStatusTypes.ERROR) && (
								<Typography
									sx={{
										color: isDirty
											? theme.palette.warning.main
											: commesseStatus == ErrorStatusTypes.SUCCESS
											? theme.palette.success.main
											: commesseStatus == ErrorStatusTypes.ERROR
											? theme.palette.error.main
											: theme.palette.warning.main,
									}}
									variant="caption"
								>
									{commesseStatus == ErrorStatusTypes.PENDING && (
										<CircularProgress size={5} />
									)}

									{isDirty
										? "Modifiche non salvate"
										: commesseStatus == ErrorStatusTypes.SUCCESS
										? "Salvato"
										: commesseStatus == ErrorStatusTypes.ERROR
										? "Errore"
										: ""}
								</Typography>
							)}
						</Box>
					</Grid>
					<Grid item sm={12} lg={2}>
						{commessa.stato == "F" &&
						<Button
							variant="contained"
							color="primary"
							disabled={!consentiModifica || !commessa.stato_completato}
							sx={{width: "100%"}}
							onClick={() => {
								dispatch(saveCommessa({
									commessaToSave: commessa,
									azione: "attiva_preparazione_cantiere"
								}))
							}}
						>
							Attiva preparazione CANTIERE
						</Button>
						}
					</Grid>
				</Grid>
			</form>
		</Box>
	);
};

export default AvvisoMerceProntaForm;
