import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, Link, useParams } from "react-router-dom";

import { RootState, useAppDispatch } from "../../../../../redux/store";

import { DateTime } from "luxon";


import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import RouterApp, { Routes_path } from "../../../../routerApp";

import { PermissionTypes } from "../../../../authentication/permissionsGroups";
import { utenteAutorizzato } from "../../../../authentication/UtenteAutorizzato";
import { UtenteLogged } from "../../../../authentication/authenticationSlice";
import {
	ServizioAdempimenti,
	getServizioAdempimenti,
	saveServizioAdempimenti,
} from "../../adempimentiSlice";
import ServizioAdempimentiForm from "./ServizioAdempimentiForm";

import { Cliente } from "../../../../anagrafiche/clienti/clientiSlice";
import { Pod, fetchPods } from "../../../../main/pods/podsSlice";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import ServizioAdempimentiPageBreadcrumbs from "./ServizioAdempimentiPageBreadcrumbs";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import { Button, Grid, ButtonGroup } from "@mui/material";
import {
	ErrorStatusTypes,
	ErrorsStack,
} from "../../../../common/errorsDeclarations";
const ServizioAdempimentiPage = () => {
	
	const dispatch = useAppDispatch();
	const { servizioAdempimenti_id } = useParams();

	const [servizioAdempimentiId, setServizioAdempimentiId] = useState<number>(
		parseInt(servizioAdempimenti_id || "")
	);

	const location = useLocation();
	const utente = useSelector<RootState, UtenteLogged>(
		(state) => state.authentication.utenteLogged
	);

	useEffect(() => {
		servizioAdempimentiId &&
			dispatch(getServizioAdempimenti(servizioAdempimentiId));
	}, []);

	const servizioAdempimenti: ServizioAdempimenti | undefined = useSelector<
		RootState,
		ServizioAdempimenti | undefined
	>((state) =>
		state.adempimentiState.serviziAdempimenti.results.find(
			(servizioAdempimenti) => servizioAdempimenti?.id == servizioAdempimentiId
		)
	);

	useEffect(() => {
		if (servizioAdempimentiId) {
			dispatch(getServizioAdempimenti(servizioAdempimentiId));
		}
	}, [servizioAdempimentiId]);

	const servizioAdempimentiStatus = useSelector<RootState, ErrorStatusTypes>(
		(state) => state.adempimentiState.adempimenti.errorsStack.status
	);

	const cliente: Cliente | undefined = useSelector<
		RootState,
		Cliente | undefined
	>((state) =>
		state.clientiState.clienti.results.find(
			(cliente) => cliente?.id == servizioAdempimenti?.cliente
		)
	);

	const [openRicercaIter, setOpenRicercaIter] = useState(false);

	return (
		<Box
			sx={{
				backgroundColor: "#fff",
				elevation: 20,
				p: 1,
				pr: 4,
			}}
		>
			<ServizioAdempimentiPageBreadcrumbs
				identificativoPosizione={`${servizioAdempimenti?.id}`}
			/>
			<Backdrop
				sx={{
					color: "#fff",
					zIndex: (theme) => theme.zIndex.drawer + 1,
				}}
				open={servizioAdempimentiStatus == ErrorStatusTypes.PENDING}
			>
				<CircularProgress color="inherit" />
			</Backdrop>
			{/* <Stack
				direction="row"
				justifyContent="flex-start"
				alignItems="flex-start"
				spacing={4}
				sx={{ mb: 1 }}
			> */}
			<Grid container spacing={1}>
				<Grid item xs={6} sm={6} md={6} lg={3}>
					<Typography variant="h4" color="text.secondary" gutterBottom>
						ServizioAdempimenti
						<Chip
							color="primary"
							variant="outlined"
							label={servizioAdempimenti?.id}
							sx={{ ml: 1 }}
						/>
					</Typography>
				</Grid>
				<Grid item xs={6} sm={6} md={6} lg={3}>
					<Typography
						variant="h5"
						component={Link}
						to={Routes_path.PRODUTTORE + servizioAdempimenti?.produttore}
					>
						{servizioAdempimenti?.produttore_descrizione}
					</Typography>
				</Grid>
				<Grid item xs={6} sm={6} md={6} lg={3}>
					<Typography variant="h5">
						{servizioAdempimenti?.in_corso ? "In corso" : "Concluso"}
					</Typography>
				</Grid>
			</Grid>
			<Grid container spacing={2} sx={{ mb: 4 }}>
				<Grid item xs={12} sm={12} md={8} lg={9}>
					<Stack spacing={2}>
						{servizioAdempimenti && (
							<ServizioAdempimentiForm
								servizioAdempimenti={servizioAdempimenti}
								// produttore={produttoreCorrente}
								consentiModifica={true}
							/>
						)}
					</Stack>
				</Grid>
			</Grid>
		</Box>
	);
};

export default ServizioAdempimentiPage;
