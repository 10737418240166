import React, {useState} from "react";
import {useSelector} from "react-redux";

import {
    IterConnessione,
} from "../../../iterConnessioneSlice";
import {ProdottiPrevistiPerIntervento, TipoIntervento} from "../../../../commesse/commesseSlice";
import {RootState} from "../../../../../../redux/store";

import ProdottoCard from "./ProdottoCard";
import ProdottoForm from "./ProdottoForm";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import IconButton from "@mui/material/IconButton";
import {
    Commessa,
    Prodotto,
    TipoProdottoTypes,
} from "components/main/commesse/commesseSlice";
import {prodottiPrevistiCommessa} from "../../../../commesse/commessa/prodottiPrevistiCommessa";


const prodottiPrevisti = (iterConnessione?: IterConnessione, commessa?: Commessa, tipiIntervento?: TipoIntervento[]): ProdottiPrevistiPerIntervento[] => {
    if (iterConnessione) {
        const prodottiPrevisti: ProdottiPrevistiPerIntervento[] = []
        Object.entries(TipoProdottoTypes).map(
            ([key, value]) => {
                const tipoProdotto = key as keyof typeof TipoProdottoTypes
                const prodottoEsistente: ProdottiPrevistiPerIntervento = {
                    id: 0,
                    tipo_prodotto: tipoProdotto,
                    esistente: true,
                    obbligatorio: false,
                }
                prodottiPrevisti.push(prodottoEsistente)
                const prodottoNuovo: ProdottiPrevistiPerIntervento = {
                    id: 0,
                    tipo_prodotto: tipoProdotto,
                    esistente: false,
                    obbligatorio: false,
                }
                prodottiPrevisti.push(prodottoNuovo)
            }
        )
        return prodottiPrevisti
    } else if (commessa) {
        return prodottiPrevistiCommessa(commessa, tipiIntervento || [])
    }
    return []
}


interface DistintaProdottiBoxProps {
    podCommessaId: number;
    iterConnessione?: IterConnessione;
    commessa?: Commessa;
    prodotti?: Prodotto[];
    consentiModifica: boolean;
    preEsistente: boolean;
}

const DistintaProdottiBox = ({
                                 podCommessaId,
                                 iterConnessione,
                                 commessa,
                                 prodotti,
                                 consentiModifica = false,
                                 preEsistente,
                             }: DistintaProdottiBoxProps) => {

    const [openInputProdotto, setOpenInputProdotto] = useState(false);
    const [tipoProdotto, setTipoProdotto] = useState<
        "MF" | "IN" | "SA" | "OT" | "PI" | "EV" | "AC" | "XX" | undefined
    >(undefined);

    const tipiIntervento = useSelector<RootState, TipoIntervento[]>(
        (state) => state.commesseState.tipiIntervento.results
    );

    const handleClosInputProdotto = () => {
        setTipoProdotto(undefined);
        setOpenInputProdotto(false);
    };

    return (
        <>
            {prodottiPrevisti(iterConnessione, commessa, tipiIntervento).filter((p) => p.esistente == preEsistente).length > 0 && (
                <Card sx={{mt: 2}}>
                    <CardHeader
                        title={
                            <Typography variant="h5" color="white">
                                Distinta Prodotti {preEsistente ? "Pre-esistenti" : "Nuovi"}
                            </Typography>
                        }
                        sx={{backgroundColor: "#22738e"}}
                    />
                    <CardContent>
                        {tipoProdotto && (
                            <Modal
                                open={openInputProdotto}
                                onClose={handleClosInputProdotto}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                            >
                                <Box
                                    sx={{
                                        position: "absolute",
                                        top: "50%",
                                        left: "50%",
                                        transform: "translate(-50%, -50%)",
                                        width: "70%",
                                        bgcolor: "background.paper",
                                        border: "2px solid #000",
                                        boxShadow: 24,
                                        pt: 2,
                                        px: 4,
                                        pb: 3,
                                    }}
                                >
                                    <ProdottoForm
                                        podCommessaId={podCommessaId}
                                        iterConnessione={iterConnessione}
                                        commessa={commessa}
                                        tipoProdotto={tipoProdotto}
                                        handleClosInputProdotto={handleClosInputProdotto}
                                        preEsistente={preEsistente}
                                    />
                                </Box>
                            </Modal>
                        )}
                        <Grid container spacing={2} sx={{mb: 2}}>
                            {prodottiPrevisti(iterConnessione, commessa, tipiIntervento).filter((p) => p.esistente == preEsistente).map((prodotto_previsto) => (
                                <Grid
                                    item
                                    xs={12}
                                    sm={6}
                                    md={6}
                                    lg={4}
                                    key={`tipo_${prodotto_previsto.tipo_prodotto}`}
                                >
                                    <Typography variant="h6"
                                                gutterBottom>
                                        {
                                            Object.entries(TipoProdottoTypes).find(
                                                ([key]) => key == prodotto_previsto.tipo_prodotto
                                            )?.[1]
                                        }
                                        {consentiModifica && (
                                            <IconButton
                                                aria-label="fingerprint"
                                                color="primary"
                                                onClick={() => {
                                                    setTipoProdotto(prodotto_previsto.tipo_prodotto);
                                                    setOpenInputProdotto(true);
                                                }}
                                                sx={{
                                                    m: 0,
                                                    ml: 1,
                                                    p: 0,
                                                    color: prodotto_previsto.obbligatorio && prodotti?.filter(
                                                        (prodotto) =>
                                                            prodotto.tipo_prodotto ==
                                                            prodotto_previsto.tipo_prodotto &&
                                                            prodotto.pre_esistente == preEsistente
                                                    ).length == 0
                                                        ? "red"
                                                        : "green",
                                                }}
                                            >
                                                <AddCircleOutlineIcon/>
                                            </IconButton>
                                        )}
                                    </Typography>
                                    <Stack>
                                        {prodotti
                                            ?.filter(
                                                (prodotto) =>
                                                    prodotto.tipo_prodotto ==
                                                    prodotto_previsto.tipo_prodotto &&
                                                    prodotto.pre_esistente == preEsistente
                                            )
                                            .map((prodotto) => (
                                                <ProdottoCard
                                                    key={"prodotto_" + prodotto.id}
                                                    prodotto={prodotto}
                                                    consentiModifica={consentiModifica}
                                                    iterConnessioneId={iterConnessione?.id}
                                                    commessaId={commessa?.id}
                                                />
                                            ))}
                                    </Stack>
                                </Grid>
                            ))}
                        </Grid>
                    </CardContent>
                </Card>
            )}
        </>
    );
};

export default DistintaProdottiBox;
