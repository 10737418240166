import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { useAppDispatch, RootState } from "../../../../redux/store";

import ConfermaAnnulla from "../../../common/ConfermaAnnulla";

import { Commessa, saveCommessa, TipoIntervento } from "../commesseSlice";

import SoggettoPage from "../../../anagrafiche/soggetti/SoggettoPage";
import Button from "@mui/material/Button";
import { Box } from "@mui/system";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import AllegatiPage from "../../../allegati/AllegatiPage";
import {prevedeAmpliamento} from "./prevedeAmpliamento";

interface ProduttoreTabProps {
	commessa: Commessa;
	consentiModifica: boolean;
}

const ProduttoreTab = ({ commessa, consentiModifica }: ProduttoreTabProps) => {
	const dispatch = useAppDispatch();

	// gestione chiusura modal su scelta "Annulla"
	const [openConferma, setOpenConferma] = useState(false);
	const handleConfermaClose = () => setOpenConferma(false);

	const tipi_intervento = useSelector<RootState, TipoIntervento[]>(
		(state) => state.commesseState.tipiIntervento.results
	);

	return (
		<Box
			sx={{
				backgroundColor: "#fff",
				elevation: 20,
				p: 2,
			}}
		>
			{commessa?.contraente == commessa.produttore ? (
				<Typography variant="h4" sx={{ mt: 2 }}>
					Uguale a Contraente
				</Typography>
			) : (
				<SoggettoPage
					key={`soggetto_${commessa?.produttore}`}
					soggetto_id={commessa?.produttore}
					commessa_id={commessa.id}
					come_produttore={true}
					consentiModifica={consentiModifica}
					ampliamento={prevedeAmpliamento(commessa, tipi_intervento)}
				/>
			)}
			<ConfermaAnnulla
				handleAnnulla={handleConfermaClose}
				handleConferma={() => {
					!!commessa &&
						dispatch(
							saveCommessa({
								commessaToSave: { ...commessa, produttore: null },
							})
						);
					handleConfermaClose();
				}}
				domanda={
					"Sicuro di voler sganciare il Produttore del POD da questa commessa"
				}
				open={openConferma}
			/>
			{!!commessa?.produttore && (
				<Stack
					direction="row"
					spacing={2}
					sx={{ mt: 2 }}
					justifyContent="flex-end"
				>
					<Button
						onClick={() => setOpenConferma(true)}
						disabled={!consentiModifica}
					>
						Cambia Produttore
					</Button>
				</Stack>
			)}

			{commessa?.produttore != commessa.contraente && (
				<AllegatiPage
					commessaId={commessa.id || 0}
					consentiModifica={consentiModifica}
					faseCommessa={"PRD"}
				/>
			)}
		</Box>
	);
};

export default ProduttoreTab;
