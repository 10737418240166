import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
	ErrorStatusTypes,
	ErrorsStack,
	parseErrorMessage,
} from "../../common/errorsDeclarations";
import * as commesseApi from "../../../api/commesse/commesseApi";

import { toast } from "react-toastify";
import {
	DocumentiCommessaPerFase,
	DocumentiPerTipo,
} from "../../allegati/allegatiSlice";
import {
	fetchDocumentiIterPerFase,
	getIterConnessione,
} from "../iterConnessioni/iterConnessioneSlice";
import {
	TipoTensioneTypes,
	TitoloPossessoTypes,
	TipologiaEdiliziaTypes,
} from "../pods/podsSlice";

// import { useDispatch } from "react-redux";

export enum TipoProdottoTypes {
	MF = "Moduli Fotovoltaici",
	IN = "Inverter",
	SA = "Sistema Accumulo",
	OT = "Ottimizatori",
	PI = "Protezione Interfaccia (Spi+Ddi)",
	AC = "Accessori Comunicazione",
	EV = 'EV Charger',
	XX = "Altri Prodotti",
}

export interface Prodotto {
	id: number | null | undefined;
	pod_commessa: number;
	iterConnessione?: number;
	tipo_prodotto: "MF" | "IN" | "SA" | "OT" | "PI" | "AC" | "EV" | "XX";
	pre_esistente: boolean;
	marca?: string;
	modello?: string;
	quantita?: number;
	potenza_unitaria?: number;
	capacita_singola_batteria?: number;
	capacita_totale?: number;
	ordinato?: boolean;
	consegnato?: boolean;
	errorsStack?: ErrorsStack;
}

export interface PodCommessa {
	id?: number | null | undefined;
	codice?: string;
	potenza_disponibile?: number;
	potenza_nominale?: number;
	potenza_immessa?: number;
	tipo_tensione?: TipoTensioneTypes;
	gestore_rete?: number;
	regime_commerciale?: number;
	produttore: number;
	produttore_descrizione?: string;
	iban_accredito_gse?: string;
	indirizzo?: string;
	numero_civico?: string;
	cap?: string;
	comune?: number;
	provincia?: string;
	foglio_catastale?: string;
	particella_catastale?: string;
	sub_particella_catastale?: string;
	superficie_calpestabile: number | null;
	numero_unita_immobiliari: number | null;
	anno_costruzione: number | null;
	destinazione_residenziale?: boolean;
	titolo_possesso?: TitoloPossessoTypes;
	tipologia_edilizia?: TipologiaEdiliziaTypes;
	tipologia_edilizia_note?: string;
	prodotti?: Prodotto[];
	numero_iter_connessioni_pod?: number;
	listaDocumentiPerTipo?: DocumentiPerTipo[];
	errorsStack?: ErrorsStack;
}

export interface ProdottiPrevistiPerIntervento {
	id: number;
	tipo_prodotto: "MF" | "IN" | "SA" | "OT" | "PI" | "EV" | "AC" | "XX";
	obbligatorio: boolean;
	esistente: boolean;
}

export interface TipoIntervento {
	id: number;
	codice?: string;
	descrizione?: string;
	ampliamento: boolean;
	prevede_pod: boolean;
	prodotti_previsti: ProdottiPrevistiPerIntervento[];
}

export interface TipoInterventoState {
	count: number;
	page: number;
	num_pages: number;
	next?: URL;
	previous?: URL;
	results: TipoIntervento[];
	errorsStack: ErrorsStack;
}

export enum StatoCommessaTypes {
	A = "in preparazione",
	B = "in ricezione acconto",
	C = "analisi preliminare",
	D = "ordine materiali",
	E = "pianificazione cantiere",
	F = "avviso merce pronta",
	G = "preparazione cantiere",
	H = "cantiere in corso",
	I = "attesa saldo",
	L = "in consegna documenti",
	M = "documenti consegnati",
	N = "completata",
}

export interface CodiciMatricola {
	id: number;
	tipo_prodotto: string;
	marca: string;
	modello: string;
	potenza: number;
	matricola: string;
	stato: string;
	data_evento?: Date | string;
}
export interface Commessa {
	id?: number;
	commessa_descrizione?: string;
	contraente?: number | null;
	contraente_descrizione?: string;
	contraente_completo: boolean;
	pratica_conto_terzi?: boolean;
	titolare_immobile?: number | null;
	titolare_immobile_descrizione?: string;
	titolare_completo: boolean;
	produttore?: number | null;
	produttore_descrizione?: string;
	produttore_completo: boolean;
	note?: string;
	referente?: number;
	cliente?: number;
	stato?: "A" | "B" | "C" | "D" | "E" | "F" | "G" | "H" | "I" | "L" | "M" | "N";
	stato_completato?: boolean;
	operatore?: number;
	codice_commessa_legacy?: string;
	pod?: PodCommessa | null;
	pod_descrizione?: string;
	pod_completo: boolean;
	anagrafica_pod?: number | null;
	tipi_intervento?: number[];
	note_interne?: string;
	data_avvio?: Date | string;
	data_conclusione?: Date | string;
	data_pronta_per_acconto?: Date | string;
	data_fattura_acconto_inviata?: Date | string;
	data_fattura_acconto_pagata?: Date | string;
	materiali_completo: boolean;
	iterConnessione?: number;
	iter_codice_pratica?: string;

	data_cantiere_pianificato?: Date | string;
	data_cantiere_confermata?: boolean;
	data_pronta_per_fattura_amp?: Date | string;
	data_fattura_amp?: Date | string;
	data_fattura_amp_pagata?: Date | string;
	stampa_ddt?: boolean;
	stampa_pos?: boolean;
	stampa_progetto?: boolean;
	stampa_sopralluogo?: boolean;
	stampa_disposizione_ottimizzatori?: boolean;
	data_avvio_installazione?: Date | string;
	data_fine_installazione?: Date | string;
	data_pronta_per_fattura_saldo?: Date | string;
	data_fattura_saldo?: Date | string;
	data_fattura_saldo_pagata?: Date | string;
	codici_matricola?: CodiciMatricola[];
	// listaDocumentiPerTipo?: DocumentiPerTipo[];

	errorsStack?: ErrorsStack;
}

export interface CommesseState {
	count: number;
	page: number;
	num_pages: number;
	next?: URL;
	previous?: URL;
	results: Commessa[];
	errorsStack: ErrorsStack;
}

export interface CommessaAllegatiFase {
	commessa_id?: number;
	commessa_descrizione?: string;
	listaDocumenti: DocumentiPerTipo[];
	lista_completa: boolean;
	errorsStack: ErrorsStack;
}

export interface CommesseStrutturaState {
	commesse: CommesseState;
	tipiIntervento: TipoInterventoState;
	documentiCommessaPerFase: DocumentiCommessaPerFase[];
	nuovaCommessaId?: number;
}

const initialState: CommesseStrutturaState = {
	commesse: {
		count: 0,
		page: 0,
		num_pages: 0,
		next: undefined,
		previous: undefined,
		results: [],
		errorsStack: { status: ErrorStatusTypes.OK },
	},
	tipiIntervento: {
		count: 0,
		page: 0,
		num_pages: 0,
		next: undefined,
		previous: undefined,
		results: [],
		errorsStack: { status: ErrorStatusTypes.OK },
	},
	documentiCommessaPerFase: [],
};

export const fetchTipoIntervento = createAsyncThunk(
	"commesse/fetchTipoIntervento",
	async (parametri: {
		numeroRecord?: number;
		page?: number;
		search?: string;
	}) => {
		return await commesseApi.fetchTipoIntervento(
			parametri.numeroRecord,
			parametri.page,
			parametri.search
		);
	}
);

export const fetchCommesse = createAsyncThunk(
	"commesse/fetchCommesse",
	async (parametri: {
		// statoCommessa?: string;
		listaStatoId?: string[];
		cliente?: number;
		numeroRecord?: number;
		page?: number;
		search?: string;
	}) => {
		return await commesseApi.fetchCommesse(
			// parametri.statoCommessa,
			parametri.listaStatoId,
			parametri.cliente,
			parametri.numeroRecord,
			parametri.page,
			parametri.search
		);
	}
);

export const getCommessa = createAsyncThunk(
	"commesse/getCommessa",
	async (
		parametri: { commessaId: number; faseCommessa?: string },
		thunkApi
	) => {
		return await commesseApi
			.getCommessa(parametri.commessaId)
			.then((response) => {
				parametri.commessaId &&
					parametri.faseCommessa &&
					thunkApi.dispatch(
						fetchDocumentiCommessePerFase({
							commessa_id: parametri.commessaId,
							fase_commessa: parametri.faseCommessa,
						})
					);
				return response;
			});
	}
);

export const saveCommessa = createAsyncThunk(
	"commesse/saveCommessa",
	async (parametri: { commessaToSave: Commessa; azione?: string }) => {
		return await commesseApi.saveCommessa(
			parametri.commessaToSave,
			parametri.azione
		);
		// .then((response) => {
		// 	parametri.azione == "rigetta" &&
		// 		thunkApi.dispatch(getCommessa(parametri.commessaToSave.id || 0));
		// 	thunkApi.dispatch(
		// 		getIterConnessione(parametri.commessaToSave.iter_connessione || 0)
		// 	);
		// 	return response;
		// });
	}
);

export const deleteCommessa = createAsyncThunk(
	"commesse/deleteCommessa",
	async (commessaToDelete: Commessa) => {
		return await commesseApi.deleteCommessa(commessaToDelete);
	}
);

export const fetchDocumentiCommessePerFase = createAsyncThunk(
	"commesse/fetchDocumentiCommessePerFase",
	async (parametri: { commessa_id: number; fase_commessa: string }) => {
		return await commesseApi.fetchDocumentiCommessePerFase(
			parametri.commessa_id,
			parametri.fase_commessa
		);
	}
);

// ############################################################################################################
// ### gestione POD
// ############################################################################################################
export const savePodCommessa = createAsyncThunk(
	"commesse/savePodCommessa",
	async (
		parametri: {
			commessa: Commessa;
			podCommessaToSave: PodCommessa;
			azione?: string;
		},
		thunkApi
	) => {
		return await commesseApi
			.savePodCommessa(
				parametri.commessa,
				parametri.podCommessaToSave,
				parametri.azione
			)
			.then((response) => {
				thunkApi.dispatch(
					getCommessa({ commessaId: parametri.commessa.id || 0 })
				);
				return response;
			});
	}
);

export const aggiungiProdotto = createAsyncThunk(
	"commesse/aggiungiProdotto",
	async (
		parametri: {
			prodotto: Prodotto;
			pod_commessa: number;
			iterconnessioneIdToRefresh?: number | null;
			commessaIdToRefresh?: number | null;
		},
		thunkApi
	) => {
		return await commesseApi
			.aggiungiProdotto(parametri.prodotto, parametri.pod_commessa)
			.then((response) => {
				!!parametri.iterconnessioneIdToRefresh &&
					thunkApi.dispatch(
						getIterConnessione({
							iterConnessioneId: parametri.iterconnessioneIdToRefresh,
						})
					);
				!!parametri.commessaIdToRefresh &&
					thunkApi.dispatch(
						getCommessa({ commessaId: parametri.commessaIdToRefresh })
					);
				return response;
			});
	}
);

export const saveProdotto = createAsyncThunk(
	"commesse/saveProdotto",
	async (
		parametri: {
			prodottoToSave: Prodotto;
			iterconnessioneIdToRefresh?: number | null;
			commessaIdToRefresh?: number | null;
		},
		thunkApi
	) => {
		return await commesseApi
			.saveProdotto(parametri.prodottoToSave)
			.then((response) => {
				!!parametri.iterconnessioneIdToRefresh &&
					thunkApi.dispatch(
						getIterConnessione({
							iterConnessioneId: parametri.iterconnessioneIdToRefresh,
						})
					);
				!!parametri.commessaIdToRefresh &&
					thunkApi.dispatch(
						getCommessa({ commessaId: parametri.commessaIdToRefresh })
					);
				return response;
			});
	}
);

export const deleteProdotto = createAsyncThunk(
	"commesse/deleteProdotto",
	async (
		parametri: {
			prodotto: Prodotto;
			iterconnessioneIdToRefresh?: number | null;
			commessaIdToRefresh?: number | null;
		},
		thunkApi
	) => {
		return await commesseApi
			.deleteProdotto(parametri.prodotto)
			.then((response) => {
				!!parametri.iterconnessioneIdToRefresh &&
					thunkApi.dispatch(
						getIterConnessione({
							iterConnessioneId: parametri.iterconnessioneIdToRefresh,
						})
					);
				!!parametri.commessaIdToRefresh &&
					thunkApi.dispatch(
						getCommessa({ commessaId: parametri.commessaIdToRefresh })
					);
				return response;
			});
	}
);

// ############################################################################################################
// ### crezione Iter da Commessa
// ############################################################################################################
export const creaIterCommessa = createAsyncThunk(
	"commesse/creaIterCommessa",
	async (
		parametri: {
			commessa: Commessa;
			tipo_servizio?: string;
		},
		thunkApi
	) => {
		return await commesseApi
			.creaIterCommessa(parametri.commessa, parametri.tipo_servizio)
			.then((response) => {
				parametri.commessa.id &&
					thunkApi.dispatch(getCommessa({ commessaId: parametri.commessa.id }));
				return response;
			});
	}
);

export const commesseSlice = createSlice({
	name: "commesseState",
	initialState,
	reducers: {
		resetNuovaCommessaId: (state) => {
			state.nuovaCommessaId = undefined;
		},
	},
	extraReducers: (builder) => {
		// tipiIntervento
		builder.addCase(fetchTipoIntervento.pending, (state) => {
			state.tipiIntervento.errorsStack = {
				status: ErrorStatusTypes.PENDING,
			};
		});
		builder.addCase(fetchTipoIntervento.fulfilled, (state, action) => {
			state.tipiIntervento = action.payload;
			state.tipiIntervento.errorsStack = { status: ErrorStatusTypes.OK };
		});
		builder.addCase(fetchTipoIntervento.rejected, (state, action) => {
			state.tipiIntervento.errorsStack = parseErrorMessage(action.error);
		});

		// fetch Richieste Connessioni
		builder.addCase(fetchCommesse.pending, (state) => {
			state.commesse.errorsStack = {
				status: ErrorStatusTypes.PENDING,
			};
		});
		builder.addCase(fetchCommesse.fulfilled, (state, action) => {
			state.commesse = action.payload;
			state.commesse.errorsStack = { status: ErrorStatusTypes.OK };
		});
		builder.addCase(fetchCommesse.rejected, (state, action) => {
			state.commesse.errorsStack = parseErrorMessage(action.error);
		});

		// get Commessa
		builder.addCase(getCommessa.pending, (state) => {
			state.commesse.errorsStack = {
				status: ErrorStatusTypes.PENDING,
			};
		});
		builder.addCase(getCommessa.fulfilled, (state, action) => {
			state.commesse.results = state.commesse.results.filter(
				(richiesta) => richiesta.id != action.payload.id
			);
			state.commesse.results.push(action.payload);

			state.commesse.errorsStack = { status: ErrorStatusTypes.OK };
		});
		builder.addCase(getCommessa.rejected, (state, action) => {
			state.commesse.errorsStack = parseErrorMessage(action.error);
		});

		// save Commessa
		builder.addCase(saveCommessa.pending, (state) => {
			state.commesse.errorsStack = {
				status: ErrorStatusTypes.PENDING,
			};
		});
		builder.addCase(saveCommessa.fulfilled, (state, action) => {
			state.commesse.results = state.commesse.results.filter(
				(richiesta) => richiesta.id != action.payload.id
			);
			state.commesse.results.push({
				...action.payload,
				errorsStack: { status: ErrorStatusTypes.SUCCESS },
			});
			state.nuovaCommessaId = action.payload.id;
			state.commesse.errorsStack = {
				status: ErrorStatusTypes.SUCCESS,
			};

			// Solo se è nuova creazione ********************
			if (!action.meta.arg.commessaToSave.id) {
				state.nuovaCommessaId = action.payload.id;
			}
			// toast.success("Commessa salvata.");
		});
		builder.addCase(saveCommessa.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");

			state.commesse.results = state.commesse.results.map((richiesta) => {
				if (richiesta.id == action.meta.arg.commessaToSave.id) {
					return {
						...richiesta,
						errorsStack: parseErrorMessage(action?.error?.message || ""),
					};
				} else {
					return richiesta;
				}
			});
			state.commesse.errorsStack = parseErrorMessage(action.error);
		});
		// cancella Commessa
		builder.addCase(deleteCommessa.pending, (state) => {
			state.commesse.errorsStack = {
				status: ErrorStatusTypes.PENDING,
			};
		});
		builder.addCase(deleteCommessa.fulfilled, (state, action) => {
			state.commesse.errorsStack = {
				status: ErrorStatusTypes.SUCCESS,
			};
			state.commesse.results = state.commesse.results.filter(
				(richiesta) => richiesta.id != action.meta.arg.id
			);
			toast.success(action.payload.message || "Commessa cancellata.");
		});
		builder.addCase(deleteCommessa.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");
			state.commesse.errorsStack = parseErrorMessage(action.error);
		});
		////////////////////////////////////////////////////////////////////

		// save Pod Commessa
		builder.addCase(savePodCommessa.pending, (state) => {
			state.commesse.errorsStack = {
				status: ErrorStatusTypes.PENDING,
			};
		});
		builder.addCase(savePodCommessa.fulfilled, (state) => {
			// state.commesse.results = state.commesse.results.filter(
			// 	(richiesta) => richiesta.id != action.payload.id
			// );
			// state.commesse.results.push({
			// 	...action.payload,
			// 	errorsStack: { status: ErrorStatusTypes.SUCCESS },
			// });
			// const nuovoPodCommessa = action.payload;
			state.commesse.errorsStack = {
				status: ErrorStatusTypes.SUCCESS,
			};
		});
		builder.addCase(savePodCommessa.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");

			state.commesse.results = state.commesse.results.map((commessa) => {
				if (commessa.id == action.meta.arg.commessa.id) {
					return {
						...commessa,
						pod: {
							...action.meta.arg.podCommessaToSave,
							errorsStack: parseErrorMessage(action.error),
						},
					};
				} else {
					return commessa;
				}
			});
			state.commesse.errorsStack = parseErrorMessage(action.error);
		});
		////////////////////////////////////////////////////////////////////

		// save Prodotto
		builder.addCase(aggiungiProdotto.pending, (state) => {
			state.commesse.errorsStack = { status: ErrorStatusTypes.PENDING };
		});
		builder.addCase(aggiungiProdotto.fulfilled, (state) => {
			state.commesse.errorsStack = { status: ErrorStatusTypes.SUCCESS };
			toast.success("Prodotto salvato.");
		});
		builder.addCase(aggiungiProdotto.rejected, (state, action) => {
			toast.error("Errore");

			state.commesse.errorsStack = parseErrorMessage(action.error);
		});

		// cancella Prodotto
		builder.addCase(deleteProdotto.pending, (state) => {
			state.commesse.errorsStack = { status: ErrorStatusTypes.PENDING };
		});
		builder.addCase(deleteProdotto.fulfilled, (state) => {
			state.commesse.errorsStack = { status: ErrorStatusTypes.SUCCESS };
			toast.success("Prodotto cancellato.");
		});
		builder.addCase(deleteProdotto.rejected, (state, action) => {
			toast.error("Errore");

			state.commesse.errorsStack = parseErrorMessage(action.error);
		});

		////////////////////////////////////////////////////////////////////
		////////////////////////////////////////////////////////////////////

		// crea Iter da Commessa
		builder.addCase(creaIterCommessa.pending, (state) => {
			state.commesse.errorsStack = {
				status: ErrorStatusTypes.PENDING,
			};
		});
		builder.addCase(creaIterCommessa.fulfilled, (state) => {
			// state.commesse.results = state.commesse.results.filter(
			// 	(richiesta) => richiesta.id != action.payload.id
			// );
			// state.commesse.results.push({
			// 	...action.payload,
			// 	errorsStack: { status: ErrorStatusTypes.SUCCESS },
			// });
			// const nuovoPodCommessa = action.payload;
			state.commesse.errorsStack = {
				status: ErrorStatusTypes.SUCCESS,
			};
		});
		builder.addCase(creaIterCommessa.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");

			state.commesse.results = state.commesse.results.map((commessa) => {
				if (commessa.id == action.meta.arg.commessa.id) {
					return {
						...commessa,
					};
				} else {
					return commessa;
				}
			});
			state.commesse.errorsStack = parseErrorMessage(action.error);
		});

		////////////////////////////////////////////////////////////////////
		builder.addCase(fetchDocumentiCommessePerFase.pending, (state) => {
			state.commesse.errorsStack = {
				status: ErrorStatusTypes.PENDING,
			};
		});
		builder.addCase(
			fetchDocumentiCommessePerFase.fulfilled,
			(state, action) => {
				// prima lascio quelli che sono dello stesso iter ma non dello stesso tipo
				state.documentiCommessaPerFase = state.documentiCommessaPerFase.filter(
					(fase) =>
						fase.commessa_id ==
							action.payload.documentiCommessaPerFase.commessa_id &&
						fase.fase_commessa !=
							action.payload.documentiCommessaPerFase.fase_commessa
				);
				// poi aggiungo quelli del tipo in arrivo
				state.documentiCommessaPerFase.push(
					action.payload.documentiCommessaPerFase
				);

				state.commesse.errorsStack = { status: ErrorStatusTypes.SUCCESS };
			}
		);
		builder.addCase(fetchDocumentiCommessePerFase.rejected, (state, action) => {
			state.commesse.errorsStack = parseErrorMessage(action.error);
		});

		////////////////////////////////////////////////////////////////////
		builder.addCase(saveProdotto.pending, (state) => {
			state.commesse.errorsStack = {
				status: ErrorStatusTypes.PENDING,
			};
		});
		builder.addCase(saveProdotto.fulfilled, (state, action) => {
			state.commesse.errorsStack = { status: ErrorStatusTypes.SUCCESS };
		});
		builder.addCase(saveProdotto.rejected, (state, action) => {
			state.commesse.errorsStack = parseErrorMessage(action.error);
		});
	},
});

// Action creators are generated for each case reducer function
export const { resetNuovaCommessaId } = commesseSlice.actions;

export const commesseSliceReducer = commesseSlice.reducer;
