import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, Link, useNavigate } from "react-router-dom";

import { RootState, useAppDispatch } from "../../../redux/store";
import { Routes_path } from "../../routerApp";

import { DateTime } from "luxon";
import { convertToDateTime } from "components/common/dateUtils/convertToDateTime";
import {
	ErrorStatusTypes,
	ErrorsStack,
	parseErrorMessage,
} from "../../common/errorsDeclarations";
import { utenteAutorizzato } from "../../authentication/UtenteAutorizzato";
import { UtenteLogged } from "../../authentication/authenticationSlice";
import { PermissionTypes } from "../../authentication/permissionsGroups";
import {
	OrdinamentoLista,
	PreferenzeLista,
	setPreferenzeListaVerificheVincoli,
} from "../../userPreference/userPreferenceSlice";

import {
	fetchVerificheVincoli,
	VerificheVincoliState,
	StatoVerificaVincoliTypes,
	resetVerificaVincoliId,
} from "./verificheVincoliSlice";
import { RegimeCommerciale } from "../../anagrafiche/regimiCommerciali/regimiCommercialiSlice";
import {
	StatoIterTypes,
	StatoConnessioneTypes,
	StatiIter,
	StatoIter,
} from "../../anagrafiche/kpi/kpiSlice";
import {
	Soggetto,
	resetProduttoreCorrente,
} from "../../anagrafiche/produttori/produttoriSlice";

// import IterConnessioneAlerChip from "./sopralluogo/IterConnessioneAlerChip";
import { Cliente } from "../../anagrafiche/clienti/clientiSlice";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";
import Chip from "@mui/material/Chip";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import AddIcon from "@mui/icons-material/Add";

import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import Pagination from "@mui/material/Pagination";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import { TipoPratica } from "components/anagrafiche/tipiPratica/tipiPraticaSlice";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import ReplayIcon from "@mui/icons-material/Replay";

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

const produttoreFullName = (produttore?: Soggetto) => {
	if (produttore) {
		if (produttore.tipo_persona == "PF") {
			return `${produttore.cognome} ${produttore.nome}`;
		} else {
			return `${produttore.ragione_sociale}`;
		}
	} else {
		return "anonimo";
	}
};

const visualizzaStatoIter = (statoIter?: StatoIter) => {
	return statoIter && `${statoIter.stato_desc} (${statoIter.count})`;
};

const VerificheVincoliPage = () => {
	
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	type LocationState = {
		statoVerificaId?: string;
		clienteId?: number;
		iterSemplificato?: string;
	};
	const location = useLocation();

	const preferenzeListaVerificheVincoli = useSelector<
		RootState,
		PreferenzeLista | undefined
	>((state) => state.userPreferenceState.preferenzeListaVerificheVincoli);

	const [statoVerificaId, setStatoVerificaId] = useState(
		location.state
			? (location.state as LocationState).statoVerificaId
			: preferenzeListaVerificheVincoli?.stato
		//  : undefined
	);

	const [clienteId, setClienteIterId] = useState(
		location.state
			? (location.state as LocationState).clienteId
			: preferenzeListaVerificheVincoli?.clienteIterId
		// : undefined
	);

	const [iterSemplificato, setIterSemplificato] = useState(
		location.state
			? (location.state as LocationState).iterSemplificato
			: // : preferenzeListaSopralluoghi.iterSemplificato
			  undefined
	);

	const verificheVincoli = useSelector<RootState, VerificheVincoliState>(
		(state) => state.verificheVincoliState.verificheVincoli
	);
	const utente = useSelector<RootState, UtenteLogged>(
		(state) => state.authentication.utenteLogged
	);
	const statiIter = useSelector<RootState, StatiIter>(
		(state) => state.kpiState.statiIter
	);

	const clienti = useSelector<RootState, Cliente[]>(
		(state) => state.clientiState.clienti.results
	);
	const produtori = useSelector<RootState, Soggetto[]>(
		(state) => state.produttoriState.produttori.results
	);

	const tipiPratica = useSelector<RootState, TipoPratica[]>(
		(state) => state.tipiPraticaState.tipiPratica.results
	);

	const produttori = useSelector<RootState, Soggetto[]>(
		(state) => state.produttoriState.produttori.results
	);

	const [page, setPage] = useState<number>(
		preferenzeListaVerificheVincoli?.page || 1
	);
	const [numeroRecord, setNumeroRecord] = useState<number>(
		preferenzeListaVerificheVincoli?.numeroRecord || 10
	);
	const [search, setSearch] = useState<string | undefined>(undefined);

	const handleChangeNumeroRecord = (event: SelectChangeEvent) => {
		setNumeroRecord(Number(event.target.value));
	};

	const handlerPager = (event: React.ChangeEvent<unknown>, page: number) => {
		setPage(page);
	};

	const [refresh, setRefresh] = useState<boolean>(false);

	const [anchorElStatoFilter, setAnchorElStatoFilter] = useState(null);
	const [anchorElClienteFilter, setAnchorElClienteFilter] = useState(null);
	const [anchorElTipoIterFilter, setAnchorElTipoIterFilter] = useState(null);

	const handleStatoFilterClose = () => {
		setAnchorElStatoFilter(null);
		setAnchorElClienteFilter(null);
		setAnchorElTipoIterFilter(null);
		// onClose(selectedValue);
	};

	useEffect(() => {
		dispatch(
			fetchVerificheVincoli({
				statoVerifica: statoVerificaId,
				cliente: clienteId,
				numeroRecord: numeroRecord,
				page: page,
				search: search,
			})
		);
		dispatch(
			setPreferenzeListaVerificheVincoli({
				numeroRecord: numeroRecord,
				page: page,
				stato: statoVerificaId,
				clienteIterId: clienteId,
				iterSemplificato: iterSemplificato,
			})
		);
		setRefresh(false);
	}, [
		page,
		numeroRecord,
		search,
		statoVerificaId,
		clienteId,
		iterSemplificato,
		refresh,
	]);

	useEffect(() => {
		setRefresh(false);
	}, [refresh]);

	const handlerCreateVerificaVincoli = () => {
		dispatch(resetVerificaVincoliId());
		navigate(Routes_path.VERIFICA_VINCOLI);
	};

	return (
		<Box
			sx={{
				backgroundColor: "#fff",
				elevation: 20,
				// p: 2,
				"& .campo-editabile": {
					backgroundColor: "#bbbbbb11",
					background: "linear-gradient(to right bottom, #f5f7f6, #dce3e0)",
					// borderWidth: "5px",
					borderColor: "#ff91003d",
					// borderColor: "#0000003d",
					// borderStyle: "outset",
					borderWidth: 3,
					borderStyle: "ridge",
					color: "#1a3e72",
					fontWeight: "600",
				},
				"& .campo-errore": {
					backgroundColor: "rgba(124, 3, 0, 0.3)",
					color: "#ffffff",
					fontWeight: "600",
				},
			}}
		>
			<List sx={{ mt: 2 }}>
				<ListItem>
					<ButtonGroup
						variant="outlined"
						aria-label="outlined button group"
						sx={{ mb: 2 }}
					>
						<Button
							color="primary"
							startIcon={<ReplayIcon />}
							onClick={() => setRefresh(true)}
						>
							aggiorna
						</Button>
						{/* <Button
							color="primary"
							startIcon={<AddIcon />}
							disabled={
								!utenteAutorizzato(utente, [
									PermissionTypes.GESTORI_ITER,
									// PermissionTypes.CLIENTI,
									// PermissionTypes.OPERATORI,
									PermissionTypes.PROGETTISTI,
								])
							}
							onClick={(event) => {
								event.preventDefault();
								handlerCreateVerificaVincoli();
							}}
						>
							Aggiungi nuova Verifica Vincoli
						</Button> */}
					</ButtonGroup>
				</ListItem>
				<ListItemText
					key={"Lista_saldo_pallet_committente"}
					sx={{ backgroundColor: "#1b75b1", color: "#ddd", mb: 1 }}
				>
					<Grid container spacing={{ xs: 0.5, md: 1, lg: 2 }} sx={{ px: 2 }}>
						<Grid item xs={2} sm={2} md={1} lg={1}>
							<Typography variant="h5" gutterBottom>
								n°
							</Typography>
						</Grid>
						<Grid item xs={3} sm={3} md={2} lg={2}>
							<Typography variant="h5" gutterBottom>
								IterConnessione
							</Typography>
						</Grid>
						<Grid item xs={6} sm={6} md={6} lg={2}>
							<Stack direction="row" spacing={1}>
								<Typography variant="h5" gutterBottom>
									Cliente
								</Typography>
								<IconButton
									color={clienteId ? "secondary" : "inherit"}
									onClick={(event: any) =>
										setAnchorElClienteFilter(event.currentTarget)
									}
								>
									<FilterAltIcon sx={{ mt: -0.5 }} />
								</IconButton>
							</Stack>
							<Box style={{ display: "flex", justifyContent: "space-between" }}>
								<Menu
									id="simple-menu"
									anchorEl={anchorElClienteFilter}
									keepMounted
									open={Boolean(anchorElClienteFilter)}
									onClose={handleStatoFilterClose}
								>
									{clienti.map((cliente) => (
										<MenuItem
											key={cliente.id}
											onClick={() => {
												setClienteIterId(cliente.id);
												setAnchorElClienteFilter(null);
											}}
											sx={{
												backgroundColor:
													cliente.id == clienteId ? "#aaa" : "inherit",
											}}
										>
											{cliente.ragione_sociale}
										</MenuItem>
									))}
									<MenuItem
										onClick={() => {
											setClienteIterId(undefined);
											setAnchorElClienteFilter(null);
										}}
										sx={{
											backgroundColor: !clienteId ? "#aaa" : "inherit",
										}}
									>
										tutti
									</MenuItem>
								</Menu>
							</Box>
						</Grid>
						<Grid item xs={6} sm={6} md={6} lg={2}>
							<Typography variant="h5">Esecutore</Typography>
						</Grid>
						<Grid item xs={6} sm={6} md={6} lg={2}>
							<Typography variant="h5">Soggetto</Typography>
						</Grid>

						<Grid item xs={6} sm={6} md={6} lg={2}>
							<Stack direction="row" spacing={1}>
								<Typography variant="h5">Stato</Typography>
								<IconButton
									color={statoVerificaId ? "secondary" : "inherit"}
									onClick={(event: any) =>
										setAnchorElStatoFilter(event.currentTarget)
									}
								>
									<FilterAltIcon sx={{ mt: -0.5 }} />
								</IconButton>
							</Stack>
							<Box style={{ display: "flex", justifyContent: "space-between" }}>
								<Menu
									id="simple-menu"
									anchorEl={anchorElStatoFilter}
									keepMounted
									open={Boolean(anchorElStatoFilter)}
									onClose={handleStatoFilterClose}
								>
									{Object.entries(StatoVerificaVincoliTypes).map(
										([key, value]) => {
											return (
												<MenuItem
													key={`statoIter_${key}`}
													value={key}
													onClick={() => {
														setStatoVerificaId(key);
														setAnchorElStatoFilter(null);
													}}
													sx={{
														backgroundColor:
															value == statoVerificaId ? "#aaa" : "inherit",
													}}
												>
													{value}
												</MenuItem>
											);
										}
									)}

									<MenuItem
										onClick={() => {
											setStatoVerificaId(undefined);
											setAnchorElStatoFilter(null);
										}}
										sx={{
											backgroundColor: !statoVerificaId ? "#aaa" : "inherit",
										}}
									>
										tutti
									</MenuItem>
								</Menu>
							</Box>
						</Grid>
						<Grid item xs={6} sm={6} md={6} lg={1}>
							<Typography variant="h5" gutterBottom>
								Data verifica
							</Typography>
						</Grid>
					</Grid>
				</ListItemText>

				<Backdrop
					sx={{
						color: "#fff",
						zIndex: (theme) => theme.zIndex.drawer + 1,
					}}
					open={
						verificheVincoli.errorsStack.status == ErrorStatusTypes.PENDING
						// errorsStack.status == ErrorStatusTypes.PENDING
					}
				>
					<CircularProgress color="inherit" />
				</Backdrop>

				{verificheVincoli.results?.map((verifica, index) => (
					<ListItemButton
						// key={"iter_" + sopralluogo.id}
						key={index}
						component={Link}
						to={Routes_path.VERIFICA_VINCOLI + verifica.id}
						sx={{
							color: "black",
							textDecoration: "none",
							backgroundColor: index % 2 === 0 ? "#fff" : "#f2f2f2",
						}}
					>
						<Grid container spacing={{ xs: 0.5, md: 1, lg: 2 }} sx={{ mb: 1 }}>
							<Grid item xs={2} sm={2} md={1} lg={1}>
								{verifica.id}
							</Grid>
							<Grid item xs={6} sm={6} md={11} lg={2}>
								<Link
									to={Routes_path.ITER_CONNESSIONE + verifica.iter_connessione}
									key={`Iter_Sopralluogo_${verifica.iter_connessione}`}
								>
									{verifica.iter_connessione}
								</Link>
							</Grid>
							<Grid item xs={6} sm={6} md={6} lg={2}>
								<Typography noWrap>
									{
										clienti.find((cliente) => cliente.id == verifica.cliente)
											?.ragione_sociale
									}
								</Typography>
							</Grid>

							<Grid item xs={6} sm={6} md={6} lg={2}>
								<Typography noWrap>
									{verifica.servizio_i43
										? "I43"
										: clienti.find((cliente) => cliente.id == verifica.cliente)
												?.ragione_sociale}
								</Typography>
							</Grid>

							<Grid item xs={6} sm={6} md={6} lg={2}>
								<Typography noWrap>
									{verifica.produttore_descrizione}
								</Typography>
							</Grid>

							<Grid item xs={6} sm={6} md={6} lg={2}>
								{
									Object.entries(StatoVerificaVincoliTypes).find(
										([key, value]) => key == verifica.stato_verifica
									)?.[1]
								}

								{/* <IterConnessioneAlerChip sopralluogo={sopralluogo} /> */}
							</Grid>
							<Grid item xs={6} sm={6} md={6} lg={1}>
								{verifica.data_verifica
									? convertToDateTime(verifica.data_verifica)?.setLocale("it").toFormat("dd/MM/yyyy")
									: ""}
							</Grid>
						</Grid>
					</ListItemButton>
				))}

				<ListItem
					sx={{
						alignContent: "right",
						width: "100%",
						mt: 2,
						borderTopStyle: "solid",
						borderTopWidth: 1,
					}}
				>
					<Stack
						direction="row"
						justifyContent="space-between"
						alignItems="center"
						spacing={2}
						sx={{ width: "100%" }}
					>
						<Pagination
							count={verificheVincoli.num_pages}
							onChange={handlerPager}
							page={page}
						/>
						{statoVerificaId || clienteId ? (
							<Typography noWrap>
								Totale verifiche filtrate:{"  "}
								{verificheVincoli.count}
							</Typography>
						) : (
							<Typography noWrap>
								Totale verifiche:{"  "}
								{verificheVincoli.count}
							</Typography>
						)}

						<TextField
							id="input-with-icon-textfield"
							// label="TextField"
							InputProps={{
								startAdornment: (
									<InputAdornment position="end">
										<SearchIcon />
									</InputAdornment>
								),
							}}
							variant="standard"
							value={search}
							onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
								setSearch(event.target.value);
							}}
						/>
						<FormControl>
							<InputLabel id="demo-simple-select-label">Record</InputLabel>
							<Select
								labelId="demo-simple-select-label"
								id="demo-simple-select"
								value={numeroRecord.toString()}
								label="Age"
								onChange={handleChangeNumeroRecord}
								variant="outlined"
							>
								<MenuItem value={10}>10</MenuItem>
								<MenuItem value={20}>20</MenuItem>
								<MenuItem value={50}>50</MenuItem>
							</Select>
						</FormControl>
					</Stack>
				</ListItem>
			</List>
		</Box>
	);
};

export default VerificheVincoliPage;
