import { handleResponse, handleError, headerset } from "../apiUtils";
import { DateTime } from "luxon";
import { convertToDateTime } from "components/common/dateUtils/convertToDateTime";
import {
	Progetto,
	SezioneProgetto,
} from "../../components/main/iterConnessioni/iterConnessione/progetti/progettiSlice";
import { VerificaVincoli } from "components/main/verificheVincoli/verificheVincoliSlice";

import { Commessa } from "components/main/commesse/commesseSlice";

const baseUrl = (verificaVincoli_id?: number | null, azione?: string) => {
	if (verificaVincoli_id) {
		let url = `${process.env.API_URL}/api/verifiche_vincoli/${verificaVincoli_id}/`;
		if (!!azione) {
			url = url + `?azione=${azione}`;
		}
		return url;
	} else {
		return process.env.API_URL + "/api/verifiche_vincoli/";
	}
};

export function fetchVerificheVincoli(
	statoVerifica?: string,
	cliente?: number,
	commessa?: number,
	servizio_i43?: string,
	numeroRecord?: number,
	page?: number,
	search?: string
) {
	let url = baseUrl();
	if (
		statoVerifica != undefined ||
		cliente != undefined ||
		commessa != undefined ||
		servizio_i43 != undefined ||
		numeroRecord != undefined ||
		search != undefined ||
		page != undefined
	) {
		url += "?";
	}
	if (statoVerifica != undefined) {
		url += `stato_verifica=${statoVerifica}&`;
	}
	if (cliente != undefined) {
		url += `cliente=${cliente}&`;
	}
	if (commessa != undefined) {
		url += `commessa=${commessa}&`;
	}
	if (servizio_i43 != undefined) {
		url += `servizio_i43=${servizio_i43}&`;
	}
	if (numeroRecord != undefined) {
		url += `numero_record=${numeroRecord}&`;
	}
	if (page != undefined) {
		url += `page=${page}&`;
	}
	if (search != undefined) {
		url += `search=${search}`;
	}
	return fetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}

export function getVerificaVincoli(verificaVincoliId: number) {
	let url = baseUrl(verificaVincoliId);
	return fetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}

export function saveVerificaVincoli(
	verificaVincoliToSave: VerificaVincoli,
	azione?: string
) {
	const data_verifica = verificaVincoliToSave.data_verifica
		? convertToDateTime(verificaVincoliToSave.data_verifica)?.setLocale("it").toFormat("yyyy-MM-dd")
		: null;
	const verificaVincoli = {
		...verificaVincoliToSave,
		data_verifica: data_verifica,
	};
	return fetch(baseUrl(verificaVincoliToSave.id, azione), {
		method: verificaVincoliToSave.id ? "PUT" : "POST", // PUT to update when id already exists.
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
		body: JSON.stringify({
			...verificaVincoli,
			// Parse authorId to a number (in case it was sent as a string).
			// id: parseInt(progetto.id, 10),
		}),
	})
		.then(handleResponse)
		.catch(handleError);
}

export function deleteVerificaVincoli(
	verificaVincoliToDelete: VerificaVincoli
) {
	return fetch(baseUrl(verificaVincoliToDelete.id), {
		method: "DELETE",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}

/////////////////////////////////////////////////

export function creaVerificaVincoliCommessa(
	commessa: Commessa,
	servizio_i43?: string
) {
	let url =
		process.env.API_URL +
		"/api/verifiche_vincoli/crea_verifica_vincoli_commessa/" +
		commessa.id +
		"/";
	if (servizio_i43) {
		url = url + `?servizio_i43=${servizio_i43}`;
	}
	return fetch(url, {
		method: "PUT",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}
