import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import {
	Pod,
	savePod,
	TitoloPossessoTypes,
	TipologiaEdiliziaTypes,
} from "./podsSlice";
import { useAppDispatch, RootState } from "../../../redux/store";
import {
	ErrorStatusTypes,
	ErrorsStack,
	parseErrorMessage,
} from "../../common/errorsDeclarations";

import { theme } from "../../theme";

import { utenteAutorizzato } from "../../authentication/UtenteAutorizzato";
import { UtenteLogged } from "../../authentication/authenticationSlice";
import { TipoTensione } from "../../anagrafiche/tipiTensione/tipiTensioneSlice";
import { Comune } from "../../anagrafiche/comuni/comuniSlice";

import { Gestore } from "../../anagrafiche/gestori/gestoriSlice";
import { RegimeCommerciale } from "../../anagrafiche/regimiCommerciali/regimiCommercialiSlice";

import { useForm, SubmitHandler, Controller } from "react-hook-form";

import ReactHookFormSelect from "../../common/ReactHookFormSelect";
import MenuItem from "@mui/material/MenuItem";
import FormControlLabel from "@mui/material/FormControlLabel";
import CircularProgress from "@mui/material/CircularProgress";
import { Routes_path } from "../../routerApp";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import FormHelperText from "@mui/material/FormHelperText";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Alert from "../../common/Alert";
import Typography from "@mui/material/Typography";
import Autocomplete from "@mui/material/Autocomplete";
import { NumericFormat } from "react-number-format";

import { Button, Divider } from "@mui/material";
import { Soggetto } from "components/anagrafiche/produttori/produttoriSlice";

import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

function makeid(length: number) {
	let result = "";
	const characters =
		"ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
	const charactersLength = characters.length;
	let counter = 0;
	while (counter < length) {
		result += characters.charAt(Math.floor(Math.random() * charactersLength));
		counter += 1;
	}
	return result;
}
interface PodDatiGeneraliFormProps {
	pod: Pod;
	consentiModifica: boolean;
}

const PodDatiGeneraliForm = ({
	pod,
	consentiModifica = false,
}: PodDatiGeneraliFormProps) => {
	const {
		handleSubmit,
		register,
		formState: { errors, isDirty, isValid },
		setValue,
		watch,
		getValues,
		control,
		setError,
		clearErrors,
		reset,
	} = useForm<Pod>();

	const listaCampi = Object.keys(pod) as Array<keyof Pod>;

	const dispatch = useAppDispatch();

	const [editing, setEditingControlled] = useState(false);

	const setEditing = (switchModifica: boolean) => {
		consentiModifica && setEditingControlled(switchModifica);
	};

	const utente = useSelector<RootState, UtenteLogged>(
		(state) => state.authentication.utenteLogged
	);

	const regimiCommerciali = useSelector<RootState, RegimeCommerciale[]>(
		(state) => state.regimiCommercialiState.regimiCommerciali.results
	);
	const tipiTensione = useSelector<RootState, TipoTensione[]>(
		(state) => state.tipiTensioneState.tipiTensione.results
	);

	const gestori = useSelector<RootState, Gestore[]>(
		(state) => state.gestoriState.gestori.results
	);

	const comuni = useSelector<RootState, Comune[]>(
		(state) => state.comuniState.comuni.results
	);

	const [comuneCorrente, setComuneCorrente] = useState<Comune | undefined>(
		undefined
	);

	const podStatus = useSelector<RootState, ErrorStatusTypes>(
		(state) => state.podsState.pods.errorsStack.status
	);

	useEffect(() => {
		setComuneCorrente(
			comuni.find((comuneScelto) => comuneScelto.id == getValues("comune"))
		);
	}, [comuni, getValues("comune"), pod]);

	const [keycodice_pod, setKeycodice_pod] = useState<string>(makeid(5));

	useEffect(() => {
		reset();
		listaCampi.map((field) => {
			setValue(field, pod[field]);
		});
		// setKeycodice_pod(makeid(5));
	}, [editing, pod, pod.id]);

	useEffect(() => {
		clearErrors();
		listaCampi.forEach((field) => {
			pod?.errorsStack?.fieldsErrors &&
				pod.errorsStack.fieldsErrors[field] &&
				setError(field, {
					type: "server",
					message: pod.errorsStack.fieldsErrors[field].toString(),
				});
		});
	}, [
		pod?.errorsStack?.fieldsErrors,
		// iterConnessione.allevamento_pack?.errorsStack?.fieldsErrors,
	]);

	const handlerSavePod = (pod: Pod) => {
		consentiModifica &&
			dispatch(
				savePod({
					podToSave: pod,
				})
			);
	};

	const onSubmit: SubmitHandler<Pod> = (pod) => {
		handlerSavePod(pod);
	};

	const handlerDetailChaingSubmit = () => {
		if (isValid && isDirty) {
			const formValues = getValues();
			handlerSavePod(formValues);
		}
	};

	useEffect(() => {
		if (isValid && isDirty) {
			const formValues = getValues();
			handlerSavePod(formValues);
		}
	}, [
		watch("comune"),
		watch("titolo_possesso"),
		watch("tipologia_edilizia"),
		watch("regime_commerciale"),
		watch("gestore_rete"),
		watch("tipo_tensione"),
		watch("destinazione_residenziale"),
	]);

	return (
		<Box sx={{ mb: 1 }}>
			<form onSubmit={handleSubmit(onSubmit)}>
				{pod?.errorsStack?.fieldsErrors?.non_field_errors && (
					<Alert severity="error">
						{pod?.errorsStack?.fieldsErrors?.non_field_errors.toString()}
					</Alert>
				)}
				<Grid container spacing={2} sx={{ my: 2 }}>
					<Grid item xs={12} sm={6} md={6} lg={4}>
						<TextField
							label="iban accrediti provenienti da GSE"
							disabled={!pod.codice || !consentiModifica}
							variant="outlined"
							InputLabelProps={{
								shrink: true,
							}}
							{...register("iban_accredito_gse")}
							error={!!errors?.iban_accredito_gse}
							helperText={errors?.iban_accredito_gse?.message}
							autoComplete={"off"}
							sx={{ width: "100%" }}
							fullWidth
						/>
					</Grid>
				</Grid>
				<Divider
					textAlign="left"
					sx={{ mb: 2, color: theme.palette.primary.main }}
				>
					Indirizzo del pod
				</Divider>
				<Grid container spacing={2} sx={{ mb: 2 }} key={keycodice_pod}>
					<Grid item xs={9} sm={9} md={9} lg={4}>
						<TextField
							label="indirizzo"
							disabled={!consentiModifica}
							variant="outlined"
							InputLabelProps={{
								shrink: true,
							}}
							{...register("indirizzo")}
							error={!!errors?.indirizzo}
							helperText={errors?.indirizzo?.message}
							autoComplete={"off"}
							sx={{ width: "100%" }}
							fullWidth
							onBlur={handlerDetailChaingSubmit}
						/>
					</Grid>
					<Grid item xs={3} sm={3} md={3} lg={1}>
						<TextField
							label="numero civico"
							disabled={!consentiModifica}
							variant="outlined"
							InputLabelProps={{
								shrink: true,
							}}
							{...register("numero_civico")}
							error={!!errors?.numero_civico}
							helperText={errors?.numero_civico?.message}
							autoComplete={"off"}
							sx={{ width: "100%" }}
							fullWidth
							onBlur={handlerDetailChaingSubmit}
						/>
					</Grid>
					<Grid item xs={4} sm={4} md={4} lg={1}>
						<TextField
							label="cap"
							disabled={!consentiModifica}
							variant="outlined"
							InputLabelProps={{
								shrink: true,
							}}
							{...register("cap")}
							error={!!errors?.cap}
							helperText={errors?.cap?.message}
							autoComplete={"off"}
							sx={{ width: "100%" }}
							fullWidth
							onBlur={handlerDetailChaingSubmit}
						/>
					</Grid>
					<Grid item xs={7} sm={7} md={7} lg={3}>
						<Autocomplete
							key={comuneCorrente?.id}
							disabled={!consentiModifica}
							value={comuneCorrente}
							isOptionEqualToValue={(option, value) => option.id === value.id}
							onChange={(event: any, newValue: any) => {
								setValue("comune", newValue ? newValue.id : null, {
									shouldDirty: true,
								});
							}}
							id="comune"
							options={comuni}
							getOptionLabel={(option) => option.nome}
							renderOption={(props, option) => (
								<Box component="li" {...props}>
									{option.nome}
								</Box>
							)}
							renderInput={(params: any) => (
								<TextField
									variant="outlined"
									{...params}
									label="Comune"
									error={!!errors?.comune}
									helperText={errors?.comune?.message}
								/>
							)}
						/>
					</Grid>

					<Grid item xs={7} sm={7} md={7} lg={2}>
						<Typography
							variant="body1"
							component="div"
							color="text.secondary"
							gutterBottom
						>
							{comuneCorrente?.provincia}
						</Typography>
					</Grid>
				</Grid>
				<Divider
					textAlign="left"
					sx={{ my: 2, color: theme.palette.primary.main }}
				>
					Dati catastali del POD
				</Divider>
				<Grid container spacing={2} sx={{ mb: 2 }}>
					<Grid item xs={6} sm={4} md={3} lg={2}>
						<TextField
							label="foglio catastale"
							disabled={!consentiModifica}
							variant="outlined"
							InputLabelProps={{
								shrink: true,
							}}
							{...register("foglio_catastale")}
							error={!!errors?.foglio_catastale}
							helperText={errors?.foglio_catastale?.message}
							autoComplete={"off"}
							sx={{ width: "100%" }}
							fullWidth
							onBlur={handlerDetailChaingSubmit}
						/>
					</Grid>
					<Grid item xs={6} sm={4} md={3} lg={2}>
						<TextField
							label="particella catastale"
							disabled={!consentiModifica}
							variant="outlined"
							InputLabelProps={{
								shrink: true,
							}}
							{...register("particella_catastale")}
							error={!!errors?.particella_catastale}
							helperText={errors?.particella_catastale?.message}
							autoComplete={"off"}
							sx={{ width: "100%" }}
							fullWidth
							onBlur={handlerDetailChaingSubmit}
						/>
					</Grid>
					<Grid item xs={6} sm={4} md={3} lg={2}>
						<TextField
							label="sub_particella_catastale"
							disabled={!consentiModifica}
							variant="outlined"
							InputLabelProps={{
								shrink: true,
							}}
							{...register("sub_particella_catastale")}
							error={!!errors?.sub_particella_catastale}
							helperText={errors?.sub_particella_catastale?.message}
							autoComplete={"off"}
							sx={{ width: "100%" }}
							fullWidth
							onBlur={handlerDetailChaingSubmit}
						/>
					</Grid>

					<Grid item xs={6} sm={4} md={3} lg={2}>
						<TextField
							label="superficie calpestabile"
							disabled={!consentiModifica}
							variant="outlined"
							InputLabelProps={{
								shrink: true,
							}}
							{...register("superficie_calpestabile")}
							error={!!errors?.superficie_calpestabile}
							helperText={errors?.superficie_calpestabile?.message}
							autoComplete={"off"}
							sx={{ width: "100%" }}
							fullWidth
							onBlur={handlerDetailChaingSubmit}
						/>
					</Grid>
					<Grid item xs={6} sm={4} md={3} lg={2}>
						<TextField
							label="numero unita immobiliari"
							disabled={!consentiModifica}
							variant="outlined"
							InputLabelProps={{
								shrink: true,
							}}
							{...register("numero_unita_immobiliari")}
							error={!!errors?.numero_unita_immobiliari}
							helperText={errors?.numero_unita_immobiliari?.message}
							autoComplete={"off"}
							sx={{ width: "100%" }}
							fullWidth
							onBlur={handlerDetailChaingSubmit}
						/>
					</Grid>
					<Grid item xs={6} sm={4} md={3} lg={2}>
						<TextField
							label="anno costruzione"
							disabled={!consentiModifica}
							variant="outlined"
							InputLabelProps={{
								shrink: true,
							}}
							{...register("anno_costruzione")}
							error={!!errors?.anno_costruzione}
							helperText={errors?.anno_costruzione?.message}
							autoComplete={"off"}
							sx={{ width: "100%" }}
							fullWidth
							onBlur={handlerDetailChaingSubmit}
						/>
					</Grid>

					<Grid item xs={6} sm={4} md={3} lg={2}>
						<FormControlLabel
							key={
								watch("destinazione_residenziale")
									? "residenziale"
									: "non_residenziale"
							}
							value={watch("destinazione_residenziale")}
							checked={watch("destinazione_residenziale") || false}
							control={<Checkbox />}
							label={
								watch("destinazione_residenziale")
									? "destinazione residenziale"
									: "destinazione non residenziale"
							}
							labelPlacement="end"
							onChange={(e, checked) => {
								setValue("destinazione_residenziale", checked, {
									shouldDirty: true,
								});
							}}
							disabled={!consentiModifica}
							style={{ padding: 0 }}
						/>
						{!!errors?.destinazione_residenziale && (
							<FormHelperText error>
								{errors?.destinazione_residenziale.toString()}
							</FormHelperText>
						)}
					</Grid>
					<Grid item xs={6} sm={4} md={3} lg={2}>
						<ReactHookFormSelect
							name="titolo_possesso"
							label="titolo possesso"
							disabled={!consentiModifica}
							register={register}
							control={control}
							watch={watch}
							variant="outlined"
							error={errors?.titolo_possesso?.message}
							sx={{ width: "100%" }}
						>
							<MenuItem value="">
								<em>Nessuno</em>
							</MenuItem>

							{Object.entries(TitoloPossessoTypes).map(([key, value]) => {
								return (
									<MenuItem key={key} value={key}>
										{value}
									</MenuItem>
								);
							})}
						</ReactHookFormSelect>
					</Grid>
					<Grid item xs={6} sm={4} md={3} lg={2}>
						<ReactHookFormSelect
							name="tipologia_edilizia"
							label="tipologia edilizia"
							disabled={!consentiModifica}
							register={register}
							control={control}
							watch={watch}
							variant="outlined"
							error={errors?.tipologia_edilizia?.message}
							sx={{ width: "100%" }}
						>
							<MenuItem value="">
								<em>Nessuno</em>
							</MenuItem>

							{Object.entries(TipologiaEdiliziaTypes).map(([key, value]) => {
								return (
									<MenuItem key={key} value={key}>
										{value}
									</MenuItem>
								);
							})}
						</ReactHookFormSelect>
					</Grid>
					<Grid item xs={6} sm={4} md={3} lg={6}>
						<TextField
							label="note"
							disabled={!consentiModifica}
							variant="outlined"
							InputLabelProps={{
								shrink: true,
							}}
							{...register("tipologia_edilizia_note")}
							error={!!errors?.tipologia_edilizia_note}
							helperText={errors?.tipologia_edilizia_note?.message}
							autoComplete={"off"}
							sx={{ width: "100%" }}
							fullWidth
							onBlur={handlerDetailChaingSubmit}
						/>
					</Grid>
				</Grid>

				<Divider
					textAlign="left"
					sx={{ my: 2, color: theme.palette.primary.main }}
				>
					Valori Totali
				</Divider>
				<Grid container spacing={2} sx={{ mb: 2 }}>
					<Grid item xs={12} sm={6} md={6} lg={3}>
						<ReactHookFormSelect
							name="regime_commerciale"
							label="regime commerciale"
							disabled={!consentiModifica}
							register={register}
							control={control}
							watch={watch}
							variant="outlined"
							error={errors?.regime_commerciale?.message}
							sx={{ width: "100%" }}
						>
							<MenuItem value="" key="regime_vuoto">
								<em>Nessuno</em>
							</MenuItem>
							{regimiCommerciali.map((regime_commerciale) => {
								return (
									<MenuItem
										key={regime_commerciale.id}
										value={regime_commerciale.id}
									>
										{regime_commerciale.nome} - {regime_commerciale.descrizione}
									</MenuItem>
								);
							})}
						</ReactHookFormSelect>
					</Grid>
					<Grid item xs={12} sm={6} md={6} lg={3}>
						<ReactHookFormSelect
							name="gestore_rete"
							label="gestore rete"
							disabled={!consentiModifica}
							register={register}
							control={control}
							watch={watch}
							variant="outlined"
							error={errors?.gestore_rete?.message}
							sx={{ width: "100%" }}
						>
							<MenuItem value="" key="gestore_vuoto">
								<em>Nessuno</em>
							</MenuItem>
							{gestori.map((gestore_rete) => {
								return (
									<MenuItem key={gestore_rete.id} value={gestore_rete.id}>
										{gestore_rete.ragione_sociale}
									</MenuItem>
								);
							})}
						</ReactHookFormSelect>
					</Grid>

					<Grid item xs={12} sm={6} md={6} lg={3}>
						<ReactHookFormSelect
							name="tipo_tensione"
							label="tipo tensione"
							register={register}
							disabled={!consentiModifica}
							control={control}
							watch={watch}
							variant="outlined"
							error={errors?.tipo_tensione?.message}
							sx={{ width: "100%" }}
						>
							<MenuItem value="">
								<em>Nessuno</em>
							</MenuItem>

							{tipiTensione.map((tipoTensione) => {
								return (
									<MenuItem key={tipoTensione.id} value={tipoTensione.id}>
										{tipoTensione.nome} {tipoTensione.descrizione}
									</MenuItem>
								);
							})}
						</ReactHookFormSelect>
					</Grid>
				</Grid>
				<Grid container spacing={2} sx={{ mb: 2 }}>
					<Grid item xs={12} sm={6} md={6} lg={3}>
						<Controller
							name="potenza_immessa"
							control={control}
							render={({ field }) => (
								<NumericFormat
									id="potenza_immessa"
									customInput={TextField}
									variant="outlined"
									disabled={!consentiModifica}
									label="potenza immessa"
									InputLabelProps={{
										shrink: true,
									}}
									onBlur={handlerDetailChaingSubmit}
									error={!!errors?.potenza_immessa}
									helperText={errors?.potenza_immessa?.message}
									// {...register("potenza_unitaria")}
									value={field.value}
									autoComplete="off"
									prefix="kW "
									thousandSeparator="."
									decimalSeparator=","
									valueIsNumericString={true}
									onValueChange={(v) => {
										setValue("potenza_immessa", Number(v.value), {
											shouldDirty: true,
										});
									}}
									sx={{ width: "100%" }}
								/>
							)}
						/>
					</Grid>

					<Grid item xs={12} sm={6} md={6} lg={3}>
						<Controller
							name="potenza_nominale"
							control={control}
							render={({ field }) => (
								<NumericFormat
									id="potenza_nominale"
									customInput={TextField}
									variant="outlined"
									label="potenza nominale"
									disabled={!consentiModifica}
									InputLabelProps={{
										shrink: true,
									}}
									onBlur={handlerDetailChaingSubmit}
									error={!!errors?.potenza_nominale}
									helperText={errors?.potenza_nominale?.message}
									// {...register("potenza_unitaria")}
									value={field.value}
									autoComplete="off"
									prefix="kW "
									thousandSeparator="."
									decimalSeparator=","
									valueIsNumericString={true}
									onValueChange={(v) => {
										setValue("potenza_nominale", Number(v.value), {
											shouldDirty: true,
										});
									}}
									sx={{ width: "100%" }}
								/>
							)}
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={6} lg={3}>
						<Controller
							name="potenza_disponibile"
							control={control}
							render={({ field }) => (
								<NumericFormat
									id="potenza_disponibile"
									customInput={TextField}
									variant="outlined"
									label="potenza disponibile"
									disabled={!consentiModifica}
									InputLabelProps={{
										shrink: true,
									}}
									onBlur={handlerDetailChaingSubmit}
									error={!!errors?.potenza_disponibile}
									helperText={errors?.potenza_disponibile?.message}
									// {...register("potenza_unitaria")}
									value={field.value}
									autoComplete="off"
									prefix="kW "
									thousandSeparator="."
									decimalSeparator=","
									valueIsNumericString={true}
									onValueChange={(v) => {
										setValue("potenza_disponibile", Number(v.value), {
											shouldDirty: true,
										});
									}}
									sx={{ width: "100%" }}
								/>
							)}
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={6} lg={3}>
						<Box>
							{(isDirty ||
								podStatus == ErrorStatusTypes.SUCCESS ||
								podStatus == ErrorStatusTypes.ERROR) && (
								<Typography
									sx={{
										color: isDirty
											? theme.palette.warning.main
											: podStatus == ErrorStatusTypes.SUCCESS
											? theme.palette.success.main
											: podStatus == ErrorStatusTypes.ERROR
											? theme.palette.error.main
											: theme.palette.warning.main,
									}}
									variant="caption"
								>
									{podStatus == ErrorStatusTypes.PENDING && (
										<CircularProgress size={5} />
									)}

									{isDirty
										? "Modifiche non salvate"
										: podStatus == ErrorStatusTypes.SUCCESS
										? "Salvato"
										: podStatus == ErrorStatusTypes.ERROR
										? "Errore"
										: ""}
								</Typography>
							)}
						</Box>
					</Grid>
				</Grid>

				{/* <Button
					// disabled={!isDirty}
					variant="outlined"
					onClick={() => {
						setEditing(false);
						// reset();
					}}
					sx={{ ...(!editing && { display: "none" }) }}
				>
					Annulla
				</Button>
				<Button
					disabled={!isDirty}
					variant="outlined"
					type="submit"
					sx={{ ...(!editing && { display: "none" }), ml: 1 }}
				>
					Salva
				</Button> */}
			</form>
		</Box>
	);
};

export default PodDatiGeneraliForm;
