import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";

import {Soggetto, saveProduttore} from "../produttori/produttoriSlice";
import {useAppDispatch, RootState} from "../../../redux/store";
import {useNavigate} from "react-router-dom";
import {convertToDateTime} from "components/common/dateUtils/convertToDateTime";

import {TipoPratica} from "components/anagrafiche/tipiPratica/tipiPraticaSlice";

import {useForm, SubmitHandler} from "react-hook-form";
import {Comune} from "../comuni/comuniSlice";
import {resetNuovoIterConnessioneId} from "../../main/iterConnessioni/iterConnessioneSlice";
import {Routes_path} from "../../routerApp";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";

import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

import Alert from "../../common/Alert";
import Typography from "@mui/material/Typography";

import {Button, Divider} from "@mui/material";
import {theme} from "../../theme";

interface SoggettoFormProps {
    soggetto: Soggetto;
    consentiModifica?: boolean;
    ampliamento?: boolean;
    iterConnessione_id_to_refresh?: number;
}

const SoggettoForm = ({
                          soggetto,
                          consentiModifica,
                          ampliamento,
                          iterConnessione_id_to_refresh
                      }: SoggettoFormProps) => {
    const {
        handleSubmit,
        register,
        formState: {errors, isDirty, isValid},
        setValue,
        watch,
        getValues,
        setError,
        clearErrors,
        reset,
    } = useForm<Soggetto>();

    const listaCampi = Object.keys(soggetto) as Array<keyof Soggetto>;

    const dispatch = useAppDispatch();

    const navigate = useNavigate();
    const [pesonaFisica, setPesonaFisica] = useState<boolean>(true);

    const comuni = useSelector<RootState, Comune[]>(
        (state) => state.comuniState.comuni.results
    );

    const [comuneCorrente, setComuneCorrente] = useState<Comune | undefined>(
        undefined
    );

    const [comuneNascitaCorrente, setComuneNascitaCorrente] = useState<
        Comune | undefined
    >(undefined);

    const nuovoIterConnessioneId = useSelector<RootState, number | undefined>(
        (state) => state.iterConnessioniState.nuovoIterConnessioneId
    );

    useEffect(() => {
        nuovoIterConnessioneId &&
        navigate(Routes_path.ITER_CONNESSIONE + nuovoIterConnessioneId);
        dispatch(resetNuovoIterConnessioneId());
    }, [nuovoIterConnessioneId]);

    useEffect(
        () => {
            reset();
            listaCampi.map((field) => {
                setValue(field, soggetto[field]);
            });
        },
        [
            // pesonaFisica,
            // listaCampi.forEach((field) => {
            // 	soggetto[field];
            // }),
            // soggetto.id,
        ]
    );

    useEffect(() => {
        setPesonaFisica(getValues("tipo_persona") == "PF");
    }, [getValues("tipo_persona")]);

    useEffect(() => {
        setComuneCorrente(
            comuni.find((comuneScelto) => comuneScelto.id == getValues("comune"))
        );
    }, [comuni, getValues("comune"), pesonaFisica]);

    useEffect(() => {
        setComuneNascitaCorrente(
            comuni.find(
                (comuneScelto) => comuneScelto.id == getValues("comune_nascita")
            )
        );
    }, [comuni, watch("comune_nascita"), pesonaFisica]);

    useEffect(() => {
        clearErrors();
        listaCampi.forEach((field) => {
            soggetto?.errorsStack?.fieldsErrors &&
            soggetto.errorsStack.fieldsErrors[field] &&
            setError(field, {
                type: "server",
                message: soggetto.errorsStack.fieldsErrors[field].toString(),
            });
        });
    }, [
        soggetto?.errorsStack?.fieldsErrors,
        // soggetto.allevamento_pack?.errorsStack?.fieldsErrors,
    ]);

    const handlerSaveProduttore = (soggetto: Soggetto) => {
        consentiModifica && dispatch(saveProduttore({produttoreToSave: soggetto, iterConnessione_id_to_refresh: iterConnessione_id_to_refresh}));
    };

    const onSubmit: SubmitHandler<Soggetto> = (soggetto) => {
        handlerSaveProduttore(soggetto);
    };

    return (
        <Box sx={{my: 1, pt: 2}}>
            <form onSubmit={handleSubmit(onSubmit)}>
                {soggetto?.errorsStack?.fieldsErrors?.non_field_errors && (
                    <Alert severity="error">
                        {soggetto?.errorsStack?.fieldsErrors?.non_field_errors.toString()}
                    </Alert>
                )}
                {soggetto.tipo_persona == "PF" ? (
                    <Grid container spacing={2} sx={{mb: 2}}>
                        <Grid item xs={12} sm={6} md={6} lg={2}>
                            <Typography variant="caption">nome:</Typography>
                            <Typography>{soggetto.nome}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={2}>
                            <Typography variant="caption">cognome:</Typography>

                            <Typography>{soggetto.cognome}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={2}>
                            <Typography variant="caption">codice fiscale:</Typography>

                            <Typography>{soggetto.codice_fiscale}</Typography>
                        </Grid>

                        <Grid item xs={12} sm={6} md={6} lg={2}>
                            <Typography variant="caption">comune nascita:</Typography>

                            <Typography>{comuneNascitaCorrente?.nome}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={2}>
                            <Typography variant="caption">nazione nascita:</Typography>
                            <Typography>{soggetto.nazione_nascita}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={2}>
                            <Typography variant="caption">data nascita:</Typography>

                            <Typography>
                                {convertToDateTime(soggetto.data_nascita)
                                    ?.setLocale("it")
                                    .toFormat("dd LLLL yyyy")}
                            </Typography>
                        </Grid>
                    </Grid>
                ) : (
                    <Grid container spacing={2} sx={{mb: 2}}>
                        <Grid item xs={12} sm={6} md={6} lg={3}>
                            <Typography variant="caption">ragione sociale:</Typography>
                            <Typography>{soggetto.ragione_sociale}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={3}>
                            <Typography variant="caption">Partita Iva:</Typography>

                            <Typography>{soggetto.piva}</Typography>
                        </Grid>
                    </Grid>
                )}
                <Divider
                    textAlign="left"
                    sx={{mb: 2, color: theme.palette.primary.main}}
                >
                    Indirizzo di residenza
                </Divider>

                <Grid container spacing={2} sx={{mb: 2}}>
                    <Grid item xs={12} sm={6} md={6} lg={4}>
                        <TextField
                            label="indirizzo*"
                            disabled={!consentiModifica}
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            {...register("indirizzo")}
                            error={!!errors?.indirizzo}
                            helperText={errors?.indirizzo?.message}
                            autoComplete={"off"}
                            sx={{width: "100%"}}
                            fullWidth
                            // onBlur={handlerDetailChaingSubmit}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={2}>
                        <TextField
                            label="numero civico*"
                            disabled={!consentiModifica}
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            {...register("numero_civico")}
                            error={!!errors?.numero_civico}
                            helperText={errors?.numero_civico?.message}
                            autoComplete={"off"}
                            sx={{width: "100%"}}
                            fullWidth
                            // onBlur={handlerDetailChaingSubmit}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={2}>
                        <TextField
                            label="cap*"
                            disabled={!consentiModifica}
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            {...register("cap")}
                            error={!!errors?.cap}
                            helperText={errors?.cap?.message}
                            autoComplete={"off"}
                            sx={{width: "100%"}}
                            fullWidth
                            // onBlur={handlerDetailChaingSubmit}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={2}>
                        <Autocomplete
                            key={comuneCorrente?.id}
                            disabled={!consentiModifica}
                            value={comuneCorrente}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            onChange={(event: any, newValue: any) => {
                                setValue("comune", newValue ? newValue.id : null, {
                                    shouldDirty: true,
                                });
                            }}
                            id="comune"
                            options={comuni}
                            getOptionLabel={(option) => option.nome}
                            renderOption={(props, option) => (
                                <Box component="li" {...props}>
                                    {option.nome}
                                </Box>
                            )}
                            renderInput={(params: any) => (
                                <TextField
                                    variant="outlined"
                                    {...params}
                                    label="Comune*"
                                    error={!!errors?.comune}
                                    helperText={errors?.comune?.message}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={2}>
                        <Typography
                            variant="body1"
                            component="div"
                            color="text.secondary"
                            gutterBottom
                        >
                            {comuneCorrente?.provincia}
                        </Typography>
                    </Grid>
                </Grid>
                {ampliamento && (
                    <>
                        <Divider
                            textAlign="left"
                            sx={{mb: 2, color: theme.palette.primary.main}}
                        >
                            Credenziali GSE e GAUDI
                        </Divider>
                        <Grid container spacing={2} sx={{mb: 2}}>
                            <Grid item xs={12} sm={6} md={6} lg={3}>
                                <TextField
                                    label="utente GSE"
                                    disabled={!consentiModifica}
                                    variant="outlined"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    {...register("gse_id")}
                                    error={!!errors?.gse_id}
                                    helperText={errors?.gse_id?.message}
                                    autoComplete={"off"}
                                    sx={{width: "100%"}}
                                    fullWidth
                                    // onBlur={handlerDetailChaingSubmit}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={3}>
                                <TextField
                                    label="password GSE"
                                    disabled={!consentiModifica}
                                    variant="outlined"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    {...register("gse_psw")}
                                    error={!!errors?.gse_psw}
                                    helperText={errors?.gse_psw?.message}
                                    autoComplete={"off"}
                                    sx={{width: "100%"}}
                                    fullWidth
                                    // onBlur={handlerDetailChaingSubmit}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6} md={6} lg={3}>
                                <TextField
                                    label="utente Gaudì"
                                    disabled={!consentiModifica}
                                    variant="outlined"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    {...register("gaudi_id")}
                                    error={!!errors?.gaudi_id}
                                    helperText={errors?.gaudi_id?.message}
                                    autoComplete={"off"}
                                    sx={{width: "100%"}}
                                    fullWidth
                                    // onBlur={handlerDetailChaingSubmit}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={3}>
                                <TextField
                                    label="password Gaudì"
                                    disabled={!consentiModifica}
                                    variant="outlined"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    {...register("gaudi_psw")}
                                    error={!!errors?.gaudi_psw}
                                    helperText={errors?.gaudi_psw?.message}
                                    autoComplete={"off"}
                                    sx={{width: "100%"}}
                                    fullWidth
                                    // onBlur={handlerDetailChaingSubmit}
                                />
                            </Grid>
                        </Grid>
                    </>
                )}
                <Divider
                    textAlign="left"
                    sx={{mb: 2, color: theme.palette.primary.main}}
                >
                    Contatti
                </Divider>

                <Grid container spacing={2} sx={{mb: 2}}>
                    <Grid item xs={12} sm={6} md={6} lg={4}>
                        <TextField
                            label="cellulare*"
                            disabled={!consentiModifica}
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            {...register("cellulare")}
                            error={!!errors?.cellulare}
                            helperText={errors?.cellulare?.message}
                            autoComplete={"off"}
                            sx={{width: "100%"}}
                            fullWidth
                            // onBlur={handlerDetailChaingSubmit}
                        />
                    </Grid>
                    {/* <Grid item xs={12} sm={6} md={6} lg={4}>
						<TextField
							label="telefono fisso*"
							// disabled={schedaCarico.numero_capi_pianificati}
							variant="outlined"
							InputLabelProps={{
								shrink: true,
							}}
							{...register("telefono_fisso")}
							error={!!errors?.telefono_fisso}
							helperText={errors?.telefono_fisso?.message}
							autoComplete={"off"}
							sx={{ width: "100%" }}
							fullWidth
							// onBlur={handlerDetailChaingSubmit}
						/>
					</Grid> */}
                    <Grid item xs={12} sm={6} md={6} lg={4}>
                        <TextField
                            label="email*"
                            disabled={!consentiModifica}
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            {...register("email")}
                            error={!!errors?.email}
                            helperText={errors?.email?.message}
                            autoComplete={"off"}
                            sx={{width: "100%"}}
                            fullWidth
                            // onBlur={handlerDetailChaingSubmit}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={2}>
                        <Stack
                            direction="row"
                            spacing={2}
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Button
                                disabled={!isDirty || !consentiModifica}
                                variant="outlined"
                                type="submit"
                                // sx={{ display: "none" }}
                            >
                                Salva
                            </Button>
                        </Stack>
                    </Grid>
                </Grid>
            </form>
        </Box>
    );
};

export default SoggettoForm;
