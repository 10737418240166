import React from "react";

import {Commessa,} from "../../commesseSlice";
import Box from "@mui/material/Box";

import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import AllegatiPage from "../../../../allegati/AllegatiPage";
import InstallazioneInCorsoForm from "../esecuzione/installazioneInCorso/InstallazioneInCorsoForm";
import CompletamentoCommessaForm from "./completamentoCommessa/CompletamentoCommessaForm";
import ConsegnaDocumentiForm from "./consegnaDocumenti/ConsegnaDocumentiForm";
import FatturaSaldoForm from "./fatturaSaldo/FatturaSaldoForm";
import Typography from "@mui/material/Typography";
import IterConnessioniCommessaGrid from "../inLavorazione/IterConnessioniCommessaGrid";

interface FineLAvoriTabProps {
    commessa: Commessa;
    consentiModifica: boolean;
    consentiModificaSoloGenralContractor: boolean;
}

const FineLavoriTab = ({commessa, consentiModifica, consentiModificaSoloGenralContractor}: FineLAvoriTabProps) => {

        return (
            <Box
                sx={{
                    backgroundColor: "#fff",
                    elevation: 20,
                    p: 2,
                }}
            >

                <Card elevation={3} sx={{mt: 2}}>
                    <CardHeader
                        sx={{backgroundColor: commessa.stato != "I" ? "#249949" : "#FF2222aa", color: "#dee"}}
                        title={"Fattura di Saldo"}
                    />

                    <CardContent>
                        <FatturaSaldoForm
                            commessa={commessa}
                            consentiModifica={consentiModificaSoloGenralContractor && commessa.stato == "I"}
                        />
                    </CardContent>
                </Card>
                {['L', 'M', 'N'].includes(commessa.stato || "") &&
                    < Card elevation={3} sx={{mt: 2}}>
                        <CardHeader
                            sx={{backgroundColor: commessa.stato != "L" ? "#249949" : "#FF2222aa", color: "#dee"}}
                            title={"Consegna Documenti"}
                        />

                        <CardContent>
                            <Box sx={{mb: 2}}>
                                <IterConnessioniCommessaGrid commessa={commessa}/>
                            </Box>

                            <ConsegnaDocumentiForm
                                commessa={commessa}
                                consentiModifica={consentiModifica && commessa.stato == "L"}
                            />
                        </CardContent>
                    </Card>
                }
                {['M', 'N'].includes(commessa.stato || "") &&
                    <Card elevation={3} sx={{mt: 2}}>
                        <CardHeader
                            sx={{backgroundColor: commessa.stato != "M" ? "#249949" : "#FF2222aa", color: "#dee"}}

                            title={"Completamento Commessa"}
                        />
                        <CardContent>

                            <CompletamentoCommessaForm
                                commessa={commessa}
                                consentiModifica={consentiModificaSoloGenralContractor && commessa.stato == "M"}
                            />

                        </CardContent>
                    </Card>
                }
            </Box>
        )
            ;
    }
;

export default FineLavoriTab;
