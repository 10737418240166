import {Commessa, TipoIntervento, ProdottiPrevistiPerIntervento} from "../commesseSlice";

export const prodottiPrevistiCommessa = (commessa: Commessa, tipiIntervento: TipoIntervento[]) => {

    const prodottiPrevistiCommessa: ProdottiPrevistiPerIntervento[] = []
    const tipiInterventoCommessa = tipiIntervento.filter((tipo) => commessa.tipi_intervento?.includes(tipo.id))

    for (const tipo of tipiInterventoCommessa) {
        // inserisco in prodottiPrevisti i prodotti previsti da tipo se non ci sono già
        for (const prodotto of tipo.prodotti_previsti) {
            if (!prodottiPrevistiCommessa.find((prodottoPrevisto) => (prodottoPrevisto.tipo_prodotto == prodotto.tipo_prodotto && prodottoPrevisto.esistente == prodotto.esistente))) {
                prodottiPrevistiCommessa.push(prodotto)
            } else {
                if (prodotto.obbligatorio){
                    const prodottoIndex = prodottiPrevistiCommessa.findIndex(
                        (prodottoPrevisto) =>
                            prodottoPrevisto.tipo_prodotto === prodotto.tipo_prodotto &&
                            prodottoPrevisto.esistente === prodotto.esistente
                    );

                    if (prodottoIndex !== -1) {
                        prodottiPrevistiCommessa.splice(prodottoIndex, 1); // Rimuove l'elemento dall'array
                    }
                    prodottiPrevistiCommessa.push(prodotto)
                }
            }
        }
    }
    return prodottiPrevistiCommessa
}
