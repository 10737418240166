export interface StatoSintetico {
	statiBase: string[];
	descrizione: string;
}

export const statiSintetici: StatoSintetico[] = [
	{ statiBase: ["A", "B"], descrizione: "bozza" },
	{ statiBase: ["C"], descrizione: "richiesta da prendere in carico" },
	{ statiBase: ["D", "E", "F", "G"], descrizione: "progetto" },
	// torno a splittare questi 3 stati
	// { statiBase: ["H", "I", "L"], descrizione: "connessione" },
	{ statiBase: ["H"], descrizione: "preventivo" },
	{ statiBase: ["I"], descrizione: "fine lavori" },
	{ statiBase: ["L"], descrizione: "allaccio" },
	{ statiBase: ["M"], descrizione: "autorizzazione esercizio" },
	{ statiBase: ["N"], descrizione: "consegnato" },
];

export const EsponiStatoSintetico = (
	statoIterConnessione: string | undefined
) => {
	var statoSintetico = "";
	statiSintetici.map((sS) => {
		if (statoIterConnessione && sS.statiBase.includes(statoIterConnessione)) {
			statoSintetico = sS.descrizione;
		}
	});
	return statoSintetico;

	// var statoSintetico = "";
	// switch (statoIterConnessione) {
	// 	case "A":
	// 	case "B":
	// 	case "C":
	// 		statoSintetico = "richiesta di presa in carico";
	// 		break;
	// 	case "D":
	// 	case "E":
	// 	case "F":
	// 	case "G":
	// 		statoSintetico = "progetto";
	// 		break;
	// 	case "H":
	// 	case "I":
	// 	case "L":
	// 		statoSintetico = "connessione";
	// 		break;
	// 	case "M":
	// 		statoSintetico = "autorizzazione esercizio";
	// 		break;
	// 	case "N":
	// 		statoSintetico = "consegnato";
	// 		break;
	// 	default:
	// 		statoSintetico = "";
	// }
	// return statoSintetico;
};
