import React, {useEffect,} from "react";
import {useSelector} from "react-redux";

import {
    Commessa,
    saveCommessa,
} from "../../commesseSlice";
import {useAppDispatch, RootState} from "../../../../../redux/store";
import {
    ErrorStatusTypes,
} from "../../../../common/errorsDeclarations";

import {theme} from "../../../../theme";


import {useForm, SubmitHandler,} from "react-hook-form";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Alert from "../../../../common/Alert";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import {convertToDateTime} from "../../../../common/dateUtils/convertToDateTime";

import Button from "@mui/material/Button";

import {DesktopDatePicker} from "@mui/x-date-pickers/DesktopDatePicker";

interface AccontoFormProps {
    commessa: Commessa;
    consentiModifica?: boolean;
}

const AccontoForm = ({
                         commessa,
                         consentiModifica = false,
                     }: AccontoFormProps) => {
    const {
        handleSubmit,
        register,
        formState: {errors, isDirty, isValid},
        setValue,
        watch,
        getValues,
        control,
        setError,
        clearErrors,
        reset,
    } = useForm<Commessa>();

    const listaCampi = Object.keys(commessa) as Array<keyof Commessa>;

    const dispatch = useAppDispatch();

    const commesseStatus = useSelector<RootState, ErrorStatusTypes>(
        (state) => state.commesseState.commesse.errorsStack.status
    );

    useEffect(() => {
        reset();
        console.log('reset')
        listaCampi.map((field) => {
            setValue(field, commessa[field]);
        });
        // setKeycodice_pod(makeid(5));
    }, [commessa.data_fattura_acconto_inviata, commessa.id]);

    useEffect(() => {
        clearErrors();
        listaCampi.forEach((field) => {
            commessa?.errorsStack?.fieldsErrors && commessa.errorsStack.fieldsErrors[field] &&
            setError(field, {
                type: "server",
                message: commessa.errorsStack.fieldsErrors[field].toString(),
            });
        });
    }, [
        commessa?.errorsStack?.fieldsErrors,
        // commessa.allevamento_pack?.errorsStack?.fieldsErrors,
    ]);

    const handlerSaveCommessa = (commessaToSave: Commessa) => {
        if (commessaToSave != commessa) {
            consentiModifica && commessa && dispatch(saveCommessa({commessaToSave: commessaToSave,}));
        }
    };

    const onSubmit: SubmitHandler<Commessa> = (commessa) => {
        handlerSaveCommessa(commessa);
    };

    useEffect(() => {
        if (isValid && isDirty) {
            const formValues = getValues();
            handlerSaveCommessa(formValues);
        }
    }, [
        watch("data_fattura_acconto_inviata"),
        watch("data_fattura_acconto_pagata"),
    ]);

    return (
        <Box sx={{mb: 1}}>
            <form onSubmit={handleSubmit(onSubmit)}>
                {commessa?.errorsStack?.fieldsErrors?.non_field_errors && (
                    <Alert severity="error">
                        {commessa?.errorsStack?.fieldsErrors?.non_field_errors.toString()}
                    </Alert>
                )}
                <Grid
                    container
                    spacing={2}
                    sx={{my: 2, justifyContent: "center",}}
                    // display="flex"
                    // justifyContent={"center"}
                >
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        md={6}
                        lg={12}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Typography variant="h5">
                            {commessa.data_pronta_per_acconto
                                && `Amministrazione avvertita il ${convertToDateTime(
                                    commessa.data_pronta_per_acconto
                                )?.setLocale("it").toFormat("dd/MM/yyyy")}`}
                        </Typography>

                    </Grid>
                </Grid>
                <Grid container spacing={2} sx={{my: 2, justifyContent: "center",}}>
                    <Grid item xs={12} sm={6} md={6} lg={4}>
                        <DesktopDatePicker
                            value={convertToDateTime(commessa.data_fattura_acconto_inviata)}
                            label="data invio fattura acconto"
                            disabled={!consentiModifica}
                            slotProps={{
                                textField: {
                                    required: false,
                                    error: !!errors?.data_fattura_acconto_inviata,
                                    helperText: errors?.data_fattura_acconto_inviata?.message,
                                    sx: {width: "100%"},
                                    autoComplete: "off",
                                    // disabled: !!richiestaConnessione.stato
                                },
                                field: {
                                    clearable: true,
                                },
                            }}
                            onChange={(newValue: any) => {
                                setValue(
                                    "data_fattura_acconto_inviata",
                                    // newValue ? newValue.toJSDate() : "",
                                    newValue
                                        ? convertToDateTime(newValue)?.toFormat("yyyy-MM-dd")
                                        : "",
                                    {
                                        shouldDirty: true,
                                    }
                                );
                                clearErrors("data_fattura_acconto_inviata");
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4}>
                        <DesktopDatePicker
                            label="data pagamento fattura acconto"
                            value={convertToDateTime(commessa.data_fattura_acconto_pagata)}

                            disabled={
                                !commessa.data_fattura_acconto_inviata || !consentiModifica
                            }

                            slotProps={{
                                textField: {
                                    required: false,
                                    error: !!errors?.data_fattura_acconto_pagata,
                                    helperText: errors?.data_fattura_acconto_pagata?.message,
                                    sx: {width: "100%"},
                                    autoComplete: "off",
                                    // disabled: !!richiestaConnessione.stato
                                },
                                field: {
                                    clearable: true,
                                },
                            }}
                            onChange={(newValue: any) => {
                                setValue(
                                    "data_fattura_acconto_pagata",
                                    // newValue ? newValue.toJSDate() : "",
                                    newValue
                                        ? convertToDateTime(newValue)?.toFormat("yyyy-MM-dd")
                                        : "",
                                    {
                                        shouldDirty: true,
                                    }
                                );
                                clearErrors("data_fattura_acconto_pagata");
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2} sx={{my: 2, justifyContent: "center",}}>

                    <Grid item xs={12} sm={6} md={6} lg={6}>
                        <Box>
                            {(isDirty ||
                                commesseStatus == ErrorStatusTypes.SUCCESS ||
                                commesseStatus == ErrorStatusTypes.ERROR) && (
                                <Typography
                                    sx={{
                                        color: isDirty
                                            ? theme.palette.warning.main
                                            : commesseStatus == ErrorStatusTypes.SUCCESS
                                                ? theme.palette.success.main
                                                : commesseStatus == ErrorStatusTypes.ERROR
                                                    ? theme.palette.error.main
                                                    : theme.palette.warning.main,
                                    }}
                                    variant="caption"
                                >
                                    {commesseStatus == ErrorStatusTypes.PENDING && (
                                        <CircularProgress size={5}/>
                                    )}

                                    {isDirty
                                        ? "Modifiche non salvate"
                                        : commesseStatus == ErrorStatusTypes.SUCCESS
                                            ? "Salvato"
                                            : commesseStatus == ErrorStatusTypes.ERROR
                                                ? "Errore"
                                                : ""}
                                </Typography>
                            )}
                        </Box>
                        {
                            commessa.stato == "B" &&
                            <Button
                                variant="contained"
                                sx={{width: '100%'}}
                                onClick={() => {
                                    dispatch(
                                        saveCommessa({
                                            commessaToSave: commessa,
                                            azione: "avvia_analisi",
                                        })
                                    );
                                }}
                                disabled={!consentiModifica || !commessa.data_fattura_acconto_pagata}
                            >
                                Segnala Acconto Pagato
                            </Button>
                        }
                    </Grid>
                </Grid>


            </form>
        </Box>
    );
};

export default AccontoForm;
