import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {useParams} from "react-router-dom";

import {RootState, useAppDispatch} from "../../../../redux/store";

import ContraenteTab from "./ContraenteTab";
import TitolareTab from "./TitolareTab";
import ProduttoreTab from "./ProduttoreTab";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

import {
    ErrorStatusTypes,
    ErrorsStack,
} from "../../../common/errorsDeclarations";

import {PermissionTypes} from "../../../authentication/permissionsGroups";
import {utenteAutorizzato} from "../../../authentication/UtenteAutorizzato";
import {UtenteLogged} from "../../../authentication/authenticationSlice";
import {
    Commessa,
    getCommessa,
    resetNuovaCommessaId,
    TipoIntervento,
} from "../commesseSlice";
import {Cliente} from "../../../anagrafiche/clienti/clientiSlice";
import {Soggetto} from "../../../anagrafiche/produttori/produttoriSlice";
import {fetchSopralluoghi} from "components/main/sopralluoghi/sopralluoghiSlice";
import {fetchVerificheVincoli} from "components/main/verificheVincoli/verificheVincoliSlice";

import {getIterConnessione} from "components/main/iterConnessioni/iterConnessioneSlice";

import CommessaForm from "./CommessaForm";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import PodTab from "./podCommessa/PodTab";
import MaterialiTab from "./materiali/MaterialiTab";
import EsecuzioneTab from "./esecuzione/EsecuzioneTab";
import FineLavoriTab from "./fineLavori/FineLavoriTab";
import AnalisiTab from "./inLavorazione/AnalisiTab";
import AccontoTab from "./acconto/AccontoTab";
import {theme} from "../../../theme";
import CommessaPageBreadcrumbs from "./CommessaPageBreadcrumbs";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import Typography from "@mui/material/Typography";
import {Box} from "@mui/system";
import {getProduttore} from "../../../anagrafiche/produttori/produttoriSlice";
import Chip from "@mui/material/Chip";
import Paper from "@mui/material/Paper";
import {prevedePod} from "./prevedePod";


const CommessaPage = () => {
    const dispatch = useAppDispatch();
    const {commessa_id} = useParams();
    resetNuovaCommessaId();

    const [commessaId, setCommessaId] = useState<number>(
        parseInt(commessa_id || "")
    );

    const utente = useSelector<RootState, UtenteLogged>(
        (state) => state.authentication.utenteLogged
    );
    const commesseErrorsStack = useSelector<RootState, ErrorsStack>(
        (state) => state.commesseState.commesse.errorsStack
    );

    const nuovaCommessaId = useSelector<RootState, number | undefined>(
        (state) => state.commesseState.nuovaCommessaId
    );

    useEffect(() => {
        if (commessaId) {
            dispatch(getCommessa({commessaId}));
        }
    }, []);

    useEffect(() => {
        nuovaCommessaId && setCommessaId(nuovaCommessaId);
    }, [nuovaCommessaId]);

    const commessa: Commessa | undefined = useSelector<
        RootState,
        Commessa | undefined
    >((state) =>
        state.commesseState.commesse.results.find(
            (commessa) => commessa?.id == commessaId
        )
    );

    useEffect(() => {
        if (commessa && commessa.contraente) {
            dispatch(getProduttore(commessa.contraente));
            if (
                commessa.titolare_immobile &&
                commessa.contraente != commessa.titolare_immobile
            ) {
                dispatch(getProduttore(commessa.titolare_immobile));
            }
            if (commessa.produttore && commessa.contraente != commessa.produttore) {
                dispatch(getProduttore(commessa.produttore));
            }
        }
        if (commessa) {
            commessa.iterConnessione &&
            dispatch(
                getIterConnessione({iterConnessioneId: commessa.iterConnessione})
            );
            dispatch(fetchSopralluoghi({commessa: commessa.id}));
            dispatch(fetchVerificheVincoli({commessa: commessa.id}));
        }
    }, [commessa]);

    const cliente: Cliente | undefined = useSelector<
        RootState,
        Cliente | undefined
    >((state) =>
        state.clientiState.clienti.results.find(
            (cliente) => cliente?.id == commessa?.cliente
        )
    );

    const contraente: Soggetto | undefined = useSelector<
        RootState,
        Soggetto | undefined
    >((state) =>
        state.produttoriState.produttori.results.find(
            (produttore) => produttore?.id == commessa?.contraente
        )
    );
    const titolare_immobile: Soggetto | undefined = useSelector<
        RootState,
        Soggetto | undefined
    >((state) =>
        state.produttoriState.produttori.results.find(
            (produttore) => produttore?.id == commessa?.titolare_immobile
        )
    );
    const produttore: Soggetto | undefined = useSelector<
        RootState,
        Soggetto | undefined
    >((state) =>
        state.produttoriState.produttori.results.find(
            (produttore) => produttore?.id == commessa?.produttore
        )
    );

    // useEffect(() => {
    // 	if (commessa) {
    // 		dispatch(getCommessa(commessaId));
    // 		dispatch(getProgettoFromIter(commessaId));
    // 	}
    // }, [produttore?.email_alias]);

    const tipiIntervento = useSelector<RootState, TipoIntervento[]>(
        (state) => state.commesseState.tipiIntervento.results
    );

    const [currentTab, setCurrentTab] = useState("contraente_tab");
    const handleTabChange = (event: any, newValue: string) => {
        setCurrentTab(newValue);
    };

    useEffect(() => {
        commessa?.stato == "A" && setCurrentTab("contraente_tab");
        commessa?.stato == "B" && setCurrentTab("acconto_tab");
        commessa?.stato == "C" && setCurrentTab("analisi_tab");
        commessa?.stato == "D" && setCurrentTab("analisi_tab");
        [ "E", "F", "G", "H"].includes(commessa?.stato || "") &&
        setCurrentTab("esecuzione_tab");
        ["I", "L", "M", "N"].includes(commessa?.stato || "") && setCurrentTab("9");
    }, [commessa?.stato]);

    const consentiModifica = utenteAutorizzato(utente, [
        PermissionTypes.GENERAL_CONTRACTOR,
        PermissionTypes.BCK_OFFICE_GENERAL_CONTRACTOR,
    ]);

    const consentiModificaSoloGenralContractor = utenteAutorizzato(utente, [
        PermissionTypes.GENERAL_CONTRACTOR,
    ]);

    return (
        <Box
            sx={{
                backgroundColor: "#fff",
                elevation: 20,
                p: 2,
            }}
        >
            <Backdrop
                sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={commesseErrorsStack.status == ErrorStatusTypes.PENDING}
            >
                <CircularProgress color="inherit"/>
            </Backdrop>
            <Box sx={{backgroundColor: "#929099"}}>
                <Typography variant="h3" gutterBottom color={"#eee"} sx={{p: 2}}>
                    <Chip
                        label={commessa?.id}
                        size="medium"
                        color="primary"
                        sx={{mr: 2, fontSize: "1.5rem"}}
                    />

                    {`commessa ${commessa?.codice_commessa_legacy}`}
                </Typography>
            </Box>
            <CommessaPageBreadcrumbs identificativoPosizione={commessa?.id}/>
            <Paper elevation={2} sx={{p: 2, backgroundColor: "#eee"}}>
                {commessa ? (
                    <Box>
                        <CommessaForm
                            commessa={commessa}
                            consentiModifica={consentiModificaSoloGenralContractor}
                        />
                        {commessa.tipi_intervento && commessa.tipi_intervento?.length > 0 && (
                            <TabContext value={currentTab}>
                                <Box
                                    sx={{
                                        borderBottom: 1,
                                        borderColor: "divider",
                                    }}
                                >
                                    <TabList
                                        onChange={handleTabChange}
                                        aria-label="lab API tabs example"
                                        variant="scrollable"
                                        scrollButtons="auto"
                                    >
                                        <Tab
                                            label={
                                                <Typography
                                                    variant="h5"
                                                    sx={{
                                                        color: commessa.contraente_completo
                                                            ? theme.palette.primary.main
                                                            : theme.palette.error.main,
                                                    }}
                                                >
                                                    1.CONTRAENTE <DoubleArrowIcon/>
                                                </Typography>
                                            }
                                            value="contraente_tab"
                                        />
                                        <Tab
                                            disabled={!commessa.contraente}
                                            label={
                                                <Typography
                                                    variant="h5"
                                                    sx={{
                                                        color: !commessa.contraente
                                                            ? "inherit"
                                                            : !commessa.titolare_immobile
                                                                ? theme.palette.error.main
                                                                : commessa.titolare_immobile ==
                                                                commessa.contraente
                                                                    ? "#0289b055"
                                                                    : commessa.titolare_completo
                                                                        ? theme.palette.primary.main
                                                                        : theme.palette.error.main,
                                                    }}
                                                >
                                                    2.TITOLARE <DoubleArrowIcon/>
                                                </Typography>
                                            }
                                            value="titolare_tab"
                                        />
                                        <Tab
                                            disabled={!commessa.contraente}
                                            label={
                                                <Typography
                                                    variant="h5"
                                                    sx={{
                                                        color: !commessa.contraente
                                                            ? "inherit"
                                                            : !commessa.produttore
                                                                ? theme.palette.error.main
                                                                : commessa.produttore == commessa.contraente
                                                                    ? "#0289b055"
                                                                    : commessa.produttore_completo
                                                                        ? theme.palette.primary.main
                                                                        : theme.palette.error.main,
                                                    }}
                                                >
                                                    3.PRODUTTORE <DoubleArrowIcon/>
                                                </Typography>
                                            }
                                            value="produttore_tab"
                                        />

                                        {prevedePod(commessa, tipiIntervento) && (
                                            <Tab
                                                disabled={!commessa.contraente}
                                                label={
                                                    <Typography
                                                        variant="h5"
                                                        sx={{
                                                            color: !commessa.contraente
                                                                ? "inherit"
                                                                : commessa.pod_completo
                                                                    ? theme.palette.primary.main
                                                                    : theme.palette.error.main,
                                                        }}
                                                    >
                                                        4.POD <DoubleArrowIcon/>
                                                    </Typography>
                                                }
                                                value="pod_tab"
                                            />
                                        )}
                                        <Tab
                                            disabled={
                                                !commessa.contraente ||
                                                (prevedePod(commessa, tipiIntervento) && !commessa.pod) ||
                                                !(commessa.tipi_intervento && commessa.tipi_intervento.length > 0)
                                            }
                                            label={
                                                <Typography
                                                    variant="h5"
                                                    sx={{
                                                        color:
                                                            !commessa.contraente ||
                                                            (prevedePod(commessa, tipiIntervento) && commessa.pod) ||
                                                            !(commessa.tipi_intervento && commessa.tipi_intervento.length > 0)
                                                                ? "inherit"
                                                                : commessa.materiali_completo
                                                                    ? theme.palette.primary.main
                                                                    : theme.palette.error.main,
                                                    }}
                                                >
                                                    5.MATERIALI <DoubleArrowIcon/>
                                                </Typography>
                                            }
                                            value="materiali_tab"
                                        />
                                        <Tab
                                            disabled={["A", undefined].includes(commessa.stato)}
                                            label={
                                                <Typography
                                                    variant="h5"
                                                    sx={{
                                                        color:
                                                            commessa.stato == "A"
                                                                ? "inherit"
                                                                : commessa.stato != "B"
                                                                    ? theme.palette.primary.main
                                                                    : theme.palette.error.main,
                                                    }}
                                                >
                                                    6.ACCONTO <DoubleArrowIcon/>
                                                </Typography>
                                            }
                                            value="acconto_tab"
                                        />
                                        <Tab
                                            disabled={["A", "B", undefined].includes(commessa.stato)}
                                            label={
                                                <Typography
                                                    variant="h5"
                                                    sx={{
                                                        color: ["A", "B"].includes(commessa.stato || "")
                                                            ? "inherit"
                                                            : commessa.stato != "C"
                                                                ? theme.palette.primary.main
                                                                : theme.palette.error.main,
                                                    }}
                                                >
                                                    7.Analisi/Progetto/Pratiche
                                                    <DoubleArrowIcon/>
                                                </Typography>
                                            }
                                            value="analisi_tab"
                                        />

                                        <Tab
                                            disabled={["A", "B", undefined].includes(commessa.stato)}
                                            label={
                                                <Typography
                                                    variant="h5"
                                                    sx={{
                                                        color: ["A", "B", "C"].includes(
                                                            commessa.stato || ""
                                                        )
                                                            ? "inherit"
                                                            : ["D", "E", "F", "G", "H"].includes(
                                                                commessa.stato || ""
                                                            )
                                                                ? theme.palette.error.main
                                                                : theme.palette.primary.main,
                                                    }}
                                                >
                                                    8.{`Esecuzione`} <DoubleArrowIcon/>
                                                </Typography>
                                            }
                                            value="esecuzione_tab"
                                        />
                                        <Tab
                                            disabled={
                                                !["I", "L", "M", "N"].includes(commessa.stato || "") ||
                                                !utenteAutorizzato(utente, [
                                                    PermissionTypes.BCK_OFFICE_GENERAL_CONTRACTOR,
                                                    PermissionTypes.GENERAL_CONTRACTOR,
                                                ]) ||
                                                !utente.is_superuser
                                            }
                                            label={
                                                <Typography
                                                    variant="h5"
                                                    sx={{
                                                        color: [
                                                            "A",
                                                            "B",
                                                            "C",
                                                            "D",
                                                            "E",
                                                            "F",
                                                            "G",
                                                            "H",
                                                        ].includes(commessa.stato || "")
                                                            ? "inherit"
                                                            : ["I"].includes(commessa.stato || "")
                                                                ? theme.palette.error.main
                                                                : theme.palette.primary.main,
                                                    }}
                                                >
                                                    9.Fine Lavori <DoubleArrowIcon/>
                                                </Typography>
                                            }
                                            value="9"
                                        />
                                    </TabList>
                                </Box>
                                <TabPanel value="contraente_tab" sx={{p: 0}}>
                                    <ContraenteTab
                                        key={`contraente_${commessa.contraente}`}
                                        commessa={commessa}
                                        consentiModifica={
                                            consentiModifica && ["A"].includes(commessa.stato || "")
                                        }
                                    />
                                </TabPanel>
                                <TabPanel value="titolare_tab" sx={{p: 0}}>
                                    <TitolareTab
                                        key={`titolare_${commessa.titolare_immobile}`}
                                        commessa={commessa}
                                        consentiModifica={
                                            consentiModifica && ["A"].includes(commessa.stato || "")
                                        }
                                    />
                                </TabPanel>
                                <TabPanel value="produttore_tab" sx={{p: 0}}>
                                    <ProduttoreTab
                                        key={`produttore_${commessa.produttore}`}
                                        commessa={commessa}
                                        consentiModifica={
                                            consentiModifica && ["A"].includes(commessa.stato || "")
                                        }
                                    />
                                </TabPanel>
                                <TabPanel value="pod_tab" sx={{p: 0}}>
                                    <PodTab
                                        key={`pod_${commessa.pod}`}
                                        commessa={commessa}
                                        produttore={produttore}
                                        consentiModifica={
                                            consentiModifica && ["A"].includes(commessa.stato || "")
                                        }
                                    />
                                </TabPanel>
                                <TabPanel value="materiali_tab" sx={{p: 0}}>
                                    <MaterialiTab
                                        key={`pod_${commessa.pod}`}
                                        commessa={commessa}
                                        consentiModifica={
                                            consentiModifica &&
                                            ["A", "B", "C"].includes(commessa.stato || "")
                                        }
                                    />
                                </TabPanel>
                                <TabPanel value="acconto_tab" sx={{p: 0}}>
                                    <AccontoTab
                                        key={`acconto_${commessa.id}`}
                                        commessa={commessa}
                                        consentiModifica={
                                            consentiModificaSoloGenralContractor &&
                                            ["B"].includes(commessa.stato || "")
                                        }
                                    />
                                </TabPanel>
                                <TabPanel value="analisi_tab" sx={{p: 0}}>
                                    <AnalisiTab
                                        key={`in_lavorazione_${commessa.iterConnessione}`}
                                        commessa={commessa}
                                        consentiModifica={consentiModifica}
                                    />
                                </TabPanel>

                                <TabPanel value="esecuzione_tab" sx={{p: 0}}>
                                    <EsecuzioneTab
                                        key={`pod_${commessa.pod}`}
                                        commessa={commessa}
                                        consentiModifica={consentiModifica}
                                        consentiModificaSoloGenralContractor={
                                            consentiModificaSoloGenralContractor
                                        }
                                    />
                                </TabPanel>
                                <TabPanel value="9" sx={{p: 0}}>
                                    <FineLavoriTab
                                        key={`pod_${commessa.pod}`}
                                        commessa={commessa}
                                        consentiModifica={consentiModifica}
                                        consentiModificaSoloGenralContractor={
                                            consentiModificaSoloGenralContractor
                                        }
                                    />
                                </TabPanel>
                            </TabContext>
                        )}
                    </Box>
                ) : (
                    <Paper sx={{p: 2, backgroundColor: "lightgoldenrodyellow", mb: 2}}>
                        <Typography variant="h5" color="text.secondary" gutterBottom>
                            <ReportGmailerrorredIcon/>
                            Errore caricamento Commessa
                        </Typography>
                    </Paper>
                )}
            </Paper>
        </Box>
    );
};

export default CommessaPage;
