import React, {useEffect, useState} from "react";
import {Link, useLocation, useParams, useNavigate} from "react-router-dom";

import {useSelector} from "react-redux";
import {RootState, useAppDispatch} from "../../../../../../redux/store";
import {UtenteLogged} from "components/authentication/authenticationSlice";
import {Commessa,getCommessa, Prodotto, PodCommessa} from "../../../commesseSlice";
import FoglioBarcodeProdottiPdf from "./FoglioBarcodeProdottiPdf";

import {Box} from "@mui/system";

import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";

import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import {Routes_path} from "components/routerApp";



interface FoglioBarcodeProdottiPageProps {
}

const FoglioBarcodeProdottiPage = () => {
    const dispatch = useAppDispatch();
    const {commessa_id} = useParams();

    const [commessaId, setCommessaId] = useState<number>(
        parseInt(commessa_id || "")
    );

    const location = useLocation();
    const navigate = useNavigate();

    const utente = useSelector<RootState, UtenteLogged>(
        (state) => state.authentication.utenteLogged
    );

    useEffect(() => {
        dispatch(getCommessa({commessaId}));
    }, []);

    // const commessaCorrenteId = useSelector<RootState, number | undefined>(
    // 	(state) => state.commesseState.commessaCorrenteId
    // );

    const commessa: Commessa | undefined = useSelector<RootState, Commessa | undefined>((state) => state.commesseState.commesse.results.find((commessa) => commessa?.id == commessaId));

    return (
        <Box>
            {/* <Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={
					lotti.errorsStack.status == ErrorStatusTypes.PENDING ||
					schedeCarico.errorsStack.status == ErrorStatusTypes.PENDING ||
					anagraficheErrorsStack.status == ErrorStatusTypes.PENDING
				}
			>
				<CircularProgress color="inherit" />
			</Backdrop> */}
            <Box sx={{backgroundColor: "#999", height: "80vh"}}>
                {commessa && (
                    <FoglioBarcodeProdottiPdf key={`foglioBarcode_${commessa.id}`} commessa={commessa} />
                )}
            </Box>

            <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                mt={2}
                spacing={1}
            >
                <Button
                    variant="contained"
                    component={Link}
                    color="secondary"
                    to={{
                        pathname: Routes_path.COMMESSE,
                    }}
                    endIcon={<KeyboardReturnIcon/>}
                    // color="success"
                >
                    Torna alla lista Commesse
                </Button>
                <Button
                    variant="contained"
                    component={Link}
                    to={{
                        pathname: Routes_path.COMMESSA + commessaId,
                    }}
                    endIcon={<KeyboardReturnIcon/>}
                    // color="success"
                >
                    Torna alla Commessa {commessaId}
                </Button>
            </Stack>
        </Box>
    );
};

export default FoglioBarcodeProdottiPage;
