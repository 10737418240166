import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import {
	ErrorStatusTypes,
	ErrorsStack,
	parseErrorMessage,
} from "../../../common/errorsDeclarations";

import * as allegatiAdempimentiApi from "../../../../api/adempimenti/allegatiAdempimentiApi";

import { toast } from "react-toastify";
import { getAdempimento } from "../adempimentiSlice";



export interface TipoDocumento {
	id: number;
	nome: string;
	descrizione?: string;
}
export interface TipiDocumento {
	count: number;
	page: number;
	num_pages: number;
	next?: URL;
	previous?: URL;
	results: TipoDocumento[];
}

export interface AllegatoAdempimenti {
	id: number | null;
	tipo_documento: number | null;
	tipo_documento_des?: TipoDocumento;
	descrizione: string;
	adempimento?: number | null;
	autore: string;
	file: string;
	file_name: string;
	data_documento?: Date | string;
}

export interface AllegatiAdempimenti {
	count: number;
	page: number;
	num_pages: number;
	next?: URL;
	previous?: URL;
	results: AllegatoAdempimenti[];
	errorsStack: ErrorsStack;
}

export interface AllegatoCorrenteState {
	allegato: AllegatoAdempimenti;
	errorsStack: ErrorsStack;
}

export interface AllegatiStrutturaState {
	tipiDocumento: TipiDocumento;
	allegatiAdempimenti: AllegatiAdempimenti;
	allegatoCorrente: AllegatoCorrenteState;
}

const initialState: AllegatiStrutturaState = {
	tipiDocumento: {
		count: 0,
		page: 0,
		num_pages: 0,
		next: undefined,
		previous: undefined,
		results: [],
	},
	allegatiAdempimenti: {
		count: 0,
		page: 0,
		num_pages: 0,
		next: undefined,
		previous: undefined,
		results: [],
		errorsStack: { status: ErrorStatusTypes.OK },
	},
	allegatoCorrente: {
		allegato: {
			id: null,
			tipo_documento: null,
			descrizione: "",
			adempimento: null,
			autore: "",
			file: "",
			file_name: "",
		},
		errorsStack: { status: ErrorStatusTypes.OK },
	},
};

export const fetchTipiDocumento = createAsyncThunk(
	"allegatoAdempimenti/fetchTipiDocumento",
	async () => {
		return await allegatiAdempimentiApi.fetchTipiDocumento();
		//   return user;
	}
);
export const fetchAllegati = createAsyncThunk(
	"allegatoAdempimenti/fetchAllegati",
	async (filtriAllegati: {
		iterConnessione_id?: number | null;
		tipo_documento_id?: number;
		numeroRecord?: number;
		page?: number;
		search?: string;
	}) => {
		return await allegatiAdempimentiApi.fetchAllegati(
			filtriAllegati.iterConnessione_id,
			filtriAllegati.tipo_documento_id,
			filtriAllegati.numeroRecord,
			filtriAllegati.page,
			filtriAllegati.search
		);
		//   return user;
	}
);

export const uploadAllegatoAdempimenti = createAsyncThunk(
	"allegatoAdempimenti/uploadAllegatoAdempimenti",
	async (
		parametriAllegatoAdempimenti: {
			allegato: AllegatoAdempimenti;
			file: File;
		},
		thunkApi
	) => {
		const adempimento_id = parametriAllegatoAdempimenti.allegato.adempimento;

		return await allegatiAdempimentiApi
			.uploadAllegatoAdempimenti(
				parametriAllegatoAdempimenti.allegato,
				parametriAllegatoAdempimenti.file
			)
			.then((response) => {
				adempimento_id && thunkApi.dispatch(getAdempimento(adempimento_id));
				return response;
			});
	}
);

export const deleteAllegatoAdempimenti = createAsyncThunk(
	"schedaCarico/deleteAllegatoAdempimenti",
	async (allegatoToDelete: AllegatoAdempimenti, thunkApi) => {
		const adempimento_id = allegatoToDelete.adempimento;

		return await allegatiAdempimentiApi
			.deleteAllegatoAdempimenti(allegatoToDelete)
			.then((response) => {
				adempimento_id && thunkApi.dispatch(getAdempimento(adempimento_id));
				return response;

				return response;
			});
	}
);

export const allegatiAdempimentiSlice = createSlice({
	name: "allegatiAdempimentiSlice",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// fetch tipiDocumento
		builder.addCase(fetchTipiDocumento.pending, (state, action) => {
			// state.tipiDocumento.errorsStack = { status: ErrorStatusTypes.PENDING };
		});
		builder.addCase(fetchTipiDocumento.fulfilled, (state, action) => {
			state.tipiDocumento = action.payload;
			// state.tipiDocumento.errorsStack = { status: ErrorStatusTypes.OK };
		});
		builder.addCase(fetchTipiDocumento.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");
			// state.tipiDocumento.errorsStack = {
			//   status: ErrorStatusTypes.ERROR,
			//   fieldsErrors: JSON.parse(action?.error?.message || ""),
			// };
		});
		// fetch Allegati
		builder.addCase(fetchAllegati.pending, (state, action) => {
			state.allegatiAdempimenti.errorsStack = {
				status: ErrorStatusTypes.PENDING,
			};
		});
		builder.addCase(fetchAllegati.fulfilled, (state, action) => {
			// state.allegati.results = [
			// 	...state.allegati.results.filter(
			// 		(allegato) =>
			// 			allegato.iter_connessione != action.meta.arg.iterConnessione_id
			// 	),
			// 	...action.payload.results,
			// ];
			state.allegatiAdempimenti = action.payload;

			state.allegatiAdempimenti.errorsStack = { status: ErrorStatusTypes.OK };
		});
		builder.addCase(fetchAllegati.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");
			state.allegatiAdempimenti.errorsStack = {
				status: ErrorStatusTypes.ERROR,
				fieldsErrors: JSON.parse(action?.error?.message || ""),
			};
		});
		// fetch Allegati
		builder.addCase(deleteAllegatoAdempimenti.pending, (state, action) => {
			state.allegatiAdempimenti.errorsStack = {
				status: ErrorStatusTypes.PENDING,
			};
		});
		builder.addCase(deleteAllegatoAdempimenti.fulfilled, (state, action) => {
			state.allegatiAdempimenti.results =
				state.allegatiAdempimenti.results.filter(
					(allegato) => allegato.id != action.meta.arg.id
				);
			toast.success(
				action.payload.message || "AllegatoAdempimenti cancellato."
			);
			state.allegatiAdempimenti.errorsStack = { status: ErrorStatusTypes.OK };
		});
		builder.addCase(deleteAllegatoAdempimenti.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");
			state.allegatiAdempimenti.errorsStack = {
				status: ErrorStatusTypes.ERROR,
				fieldsErrors: JSON.parse(action?.error?.message || ""),
			};
		});

		// carica Documento
		builder.addCase(uploadAllegatoAdempimenti.pending, (state, action) => {
			state.allegatoCorrente.errorsStack = { status: ErrorStatusTypes.PENDING };
		});
		builder.addCase(uploadAllegatoAdempimenti.fulfilled, (state, action) => {
			state.allegatoCorrente.errorsStack = { status: ErrorStatusTypes.OK };
			toast.success("AllegatoAdempimenti inserito.");
		});
		builder.addCase(uploadAllegatoAdempimenti.rejected, (state, action) => {
			state.allegatoCorrente.errorsStack = { status: ErrorStatusTypes.ERROR };
			toast.error("Errore:" + action?.error?.message || "");
			try {
				const errorMessage = JSON.parse(
					(action?.error?.message || "").replace(/^Error: /, "")
				);

				state.allegatoCorrente.errorsStack = {
					status: ErrorStatusTypes.ERROR,
					fieldsErrors: errorMessage,
				};
			} catch {
				console.log("json errato = ", action?.error?.message);
			}
		});
	},
});

// Action creators are generated for each case reducer function
export const {} = allegatiAdempimentiSlice.actions;

export const allegatiAdempimentiSliceReducer = allegatiAdempimentiSlice.reducer;
