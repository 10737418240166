import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";

import {
    IterConnessione,
    TipoTensioneTypes,
    saveIterConnessione,
    assegnaPratica,
} from "../../iterConnessioneSlice";
import {useAppDispatch, RootState} from "../../../../../redux/store";
import {
    ErrorStatusTypes,
    ErrorsStack,
} from "../../../../common/errorsDeclarations";

import {theme} from "../../../../theme";

import {PermissionTypes} from 'components/authentication/permissionsGroups'

import {utenteAutorizzato} from "../../../../authentication/UtenteAutorizzato";
import {
    UtenteLogged,
    UtenteApplicativo, Utente
} from "../../../../authentication/authenticationSlice";
import {Cliente} from "../../../../anagrafiche/clienti/clientiSlice";
import {Installatore} from "../../../../anagrafiche/installatori/installatoriSlice";
import {RegimeCommerciale} from "../../../../anagrafiche/regimiCommerciali/regimiCommercialiSlice";
import {ServizioTypes} from "../../../../anagrafiche/kpi/kpiSlice";
import {TipoServizio} from "../../../../anagrafiche/tipiServizio/tipiServizioSlice";
import {TipoPratica} from "../../../../anagrafiche/tipiPratica/tipiPraticaSlice";
import {TipoTensione} from "../../../../anagrafiche/tipiTensione/tipiTensioneSlice";

import {Gestore} from "../../../../anagrafiche/gestori/gestoriSlice";
import {useForm, SubmitHandler, Controller} from "react-hook-form";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

import ReactHookFormSelect from "../../../../common/ReactHookFormSelect";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Alert from "../../../../common/Alert";
import Typography from "@mui/material/Typography";
import InputAdornment from "@mui/material/InputAdornment";

import {Button, Divider} from "@mui/material";

interface IterConnessioneGestoreFormProps {
    iterConnessione: IterConnessione;
    consentiModifica: boolean;
}

const IterConnessioneGestoreForm = ({
                                        iterConnessione,
                                        consentiModifica = false,
                                    }: IterConnessioneGestoreFormProps) => {
    const {
        handleSubmit,
        register,
        formState: {errors, isDirty, isValid},
        setValue,
        watch,
        getValues,
        control,
        setError,
        clearErrors,
        reset,
    } = useForm<IterConnessione>();

    const listaCampi = Object.keys(iterConnessione) as Array<
        keyof IterConnessione
    >;

    const dispatch = useAppDispatch();

    // const [editing, setEditing] = useState(!iterConnessione.cliente);
    const [editing, setEditingControlled] = useState(false);

    const setEditing = (switchModifica: boolean) => {
        consentiModifica && setEditingControlled(switchModifica);
    };

    const [avviabile, setAvviabile] = useState(false);
    useEffect(() => {
        const avviabile: boolean =
            iterConnessione.stato == "D" &&
            !!iterConnessione.cliente &&
            // !!iterConnessione.installatore &&
            !!iterConnessione.tipo_pratica &&
            // !!iterConnessione.regime_commerciale &&
            !!iterConnessione.operatore &&
            !!iterConnessione.progettista;
        setAvviabile(avviabile);
    }, [iterConnessione]);

    const utente = useSelector<RootState, UtenteLogged>(
        (state) => state.authentication.utenteLogged
    );
    const clienti = useSelector<RootState, Cliente[]>(
        (state) => state.clientiState.clienti.results
    );

    const tipiServizio = useSelector<RootState, TipoServizio[]>(
        (state) => state.tipiServizioState.tipiServizio.results
    );
    const installatori = useSelector<RootState, Installatore[]>(
        (state) => state.installatoriState.installatori.results
    );

    const regimiCommerciali = useSelector<RootState, RegimeCommerciale[]>(
        (state) => state.regimiCommercialiState.regimiCommerciali.results
    );
    const tipiPratica = useSelector<RootState, TipoPratica[]>(
        (state) => state.tipiPraticaState.tipiPratica.results
    );
    const tipiTensione = useSelector<RootState, TipoTensione[]>(
        (state) => state.tipiTensioneState.tipiTensione.results
    );

    const gestori = useSelector<RootState, Gestore[]>(
        (state) => state.gestoriState.gestori.results
    );

    const utentiApplicativo = useSelector<RootState, UtenteApplicativo[]>(
        (state) => state.authentication.utentiApplicativo.results
    );

    const utentiStaff = useSelector<RootState, Utente[] | undefined>(
        (state) => state.authentication.utentiStaff?.results
    );

    useEffect(() => {
        reset();
        listaCampi.map((field) => {
            setValue(field, iterConnessione[field]);
        });
    }, []);

    useEffect(() => {
        clearErrors();
        listaCampi.forEach((field) => {
            iterConnessione?.errorsStack?.fieldsErrors &&
            iterConnessione.errorsStack.fieldsErrors[field] &&
            setError(field, {
                type: "server",
                message: iterConnessione.errorsStack.fieldsErrors[field].toString(),
            });
        });
    }, [
        iterConnessione?.errorsStack?.fieldsErrors,
        // iterConnessione.allevamento_pack?.errorsStack?.fieldsErrors,
    ]);

    useEffect(() => {
        iterConnessione?.errorsStack?.status == ErrorStatusTypes.SUCCESS &&
        setEditing(false);
    }, [
        iterConnessione,
        // iterConnessione.allevamento_pack?.errorsStack?.fieldsErrors,
    ]);

    const handlerSaveIterConnessione = (iterConnessione: IterConnessione) => {
        consentiModifica &&
        dispatch(
            saveIterConnessione({
                iterConnessioneToSave: iterConnessione,
            })
        );
    };

    const onSubmit: SubmitHandler<IterConnessione> = (iterConnessione) => {
        handlerSaveIterConnessione(iterConnessione);
    };

    return (
        <Box sx={{mb: 1}}>
            <form onSubmit={handleSubmit(onSubmit)}>
                {iterConnessione?.errorsStack?.fieldsErrors?.non_field_errors && (
                    <Alert severity="error">
                        {iterConnessione?.errorsStack?.fieldsErrors?.non_field_errors.toString()}
                    </Alert>
                )}
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={6} lg={4}>
                        <Typography
                            variant="caption"
                            component="div"
                            color="text.secondary"
                            gutterBottom
                            onClick={() => setEditing(true)}
                        >
                            tipo di iter:
                        </Typography>
                        {!editing ? (
                            <Typography
                                variant="body1"
                                component="div"
                                // color="text.secondary"
                                gutterBottom
                                onClick={() => setEditing(true)}
                                sx={{
                                    color: iterConnessione.iter_semplificato
                                        ? theme.palette.text.secondary
                                        : theme.palette.divider,
                                }}
                            >
                                {iterConnessione.iter_semplificato
                                    ? "Iter semplificato"
                                    : "Iter ordinario"}
                            </Typography>
                        ) : (
                            <FormControlLabel
                                label={
                                    <Typography fontSize="0.8rem">
                                        {getValues("iter_semplificato")
                                            ? "Iter semplificato"
                                            : "Iter ordinario"}
                                    </Typography>
                                }
                                control={
                                    <Switch
                                        id="iter_semplificato"
                                        checked={watch("iter_semplificato") || false}
                                        onChange={(
                                            event: React.ChangeEvent<HTMLInputElement>,
                                            newValue: any
                                        ) => {
                                            setValue("iter_semplificato", newValue, {
                                                shouldDirty: true,
                                            });
                                        }}
                                        color="info"
                                        size="small"
                                    />
                                }
                            />
                        )}
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4}>
                        <Typography
                            variant="caption"
                            component="div"
                            color="text.secondary"
                            gutterBottom
                            onClick={() => setEditing(true)}
                        >
                            tipo di servizio:
                        </Typography>
                        {!editing ? (
                            <Typography
                                variant="body1"
                                component="div"
                                // color="text.secondary"
                                gutterBottom
                                onClick={() => setEditing(true)}
                                sx={{
                                    color: iterConnessione.tipo_servizio
                                        ? theme.palette.text.secondary
                                        : theme.palette.divider,
                                }}
                            >
                                {
                                    tipiServizio.find(
                                        (ts) => ts.id == iterConnessione?.tipo_servizio
                                    )?.codice
                                }
                            </Typography>
                        ) : (
                            <ReactHookFormSelect
                                name="tipo_servizio"
                                // label="tipo_pratica"
                                register={register}
                                control={control}
                                watch={watch}
                                variant="outlined"
                                error={errors?.tipo_servizio?.message}
                                sx={{width: "100%"}}
                            >
                                <MenuItem value="">
                                    <em>Nessuno</em>
                                </MenuItem>

                                {tipiServizio.map((tipoServizio) => {
                                    return (
                                        <MenuItem key={tipoServizio.id} value={tipoServizio.id}>
                                            {tipoServizio.codice} - {tipoServizio.descrizione}
                                        </MenuItem>
                                    );
                                })}
                            </ReactHookFormSelect>
                        )}
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4}>
                        <Typography
                            variant="caption"
                            component="div"
                            color="text.secondary"
                            gutterBottom
                            onClick={() => setEditing(true)}
                        >
                            cliente:
                        </Typography>
                        {!editing ? (
                            <Typography
                                variant="body1"
                                component="div"
                                // color="text.secondary"
                                gutterBottom
                                onClick={() => setEditing(true)}
                                sx={{
                                    color: iterConnessione.cliente
                                        ? theme.palette.text.secondary
                                        : theme.palette.divider,
                                }}
                            >
                                {clienti.find(
                                    (cliente) => cliente.id == iterConnessione.cliente
                                )?.ragione_sociale || "seleziona cliente"}
                            </Typography>
                        ) : (
                            <ReactHookFormSelect
                                name="cliente"
                                // label="cliente"
                                register={register}
                                control={control}
                                watch={watch}
                                variant="outlined"
                                error={errors?.cliente?.message}
                                sx={{width: "100%"}}
                            >
                                <MenuItem value="" key="cliente_vuoto">
                                    <em>Nessuno</em>
                                </MenuItem>
                                {clienti.map((cliente) => {
                                    return (
                                        <MenuItem key={cliente.id} value={cliente.id}>
                                            {cliente.ragione_sociale}
                                        </MenuItem>
                                    );
                                })}
                            </ReactHookFormSelect>
                        )}
                    </Grid>
                    {/* <Grid item xs={12} sm={6} md={6} lg={4}>
						<Typography
							variant="caption"
							component="div"
							color="text.secondary"
							gutterBottom
							onClick={() => setEditing(true)}
						>
							commessa:
						</Typography>
						{!editing ? (
							<Typography
								variant="body1"
								component="div"
								// color="text.secondary"
								gutterBottom
								onClick={() => setEditing(true)}
								sx={{
									color: iterConnessione.commessa
										? theme.palette.text.secondary
										: theme.palette.divider,
								}}
							>
								{iterConnessione.commessa || "inserisci commessa"}
							</Typography>
						) : (
							<TextField
								variant="outlined"
								InputLabelProps={{
									shrink: true,
								}}
								{...register("commessa")}
								error={!!errors?.commessa}
								helperText={errors?.commessa?.message}
								autoComplete={"off"}
								sx={{ width: "100%" }}
								fullWidth
								// onBlur={handlerDetailChaingSubmit}
							/>
						)}
					</Grid> */}
                    <Grid item xs={12} sm={6} md={6} lg={4}>
                        <Typography
                            variant="caption"
                            component="div"
                            color="text.secondary"
                            gutterBottom
                            onClick={() => setEditing(true)}
                        >
                            installatore:
                        </Typography>
                        {!editing ? (
                            <Typography
                                variant="body1"
                                component="div"
                                // color="text.secondary"
                                gutterBottom
                                onClick={() => setEditing(true)}
                                sx={{
                                    color: iterConnessione.installatore
                                        ? theme.palette.text.secondary
                                        : theme.palette.divider,
                                }}
                            >
                                {installatori.find(
                                    (installatore) =>
                                        installatore.id == iterConnessione.installatore
                                )?.ragione_sociale || "seleziona installatore"}
                            </Typography>
                        ) : (
                            <ReactHookFormSelect
                                name="installatore"
                                // label="installatore"
                                register={register}
                                control={control}
                                watch={watch}
                                variant="outlined"
                                error={errors?.installatore?.message}
                                sx={{width: "100%"}}
                            >
                                <MenuItem value="" key="installatore_vuoto">
                                    <em>Nessuno</em>
                                </MenuItem>
                                {installatori.map((installatore) => {
                                    return (
                                        <MenuItem key={installatore.id} value={installatore.id}>
                                            {installatore.ragione_sociale}
                                        </MenuItem>
                                    );
                                })}
                            </ReactHookFormSelect>
                        )}
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={8}>
                        <Typography
                            variant="caption"
                            component="div"
                            color="text.secondary"
                            gutterBottom
                            onClick={() => setEditing(true)}
                        >
                            tipo di pratica:
                        </Typography>
                        {!editing ? (
                            <Typography
                                variant="body1"
                                component="div"
                                // color="text.secondary"
                                gutterBottom
                                onClick={() => setEditing(true)}
                                sx={{
                                    color: iterConnessione.tipo_pratica
                                        ? theme.palette.text.secondary
                                        : theme.palette.divider,
                                }}
                            >
                                {tipiPratica.find(
                                    (tipoPratica) =>
                                        tipoPratica.id == iterConnessione.tipo_pratica
                                )?.descrizione || "seleziona tipo pratica"}
                            </Typography>
                        ) : (
                            <ReactHookFormSelect
                                name="tipo_pratica"
                                // label="tipo_pratica"
                                register={register}
                                control={control}
                                watch={watch}
                                variant="outlined"
                                error={errors?.tipo_pratica?.message}
                                sx={{width: "100%"}}
                            >
                                <MenuItem value="">
                                    <em>Nessuno</em>
                                </MenuItem>

                                {tipiPratica.map((tipoPratica) => {
                                    return (
                                        <MenuItem key={tipoPratica.id} value={tipoPratica.id}>
                                            {tipoPratica.nome} {tipoPratica.descrizione}
                                        </MenuItem>
                                    );
                                })}
                            </ReactHookFormSelect>
                        )}
                    </Grid>
                    {/* <Grid item xs={12} sm={6} md={6} lg={4}>
						<Typography
							variant="caption"
							component="div"
							color="text.secondary"
							gutterBottom
							onClick={() => setEditing(true)}
						>
							regime commerciale:
						</Typography>
						{!editing ? (
							<Typography
								variant="body1"
								component="div"
								color="text.secondary"
								gutterBottom
								onClick={() => setEditing(true)}
								sx={{
									color: iterConnessione.regime_commerciale
										? theme.palette.text.secondary
										: theme.palette.divider,
								}}
							>
								{regimiCommerciali.find(
									(reg) => reg.id == iterConnessione.regime_commerciale
								)?.descrizione || "seleziona regime commerciale"}
							</Typography>
						) : (
							<ReactHookFormSelect
								name="regime_commerciale"
								// label="regime_commerciale"
								register={register}
								control={control}
								watch={watch}
								variant="outlined"
								error={errors?.regime_commerciale?.message}
								sx={{ width: "100%" }}
							>
								<MenuItem value="" key="regime_vuoto">
									<em>Nessuno</em>
								</MenuItem>
								{regimiCommerciali.map((regime_commerciale) => {
									return (
										<MenuItem
											key={regime_commerciale.id}
											value={regime_commerciale.id}
										>
											{regime_commerciale.nome} -{" "}
											{regime_commerciale.descrizione}
										</MenuItem>
									);
								})}
							</ReactHookFormSelect>
						)}
					</Grid> */}
                    <Grid item xs={12} sm={6} md={6} lg={4}>
                        <Typography
                            variant="caption"
                            component="div"
                            color="text.secondary"
                            gutterBottom
                            onClick={() => setEditing(true)}
                        >
                            operatore:
                        </Typography>
                        {!editing ? (
                            <Typography
                                variant="body1"
                                component="div"
                                // color="text.secondary"
                                gutterBottom
                                onClick={() => setEditing(true)}
                                sx={{
                                    color: iterConnessione.operatore

                                        ? theme.palette.text.secondary
                                        : theme.palette.divider,
                                }}
                            >
                                {utentiStaff?.find(
                                    (utente) => utente.id == iterConnessione.operatore
                                )?.full_name || "seleziona operatore"}
                            </Typography>
                        ) : (
                            <ReactHookFormSelect
                                name="operatore"
                                // label="operatore"
                                register={register}
                                control={control}
                                watch={watch}
                                variant="outlined"
                                error={errors?.operatore?.message}
                                sx={{width: "100%"}}
                            >
                                <MenuItem value="" key="operatore_vuoto">
                                    <em>Nessuno</em>
                                </MenuItem>
                                {utentiStaff?.filter((utente) => utente.gruppi?.includes(PermissionTypes.OPERATORI)).map((utente) => {
                                    return (<MenuItem key={utente.id} value={utente.id}>{utente.full_name}</MenuItem>);
                                })}
                            </ReactHookFormSelect>
                        )}
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4}>
                        <Typography
                            variant="caption"
                            component="div"
                            color="text.secondary"
                            gutterBottom
                            onClick={() => setEditing(true)}
                        >
                            progettista:
                        </Typography>
                        {!editing ? (
                            <Typography
                                variant="body1"
                                component="div"
                                // color="text.secondary"
                                gutterBottom
                                onClick={() => setEditing(true)}
                                sx={{
                                    color: iterConnessione.progettista
                                        ? theme.palette.text.secondary
                                        : theme.palette.divider,
                                }}
                            >
                                {utentiStaff?.find(
                                    (utente) => utente.id == iterConnessione.progettista
                                )?.full_name || "seleziona operatore"}
                            </Typography>
                        ) : (
                            <ReactHookFormSelect
                                name="progettista"
                                // label="progettista"
                                register={register}
                                control={control}
                                watch={watch}
                                variant="outlined"
                                error={errors?.progettista?.message}
                                sx={{width: "100%"}}
                            >
                                <MenuItem value="" key="operatore_vuoto">
                                    <em>Nessuno</em>
                                </MenuItem>
                                {utentiStaff?.filter((utente) => utente.gruppi?.includes(PermissionTypes.PROGETTISTI))
                                            .map((utente) => {
                                                return (
                                                    <MenuItem key={utente.id} value={utente.id}>
                                                        {utente.full_name}
                                                    </MenuItem>
                                                );
                                            })}
                            </ReactHookFormSelect>
                        )}
                    </Grid>
                </Grid>
                {/* <Stack
					direction="row"
					justifyContent="flex-end"
					alignItems="flex-end"
					spacing={2}
					sx={{ minHeight: 80 }}
				> */}
                <Button
                    // disabled={!isDirty}
                    variant="outlined"
                    onClick={() => {
                        setEditing(false);
                        // reset();
                    }}
                    sx={{...(!editing && {display: "none"}), mt: 2}}
                >
                    Annulla
                </Button>

                <Button
                    disabled={!isDirty}
                    variant="outlined"
                    type="submit"
                    sx={{...(!editing && {display: "none"}), ml: 1, mt: 2}}
                >
                    Salva
                </Button>
                {/* </Stack> */}
            </form>
        </Box>
    );
};

export default IterConnessioneGestoreForm;
