import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { NumericFormat } from "react-number-format";

import {
	Commessa,
	Prodotto,
	TipoProdottoTypes,
	saveProdotto,
} from "../../../commesseSlice";

import { useAppDispatch, RootState } from "../../../../../../redux/store";
import {
	ErrorStatusTypes,
	ErrorsStack,
} from "../../../../../common/errorsDeclarations";

import { theme } from "../../../../../theme";

import { utenteAutorizzato } from "../../../../../authentication/UtenteAutorizzato";
import { UtenteLogged } from "../../../../../authentication/authenticationSlice";
import { useForm, SubmitHandler, Controller } from "react-hook-form";

import FormControlLabel from "@mui/material/FormControlLabel";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Alert from "../../../../../common/Alert";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import FormHelperText from "@mui/material/FormHelperText";

import { Button, Divider } from "@mui/material";
import { Soggetto } from "components/anagrafiche/produttori/produttoriSlice";

interface OrdineProdottoFormProps {
	prodotto: Prodotto;
	commessa: Commessa;
	consentiModifica: boolean;
	index: number;
}

const OrdineProdottoForm = ({
	prodotto,
	commessa,
	consentiModifica,
	index,
}: OrdineProdottoFormProps) => {
	const {
		handleSubmit,
		register,
		formState: { errors, isDirty, isValid },
		setValue,
		watch,
		getValues,
		control,
		setError,
		clearErrors,
		reset,
	} = useForm<Prodotto>();

	const listaCampi = Object.keys(prodotto) as Array<keyof Prodotto>;

	const dispatch = useAppDispatch();

	const utente = useSelector<RootState, UtenteLogged>(
		(state) => state.authentication.utenteLogged
	);

	useEffect(() => {
		reset();
		listaCampi.map((field) => {
			setValue(field, prodotto[field]);
		});
		// setKeycodice_pod(makeid(5));
	}, [prodotto, prodotto.id]);

	useEffect(() => {
		clearErrors();
		listaCampi.forEach((field) => {
			prodotto?.errorsStack?.fieldsErrors &&
				prodotto.errorsStack.fieldsErrors[field] &&
				setError(field, {
					type: "server",
					message: prodotto.errorsStack.fieldsErrors[field].toString(),
				});
		});
	}, [
		prodotto?.errorsStack?.fieldsErrors,
		// commessa.allevamento_pack?.errorsStack?.fieldsErrors,
	]);

	const handlerSaveProdotto = (prodotto: Prodotto) => {
		dispatch(
			saveProdotto({
				prodottoToSave: prodotto,
				commessaIdToRefresh: commessa?.id,
				// iterconnessioneIdToRefresh: commessa?.iterConnessione,
			})
		);
	};

	const handlerDetailChaingSubmit = () => {
		if (isValid && isDirty) {
			const formValues = getValues();
			handlerSaveProdotto(formValues);
		}
	};

	const onSubmit: SubmitHandler<Prodotto> = (prodotto) => {
		handlerSaveProdotto(prodotto);
	};

	useEffect(() => {
		if (isValid && isDirty) {
			const formValues = getValues();
			handlerSaveProdotto(formValues);
		}
	}, [watch("ordinato"), watch("consegnato")]);

	return (
		<Box
			sx={{ mb: 1, backgroundColor: index % 2 === 0 ? "inherit" : "#99999977" }}
		>
			<form onSubmit={handleSubmit(onSubmit)}>
				<Grid container justifyContent="flex-start" spacing={2} sx={{ mb: 2 }}>
					<Grid item xs={12} sm={6} md={6} lg={4}>
						<Typography variant="h4" display="inline" sx={{ mr: 1 }}>
							{prodotto.quantita}
						</Typography>
						<Typography variant="h6" gutterBottom display="inline">
							{`${
								Object.entries(TipoProdottoTypes).find(
									([key, value]) => key == prodotto.tipo_prodotto
								)?.[1]
							} ${prodotto?.marca} ${prodotto?.modello}`}
						</Typography>
					</Grid>
					<Grid item xs={12} sm={6} md={6} lg={1}>
						<FormControlLabel
							value={watch("ordinato") || prodotto?.ordinato}
							checked={watch("ordinato") || false}
							control={<Checkbox />}
							label="ordinato"
							labelPlacement="start"
							onChange={(e, checked) => {
								setValue("ordinato", checked, {
									shouldDirty: true,
								});
							}}
							disabled={!consentiModifica}
							style={{ padding: 0, fontSize: "0.4rem" }}
						/>
						{!!errors?.ordinato && (
							<FormHelperText error>
								{errors?.ordinato.toString()}
							</FormHelperText>
						)}
					</Grid>
					<Grid item xs={12} sm={6} md={6} lg={1}>
						<FormControlLabel
							value={watch("consegnato") || prodotto?.consegnato}
							checked={watch("consegnato") || false}
							control={<Checkbox />}
							label="consegnato"
							labelPlacement="start"
							onChange={(e, checked) => {
								setValue("consegnato", checked, {
									shouldDirty: true,
								});
							}}
							disabled={!consentiModifica || !watch("ordinato")}
							style={{ padding: 0, fontSize: "0.4rem" }}
						/>
						{!!errors?.ordinato && (
							<FormHelperText error>
								{errors?.ordinato.toString()}
							</FormHelperText>
						)}
					</Grid>
				</Grid>
			</form>
		</Box>
	);
};

export default OrdineProdottoForm;
