import React from "react";
import { RootState, useAppDispatch } from "../../../redux/store";
import { useSelector } from "react-redux";

import { Utente } from "../../authentication/authenticationSlice";
import {
	progettiEmessiKpiFetch,
	ProgettiEmessiKpi,
} from "../../anagrafiche/kpi/kpiSlice";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Stack } from "@mui/material";
import ArchitectureIcon from "@mui/icons-material/Architecture";
import Chip from "@mui/material/Chip";
import {
	ErrorStatusTypes,
	ErrorsStack,
	parseErrorMessage,
} from "../../common/errorsDeclarations";
import CircularProgress from "@mui/material/CircularProgress";

interface ProgettiEmessiKpiCardProps {
	data_da?: Date;
	data_a?: Date;
	periodo?: string;
}

const ProgettiEmessiKpiCard = ({
	data_da,
	data_a,
	periodo,
}: ProgettiEmessiKpiCardProps) => {
	const dispatch = useAppDispatch();

	React.useEffect(() => {
		dispatch(progettiEmessiKpiFetch({ data_da, data_a, periodo }));
	}, [data_da, data_a, periodo]);

	const progettiEmessiKpi = useSelector<RootState, ProgettiEmessiKpi>(
		(state) => state.kpiState.progettiEmessiKpi
	);

	return (
		<Box>
			<Card variant="outlined">
				<CardContent>
					<Stack
						direction="column"
						spacing={2}
						justifyContent="center"
						alignItems="center"
						sx={{ minHeight: 200 }}
					>
						{progettiEmessiKpi.errorsStack.status ==
						ErrorStatusTypes.PENDING ? (
							<CircularProgress />
						) : (
							<Typography variant="h2" sx={{ color: "darkblue" }}>
								{progettiEmessiKpi?.progetti_emessi_kpi}
							</Typography>
						)}
						<Chip
							icon={<ArchitectureIcon color="success" sx={{ fontSize: 100 }} />}
							label="Progetti Emessi"
						/>
					</Stack>
				</CardContent>
			</Card>
		</Box>
	);
};

export default ProgettiEmessiKpiCard;
