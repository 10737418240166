import React from "react";

import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";

import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import ReplayIcon from "@mui/icons-material/Replay";
import { convertToDateTime } from "../../../components/common/dateUtils/convertToDateTime";

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import DateRangeIcon from "@mui/icons-material/DateRange";
import CardMui from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import styled from "@mui/system/styled";
import Button from "@mui/material/Button";
import { DateTime } from "luxon";

const Card = styled(CardMui)(({ theme }) => ({
	margin: theme.spacing(1),
	marginBottom: 1,
	// },
}));

interface NavigazioneMesiToolbarProps {
	titolo?: string;
	data?: DateTime | null;
	setData: (data: DateTime | null) => void;
	refresh: () => void;
}

const NavigazioneMesiToolbar = ({
	titolo,
	data,
	setData,
	refresh,
}: NavigazioneMesiToolbarProps) => {
	// 

	const cambiaMese = (avanti: boolean) => {
		data &&
			(avanti
				? setData(data.plus({ months: 1 }))
				: setData(data.minus({ months: 1 })));
	};

	const [datePickerView, setDatePickerView] = React.useState<boolean>(false);
	const changeData = (newValue: DateTime | null) => {
		setData(newValue);
	};

	return (
		<Grid container spacing={1}>
			<Grid item xs={12}>
				<Card>
					<Stack
						direction="row"
						justifyContent="center"
						alignItems="center"
						mr={2}
						spacing={2}
					>
						<Typography variant="h4" component="div">
							{titolo}
						</Typography>
						<Typography variant="h4" component="div">
							<IconButton
								aria-label="MesePrecedente"
								onClick={() => {
									cambiaMese(false);
								}}
							>
								<ArrowBackIosIcon />
							</IconButton>
							{convertToDateTime(data)?.setLocale("it").toFormat("LLLL yyyy")}{" "}
							<Button
								sx={{ p: 2, ml: 2, mr: 2 }}
								onClick={() => {
									setDatePickerView(!datePickerView);
								}}
							>
								<DateRangeIcon />
							</Button>
							<IconButton
								color="primary"
								aria-label="upload picture"
								component="span"
								onClick={refresh}
							>
								<ReplayIcon />
							</IconButton>
							<IconButton
								aria-label="GiornoSuccessivo"
								onClick={() => {
									cambiaMese(true);
								}}
							>
								<ArrowForwardIosIcon />
							</IconButton>
						</Typography>
						{datePickerView && (
							<StaticDatePicker
								displayStaticWrapperAs="desktop"
								openTo="day"
								value={convertToDateTime(data)}
								onChange={(newValue) => {
									newValue && console.log(newValue);
									changeData(newValue);
								}}
							/>
						)}
					</Stack>
				</Card>
			</Grid>
		</Grid>
	);
};

export default NavigazioneMesiToolbar;
