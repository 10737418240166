import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { RootState, useAppDispatch } from "../../../../../redux/store";

import { UtenteLogged } from "../../../../authentication/authenticationSlice";
import { IterConnessione } from "../../iterConnessioneSlice";
import { TipoServizio } from "../../../../anagrafiche/tipiServizio/tipiServizioSlice";
import IterConnessioneIbanForm from "./IterConnessioneIbanForm";
import { testoBottoneProseguimento } from "../../../../anagrafiche/produttori/testoBottoneProseguimento";
import { ServizioTypes } from "../../../../anagrafiche/kpi/kpiSlice";
import IterConnessioneSpalla from "../IterConnessioneSpalla";
import IterConnessioneImpostazioneForm from "./IterConnessioneImpostazioneForm";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";
import {  CardHeader } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Stack from "@mui/material/Stack";
import { Soggetto } from "components/anagrafiche/produttori/produttoriSlice";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";

interface IterConnessioneIterImpostazioneTabProps {
	iterConnessione: IterConnessione;
	produttore?: Soggetto;
	utente: UtenteLogged;
	consentiModifica: boolean;
}

const IterConnessioneIterImpostazioneTab = ({
	iterConnessione,
	produttore,
	utente,
	consentiModifica = false,
}: IterConnessioneIterImpostazioneTabProps) => {
	

	const tipiServizio = useSelector<RootState, TipoServizio[]>(
		(state) => state.tipiServizioState.tipiServizio.results
	);

	const [refresh, setRefresh] = useState<boolean>(false);

	useEffect(() => {
		setRefresh(false);
	}, [refresh]);

	// gestione chiusura modal su scelta "Annulla"

	return (
		<Box>
			<Grid container spacing={2} sx={{ mb: 4 }}>
				<Grid item xs={12} sm={12} md={8} lg={9} order={{ xs: 2, md: 1 }}>
					<Card sx={{ mt: 2, backgroundColor: "#ddd" }}>
							<CardContent>
								<Stack sx={{ width: "100%" }} spacing={2}>
									{!iterConnessione.dati_impostazione_iter_completo?.dati_impostazione_iter_completo && (
										<Alert severity="error" sx={{ mt: 1 }}>
											<AlertTitle>Attenzione manca:</AlertTitle>
											<Typography
												variant="body2"
												// component="div"
												// color="text.secondary"
												// sx={{
												// 	color: theme?.palette?.error.main,
												// }}
												sx={{ whiteSpace: "pre-line" }}
											>
												{iterConnessione.dati_impostazione_iter_completo?.dati_mancanti}
											</Typography>
										</Alert>
									)}
								</Stack>
							</CardContent></Card>
					<Card>
						<CardHeader
							title={
								<Stack
									spacing={2}
									direction="row"
									color="white"
									justifyContent="space-between"
								>
									<Typography variant="h4">
										Richiesta di avvio servizio{" "}
										{testoBottoneProseguimento(
											Object.entries(ServizioTypes).find(
												([key, value]) =>
													key ==
													tipiServizio.find(
														(ts) => ts.id == iterConnessione?.tipo_servizio
													)?.codice
											)?.[1]
										)}
									</Typography>
								</Stack>
							}
							sx={{ backgroundColor: "#22568e" }}
						/>
						<CardContent sx={{ backgroundColor: "#eeeeee" }}>
							<Grid container spacing={2}>
									<Grid item xs={12} lg={12}>
										<IterConnessioneImpostazioneForm
											iterConnessione={iterConnessione}
											consentiModifica={consentiModifica}
										/>
									</Grid>
								<Grid item xs={12} lg={6}>
									<IterConnessioneIbanForm
										iterConnessione={iterConnessione}
										consentiModifica={consentiModifica}
									/>
								</Grid>
							</Grid>

						</CardContent>
					</Card>

				</Grid>
				<Grid item xs={12} sm={6} md={4} lg={3} order={{ xs: 1, md: 2 }}>
					<IterConnessioneSpalla iterConnessione={iterConnessione} />


				</Grid>
			</Grid>
		</Box>
	);
};

export default IterConnessioneIterImpostazioneTab;
