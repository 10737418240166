import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { useAppDispatch, RootState } from "../../../../../redux/store";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import FormControlLabel from "@mui/material/FormControlLabel";
import ReactHookFormSelect from "../../../../common/ReactHookFormSelect";
import {
	ErrorStatusTypes,
	ErrorsStack,
	parseErrorMessage,
} from "../../../../common/errorsDeclarations";

import { Commessa, PodCommessa, savePodCommessa } from "../../commesseSlice";
import MenuItem from "@mui/material/MenuItem";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

import TextField from "@mui/material/TextField";
import Switch from "@mui/material/Switch";

import Alert from "../../../../common/Alert";
import Typography from "@mui/material/Typography";

import { Button } from "@mui/material";
import {
	IterConnessione,
	savePodIterconnessione,
} from "components/main/iterConnessioni/iterConnessioneSlice";

interface NuovoPodCommessaFormProps {
	commessa?: Commessa;
	iterConnessione?: IterConnessione;
	consentiModifica?: boolean;
}

const NuovoPodCommessaForm = ({
	commessa,
	iterConnessione,
	consentiModifica,
}: NuovoPodCommessaFormProps) => {
	const {
		handleSubmit,
		register,
		formState: { errors, isDirty, isValid },
		setValue,
		watch,
		getValues,
		control,
		setError,
		clearErrors,
		reset,
	} = useForm<PodCommessa>();

	const [nuovoPod, setNuovoPod] = useState<PodCommessa>({
		produttore: commessa?.produttore || iterConnessione?.produttore || 0,
		superficie_calpestabile: null,
		numero_unita_immobiliari: null,
		anno_costruzione: null,
	});

	const listaCampi = Object.keys(nuovoPod) as Array<keyof PodCommessa>;

	const dispatch = useAppDispatch();

	const [errorsStack, setErrorsStack] = useState<ErrorsStack>({
		status: ErrorStatusTypes.OK,
	});

	useEffect(() => {
		if (commessa?.pod?.errorsStack) {
			setNuovoPod(commessa.pod);
			setErrorsStack(commessa.pod.errorsStack);
		} else if (iterConnessione?.pod_commessa?.errorsStack) {
			setNuovoPod(iterConnessione.pod_commessa);
			setErrorsStack(iterConnessione.pod_commessa.errorsStack);
		}
	}, [
		commessa?.pod,
		commessa?.pod?.errorsStack,
		iterConnessione?.pod_commessa,
		iterConnessione?.pod_commessa?.errorsStack,
	]);

	const [forzaCambioGestorePod, setForzaCambioGestorePod] = useState(false);

	useEffect(
		() => {
			reset();
			listaCampi.map((field) => {
				setValue(field, nuovoPod[field]);
			});
		},
		[
			// listaCampi.forEach((field) => {
			// 	nuovoPod[field];
			// }),
			// nuovoPod.id,
		]
	);

	useEffect(() => {
		clearErrors();
		listaCampi.forEach((field) => {
			errorsStack?.fieldsErrors &&
				errorsStack.fieldsErrors[field] &&
				setError(field, {
					type: "server",
					message: errorsStack?.fieldsErrors[field].toString(),
				});
		});
	}, [
		errorsStack,
		// nuovoPod?.errorsStack?.fieldsErrors,
		// nuovoPod.allevamento_pack?.errorsStack?.fieldsErrors,
	]);

	const handlerSavePod = (nuovoPod: PodCommessa) => {
		if (consentiModifica) {
			if (commessa) {
				dispatch(
					savePodCommessa({
						commessa: commessa,
						podCommessaToSave: nuovoPod,
						azione: forzaCambioGestorePod ? "forza_cambio_gestore" : undefined,
					})
				);
			} else if (iterConnessione) {
				dispatch(
					savePodIterconnessione({
						iterConnessione: iterConnessione,
						podCommessaToSave: nuovoPod,
						azione: forzaCambioGestorePod ? "forza_cambio_gestore" : undefined,
					})
				);
			}
		}
	};

	const onSubmit: SubmitHandler<PodCommessa> = (nuovoPod) => {
		handlerSavePod(nuovoPod);
	};

	return (
		<Box sx={{ mb: 1 }}>
			<form onSubmit={handleSubmit(onSubmit)}>
				{nuovoPod?.errorsStack?.fieldsErrors?.non_field_errors && (
					<Alert severity="error">
						{nuovoPod?.errorsStack?.fieldsErrors?.non_field_errors.toString()}
					</Alert>
				)}
				{nuovoPod?.errorsStack?.fieldsErrors?.codice?.map((errore, index) => (
					<Alert severity="error" key={`fields_errore_${index}`}>
						{errore}
					</Alert>
				))}
				{/* {commessa?.pod?.errorsStack?.fieldsErrors?.non_field_errors?.map(
					(errore, index) => (
						<Alert severity="error" key={`errore_${index}`}>
							{errore}
						</Alert>
					)
				)} */}
				<Grid container spacing={2} sx={{ mb: 2 }}></Grid>
				<Grid container spacing={2}>
					<Grid item xs={12} sm={6} md={6} lg={4}>
						<TextField
							label="codice POD"
							disabled={!consentiModifica}
							variant="outlined"
							required
							InputLabelProps={{
								shrink: true,
							}}
							{...register("codice")}
							error={!!errors?.codice}
							helperText={errors?.codice?.message}
							autoComplete={"off"}
							sx={{ width: "100%" }}
							fullWidth
							// onBlur={handlerDetailChaingSubmit}
						/>
					</Grid>
				</Grid>
				{!!nuovoPod?.errorsStack?.fieldsErrors?.non_field_errors && (
					<FormControlLabel
						label={
							<Typography fontSize="0.8rem" marginLeft="0.1rem">
								{forzaCambioGestorePod
									? "Forza il cambio gestore sul POD"
									: "attiva per forzare il cambio gestore sul POD"}
							</Typography>
						}
						control={
							<Switch
								id="forza_codice_pod"
								checked={forzaCambioGestorePod || false}
								onChange={() =>
									setForzaCambioGestorePod(!forzaCambioGestorePod)
								}
								color="warning"
								size="small"
							/>
						}
					/>
				)}
				<Button
					disabled={!isDirty || !isValid || !consentiModifica}
					variant="outlined"
					type="submit"
					sx={{ mt: 2 }}
				>
					Aggancia
				</Button>
			</form>
		</Box>
	);
};

export default NuovoPodCommessaForm;
