import { handleResponse, handleError, headerset } from "../apiUtils";
import { DateTime } from "luxon";
import { convertToDateTime } from "components/common/dateUtils/convertToDateTime";
import { Enea } from "../../components/main/iterConnessioni/iterConnessione/enea/eneaSlice";

const baseUrl = (enea_id?: number | null, azione?: string) => {
	if (enea_id) {
		let url = `${process.env.API_URL}/api/enea/${enea_id}/`;
		if (!!azione) {
			url = url + `?azione=${azione}`;
		}
		return url;
	} else {
		return process.env.API_URL + "/api/enea/";
	}
};

export function fetchEnea() {
	let url = baseUrl();
	// data_da.toFormat('yyyy-MM-dd');
	return fetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}

export function getEnea(eneaId: number) {
	let url = baseUrl(eneaId);
	return fetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}

export function saveEnea(eneaToSave: Enea, azione?: string) {
	const enea_data = eneaToSave.enea_data_compilazione
		? convertToDateTime(eneaToSave.enea_data_compilazione)?.setLocale("it").toFormat("yyyy-MM-dd")
		: null;
	const enea = {
		...eneaToSave,
		enea_data_compilazione: enea_data,
	};
	return fetch(baseUrl(eneaToSave.id, azione), {
		method: eneaToSave.id ? "PUT" : "POST", // PUT to update when id already exists.
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
		body: JSON.stringify({
			...enea,
			// Parse authorId to a number (in case it was sent as a string).
			// id: parseInt(allacci.id, 10),
		}),
	})
		.then(handleResponse)
		.catch(handleError);
}

export function deleteEnea(eneaToDelete: Enea) {
	return fetch(baseUrl(eneaToDelete.id), {
		method: "DELETE",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}

////////////////////////////////////////

export function getEneaFromIter(iterId: number) {
	let url = process.env.API_URL + "/api/enea/enea_from_iter/" + iterId + "/";
	return fetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}

/////////////////////
