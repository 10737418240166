import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";

import storage from "redux-persist/lib/storage";
import {
	persistStore,
	persistReducer,
	FLUSH,
	REHYDRATE,
	PAUSE,
	PERSIST,
	PURGE,
	REGISTER,
} from "redux-persist";
import { userPreferenceReducer } from "../components/userPreference/userPreferenceSlice";
import { authenticationReducer } from "../components/authentication/authenticationSlice";
import { allegatiSliceReducer } from "../components/allegati/allegatiSlice";
import { regimiCommercialiSliceReducer } from "../components/anagrafiche/regimiCommerciali/regimiCommercialiSlice";
import { tipiTensioneSliceReducer } from "../components/anagrafiche/tipiTensione/tipiTensioneSlice";
import { tipiPraticaSliceReducer } from "../components/anagrafiche/tipiPratica/tipiPraticaSlice";
import { tipiServizioSliceReducer } from "../components/anagrafiche/tipiServizio/tipiServizioSlice";
import { clientiSliceReducer } from "../components/anagrafiche/clienti/clientiSlice";
import { installatoriSliceReducer } from "../components/anagrafiche/installatori/installatoriSlice";
import { gestoriSliceReducer } from "../components/anagrafiche/gestori/gestoriSlice";
import { comuniSliceReducer } from "../components/anagrafiche/comuni/comuniSlice";
import { produttoriSliceReducer } from "../components/anagrafiche/produttori/produttoriSlice";
import { kpiSliceReducer } from "../components/anagrafiche/kpi/kpiSlice";

import { iterConnessioniSliceReducer } from "../components/main/iterConnessioni/iterConnessioneSlice";
import { progettiSliceReducer } from "../components/main/iterConnessioni/iterConnessione/progetti/progettiSlice";
import { fineLavoriSliceReducer } from "../components/main/iterConnessioni/iterConnessione/richiesteConnessione/fineLavori/fineLavoriSlice";
import { allacciSliceReducer } from "../components/main/iterConnessioni/iterConnessione/richiesteConnessione/allaccio/allaccioSlice";
import { scadenzeSliceReducer } from "../components/main/scadenze/scadenzeSlice";
import { richiesteConnessioneSliceReducer } from "../components/main/iterConnessioni/iterConnessione/richiesteConnessione/richiesteConnessioneSlice";
import { eneaSliceReducer } from "components/main/iterConnessioni/iterConnessione/enea/eneaSlice";
import { officinaElettricaSliceReducer } from "components/main/iterConnessioni/iterConnessione/officinaElettrica/officinaElettricaSlice";
import { autorizzazioneComunaleSliceReducer } from "components/main/iterConnessioni/iterConnessione/autorizzazioneComunale/autorizzazioneComunaleSlice";
import { amministrazioneSliceReducer } from "components/main/amministrazione/amministrazioneSlice";
import { podsSliceReducer } from "../components/main/pods/podsSlice";
import { sopralluoghiSliceReducer } from "components/main/sopralluoghi/sopralluoghiSlice";
import { commesseSliceReducer } from "components/main/commesse/commesseSlice";
import { lettoreCBSliceReducer } from "components/main/commesse/lettoreCB/lettoreCBSlice";
import { adempimentiSliceReducer } from "../components/main/adempimenti/adempimentiSlice";
import { allegatiAdempimentiSliceReducer } from "../components/main/adempimenti/allegatiAdempimenti/allegatiAdempimentiSlice";
import { utentiSliceReducer } from "components/authentication/utenti/utentiSlice";
import { verificheVincoliSliceReducer } from "components/main/verificheVincoli/verificheVincoliSlice";
import { comunitaEnergeticheSliceReducer } from "components/main/comunitaEnergetiche/comunitaEnergeticheSlice";

const persistConfig = {
	key: `root_${process.env.API_URL}`,
	storage,
	whitelist: [
		"userPreferenceState",
		// "authentication",
		"regimiCommercialiState",
		"tipiTensioneState",
		"tipiPraticaState",
		"tipiServizioState",
		"clientiState",
		"installatoriState",
		"gestoriState",
		"comuniState",
		// "produttoriSliceReducer",
	], // Elenco dei reducer persistenti
};

const rootReducer = combineReducers({
	userPreferenceState: userPreferenceReducer,
	authentication: authenticationReducer,
	allegati: allegatiSliceReducer,
	regimiCommercialiState: regimiCommercialiSliceReducer,
	tipiTensioneState: tipiTensioneSliceReducer,
	tipiPraticaState: tipiPraticaSliceReducer,
	tipiServizioState: tipiServizioSliceReducer,
	clientiState: clientiSliceReducer,
	installatoriState: installatoriSliceReducer,
	gestoriState: gestoriSliceReducer,
	comuniState: comuniSliceReducer,
	produttoriState: produttoriSliceReducer,
	kpiState: kpiSliceReducer,

	iterConnessioniState: iterConnessioniSliceReducer,
	progettiState: progettiSliceReducer,
	fineLavoriState: fineLavoriSliceReducer,
	allacciState: allacciSliceReducer,
	scadenzeState: scadenzeSliceReducer,
	eneaState: eneaSliceReducer,
	officinaElettricaState: officinaElettricaSliceReducer,
	autorizzazioneComunaleState: autorizzazioneComunaleSliceReducer,
	amministrazioneState: amministrazioneSliceReducer,
	richiesteConnessioniState: richiesteConnessioneSliceReducer,
	sopralluoghiState: sopralluoghiSliceReducer,
	commesseState: commesseSliceReducer,
	lettoreCBSlice: lettoreCBSliceReducer,
	podsState: podsSliceReducer,
	adempimentiState: adempimentiSliceReducer,
	allegatiAdempimentiState: allegatiAdempimentiSliceReducer,
	utentiState: utentiSliceReducer,
	verificheVincoliState: verificheVincoliSliceReducer,
	comunitaEnergeticheState: comunitaEnergeticheSliceReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
	reducer: persistedReducer,
	devTools: process.env.NODE_ENV !== "production",
	// middleware: [thunk],
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: {
				ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
			},
		}),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>(); // Export a hook that can be reused to resolve types

export default store;
export const persistor = persistStore(store);
