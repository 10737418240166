import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import {
	ErrorStatusTypes,
	ErrorsStack,
	parseErrorMessage,
} from "../../common/errorsDeclarations";
import * as verificheVincoliApi from "../../../api/verificheVincoli/verificheVincoliApi";

import { toast } from "react-toastify";
import { DocumentiPerTipo } from "../../allegati/allegatiSlice";
import { getIterConnessione } from "../iterConnessioni/iterConnessioneSlice";
import { Commessa, getCommessa } from "../commesse/commesseSlice";

// import { useDispatch } from "react-redux";



export enum StatoVerificaVincoliTypes {
	A = "da affidare",
	B = "programmato",
	C = "eseguito",
}

export interface VerificaVincoli {
	id?: number;
	stato_verifica?: string;
	iter_connessione?: number | null;
	commessa?: number | null;
	operatore?: number;
	esecutore?: number;
	cliente?: number;
	produttore?: number;
	produttore_descrizione?: string;
	data_verifica?: Date | string;

	indirizzo?: string;
	numero_civico?: string;
	cap?: string;
	comune?: number;
	cellulare?: number;

	linea_vita?: boolean;
	pratica_paesaggistica?: boolean;
	autorizzativi_comunali?: boolean;
	servizio_i43?: boolean;
	note?: string;

	errorsStack?: ErrorsStack;
}

export interface VerificheVincoliState {
	count: number;
	page: number;
	num_pages: number;
	next?: URL;
	previous?: URL;
	results: VerificaVincoli[];
	errorsStack: ErrorsStack;
}

export interface verificheVincoliStrutturaState {
	verificheVincoli: VerificheVincoliState;
	nuovaVerificaVincoliId?: number;
}

const initialState: verificheVincoliStrutturaState = {
	verificheVincoli: {
		count: 0,
		page: 0,
		num_pages: 0,
		next: undefined,
		previous: undefined,
		results: [],
		errorsStack: { status: ErrorStatusTypes.OK },
	},
};

export const fetchVerificheVincoli = createAsyncThunk(
	"verifiche_vincoli/fetchVerificheVincoli",
	async (parametri: {
		statoVerifica?: string;
		cliente?: number;
		commessa?: number;
		servizio_i43?: string;
		numeroRecord?: number;
		page?: number;
		search?: string;
	}) => {
		return await verificheVincoliApi.fetchVerificheVincoli(
			parametri.statoVerifica,
			parametri.cliente,
			parametri.commessa,
			parametri.servizio_i43,
			parametri.numeroRecord,
			parametri.page,
			parametri.search
		);
	}
);

export const getVerificaVincoli = createAsyncThunk(
	"verifiche_vincoli/getVerificaVincoli",
	async (verificaVincoliId: number) => {
		return await verificheVincoliApi.getVerificaVincoli(verificaVincoliId);
	}
);

export const saveVerificaVincoli = createAsyncThunk(
	"verifiche_vincoli/saveVerificaVincoli",
	async (
		parametri: {
			verificaVincoliToSave: VerificaVincoli;
			azione?: string;
		},
		thunkApi
	) => {
		return await verificheVincoliApi
			.saveVerificaVincoli(parametri.verificaVincoliToSave, parametri.azione)
			.then((response) => {
				thunkApi.dispatch(
					getVerificaVincoli(parametri.verificaVincoliToSave.id || 0)
				);
				return response;
			});
	}
);

export const deleteVerificaVincoli = createAsyncThunk(
	"verifiche_vincoli/deleteVerificaVincoli",
	async (verificaVincoliToDelete: VerificaVincoli, thunkApi) => {
		return await verificheVincoliApi.deleteVerificaVincoli(
			verificaVincoliToDelete
		);
	}
);

////////////////////////////////////////////////////////////////////////////////////

export const creaVerificaVincoliCommessa = createAsyncThunk(
	"verifiche_vincoli/creaVerificaVincoliCommessa",
	async (
		parametri: { commessa: Commessa; servizio_i43?: string },
		thunkApi
	) => {
		return await verificheVincoliApi
			.creaVerificaVincoliCommessa(parametri.commessa, parametri.servizio_i43)
			.then((response) => {
				parametri.commessa.id &&
					thunkApi.dispatch(getCommessa({commessaId:parametri.commessa.id}));
				return response;
			});
	}
);

export const verificheVincoliSlice = createSlice({
	name: "verificheVincoliState",
	initialState,
	reducers: {
		resetVerificaVincoliId: (state) => {
			state.nuovaVerificaVincoliId = undefined;
		},
	},
	extraReducers: (builder) => {
		// fetch Verifiche Vincoli
		builder.addCase(fetchVerificheVincoli.pending, (state, action) => {
			state.verificheVincoli.errorsStack = {
				status: ErrorStatusTypes.PENDING,
			};
		});
		builder.addCase(fetchVerificheVincoli.fulfilled, (state, action) => {
			state.verificheVincoli = action.payload;
			state.verificheVincoli.errorsStack = { status: ErrorStatusTypes.OK };
		});
		builder.addCase(fetchVerificheVincoli.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");
			state.verificheVincoli.errorsStack = {
				status: ErrorStatusTypes.ERROR,
				fieldsErrors: JSON.parse(action?.error?.message || ""),
			};
		});

		// get Verifica Vincoli
		builder.addCase(getVerificaVincoli.pending, (state, action) => {
			state.verificheVincoli.errorsStack = {
				status: ErrorStatusTypes.PENDING,
			};
		});
		builder.addCase(getVerificaVincoli.fulfilled, (state, action) => {
			state.verificheVincoli.results = state.verificheVincoli.results.filter(
				(verifica) => verifica.id != action.payload.id
			);
			state.verificheVincoli.results.push(action.payload);

			state.verificheVincoli.errorsStack = { status: ErrorStatusTypes.OK };
		});
		builder.addCase(getVerificaVincoli.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");
			state.verificheVincoli.errorsStack = {
				status: ErrorStatusTypes.ERROR,
				fieldsErrors: JSON.parse(action?.error?.message || ""),
			};
		});

		// save Verifica Vincoli
		builder.addCase(saveVerificaVincoli.pending, (state, action) => {
			state.verificheVincoli.errorsStack = {
				status: ErrorStatusTypes.PENDING,
			};
		});
		builder.addCase(saveVerificaVincoli.fulfilled, (state, action) => {
			state.verificheVincoli.results = state.verificheVincoli.results.filter(
				(verifica) => verifica.id != action.payload.id
			);
			state.verificheVincoli.results.push({
				...action.payload,
				errorsStack: { status: ErrorStatusTypes.SUCCESS },
			});
			state.verificheVincoli.errorsStack = {
				status: ErrorStatusTypes.SUCCESS,
			};

			// Solo se è nuova creazione ********************
			if (!action.meta.arg.verificaVincoliToSave.id) {
				state.nuovaVerificaVincoliId = action.payload.id;
			}
			toast.success("Verifica Vincoli salvata.");
		});
		builder.addCase(saveVerificaVincoli.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");

			state.verificheVincoli.results = state.verificheVincoli.results.map(
				(verifica) => {
					if (verifica.id == action.meta.arg.verificaVincoliToSave.id) {
						return {
							...verifica,
							errorsStack: {
								status: ErrorStatusTypes.ERROR,
								fieldsErrors: JSON.parse(action?.error?.message || ""),
							},
						};
					} else {
						return verifica;
					}
				}
			);
			state.verificheVincoli.errorsStack = {
				status: ErrorStatusTypes.ERROR,
			};
		});
		// cancella Verifica Vincoli
		builder.addCase(deleteVerificaVincoli.pending, (state, action) => {
			state.verificheVincoli.errorsStack = {
				status: ErrorStatusTypes.PENDING,
			};
		});
		builder.addCase(deleteVerificaVincoli.fulfilled, (state, action) => {
			state.verificheVincoli.errorsStack = {
				status: ErrorStatusTypes.SUCCESS,
			};
			state.verificheVincoli.results = state.verificheVincoli.results.filter(
				(verifica) => verifica.id != action.meta.arg.id
			);
			toast.success(action.payload.message || "Verifica Vincoli cancellata.");
		});
		builder.addCase(deleteVerificaVincoli.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");
			state.verificheVincoli.errorsStack = {
				status: ErrorStatusTypes.ERROR,
				fieldsErrors: JSON.parse(action?.error?.message || ""),
			};
		});

		////////////////////////////////////////////////////////////////////
		// crea Verifica Vincoli da Commessa **************************
		builder.addCase(creaVerificaVincoliCommessa.pending, (state, action) => {
			state.verificheVincoli.errorsStack = { status: ErrorStatusTypes.PENDING };
		});
		builder.addCase(creaVerificaVincoliCommessa.fulfilled, (state, action) => {
			state.verificheVincoli.results = state.verificheVincoli.results.filter(
				(verifica) => verifica.id != action.payload.id
			);
			state.verificheVincoli.results.push({
				...action.payload,
				errorsStack: { status: ErrorStatusTypes.SUCCESS },
			});
			state.verificheVincoli.errorsStack = { status: ErrorStatusTypes.SUCCESS };
			toast.success("Verifica Vincoli salvata.");
		});
		builder.addCase(creaVerificaVincoliCommessa.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");

			state.verificheVincoli.errorsStack = {
				status: ErrorStatusTypes.ERROR,
			};
		});
	},
});

// Action creators are generated for each case reducer function
export const { resetVerificaVincoliId } = verificheVincoliSlice.actions;

export const verificheVincoliSliceReducer = verificheVincoliSlice.reducer;
