import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
	TipoDocumento,
	saveTipoDocumento,
	TipoFaseIterConnessioneTypes,
	TipoCasiIterTypes,
	TipoCasiObbligoTypes,
	TipoCasiVisibiliTypes,
	TipoFaseCommessaTypes,
	TipoVibilitaClienteTypes,
} from "../../allegatiSlice";

import { useAppDispatch, RootState } from "../../../../redux/store";
import { ErrorStatusTypes } from "../../../common/errorsDeclarations";

import { theme } from "../../../theme";
import CircularProgress from "@mui/material/CircularProgress";

import FormHelperText from "@mui/material/FormHelperText";
import FormControlLabel from "@mui/material/FormControlLabel";

import ConfermaAnnulla from "../../../common/ConfermaAnnulla";

import { useForm, SubmitHandler } from "react-hook-form";

import ReactHookFormSelect from "../../../common/ReactHookFormSelect";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import TextField from "@mui/material/TextField";
import Alert from "../../../common/Alert";
import Typography from "@mui/material/Typography";
import Switch from "@mui/material/Switch";
import Stack from "@mui/material/Stack";

interface TipoDocumentoFormProps {
	tipoDocumento: TipoDocumento;
	// produttore?: Soggetto;
	consentiModifica: boolean;
}

const TipoDocumentoForm = ({
	tipoDocumento, // produttore,
	consentiModifica = false,
}: TipoDocumentoFormProps) => {
	const {
		handleSubmit,
		register,
		formState: { errors, isDirty, isValid },
		setValue,
		watch,
		getValues,
		control,
		setError,
		clearErrors,
		reset,
	} = useForm<TipoDocumento>();

	const listaCampi = Object.keys(tipoDocumento) as Array<keyof TipoDocumento>;

	const dispatch = useAppDispatch();

	const tipoDocumentoStatus = useSelector<RootState, ErrorStatusTypes>(
		(state) => state.allegati.tipiDocumento.errorsStack?.status
	);

	const [openConferma, setOpenConferma] = useState(false);
	const handleConfermaClose = () => setOpenConferma(false);

	const [annulla, setAnnulla] = useState<boolean>(false);

	useEffect(() => {
		reset();
		listaCampi.map((field) => {
			setValue(field, tipoDocumento[field]);
		});
		// setKeyPod(makeid(5));

		setAnnulla(false);
	}, [tipoDocumento, tipoDocumento.id, annulla]);

	useEffect(() => {
		clearErrors();
		listaCampi.forEach((field) => {
			tipoDocumento?.errorsStack?.fieldsErrors &&
				tipoDocumento.errorsStack.fieldsErrors[field] &&
				setError(field, {
					type: "server",
					message: tipoDocumento.errorsStack.fieldsErrors[field].toString(),
				});
		});
	}, [
		tipoDocumento?.errorsStack?.fieldsErrors, // sopralluogo.allevamento_pack?.errorsStack?.fieldsErrors,
	]);

	const handlerSaveTipoDocumento = (tipoDocumento: TipoDocumento) => {
		consentiModifica &&
			dispatch(
				saveTipoDocumento({
					tipoDocumentoToSave: tipoDocumento,
				})
			);
		// tipoDocumento.id && dispatch(getTipoDocumento(tipoDocumento.id));
	};

	useEffect(() => {
		console.log('passo dallhooks isDirty==', isDirty);
		if (isValid && isDirty) {
			const formValues = getValues();
			handlerSaveTipoDocumento(formValues);
		}
	}, [
		watch("fase_iter_connessione"),
		watch("casi_visibili"),
		watch("casi_obbligo"),
		watch("casi_iter"),
		watch("obbligatorio_iter"),
		watch("visibilita_cliente"),
		watch("fase_commessa"),
		watch("obbligatorio_commessa"),
	]);

	const handlerDetailChaingSubmit = () => {
		console.log('passo dal onblur isDirty==', isDirty)
		if (isValid && isDirty) {
			const formValues = getValues();
			handlerSaveTipoDocumento(formValues);
		}
	};

	const onSubmit: SubmitHandler<TipoDocumento> = (tipoDocumento) => {
		handlerSaveTipoDocumento(tipoDocumento);
	};

	return (
		<Box sx={{ mb: 1 }}>
			<form onSubmit={handleSubmit(onSubmit)}>
				{tipoDocumento?.errorsStack?.fieldsErrors?.non_field_errors && (
					<Alert severity="error">
						{tipoDocumento?.errorsStack?.fieldsErrors?.non_field_errors.toString()}
					</Alert>
				)}

				<Grid container spacing={2} sx={{ my: 2 }}>
					<Grid item xs={12} sm={6} lg={4}>
						<TextField
							fullWidth
							label="Nome"
							variant="outlined"
							{...register("nome", { required: "Campo obbligatorio" })}
							error={errors?.nome?.message ? true : false}
							helperText={errors?.nome?.message}
							disabled={!consentiModifica}
							onBlur={handlerDetailChaingSubmit}
						/>

						<Box>
							{(isDirty ||
								tipoDocumentoStatus == ErrorStatusTypes.SUCCESS ||
								tipoDocumentoStatus == ErrorStatusTypes.ERROR) && (
								<Typography
									sx={{
										color: isDirty
											? theme.palette.warning.main
											: tipoDocumentoStatus == ErrorStatusTypes.SUCCESS
											? theme.palette.success.main
											: tipoDocumentoStatus == ErrorStatusTypes.ERROR
											? theme.palette.error.main
											: theme.palette.warning.main,
									}}
									variant="caption"
								>
									{tipoDocumentoStatus == ErrorStatusTypes.PENDING && (
										<CircularProgress size={5} />
									)}

									{isDirty
										? "Modifiche non salvate"
										: tipoDocumentoStatus == ErrorStatusTypes.SUCCESS
										? "Salvato"
										: tipoDocumentoStatus == ErrorStatusTypes.ERROR
										? "Errore"
										: ""}
								</Typography>
							)}
						</Box>
					</Grid>

					<Grid item xs={8}>
						<TextField
							fullWidth
							label="Descrizione"
							variant="outlined"
							multiline
							rows={2}
							{...register("descrizione")}
							error={errors?.descrizione?.message ? true : false}
							helperText={errors?.descrizione?.message}
							disabled={!consentiModifica}
							onBlur={handlerDetailChaingSubmit}
						/>
					</Grid>

					<Grid item xs={12} sm={6} md={6} lg={4}>
						<ReactHookFormSelect
							name="casi_iter"
							label="valido per ampliamento/nuovo (se non valorizzato vale per entrambi)"
							register={register}
							control={control}
							disabled={!consentiModifica}
							watch={watch}
							variant="outlined"
							error={errors?.fase_iter_connessione?.message}
							sx={{ width: "100%" }}
						>
							<MenuItem value="" key="casi_iter_vuoto">
								<em>Entrambi</em>
							</MenuItem>
							{Object.entries(TipoCasiIterTypes).map(([key, value]) => {
								return (
									<MenuItem key={key} value={key}>
										{value}
									</MenuItem>
								);
							})}
						</ReactHookFormSelect>
					</Grid>

					<Grid item xs={12} sm={6} md={6} lg={4}>
						<ReactHookFormSelect
							name="visibilita_cliente"
							label="visibilita del cliente"
							register={register}
							control={control}
							disabled={!consentiModifica}
							watch={watch}
							variant="outlined"
							error={errors?.visibilita_cliente?.message}
							sx={{ width: "100%" }}
						>
							{Object.entries(TipoVibilitaClienteTypes).map(([key, value]) => {
								return (
									<MenuItem key={key} value={key}>
										{value}
									</MenuItem>
								);
							})}
						</ReactHookFormSelect>
					</Grid>
					<Grid item xs={12} sm={6} md={6} lg={4}></Grid>
					<Grid item xs={12} sm={6} md={6} lg={4}>
						<ReactHookFormSelect
							name="casi_visibili"
							label="valido per PF/PG (se non valorizzato visibili per entrambi)"
							register={register}
							control={control}
							disabled={!consentiModifica}
							watch={watch}
							variant="outlined"
							error={errors?.casi_visibili?.message}
							sx={{ width: "100%" }}
						>
							<MenuItem value="" key="casi_visibili_vuoto">
								<em>Entrambi</em>
							</MenuItem>

							{Object.entries(TipoCasiVisibiliTypes).map(([key, value]) => {
								return (
									<MenuItem key={key} value={key}>
										{value}
									</MenuItem>
								);
							})}
						</ReactHookFormSelect>
					</Grid>
					<Grid item xs={12} sm={6} md={6} lg={4}>
						<ReactHookFormSelect
							name="casi_obbligo"
							label="nel caso di obbligo, obbligatorio per PF/PG (se non valorizzato obbligatori per entrambi)"
							register={register}
							control={control}
							disabled={!consentiModifica}
							watch={watch}
							variant="outlined"
							error={errors?.casi_obbligo?.message}
							sx={{ width: "100%" }}
						>
							<MenuItem value="" key="obbligo_vuoto">
								<em>Entrambi</em>
							</MenuItem>

							{Object.entries(TipoCasiObbligoTypes).map(([key, value]) => {
								return (
									<MenuItem key={key} value={key}>
										{value}
									</MenuItem>
								);
							})}
						</ReactHookFormSelect>
					</Grid>
					<Grid item xs={12} md={6}>
						<Card>
							<CardHeader
								title={
									<Typography variant="h5" gutterBottom sx={{ color: "white" }}>
										Iter Connessione
									</Typography>
								}
								sx={{ backgroundColor: "#129555" }}
							/>
							<CardContent>
								<ReactHookFormSelect
									name="fase_iter_connessione"
									label="Fase iter connessione"
									register={register}
									control={control}
									disabled={!consentiModifica}
									watch={watch}
									variant="outlined"
									error={errors?.fase_iter_connessione?.message}
									sx={{ width: "100%" }}
								>
									<MenuItem value="" key="cliente_vuoto">
										<em>Nessuno</em>
									</MenuItem>
									{Object.entries(TipoFaseIterConnessioneTypes).map(
										([key, value]) => {
											return (
												<MenuItem key={key} value={key}>
													{value}
												</MenuItem>
											);
										}
									)}
								</ReactHookFormSelect>
								<Stack
									direction="row"
									spacing={1}
									sx={{
										justifyContent: "flex-start",
										alignItems: "center",
										marginTop: "0.5rem",
										marginLeft: "0.2rem",
									}}
								>
									<Typography
										variant="body1"
										// marginLeft="0.2rem"
									>
										è obbligatorio?
									</Typography>
									<FormControlLabel
										label={
											<Typography fontSize="0.8rem" marginLeft="0.1rem">
												{tipoDocumento.obbligatorio_iter ? "SI" : "NO"}
											</Typography>
										}
										control={
											<Switch
												id="obbligatorio_iter"
												checked={tipoDocumento.obbligatorio_iter || false}
												onChange={(e, checked) => {
													setValue("obbligatorio_iter", checked, {
														shouldDirty: true,
													});
												}}
												color="info"
												size="small"
												sx={{ ml: "0.6rem" }}
											/>
										}
									/>
									{!!errors?.obbligatorio_iter && (
										<FormHelperText error>
											{errors?.obbligatorio_iter?.toString()}
										</FormHelperText>
									)}
								</Stack>
							</CardContent>
						</Card>
					</Grid>
					<Grid item xs={12} md={6}>
						<Card>
							<CardHeader
								title={
									<Typography variant="h5" gutterBottom sx={{ color: "white" }}>
										Commessa
									</Typography>
								}
								sx={{ backgroundColor: "#929555" }}
							/>
							<CardContent>
								<ReactHookFormSelect
									name="fase_commessa"
									label="Fase commessa"
									register={register}
									control={control}
									disabled={!consentiModifica}
									watch={watch}
									variant="outlined"
									error={errors?.fase_commessa?.message}
									sx={{ width: "100%" }}
								>
									<MenuItem value="" key="cliente_vuoto">
										<em>Nessuno</em>
									</MenuItem>
									{Object.entries(TipoFaseCommessaTypes).map(([key, value]) => {
										return (
											<MenuItem key={key} value={key}>
												{value}
											</MenuItem>
										);
									})}
								</ReactHookFormSelect>
								<Stack
									direction="row"
									spacing={1}
									sx={{
										justifyContent: "flex-start",
										alignItems: "center",
										marginTop: "0.5rem",
										marginLeft: "0.2rem",
									}}
								>
									<Typography
										variant="body1"
										// marginLeft="0.2rem"
									>
										è obbligatorio?
									</Typography>
									<FormControlLabel
										label={
											<Typography fontSize="0.8rem" marginLeft="0.1rem">
												{tipoDocumento.obbligatorio_commessa ? "SI" : "NO"}
											</Typography>
										}
										control={
											<Switch
												id="obbligatorio_commessa"
												checked={tipoDocumento.obbligatorio_commessa || false}
												onChange={(e, checked) => {
													setValue("obbligatorio_commessa", checked, {
														shouldDirty: true,
													});
												}}
												color="info"
												size="small"
												sx={{ ml: "0.6rem" }}
											/>
										}
									/>
									{!!errors?.obbligatorio_commessa && (
										<FormHelperText error>
											{errors?.obbligatorio_commessa?.toString()}
										</FormHelperText>
									)}
								</Stack>
							</CardContent>
						</Card>
					</Grid>
				</Grid>
			</form>

			<ConfermaAnnulla
				handleAnnulla={handleConfermaClose}
				handleConferma={() => {
					// dispatch(deleteTipoDocumento(tipoDocumento));
					handleConfermaClose();
				}}
				domanda={"Sicuro di voler eliminare  L'tipoDocumento"}
				open={openConferma}
			/>
		</Box>
	);
};

export default TipoDocumentoForm;
