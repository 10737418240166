import { Pratica } from "../amministrazioneSlice";
import { convertToDateTime } from "components/common/dateUtils/convertToDateTime";


export const tabellaListaPraticheFatturabiliMese = (
	pratiche: Pratica[]
): string[][] => {
	const csvData: string[][] = [];

	csvData.push([
		"numero_iter",
		"commessa",
		"fatturabilità",
		"tipo_pratica",
		"tipo_servizio",
		"cliente",
		"produttore",
		"data_richiesta_presa_carico",
		"data_presa_carico",
		"data_alaccio",
	]);

	pratiche.map((pratica) => {
		csvData.push([
			pratica.numero_iter ? pratica.numero_iter.toString() : "",
			pratica.commessa ? pratica.commessa.toString() : "",
			pratica.fatturabilità ? pratica.fatturabilità.toString() : "",
			pratica.tipo_pratica ? pratica.tipo_pratica.toString() : "",
			pratica.tipo_servizio ? pratica.tipo_servizio.toString() : "",
			pratica.cliente ? pratica.cliente.toString() : "",
			pratica.produttore ? pratica.produttore.toString() : "",
			pratica.data_richiesta_presa_carico
				? (convertToDateTime(pratica.data_richiesta_presa_carico)?.setLocale("it").toFormat("dd/MM/yyyy")||"")
				: "",
			pratica.data_presa_carico
				? (convertToDateTime(pratica.data_presa_carico)?.setLocale("it").toFormat("dd/MM/yyyy")||"")
				: "",
			pratica.data_alaccio
				? (convertToDateTime(pratica.data_alaccio)?.setLocale("it").toFormat("dd/MM/yyyy")||"")
				: "",
		]);
	});

	return csvData;
};
