import React, { useEffect } from "react";

import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState, useAppDispatch } from "../../../redux/store";

import { Routes_path } from "../../routerApp";
import { PermissionTypes } from "../../authentication/permissionsGroups";
import { TipoServizio } from "../../anagrafiche/tipiServizio/tipiServizioSlice";

import {
	fetchIterConnessioni,
	IterConnessioniState,
	IterConnessione,
	resetNuovoIterConnessioneId,
	saveIterConnessione,
} from "../iterConnessioni/iterConnessioneSlice";
import { Utente } from "../../authentication/authenticationSlice";
import { StatiIter, ServizioTypes } from "../../anagrafiche/kpi/kpiSlice";

import KpiCard from "./KpiCard";
import KpiList from "./KpiList";
import HomeMenuButton from "./HomeMenuButton";
import Slide from "@mui/material/Slide";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";

const ClienteServiziButtonSection = () => {
	const utente = useSelector<RootState, Utente>(
		(state) => state.authentication.utenteLogged
	);
	const statiIter = useSelector<RootState, StatiIter>(
		(state) => state.kpiState.statiIter
	);
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const tipiServizio = useSelector<RootState, TipoServizio[]>(
		(state) => state.tipiServizioState.tipiServizio.results
	);

	const nuovoIterConnessioneId = useSelector<RootState, number | undefined>(
		(state) => state.iterConnessioniState.nuovoIterConnessioneId
	);

	useEffect(() => {
		!!nuovoIterConnessioneId &&
			navigate(Routes_path.ITER_CONNESSIONE + nuovoIterConnessioneId);
		dispatch(resetNuovoIterConnessioneId());
	}, [nuovoIterConnessioneId]);

	const handlerCreateIterConnessione = (servizio?: ServizioTypes) => {
		const tipoServizio = tipiServizio.find((s) => s.codice == servizio);

		const nuovaIterConnessione: IterConnessione = {
			// tipo_servizio: servizio,
			produttore: null,
			ampliamento: tipoServizio?.is_ampliamento,
			iter_semplificato: !tipoServizio?.is_ampliamento,
			tipo_servizio: tipoServizio?.id,

			stato: "A",
		};

		dispatch(
			saveIterConnessione({ iterConnessioneToSave: nuovaIterConnessione })
		);
	};

	const colorEs = "#C2AC31";
	const colorPe = "#61CE2C";
	const colorFes = "#03b0e2";

	return (
		<Slide in direction="up" timeout={1500}>
			<Box
				sx={{
					padding: "0px 16px",
					p: 0,
					background: "#FFFFFF",
					position: "relative",
					zIndex: "3",
					margin: "-60px 30px 0px",
					borderRadius: 6,
					boxShadow:
						"0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
				}}
			>
				<Typography variant="h4" align="center" sx={{ mt: 2 }}>
					Lista dei servizi
				</Typography>
				{/* <Grid container>
				<Grid item>
					<Typography variant="h3">Cliente:</Typography>
				</Grid>
			</Grid> */}
				<Grid
					container
					direction="row"
					justifyContent="left"
					alignItems="center"
					// sx={{ flexGrow: 1, mb: 1 }}
					rowSpacing={2}
					columnSpacing={{ xs: 1, sm: 2, md: 3 }}
					sx={{ padding: 2 }}
				>
					<Grid item xs={12} sm={12} md={4} lg={4} textAlign={"center"}>
						Engineering Services
					</Grid>
					<Grid item xs={12} sm={12} md={4} lg={4} textAlign={"center"}>
						Photovoltaic Engineering Design
					</Grid>
					<Grid item xs={12} sm={12} md={4} lg={4} textAlign={"center"}>
						Full Engineering Services
					</Grid>
					<Grid item xs={12} sm={12} md={4} lg={4}>
						<HomeMenuButton
							sigla="SOP-FV"
							nome="Sopralluogo"
							descrizione="per rilievo stato di fatto e verifica fattibilità"
							colore={colorEs}
							utente={utente}
							gruppiAutorizzati={[
								PermissionTypes.ALL,
								// PermissionTypes.PIANIFICATORI_LETTURA,
							]}
							path={Routes_path.PRODUTTORE}
							onClick={(event) => {
								event.preventDefault();
								handlerCreateIterConnessione(ServizioTypes.SOP_FV);
							}}
							// disabled={true}
							// Icon={TourIcon}
						/>
					</Grid>
					<Grid item xs={12} sm={12} md={4} lg={4}>
						<HomeMenuButton
							sigla="PE11-FV"
							nome="Progetto Esecutivo Impianto Fotovoltaico sino a 11,08 kWp"
							descrizione="progetto elettrico + progetto architettonico + elenco materiali"
							colore={colorPe}
							utente={utente}
							gruppiAutorizzati={[
								PermissionTypes.ALL,
								// PermissionTypes.PIANIFICATORI_LETTURA,
							]}
							path={Routes_path.PRODUTTORE}
							onClick={(event) => {
								event.preventDefault();
								handlerCreateIterConnessione();
							}}
							disabled={true}
							// Icon={ElectricalServicesIcon}
						/>
					</Grid>

					<Grid item xs={12} sm={12} md={4} lg={4}>
						<HomeMenuButton
							sigla="FES-11,08"
							nome="Full Engineering Service sino a 11,08 kWp"
							descrizione="sopralluogo + progetto esecutivo + autorizzazione comunale + 
						iter connessione + comunicazione Enea + convenzione operatore dispacciamento (GSE)"
							colore={colorFes}
							utente={utente}
							gruppiAutorizzati={[
								PermissionTypes.ALL,
								// PermissionTypes.PIANIFICATORI_LETTURA,
							]}
							path={Routes_path.PRODUTTORE}
							onClick={(event) => {
								event.preventDefault();
								handlerCreateIterConnessione(ServizioTypes.FES_11);
							}}
							// Icon={ElectricalServicesIcon}
						/>
					</Grid>
					<Grid item xs={12} sm={12} md={4} lg={4}>
						<HomeMenuButton
							sigla="ITERS-FV"
							nome="Nuovo Iter Connessione per Impianti Nuovi"
							descrizione="+ schema elettrico + convenzione operatore dispacciamento (GSE)"
							colore={colorEs}
							utente={utente}
							gruppiAutorizzati={[
								PermissionTypes.ALL,
								// PermissionTypes.PIANIFICATORI_LETTURA,
							]}
							path={Routes_path.PRODUTTORE}
							onClick={(event) => {
								event.preventDefault();
								handlerCreateIterConnessione(ServizioTypes.ITERS_FV);
							}}
							// Icon={TourIcon}
						/>
					</Grid>
					<Grid item xs={12} sm={12} md={4} lg={4}>
						<HomeMenuButton
							sigla="PE20-FV"
							nome="Progetto Esecutivo Impianto Fotovoltaico sino a 20 kWp"
							descrizione="progetto elettrico + progetto architettonico + elenco materiali"
							colore={colorPe}
							utente={utente}
							gruppiAutorizzati={[
								PermissionTypes.ALL,
								// PermissionTypes.PIANIFICATORI_LETTURA,
							]}
							path={Routes_path.PRODUTTORE}
							onClick={(event) => {
								event.preventDefault();
								handlerCreateIterConnessione();
							}}
							disabled={true}

							// Icon={ElectricalServicesIcon}
						/>
					</Grid>

					<Grid item xs={12} sm={12} md={4} lg={4}>
						<HomeMenuButton
							sigla="FES-20"
							nome="Full Engineering Service sino a 20 kWp"
							descrizione="sopralluogo + progetto esecutivo + autorizzazione comunale + 
						iter connessione  + taratura dispositivo interfaccia (SPI) + comunicazione Enea + convenzione operatore dispacciam. (GSE)"
							colore={colorFes}
							utente={utente}
							gruppiAutorizzati={[
								PermissionTypes.ALL,
								// PermissionTypes.PIANIFICATORI_LETTURA,
							]}
							path={Routes_path.PRODUTTORE}
							onClick={(event) => {
								event.preventDefault();
								handlerCreateIterConnessione(ServizioTypes.FES_20);
							}}
							// Icon={ElectricalServicesIcon}
						/>
					</Grid>
					<Grid item xs={12} sm={12} md={4} lg={4}>
						<HomeMenuButton
							sigla="ITERO-FV"
							nome="Nuovo Iter Connessione per ampliam. Impianti Esistenti"
							descrizione="+ schema elettrico + convenzione operatore dispacciamento (GSE)"
							colore={colorEs}
							utente={utente}
							gruppiAutorizzati={[
								PermissionTypes.ALL,
								// PermissionTypes.PIANIFICATORI_LETTURA,
							]}
							path={Routes_path.PRODUTTORE}
							onClick={(event) => {
								event.preventDefault();
								handlerCreateIterConnessione(ServizioTypes.ITERO_FV);
							}}
							// Icon={TourIcon}
						/>
					</Grid>
					<Grid item xs={12} sm={12} md={4} lg={4}>
						<HomeMenuButton
							sigla="PE150-FV"
							nome="Progetto Esecutivo Impianto Fotovoltaico sino a 150 kWp"
							descrizione="progetto elettrico + progetto architettonico + elenco materiali"
							colore={colorPe}
							utente={utente}
							gruppiAutorizzati={[
								PermissionTypes.ALL,
								// PermissionTypes.PIANIFICATORI_LETTURA,
							]}
							path={Routes_path.PRODUTTORE}
							onClick={(event) => {
								event.preventDefault();
								handlerCreateIterConnessione();
							}}
							disabled={true}

							// Icon={ElectricalServicesIcon}
						/>
					</Grid>

					<Grid item xs={12} sm={12} md={4} lg={4}>
						<HomeMenuButton
							sigla="FES-150"
							nome="Full Engineering Service sino a 150 kWp"
							descrizione="sopralluogo + progetto esecutivo + autorizzazione comunale + 
						iter connessione  + taratura dispositivo interfaccia (SPI) + pratica apertura officina elettrica (ADM) 
						+ convenzione operatore dispacciam. (GSE)"
							colore={colorFes}
							utente={utente}
							gruppiAutorizzati={[
								PermissionTypes.ALL,
								// PermissionTypes.PIANIFICATORI_LETTURA,
							]}
							path={Routes_path.PRODUTTORE}
							onClick={(event) => {
								event.preventDefault();
								handlerCreateIterConnessione(ServizioTypes.FES_150);
							}}
							// Icon={ElectricalServicesIcon}
						/>
					</Grid>
					<Grid item xs={12} sm={12} md={4} lg={4}>
						<HomeMenuButton
							sigla="ENEA-FV"
							nome="Comunicazione su portale ENEA"
							descrizione="intervento effettuato"
							colore={colorEs}
							utente={utente}
							gruppiAutorizzati={[
								PermissionTypes.ALL,
								// PermissionTypes.PIANIFICATORI_LETTURA,
							]}
							path={Routes_path.PRODUTTORE}
							onClick={(event) => {
								event.preventDefault();
								handlerCreateIterConnessione(ServizioTypes.ENEA_FV);
							}}
							// Icon={TourIcon}
						/>
					</Grid>
					<Grid item xs={12} sm={12} md={4} lg={4}>
						<HomeMenuButton
							sigla="PE300-FV"
							nome="Progetto Esecutivo Impianto Fotovoltaico sino a 300 kWp"
							descrizione="progetto elettrico + progetto architettonico + elenco materiali"
							colore={colorPe}
							utente={utente}
							gruppiAutorizzati={[
								PermissionTypes.ALL,
								// PermissionTypes.PIANIFICATORI_LETTURA,
							]}
							path={Routes_path.PRODUTTORE}
							onClick={(event) => {
								event.preventDefault();
								handlerCreateIterConnessione();
							}}
							disabled={true}

							// Icon={ElectricalServicesIcon}
						/>
					</Grid>

					<Grid item xs={12} sm={12} md={4} lg={4}>
						<HomeMenuButton
							sigla="FES-300"
							nome="Full Engineering Service sino a 300 kWp"
							descrizione="sopralluogo + progetto esecutivo + autorizzazione comunale + 
						iter connessione  + taratura dispositivo interfaccia (SPI) + pratica apertura officina elettrica (ADM) 
						+ convenzione operatore dispacciam. (GSE)"
							colore={colorFes}
							utente={utente}
							gruppiAutorizzati={[
								PermissionTypes.ALL,
								// PermissionTypes.PIANIFICATORI_LETTURA,
							]}
							path={Routes_path.PRODUTTORE}
							onClick={(event) => {
								event.preventDefault();
								handlerCreateIterConnessione();
							}}
							disabled={true}

							// Icon={ElectricalServicesIcon}
						/>
					</Grid>
					<Grid item xs={12} sm={12} md={4} lg={4}>
						<HomeMenuButton
							sigla="TAR-SPI"
							nome="Taratura Dispositivo di Interfaccia (SPI)"
							descrizione="+ aggiornamento regolamento di esercizio Gestore di Rete"
							colore={colorEs}
							utente={utente}
							gruppiAutorizzati={[
								PermissionTypes.ALL,
								// PermissionTypes.PIANIFICATORI_LETTURA,
							]}
							path={Routes_path.PRODUTTORE}
							onClick={(event) => {
								event.preventDefault();
								handlerCreateIterConnessione();
							}}
							disabled={true}

							// Icon={TourIcon}
						/>
					</Grid>
					<Grid item xs={12} sm={12} md={4} lg={4}>
						<HomeMenuButton
							sigla="PE500-FV"
							nome="Progetto Esecutivo Impianto Fotovoltaico sino a 500 kWp"
							descrizione="progetto elettrico + progetto architettonico + elenco materiali"
							colore={colorPe}
							utente={utente}
							gruppiAutorizzati={[
								PermissionTypes.ALL,
								// PermissionTypes.PIANIFICATORI_LETTURA,
							]}
							path={Routes_path.PRODUTTORE}
							onClick={(event) => {
								event.preventDefault();
								handlerCreateIterConnessione();
							}}
							disabled={true}

							// Icon={ElectricalServicesIcon}
						/>
					</Grid>

					<Grid item xs={12} sm={12} md={4} lg={4}>
						<HomeMenuButton
							sigla="FES-500"
							nome="Full Engineering Service sino a 500 kWp"
							descrizione="sopralluogo + progetto esecutivo + autorizzazione comunale + 
						iter connessione  + taratura dispositivo interfaccia (SPI) + pratica apertura officina elettrica (ADM) 
						+ convenzione operatore dispacciam. (GSE)"
							colore={colorFes}
							utente={utente}
							gruppiAutorizzati={[
								PermissionTypes.ALL,
								// PermissionTypes.PIANIFICATORI_LETTURA,
							]}
							path={Routes_path.PRODUTTORE}
							onClick={(event) => {
								event.preventDefault();
								handlerCreateIterConnessione();
							}}
							disabled={true}

							// Icon={ElectricalServicesIcon}
						/>
					</Grid>
					<Grid item xs={12} sm={12} md={4} lg={4}>
						<HomeMenuButton
							sigla="OFF-FV"
							nome="Officina Elettrica (ADM)"
							descrizione="Apertura nuova Officina Elettrica"
							colore={colorEs}
							utente={utente}
							gruppiAutorizzati={[
								PermissionTypes.ALL,
								// PermissionTypes.PIANIFICATORI_LETTURA,
							]}
							path={Routes_path.PRODUTTORE}
							onClick={(event) => {
								event.preventDefault();
								handlerCreateIterConnessione(ServizioTypes.OFF_FV);
							}}
							// Icon={TourIcon}
						/>
					</Grid>
					<Grid item xs={12} sm={12} md={4} lg={4}>
						<HomeMenuButton
							sigla="PE750-FV"
							nome="Progetto Esecutivo Impianto Fotovoltaico sino a 750 kWp"
							descrizione="progetto elettrico + progetto architettonico + elenco materiali"
							colore={colorPe}
							utente={utente}
							gruppiAutorizzati={[
								PermissionTypes.ALL,
								// PermissionTypes.PIANIFICATORI_LETTURA,
							]}
							path={Routes_path.PRODUTTORE}
							onClick={(event) => {
								event.preventDefault();
								handlerCreateIterConnessione();
							}}
							disabled={true}

							// Icon={ElectricalServicesIcon}
						/>
					</Grid>

					<Grid item xs={12} sm={12} md={4} lg={4}>
						<HomeMenuButton
							sigla="FES-750"
							nome="Full Engineering Service sino a 750 kWp"
							descrizione="sopralluogo + progetto esecutivo + autorizzazione comunale + 
						iter connessione  + taratura dispositivo interfaccia (SPI) + pratica apertura officina elettrica (ADM) 
						+ convenzione operatore dispacciam. (GSE)"
							colore={colorFes}
							utente={utente}
							gruppiAutorizzati={[
								PermissionTypes.ALL,
								// PermissionTypes.PIANIFICATORI_LETTURA,
							]}
							path={Routes_path.PRODUTTORE}
							onClick={(event) => {
								event.preventDefault();
								handlerCreateIterConnessione();
							}}
							disabled={true}

							// Icon={ElectricalServicesIcon}
						/>
					</Grid>
					<Grid item xs={12} sm={12} md={4} lg={4}>
						<HomeMenuButton
							sigla="PRA-FV"
							nome="Pratiche Varie Operatore di Dispacciamento (GSE)"
							descrizione="cambio titolarità, cambio Iban, ecc..."
							colore={colorEs}
							utente={utente}
							gruppiAutorizzati={[
								PermissionTypes.ALL,
								// PermissionTypes.PIANIFICATORI_LETTURA,
							]}
							path={Routes_path.PRODUTTORE}
							onClick={(event) => {
								event.preventDefault();
								handlerCreateIterConnessione();
							}}
							disabled={true}

							// Icon={TourIcon}
						/>
					</Grid>
					<Grid item xs={12} sm={12} md={4} lg={4}>
						<HomeMenuButton
							sigla="PE1000-FV"
							nome="Progetto Esecutivo Impianto Fotovoltaico sino a 1000 kWp"
							descrizione="progetto elettrico + progetto architettonico + elenco materiali"
							colore={colorPe}
							utente={utente}
							gruppiAutorizzati={[
								PermissionTypes.ALL,
								// PermissionTypes.PIANIFICATORI_LETTURA,
							]}
							path={Routes_path.PRODUTTORE}
							onClick={(event) => {
								event.preventDefault();
								handlerCreateIterConnessione();
							}}
							disabled={true}

							// Icon={ElectricalServicesIcon}
						/>
					</Grid>

					<Grid item xs={12} sm={12} md={4} lg={4}>
						<HomeMenuButton
							sigla="FES-1000"
							nome="Full Engineering Service sino a 1000 kWp"
							descrizione="sopralluogo + progetto esecutivo + autorizzazione comunale + 
						iter connessione  + taratura dispositivo interfaccia (SPI) + pratica apertura officina elettrica (ADM) 
						+ convenzione operatore dispacciam. (GSE)"
							colore={colorFes}
							utente={utente}
							gruppiAutorizzati={[
								PermissionTypes.ALL,
								// PermissionTypes.PIANIFICATORI_LETTURA,
							]}
							path={Routes_path.PRODUTTORE}
							onClick={(event) => {
								event.preventDefault();
								handlerCreateIterConnessione();
							}}
							disabled={true}

							// Icon={ElectricalServicesIcon}
						/>
					</Grid>
					<Grid item xs={12} sm={12} md={4} lg={4}>
						<HomeMenuButton
							sigla="ADEM-FV"
							nome="Gestione scadenze mantenim. esercizio impiant. fotov."
							descrizione="Officina elettrica, GSE, ARERA, Taratura periodica gruppo di misura (ADM), 
											Taratura periodica SPI (Gestore di Rete)"
							colore={colorEs}
							utente={utente}
							gruppiAutorizzati={[
								PermissionTypes.ALL,
								// PermissionTypes.PIANIFICATORI_LETTURA,
							]}
							path={Routes_path.PRODUTTORE}
							onClick={(event) => {
								event.preventDefault();
								handlerCreateIterConnessione(ServizioTypes.ADEM_FV);
							}}
							// Icon={TourIcon}
						/>
					</Grid>
					<Grid item xs={12} sm={12} md={4} lg={4}></Grid>

					<Grid item xs={12} sm={12} md={4} lg={4}></Grid>
				</Grid>
			</Box>
		</Slide>
	);
};

export default ClienteServiziButtonSection;
