import React from "react";
import { RootState, useAppDispatch } from "../../../redux/store";
import { useSelector } from "react-redux";

import { Utente } from "../../authentication/authenticationSlice";
import {
	richiesteRilavorateKpiFetch,
	RichiesteRilavorateKpi,
} from "../../anagrafiche/kpi/kpiSlice";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Stack } from "@mui/material";
import ArchitectureIcon from "@mui/icons-material/Architecture";
import Chip from "@mui/material/Chip";
import {
	ErrorStatusTypes,
	ErrorsStack,
	parseErrorMessage,
} from "../../common/errorsDeclarations";
import CircularProgress from "@mui/material/CircularProgress";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";

interface RichiesteRilavorateKpiCardProps {
	data_da?: Date;
	data_a?: Date;
	periodo?: string;
}

const RichiesteRilavorateKpiCard = ({
	data_da,
	data_a,
	periodo,
}: RichiesteRilavorateKpiCardProps) => {
	const dispatch = useAppDispatch();

	React.useEffect(() => {
		dispatch(richiesteRilavorateKpiFetch({ data_da, data_a, periodo }));
	}, [data_da, data_a, periodo]);

	const richiesteRilavorateKpi = useSelector<RootState, RichiesteRilavorateKpi>(
		(state) => state.kpiState.richiesteRilavorateKpi
	);

	return (
		<Box>
			<Card variant="outlined">
				<CardContent>
					<Stack
						direction="column"
						spacing={2}
						justifyContent="center"
						alignItems="center"
						sx={{ minHeight: 200 }}
					>
						{richiesteRilavorateKpi.errorsStack.status ==
						ErrorStatusTypes.PENDING ? (
							<CircularProgress />
						) : (
							<Typography variant="h2" sx={{ color: "orange" }}>
								{richiesteRilavorateKpi?.richieste_rilavorate_kpi}
							</Typography>
						)}
						<Chip
							icon={
								<AddShoppingCartIcon color="error" sx={{ fontSize: 100 }} />
							}
							label="Rilav. dom. connessione"
						/>
					</Stack>
				</CardContent>
			</Card>
		</Box>
	);
};

export default RichiesteRilavorateKpiCard;
