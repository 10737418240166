import { useSelector } from "react-redux";
import React, { useEffect, useState } from "react";

import {
	Sopralluogo,
	saveSopralluogo,
	StatoSopralluogoTypes,
} from "components/main/sopralluoghi/sopralluoghiSlice";

import { DateTime } from "luxon";

import { RootState, useAppDispatch } from "../../../../redux/store";
import { PermissionTypes } from "../../../authentication/permissionsGroups";
import { utenteAutorizzato } from "../../../authentication/UtenteAutorizzato";
import { UtenteLogged } from "../../../authentication/authenticationSlice";

import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

interface AzioniSopralluogoCardProps {
	sopralluogo: Sopralluogo;
}

const AzioniSopralluogoCard = ({ sopralluogo }: AzioniSopralluogoCardProps) => {
	
	const dispatch = useAppDispatch();

	const utente = useSelector<RootState, UtenteLogged>(
		(state) => state.authentication.utenteLogged
	);
	const [formRigetto, setFormRigetto] = useState<boolean>(false);

	return (
		<Stack sx={{ width: "100%" }} spacing={1}>
			{sopralluogo.stato == "A" && (
				<Button
					variant="contained"
					size="large"
					disabled={!sopralluogo.operatore}
					sx={{ width: "100%", mt: 1 }}
					onClick={() => {
						dispatch(
							saveSopralluogo({
								sopralluogoToSave: sopralluogo,
								azione: "assegna",
							})
						);
					}}
				>
					Assegna sopralluogo
				</Button>
			)}

			{sopralluogo.stato == "B" && (
				<Button
					variant="contained"
					size="large"
					disabled={!sopralluogo.operatore}
					sx={{ width: "100%", mt: 1 }}
					onClick={() => {
						dispatch(
							saveSopralluogo({
								sopralluogoToSave: sopralluogo,
								azione: "eseguito",
							})
						);
					}}
				>
					Contrassegna come eseguito
				</Button>
			)}
		</Stack>
	);
};

export default AzioniSopralluogoCard;
