import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import RouterApp, { Routes_path } from "./routerApp";
import RouterAppMaintenance from "./RouterAppMaintenance";
import SideBar from "./common/sideBar/SideBar";
import TopBar from "./common/topBar/TopBar";

import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../redux/store";
import { UtenteLogged, saveUtente } from "./authentication/authenticationSlice";
import {
	PreferenzeUtente,
	PreferenzeUtenteState,
	setDrawerAperto,
} from "./userPreference/userPreferenceSlice";
import CssBaseline from "@mui/material/CssBaseline";
import { styled } from "@mui/material/styles";
import "react-toastify/dist/ReactToastify.css";
import Box from "@mui/material/Box";
import Copyright from "./common/Copyright";
import { Stack } from "@mui/material";
import { PermissionTypes } from "./authentication/permissionsGroups";

import logoPiccolo from "../../static/images/logo/logo-I43_biglia.png";
import logoGrande from "../../static/images/logo/logo-I43_lungo.png";

const sideMenuDrawerWidth = 240;
const miniSideMenuDrawerWidth = 70;
const appBarAltezza = 70;

const HeaderSpacer = styled("div")(({ theme }) => ({
	display: "flex",
	alignItems: "center",
	justifyContent: "flex-end",
	padding: 0,
	marginTop: miniSideMenuDrawerWidth,
	// // necessary for content to be below app bar
	// ...theme.mixins.toolbar,
}));

const AppComposer = () => {
	const location = useLocation();
	const dispatch = useAppDispatch();
	const utente = useSelector<RootState, UtenteLogged>(
		(state) => state.authentication.utenteLogged
	);

	const preferenzeUtente = useSelector<RootState, PreferenzeUtente | undefined>(
		(state) => state.userPreferenceState.preferenzeUtente
	);

	const [drawerPreview, setDrawerPreview] = React.useState(false);

	const [drawerIsOpen, setDrawerIsOpen] = React.useState(
		preferenzeUtente ? preferenzeUtente.drawer_aperto : true
		// true
	);

	const tooggleDrawer = () => {
		setDrawerIsOpen(!drawerIsOpen);
		dispatch(setDrawerAperto(!drawerIsOpen));
	};

	const drawerPreviewOn = () => {
		setDrawerPreview(true);
	};

	const drawerPreviewOff = () => {
		setDrawerPreview(false);
	};

	// creo un hooks e se l'utente ha come unico gruppo utenti_comunita_energetiche allora opero un redirect forzato attraverso react rooter dom  alla pagina della sua comunità energetica
	// const navigate = useNavigate();

	const [sideBarPresente, setSideBarPresente] = React.useState(false);

	// useEffect(() => {
	// 	if (utente && !utente.is_superuser && utente.gruppi) {
	// 		if (utente.gruppi.length == 1) {
	// 			if (utente.gruppi[0] == PermissionTypes.UTENTI_COMUNITA_ENERGETICHE) {
	// 				navigate(Routes_path.COMUNITA_ENERGETICA + 9999, {
	// 					replace: true,
	// 				});
	// 			}
	// 		}
	// 	}
	// }, [utente]);

	useEffect(() => {
		if (
			location.pathname.startsWith(Routes_path.PODS) ||
			location.pathname.startsWith(Routes_path.POD) ||
			location.pathname.startsWith(Routes_path.ITER_CONNESSIONI) ||
			location.pathname.startsWith(Routes_path.ITER_CONNESSIONE) ||
			location.pathname.startsWith(Routes_path.SCADENZE) ||
			location.pathname.startsWith(Routes_path.SOPRALLUOGHI) ||
			location.pathname.startsWith(Routes_path.SOPRALLUOGO) ||
			location.pathname.startsWith(Routes_path.RICHIESTE_CONNESSIONE) ||
			location.pathname.startsWith(Routes_path.PRATICHE_COMUNALI) ||
			location.pathname.startsWith(Routes_path.PRATICA_COMUNALE) ||
			location.pathname.startsWith(
				Routes_path.OFFICINA_ELETTRICA_NUOVA_ISTANZA
			) ||
			location.pathname.startsWith(Routes_path.ANAGRAFICHE) ||
			location.pathname.startsWith(Routes_path.PRODUTTORI) ||
			location.pathname.startsWith(Routes_path.PRODUTTORE) ||
			location.pathname.startsWith(Routes_path.CLIENTI) ||
			location.pathname.startsWith(Routes_path.CLIENTE) ||
			location.pathname.startsWith(Routes_path.INSTALLATORI) ||
			location.pathname.startsWith(Routes_path.INSTALLATORE) ||
			location.pathname.startsWith(Routes_path.GESTORE) ||
			location.pathname.startsWith(Routes_path.FATTURABILI) ||
			location.pathname.startsWith(Routes_path.ADEMPIMENTI) ||
			location.pathname.startsWith(Routes_path.ADEMPIMENTO) ||
			location.pathname.startsWith(Routes_path.SERVIZI_ADEMPIMENTI) ||
			location.pathname.startsWith(Routes_path.SERVIZIO_ADEMPIMENTI) ||
			location.pathname.startsWith(Routes_path.COMMESSE) ||
			location.pathname.startsWith(Routes_path.COMMESSA) ||
			location.pathname.startsWith(Routes_path.VERIFICHE_VINCOLI) ||
			location.pathname.startsWith(Routes_path.VERIFICA_VINCOLI) ||
			location.pathname.startsWith(Routes_path.COMUNITA_ENERGETICHE) ||
			location.pathname.startsWith(Routes_path.UTENTI) ||
			location.pathname.startsWith(Routes_path.UTENTE) ||
			location.pathname.startsWith(Routes_path.TIPI_DOCUMENTO) ||
			location.pathname.startsWith(Routes_path.TIPO_DOCUMENTO)
		) {
			setSideBarPresente(true);
		} else {
			setSideBarPresente(false);
		}
	}, [location.pathname]);

	return (
		<Box
			sx={{
				display: { xs: "block", sm: "flex", md: "flex", lg: "flex" },
			}}
		>
			<CssBaseline />

			<TopBar
				open={drawerIsOpen || drawerPreview}
				// sideMenuDrawerWidth={sideMenuDrawerWidth}
				sideMenuDrawerWidth={0}
				miniSideMenuDrawerWidth={miniSideMenuDrawerWidth}
				appBarAltezza={appBarAltezza}
				logoPiccolo={logoPiccolo}
				tooggleDrawer={tooggleDrawer}
			/>
			{sideBarPresente && (
				<SideBar
					drawerPreviewOn={drawerPreviewOn}
					drawerPreviewOff={drawerPreviewOff}
					open={drawerIsOpen || drawerPreview}
					sideMenuDrawerWidth={sideMenuDrawerWidth}
					miniSideMenuDrawerWidth={miniSideMenuDrawerWidth}
					tooggleDrawer={tooggleDrawer}
					logoPiccolo={logoPiccolo}
					logoGrande={logoGrande}
					appBarAltezza={appBarAltezza}
				/>
			)}
			<Box
				component="main"
				sx={{ flexGrow: 1, p: 0 }}

				// sx={{
				// 	flexGrow: { lg: 1 },
				// 	p: { xs: 2, lg: 3 },
				// 	width: { sm: "92%", md: "92%", lg: "100%" },
				// }}
			>
				{/* <Box component="main" sx={{ flexGrow: 1, p: 3 }}> */}
				<HeaderSpacer />
				{/* <Box sx={{ minHeight: "90vh",p: 0, m: 0 }}> */}
				{/* <Box component="div" sx={{ p: 0, m: 0 }}> */}
				<Box sx={{ minHeight: "50vh", p: 0, m: 0 }}>
					<RouterApp />
					{/*<RouterAppMaintenance />*/}
					<Copyright />
				</Box>
			</Box>
		</Box>
	);
};

export default AppComposer;
