import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/system";

const Content = styled(Box)(({ theme }) => ({
	zIndex: 2,
	padding: theme.spacing(3),
	backgroundColor: theme.palette.primary.main,
	clipPath: "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%, 10% 50%, 0% 0%)",
	width: "100%",
	height: "100%",
}));

interface ArrowStepperProps {
	titolo: string;
	passi: { indice: string; descrizione: string }[];
	colore: string;
	indicePassoCorrente: number;
}
const ArrowStepper = ({
	titolo,
	passi,
	colore,
	indicePassoCorrente,
}: ArrowStepperProps) => {
	return (
		<Box sx={{ backgroundColor: "#555" }}>
			<Typography variant="h3" gutterBottom color={"#eee"} sx={{ m: 2 }}>
				{titolo}
			</Typography>
			<Grid container sx={{ mb: 2 }}>
				{passi.map((passo, index) => (
					<Grid
						item
						key={index}
						sx={{
							borderStyle: "none",
							// display: "flex",
							justifyContent: "center",
							alignItems: "center",
							// left: `${(index * 100) / passi.length}%`,
							width: `calc(100% / ${passi.length})`,
							backgroundColor:
								index == indicePassoCorrente + 1
									? colore
									: indicePassoCorrente >= index
									? `#444`
									: "#aaa",
							// backgroundImage:
							// 	indicePassoCorrente == index
							// 		? `linear-gradient(90deg,#444 50%,#999 50%)`
							// 		: indicePassoCorrente > index
							// 		? `linear-gradient(90deg,#444 50%,#555 50%)`
							// 		: `linear-gradient(90deg,#aaa 50%,#aaa 50%)`,
						}}
					>
						<Content
							sx={{
								backgroundImage:
									indicePassoCorrente == index
										? `linear-gradient(90deg,${colore}dd ,${colore}  )`
										: indicePassoCorrente > index
										? `linear-gradient(90deg,#555 ,#444)`
										: `linear-gradient(90deg,#999 ,#aaa)`,
								color: indicePassoCorrente == index ? "#eee" : "inherit",
							}}
						>
							<Typography variant="h6" sx={{ pl: 10 }}>
								{passo.indice}
							</Typography>
							<Typography variant="subtitle1" sx={{ pl: 10 }}>
								{passo.descrizione}
							</Typography>
						</Content>
					</Grid>
				))}
			</Grid>
		</Box>
	);
};

export default ArrowStepper;
