import React from "react";
import { Cliente } from "../../anagrafiche/clienti/clientiSlice";

import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

interface SelectClienteProps {
	clienteCorrente: Cliente | null;
	committenti: Cliente[];
	setClienteCorrente: any;
}
export const SelectCliente = ({
	clienteCorrente,
	committenti,
	setClienteCorrente,
}: SelectClienteProps) => {
	const handlerCahnge = (event: SelectChangeEvent) => {
		const newCliente = committenti.find(
			(cliente) => cliente.id?.toString() == event.target.value
		);
		setClienteCorrente(newCliente);
	};
	return (
		<Select
			key="selectClienteCorrente"
			required={true}
			id="selectClienteCorrente"
			label="Cliente"
			// disabled={disabled}
			// notched={"true"}
			// defaultValue={clienteCorrente?.id||0}
			value={clienteCorrente?.id?.toString() || "0"}
			variant="outlined"
			sx={{ width: "100%" }}
			onChange={handlerCahnge}
		>
			{committenti.map((option) => {
				return (
					<MenuItem key={option.id} value={option.id}>
						{option.ragione_sociale}
					</MenuItem>
				);
			})}
		</Select>
	);
};

interface SelectPeriodoProps {
	periodo: string;
	setPeriodo: any;
	periodi: { valore: string; periodo: string }[];
}
export const SelectPeriodo = ({
	periodo,
	setPeriodo,
	periodi,
}: SelectPeriodoProps) => {
	const handlerCahnge = (event: SelectChangeEvent) => {
		setPeriodo(event.target.value);
	};

	return (
		<Select
			key="periodo"
			required={true}
			id="periodo"
			label="periodo"
			// disabled={disabled}
			// notched={"true"}
			// defaultValue={clienteCorrente?.id||0}
			value={periodo}
			variant="outlined"
			sx={{ width: "100%" }}
			onChange={handlerCahnge}
		>
			{periodi.map((periodo) => (
				<MenuItem key={periodo.valore} value={periodo.valore}>
					{periodo.periodo}
				</MenuItem>
			))}
		</Select>
	);
};
