import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";

import { useLocation, Link, useNavigate } from "react-router-dom";
import { Routes_path } from "../../../../routerApp";

import { useAppDispatch } from "../../../../../redux/store";

import ConfermaAnnulla from "../../../../common/ConfermaAnnulla";
import { DateTime } from "luxon";
import { convertToDateTime } from "components/common/dateUtils/convertToDateTime";


import { Commessa, getCommessa, saveCommessa } from "../../commesseSlice";
import {
	Sopralluogo,
	SopralluoghiState,
	StatoSopralluogoTypes,
} from "components/main/sopralluoghi/sopralluoghiSlice";
import { VerificaVincoli } from "components/main/verificheVincoli/verificheVincoliSlice";
import { Cliente } from "../../../../anagrafiche/clienti/clientiSlice";

import Grid from "@mui/material/Grid";
import { Box, Divider, Typography } from "@mui/material";
import { theme } from "../../../../theme";
import IconButton from "@mui/material/IconButton";

interface SopralluoghiCommessaGridProps {
	commessa: Commessa;
}

const SopralluoghiCommessaGrid = ({
	commessa,
}: SopralluoghiCommessaGridProps) => {
	

	const sopralluoghi = useSelector<RootState, Sopralluogo[]>(
		(state) => state.sopralluoghiState.sopralluoghi.results
	);

	const verificheVincoli = useSelector<RootState, VerificaVincoli[]>(
		(state) => state.verificheVincoliState.verificheVincoli.results
	);

	const clienti = useSelector<RootState, Cliente[]>(
		(state) => state.clientiState.clienti.results
	);

	return (
		<>
			{sopralluoghi.length > 0 && (
				<Box>
					<Divider
						textAlign="left"
						sx={{ my: 2, color: theme.palette.primary.main }}
					>
						Sopralluoghi
					</Divider>

					<Grid container spacing={0.5}>
						<Grid item xs={12} sm={12} md={12} lg={2}>
							<Typography
								variant="subtitle1"
								// color="text.secondary"
								gutterBottom
								// onClick={() => setEditing(true)}
								sx={{
									color: theme.palette.text.secondary,
								}}
							>
								Sopralluogo
							</Typography>
						</Grid>
						<Grid item xs={12} sm={12} md={12} lg={2}>
							<Typography
								variant="subtitle1"
								// color="text.secondary"
								gutterBottom
								// onClick={() => setEditing(true)}
								sx={{
									color: theme.palette.text.secondary,
								}}
							>
								Eseguito da
							</Typography>
						</Grid>
						<Grid item xs={12} sm={12} md={12} lg={2}>
							<Typography
								variant="subtitle1"
								// color="text.secondary"
								gutterBottom
								// onClick={() => setEditing(true)}
								sx={{
									color: theme.palette.text.secondary,
								}}
							>
								Iter Connessione
							</Typography>
						</Grid>
						<Grid item xs={12} sm={12} md={12} lg={3}>
							<Typography
								variant="subtitle1"
								// color="text.secondary"
								gutterBottom
								// onClick={() => setEditing(true)}
								sx={{
									color: theme.palette.text.secondary,
								}}
							>
								Stato
							</Typography>
						</Grid>
						<Grid item xs={12} sm={12} md={12} lg={3}>
							<Typography
								variant="subtitle1"
								// color="text.secondary"
								gutterBottom
								// onClick={() => setEditing(true)}
								sx={{
									color: theme.palette.text.secondary,
								}}
							>
								Data Sopralluogo
							</Typography>
						</Grid>
					</Grid>
					{sopralluoghi
						.filter((sopralluogo) => sopralluogo.commessa == commessa.id)
						.map((sopralluogo: Sopralluogo, index) => (
							<Grid
								container
								spacing={0.5}
								key={index}
								sx={{
									backgroundColor: index % 2 === 0 ? "#eeeeee" : "inherit",
								}}
							>
								<Grid item xs={12} sm={12} md={12} lg={2}>
									<IconButton
										key={sopralluogo.id}
										aria-label="vai a sopralluogo"
										size="small"
										component={Link}
										to={{
											pathname: Routes_path.SOPRALLUOGO + sopralluogo.id,
										}}
										// state={{
										// 	lavorazine_id: params.value,
										// }}
									>
										<Typography
											variant="body1"

											// color="text.secondary"
											// onClick={() => setEditing(true)}
										>
											{sopralluogo.id}
										</Typography>
									</IconButton>
								</Grid>
								<Grid item xs={12} sm={12} md={12} lg={2}>
									<Typography
										variant="body1"
										// sx={{ fontSize: "0.8rem" }}
										// color="text.secondary"
										// onClick={() => setEditing(true)}
									>
										{sopralluogo.servizio_i43
											? "I43"
											: clienti.find((cliente) => cliente.id == sopralluogo.cliente)?.ragione_sociale}
									</Typography>
								</Grid>
								<Grid item xs={12} sm={12} md={12} lg={2}>
									<Typography
										variant="body1"
										// sx={{ fontSize: "0.8rem" }}
										// color="text.secondary"
										// onClick={() => setEditing(true)}
									>
										{sopralluogo.iter_connessione || ""}
									</Typography>
								</Grid>
								<Grid item xs={12} sm={12} md={12} lg={3}>
									<Typography
										variant="body1"
										// color="text.secondary"
										// onClick={() => setEditing(true)}
									>
										{
											Object.entries(StatoSopralluogoTypes).find(
												([key, value]) => key == sopralluogo.stato
											)?.[1]
										}
									</Typography>
								</Grid>
								<Grid item xs={12} sm={12} md={12} lg={3}>
									<Typography
										variant="body1"
										// color="text.secondary"
										// onClick={() => setEditing(true)}
									>
										{sopralluogo.data_sopralluogo
											? convertToDateTime(sopralluogo.data_sopralluogo)?.setLocale("it").toFormat("dd/MM/yyyy")
											: ""}
									</Typography>
								</Grid>
							</Grid>
						))}
				</Box>
			)}
		</>
	);
};
export default SopralluoghiCommessaGrid;
