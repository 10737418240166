import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import {
	Soggetto,
	saveProduttore,
} from "../../../../anagrafiche/produttori/produttoriSlice";
import { useAppDispatch, RootState } from "../../../../../redux/store";
import { useNavigate } from "react-router-dom";
import { DateTime } from "luxon";


import { useForm, SubmitHandler, Controller } from "react-hook-form";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Comune } from "../../../../anagrafiche/comuni/comuniSlice";
import {
	IterConnessione,
	saveIterConnessione,
	resetNuovoIterConnessioneId,
} from "../../iterConnessioneSlice";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import ButtonGroup from "@mui/material/ButtonGroup";
import MenuItem from "@mui/material/MenuItem";
import { Routes_path } from "../../../../routerApp";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";

import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

import Alert from "../../../../common/Alert";
import Typography from "@mui/material/Typography";

import { Button, Divider } from "@mui/material";
import { theme } from "../../../../theme";

interface ProduttoreCredenzialiGSEFormProps {
	produttore: Soggetto;
}

const ProduttoreCredenzialiGSEForm = ({
	produttore,
}: ProduttoreCredenzialiGSEFormProps) => {
	const {
		handleSubmit,
		register,
		formState: { errors, isDirty, isValid },
		setValue,
		watch,
		getValues,
		control,
		setError,
		clearErrors,
		reset,
	} = useForm<Soggetto>();

	const listaCampi = Object.keys(produttore) as Array<keyof Soggetto>;

	const dispatch = useAppDispatch();

	const navigate = useNavigate();
	const [pesonaFisica, setPesonaFisica] = useState<boolean>(true);

	const comuni = useSelector<RootState, Comune[]>(
		(state) => state.comuniState.comuni.results
	);

	const [edit, setEdit] = useState<boolean>(false);
	const [ripristina, setRipristina] = useState<boolean>(false);
	const [comuneCorrente, setComuneCorrente] = useState<Comune | undefined>(
		undefined
	);

	const [comuneNascitaCorrente, setComuneNascitaCorrente] = useState<
		Comune | undefined
	>(undefined);

	const nuovoIterConnessioneId = useSelector<RootState, number | undefined>(
		(state) => state.iterConnessioniState.nuovoIterConnessioneId
	);
	const [ampliamento, setAmpliamento] = useState<boolean | undefined>(
		undefined
	);
	const [altroPod, setAltroPod] = useState<boolean | undefined>(undefined);
	useEffect(() => {
		nuovoIterConnessioneId &&
			navigate(Routes_path.ITER_CONNESSIONE + nuovoIterConnessioneId);
		dispatch(resetNuovoIterConnessioneId());
	}, [nuovoIterConnessioneId]);

	useEffect(() => {
		reset();
		listaCampi.map((field) => {
			setValue(field, produttore[field]);
		});
		setRipristina(false);
	}, [
		ripristina,
		produttore,
		// pesonaFisica,
		// listaCampi.forEach((field) => {
		// 	produttore[field];
		// }),
		// produttore.id,
	]);

	useEffect(() => {
		setPesonaFisica(getValues("tipo_persona") == "PF");
	}, [getValues("tipo_persona")]);

	useEffect(() => {
		setComuneCorrente(
			comuni.find((comuneScelto) => comuneScelto.id == getValues("comune"))
		);
	}, [comuni, getValues("comune"), pesonaFisica]);

	useEffect(() => {
		setComuneNascitaCorrente(
			comuni.find(
				(comuneScelto) => comuneScelto.id == getValues("comune_nascita")
			)
		);
	}, [comuni, watch("comune_nascita"), pesonaFisica]);

	useEffect(() => {
		clearErrors();
		listaCampi.forEach((field) => {
			produttore?.errorsStack?.fieldsErrors &&
				produttore.errorsStack.fieldsErrors[field] &&
				setError(field, {
					type: "server",
					message: produttore.errorsStack.fieldsErrors[field].toString(),
				});
		});
	}, [
		produttore?.errorsStack?.fieldsErrors,
		// produttore.allevamento_pack?.errorsStack?.fieldsErrors,
	]);

	const handlerSaveProduttore = (produttore: Soggetto) => {
		dispatch(saveProduttore({ produttoreToSave: produttore }));
	};

	const handlerCreateIterConnessione = (semplificato: boolean) => {
		const iterConnessione: IterConnessione = {
			super_bonus: false,
			iter_semplificato: semplificato,
			produttore: produttore.id || 0,
			produttore_descrizione: "",
			// indirizzo: produttore.indirizzo,
			// numero_civico: produttore.numero_civico,
			// cap: produttore.cap,
			// comune: produttore.comune || undefined,
			stato: "A",
			ampliamento: ampliamento,
			altro_pod: altroPod,
		};
		// handleSubmit(onSubmit);
		dispatch(saveProduttore({ produttoreToSave: { ...getValues() } }));

		dispatch(
			saveIterConnessione({
				iterConnessioneToSave: iterConnessione,
			})
		);
	};

	const onSubmit: SubmitHandler<Soggetto> = (produttore) => {
		handlerSaveProduttore(produttore);
		setEdit(false);
	};

	return (
		<Box sx={{ my: 1, pt: 2 }}>
			<form onSubmit={handleSubmit(onSubmit)}>
				{produttore?.errorsStack?.fieldsErrors?.non_field_errors && (
					<Alert severity="error">
						{produttore?.errorsStack?.fieldsErrors?.non_field_errors.toString()}
					</Alert>
				)}

				<Grid container spacing={2} sx={{ mb: 2 }}>
					<Grid item xs={12} sm={12} md={5} lg={12}>
						<Divider
							textAlign="left"
							sx={{ mb: 2, color: theme.palette.primary.main }}
						>
							Credenziali portale GSE{" "}
							<IconButton aria-label="modifica" onClick={() => setEdit(true)}>
								<EditIcon />
							</IconButton>
						</Divider>
						<Grid container spacing={2} sx={{ mb: 2 }}>
							<Grid item xs={12} sm={6} md={6} lg={6}>
								<TextField
									label="gse id"
									disabled={!edit}
									variant="outlined"
									InputLabelProps={{
										shrink: true,
									}}
									{...register("gse_id")}
									required
									error={!!errors?.gse_id}
									helperText={errors?.gse_id?.message}
									autoComplete={"off"}
									sx={{
										width: "100%",
										"& input": {
											fontWeight: "bold",
											backgroundColor: "floralwhite",
										},
									}}
									fullWidth
									// onBlur={handlerDetailChaingSubmit}
								/>
							</Grid>
							<Grid item xs={12} sm={6} md={6} lg={6}>
								<TextField
									label="gse psw"
									disabled={!edit}
									variant="outlined"
									InputLabelProps={{
										shrink: true,
									}}
									{...register("gse_psw")}
									required
									error={!!errors?.gse_psw}
									helperText={errors?.gse_psw?.message}
									autoComplete={"off"}
									sx={{
										width: "100%",
										"& input": {
											fontWeight: "bold",
											backgroundColor: "floralwhite",
										},
									}}
									fullWidth
									// onBlur={handlerDetailChaingSubmit}
								/>
							</Grid>
						</Grid>
					</Grid>

					<Grid item xs={12} sm={12} md={6} lg={12}>
						<Divider
							textAlign="left"
							sx={{ mb: 2, color: theme.palette.primary.main }}
						>
							Credenziali Gestore di Rete
						</Divider>
						<Grid container spacing={2} sx={{ mb: 2 }}>
							<Grid item xs={12} sm={6} md={6} lg={6}>
								<TextField
									label="gestore di rete id"
									disabled={!edit}
									variant="outlined"
									InputLabelProps={{
										shrink: true,
									}}
									InputProps={{}}
									{...register("gestore_rete_id")}
									error={!!errors?.gestore_rete_id}
									helperText={errors?.gestore_rete_id?.message}
									autoComplete={"off"}
									sx={{
										width: "100%",
										"& input": {
											fontWeight: "bold",
											backgroundColor: "aliceblue",
										},
									}}
									fullWidth

									// onBlur={handlerDetailChaingSubmit}
								/>
							</Grid>
							<Grid item xs={12} sm={6} md={6} lg={6}>
								<TextField
									label="gestore direte psw"
									disabled={!edit}
									variant="outlined"
									InputLabelProps={{
										shrink: true,
									}}
									{...register("gestore_rete_psw")}
									error={!!errors?.gestore_rete_psw}
									helperText={errors?.gestore_rete_psw?.message}
									autoComplete={"off"}
									sx={{
										width: "100%",
										"& input": {
											fontWeight: "bold",
											backgroundColor: "aliceblue",
										},
									}}
									fullWidth
									// onBlur={handlerDetailChaingSubmit}
								/>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={12} sm={12} md={6} lg={12}>
						<Divider
							textAlign="left"
							sx={{ mb: 2, color: theme.palette.primary.main }}
						>
							Credenziali portale Gaudì{" "}
						</Divider>
						<Grid container spacing={2} sx={{ mb: 2 }}>
							<Grid item xs={12} sm={6} md={6} lg={6}>
								<TextField
									label="gaudi id"
									disabled={!edit}
									variant="outlined"
									InputLabelProps={{
										shrink: true,
									}}
									InputProps={{}}
									{...register("gaudi_id")}
									error={!!errors?.gaudi_id}
									helperText={errors?.gaudi_id?.message}
									autoComplete={"off"}
									sx={{
										width: "100%",
										"& input": {
											fontWeight: "bold",
											backgroundColor: "aliceblue",
										},
									}}
									fullWidth

									// onBlur={handlerDetailChaingSubmit}
								/>
							</Grid>
							<Grid item xs={12} sm={6} md={6} lg={6}>
								<TextField
									label="gaudi psw"
									disabled={!edit}
									variant="outlined"
									InputLabelProps={{
										shrink: true,
									}}
									{...register("gaudi_psw")}
									error={!!errors?.gaudi_psw}
									helperText={errors?.gaudi_psw?.message}
									autoComplete={"off"}
									sx={{
										width: "100%",
										"& input": {
											fontWeight: "bold",
											backgroundColor: "aliceblue",
										},
									}}
									fullWidth
									// onBlur={handlerDetailChaingSubmit}
								/>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={12} sm={12} md={6} lg={2}>
						{edit && (
							<ButtonGroup
								variant="outlined"
								aria-label="outlined button group"
							>
								<Button
									disabled={!isDirty}
									variant="outlined"
									type="submit"
									// sx={{ display: "none" }}
								>
									Salva
								</Button>

								<Button
									disabled={!isDirty}
									variant="outlined"
									onClick={() => {
										setRipristina(true);
										setEdit(false);
									}}
									// sx={{ display: "none" }}
								>
									Annulla
								</Button>
							</ButtonGroup>
						)}
					</Grid>
				</Grid>
			</form>
		</Box>
	);
};

export default ProduttoreCredenzialiGSEForm;
