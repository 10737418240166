import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import {
	IterConnessione,
	TipoTensioneTypes,
	saveIterConnessione,
} from "../../iterConnessioneSlice";
import { useAppDispatch, RootState } from "../../../../../redux/store";
import {
	ErrorStatusTypes,
	ErrorsStack,
} from "../../../../common/errorsDeclarations";

import { theme } from "../../../../theme";

import { utenteAutorizzato } from "../../../../authentication/UtenteAutorizzato";
import { UtenteLogged } from "../../../../authentication/authenticationSlice";
import { useForm, SubmitHandler, Controller } from "react-hook-form";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Alert from "../../../../common/Alert";
import Typography from "@mui/material/Typography";
import Autocomplete from "@mui/material/Autocomplete";
import ButtonGroup from "@mui/material/ButtonGroup";

import { Button, Divider } from "@mui/material";
import { Soggetto } from "components/anagrafiche/produttori/produttoriSlice";

interface IterConnessioneNoteInterneFormProps {
	iterConnessione: IterConnessione;
}

const IterConnessioneNoteInterneForm = ({
	iterConnessione,
}: IterConnessioneNoteInterneFormProps) => {
	const {
		handleSubmit,
		register,
		formState: { errors, isDirty, isValid },
		setValue,
		watch,
		getValues,
		control,
		setError,
		clearErrors,
		reset,
	} = useForm<IterConnessione>();

	const listaCampi = Object.keys(iterConnessione) as Array<
		keyof IterConnessione
	>;

	const dispatch = useAppDispatch();

	const [editing, setEditing] = useState(!iterConnessione.pod_commessa?.id);
	// const [editing, setEditing] = useState(false);

	const utente = useSelector<RootState, UtenteLogged>(
		(state) => state.authentication.utenteLogged
	);

	useEffect(() => {
		reset();
		listaCampi.map((field) => {
			setValue(field, iterConnessione[field]);
		});
	}, [iterConnessione.id]);

	useEffect(() => {
		clearErrors();
		listaCampi.forEach((field) => {
			iterConnessione?.errorsStack?.fieldsErrors &&
				iterConnessione.errorsStack.fieldsErrors[field] &&
				setError(field, {
					type: "server",
					message: iterConnessione.errorsStack.fieldsErrors[field].toString(),
				});
		});
	}, [
		iterConnessione?.errorsStack?.fieldsErrors,
		// iterConnessione.allevamento_pack?.errorsStack?.fieldsErrors,
	]);

	const onSubmit: SubmitHandler<IterConnessione> = (iterConnessione) => {
		dispatch(
			saveIterConnessione({
				iterConnessioneToSave: iterConnessione,
				richiedeIntegrazioni: true,
			})
		);
		setEditing(false);
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			{iterConnessione?.errorsStack?.fieldsErrors?.non_field_errors && (
				<Alert severity="error">
					{iterConnessione?.errorsStack?.fieldsErrors?.non_field_errors.toString()}
				</Alert>
			)}
			{iterConnessione.stato == "C" ? (
				<Grid container spacing={0.5}>
					<Grid item xs={12} sm={12} md={12} lg={12}>
						{!editing ? (
							<Button
								color="secondary"
								variant="contained"
								size="large"
								sx={{ width: "100%" }}
								onClick={() => setEditing(true)}
							>
								chiedi integrazioni
							</Button>
						) : (
							// <Typography
							// 	variant="h6"
							// 	component="div"
							// 	// color="text.secondary"
							// 	gutterBottom
							// 	onClick={() => setEditing(true)}
							// 	sx={{
							// 		color: theme.palette.text.secondary,
							// 	}}
							// >
							// 	{iterConnessione.note_non_ricevibile ||
							// 		"clicca x chiedere integrazioni"}
							// </Typography>
							<TextField
								// label="note_non_ricevibile"
								// disabled={schedaCarico.numero_capi_pianificati}
								variant="outlined"
								InputLabelProps={{
									shrink: true,
								}}
								{...register("note_non_ricevibile")}
								rows={5}
								multiline={true}
								error={!!errors?.note_non_ricevibile}
								helperText={errors?.note_non_ricevibile?.message}
								autoComplete={"off"}
								sx={{
									width: "100%",
									textarea: {
										resize: "both",
									},
								}}
								// multiline={true}
								// rows={3}
								fullWidth
								// onBlur={handlerDetailChaingSubmit}
							/>
						)}
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={12}>
						<ButtonGroup
							size="small"
							aria-label="ricevi o non ricevi"
							sx={{ width: "100%" }}
						>
							<Button
								// disabled={!isDirty}
								variant="outlined"
								onClick={() => {
									setEditing(false);
									// reset();
								}}
								sx={{ ...(!editing && { display: "none" }) }}
							>
								Annulla
							</Button>
							<Button
								disabled={!isDirty}
								variant="outlined"
								type="submit"
								sx={{ ...(!editing && { display: "none" }) }}
							>
								Avverti cliente
							</Button>
						</ButtonGroup>
					</Grid>
				</Grid>
			) : (
				iterConnessione.note_non_ricevibile && (
					<Alert severity="info">
						Integrazioni richieste: {iterConnessione.note_non_ricevibile}
					</Alert>
				)
			)}
		</form>
	);
};

export default IterConnessioneNoteInterneForm;
