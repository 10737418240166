import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {useAppDispatch, RootState} from "../../../../../../redux/store";
import {
    ErrorStatusTypes,
    ErrorsStack,
} from "../../../../../common/errorsDeclarations";

import {
    UtenteLogged,
    UtenteApplicativo,
} from "../../../../../authentication/authenticationSlice";
import {
    Commessa,
    saveCommessa,
    StatoCommessaTypes,
    TipoIntervento,
} from "../../../commesseSlice";

import ReactHookFormSelect from "../../../../../common/ReactHookFormSelect";
import {
    Checkbox,
    CircularProgress,
    FormControlLabel,
    FormHelperText,
} from "@mui/material";
import {useForm, SubmitHandler, Controller} from "react-hook-form";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import {Card, CardHeader, CardContent, Paper} from "@mui/material";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import {Button, Divider} from "@mui/material";
import {DesktopDatePicker} from "@mui/x-date-pickers/DesktopDatePicker";
import {Alert} from "@mui/material";
import CheckIcon from '@mui/icons-material/Check';
import Switch from "@mui/material/Switch";
import {IterConnessione} from "../../../../iterConnessioni/iterConnessioneSlice";

interface ConsegnaDocumentiFormProps {
    commessa: Commessa;
    consentiModifica: boolean;
}

const ConsegnaDocumentiForm = ({
                                   commessa,
                                   consentiModifica = false,
                               }: ConsegnaDocumentiFormProps) => {
    const {
        handleSubmit,
        register,
        formState: {errors, isDirty, isValid},
        setValue,
        watch,
        getValues,
        control,
        setError,
        clearErrors,
        reset,
    } = useForm<Commessa>();

    const listaCampi = Object.keys(commessa) as Array<keyof Commessa>;

    const dispatch = useAppDispatch();

    const [editing, setEditingControlled] = useState(false);
    const setEditing = (switchModifica: boolean) => {
        consentiModifica && setEditingControlled(switchModifica);
    };

    const utente = useSelector<RootState, UtenteLogged>(
        (state) => state.authentication.utenteLogged
    );

    const commesseStatus = useSelector<RootState, ErrorStatusTypes>(
        (state) => state.commesseState.commesse.errorsStack.status
    );

    const utenteApplicativo = useSelector<
        RootState,
        UtenteApplicativo | undefined
    >((state) =>
        state.authentication.utentiApplicativo.results.find(
            (u) => u.id == utente.id
        )
    );
    const utentiApplicativo = useSelector<RootState, UtenteApplicativo[]>(
        (state) =>
            state.authentication.utentiApplicativo.results.filter(
                (u) =>
                    u.cliente == utenteApplicativo?.cliente || utenteApplicativo?.interno
            )
    );

    const tipiIntervento = useSelector<RootState, TipoIntervento[]>(
        (state) => state.commesseState.tipiIntervento.results
    );

    const iter_connessioni = useSelector<RootState, IterConnessione[]>(
        (state) => state.iterConnessioniState.iterConnessioni.results
    );

    const iterConnessione = iter_connessioni.find(
        (iterConnessione) => iterConnessione.id == commessa.iterConnessione
    );

    const [annulla, setAnnulla] = useState<boolean>(false);

    useEffect(() => {
        clearErrors();
        listaCampi.forEach((field) => {
            commessa?.errorsStack?.fieldsErrors &&
            commessa.errorsStack.fieldsErrors[field] &&
            setError(field, {
                type: "server",
                message: commessa.errorsStack.fieldsErrors[field].toString(),
            });
        });
    }, [
        commessa?.errorsStack?.fieldsErrors,
        // cliente.allevamento_pack?.errorsStack?.fieldsErrors,
    ]);

    const handlerSaveCommessa = (commessa: Commessa) => {
        consentiModifica &&
        dispatch(
            saveCommessa({
                commessaToSave: commessa,
            })
        );
    };
    const [documentiConsegnati, setdocumentiConsegnati] = useState<boolean>(false);

    useEffect(() => {
        setdocumentiConsegnati(["M"].includes(commessa?.stato || ""))
    }, [commessa.stato
    ]);

    return (
        <Box sx={{mb: 1}}>
            {commessa?.errorsStack?.fieldsErrors?.non_field_errors && (
                <Alert severity="error">
                    {commessa?.errorsStack?.fieldsErrors?.non_field_errors.toString()}
                </Alert>
            )}
            {!!iterConnessione?.id &&
                <Box>
                    {iterConnessione?.stato == 'N' ?

                        <Alert icon={<CheckIcon fontSize="inherit"/>} severity="success" sx={{my: 3}}>
                            L'iter connessione è conclusa puoi, procedere con la consegna dei documenti.
                        </Alert>
                        :
                        <Alert variant="filled" severity="error" sx={{my: 3}}>
                            L'iter connessione NON è conclusa puoi, NON procedere con la consegna dei documenti.
                        </Alert>
                    }
                </Box>
            }


            <Grid container spacing={2} width={"100%"} sx={{mb: 1}}>
                <Grid item lg={4}>


                    <Typography variant="h6">I documenti sono stati consegnati al cliente?</Typography>
                    <FormControlLabel
                        label={
                            <Typography fontSize="0.8rem" marginLeft="0.1rem">
                                {documentiConsegnati ? "SI" : "NO"}
                            </Typography>
                        }
                        control={
                            <Switch
                                disabled={!consentiModifica}
                                id="edit_sede_codice_pod"
                                checked={documentiConsegnati || false}
                                onChange={(e, checked) => {
                                    setdocumentiConsegnati(checked);
                                }}
                                color="info"
                                size="small"
                            />
                        }
                    />
                    {!!errors?.stampa_ddt && (
                        <FormHelperText error>
                            {errors?.stampa_ddt?.toString()}
                        </FormHelperText>
                    )}
                </Grid>

                <Grid item sm={12} lg={2}>
                    {commessa.stato == "L" &&
                        <Button
                            variant="contained"
                            color="primary"
                            disabled={!consentiModifica || !documentiConsegnati || !commessa.stato_completato}
                            sx={{width: "100%"}}
                            onClick={() => {
                                dispatch(saveCommessa({
                                    commessaToSave: commessa,
                                    azione: "avvia_documenti_consegnati"
                                }))
                            }}
                        >
                            {'Segna come "documenti consegnati"'}
                        </Button>
                    }
                </Grid>
            </Grid>
        </Box>
    );
};

export default ConsegnaDocumentiForm;
