import { handleResponse, handleError, headerset } from "../apiUtils";
import { DateTime } from "luxon";
import { convertToDateTime } from "components/common/dateUtils/convertToDateTime";


import { Allaccio } from "../../components/main/iterConnessioni/iterConnessione/richiesteConnessione/allaccio/allaccioSlice";

const baseUrl = (allacci_id?: number | null, azione?: string) => {
	if (allacci_id) {
		let url = `${process.env.API_URL}/api/allaccio/${allacci_id}/`;
		if (!!azione) {
			url = url + `?azione=${azione}`;
		}
		return url;
	} else {
		return process.env.API_URL + "/api/allaccio/";
	}
};

export function fetchAllacci() {
	let url = baseUrl();
	// data_da.toFormat('yyyy-MM-dd');
	return fetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}

export function getAllaccio(allacciId: number) {
	let url = baseUrl(allacciId);
	return fetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}

export function saveAllaccio(allaccioToSave: Allaccio, azione?: string) {
	const alaccio_data = allaccioToSave.alaccio_data
		? convertToDateTime(allaccioToSave.alaccio_data)?.setLocale("it").toFormat("yyyy-MM-dd")
		: null;
	const data_ultima_taratura_spi = allaccioToSave.data_ultima_taratura_spi
		? convertToDateTime(allaccioToSave.data_ultima_taratura_spi)?.setLocale("it").toFormat("yyyy-MM-dd")
		: null;
	const data_ultima_taratura_contatori =
		allaccioToSave.data_ultima_taratura_contatori
			? convertToDateTime(allaccioToSave.data_ultima_taratura_contatori)?.setLocale("it").toFormat("yyyy-MM-dd") : null;
	const gse_data_registrazione_produttore =
		allaccioToSave.gse_data_registrazione_produttore
			? convertToDateTime(allaccioToSave.gse_data_registrazione_produttore)?.setLocale("it").toFormat("yyyy-MM-dd") : null;
	const gse_data_richiesta_contratto =
		allaccioToSave.gse_data_richiesta_contratto ? convertToDateTime(allaccioToSave.gse_data_richiesta_contratto)?.setLocale("it").toFormat("yyyy-MM-dd") : null;
	const gse_data_accettazione_contratto =
		allaccioToSave.gse_data_accettazione_contratto
			? convertToDateTime(allaccioToSave.gse_data_accettazione_contratto)?.setLocale("it").toFormat("yyyy-MM-dd") : null;
	const gse_data_attivazione_contratto =
		allaccioToSave.gse_data_attivazione_contratto
			? convertToDateTime(allaccioToSave.gse_data_attivazione_contratto)?.setLocale("it").toFormat("yyyy-MM-dd") : null;
	const siad_data_comunicazione_modifica =
		allaccioToSave.siad_data_comunicazione_modifica
			? convertToDateTime(allaccioToSave.siad_data_comunicazione_modifica)?.setLocale("it").toFormat("yyyy-MM-dd")
			: null;
	const siad_data_modifica_accettazione_GSE =
		allaccioToSave.siad_data_modifica_accettazione_GSE
			? convertToDateTime(allaccioToSave.siad_data_modifica_accettazione_GSE)?.setLocale("it").toFormat("yyyy-MM-dd") : null;
	const allacci = {
		...allaccioToSave,
		alaccio_data: alaccio_data,
		data_ultima_taratura_spi: data_ultima_taratura_spi,
		data_ultima_taratura_contatori: data_ultima_taratura_contatori,
		gse_data_registrazione_produttore: gse_data_registrazione_produttore,
		gse_data_richiesta_contratto: gse_data_richiesta_contratto,
		gse_data_accettazione_contratto: gse_data_accettazione_contratto,
		gse_data_attivazione_contratto: gse_data_attivazione_contratto,
		siad_data_comunicazione_modifica: siad_data_comunicazione_modifica,
		siad_data_modifica_accettazione_GSE: siad_data_modifica_accettazione_GSE,
	};
	return fetch(baseUrl(allaccioToSave.id, azione), {
		method: allaccioToSave.id ? "PUT" : "POST", // PUT to update when id already exists.
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
		body: JSON.stringify({
			...allacci,
			// Parse authorId to a number (in case it was sent as a string).
			// id: parseInt(allacci.id, 10),
		}),
	})
		.then(handleResponse)
		.catch(handleError);
}

export function deleteAllaccio(allacciToDelete: Allaccio) {
	return fetch(baseUrl(allacciToDelete.id), {
		method: "DELETE",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}

////////////////////////////////////////

export function getAllaccioFromIter(iterId: number) {
	let url =
		process.env.API_URL + "/api/allaccio/allaccio_from_iter/" + iterId + "/";
	return fetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}

/////////////////////
