import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {useLocation, useParams, useNavigate, Link} from "react-router-dom";

import {RootState, useAppDispatch} from "../../../../redux/store";
import {Routes_path} from "components/routerApp";
import {
    ErrorStatusTypes,
    ErrorsStack,
} from "components/common/errorsDeclarations";

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import IterConnessioniProduttoreGrid from "components/anagrafiche/produttori/IterConnessioniProduttoreGrid";
import AdempimentiProduttoreGrid from "components/anagrafiche/produttori/AdempimentiProduttoreGrid";
import PodsProduttoreGrid from "components/anagrafiche/produttori/PodsProduttoreGrid";

import ArrowStepper from "components/common/ArrowStepper";
import {
    testoBottoneProseguimento,
    passiInizioPratica,
} from "components/anagrafiche/produttori/testoBottoneProseguimento";
import {utenteAutorizzato} from "components/authentication/UtenteAutorizzato";
import {PermissionTypes} from "components/authentication/permissionsGroups";

import {UtenteLogged} from "components/authentication/authenticationSlice";
import {Soggetto, getProduttore, saveProduttore,} from "components/anagrafiche/produttori/produttoriSlice";
import ProduttoreForm from "components/anagrafiche/produttori/ProduttoreForm";
import NuovoProduttoreForm from "components/anagrafiche/produttori/NuovoProduttoreForm";
import Typography from "@mui/material/Typography";
import {Box} from "@mui/system";
import {Button, Divider, Grid} from "@mui/material";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";

import {ServizioTypes} from "components/anagrafiche/kpi/kpiSlice";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import UtentiProduttoreList from "components/anagrafiche/produttori/UtentiProduttoreList";
import Alert from "../../../common/Alert";
import {convertToDateTime} from "../../../common/dateUtils/convertToDateTime";
import {theme} from "../../../theme";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import {Comune} from "../../../anagrafiche/comuni/comuniSlice";
import AccessoProduttorePageBreadcrumbs from "./AccessoProduttorePageBreadcrumbs";

const AccessoProduttorePage = () => {

    const dispatch = useAppDispatch();

    const navigate = useNavigate();
    const utente = useSelector<RootState, UtenteLogged>(
        (state) => state.authentication.utenteLogged
    );

    const [comuneNascitaCorrente, setComuneNascitaCorrente] = useState<
        Comune | undefined
    >(undefined);

    const [comuneCorrente, setComuneCorrente] = useState<Comune | undefined>(
        undefined
    );


    useEffect(() => {
        utente.produttore && dispatch(getProduttore(utente.produttore));
    }, []);

    const produttore: Soggetto | undefined = useSelector<
        RootState,
        Soggetto | undefined
    >((state) =>
        state.produttoriState.produttori.results.find(
            (produttore) => produttore?.id == utente.produttore
        )
    );
    const errorsStack: ErrorsStack = useSelector<RootState, ErrorsStack>(
        (state) => state.produttoriState.produttori.errorsStack
    );

    return (
        <>
            <Box
                sx={{
                    backgroundColor: "#fff",
                    elevation: 20,
                    p: 2,
                }}
            >
                <AccessoProduttorePageBreadcrumbs/>
                <Backdrop
                    sx={{
                        color: "#fff",
                        zIndex: (theme) => theme.zIndex.drawer + 1,
                    }}
                    open={errorsStack.status == ErrorStatusTypes.PENDING}
                >
                    <CircularProgress color="inherit"/>
                </Backdrop>

                <Stack
                    direction="row"
                    justifyContent="space-between"
                    spacing={4}
                    sx={{mb: 1}}
                >
                    <Stack
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        spacing={4}
                        sx={{mb: 1}}
                    >
                        <Typography variant="h3" component="div" color="text.secondary">
                            {produttore?.id}
                        </Typography>
                        <Typography variant="h3" component="div" color="text.secondary">
                            {produttore?.tipo_persona == "PF"
                                ? `${produttore?.nome} ${produttore?.cognome}`
                                : produttore?.ragione_sociale}
                        </Typography>
                    </Stack>

                </Stack>

                <Divider sx={{mb: 1}}/>

                <Box sx={{mb: 2}}>
                    {
                        produttore ? (
                            <Paper elevation={3} sx={{p: 3}}>
                                {produttore.tipo_persona == "PF" ? (
                                    <Grid container spacing={2} sx={{mb: 2}}>
                                        <Grid item xs={12} sm={6} md={6} lg={2}>
                                            <Typography variant="caption">nome:</Typography>
                                            <Typography>{produttore.nome}</Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={6} lg={2}>
                                            <Typography variant="caption">cognome:</Typography>

                                            <Typography>{produttore.cognome}</Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={6} lg={2}>
                                            <Typography variant="caption">codice fiscale:</Typography>

                                            <Typography>{produttore.codice_fiscale}</Typography>
                                        </Grid>

                                        <Grid item xs={12} sm={6} md={6} lg={2}>
                                            <Typography variant="caption">comune nascita:</Typography>

                                            <Typography>{comuneNascitaCorrente?.nome}</Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={6} lg={2}>
                                            <Typography variant="caption">nazione nascita:</Typography>
                                            <Typography>{produttore.nazione_nascita}</Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={6} lg={2}>
                                            <Typography variant="caption">data nascita:</Typography>

                                            <Typography>
                                                {convertToDateTime(produttore.data_nascita)?.setLocale("it").toFormat("dd LLLL yyyy")}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                ) : (
                                    <Grid container spacing={2} sx={{mb: 2}}>
                                        <Grid item xs={12} sm={6} md={6} lg={3}>
                                            <Typography variant="caption">ragione sociale:</Typography>
                                            <Typography>{produttore.ragione_sociale}</Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={6} lg={3}>
                                            <Typography variant="caption">Partita Iva:</Typography>

                                            <Typography>{produttore.piva}</Typography>
                                        </Grid>
                                    </Grid>
                                )}
                                <Divider
                                    textAlign="left"
                                    sx={{mb: 2, color: theme.palette.primary.main}}
                                >
                                    Indirizzo di residenza
                                </Divider>

                                <Grid container spacing={2} sx={{mb: 2}}>
                                    <Grid item xs={12} sm={6} md={6} lg={4}>
                                        <Typography variant="caption">indirizzo:</Typography>
                                        <Typography>{produttore.indirizzo}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} lg={2}>
                                        <Typography variant="caption">numero civico:</Typography>
                                        <Typography>{produttore.numero_civico}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} lg={2}>
                                        <Typography variant="caption">cap:</Typography>
                                        <Typography>{produttore.cap}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} lg={2}>
                                        <Typography variant="caption">comune:</Typography>
                                        <Typography>{comuneCorrente?.nome}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} lg={2}>
                                        <Typography
                                            variant="body1"
                                            component="div"
                                            color="text.secondary"
                                            gutterBottom
                                        >
                                            {comuneCorrente?.provincia}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Divider
                                    textAlign="left"
                                    sx={{mb: 2, color: theme.palette.primary.main}}
                                >
                                    Contatti
                                </Divider>

                                <Grid container spacing={2} sx={{mb: 2}}>
                                    <Grid item xs={12} sm={6} md={6} lg={4}>
                                        <Typography variant="caption">cellulare:</Typography>
                                        <Typography>{produttore.cellulare}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} lg={4}>
                                        <Typography variant="caption">email:</Typography>
                                        <Typography>{produttore.email}</Typography>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2} sx={{mb: 2}}>

                                    <Grid item xs={12} lg={4}>
                                        <Divider
                                            textAlign="left"
                                            sx={{mb: 2, color: theme.palette.primary.main}}
                                        >
                                            Credenziali portale GSE{" "}
                                        </Divider>
                                        <Grid container spacing={2} sx={{mb: 2}}>
                                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                                <Typography variant="caption">gse id:</Typography>
                                                <Typography>{produttore.gse_id}</Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                                <Typography variant="caption">gse psw:</Typography>
                                                <Typography>{produttore.gse_psw}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} lg={4}>
                                        <Divider
                                            textAlign="left"
                                            sx={{mb: 2, color: theme.palette.primary.main}}
                                        >
                                            Credenziali gestore di rete
                                        </Divider>
                                        <Grid container spacing={2} sx={{mb: 2}}>
                                            <Grid item xs={12} sm={6} md={6} lg={6}>

                                                <Typography variant="caption">gestore di rete id:</Typography>
                                                <Typography>{produttore.gestore_rete_id}</Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                                <Typography variant="caption">gestore di rete psw:</Typography>
                                                <Typography>{produttore.gestore_rete_psw}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} lg={4}>
                                        <Divider
                                            textAlign="left"
                                            sx={{mb: 2, color: theme.palette.primary.main}}
                                        >
                                            Credenziali portale Gaudì
                                        </Divider>
                                        <Grid container spacing={2} sx={{mb: 2}}>
                                            <Grid item xs={12} sm={6} md={6} lg={6}>

                                                <Typography variant="caption">gaudi id:</Typography>
                                                <Typography>{produttore.gaudi_id}</Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                                <Typography variant="caption">gaudì psw:</Typography>
                                                <Typography>{produttore.gaudi_psw}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                </Grid>
                            </Paper>
                        ) : (
                            <Box>non trovato</Box>
                        )
                    }
                </Box>

                {!!produttore?.numero_pods &&
                    produttore.pods &&
                    utenteAutorizzato(utente, [
                        PermissionTypes.GESTORI_ITER,
                        PermissionTypes.OPERATORI,
                        PermissionTypes.PROGETTISTI,
                        PermissionTypes.PRODUTTORI,
                    ]) && (
                        <Box sx={{mb: 2}}>
                            <PodsProduttoreGrid pods={produttore.pods}/>
                        </Box>
                    )}
                <Box>
                    {!!produttore?.numero_iter_connessioni &&
                        produttore.iter_connessioni &&
                        utenteAutorizzato(utente, [
                            PermissionTypes.GESTORI_ITER,
                            PermissionTypes.OPERATORI,
                            PermissionTypes.PROGETTISTI,
                            PermissionTypes.PRODUTTORI,
                        ]) && (
                            <Box sx={{mb: 2}}>
                                <IterConnessioniProduttoreGrid
                                    iterConnessioni={produttore.iter_connessioni}
                                />
                            </Box>
                        )}
                </Box>

                <Box>
                    {produttore && (
                        <Box sx={{mb: 2}}>
                            <AdempimentiProduttoreGrid
                                produttore={produttore}
                                consentiModifica={utenteAutorizzato(utente, [
                                    PermissionTypes.GESTORI_ITER,
                                    PermissionTypes.OPERATORI,
                                    PermissionTypes.PROGETTISTI,
                                ])}
                            />
                        </Box>
                    )}
                </Box>
            </Box>
        </>
    );
};

export default AccessoProduttorePage;
