import React from "react";
import {Route, Routes} from "react-router-dom";
import PrivateRoute from "./authentication/PrivateRoute";
import ScrollToTop from "./common/ScrollToTop";

import HomePage from "./main/home/HomePage";
import ProfilePage from "./authentication/profile/ProfilePage";
import UnderConstruction from "./common/UnderConstruction";
import NotFoundPage from "./common/NotFoundPage";
import NotPermittedPage from "./common/NotPermittedPage";

import {PermissionTypes} from "../components/authentication/permissionsGroups";

import SignIn from "./authentication/SignIn";
import ChangePasswordPage from "./authentication/ChangePasswordPage";
import ForgotPassword from "./authentication/ForgotPassword";
import ResetPassword from "./authentication/ResetPassword";
import WellComePage from "./authentication/WellComePage";

import DashboardPage from "./main/dashboard/DashboardPage";
import IterConnessioniPage from "./main/iterConnessioni/IterConnessioniPage";
import IterConnessionePage from "./main/iterConnessioni/iterConnessione/IterConnessionePage";
import OfficinaElettricaNuovaIstanzaPage
    from "./main/iterConnessioni/iterConnessione/officinaElettrica/OfficinaElettricaNuovaIstanzaPage";
import AnagrafichePage from "./anagrafiche/AnagrafichePage";
import ProduttorePage from "./anagrafiche/produttori/ProduttorePage";
import ProduttoriPage from "./anagrafiche/produttori/ProduttoriPage";
import PraticheFatturabiliPage from "./main/amministrazione/fatturabili/PraticheFatturabiliPage";
import ClientiPage from "./anagrafiche/clienti/ClientiPage";
import ClientePage from "./anagrafiche/clienti/ClientePage";
import InstallatoriPage from "./anagrafiche/installatori/InstallatoriPage";

import SopralluoghiPage from "./main/sopralluoghi/SopralluoghiPage";
import SopralluogoPage from "./main/sopralluoghi/sopralluogo/SopralluogoPage";
import VerificheVincoliPage from "./main/verificheVincoli/VerificheVincoliPage";
import VerificaVincoliPage from "./main/verificheVincoli/verificaVincoli/VerificaVincoliPage";

import ScadenzePage from "./main/scadenze/ScadenzePage";
import RichiesteConnessionePage from "./main/richiesteConnessione/RichiesteConnessionePage";
import PRATICHE_COMUNALI from "./main/autorizzazioniComunali/AutorizzazioniComunaliPage";
import PodsPage from "./main/pods/podsPage";
import PodPage from "./main/pods/podPage";
import AdempimentiPage from "./main/adempimenti/AdempimentiPage";
import AdempimentoPage from "./main/adempimenti/adempimento/AdempimentoPage";
import ServiziServiziAdempimentiPage from "./main/adempimenti/serviziAdempimenti/ServiziAdempimentiPage";
import ServizioAdempimentiPage from "./main/adempimenti/serviziAdempimenti/servizioAdempimento/ServizioAdempimentiPage";
import CommessePage from "./main/commesse/CommessePage";
import FoglioBarcodeProdottiPage from "./main/commesse/commessa/esecuzione/preparazioneCantiere/FoglioBarcodeProdottiPage";
import LettoreCBPage from "./main/commesse/lettoreCB/LettoreCBPage";
import CommessaPage from "./main/commesse/commessa/CommessaPage";
import InstallatorePage from "./anagrafiche/installatori/InstallatorePage";
import UtentiPage from "./authentication/utenti/UtentiPage";
import UtentePage from "./authentication/utenti/utente/UtentePage";
import UtenteCerPage from "./main/comunitaEnergetiche/utenteCer/UtenteCerPage";
import ComunitaEnergeticaPage from "./main/comunitaEnergetiche/comunitaEnergetica/ComunitaEnergeticaPage";
import ComunitaEnergetichePage from "./main/comunitaEnergetiche/ComunitaEnergetichePage";
import TipiDocumentoPage from "./allegati/tipiDocumento/TipiDocumentoPage";
import TipoDocumentoPage from "./allegati/tipiDocumento/tipoDocumento/TipoDocumentoPage";
import AccessoProduttorePage from "components/main/accessiEsterni/produttori/AccessoProduttorePage";
import DynamicRedirect from "./DynamicRedirect";

export enum Routes_path {
    HOME = "/",
    SIGNIN = "/signin/",
    WELL_COME = "/well_come/:uidb64/:token/",
    FORGOT_PASSWORD = "/forgotPassword/",
    CHANGE_PASSWORD = "/changepassword/",
    RESET_PASSWORD = "/resetPassword/:uidb64/:token/",
    UNDER_COSTRUCTION = "/UnderConstruction/",

    DASHBOARD = "/dashboard/",
    PODS = "/pods/",
    POD = "/pods/pod/",
    ITER_CONNESSIONI = "/iter_connessioni/",
    ITER_CONNESSIONE = "/iter_connessioni/iter/",
    SCADENZE = "/scadenze/",
    SOPRALLUOGHI = "/sopralluoghi/",
    SOPRALLUOGO = "/sopralluoghi/sopralluogo/",
    RICHIESTE_CONNESSIONE = "/richieste_connessione/",
    PRATICHE_COMUNALI = "/pratiche_comunali/",
    PRATICA_COMUNALE = "/pratica_comunale/",
    OFFICINA_ELETTRICA_NUOVA_ISTANZA = "/iter_connessioni/officinaElettrica/nuova_istanza/",
    ANAGRAFICHE = "/anagrafiche/",
    PRODUTTORI = "/produttori/",
    PRODUTTORE = "/anagrafiche/produttori/",
    PAGINA_PRODUTTORE = "/produttore/",
    CLIENTI = "/anagrafiche/clienti/",
    CLIENTE = "/anagrafiche/cliente/",
    INSTALLATORI = "/anagrafiche/installatori/",
    INSTALLATORE = "/anagrafiche/installatore/",
    GESTORE = "/anagrafiche/gestori/",
    FATTURABILI = "/amministrazione/fatturabili/",
    ADEMPIMENTI = "/adempimenti/",
    ADEMPIMENTO = "/adempimenti/adempimento/",
    SERVIZI_ADEMPIMENTI = "/servizi_adempimenti/",
    SERVIZIO_ADEMPIMENTI = "/servizio_adempimenti/",
    COMMESSE = "/commesse/",
    LETTORE_CB = "/lettore_cb/",
    COMMESSA = "/commesse/commessa/",
    BARCODE_PRODOTTO = "/commesse/commessa/barcode_prodotto/",
    VERIFICHE_VINCOLI = "/verifiche_vincoli/",
    VERIFICA_VINCOLI = "/verifiche_vincoli/verifica_vincoli/",
    COMUNITA_ENERGETICHE = "/comunita_energetiche/",
    COMUNITA_ENERGETICA = "/comunita_energetica/",
    TIPI_DOCUMENTO = "/tipi_documento/",
    TIPO_DOCUMENTO = "/tipo_documento/",
    UTENTI = "/utenti/",
    UTENTE = "/utente/",
    UTENTE_CER = "/utente_cer/",
}

const RouterApp = () => {
    return (
        <ScrollToTop>
            <Routes>
                <Route path={Routes_path.HOME}  element={<DynamicRedirect />} />

                <Route path={Routes_path.SIGNIN} element={<SignIn/>}/>
                {/* <Route  path={"/register/"} element={Register} /> */}
                <Route path={Routes_path.WELL_COME} element={<WellComePage/>}/>
                <Route
                    path={Routes_path.FORGOT_PASSWORD}
                    element={<ForgotPassword/>}
                />
                <Route path={Routes_path.RESET_PASSWORD} element={<ResetPassword/>}/>
                {/* <Route
					path={Routes_path.UNDER_COSTRUCTION}
					element={<UnderConstruction />}
				/> */}
                <Route
                    path={Routes_path.UNDER_COSTRUCTION}
                    element={
                        <PrivateRoute
                            gruppiAutorizzati={[PermissionTypes.ALL]}
                            element={<UnderConstruction/>}
                        />
                    }
                />
                <Route
                    path={Routes_path.DASHBOARD}
                    element={
                        <PrivateRoute
                            gruppiAutorizzati={[
                                PermissionTypes.GESTORI_ITER,
                                PermissionTypes.OPERATORI,
                                PermissionTypes.PROGETTISTI,
                            ]}
                            element={<DashboardPage/>}
                        />
                    }
                />
                <Route
                    path={Routes_path.ITER_CONNESSIONI}
                    element={
                        <PrivateRoute
                            gruppiAutorizzati={[
                                PermissionTypes.GESTORI_ITER,
                                PermissionTypes.OPERATORI,
                                PermissionTypes.CLIENTI,
                                PermissionTypes.GENERAL_CONTRACTOR,
                                PermissionTypes.BCK_OFFICE_GENERAL_CONTRACTOR,
                                // PermissionTypes.PIANIFICATORI_LETTURA,
                                // PermissionTypes.OSPITI,
                            ]}
                            element={<IterConnessioniPage/>}
                        />
                    }
                />
                <Route
                    path={Routes_path.ITER_CONNESSIONE + ":iter_id"}
                    element={
                        <PrivateRoute
                            gruppiAutorizzati={[
                                PermissionTypes.GESTORI_ITER,
                                PermissionTypes.OPERATORI,
                                PermissionTypes.CLIENTI,
                                PermissionTypes.GENERAL_CONTRACTOR,
                                PermissionTypes.BCK_OFFICE_GENERAL_CONTRACTOR,

                                // PermissionTypes.PIANIFICATORI_LETTURA,
                                // PermissionTypes.OSPITI,
                            ]}
                            element={<IterConnessionePage/>}
                        />
                    }
                />
                <Route
                    path={Routes_path.PODS}
                    element={
                        <PrivateRoute
                            gruppiAutorizzati={[
                                PermissionTypes.GESTORI_ITER,
                                PermissionTypes.OPERATORI,
                                PermissionTypes.PROGETTISTI,
                            ]}
                            element={<PodsPage/>}
                        />
                    }
                />
                <Route
                    path={Routes_path.POD + ":pod_id"}
                    element={
                        <PrivateRoute
                            gruppiAutorizzati={[
                                PermissionTypes.GESTORI_ITER,
                                PermissionTypes.OPERATORI,
                                PermissionTypes.PROGETTISTI,
                            ]}
                            element={<PodPage/>}
                        />
                    }
                />
                <Route
                    path={Routes_path.ITER_CONNESSIONE}
                    element={
                        <PrivateRoute
                            gruppiAutorizzati={[
                                PermissionTypes.GESTORI_ITER,
                                PermissionTypes.OPERATORI,
                                // PermissionTypes.PIANIFICATORI_LETTURA,
                                // PermissionTypes.OSPITI,
                            ]}
                            element={<IterConnessioniPage/>}
                        />
                    }
                />
                <Route
                    path={Routes_path.SOPRALLUOGO + ":sopralluogo_id"}
                    element={
                        <PrivateRoute
                            gruppiAutorizzati={[
                                PermissionTypes.GESTORI_ITER,
                                PermissionTypes.OPERATORI,
                                // PermissionTypes.CLIENTI,
                                PermissionTypes.GENERAL_CONTRACTOR,
                                PermissionTypes.BCK_OFFICE_GENERAL_CONTRACTOR,

                                // PermissionTypes.PIANIFICATORI_LETTURA,
                                // PermissionTypes.OSPITI,
                            ]}
                            element={<SopralluogoPage/>}
                        />
                    }
                />
                <Route
                    path={Routes_path.SOPRALLUOGO}
                    element={
                        <PrivateRoute
                            gruppiAutorizzati={[
                                PermissionTypes.GESTORI_ITER,
                                PermissionTypes.OPERATORI,
                                // PermissionTypes.CLIENTI,
                                PermissionTypes.GENERAL_CONTRACTOR,
                                PermissionTypes.BCK_OFFICE_GENERAL_CONTRACTOR,

                                // PermissionTypes.PIANIFICATORI_LETTURA,
                                // PermissionTypes.OSPITI,
                            ]}
                            element={<SopralluogoPage/>}
                        />
                    }
                />
                <Route
                    path={Routes_path.SOPRALLUOGHI}
                    element={
                        <PrivateRoute
                            gruppiAutorizzati={[
                                PermissionTypes.GESTORI_ITER,
                                PermissionTypes.OPERATORI,
                                // PermissionTypes.CLIENTI,
                                PermissionTypes.GENERAL_CONTRACTOR,
                                PermissionTypes.BCK_OFFICE_GENERAL_CONTRACTOR,

                                // PermissionTypes.PIANIFICATORI_LETTURA,
                                // PermissionTypes.OSPITI,
                            ]}
                            element={<SopralluoghiPage/>}
                        />
                    }
                />
                <Route
                    path={Routes_path.VERIFICA_VINCOLI + ":verifica_id"}
                    element={
                        <PrivateRoute
                            gruppiAutorizzati={[
                                PermissionTypes.GESTORI_ITER,
                                PermissionTypes.OPERATORI,
                                // PermissionTypes.CLIENTI,
                                PermissionTypes.GENERAL_CONTRACTOR,
                                PermissionTypes.BCK_OFFICE_GENERAL_CONTRACTOR,

                                // PermissionTypes.PIANIFICATORI_LETTURA,
                                // PermissionTypes.OSPITI,
                            ]}
                            element={<VerificaVincoliPage/>}
                        />
                    }
                />
                <Route
                    path={Routes_path.VERIFICA_VINCOLI}
                    element={
                        <PrivateRoute
                            gruppiAutorizzati={[
                                PermissionTypes.GESTORI_ITER,
                                PermissionTypes.OPERATORI,
                                // PermissionTypes.CLIENTI,
                                PermissionTypes.GENERAL_CONTRACTOR,
                                PermissionTypes.BCK_OFFICE_GENERAL_CONTRACTOR,

                                // PermissionTypes.PIANIFICATORI_LETTURA,
                                // PermissionTypes.OSPITI,
                            ]}
                            element={<VerificaVincoliPage/>}
                        />
                    }
                />
                <Route
                    path={Routes_path.VERIFICHE_VINCOLI}
                    element={
                        <PrivateRoute
                            gruppiAutorizzati={[
                                PermissionTypes.GESTORI_ITER,
                                PermissionTypes.OPERATORI,
                                // PermissionTypes.CLIENTI,
                                PermissionTypes.GENERAL_CONTRACTOR,
                                PermissionTypes.BCK_OFFICE_GENERAL_CONTRACTOR,

                                // PermissionTypes.PIANIFICATORI_LETTURA,
                                // PermissionTypes.OSPITI,
                            ]}
                            element={<VerificheVincoliPage/>}
                        />
                    }
                />
                <Route
                    path={Routes_path.SCADENZE}
                    element={
                        <PrivateRoute
                            gruppiAutorizzati={[
                                PermissionTypes.GESTORI_ITER,
                                // PermissionTypes.OSPITI,
                            ]}
                            element={<ScadenzePage/>}
                        />
                    }
                />
                <Route
                    path={Routes_path.PRATICA_COMUNALE + ":praticaComunale_id"}
                    element={
                        <PrivateRoute
                            gruppiAutorizzati={[
                                PermissionTypes.GESTORI_ITER,
                                PermissionTypes.OPERATORI,
                                // PermissionTypes.CLIENTI,
                                PermissionTypes.GENERAL_CONTRACTOR,
                                PermissionTypes.BCK_OFFICE_GENERAL_CONTRACTOR,

                                // PermissionTypes.PIANIFICATORI_LETTURA,
                                // PermissionTypes.OSPITI,
                            ]}
                            element={<UnderConstruction/>}
                        />
                    }
                />
                <Route
                    path={Routes_path.PRATICA_COMUNALE}
                    element={
                        <PrivateRoute
                            gruppiAutorizzati={[
                                PermissionTypes.GESTORI_ITER,
                                PermissionTypes.OPERATORI,
                                // PermissionTypes.CLIENTI,
                                PermissionTypes.GENERAL_CONTRACTOR,
                                PermissionTypes.BCK_OFFICE_GENERAL_CONTRACTOR,

                                // PermissionTypes.PIANIFICATORI_LETTURA,
                                // PermissionTypes.OSPITI,
                            ]}
                            element={<UnderConstruction/>}
                        />
                    }
                />
                <Route
                    path={Routes_path.RICHIESTE_CONNESSIONE}
                    element={
                        <PrivateRoute
                            gruppiAutorizzati={[
                                PermissionTypes.GESTORI_ITER,
                                PermissionTypes.OPERATORI,
                            ]}
                            element={<RichiesteConnessionePage/>}
                        />
                    }
                />
                <Route
                    path={Routes_path.PRATICHE_COMUNALI}
                    element={
                        <PrivateRoute
                            gruppiAutorizzati={[
                                PermissionTypes.GESTORI_ITER,
                                PermissionTypes.OPERATORI,
                                // PermissionTypes.CLIENTI,
                                PermissionTypes.GENERAL_CONTRACTOR,
                                PermissionTypes.BCK_OFFICE_GENERAL_CONTRACTOR,
                            ]}
                            element={<PRATICHE_COMUNALI/>}
                        />
                    }
                />
                <Route
                    path={
                        Routes_path.OFFICINA_ELETTRICA_NUOVA_ISTANZA + ":iterConnessione_id"
                    }
                    element={
                        <PrivateRoute
                            gruppiAutorizzati={[
                                PermissionTypes.ALL,
                                // PermissionTypes.PIANIFICATORI_LETTURA,
                                // PermissionTypes.OSPITI,
                            ]}
                            element={<OfficinaElettricaNuovaIstanzaPage/>}
                        />
                    }
                />
                <Route
                    path={Routes_path.ANAGRAFICHE}
                    element={
                        <PrivateRoute
                            gruppiAutorizzati={[PermissionTypes.GESTORI_ITER]}
                            element={<AnagrafichePage/>}
                        />
                    }
                />
                <Route
                    path={Routes_path.PRODUTTORI}
                    element={
                        <PrivateRoute
                            gruppiAutorizzati={[
                                PermissionTypes.GESTORI_ITER,
                                PermissionTypes.OPERATORI,
                                PermissionTypes.PROGETTISTI,
                            ]}
                            element={<ProduttoriPage/>}
                        />
                    }
                />
                <Route
                    path={Routes_path.PRODUTTORE + ":produttore_id/:tipoPratica_id"}
                    element={
                        <PrivateRoute
                            gruppiAutorizzati={[
                                PermissionTypes.GESTORI_ITER,
                                PermissionTypes.OPERATORI,
                                PermissionTypes.PROGETTISTI,
                                // PermissionTypes.PIANIFICATORI_LETTURA,
                                // PermissionTypes.OSPITI,
                            ]}
                            element={<ProduttorePage/>}
                        />
                    }
                />
                <Route
                    path={Routes_path.PRODUTTORE + ":produttore_id"}
                    element={
                        <PrivateRoute
                            gruppiAutorizzati={[
                                PermissionTypes.GESTORI_ITER,
                                PermissionTypes.OPERATORI,
                                PermissionTypes.PROGETTISTI,
                                // PermissionTypes.PIANIFICATORI_LETTURA,
                                // PermissionTypes.OSPITI,
                            ]}
                            element={<ProduttorePage/>}
                        />
                    }
                />
                <Route
                    path={Routes_path.PRODUTTORE}
                    element={
                        <PrivateRoute
                            gruppiAutorizzati={[
                                PermissionTypes.GESTORI_ITER,
                                PermissionTypes.OPERATORI,
                                PermissionTypes.PROGETTISTI,
                                // PermissionTypes.PIANIFICATORI_LETTURA,
                                // PermissionTypes.OSPITI,
                            ]}
                            element={<ProduttorePage/>}
                        />
                    }
                />
                <Route
                    path={Routes_path.PAGINA_PRODUTTORE}
                    element={
                        <PrivateRoute
                            gruppiAutorizzati={[
                                PermissionTypes.PRODUTTORI,
                            ]}
                            element={<AccessoProduttorePage/>}
                        />
                    }
                />
                <Route
                    path={Routes_path.FATTURABILI}
                    element={
                        <PrivateRoute
                            gruppiAutorizzati={[
                                PermissionTypes.GESTORI_ITER,
                                // PermissionTypes.PIANIFICATORI_LETTURA,
                                // PermissionTypes.OSPITI,
                            ]}
                            element={<PraticheFatturabiliPage/>}
                        />
                    }
                />
                <Route
                    path={Routes_path.ADEMPIMENTI}
                    element={
                        <PrivateRoute
                            gruppiAutorizzati={[
                                PermissionTypes.GESTORI_ITER,
                                PermissionTypes.OPERATORI,
                                PermissionTypes.CLIENTI,
                                // PermissionTypes.OSPITI,
                            ]}
                            element={<AdempimentiPage/>}
                        />
                    }
                />
                <Route
                    path={Routes_path.ADEMPIMENTO + ":adempimento_id"}
                    element={
                        <PrivateRoute
                            gruppiAutorizzati={[
                                PermissionTypes.GESTORI_ITER,
                                PermissionTypes.OPERATORI,
                                PermissionTypes.CLIENTI,
                                PermissionTypes.PRODUTTORI,
                                // PermissionTypes.PIANIFICATORI_LETTURA,
                                // PermissionTypes.OSPITI,
                            ]}
                            element={<AdempimentoPage/>}
                        />
                    }
                />
                <Route
                    path={Routes_path.SERVIZI_ADEMPIMENTI}
                    element={
                        <PrivateRoute
                            gruppiAutorizzati={[
                                PermissionTypes.GESTORI_ITER,
                                PermissionTypes.OPERATORI,
                                PermissionTypes.CLIENTI,
                                // PermissionTypes.OSPITI,
                            ]}
                            element={<ServiziServiziAdempimentiPage/>}
                        />
                    }
                />
                <Route
                    path={Routes_path.SERVIZIO_ADEMPIMENTI + ":servizioAdempimenti_id"}
                    element={
                        <PrivateRoute
                            gruppiAutorizzati={[
                                PermissionTypes.GESTORI_ITER,
                                PermissionTypes.OPERATORI,
                                PermissionTypes.CLIENTI,
                                // PermissionTypes.PIANIFICATORI_LETTURA,
                                // PermissionTypes.OSPITI,
                            ]}
                            element={<ServizioAdempimentiPage/>}
                        />
                    }
                />
                ////////////////////////////////////////////////////
                <Route
                    path={Routes_path.COMMESSA + ":commessa_id"}
                    element={
                        <PrivateRoute
                            gruppiAutorizzati={[
                                PermissionTypes.GENERAL_CONTRACTOR,
                                PermissionTypes.BCK_OFFICE_GENERAL_CONTRACTOR,

                                // PermissionTypes.GESTORI_ITER,
                                // PermissionTypes.OPERATORI,
                                // PermissionTypes.PIANIFICATORI_LETTURA,
                                // PermissionTypes.OSPITI,
                            ]}
                            element={<CommessaPage/>}
                        />
                    }
                />
                <Route
                    path={Routes_path.COMMESSA}
                    element={
                        <PrivateRoute
                            gruppiAutorizzati={[
                                // PermissionTypes.GESTORI_ITER,
                                // PermissionTypes.OPERATORI,
                                PermissionTypes.GENERAL_CONTRACTOR,
                                PermissionTypes.BCK_OFFICE_GENERAL_CONTRACTOR,
                            ]}
                            element={<CommessaPage/>}
                        />
                    }
                />
                <Route
                    path={Routes_path.COMMESSE}
                    element={
                        <PrivateRoute
                            gruppiAutorizzati={[
                                // PermissionTypes.GESTORI_ITER,
                                // PermissionTypes.OPERATORI,
                                PermissionTypes.GENERAL_CONTRACTOR,
                                PermissionTypes.BCK_OFFICE_GENERAL_CONTRACTOR,
                            ]}
                            element={<CommessePage/>}
                        />
                    }
                />
                <Route
                    path={Routes_path.BARCODE_PRODOTTO + ":commessa_id"}
                    element={
                        <PrivateRoute
                            gruppiAutorizzati={[
                                // PermissionTypes.GESTORI_ITER,
                                // PermissionTypes.OPERATORI,
                                PermissionTypes.GENERAL_CONTRACTOR,
                                PermissionTypes.BCK_OFFICE_GENERAL_CONTRACTOR,
                            ]}
                            element={<FoglioBarcodeProdottiPage/>}
                        />
                    }
                />

                <Route
                    path={Routes_path.LETTORE_CB}
                    element={
                        <PrivateRoute
                            gruppiAutorizzati={[
                                PermissionTypes.ALL,
                                // PermissionTypes.PIANIFICATORI_LETTURA,
                                // PermissionTypes.OSPITI,
                            ]}
                            element={<LettoreCBPage />}
                        />
                    }
                />
                ////////////////////////////////////////////////////
                <Route
                    path={Routes_path.COMUNITA_ENERGETICA + ":comunitaEnergetica_id"}
                    element={
                        <PrivateRoute
                            gruppiAutorizzati={[
                                PermissionTypes.ALL,
                                // PermissionTypes.PIANIFICATORI_LETTURA,
                                // PermissionTypes.OSPITI,
                            ]}
                            element={<ComunitaEnergeticaPage/>}
                        />
                    }
                />
                <Route
                    path={Routes_path.COMUNITA_ENERGETICA}
                    element={
                        <PrivateRoute
                            gruppiAutorizzati={[
                                PermissionTypes.ALL,
                                // PermissionTypes.PIANIFICATORI_LETTURA,
                                // PermissionTypes.OSPITI,
                            ]}
                            element={<NotFoundPage/>}
                        />
                    }
                />
                <Route
                    path={Routes_path.COMUNITA_ENERGETICHE}
                    element={
                        <PrivateRoute
                            gruppiAutorizzati={[
                                PermissionTypes.GESTORI_COMUNITA_ENERGETICHE,
                                // PermissionTypes.PIANIFICATORI_LETTURA,
                                // PermissionTypes.OSPITI,
                            ]}
                            element={<ComunitaEnergetichePage/>}
                        />
                    }
                />
                <Route
                    path={Routes_path.UTENTE_CER}
                    element={
                        <PrivateRoute
                            gruppiAutorizzati={[
                                PermissionTypes.GESTORI_COMUNITA_ENERGETICHE,
                                // PermissionTypes.PIANIFICATORI_LETTURA,
                                // PermissionTypes.OSPITI,
                            ]}
                            element={<UtenteCerPage/>}
                        />
                    }
                />
                <Route
                    path={Routes_path.UTENTE_CER + ":utente_id"}
                    element={
                        <PrivateRoute
                            gruppiAutorizzati={[
                                PermissionTypes.GESTORI_COMUNITA_ENERGETICHE,
                                // PermissionTypes.PIANIFICATORI_LETTURA,
                                // PermissionTypes.OSPITI,
                            ]}
                            element={<UtenteCerPage/>}
                        />
                    }
                />
                ////////////////////////////////////////////////////
                <Route
                    path={Routes_path.CLIENTI}
                    element={
                        <PrivateRoute
                            gruppiAutorizzati={[
                                PermissionTypes.GESTORI_ITER,
                                PermissionTypes.OPERATORI,
                                // PermissionTypes.PIANIFICATORI_LETTURA,
                                // PermissionTypes.OSPITI,
                            ]}
                            element={<ClientiPage/>}
                        />
                    }
                />
                <Route
                    path={Routes_path.CLIENTE + ":cliente_id"}
                    element={
                        <PrivateRoute
                            gruppiAutorizzati={[
                                PermissionTypes.GESTORI_ITER,
                                PermissionTypes.OPERATORI,
                                // PermissionTypes.PIANIFICATORI_LETTURA,
                                // PermissionTypes.OSPITI,
                            ]}
                            element={<ClientePage/>}
                        />
                    }
                />
                <Route
                    path={Routes_path.CLIENTE}
                    element={
                        <PrivateRoute
                            gruppiAutorizzati={[
                                PermissionTypes.GESTORI_ITER,
                                PermissionTypes.OPERATORI,
                                // PermissionTypes.PIANIFICATORI_LETTURA,
                                // PermissionTypes.OSPITI,
                            ]}
                            element={<ClientePage/>}
                        />
                    }
                />
                <Route
                    path={Routes_path.INSTALLATORE + ":installatore_id"}
                    element={
                        <PrivateRoute
                            gruppiAutorizzati={[
                                PermissionTypes.GESTORI_ITER,
                                PermissionTypes.OPERATORI,
                                // PermissionTypes.PIANIFICATORI_LETTURA,
                                // PermissionTypes.OSPITI,
                            ]}
                            element={<InstallatorePage/>}
                        />
                    }
                />
                <Route
                    path={Routes_path.INSTALLATORE}
                    element={
                        <PrivateRoute
                            gruppiAutorizzati={[
                                PermissionTypes.GESTORI_ITER,
                                PermissionTypes.OPERATORI,
                                // PermissionTypes.PIANIFICATORI_LETTURA,
                                // PermissionTypes.OSPITI,
                            ]}
                            element={<InstallatorePage/>}
                        />
                    }
                />
                <Route
                    path={Routes_path.INSTALLATORI}
                    element={
                        <PrivateRoute
                            gruppiAutorizzati={[
                                PermissionTypes.GESTORI_ITER,
                                PermissionTypes.OPERATORI,
                                // PermissionTypes.PIANIFICATORI_LETTURA,
                                // PermissionTypes.OSPITI,
                            ]}
                            element={<InstallatoriPage/>}
                        />
                    }
                />
                ///////////////////////////////////////////////
                <Route
                    path={Routes_path.TIPI_DOCUMENTO}
                    element={
                        <PrivateRoute
                            gruppiAutorizzati={[
                                PermissionTypes.AMMINISTRATORI_DOCUMENTI,
                                // PermissionTypes.PIANIFICATORI,
                                // PermissionTypes.GESTORI_COMMESSE,
                            ]}
                            element={<TipiDocumentoPage/>}
                        />
                    }
                />
                <Route
                    path={Routes_path.TIPO_DOCUMENTO}
                    element={
                        <PrivateRoute
                            gruppiAutorizzati={[
                                PermissionTypes.AMMINISTRATORI_DOCUMENTI,
                                // PermissionTypes.PIANIFICATORI,
                                // PermissionTypes.GESTORI_COMMESSE,
                            ]}
                            element={<TipoDocumentoPage/>}
                        />
                    }
                />
                <Route
                    path={Routes_path.TIPO_DOCUMENTO + ":tipo_documento_id"}
                    element={
                        <PrivateRoute
                            gruppiAutorizzati={[
                                PermissionTypes.AMMINISTRATORI_DOCUMENTI,
                                // PermissionTypes.PIANIFICATORI,
                                // PermissionTypes.GESTORI_COMMESSE,
                            ]}
                            element={<TipoDocumentoPage/>}
                        />
                    }
                />
                <Route
                    path={Routes_path.UTENTI}
                    element={
                        <PrivateRoute
                            gruppiAutorizzati={[
                                PermissionTypes.GESTORI_COMUNITA_ENERGETICHE,
                                PermissionTypes.GESTORI_ITER,
                            ]}
                            element={<UtentiPage/>}
                        />
                    }
                />
                <Route
                    path={Routes_path.UTENTE}
                    element={
                        <PrivateRoute
                            gruppiAutorizzati={[
                                PermissionTypes.GESTORI_COMUNITA_ENERGETICHE,
                                PermissionTypes.GESTORI_ITER,
                            ]}
                            element={<UtentePage/>}
                        />
                    }
                />
                <Route
                    path={Routes_path.UTENTE + ":utente_id"}
                    element={
                        <PrivateRoute
                            gruppiAutorizzati={[
                                PermissionTypes.GESTORI_COMUNITA_ENERGETICHE,
                                PermissionTypes.GESTORI_ITER,
                            ]}
                            element={<UtentePage/>}
                        />
                    }
                />
                <Route
                    path="/me"
                    element={
                        <PrivateRoute
                            gruppiAutorizzati={[PermissionTypes.ALL]}
                            element={<ProfilePage/>}
                        />
                    }
                />
                <Route
                    path={Routes_path.CHANGE_PASSWORD}
                    element={
                        <PrivateRoute
                            gruppiAutorizzati={[PermissionTypes.ALL]}
                            element={<ChangePasswordPage/>}
                        />
                    }
                />
                <Route path="/notPermitted" element={<NotPermittedPage/>}/>
                <Route path="*" element={<NotFoundPage/>}/>
            </Routes>
        </ScrollToTop>
    );
};

export default RouterApp;
