import React from 'react';
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { ConteggioStati } from "../../anagrafiche/kpi/kpiSlice";
import {
	PieChart,
	Pie,
	Sector,
	Cell,
	ResponsiveContainer,
	Legend,
	Tooltip,
	Label,
	LabelList,
} from "recharts";

const GeneratoreDati = (parametri: {
	inLavorazione: number;
	disponibili: number;
}) => {
	const data = [
		{ name: "In lavorazione", value: parametri.inLavorazione },
		{ name: "Disponibile", value: parametri.disponibili },
	];
	return data;
};

import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

const COLORS = ["#50AABC", "#AFFF42"];

const RADIAN = Math.PI / 180;
interface renderCustomizedLabelProps {
	cx: number;
	cy: number;
	midAngle: number;
	innerRadius: number;
	outerRadius: number;
	percent: number;
	name: string;
	index: number | undefined;
}
const renderCustomizedLabel = ({
	cx,
	cy,
	midAngle,
	innerRadius,
	outerRadius,
	percent,
	name,
	index,
}: renderCustomizedLabelProps) => {
	const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
	const x = cx + radius * Math.cos(-midAngle * RADIAN);
	const y = cy + radius * Math.sin(-midAngle * RADIAN);

	return (
		<text
			x={x}
			y={y}
			fontSize={14}
			fill="black"
			// textAnchor={x > cx ? "start" : "end"}
			textAnchor="middle"
			dominantBaseline="central"
			// transform="rotate(-35)"
		>
			{`${(percent * 100).toFixed(0)}%`}
		</text>
	);
};

const labelFormatter = (name: any) => {
	// const color = COLORS[entry.index % COLORS.length];
	const color = "black";
	const fontSize = 14;
	return (
		<text fill={color} fontSize={fontSize}>
			<tspan>{name}</tspan>
		</text>
	);
};

const CustomTooltip = ({ active, payload }: any) => {
	if (active && payload && payload.length) {
		const { name, value } = payload[0];
		return (
			<div className="custom-tooltip">
				<p className="label">{`${name} : ${value}`}</p>
			</div>
		);
	}

	return null;
};

const formatLegendText = (value: string) => {
	return <span style={{ fontSize: 14 }}>{value}</span>;
};

const ClienteProgressoPieChart = () => {
	const conteggioStatiSopralluoghi = useSelector<RootState, ConteggioStati>(
		(state) => state.kpiState.conteggioStatiSopralluoghi
	);
	const conteggioStatiProgetti = useSelector<RootState, ConteggioStati>(
		(state) => state.kpiState.conteggioStatiProgettii
	);
	const conteggioStatiIter = useSelector<RootState, ConteggioStati>(
		(state) => state.kpiState.conteggioStatiIter
	);
	const conteggioDispacciamentoGse = useSelector<RootState, ConteggioStati>(
		(state) => state.kpiState.conteggioDispacciamentoGse
	);
	const conteggioPraticheEnea = useSelector<RootState, ConteggioStati>(
		(state) => state.kpiState.conteggioPraticheEnea
	);

	const data = GeneratoreDati({
		inLavorazione:
			(conteggioStatiSopralluoghi.in_lavorazione || 0) +
			(conteggioStatiProgetti.in_lavorazione || 0) +
			(conteggioStatiIter.in_lavorazione || 0) +
			(conteggioStatiIter.in_lavorazione || 0) +
			0 +
			(conteggioDispacciamentoGse.in_lavorazione || 0) +
			(conteggioPraticheEnea.in_lavorazione || 0),
		disponibili:
			(conteggioStatiSopralluoghi.disponibile || 0) +
			(conteggioStatiProgetti.disponibile || 0) +
			(conteggioStatiIter.disponibile || 0) +
			(conteggioStatiIter.disponibile || 0) +
			0 +
			(conteggioDispacciamentoGse.disponibile || 0) +
			(conteggioPraticheEnea.disponibile || 0),
	});
	return (
		<Card sx={{ bgcolor: "rgba(25,25,25,0.6)" }}>
			<Typography
				variant="h5"
				component={Box}
				align="center"
				sx={{ fontSize: "1.3rem !important", color: "white" }}
			>
				Progresso dei servizi
			</Typography>
			<CardContent>
				<Box sx={{ height: "27vh", minHeight: 250 }}>
					<ResponsiveContainer width="100%" height="100%">
						<PieChart width={400} height={400}>
							<Pie
								data={data}
								nameKey="name"
								cx="50%"
								cy="50%"
								labelLine={false}
								label={renderCustomizedLabel}
								outerRadius={120}
								// fill="#8884d8"
								dataKey="value"
							>
								{data.map((entry, index) => (
									<Cell
										key={`cell-${index}`}
										fill={COLORS[index % COLORS.length]}
									/>
								))}
							</Pie>

							{/* <Tooltip content={<CustomTooltip />} /> */}
							<Legend
								layout="vertical"
								align="right"
								verticalAlign="middle"
								iconSize={8}
								width={250}
								formatter={formatLegendText}
							/>
						</PieChart>
					</ResponsiveContainer>
				</Box>
			</CardContent>
		</Card>
	);
};

export default ClienteProgressoPieChart;
