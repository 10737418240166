import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, Link } from "react-router-dom";

import { RootState, useAppDispatch } from "../../../../redux/store";

import {
	setUtenteCorrente,
	getUtente,
	Utente,
	Utenti,
	saveUtente,
	deleteUtente,
	invitaUtente,
} from "../utentiSlice";
import {
	Cliente,
	fetchClienti,
} from "../../../anagrafiche/clienti/clientiSlice";

import UtentePageBreadcrumbs from "./UtentePageBreadcrumbs";

import {
	ComunitaEnergetica,
	fetchComunitaEnergetiche,
} from "../../../main/comunitaEnergetiche/comunitaEnergeticheSlice";

import { DateTime } from "luxon";
import { convertToDateTime } from "components/common/dateUtils/convertToDateTime";

import UtenteForm from "./UtenteForm";
import {
	ErrorStatusTypes,
	ErrorsStack,
} from "../../../common/errorsDeclarations";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";

import Button from "@mui/material/Button";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

import Grid from "@mui/material/Grid";
import PrintIcon from "@mui/icons-material/Print";
import SendIcon from "@mui/icons-material/Send";

import { Box } from "@mui/system";
import { Routes_path } from "components/routerApp";
import { CardActionArea, CardHeader, Typography } from "@mui/material";

const UtentePage = () => {
	
	const dispatch = useAppDispatch();

	const utente_id = useSelector<RootState, number | null>(
		(state) => state.utentiState.utente_id
	);

	type LocationState = {
		utente_attuale_id?: number | null;
		clientePreset?: number;
	};
	const location = useLocation();

	const { utente_attuale_id } = (location.state as LocationState) || {
		utente_id: null,
	};

	const { clientePreset } = (location.state as LocationState) || {
		clientePreset: [],
	};

	const [refresh, setRefresh] = React.useState<boolean>(false);

	useEffect(() => {
		dispatch(fetchClienti({ numeroRecord: 1000 }));
		dispatch(fetchComunitaEnergetiche({ numeroRecord: 1000 }));

		setRefresh(false);
	}, [refresh]);

	useEffect(() => {
		dispatch(setUtenteCorrente({ utente_id: utente_attuale_id || null }));
	}, []);

	// ############# Per resettare all'uscita ###############################
	useEffect(
		() => () => {
			dispatch(setUtenteCorrente({ utente_id: null }));
		},
		[]
	);
	// #######################################################################

	useEffect(() => {
		if (utente_id != null || utente_id != undefined) {
			dispatch(getUtente(utente_id));
		}
	}, [utente_id, refresh]);

	const utenti: Utenti = useSelector<RootState, Utenti>(
		(state) => state.utentiState.utenti
	);

	const clienti = useSelector<RootState, Cliente[]>(
		(state) => state.clientiState.clienti.results
	);

	const comunita_energetiche: ComunitaEnergetica[] = useSelector<
		RootState,
		ComunitaEnergetica[]
	>((state) => state.comunitaEnergeticheState.comunitaEnergetiche.results);

	const utenteNuovoFieldsErrors: ErrorsStack = useSelector<
		RootState,
		ErrorsStack
	>((state) => state.utentiState.utenti.errorsStack);

	const [utente, setUtente] = useState<Utente>({
		id: null,
		user: null,
		cliente: null,
		general_contractor: false,
		cer: null,
		// solo_lettura: true,
		errorsStack: utenteNuovoFieldsErrors,
	});

	useEffect(() => {
		setUtente({
			...utente,
			errorsStack: utenteNuovoFieldsErrors,
		});
	}, [utenteNuovoFieldsErrors]);

	const utenteEsistente: Utente | undefined = useSelector<
		RootState,
		Utente | undefined
	>((state) =>
		state.utentiState.utenti.results.find((utente) => utente.id == utente_id)
	);

	useEffect(() => {
		utenteEsistente && setUtente(utenteEsistente);
	}, [utente_id, utenteEsistente]);

	const handlerSaveUtente = (utenteToSave: Utente) => {
		dispatch(saveUtente(utenteToSave));
	};

	const handlerDeleteUtente = (utenteToDelete: Utente) => {
		dispatch(deleteUtente(utenteToDelete));
	};

	const handlerInvitaUtente = () => {
		utente?.user?.email && dispatch(invitaUtente(utente));
	};

	return (
		<Box sx={{ p: 2 }}>
			<UtentePageBreadcrumbs identificativoPosizione={utente?.id || "nuovo"} />
			<Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={utenti.errorsStack.status == ErrorStatusTypes.PENDING}
			>
				<CircularProgress color="inherit" />
			</Backdrop>

			{utente && (
				<Grid container justifyContent="center">
					<Grid item xs={12} sm={10} md={8}>
						<Card raised sx={{ mt: 3 }}>
							<CardHeader
								sx={{ backgroundColor: "#b0cab0" }}
								title={
									<Stack direction="row" justifyContent="space-between">
										<Typography variant="h5" gutterBottom>
											{utente.id ? "Utente n° " + utente.id : "Nuovo utente"}
										</Typography>

										<Typography>
											Ultimo accesso:
											{convertToDateTime(utente.user?.last_login)?.setLocale("it").toFormat("dd/MM/yy HH:mm")}
										</Typography>
									</Stack>
								}
							/>
							<CardContent sx={{ p: 2 }}>
								<UtenteForm
									key={utente.id || 0}
									utente={utente}
									clienti={clienti}
									comunita_energetiche={comunita_energetiche}
									clientePreset={clientePreset}
								/>
							</CardContent>
							<CardActionArea>
								{/* {" "}
								<Button
									variant="contained"
									component={Link}
									to={{
										pathname: Routes_path.UTENTI,
									}}
									endIcon={<KeyboardReturnIcon />}
								>
									Torna alla lista Utenti
								</Button> */}
							</CardActionArea>
						</Card>
					</Grid>
				</Grid>
			)}
		</Box>
	);
};

export default UtentePage;
