import { useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Routes_path } from "../../../../routerApp";

import {
	IterConnessione,
	presaInCarico,
	avviaProgetto,
	assegnaPratica,
	deleteIterConnessione,
} from "components/main/iterConnessioni/iterConnessioneSlice";
import { Soggetto } from "../../../../anagrafiche/produttori/produttoriSlice";

import { DateTime } from "luxon";

import { RootState, useAppDispatch } from "../../../../../redux/store";

import IterConnessioneNoteInterneForm from "../datiPod/IterConnessioneNoteInterneForm";

import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import ConfermaAnnulla from "../../../../common/ConfermaAnnulla";

import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import { CardHeader } from "@mui/material";
interface AzioniGestoreCardProps {
	iterConnessione: IterConnessione;
}

const AzioniGestoreCard = ({ iterConnessione }: AzioniGestoreCardProps) => {
	
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const produttore: Soggetto | undefined = useSelector<
		RootState,
		Soggetto | undefined
	>((state) =>
		state.produttoriState.produttori.results.find(
			(produttore) => produttore?.id == iterConnessione?.produttore
		)
	);

	const [openConferma, setOpenConferma] = useState(false);
	const handleConfermaClose = () => setOpenConferma(false);
	return (
		<Card elevation={3} sx={{ mt: 2 }}>
			<CardHeader sx={{ backgroundColor: "#52ed22" }} title="Gestione I43" />
			<CardContent>
				<Stack sx={{ width: "100%" }} spacing={2}>
					<IterConnessioneNoteInterneForm iterConnessione={iterConnessione} />
					{/* {["A", "B", "C", "D", "E", "F", "G", "H"].includes(
						iterConnessione.stato
					) && ( */}
					<Button
						color="secondary"
						variant="contained"
						size="large"
						sx={{ width: "100%" }}
						onClick={() => setOpenConferma(true)}
					>
						Cancella Iter Connessione
					</Button>
					<ConfermaAnnulla
						handleAnnulla={handleConfermaClose}
						handleConferma={() => {
							dispatch(deleteIterConnessione(iterConnessione));
							navigate(`${Routes_path.ITER_CONNESSIONI}`, {
								replace: true,
							});
						}}
						domanda={"Sicuro di voler cancellare la richiesta"}
						open={openConferma}
					/>
					{/* )} */}
					{["A", "B", "C"].includes(iterConnessione.stato) && (
						<Button
							variant="contained"
							size="large"
							disabled={
								!["B", "C"].includes(iterConnessione.stato) ||
								!iterConnessione.dati_avvio_iter_completo
							}
							sx={{ width: "100%", mt: 1 }}
							onClick={() => {
								dispatch(presaInCarico(iterConnessione));
							}}
						>
							Prendi in carico
						</Button>
					)}
					{["E"].includes(iterConnessione.stato) && (
						<>
							{!iterConnessione.progetto_avviabilie?.progetto_avviabilie && (
								<Alert severity="error" sx={{ mt: 1 }}>
									<AlertTitle>Attenzione manca:</AlertTitle>
									<Typography variant="body2" sx={{ whiteSpace: "pre-line" }}>
										{iterConnessione.progetto_avviabilie?.dati_mancanti}
									</Typography>
								</Alert>
							)}
							<Button
								variant="contained"
								size="large"
								disabled={
									!iterConnessione.progetto_avviabilie?.progetto_avviabilie
								}
								sx={{ width: "100%", mt: 1 }}
								onClick={() => {
									dispatch(avviaProgetto(iterConnessione));
								}}
							>
								Avvia Progetto
							</Button>
						</>
					)}
					{["D"].includes(iterConnessione.stato) && (
						<>
							{!iterConnessione.assegnabile?.assegnabile && (
								<Alert severity="error" sx={{ mt: 1 }}>
									<AlertTitle>Attenzione manca:</AlertTitle>
									<Typography
										variant="body2"
										// component="div"
										// color="text.secondary"
										// sx={{
										// 	color: theme?.palette?.error.main,
										// }}
										sx={{ whiteSpace: "pre-line" }}
									>
										{iterConnessione.assegnabile?.dati_mancanti}
									</Typography>
								</Alert>
							)}
							<Button
								variant="contained"
								size="large"
								disabled={!iterConnessione.assegnabile?.assegnabile}
								sx={{ width: "100%", mt: 1 }}
								onClick={() => {
									dispatch(assegnaPratica(iterConnessione));
								}}
							>
								Assegna Pratica
							</Button>
						</>
					)}
				</Stack>
			</CardContent>
		</Card>
	);
};

export default AzioniGestoreCard;
