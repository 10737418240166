import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { useAppDispatch, RootState } from "../../../../../redux/store";
import {
	ErrorStatusTypes,
	ErrorsStack,
} from "../../../../common/errorsDeclarations";

import { theme } from "../../../../theme";

import {IterConnessione, getIterConnessione,} from "../../iterConnessioneSlice";
import { utenteAutorizzato } from "../../../../authentication/UtenteAutorizzato";
import { UtenteLogged } from "../../../../authentication/authenticationSlice";
import {
	Soggetto,
	saveProduttore,
} from "../../../../anagrafiche/produttori/produttoriSlice";
import { useForm, SubmitHandler, Controller } from "react-hook-form";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Alert from "../../../../common/Alert";
import Typography from "@mui/material/Typography";
import { PermissionTypes } from "../../../../authentication/permissionsGroups";

import { Button, Divider } from "@mui/material";

interface EmailAliasFormProps {
	produttore: Soggetto;
	iterConnessione: IterConnessione;
	consentiModifica: boolean;
}

const EmailAliasForm = ({
	produttore,iterConnessione,
	consentiModifica = false,
}: EmailAliasFormProps) => {
	const {
		handleSubmit,
		register,
		formState: { errors, isDirty, isValid },
		setValue,
		watch,
		getValues,
		control,
		setError,
		clearErrors,
		reset,
	} = useForm<Soggetto>();

	const listaCampi = Object.keys(produttore) as Array<keyof Soggetto>;

	const dispatch = useAppDispatch();

	const [editing, setEditingControlled] = useState(false);

	const setEditing = (switchModifica: boolean) => {
		consentiModifica && setEditingControlled(switchModifica);
	};

	const utente = useSelector<RootState, UtenteLogged>(
		(state) => state.authentication.utenteLogged
	);

	useEffect(() => {
		reset();
		listaCampi.map((field) => {
			setValue(field, produttore[field]);
		});
	}, []);

	useEffect(() => {
		clearErrors();
		listaCampi.forEach((field) => {
			produttore?.errorsStack?.fieldsErrors &&
				produttore.errorsStack.fieldsErrors[field] &&
				setError(field, {
					type: "server",
					message: produttore.errorsStack.fieldsErrors[field].toString(),
				});
		});
	}, [
		produttore?.errorsStack?.fieldsErrors,
		// iterConnessione.allevamento_pack?.errorsStack?.fieldsErrors,
	]);

	useEffect(() => {
		produttore?.errorsStack?.status == ErrorStatusTypes.SUCCESS &&
			setEditing(false);
	}, [produttore, produttore?.errorsStack]);

	const handlerSaveProduttore = (produttore: Soggetto) => {
		consentiModifica &&
			dispatch(saveProduttore({ produttoreToSave: produttore }));
	};

	const onSubmit: SubmitHandler<Soggetto> = (produttore) => {
		handlerSaveProduttore(produttore);
	};

	useEffect(() => {
		iterConnessione.id && dispatch(getIterConnessione({iterConnessioneId:iterConnessione.id}))
	}, [produttore.email_alias]);

	return (
		<Box sx={{ mb: 1 }}>
			{utenteAutorizzato(utente, [
				PermissionTypes.GESTORI_ITER,
				PermissionTypes.OPERATORI,
				PermissionTypes.PROGETTISTI,
			]) && (
				<>
					<form onSubmit={handleSubmit(onSubmit)}>
						{produttore?.errorsStack?.fieldsErrors?.non_field_errors && (
							<Alert severity="error">
								{produttore?.errorsStack?.fieldsErrors?.non_field_errors.toString()}
							</Alert>
						)}

						<Divider
							textAlign="left"
							sx={{ my: 2, color: theme.palette.primary.main }}
						>
							Email alias del produttore
						</Divider>
						<Grid container spacing={2} sx={{ mb: 2 }}>
							<Grid item xs={12} lg={12}>
								<Typography
									variant="caption"
									component="div"
									color="text.secondary"
									gutterBottom
									onClick={() => setEditing(true)}
								>
									email alias*:
								</Typography>
								{!editing ? (
									<Typography
										variant="body1"
										component="div"
										// color="text.secondary"
										gutterBottom
										onClick={() => setEditing(true)}
										sx={{
											color: produttore.email_alias
												? theme.palette.text.secondary
												: theme.palette.divider,
										}}
									>
										{produttore.email_alias || "inserisci email alias"}
									</Typography>
								) : (
									<TextField
										// label="email_alias"
										// disabled={schedaCarico.numero_capi_pianificati}
										variant="outlined"
										InputLabelProps={{
											shrink: true,
										}}
										{...register("email_alias")}
										error={!!errors?.email_alias}
										helperText={errors?.email_alias?.message}
										autoComplete={"off"}
										sx={{ width: "100%" }}
										fullWidth
									/>
								)}
							</Grid>
						</Grid>

						<Button
							// disabled={!isDirty}
							variant="outlined"
							onClick={() => {
								setEditing(false);
								// reset();
							}}
							sx={{ ...(!editing && { display: "none" }) }}
						>
							Annulla
						</Button>

						<Button
							disabled={!isDirty}
							variant="outlined"
							type="submit"
							sx={{ ...(!editing && { display: "none" }), ml: 1 }}
						>
							Salva
						</Button>
					</form>
				</>
			)}
		</Box>
	);
};

export default EmailAliasForm;
