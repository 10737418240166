import React, {useState, useEffect} from "react";
import {RootState, useAppDispatch} from "../../../redux/store";
import {useSelector} from "react-redux";

import {Link} from "react-router-dom";
import {DateTime} from "luxon";

import {Routes_path} from "components/routerApp";
import {
    Utenti,
    Utente,
    fetchUtenti, invitaUtente, deleteUtente,
} from "components/authentication/utenti/utentiSlice";
import {
    PreferenzeLista,
    setPreferenzeListaUtentiCer,
} from "components/userPreference/userPreferenceSlice";

import {theme} from "components/theme";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Grid from "@mui/material/Grid";
import Pagination from "@mui/material/Pagination";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import Select, {SelectChangeEvent} from "@mui/material/Select";
import {Button} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import DeleteIcon from "@mui/icons-material/Delete";


interface UtentiProduttoreListProps {
    // utenti: Utenti;
    produttore: number | undefined;
    // clienti: Cliente[];
    // comunita_energetiche: ComunitaEnergetica[];
}

const UtentiProduttoreList = ({produttore,}: UtentiProduttoreListProps) => {
    const dispatch = useAppDispatch();

    const preferenzeListaUtentiCer = useSelector<
        RootState,
        PreferenzeLista | undefined
    >((state) => state.userPreferenceState.preferenzeListaUtentiCer);

    const [page, setPage] = useState<number>(preferenzeListaUtentiCer?.page || 1);
    const [numeroRecord, setNumeroRecord] = useState<number>(
        preferenzeListaUtentiCer?.numeroRecord || 10
    );
    const [search, setSearch] = useState<string | undefined>(undefined);
    const [refresh, setRefresh] = React.useState<boolean>(false);


    const handlerInvitaUtente = (utente: Utente) => {
        utente?.user?.email && dispatch(invitaUtente(utente));
    };

    const handleChangeNumeroRecord = (event: SelectChangeEvent) => {
        setNumeroRecord(Number(event.target.value));
    };
    const handlerPager = (event: React.ChangeEvent<unknown>, page: number) => {
        setPage(page);
    };

    useEffect(() => {
        dispatch(
            fetchUtenti({
                produttore: produttore,
                numeroRecord: numeroRecord,
                page: page,
                search: search,
            })
        );
        dispatch(
            setPreferenzeListaUtentiCer({
                numeroRecord: numeroRecord,
                page: page,
            })
        );
        setRefresh(false);
    }, [refresh, numeroRecord, page, produttore]);

    const utentiCer: Utenti = useSelector<RootState, Utenti>(
        (state) => state.utentiState.utenti
    );

    return (
        <List>
            <ListItem sx={{backgroundColor: theme.palette.primary.main}}>
                <Grid container>
                    <Grid item xs={4} md={2} lg={3}>
                        <Typography variant="h6" color="secondary" gutterBottom>
                            Utente
                        </Typography>
                    </Grid>
                    <Grid item xs={4} md={2} lg={3}>
                        <Typography variant="h6" color="secondary" gutterBottom>
                            Nome
                        </Typography>
                    </Grid>
                    <Grid item xs={4} md={2} lg={6}>
                        <Typography variant="h6" color="secondary" gutterBottom>
                            Email
                        </Typography>
                    </Grid>
                </Grid>
            </ListItem>
            {utentiCer.results.map((utente, index) => (
                <ListItem key={`utenteProduttore_${utente.id}`}
                          sx={{backgroundColor: index % 2 == 0 ? "#aaaaaa55" : "inherit", color: "#112211dd",}}>
                    <Grid container>
                        <Grid item xs={4} md={2} lg={3}>
                            {`${utente.user?.username} (n.${utente.id})`}
                        </Grid>
                        <Grid item xs={4} md={2} lg={3}>
                            {`${utente.user?.first_name} ${utente.user?.last_name}`}
                        </Grid>
                        <Grid item xs={6} md={4} lg={4}>
                            {utente.user?.email}
                        </Grid>
                        <Grid item xs={6} md={2} lg={2}>

                            <Button
                                variant="contained"
                                onClick={() => handlerInvitaUtente(utente)}
                                endIcon={<SendIcon/>}
                                disabled={!utente.user?.email}
                            >
                                Invita
                            </Button>
                            <Button
                                variant="contained"
                                endIcon={<DeleteIcon/>}
                                onClick={() => {
                                    dispatch(deleteUtente(utente))
                                }}
                            >
                                Cancella
                            </Button>
                        </Grid>
                    </Grid>
                </ListItem>
            ))}
            <ListItem
                sx={{
                    alignContent: "right",
                    width: "100%",
                    mt: 2,
                    borderTopStyle: "solid",
                    borderTopWidth: 1,
                }}
            >
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={2}
                    sx={{width: "100%"}}
                >
                    <Pagination
                        count={utentiCer.num_pages}
                        onChange={handlerPager}
                        page={page}
                    />

                    <Typography noWrap>
                        Totale utenti:{"  "}
                        {utentiCer.count}
                    </Typography>
                    <Box>
                        <TextField
                            id="input-with-icon-textfield"
                            // label="TextField"
                            // InputProps={{
                            // 	startAdornment: (
                            // 		<InputAdornment position="end">
                            // 			<SearchIcon />
                            // 		</InputAdornment>
                            // 	),
                            // }}
                            variant="standard"
                            value={search}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setSearch(event.target.value);
                            }}
                        />
                        <IconButton onClick={() => setRefresh(true)}>
                            <SearchIcon/>
                        </IconButton>
                    </Box>

                    <FormControl>
                        <InputLabel id="demo-simple-select-label">Record</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={numeroRecord.toString()}
                            label="Age"
                            onChange={handleChangeNumeroRecord}
                            variant="outlined"
                        >
                            <MenuItem value={10}>10</MenuItem>
                            <MenuItem value={20}>20</MenuItem>
                            <MenuItem value={50}>50</MenuItem>
                        </Select>
                    </FormControl>
                </Stack>
            </ListItem>
        </List>
    );
};

export default UtentiProduttoreList;
