import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {useAppDispatch, RootState} from "../../../../redux/store";
import {
    ErrorStatusTypes,
    ErrorsStack,
} from "../../../common/errorsDeclarations";
import {Comune} from "../../../anagrafiche/comuni/comuniSlice";

import {theme} from "../../../theme";
import {convertToDateTime} from "components/common/dateUtils/convertToDateTime";
import {AdapterLuxon} from "@mui/x-date-pickers/AdapterLuxon";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {DateTime} from "luxon";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";

import IconButton from "@mui/material/IconButton";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";

import {
    UtenteLogged,
    UtenteApplicativo,
} from "../../../authentication/authenticationSlice";
import {
    Commessa,
    saveCommessa,
    StatoCommessaTypes,
    TipoIntervento,
} from "../commesseSlice";

import ReactHookFormSelect from "../../../common/ReactHookFormSelect";
import {
    Checkbox,
    CircularProgress,
    FormControlLabel,
    FormHelperText,
} from "@mui/material";
import {useForm, SubmitHandler, Controller} from "react-hook-form";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import {Card, CardHeader, CardContent, Paper} from "@mui/material";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import {Button, Divider} from "@mui/material";
import {DesktopDatePicker} from "@mui/x-date-pickers/DesktopDatePicker";
import {Alert} from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

interface CommessaFormProps {
    commessa: Commessa;
    consentiModifica: boolean;
}

const CommessaForm = ({
                          commessa,
                          consentiModifica = false,
                      }: CommessaFormProps) => {
    const {
        handleSubmit,
        register,
        formState: {errors, isDirty, isValid},
        setValue,
        watch,
        getValues,
        control,
        setError,
        clearErrors,
        reset,
    } = useForm<Commessa>();

    const listaCampi = Object.keys(commessa) as Array<keyof Commessa>;

    const dispatch = useAppDispatch();

    const [editing, setEditingControlled] = useState(false);
    const setEditing = (switchModifica: boolean) => {
        consentiModifica && setEditingControlled(switchModifica);
    };

    const utente = useSelector<RootState, UtenteLogged>(
        (state) => state.authentication.utenteLogged
    );

    const commesseStatus = useSelector<RootState, ErrorStatusTypes>(
        (state) => state.commesseState.commesse.errorsStack.status
    );

    const utenteApplicativo = useSelector<
        RootState,
        UtenteApplicativo | undefined
    >((state) =>
        state.authentication.utentiApplicativo.results.find(
            (u) => u.id == utente.id
        )
    );
    const utentiApplicativo = useSelector<RootState, UtenteApplicativo[]>(
        (state) =>
            state.authentication.utentiApplicativo.results.filter(
                (u) =>
                    u.cliente == utenteApplicativo?.cliente || utenteApplicativo?.interno
            )
    );

    const tipiIntervento = useSelector<RootState, TipoIntervento[]>(
        (state) => state.commesseState.tipiIntervento.results
    );

    const [annulla, setAnnulla] = useState<boolean>(false);

    useEffect(() => {
        reset(commessa);
        // listaCampi.map((field) => {
        // 	setValue(field, commessa[field]);
        // });
        // setAnnulla(false);
    }, [commessa, reset]);

    useEffect(() => {
        clearErrors();
        listaCampi.forEach((field) => {
            commessa?.errorsStack?.fieldsErrors &&
            commessa.errorsStack.fieldsErrors[field] &&
            setError(field, {
                type: "server",
                message: commessa.errorsStack.fieldsErrors[field].toString(),
            });
        });
    }, [
        commessa?.errorsStack?.fieldsErrors,
        // cliente.allevamento_pack?.errorsStack?.fieldsErrors,
    ]);

    const handlerSaveCommessa = (commessa: Commessa) => {
        consentiModifica &&
        dispatch(
            saveCommessa({
                commessaToSave: commessa,
            })
        );
    };
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const onSubmit: SubmitHandler<Commessa> = (commessa) => {
        handlerSaveCommessa(commessa);
    };

    const handlerDetailChaingSubmit = () => {
        if (isValid && isDirty) {
            const formValues = getValues();
            handlerSaveCommessa(formValues);
        }
    };

    useEffect(() => {
        if (isValid && isDirty) {
            const formValues = getValues();
            handlerSaveCommessa(formValues);
        }
    }, [
        watch("referente"),
        watch("stato"),
        watch("data_conclusione"),
        watch("data_avvio"),
        watch("tipi_intervento"),
        watch("pratica_conto_terzi"),
    ]);

    return (
        <Box sx={{mb: 1}}>
            <form onSubmit={handleSubmit(onSubmit)}>
                {commessa?.errorsStack?.fieldsErrors?.non_field_errors && (
                    <Alert severity="error">
                        {commessa?.errorsStack?.fieldsErrors?.non_field_errors.toString()}
                    </Alert>
                )}
                <Grid container spacing={1}>
                    <Grid item sm={12} lg={7}>
                        <Grid container  spacing={1}>
                            <Grid item xs={12} sm={6} lg={5}>
                                {tipiIntervento.length > 0 && (
                                    <ReactHookFormSelect
                                        name="tipi_intervento"
                                        label="tipo di intervento"
                                        register={register}
                                        disabled={!consentiModifica }
                                        control={control}
                                        watch={watch}
                                        variant="outlined"
                                        multiple={true}
                                        error={errors?.tipi_intervento?.message}
                                        sx={{width: "100%", mb: 1}}
                                    >
                                        {tipiIntervento.map((tipo) => {
                                            return (
                                                <MenuItem key={tipo.id} value={tipo.id}>
                                                    {tipo.descrizione}
                                                </MenuItem>
                                            );
                                        })}
                                    </ReactHookFormSelect>
                                )}
                            </Grid>
                            <Grid item xs={12} sm={6} lg={4}>
                                <ReactHookFormSelect
                                    name="stato"
                                    label="stato"
                                    register={register}
                                    disabled={!consentiModifica}
                                    control={control}
                                    watch={watch}
                                    variant="outlined"
                                    error={errors?.stato?.message}
                                    sx={{width: "100%", mb: 1}}
                                >
                                    {Object.entries(StatoCommessaTypes).map(([key, value]) => {
                                        return (
                                            <MenuItem key={key} value={key}>
                                                {value}
                                            </MenuItem>
                                        );
                                    })}
                                </ReactHookFormSelect>
                            </Grid>
                            <Grid item xs={12} sm={6} lg={3}>
                                <DesktopDatePicker
                                    label="data avvio"
                                    // components={{
                                    // 	OpenPickerIcon: ClockIcon,
                                    // }}
                                    // inputFormat="DD/MM/YY HH:mm"
                                    // mask={"__/__/__ __:__"}
                                    // label="Data Progetto"
                                    // {...register("data_ora_carico_pianificata")}
                                    value={convertToDateTime(commessa.data_avvio)}
                                    disabled={!consentiModifica}

                                    slotProps={{
                                        textField: {
                                            required: false,
                                            error: !!errors?.data_avvio,
                                            helperText: errors?.data_avvio?.message,
                                            sx: { width: "100%" },
                                            autoComplete: "off",
                                            // disabled: !!richiestaConnessione.stato
                                        },
                                        field: {
                                            clearable: true,
                                        },
                                    }}
                                    onChange={(newValue: any) => {
                                        setEditing(true);
                                        setValue(
                                            "data_avvio",
                                            newValue ? convertToDateTime(newValue)?.toFormat("yyyy-MM-dd") : "",
                                            {
                                                shouldDirty: true,
                                            }
                                        );
                                        clearErrors("data_avvio");
                                    }}
                                />
                            </Grid>



                            <Grid item xs={12} sm={6} lg={5}>
                                <TextField
                                    id="codice_commessa_legacy"
                                    label="codice commessa legacy"
                                    {...register("codice_commessa_legacy")}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    sx={{mb: 2}}
                                    disabled={!consentiModifica}
                                    onBlur={handlerDetailChaingSubmit}
                                    variant="outlined"
                                    fullWidth
                                    type="text"
                                    autoComplete={"off"}
                                    error={!!errors?.codice_commessa_legacy}
                                    helperText={errors?.codice_commessa_legacy?.message}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} lg={4}>
                                {utentiApplicativo.length > 0 && (
                                    <ReactHookFormSelect
                                        name="referente"
                                        label="referente"
                                        register={register}
                                        control={control}
                                        disabled={!consentiModifica}
                                        watch={watch}
                                        variant="outlined"
                                        error={errors?.referente?.message}
                                        sx={{width: "100%", mb: 1}}
                                    >
                                        <MenuItem value="">
                                            <em>Nessuno</em>
                                        </MenuItem>
                                        {utentiApplicativo
                                            .filter((ut) => ut.cliente == commessa.cliente)
                                            .map((operatore) => {
                                                return (
                                                    <MenuItem key={operatore.id} value={operatore.id}>
                                                        {operatore.user.full_name}
                                                    </MenuItem>
                                                );
                                            })}
                                    </ReactHookFormSelect>
                                )}
                            </Grid>
                            <Grid item xs={12} sm={6} lg={3}>
                                <DesktopDatePicker
                                    label="data conclusione"
                                    // components={{
                                    // 	OpenPickerIcon: ClockIcon,
                                    // }}
                                    // inputFormat="DD/MM/YY HH:mm"
                                    // mask={"__/__/__ __:__"}
                                    // label="Data Progetto"
                                    // {...register("data_ora_carico_pianificata")}
                                    value={convertToDateTime(commessa.data_conclusione)}
                                    disabled={!consentiModifica}

                                    slotProps={{
                                        textField: {
                                            required: false,
                                            error: !!errors?.data_conclusione,
                                            helperText: errors?.data_conclusione?.message,
                                            sx: { width: "100%" },
                                            autoComplete: "off",
                                            // disabled: !!richiestaConnessione.stato
                                        },
                                        field: {
                                            clearable: true,
                                        },
                                    }}
                                    onChange={(newValue: any) => {
                                        setEditing(true);
                                        setValue(
                                            "data_conclusione",
                                            newValue ? convertToDateTime(newValue)?.toFormat("yyyy-MM-dd") : "",
                                            {
                                                shouldDirty: true,
                                            }
                                        );
                                        clearErrors("data_conclusione");
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item sm={12} lg={5}>
                        <Grid container>
                            <Grid item sm={12} lg={12}>

                                <TextField
                                    label="note"
                                    // disabled={!iterConnessione.codice_pod}
                                    variant="outlined"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    multiline={true}
                                    rows={3}
                                    {...register("note")}
                                    onBlur={handlerDetailChaingSubmit}
                                    disabled={!consentiModifica}
                                    error={!!errors?.note}
                                    helperText={errors?.note?.message}
                                    autoComplete={"off"}
                                    sx={{
                                        width: "100%",
                                    }}
                                    fullWidth
                                    onClick={() => setEditing(true)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                <FormControlLabel
                                    value={watch("pratica_conto_terzi") || commessa?.pratica_conto_terzi}
                                    checked={watch("pratica_conto_terzi") || false}
                                    control={<Checkbox/>}
                                    label="pratica conto terzi"
                                    labelPlacement="start"
                                    onChange={(e, checked) => {
                                        setValue("pratica_conto_terzi", checked, {
                                            shouldDirty: true,
                                        });
                                    }}
                                    disabled={!consentiModifica}
                                    style={{padding: 0, fontSize: "0.4rem"}}
                                />
                                {!!errors?.pratica_conto_terzi && (
                                    <FormHelperText error>
                                        {errors?.pratica_conto_terzi.toString()}
                                    </FormHelperText>
                                )}
                            </Grid>

                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                <Box>
                                    {(isDirty ||
                                        commesseStatus == ErrorStatusTypes.SUCCESS ||
                                        commesseStatus == ErrorStatusTypes.ERROR) && (
                                        <Typography
                                            sx={{
                                                color: isDirty
                                                    ? theme.palette.warning.main
                                                    : commesseStatus == ErrorStatusTypes.SUCCESS
                                                        ? theme.palette.success.main
                                                        : commesseStatus == ErrorStatusTypes.ERROR
                                                            ? theme.palette.error.main
                                                            : theme.palette.warning.main,
                                            }}
                                            variant="caption"
                                        >
                                            {commesseStatus == ErrorStatusTypes.PENDING && (
                                                <CircularProgress size={5}/>
                                            )}

                                            {isDirty
                                                ? "Modifiche non salvate"
                                                : commesseStatus == ErrorStatusTypes.SUCCESS
                                                    ? "Salvato"
                                                    : commesseStatus == ErrorStatusTypes.ERROR
                                                        ? "Errore"
                                                        : ""}
                                        </Typography>
                                    )}
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>

                </Grid>

                {/* <Button
					// disabled={!isDirty}
					variant="outlined"
					onClick={() => {
						// setEditing(false);
						setAnnulla(true);
					}}
					sx={{ mt: 2 }}
					// sx={{ ...(!editing && { display: "none" }), mt: 2 }}
				>
					Annulla
				</Button>
				<Button
					disabled={!isDirty}
					variant="outlined"
					type="submit"
					sx={{ ml: 1, mt: 2 }}
					// sx={{ ...(!editing && { display: "none" }), mt: 2 }}
				>
					Salva
				</Button> */
                }
            </form>
        </Box>
    )
        ;
};

export default CommessaForm;
