import React, { useState, useEffect } from "react";

import {
	IterConnessione,
	richiestaPresaInCarico,
	deleteIterConnessione,
} from "components/main/iterConnessioni/iterConnessioneSlice";
import { DateTime } from "luxon";

import { RootState, useAppDispatch } from "../../../../../redux/store";

import { useNavigate } from "react-router-dom";
import { Routes_path } from "../../../../routerApp";

import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import ConfermaAnnulla from "../../../../common/ConfermaAnnulla";

import { Typography } from "@mui/material";

interface AzioniClienteCardProps {
	iterConnessione: IterConnessione;
}

const AzioniClienteCard = ({ iterConnessione }: AzioniClienteCardProps) => {
	
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	// gestione chiusura modal su scelta "Annulla"
	const [openConferma, setOpenConferma] = useState(false);
	const handleConfermaClose = () => setOpenConferma(false);
	// gestione chiusura modal su scelta "Annulla"
	const [openConfermaSopralluogo, setOpenConfermaSopralluogo] = useState(false);
	const handleConfermaSopralluogoClose = () =>
		setOpenConfermaSopralluogo(false);

	return (
		<Card sx={{ mt: 2, backgroundColor: "#ddd" }}>
			{["A", "B"].includes(iterConnessione.stato) && (
				<CardContent>
					<Stack sx={{ width: "100%" }} spacing={2}>
						{!iterConnessione.dati_pod_completo?.dati_pod_completo && (
							<Alert severity="error" sx={{ mt: 1 }}>
								<AlertTitle>Attenzione manca:</AlertTitle>
								<Typography
									variant="body2"
									// component="div"
									// color="text.secondary"
									// sx={{
									// 	color: theme?.palette?.error.main,
									// }}
									sx={{ whiteSpace: "pre-line" }}
								>
									{iterConnessione.dati_pod_completo?.dati_mancanti}
								</Typography>
							</Alert>
						)}
					</Stack>
				</CardContent>
			)}
			{["A", "B", "C"].includes(iterConnessione.stato) && (
				<CardContent>
					<Button
						color="secondary"
						variant="contained"
						size="large"
						sx={{ width: "100%" }}
						onClick={() => setOpenConferma(true)}
					>
						Cancella Iter Connessione
					</Button>
					<ConfermaAnnulla
						handleAnnulla={handleConfermaClose}
						handleConferma={() => {
							dispatch(deleteIterConnessione(iterConnessione));
							navigate(`${Routes_path.ITER_CONNESSIONI}`, {
								replace: true,
							});
						}}
						domanda={"Sicuro di voler cancellare la richiesta"}
						open={openConferma}
					/>
				</CardContent>
			)}
		</Card>
	);
};

export default AzioniClienteCard;
