import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch, RootState } from "../../../../../../redux/store";
import { ErrorStatusTypes } from "../../../../../common/errorsDeclarations";

import { theme } from "../../../../../theme";
import { convertToDateTime } from "components/common/dateUtils/convertToDateTime";

import {
	UtenteLogged,
	UtenteApplicativo,
} from "../../../../../authentication/authenticationSlice";
import {
	Commessa,
	saveCommessa,
	StatoCommessaTypes,
	TipoIntervento,
} from "../../../commesseSlice";

import ReactHookFormSelect from "../../../../../common/ReactHookFormSelect";
import {
	Checkbox,
	CircularProgress,
	FormControlLabel,
	FormHelperText,
} from "@mui/material";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Card, CardHeader, CardContent, Paper } from "@mui/material";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { Button, Divider } from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { Alert } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Switch from "@mui/material/Switch";

interface PianificazioneCantiereFormProps {
	commessa: Commessa;
	consentiModifica: boolean;
}

const PianificazioneCantiereForm = ({
	commessa,
	consentiModifica = false,
}: PianificazioneCantiereFormProps) => {
	const {
		handleSubmit,
		register,
		formState: { errors, isDirty, isValid },
		setValue,
		watch,
		getValues,
		control,
		setError,
		clearErrors,
		reset,
	} = useForm<Commessa>();

	const listaCampi = Object.keys(commessa) as Array<keyof Commessa>;

	const dispatch = useAppDispatch();

	const [editing, setEditingControlled] = useState(false);
	const setEditing = (switchModifica: boolean) => {
		consentiModifica && setEditingControlled(switchModifica);
	};

	const utente = useSelector<RootState, UtenteLogged>(
		(state) => state.authentication.utenteLogged
	);

	const commesseStatus = useSelector<RootState, ErrorStatusTypes>(
		(state) => state.commesseState.commesse.errorsStack.status
	);

	useEffect(() => {
		reset(commessa);
	}, [commessa, reset]);

	useEffect(() => {
		clearErrors();
		listaCampi.forEach((field) => {
			commessa?.errorsStack?.fieldsErrors &&
				commessa.errorsStack.fieldsErrors[field] &&
				setError(field, {
					type: "server",
					message: commessa.errorsStack.fieldsErrors[field].toString(),
				});
		});
	}, [
		commessa?.errorsStack?.fieldsErrors,
		// cliente.allevamento_pack?.errorsStack?.fieldsErrors,
	]);

	const handlerSaveCommessa = (commessa: Commessa) => {
		consentiModifica &&
			dispatch(
				saveCommessa({
					commessaToSave: commessa,
				})
			);
	};

	const onSubmit: SubmitHandler<Commessa> = (commessa) => {
		handlerSaveCommessa(commessa);
	};

	useEffect(() => {
		if (isValid && isDirty) {
			const formValues = getValues();
			handlerSaveCommessa(formValues);
		}
	}, [watch("data_cantiere_pianificato"), watch("data_cantiere_confermata")]);

	return (
		<Box sx={{ mb: 1 }}>
			{commesseStatus == ErrorStatusTypes.PENDING && consentiModifica ? (
				<CircularProgress size={100} color="info" />
			) : (
				<form onSubmit={handleSubmit(onSubmit)}>
					{commessa?.errorsStack?.fieldsErrors?.non_field_errors && (
						<Alert severity="error">
							{commessa?.errorsStack?.fieldsErrors?.non_field_errors.toString()}
						</Alert>
					)}
					<Grid container spacing={2} width={"100%"} sx={{ mb: 1 }}>
						<Grid item xs={12} sm={6} lg={2}>
							<DesktopDatePicker
								label="data cantier prevista"
								value={convertToDateTime(commessa.data_cantiere_pianificato)}
								disabled={!consentiModifica}
								slotProps={{
									textField: {
										required: false,
										error: !!errors?.data_cantiere_pianificato,
										helperText: errors?.data_cantiere_pianificato?.message,
										sx: { width: "100%" },
										autoComplete: "off",
										// disabled: !!richiestaConnessione.stato
									},
									field: {
										clearable: true,
									},
								}}
								onChange={(newValue: any) => {
									setEditing(true);
									setValue(
										"data_cantiere_pianificato",
										newValue
											? convertToDateTime(newValue)?.toFormat("yyyy-MM-dd")
											: "",
										{
											shouldDirty: true,
										}
									);
									clearErrors("data_cantiere_pianificato");
								}}
							/>
						</Grid>
						<Grid item xs={6} sm={4} md={3} lg={2}>
							<Typography variant="h6">
								la data cantiere è stata confermata?
							</Typography>
							<FormControlLabel
								label={
									<Typography fontSize="0.8rem" marginLeft="0.1rem">
										{commessa.data_cantiere_confermata ? "SI" : "NO"}
									</Typography>
								}
								control={
									<Switch
										id="data_cantiere_confermata"
										checked={commessa.data_cantiere_confermata || false}
										onChange={(e, checked) => {
											setValue("data_cantiere_confermata", checked, {
												shouldDirty: true,
											});
										}}
										color="info"
										size="small"
									/>
								}
							/>
							{!!errors?.data_cantiere_confermata && (
								<FormHelperText error>
									{errors?.data_cantiere_confermata?.toString()}
								</FormHelperText>
							)}
						</Grid>
						<Grid item sm={12} lg={3}>
							<Box>
								{(isDirty ||
									commesseStatus == ErrorStatusTypes.SUCCESS ||
									commesseStatus == ErrorStatusTypes.ERROR) && (
									<Typography
										sx={{
											color: isDirty
												? theme.palette.warning.main
												: commesseStatus == ErrorStatusTypes.SUCCESS
												? theme.palette.success.main
												: commesseStatus == ErrorStatusTypes.ERROR
												? theme.palette.error.main
												: theme.palette.warning.main,
										}}
										variant="caption"
									>
										{commesseStatus == ErrorStatusTypes.PENDING && (
											<CircularProgress size={5} />
										)}

										{isDirty
											? "Modifiche non salvate"
											: commesseStatus == ErrorStatusTypes.SUCCESS
											? "Salvato"
											: commesseStatus == ErrorStatusTypes.ERROR
											? "Errore"
											: ""}
									</Typography>
								)}
							</Box>
						</Grid>
						<Grid item sm={12} lg={3}></Grid>
						<Grid item sm={12} lg={2}>
							{commessa.stato == "E" && (
								<Button
									variant="contained"
									color="primary"
									disabled={!consentiModifica || !commessa.stato_completato}
									sx={{ width: "100%" }}
									onClick={() => {
										dispatch(
											saveCommessa({
												commessaToSave: commessa,
												azione: "attiva_fatturazione_amp",
											})
										);
									}}
								>
									Attiva fatturazione AMP & avvisa amministrazione
								</Button>
							)}
						</Grid>
					</Grid>
				</form>
			)}
		</Box>
	);
};

export default PianificazioneCantiereForm;
