import { handleResponse, handleError, headerset } from "../apiUtils";
import { DateTime } from "luxon";
import { convertToDateTime } from "components/common/dateUtils/convertToDateTime";
import {
	Commessa,
	Prodotto,
	PodCommessa,
} from "components/main/commesse/commesseSlice";

const baseUrl = (commessa_id?: number | null, azione?: string) => {
	if (commessa_id) {
		let url = `${process.env.API_URL}/api/commesse/${commessa_id}/`;
		if (!!azione) {
			url = url + `?azione=${azione}`;
		}
		return url;
	} else {
		return process.env.API_URL + "/api/commesse/";
	}
};

export function fetchTipoIntervento(
	numeroRecord?: number,
	page?: number,
	search?: string
) {
	let url = `${process.env.API_URL}/api/commesse/tipi_intervento/`;
	if (numeroRecord != undefined || search != undefined || page != undefined) {
		url += "?";
	}
	if (numeroRecord != undefined) {
		url += `numero_record=${numeroRecord}&`;
	}
	if (page != undefined) {
		url += `page=${page}&`;
	}
	if (search != undefined) {
		url += `search=${search}`;
	}
	return fetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}

export function fetchCommesse(
	statoCommessa?: string,
	cliente?: number,
	numeroRecord?: number,
	page?: number,
	search?: string
) {
	let url = baseUrl();
	if (
		statoCommessa != undefined ||
		cliente != undefined ||
		numeroRecord != undefined ||
		search != undefined ||
		page != undefined
	) {
		url += "?";
	}
	if (statoCommessa != undefined) {
		url += `stato_commessa=${statoCommessa}&`;
	}
	if (cliente != undefined) {
		url += `cliente=${cliente}&`;
	}
	if (numeroRecord != undefined) {
		url += `numero_record=${numeroRecord}&`;
	}
	if (page != undefined) {
		url += `page=${page}&`;
	}
	if (search != undefined) {
		url += `search=${search}`;
	}
	return fetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}

export function getCommessa(commessaId: number) {
	let url = baseUrl(commessaId);
	return fetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}

export function saveCommessa(commessaToSave: Commessa, azione?: string) {
	const data_avvio = commessaToSave.data_avvio
		? convertToDateTime(commessaToSave.data_avvio)?.setLocale("it").toFormat("yyyy-MM-dd")
		: null;
	const data_conclusione = commessaToSave.data_conclusione
		? convertToDateTime(commessaToSave.data_conclusione)?.setLocale("it").toFormat("yyyy-MM-dd")
		: null;
	const data_pronta_per_acconto = commessaToSave.data_pronta_per_acconto
		? convertToDateTime(commessaToSave.data_pronta_per_acconto)?.setLocale("it").toFormat("yyyy-MM-dd")
		: null;
	const data_fattura_acconto_inviata =
		commessaToSave.data_fattura_acconto_inviata
			? convertToDateTime(commessaToSave.data_fattura_acconto_inviata)?.setLocale("it").toFormat("yyyy-MM-dd")
			: null;
	const data_fattura_acconto_pagata = commessaToSave.data_fattura_acconto_pagata
		? convertToDateTime(commessaToSave.data_fattura_acconto_pagata)?.setLocale("it").toFormat("yyyy-MM-dd")
		: null;

	const data_cantiere_pianificato = commessaToSave.data_cantiere_pianificato
		? convertToDateTime(commessaToSave.data_cantiere_pianificato)?.setLocale("it").toFormat("yyyy-MM-dd")
		: null;
	const data_pronta_per_fattura_amp = commessaToSave.data_pronta_per_fattura_amp
		? convertToDateTime(commessaToSave.data_pronta_per_fattura_amp)?.setLocale("it").toFormat("yyyy-MM-dd")
		: null;
	const data_fattura_amp = commessaToSave.data_fattura_amp
		? convertToDateTime(commessaToSave.data_fattura_amp)?.setLocale("it").toFormat("yyyy-MM-dd")
		: null;
	const data_fattura_amp_pagata = commessaToSave.data_fattura_amp_pagata
		? convertToDateTime(commessaToSave.data_fattura_amp_pagata)?.setLocale("it").toFormat("yyyy-MM-dd")
		: null;
	const data_avvio_installazione = commessaToSave.data_avvio_installazione
		? convertToDateTime(commessaToSave.data_avvio_installazione)?.setLocale("it").toFormat("yyyy-MM-dd")
		: null;
	const data_fine_installazione = commessaToSave.data_fine_installazione
		? convertToDateTime(commessaToSave.data_fine_installazione)?.setLocale("it").toFormat("yyyy-MM-dd")
		: null;
	const data_pronta_per_fattura_saldo =
		commessaToSave.data_pronta_per_fattura_saldo
			? convertToDateTime(commessaToSave.data_pronta_per_fattura_saldo)?.setLocale("it").toFormat("yyyy-MM-dd") : null;
	const data_fattura_saldo = commessaToSave.data_fattura_saldo
		? convertToDateTime(commessaToSave.data_fattura_saldo)?.setLocale("it").toFormat("yyyy-MM-dd")
		: null;
	const data_fattura_saldo_pagata = commessaToSave.data_fattura_saldo_pagata
		? convertToDateTime(commessaToSave.data_fattura_saldo_pagata)?.setLocale("it").toFormat("yyyy-MM-dd")
		: null;

	const commessa = {
		...commessaToSave,
		data_avvio: data_avvio,
		data_conclusione: data_conclusione,
		data_pronta_per_acconto: data_pronta_per_acconto,
		data_fattura_acconto_inviata: data_fattura_acconto_inviata,
		data_fattura_acconto_pagata: data_fattura_acconto_pagata,
		data_cantiere_pianificato: data_cantiere_pianificato,
		data_pronta_per_fattura_amp: data_pronta_per_fattura_amp,
		data_fattura_amp: data_fattura_amp,
		data_fattura_amp_pagata: data_fattura_amp_pagata,
		data_avvio_installazione: data_avvio_installazione,
		data_fine_installazione: data_fine_installazione,
		data_pronta_per_fattura_saldo: data_pronta_per_fattura_saldo,
		data_fattura_saldo: data_fattura_saldo,
		data_fattura_saldo_pagata: data_fattura_saldo_pagata,
	};
	return fetch(baseUrl(commessaToSave.id, azione), {
		method: commessaToSave.id ? "PUT" : "POST", // PUT to update when id already exists.
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
		body: JSON.stringify({
			...commessa,
			// Parse authorId to a number (in case it was sent as a string).
			// id: parseInt(progetto.id, 10),
		}),
	})
		.then(handleResponse)
		.catch(handleError);
}

export function deleteCommessa(commessaToDelete: Commessa) {
	return fetch(baseUrl(commessaToDelete.id), {
		method: "DELETE",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}

export function savePodCommessa(
	commessa: Commessa,
	podCommessaToSave: PodCommessa,
	azione?: string
) {
	let url = `${process.env.API_URL}/api/commesse/${commessa.id}/pod_commessa/`;
	if (!!azione) {
		url = url + `?azione=${azione}`;
	}
	return fetch(url, {
		method: "PUT",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
		body: JSON.stringify({
			...podCommessaToSave,
		}),
	})
		.then(handleResponse)
		.catch(handleError);
}

export function creaIterCommessa(commessa: Commessa, tipo_servizio?: string) {
	let url = `${process.env.API_URL}/api/commesse/${commessa.id}/crea_iter_commessa/`;
	if (!!tipo_servizio) {
		url = url + `?tipo_servizio=${tipo_servizio}`;
	}
	return fetch(url, {
		method: "PUT",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}

export function aggiungiProdotto(
	prodottoToSave: Prodotto,
	pod_commessa: number
) {
	const url =
		process.env.API_URL +
		"/api/commesse/pod_commessa/" +
		pod_commessa +
		"/" +
		"aggiungi_prodotto/";

	return fetch(url, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},

		body: JSON.stringify({
			...prodottoToSave,
			// tipo_prodotto: codiceTipoProdotto,
			// Parse authorId to a number (in case it was sent as a string).
			// id: parseInt(progetto.id, 10),
		}),
	})
		.then(handleResponse)
		.catch(handleError);
}

export function saveProdotto(prodottoToSave: Prodotto) {
	const url =
		process.env.API_URL + "/api/commesse/prodotto/" + prodottoToSave.id + "/";
	return fetch(url, {
		method: "PUT",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
		body: JSON.stringify({
			...prodottoToSave,
		}),
	})
		.then(handleResponse)
		.catch(handleError);
}

export function deleteProdotto(prodottoToDelete: Prodotto) {
	const url =
		process.env.API_URL + "/api/commesse/prodotto/" + prodottoToDelete.id + "/";
	return fetch(url, {
		method: "DELETE",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}

export function fetchDocumentiCommessePerFase(
	commessa_id: number,
	fase_commessa: string
) {
	const url = `${process.env.API_URL}/api/commesse/${commessa_id}/allegati_fase_commessa/${fase_commessa}/`;
	return fetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}
