import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {useLocation, Link} from "react-router-dom";

import {RootState, useAppDispatch} from "../../../redux/store";
import {Routes_path} from "../../routerApp";
import {fetchClienti} from "../clienti/clientiSlice";
import {EsponiStatoSintetico} from "components/common/EsponiStatoSintetico";

import {convertToDateTime} from "components/common/dateUtils/convertToDateTime";

import {utenteAutorizzato} from "../../authentication/UtenteAutorizzato";
import {UtenteLogged} from "../../authentication/authenticationSlice";
import {IterConnessioneLight} from "./produttoriSlice";
import {TipoPratica} from "components/anagrafiche/tipiPratica/tipiPraticaSlice";
import {PermissionTypes} from "../../authentication/permissionsGroups";

import IterConnessioneDocumentiTab
    from "../../main/iterConnessioni/iterConnessione/documenti/IterConnessioneDocumentiTab";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import IconButton from "@mui/material/IconButton";
import {theme} from "../../theme";

interface IterConnessioniProduttoreGridProps {
    iterConnessioni: IterConnessioneLight[];
}

const IterConnessioniProduttoreGrid = ({
                                           iterConnessioni,
                                       }: IterConnessioniProduttoreGridProps) => {

    const dispatch = useAppDispatch();

    const utente = useSelector<RootState, UtenteLogged>(
        (state) => state.authentication.utenteLogged
    );

    const tipiPratica = useSelector<RootState, TipoPratica[]>(
        (state) => state.tipiPraticaState.tipiPratica.results
    );

    const [iterConnessioneScelta_id, setIterConnessioneScelta_id] = useState<
        number | undefined
    >(undefined);
    const [refresh, setRefresh] = useState<boolean>(false);

    useEffect(() => {
        setRefresh(false);
    }, [refresh]);

    useEffect(() => {
        dispatch(fetchClienti({}));
    }, []);

    return (
        <Paper elevation={3} sx={{p: 3}}>
            <Typography gutterBottom variant="h5">
                IterConnessioni
            </Typography>
            <Grid container spacing={0.5}>
                <Grid item xs={12} sm={12} md={12} lg={2}>
                    <Typography
                        variant="subtitle1"
                        // color="text.secondary"
                        gutterBottom
                        // onClick={() => setEditing(true)}
                        sx={{
                            color: theme.palette.text.secondary,
                        }}
                    >
                        Commessa
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={2}>
                    <Typography
                        variant="subtitle1"
                        // color="text.secondary"
                        gutterBottom
                        // onClick={() => setEditing(true)}
                        sx={{
                            color: theme.palette.text.secondary,
                        }}
                    >
                        Pod
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={1}>
                    <Typography
                        variant="subtitle1"
                        // color="text.secondary"
                        gutterBottom
                        // onClick={() => setEditing(true)}
                        sx={{
                            color: theme.palette.text.secondary,
                        }}
                    >
                        Stato
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={1}>
                    <Typography
                        variant="subtitle1"
                        // color="text.secondary"
                        gutterBottom
                        // onClick={() => setEditing(true)}
                        sx={{
                            color: theme.palette.text.secondary,
                        }}
                    >
                        Data
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={2}>
                    <Typography
                        variant="subtitle1"
                        // color="text.secondary"
                        gutterBottom
                        // onClick={() => setEditing(true)}
                        sx={{
                            color: theme.palette.text.secondary,
                        }}
                    >
                        Tipo pratica
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={3}>
                    <Typography
                        variant="subtitle1"
                        // color="text.secondary"
                        gutterBottom
                        // onClick={() => setEditing(true)}
                        sx={{
                            color: theme.palette.text.secondary,
                        }}
                    >
                        Indirizzo
                    </Typography>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={1}>
                    <Typography
                        variant="subtitle1"
                        // color="text.secondary"
                        gutterBottom
                        // onClick={() => setEditing(true)}
                        sx={{
                            color: theme.palette.text.secondary,
                        }}
                    >
                        Docum.
                    </Typography>
                </Grid>
            </Grid>
            {iterConnessioni.map((iterConnessione: IterConnessioneLight, index) => (
                <Grid
                    container
                    spacing={0.5}
                    key={index}
                    sx={{backgroundColor: index % 2 === 0 ? "#eeeeee" : "inherit"}}
                >
                    <Grid item xs={12} sm={12} md={12} lg={2}>
                        <IconButton
                            key={iterConnessione.commessa}
                            aria-label="vai a commessa"
                            size="small"
                            component={Link}
                            to={{
                                pathname:
                                    Routes_path.ITER_CONNESSIONE + iterConnessione.id
                            }}
                            // state={{
                            // 	lavorazine_id: params.value,
                            // }}
                        >
                            <Typography
                                variant="body1"

                                // color="text.secondary"
                                // onClick={() => setEditing(true)}
                            >
                                {iterConnessione.codice_pratica}
                            </Typography>
                        </IconButton>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={2}>
                        <Typography
                            variant="body1"
                            // sx={{ fontSize: "0.8rem" }}
                            // color="text.secondary"
                            // onClick={() => setEditing(true)}
                        >
                            {iterConnessione.codice_pod}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={1}>
                        <Typography
                            variant="body1"
                            // color="text.secondary"
                            // onClick={() => setEditing(true)}
                        >
                            {EsponiStatoSintetico(iterConnessione.stato)}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={1}>
                        <Typography
                            variant="body1"
                            // color="text.secondary"
                            // onClick={() => setEditing(true)}
                        >
                            {iterConnessione.data_ultimo_stato
                                ? convertToDateTime(iterConnessione.data_ultimo_stato)?.setLocale("it").toFormat("dd/MM/yyyy")
                                : ""}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={2}>
                        <Typography
                            variant="body1"
                            // color="text.secondary"
                            // onClick={() => setEditing(true)}
                        >
                            {
                                tipiPratica.find(
                                    (tipoPratica) =>
                                        tipoPratica.id == iterConnessione.tipo_pratica
                                )?.descrizione
                            }
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={3}>
                        <Typography
                            variant="body1"
                            sx={{fontSize: "0.8rem"}}
                            // color="text.secondary"
                            // onClick={() => setEditing(true)}
                        >
                            {iterConnessione.indirizzo}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={1}>
                        {utenteAutorizzato(utente, [
                            PermissionTypes.GESTORI_ITER,
                            PermissionTypes.OPERATORI,
                            PermissionTypes.PROGETTISTI,
                        ]) && (
                            <IconButton
                                aria-label="stampa"
                                size="small"
                                onClick={() => {
                                    iterConnessione.id &&
                                    setIterConnessioneScelta_id(iterConnessione.id);
                                }}
                            >
                                <FolderOpenIcon fontSize="inherit"/>
                            </IconButton>
                        )}
                    </Grid>
                </Grid>
            ))}

            {iterConnessioneScelta_id && (
                <IterConnessioneDocumentiTab
                    key={`iterConnessioneScelta_id_${iterConnessioneScelta_id}`}
                    iterConnessione_id={iterConnessioneScelta_id}
                    utente={utente}
                    consentiModifica={utenteAutorizzato(utente, [
                        PermissionTypes.GESTORI_ITER,
                        PermissionTypes.OPERATORI,
                    ])}
                />
            )}
        </Paper>
    );
};

export default IterConnessioniProduttoreGrid;
