import {
	ErrorStatusTypes,
	ErrorsStack, parseErrorMessage,
} from "../../../common/errorsDeclarations";
import * as lettoreCBApi from "../../../../api/commesse/lettoreCB/lettoreCBApi";
import { toast } from "react-toastify";
// import { useDispatch } from "react-redux";
import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../../redux/store";
import succesSuond from "../../../../../static/sounds/success.wav";
import errorSuond from "../../../../../static/sounds/strong_error.wav";


export interface ElementoProdotto {
	id: number;
	matricola: string;
	descrizione: string;
	prodotto: number;
	prodotto_descrizione: number;
	lettoreCB: string;
}

export interface Prodotto {
	id: number;
	descrizione: string;
	quantita_prevista: number;
	quantita_istanziata: number;
	elementi: ElementoProdotto[];
}

export interface LettoreCB {
	prodotto?: Prodotto;
	codeBar?: string;
	errorsStack?: ErrorsStack;
}

export interface LettoreCBStrutturaState {
	lettoreCB: LettoreCB;
}

const initialState: LettoreCBStrutturaState = {
	lettoreCB: {
		// prodotto: {
		// 	id: 0,
		// 	codice: "------------",
		// 	descrizione: "prodotto non attribuita",
		// },
		// elementoProdotto: {
		// 	id: 0,
		// 	codice: "------------",
		// 	descrizione: "elementoProdotto non attribuito",
		// 	stato: "stato non definito",
		// 	lettoreCB: "lettoreCB non attribuita",
		// },
		codeBar: "",
		errorsStack: { status: ErrorStatusTypes.OK },
	},
};

// export const fetchLettoreCB = createAsyncThunk(
// 	"lettoreCB/fetchLettoreCB",
// 	async () => {
// 		return await lettoreCBApi.fetchLettoreCB();
// 	}
// );

export const getLettoreCB = createAsyncThunk(
	"lettoreCB/getLettoreCB",
	async () => {
		return await lettoreCBApi.getLettoreCB();
	}
);

export const saveLettoreCB = createAsyncThunk(
	"lettoreCB/saveLettoreCB",
	async (lettoreCBToSave: LettoreCB, thunkApi) => {
		return await lettoreCBApi.saveLettoreCB(lettoreCBToSave);
	}
);

export const deleteElementoProdotto = createAsyncThunk(
	"lettoreCB/deleteElementoProdotto",
	async (elementoProdottoToDelete: ElementoProdotto, thunkApi) => {
		return await lettoreCBApi.deleteElementoProdotto(elementoProdottoToDelete);
	}
);


export const lettoreCBSlice = createSlice({
	name: "lettoreCBState",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// // sparata di un ciodice
		builder.addCase(saveLettoreCB.pending, (state, action) => {
			state.lettoreCB.errorsStack = { status: ErrorStatusTypes.PENDING };
		});
		builder.addCase(saveLettoreCB.fulfilled, (state, action) => {
			state.lettoreCB = action.payload;
			state.lettoreCB.errorsStack = { status: ErrorStatusTypes.OK };

			new Audio(succesSuond).play();
		});
		builder.addCase(saveLettoreCB.rejected, (state, action) => {
			new Audio(errorSuond).play();
			toast.error(action?.error?.message || "", {
				position: "top-center",
				// autoClose: false,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: "colored",
			});
			// state.lettoreCB.errorsStack = parseErrorMessage(
			// 	action?.error?.message || ""
			// );
			state.lettoreCB.errorsStack = { status: ErrorStatusTypes.ERROR };

		});
		// get getLettoreCB
		builder.addCase(getLettoreCB.pending, (state, action) => {
			state.lettoreCB.errorsStack = { status: ErrorStatusTypes.PENDING };
		});
		builder.addCase(getLettoreCB.fulfilled, (state, action) => {
			state.lettoreCB = action.payload;
			state.lettoreCB.errorsStack = { status: ErrorStatusTypes.OK };
		});
		builder.addCase(getLettoreCB.rejected, (state, action) => {
			state.lettoreCB.errorsStack = parseErrorMessage(
				action?.error?.message || ""
			);
		});
		// delete elementoProdotto
		builder.addCase(deleteElementoProdotto.pending, (state, action) => {
			state.lettoreCB.errorsStack = { status: ErrorStatusTypes.PENDING };
		});
		builder.addCase(deleteElementoProdotto.fulfilled, (state, action) => {
			state.lettoreCB = action.payload;
			state.lettoreCB.errorsStack = { status: ErrorStatusTypes.OK };
		});
		builder.addCase(deleteElementoProdotto.rejected, (state, action) => {
			state.lettoreCB.errorsStack = parseErrorMessage(
				action?.error?.message || ""
			);
		});
	},
});

// Action creators are generated for each case reducer function
export const {} = lettoreCBSlice.actions;

export const lettoreCBSliceReducer = lettoreCBSlice.reducer;
