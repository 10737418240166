import { useSelector } from "react-redux";
import React, { useEffect, useState } from "react";

import {
	IterConnessione,
	presaInCarico,
	avviaProgetto,
	assegnaPratica,
} from "components/main/iterConnessioni/iterConnessioneSlice";

import { DateTime } from "luxon";

import { RootState, useAppDispatch } from "../../../../../redux/store";
import { PermissionTypes } from "../../../../authentication/permissionsGroups";
import { utenteAutorizzato } from "../../../../authentication/UtenteAutorizzato";
import { UtenteLogged } from "../../../../authentication/authenticationSlice";
import {
	AutorizzazioneComunale,
	saveAutorizzazioneComunale,
	StatoAutorizzazioneComunaleTypes,
} from "./autorizzazioneComunaleSlice";

import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { Alert, Typography } from "@mui/material";
import AlertTitle from "@mui/material/AlertTitle";

interface AzioniAutorizzazioneComunaleCardProps {
	iterConnessione: IterConnessione;
	autorizzazioneComunale: AutorizzazioneComunale;
	consentiModifica?: boolean;
}

const AzioniAutorizzazioneComunaleCard = ({
	iterConnessione,
	autorizzazioneComunale,consentiModifica
}: AzioniAutorizzazioneComunaleCardProps) => {
	
	const dispatch = useAppDispatch();

	const utente = useSelector<RootState, UtenteLogged>(
		(state) => state.authentication.utenteLogged
	);
	const [formRigetto, setFormRigetto] = useState<boolean>(false);

	return (
		<Card sx={{ mt: 2, backgroundColor: "#ddd" }}>
			<CardContent>
				<Stack sx={{ width: "100%" }} spacing={2}>
					<Alert variant="filled" severity="info">
						Pratica{" "}
						{
							Object.entries(StatoAutorizzazioneComunaleTypes).find(
								([key, value]) => key == autorizzazioneComunale.stato
							)?.[1]
						}
					</Alert>

					{!autorizzazioneComunale.autorizzazione_comunale_completa?.autorizzazione_comunale_completa && (
						<Alert severity="error" sx={{ mt: 1 }}>
							<AlertTitle>Attenzione manca:</AlertTitle>
							<Typography
								variant="body2"
								// component="div"
								// color="text.secondary"
								// sx={{
								// 	color: theme?.palette?.error.main,
								// }}
								sx={{ whiteSpace: "pre-line" }}
							>
								{autorizzazioneComunale.autorizzazione_comunale_completa?.dati_mancanti}
							</Typography>
						</Alert>
					)}
					{autorizzazioneComunale.stato == "A" && (
						<Button
							variant="contained"
							size="large"
							disabled={!(autorizzazioneComunale?.autorizzazione_comunale_completa?.autorizzazione_comunale_completa && consentiModifica)}
							sx={{ width: "100%", mt: 1 }}
							onClick={() => {
								dispatch(
									saveAutorizzazioneComunale({
										autorizzazioneComunaleToSave: autorizzazioneComunale,
										azione: "concludi_autorizzazione_comunale",
									})
								);
							}}
						>
							Segna come conclusa la fase Autorizzazioni Comunali
						</Button>
					)}
				</Stack>
			</CardContent>
		</Card>
	);
};

export default AzioniAutorizzazioneComunaleCard;
