import { handleResponse, handleError, headerset } from "../apiUtils";
import { TipoServizio } from "../../components/anagrafiche/tipiServizio/tipiServizioSlice";

const baseUrl = (tipo_servizio_id?: number | null) => {
	if (tipo_servizio_id) {
		return (
			process.env.API_URL +
			"/api/anagrafiche/tipi_servizio/" +
			tipo_servizio_id +
			"/"
		);
	} else {
		return process.env.API_URL + "/api/anagrafiche/tipi_servizio/";
	}
};

export function fetchTipiServizio() {
	let url = baseUrl();

	return fetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}

export function getTipoServizio(tipoServizioId: number) {
	let url = baseUrl(tipoServizioId);
	return fetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}

export function saveTipoServizio(tipoServizioToSave: TipoServizio) {
	const tipoServizio = {
		...tipoServizioToSave,
	};

	return fetch(baseUrl(tipoServizioToSave.id), {
		method: tipoServizioToSave.id ? "PUT" : "POST", // PUT to update when id already exists.
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
		body: JSON.stringify({
			...tipoServizio,
			// Parse authorId to a number (in case it was sent as a string).
			// id: parseInt(progetto.id, 10),
		}),
	})
		.then(handleResponse)
		.catch(handleError);
}

export function deleteTipoServizio(tipoServizioToDelete: TipoServizio) {
	return fetch(baseUrl(tipoServizioToDelete.id), {
		method: "DELETE",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}
