import {Commessa, TipoIntervento} from "../commesseSlice";

export const prevedeAmpliamento = (commessa: Commessa, tipiIntervento: TipoIntervento[]) => {
    let prevedeAmpliamento: boolean = false;
    const tipiInterventoCommessa = tipiIntervento.filter((tipo) => commessa.tipi_intervento?.includes(tipo.id))
    for (const tipo of tipiInterventoCommessa) {
        if (tipo.ampliamento) {
            prevedeAmpliamento = true;
        }
    }
    return prevedeAmpliamento;
}
