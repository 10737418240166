import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, Link, useNavigate } from "react-router-dom";

import { RootState, useAppDispatch } from "../../../redux/store";
import { Routes_path } from "../../routerApp";

import { DateTime } from "luxon";

import {
	ErrorStatusTypes,
	ErrorsStack,
	parseErrorMessage,
} from "../../common/errorsDeclarations";
import { utenteAutorizzato } from "../../authentication/UtenteAutorizzato";
import { UtenteLogged } from "../../authentication/authenticationSlice";
import { PermissionTypes } from "../../authentication/permissionsGroups";
import {
	OrdinamentoLista,
	PreferenzeLista,
	setPreferenzeListaAdempimenti,
} from "../../userPreference/userPreferenceSlice";
import NavigazioneMesiToolbar from "../../common/dateUtils/NavigazioneMesiToolbar";

import {
	RecuperaDataCorrente,
	FissaDataCorrente,
} from "../../common/dateUtils/RecuperaDataCorrente";

import {
	TipoAdempimento,
	fetchTipiAdempimento,
	fetchAdempimenti,
	AdempimentiState,
	StatoAdempimentoTypes,
} from "./adempimentiSlice";
import HandshakeIcon from "@mui/icons-material/Handshake";
import ServiziServiziAdempimentiPage from "./serviziAdempimenti/ServiziAdempimentiPage";
import AdempimentiPageBreadcrumbs from "./AdempimentiPageBreadcrumbs";

import {
	Soggetto,
	resetProduttoreCorrente,
	fetchProduttori,
} from "../../anagrafiche/produttori/produttoriSlice";

import { Cliente } from "../../anagrafiche/clienti/clientiSlice";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";
import Chip from "@mui/material/Chip";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import AddIcon from "@mui/icons-material/Add";
import PreviewIcon from "@mui/icons-material/Preview";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import Pagination from "@mui/material/Pagination";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import { TipoPratica } from "components/anagrafiche/tipiPratica/tipiPraticaSlice";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import ReplayIcon from "@mui/icons-material/Replay";

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import {convertToDateTime} from "../../common/dateUtils/convertToDateTime";

const produttoreFullName = (produttore?: Soggetto) => {
	if (produttore) {
		if (produttore.tipo_persona == "PF") {
			return `${produttore.cognome} ${produttore.nome}`;
		} else {
			return `${produttore.ragione_sociale}`;
		}
	} else {
		return "anonimo";
	}
};

// const visualizzaStatoIter = (statoIter?: StatoIter) => {
// 	return statoIter && `${statoIter.stato_desc} (${statoIter.count})`;
// };

const AdempimentiPage = () => {
	
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	type LocationState = {
		statoAdempimentoId?: string;
		tipoAdempimentoId?: number;
		clienteId?: number;
	};
	const location = useLocation();
	const recupera_data_corrente = RecuperaDataCorrente(location);

	const preferenzeListaAdempimenti = useSelector<
		RootState,
		PreferenzeLista | undefined
	>((state) => state.userPreferenceState.preferenzeListaAdempimenti);

	const [statoAdempimentoId, setStatoAdempimentoId] = useState(
		location.state
			? (location.state as LocationState).statoAdempimentoId
			: preferenzeListaAdempimenti?.stato
		//  : undefined
	);

	const [tipoAdempimentoId, setTipoAdempimentoId] = useState(
		location.state
			? (location.state as LocationState).tipoAdempimentoId
			: // : preferenzeListaAdempimenti?.tipo_adempimento
			  undefined
	);

	const [clienteId, setClienteIterId] = useState(
		location.state
			? (location.state as LocationState).clienteId
			: preferenzeListaAdempimenti?.clienteIterId
		// : undefined
	);

	const tipi_adempimenti = useSelector<RootState, TipoAdempimento[]>(
		(state) => state.adempimentiState.tipiAdempimento.results
	);

	const adempimenti = useSelector<RootState, AdempimentiState>(
		(state) => state.adempimentiState.adempimenti
	);
	const utente = useSelector<RootState, UtenteLogged>(
		(state) => state.authentication.utenteLogged
	);

	// const statoAdempimenti = useSelector<RootState, StatiIter>(
	// 	(state) => state.kpiState.statiIter
	// );

	const clienti = useSelector<RootState, Cliente[]>(
		(state) => state.clientiState.clienti.results
	);

	const [page, setPage] = useState<number>(
		preferenzeListaAdempimenti?.page || 1
	);
	const [numeroRecord, setNumeroRecord] = useState<number>(
		preferenzeListaAdempimenti?.numeroRecord || 10
	);
	const [search, setSearch] = useState<string | undefined>(undefined);
	const [vistaProspettica, setVistaProspettica] = useState<boolean>(true);

	const handleChangeNumeroRecord = (event: SelectChangeEvent) => {
		setNumeroRecord(Number(event.target.value));
	};

	const handlerPager = (event: React.ChangeEvent<unknown>, page: number) => {
		setPage(page);
	};

	const [refresh, setRefresh] = useState<boolean>(false);

	const [anchorElStatoFilter, setAnchorElStatoFilter] = useState(null);
	const [anchorElClienteFilter, setAnchorElClienteFilter] = useState(null);
	const [anchorElTipoAdempimentoFilter, setAnchorElTipoAdempimentoFilter] =
		useState(null);

	const [data, setData] = useState<DateTime | null>(null);

	const handleStatoFilterClose = () => {
		setAnchorElStatoFilter(null);
		setAnchorElClienteFilter(null);
		setAnchorElTipoAdempimentoFilter(null);
		// onClose(selectedValue);
	};

	useEffect(() => {
		data && FissaDataCorrente(data);

		const data_da =
			data != undefined ? convertToDateTime(data)?.startOf("month").toJSDate() : undefined;
		const data_a =
			data != undefined ? convertToDateTime(data)?.endOf("month").toJSDate() : undefined;
		dispatch(fetchTipiAdempimento());

		dispatch(
			fetchAdempimenti({
				stato_adempimento: statoAdempimentoId,
				cliente: clienteId,
				tipo_adempimento: tipoAdempimentoId,
				numeroRecord: numeroRecord,
				page: page,
				search: search,
				data_da: data_da,
				data_a: data_a,
			})
		);
		dispatch(
			setPreferenzeListaAdempimenti({
				numeroRecord: numeroRecord,
				page: page,
				stato: statoAdempimentoId,
			})
		);
		setRefresh(false);
	}, [
		page,
		numeroRecord,
		search,
		statoAdempimentoId,
		clienteId,
		tipoAdempimentoId,
		data,
		refresh,
	]);

	useEffect(() => {
		setRefresh(false);
	}, [refresh]);

	const handlerCreateAdempimento = () => {
		dispatch(resetProduttoreCorrente());
		navigate(Routes_path.PRODUTTORE + "0/" + "ADEM_FV");
	};

	return (
		<Box
			sx={{
				backgroundColor: "#fff",
				elevation: 20,
				p: 1,
				// p: 2,
				"& .campo-editabile": {
					backgroundColor: "#bbbbbb11",
					background: "linear-gradient(to right bottom, #f5f7f6, #dce3e0)",
					// borderWidth: "5px",
					borderColor: "#ff91003d",
					// borderColor: "#0000003d",
					// borderStyle: "outset",
					borderWidth: 3,
					borderStyle: "ridge",
					color: "#1a3e72",
					fontWeight: "600",
				},
				"& .campo-errore": {
					backgroundColor: "rgba(124, 3, 0, 0.3)",
					color: "#ffffff",
					fontWeight: "600",
				},
			}}
		>
			<AdempimentiPageBreadcrumbs />

			<Stack
				direction="row"
				spacing={2}
				sx={{ my: 2 }}
				justifyContent="space-between"
			>

				<Stack direction="row" spacing={2} justifyContent="flex-start">
					<Typography variant="h4" gutterBottom>
						{`Adempimenti ${vistaProspettica ? "Vista prospettica" : "Vista mensile"}`}
					</Typography>
					<Button
						color="primary"
						variant="outlined"
						startIcon={<PreviewIcon />}
						disabled={
							!utenteAutorizzato(utente, [
								PermissionTypes.GESTORI_ITER,
								PermissionTypes.CLIENTI,
								PermissionTypes.OPERATORI,
								PermissionTypes.PROGETTISTI,
							])
						}
						onClick={(event) => {
							event.preventDefault();
							if (vistaProspettica) {
								setVistaProspettica(false);
								setData(convertToDateTime(new Date()));
							} else {
								setVistaProspettica(true);
								setData(null);
							}
						}}
					>
						{vistaProspettica ?  "Vista mensile" : "Vista prospettica"}
					</Button>
					{!vistaProspettica && (
						<NavigazioneMesiToolbar
							// titolo={"Adempimenti"}
							setData={setData}
							data={data}
							refresh={() => {
								setRefresh(true);
							}}
						/>
					)}
				</Stack>

				<Button
					color="primary"
					variant="outlined"
					startIcon={<AddIcon />}
					disabled={
						!utenteAutorizzato(utente, [
							PermissionTypes.GESTORI_ITER,
							// PermissionTypes.CLIENTI,
							// PermissionTypes.OPERATORI,
							PermissionTypes.PROGETTISTI,
						])
					}
					onClick={(event) => {
						event.preventDefault();
						handlerCreateAdempimento();
					}}
				>
					Aggiungi nuovo Adempimento
				</Button>
				<Button
					color="primary"
					size="small"
					startIcon={<HandshakeIcon />}
					component={Link}
					to={Routes_path.SERVIZI_ADEMPIMENTI}
					disabled={
						!utenteAutorizzato(utente, [
							PermissionTypes.GESTORI_ITER,
							// PermissionTypes.CLIENTI,
							// PermissionTypes.OPERATORI,
							PermissionTypes.PROGETTISTI,
						])
					}
					variant="outlined"
				>
					Contratti Adempimenti
				</Button>
			</Stack>
			<List>
				<ListItemText
					key={"Lista_saldo_pallet_committente"}
					sx={{ backgroundColor: "#1b75b1", color: "#ddd", mb: 1 }}
				>
					<Grid container spacing={{ xs: 0.5, md: 1, lg: 2 }} sx={{ px: 2 }}>
						<Grid item xs={2} sm={2} md={1} lg={1}>
							<Typography variant="h5" gutterBottom>
								n°
							</Typography>
						</Grid>
						<Grid item xs={3} sm={3} md={2} lg={2}>
							<Stack direction="row" spacing={1}>
								<Typography variant="h5">Tipo Adempimento</Typography>
								<IconButton
									color={tipoAdempimentoId ? "secondary" : "inherit"}
									onClick={(event: any) =>
										setAnchorElTipoAdempimentoFilter(event.currentTarget)
									}
								>
									<FilterAltIcon sx={{ mt: -0.5 }} />
								</IconButton>
							</Stack>
							<Box style={{ display: "flex", justifyContent: "space-between" }}>
								<Menu
									id="simple-menu"
									anchorEl={anchorElTipoAdempimentoFilter}
									keepMounted
									open={Boolean(anchorElTipoAdempimentoFilter)}
									onClose={handleStatoFilterClose}
								>
									{tipi_adempimenti.map((tipo_adempimento) => (
										<MenuItem
											key={tipo_adempimento.id}
											onClick={() => {
												setTipoAdempimentoId(tipo_adempimento.id);
												setAnchorElTipoAdempimentoFilter(null);
											}}
											sx={{
												backgroundColor:
													tipo_adempimento.id == tipoAdempimentoId
														? "#aaa"
														: "inherit",
											}}
										>
											{tipo_adempimento.nome}
										</MenuItem>
									))}
									<MenuItem
										onClick={() => {
											setTipoAdempimentoId(undefined);
											setAnchorElTipoAdempimentoFilter(null);
										}}
										sx={{
											backgroundColor: !tipoAdempimentoId ? "#aaa" : "inherit",
										}}
									>
										tutti
									</MenuItem>
								</Menu>
							</Box>
						</Grid>
						<Grid item xs={6} sm={6} md={6} lg={2}>
							<Stack direction="row" spacing={1}>
								<Typography variant="h5" gutterBottom>
									Cliente
								</Typography>
								<IconButton
									color={clienteId ? "secondary" : "inherit"}
									onClick={(event: any) =>
										setAnchorElClienteFilter(event.currentTarget)
									}
								>
									<FilterAltIcon sx={{ mt: -0.5 }} />
								</IconButton>
							</Stack>
							<Box style={{ display: "flex", justifyContent: "space-between" }}>
								<Menu
									id="simple-menu"
									anchorEl={anchorElClienteFilter}
									keepMounted
									open={Boolean(anchorElClienteFilter)}
									onClose={handleStatoFilterClose}
								>
									{clienti.map((cliente) => (
										<MenuItem
											key={cliente.id}
											onClick={() => {
												setClienteIterId(cliente.id);
												setAnchorElClienteFilter(null);
											}}
											sx={{
												backgroundColor:
													cliente.id == clienteId ? "#aaa" : "inherit",
											}}
										>
											{cliente.ragione_sociale}
										</MenuItem>
									))}
									<MenuItem
										onClick={() => {
											setClienteIterId(undefined);
											setAnchorElClienteFilter(null);
										}}
										sx={{
											backgroundColor: !clienteId ? "#aaa" : "inherit",
										}}
									>
										tutti
									</MenuItem>
								</Menu>
							</Box>
						</Grid>
						<Grid item xs={6} sm={6} md={6} lg={2}>
							<Typography variant="h5">Soggetto</Typography>
						</Grid>

						<Grid item xs={6} sm={6} md={6} lg={1}>
							<Stack direction="row" spacing={1}>
								<Typography variant="h5">Stato</Typography>
								<IconButton
									color={statoAdempimentoId ? "secondary" : "inherit"}
									onClick={(event: any) =>
										setAnchorElStatoFilter(event.currentTarget)
									}
								>
									<FilterAltIcon sx={{ mt: -0.5 }} />
								</IconButton>
							</Stack>
							<Box style={{ display: "flex", justifyContent: "space-between" }}>
								<Menu
									id="simple-menu"
									anchorEl={anchorElStatoFilter}
									keepMounted
									open={Boolean(anchorElStatoFilter)}
									onClose={handleStatoFilterClose}
								>
									{Object.entries(StatoAdempimentoTypes).map(([key, value]) => {
										return (
											<MenuItem
												key={`statoIter_${key}`}
												value={key}
												onClick={() => {
													setStatoAdempimentoId(key);
													setAnchorElStatoFilter(null);
												}}
												sx={{
													backgroundColor:
														value == statoAdempimentoId ? "#aaa" : "inherit",
												}}
											>
												{value}
											</MenuItem>
										);
									})}

									<MenuItem
										onClick={() => {
											setStatoAdempimentoId(undefined);
											setAnchorElStatoFilter(null);
										}}
										sx={{
											backgroundColor: !statoAdempimentoId ? "#aaa" : "inherit",
										}}
									>
										tutti
									</MenuItem>
								</Menu>
							</Box>
						</Grid>
						<Grid item xs={6} sm={6} md={6} lg={1}>
							<Typography variant="h5" gutterBottom>
								Data Pianif.
							</Typography>
						</Grid>
						<Grid item xs={6} sm={6} md={6} lg={1}>
							<Typography variant="h5" gutterBottom>
								Data Scadenza
							</Typography>
						</Grid>
						<Grid item xs={6} sm={6} md={6} lg={2}>
							<Typography variant="h5" gutterBottom>
								Data Completamento
							</Typography>
						</Grid>
					</Grid>
				</ListItemText>

				<Backdrop
					sx={{
						color: "#fff",
						zIndex: (theme) => theme.zIndex.drawer + 1,
					}}
					open={
						adempimenti.errorsStack.status == ErrorStatusTypes.PENDING
						// errorsStack.status == ErrorStatusTypes.PENDING
					}
				>
					<CircularProgress color="inherit" />
				</Backdrop>

				{adempimenti.results?.map((adempimento, index) => (
					<ListItemButton
						// key={"iter_" + sopralluogo.id}
						key={index}
						component={Link}
						to={Routes_path.ADEMPIMENTO + adempimento.id}
						sx={{
							color: "black",
							textDecoration: "none",
							backgroundColor: index % 2 === 0 ? "#fff" : "#f2f2f2",
						}}
					>
						<Grid container spacing={{ xs: 0.5, md: 1, lg: 2 }} sx={{ mb: 1 }}>
							<Grid item xs={2} sm={2} md={1} lg={1}>
								{adempimento.id}
							</Grid>
							<Grid item xs={6} sm={6} md={6} lg={2}>
								{
									tipi_adempimenti.find(
										(tipo) => tipo.id == adempimento.tipo_adempimento
									)?.nome
								}
							</Grid>

							<Grid item xs={6} sm={6} md={6} lg={2}>
								<Typography noWrap>
									{/* {
										clienti.find((cliente) => cliente.id == adempimento.cliente)
											?.ragione_sociale
									} */}
									{adempimento.cliente_descrizione}
								</Typography>
							</Grid>

							<Grid item xs={6} sm={6} md={6} lg={2}>
								<Typography noWrap>
									{adempimento.produttore_descrizione}
								</Typography>
							</Grid>

							<Grid item xs={6} sm={6} md={6} lg={1}>
								{
									Object.entries(StatoAdempimentoTypes).find(
										([key, value]) => key == adempimento.stato
									)?.[1]
								}
							</Grid>
							<Grid
								item
								xs={6}
								sm={6}
								md={6}
								lg={1}
								sx={{
									color:
										(convertToDateTime(adempimento.data_pianificata)?.diff(DateTime.now(), 'days').days || 0) < 15 && adempimento.stato != "C"
											? "red"
											: "inherit",
								}}
							>
								{adempimento.data_pianificata
									? convertToDateTime(adempimento.data_pianificata)?.setLocale("it").toFormat("dd/MM/yyyy")
									: ""}
							</Grid>

							<Grid item xs={6} sm={6} md={6} lg={1}>
								<Typography
									sx={{
										color:
											(convertToDateTime(adempimento.data_scadenza)?.diff(DateTime.now(), 'days').days || 0) <
												10 && adempimento.stato != "C"
												? "red"
												: "inherit",
									}}
								>
									{adempimento.data_scadenza
										? convertToDateTime(adempimento.data_scadenza)?.setLocale("it").toFormat("dd/MM/yyyy")
										: ""}
								</Typography>
							</Grid>
							<Grid item xs={6} sm={6} md={6} lg={2}>
								{adempimento.data_completamento
									? convertToDateTime(adempimento.data_completamento)?.setLocale("it").toFormat("dd/MM/yyyy")
									: ""}
							</Grid>
						</Grid>
					</ListItemButton>
				))}

				<ListItem
					sx={{
						alignContent: "right",
						width: "100%",
						mt: 2,
						borderTopStyle: "solid",
						borderTopWidth: 1,
					}}
				>
					<Stack
						direction="row"
						justifyContent="space-between"
						alignItems="center"
						spacing={2}
						sx={{ width: "100%" }}
					>
						<Pagination
							count={adempimenti.num_pages}
							onChange={handlerPager}
							page={page}
						/>
						{statoAdempimentoId || clienteId || tipoAdempimentoId ? (
							<Typography noWrap>
								Totale adempimenti filtrati:{"  "}
								{adempimenti.count}
							</Typography>
						) : (
							<Typography noWrap>
								Totale adempimenti:{"  "}
								{adempimenti.count}
							</Typography>
						)}

						<TextField
							id="input-with-icon-textfield"
							// label="TextField"
							InputProps={{
								startAdornment: (
									<InputAdornment position="end">
										<SearchIcon />
									</InputAdornment>
								),
							}}
							variant="standard"
							value={search}
							onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
								setSearch(event.target.value);
							}}
						/>
						<FormControl>
							<InputLabel id="demo-simple-select-label">Record</InputLabel>
							<Select
								labelId="demo-simple-select-label"
								id="demo-simple-select"
								value={numeroRecord.toString()}
								label="Age"
								onChange={handleChangeNumeroRecord}
								variant="outlined"
							>
								<MenuItem value={10}>10</MenuItem>
								<MenuItem value={20}>20</MenuItem>
								<MenuItem value={50}>50</MenuItem>
							</Select>
						</FormControl>
					</Stack>
				</ListItem>
			</List>
		</Box>
	);
};

export default AdempimentiPage;
