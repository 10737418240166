import { handleResponse, handleError, headerset } from "../../apiUtils";
import { LettoreCB , ElementoProdotto} from "components/main/commesse/lettoreCB/lettoreCBSlice";
import {TipoServizio} from "../../../components/anagrafiche/tipiServizio/tipiServizioSlice";

const baseUrl = () => {
	return process.env.API_URL + "/api/commesse/lettoreCB/";
};

export function getLettoreCB() {
	let url = baseUrl();
	return fetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}

export function saveLettoreCB(lettoreCBToSave: LettoreCB) {
	return fetch(baseUrl(), {
		method: "PUT", // PUT to update when id already exists.
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
		body: JSON.stringify({
			...lettoreCBToSave,
			// Parse authorId to a number (in case it was sent as a string).
			// id: parseInt(progetto.id, 10),
		}),
	})
		.then(handleResponse)
		.catch(handleError);
}


export function deleteElementoProdotto(elementoProdottoToDelete: ElementoProdotto) {
	const url = `${process.env.API_URL}/api/commesse/lettoreCB/elemento_prodotto/${elementoProdottoToDelete.id}/`;
	return fetch(url, {
		method: "DELETE",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}
