import { handleResponse, handleError, headerset } from "../apiUtils";
import { DateTime } from "luxon";
import { convertToDateTime } from "components/common/dateUtils/convertToDateTime";
export function fetchPratiche(
	data_da?: string | Date,
	data_a?: string | Date,
	clienteIter?: number,
	numeroRecord?: number,
	page?: number,
	search?: string
) {
	let url = process.env.API_URL + "/api/amministrazione/fatturabili/";
	if (
		data_da != undefined ||
		data_a != undefined ||
		clienteIter != undefined ||
		numeroRecord != undefined ||
		search != undefined ||
		page != undefined
	) {
		url += "?";
	}
	if (clienteIter != undefined) {
		url += `cliente=${clienteIter}&`;
	}

	if (numeroRecord != undefined) {
		url += `numero_record=${numeroRecord}&`;
	}
	if (page != undefined) {
		url += `page=${page}&`;
	}
	if (search != undefined) {
		url += `search=${search}&`;
	}
	if (data_da != undefined) {
		url += `data_da=${convertToDateTime(data_da)?.setLocale("it").toFormat("yyyy-MM-dd")}&`;
	}
	if (data_a != undefined) {
		url += `data_a=${convertToDateTime(data_a)?.setLocale("it").toFormat("yyyy-MM-dd")}&`;

	}
	// data_da.toFormat('yyyy-MM-dd');
	return fetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}
