import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { RootState, useAppDispatch } from "../../../../../redux/store";

import { DateTime } from "luxon";


import { utenteAutorizzato } from "../../../../authentication/UtenteAutorizzato";
import { PermissionTypes } from "../../../../authentication/permissionsGroups";

import {
	ErrorStatusTypes,
	ErrorsStack,
} from "../../../../common/errorsDeclarations";
import { UtenteLogged } from "../../../../authentication/authenticationSlice";
import { Comune } from "../../../../anagrafiche/comuni/comuniSlice";
import {
	Sopralluogo,
	SopralluogoElettrico,
	getSopralluogoElettricoFromSopralluogo,
} from "../../sopralluoghiSlice";
import SopralluogoElettricoForm from "./SopralluogoElettricoForm";
import AzioniSopralluogoCard from "../AzioniSopralluogoCard";
import AllegatiPage from "../../../../allegati/AllegatiPage";
// import SopralluogoDatiGeneraliForm from "./SopralluogoDatiGeneraliForm";
// import SopralluogoNoteInterneForm from "./SopralluogoNoteInterneForm";
// import SopralluogoDistintaProdottiBox from "./prodotti/SopralluogoDistintaProdottiBox";
// import AzioniClienteCard from "./AzioniClienteCard";
// import AzioniGestoreCard from "./AzioniGestoreCard";
import Divider from "@mui/material/Divider";
import tipi_staffa from "../../../../../../static/images/common/tipi_staffa.png";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";
import { Button } from "@mui/material";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Stack from "@mui/material/Stack";
import { theme } from "../../../../theme";
import { Soggetto } from "components/anagrafiche/produttori/produttoriSlice";

interface SopralluogoElettricoTabProps {
	sopralluogo: Sopralluogo;
	utente: UtenteLogged;
	// produttore: Soggetto;
	consentiModifica: boolean;
}

const SopralluogoElettricoTab = ({
	sopralluogo,
	utente,
	// produttore,
	consentiModifica = false,
}: SopralluogoElettricoTabProps) => {
	
	const dispatch = useAppDispatch();

	const [refresh, setRefresh] = useState<boolean>(false);

	useEffect(() => {
		setRefresh(false);
	}, [refresh]);

	useEffect(() => {
		if (sopralluogo.id) {
			dispatch(getSopralluogoElettricoFromSopralluogo(sopralluogo.id));
		}
	}, []);

	const sopralluogoElettrico: SopralluogoElettrico | undefined = useSelector<
		RootState,
		SopralluogoElettrico | undefined
	>((state) =>
		state.sopralluoghiState.sopralluoghiElettrici.results.find(
			(sopralluogoElettrico) =>
				sopralluogoElettrico?.sopralluogo == sopralluogo.id
		)
	);

	const sopralluogoStatus = useSelector<RootState, ErrorStatusTypes>(
		(state) => state.sopralluoghiState.sopralluoghiElettrici.errorsStack.status
	);

	return (
		<Box>
			<Grid container spacing={2} sx={{ mb: 4 }}>
				<Grid item xs={12} sm={12} md={8} lg={9} order={{ xs: 2, md: 1 }}>
					<Stack spacing={2}>
						{sopralluogoElettrico && (
							<SopralluogoElettricoForm
								sopralluogoElettrico={sopralluogoElettrico}
								// produttore={produttore}
								consentiModifica={consentiModifica}
							/>
						)}
					</Stack>
				</Grid>
				<Grid item xs={12} sm={6} md={4} lg={3} order={{ xs: 1, md: 2 }}>
					<AzioniSopralluogoCard sopralluogo={sopralluogo} />

					{/* {utenteAutorizzato(utente, [
						PermissionTypes.CLIENTI,
						PermissionTypes.OPERATORI,
						// PermissionTypes.GESTORI_ITER,
					]) && <AzioniClienteCard sopralluogo={sopralluogo} />}
					{utenteAutorizzato(utente, [PermissionTypes.GESTORI_ITER]) && (
						<AzioniGestoreCard sopralluogo={sopralluogo} />
					)} */}
				</Grid>
			</Grid>
			<Divider
				textAlign="left"
				sx={{ mt: 4, mb: 1, color: theme.palette.primary.main }}
			>
				Allegati
			</Divider>
			{sopralluogoElettrico?.listaDocumentiPerTipo && (
				<AllegatiPage
					listaDocumentiPerTipo={sopralluogoElettrico.listaDocumentiPerTipo}
					sopralluogoElettricoId={sopralluogoElettrico.id || 0}
					consentiModifica={consentiModifica}
				/>
			)}
		</Box>
	);
};

export default SopralluogoElettricoTab;
