import React from 'react';
import { Utente } from "../../authentication/authenticationSlice";

import { styled, useTheme } from "@mui/material/styles";
import { Link } from "react-router-dom";
import { Routes_path } from "../../routerApp";
import { PermissionTypes } from "../../authentication/permissionsGroups";

import List from "@mui/material/List";
// import MedicalInformationIcon from "@mui/icons-material/MedicalInformation";
import Grid from "@mui/material/Grid";
import { utenteAutorizzato } from "../../authentication/UtenteAutorizzato";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
const PREFIX = "HomeMenu";

interface HomeMenuButtonProps {
	sigla?: string;
	nome: string;
	descrizione?: string;
	colore: string;
	// colore2: string;
	utente: Utente;
	gruppiAutorizzati: PermissionTypes[];
	path: Routes_path;
	Icon?: any;
	disabled?: boolean;
	onClick?: React.MouseEventHandler<HTMLAnchorElement> | undefined;
}
const HomeMenuButton = ({
	sigla,
	nome,
	descrizione,
	colore,
	// colore2,
	utente,
	gruppiAutorizzati,
	path,
	Icon,
	disabled = false,
	onClick,
}: HomeMenuButtonProps) => {
	return (
		<>
			{utenteAutorizzato(utente, gruppiAutorizzati) && (
				// <Grid item sm={6} md={4} xs={6}>
				// <Grid item xs={6} sm={6} md={4} lg={4}>
				<Button
					sx={{
						// background: "linear-gradient(40deg, #03b0e2 50%, #03b0e255 100%)",
						background:
							"linear-gradient(40deg, " +
							colore +
							" 50%, " +
							colore +
							"55 100%)",
						"&:hover": {
							background:
								// "linear-gradient(25deg, #03b0e2ee 50%, #03b0e2aa 100%)",
								"linear-gradient(25deg, " +
								colore +
								"ee 50%, " +
								colore +
								"aa 100%)",
							color: "#f8ec06dd",
						},
						color: "#fff",
						width: "100%",
						minHeight: 90,
						elevation: 3,
						display: "block",
						textAlign: "center",
						// justifyContent: "center",
						// fontSize: { lg: "1vw", md: 20, sm: 14, xs: 14 },
					}}
					// startIcon={<Icon sx={{ height: 50, mx: 3 }} />}
					variant="contained"
					component={Link}
					to={path}
					disabled={disabled}
					onClick={onClick}
				>
					{/* <Grid container>
						<Grid item> */}
					{/* <Icon
						sx={{
							fontSize: { lg: "1vw", md: 30, sm: 20, xs: 12 },
							mr: { md: 2, sm: 1, xs: 1 },
						}}
					/> */}
					{sigla && (
						<Typography fontSize={"1vw"} fontWeight={600}>
							{sigla}
						</Typography>
					)}
					<Typography fontSize={"0.8vw"} fontWeight={600}>
						{nome}
					</Typography>
					{/* </Grid> */}
					{descrizione && (
						// <Grid item>
						<Typography variant="body2" fontSize={"0.5vw"}>
							{descrizione}
						</Typography>
						// </Grid>
					)}
					{/* </Grid> */}
				</Button>
				// </Grid>
			)}
		</>
	);
};

export default HomeMenuButton;
