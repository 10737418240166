import React from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { PermissionTypes } from "components/authentication/permissionsGroups";
import {Routes_path} from "./routerApp";
import {UtenteLogged} from "./authentication/authenticationSlice";
import HomePage from "components/main/home/HomePage";

const DynamicRedirect = () => {

    const utente = useSelector<RootState, UtenteLogged>(
        (state) => state.authentication.utenteLogged
    );
    // if (!utente || !utente.gruppi) {
    //     return <Navigate to={Routes_path.SIGNIN} />;
    // }

    if (utente.gruppi?.includes(PermissionTypes.PRODUTTORI)) {
        return <Navigate to={Routes_path.PAGINA_PRODUTTORE} />;
    }


    // Aggiungi altri gruppi con le loro rispettive pagine se necessario
    return <HomePage />;
};

export default DynamicRedirect;
