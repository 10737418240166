import { handleResponse, handleError, headerset } from "../apiUtils";
import { DateTime } from "luxon";
import { convertToDateTime } from "components/common/dateUtils/convertToDateTime";
import {
	Progetto,
	SezioneProgetto,
} from "../../components/main/iterConnessioni/iterConnessione/progetti/progettiSlice";

const baseUrl = (progetto_id?: number | null, azione?: string) => {
	if (progetto_id) {
		let url = `${process.env.API_URL}/api/progetti/${progetto_id}/`;
		if (!!azione) {
			url = url + `?azione=${azione}`;
		}
		return url;
	} else {
		return process.env.API_URL + "/api/progetti/";
	}
};

export function fetchProgetti() {
	let url = baseUrl();
	// data_da.toFormat('yyyy-MM-dd');
	return fetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}

export function getProgetto(progettoId: number) {
	let url = baseUrl(progettoId);
	return fetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}

export function saveProgetto(progettoToSave: Progetto, azione?: string) {
	const data_progetto = progettoToSave.data_progetto
		? convertToDateTime(progettoToSave.data_progetto)?.setLocale("it").toFormat("yyyy-MM-dd")
		: null;
	const data_rigetto = progettoToSave.data_rigetto
		? convertToDateTime(progettoToSave.data_rigetto)?.setLocale("it").toFormat("yyyy-MM-dd")
		: null;
	const progetto = {
		...progettoToSave,
		data_progetto: data_progetto,
		data_rigetto: data_rigetto,
	};
	return fetch(baseUrl(progettoToSave.id, azione), {
		method: progettoToSave.id ? "PUT" : "POST", // PUT to update when id already exists.
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
		body: JSON.stringify({
			...progetto,
			// Parse authorId to a number (in case it was sent as a string).
			// id: parseInt(progetto.id, 10),
		}),
	})
		.then(handleResponse)
		.catch(handleError);
}

export function deleteProgetto(progettoToDelete: Progetto) {
	return fetch(baseUrl(progettoToDelete.id), {
		method: "DELETE",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}

////////////////////////////////////////

export function getProgettoFromIter(iterId: number) {
	let url =
		process.env.API_URL + "/api/progetti/progetto_from_iter/" + iterId + "/";
	return fetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}

////////////////////////////////////////
export function deleteSezione(sezioneToDelete: SezioneProgetto) {
	const url =
		process.env.API_URL + "/api/progetti/sezione/" + sezioneToDelete.id + "/";
	return fetch(url, {
		method: "DELETE",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}

/////////////////////////////////////////////////

export function saveSezioneProgetto(
	sezioneProgettoToSave: SezioneProgetto,
	progettoId: number
) {
	const url =
		process.env.API_URL + "/api/progetti/" + progettoId + "/aggiungi_sezione/";
	return fetch(url, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
		body: JSON.stringify({
			...sezioneProgettoToSave,
			// Parse authorId to a number (in case it was sent as a string).
			// id: parseInt(progetto.id, 10),
		}),
	})
		.then(handleResponse)
		.catch(handleError);
}

/////////////////////////////////////////////////

export function chiudiProgetto(progettoId: number) {
	const url =
		process.env.API_URL + "/api/progetti/" + progettoId + "/chiudi_progetto/";
	return fetch(url, {
		method: "PUT",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}

/////////////////////////////////////////////////

export function creaSopralluogoProgetto(progettoId: number) {
	const url =
		process.env.API_URL +
		"/api/progetti/" +
		progettoId +
		"/crea_sopralluogo_progetto/";
	return fetch(url, {
		method: "PUT",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}
