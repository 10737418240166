import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {convertToDateTime} from "components/common/dateUtils/convertToDateTime";

import {FineLavoro, saveFineLavoro} from "../fineLavoriSlice";
import {useAppDispatch, RootState} from "../../../../../../../redux/store";
import {
    ErrorStatusTypes,
    ErrorsStack,
} from "components/common/errorsDeclarations";

import {theme} from "components/theme";

import {UtenteLogged} from "../../../../../../authentication/authenticationSlice";
import {useForm, SubmitHandler, Controller} from "react-hook-form";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Alert from "components/common/Alert";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";

;
import {DesktopDatePicker} from "@mui/x-date-pickers/DesktopDatePicker";

import {Button, Divider} from "@mui/material";
import AllegatiPage from "../../../../../../allegati/AllegatiPage";

interface IterConnessioneFineLavoroRegolamentoEsercizioFormProps {
    fineLavoro: FineLavoro;
    consentiModifica: boolean;
    iterSemplificato: boolean;
}

const IterConnessioneFineLavoroRegolamentoEsercizioForm = ({
                                                               fineLavoro,
                                                               consentiModifica = false,
                                                               iterSemplificato = true,
                                                           }: IterConnessioneFineLavoroRegolamentoEsercizioFormProps) => {
    const {
        handleSubmit,
        register,
        formState: {errors, isDirty, isValid},
        setValue,
        watch,
        getValues,
        control,
        setError,
        clearErrors,
        reset,
    } = useForm<FineLavoro>();

    // const listaCampi = Object.keys(fineLavoro) as Array<keyof FineLavoro>;
    const listaCampi: (
        "regolamento_esercizio_data_disponibilita" |
        "regolamento_esercizio_data_inoltro" |
        "regolamento_esercizio_data_convalida" |
        "regolamento_esercizio_note"
        )[] = [
        "regolamento_esercizio_data_disponibilita",
        "regolamento_esercizio_data_inoltro",
        "regolamento_esercizio_data_convalida",
        "regolamento_esercizio_note"
    ]


    const dispatch = useAppDispatch();

    const [editing, setEditingControlled] = useState(false);

    const setEditing = (switchModifica: boolean) => {
        consentiModifica && setEditingControlled(switchModifica);
    };
    const utente = useSelector<RootState, UtenteLogged>(
        (state) => state.authentication.utenteLogged
    );

    useEffect(() => {
        reset();
        listaCampi.map((field) => {
            setValue(field, fineLavoro[field]);
        });
    }, []);

    useEffect(() => {
        clearErrors();
        listaCampi.forEach((field) => {
            fineLavoro?.errorsStack?.fieldsErrors &&
            fineLavoro.errorsStack.fieldsErrors[field] &&
            setError(field, {
                type: "server",
                message: fineLavoro.errorsStack.fieldsErrors[field].toString(),
            });
        });
    }, [
        fineLavoro?.errorsStack?.fieldsErrors,
        // iterConnessione.allevamento_pack?.errorsStack?.fieldsErrors,
    ]);

    useEffect(() => {
        fineLavoro?.errorsStack?.status == ErrorStatusTypes.SUCCESS &&
        setEditing(false);
    }, [
        fineLavoro,
        // iterConnessione.allevamento_pack?.errorsStack?.fieldsErrors,
    ]);

    const handlerSaveFineLavoro = (fineLavoroDaForm: FineLavoro) => {
        const formValues = getValues();
        consentiModifica &&
        dispatch(saveFineLavoro({fineLavoroToSave: {...fineLavoro, ...formValues}}));
    };

    const onSubmit: SubmitHandler<FineLavoro> = (fineLavoro) => {
        handlerSaveFineLavoro(fineLavoro);
    };

    return (
        <Box sx={{my: 1}}>
            <form onSubmit={handleSubmit(onSubmit)}>
                {fineLavoro?.errorsStack?.fieldsErrors?.non_field_errors && (
                    <Alert severity="error">
                        {fineLavoro?.errorsStack?.fieldsErrors?.non_field_errors.toString()}
                    </Alert>
                )}
                <Card elevation={3} sx={{mb: 2}}>
                    <CardHeader
                        sx={{
                            backgroundColor: fineLavoro.regolamento_esercizio_completo.regolamento_esercizio_completo
                                ? "#00aa0099"
                                : "#aa000099",
                        }}
                        title={"Regolamento d'esercizo"}
                    />
                    <CardContent>

                        <Grid container spacing={2} sx={{mb: 2}}>
                            <Grid item xs={12} sm={6} md={6} lg={4}>
                                <Typography
                                    variant="caption"
                                    component="div"
                                    color="text.secondary"
                                    gutterBottom
                                    onClick={() => setEditing(true)}
                                >
                                    data disponibilità*:
                                </Typography>

                                {!editing ? (
                                    <Typography
                                        variant="body1"
                                        component="div"
                                        // color="text.secondary"
                                        gutterBottom
                                        onClick={() => setEditing(true)}
                                        sx={{
                                            color: fineLavoro.regolamento_esercizio_data_disponibilita
                                                ? theme.palette.text.secondary
                                                : theme.palette.divider,
                                        }}
                                    >
                                        {fineLavoro.regolamento_esercizio_data_disponibilita
                                            ? convertToDateTime(fineLavoro.regolamento_esercizio_data_disponibilita)?.setLocale("it").toFormat("EEEE dd LLLL yyyy")
                                            : "inserisci data disponibilita"}
                                    </Typography>
                                ) : (
                                    <DesktopDatePicker
                                        value={convertToDateTime(fineLavoro.regolamento_esercizio_data_disponibilita)}
                                        slotProps={{
                                            textField: {
                                                required: false,
                                                error: !!errors?.regolamento_esercizio_data_disponibilita,
                                                helperText: errors?.regolamento_esercizio_data_disponibilita?.message,
                                                sx: {width: "100%"},
                                                autoComplete: "off",
                                                // disabled: !!richiestaConnessione.stato
                                            },
                                            field: {
                                                clearable: true,
                                            },
                                        }}
                                        onChange={(newValue: any) => {
                                            setEditing(true);
                                            setValue(
                                                "regolamento_esercizio_data_disponibilita",
                                                newValue ? convertToDateTime(newValue)?.toFormat("yyyy-MM-dd") : "",
                                                {
                                                    shouldDirty: true,
                                                }
                                            );
                                            clearErrors("regolamento_esercizio_data_disponibilita");
                                        }}
                                    />
                                )}
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={4}>
                                <Typography
                                    variant="caption"
                                    component="div"
                                    color="text.secondary"
                                    gutterBottom
                                    onClick={() => setEditing(true)}
                                >
                                    data inoltro.*:
                                </Typography>

                                {!editing ? (
                                    <Typography
                                        variant="body1"
                                        component="div"
                                        // color="text.secondary"
                                        gutterBottom
                                        onClick={() => setEditing(true)}
                                        sx={{
                                            color: fineLavoro.regolamento_esercizio_data_inoltro
                                                ? theme.palette.text.secondary
                                                : theme.palette.divider,
                                        }}
                                    >
                                        {fineLavoro.regolamento_esercizio_data_inoltro
                                            ? convertToDateTime(fineLavoro.regolamento_esercizio_data_inoltro)?.setLocale("it").toFormat("EEEE dd LLLL yyyy")
                                            : "inserisci data inoltro"}
                                    </Typography>
                                ) : (
                                    <DesktopDatePicker
                                        // components={{
                                        // 	OpenPickerIcon: ClockIcon,
                                        // }}
                                        // inputFormat="DD/MM/YY HH:mm"
                                        // mask={"__/__/__ __:__"}
                                        // label="Data FineLavoro"
                                        // {...register("data_ora_carico_pianificata")}
                                        value={convertToDateTime(fineLavoro.regolamento_esercizio_data_inoltro)}
                                        slotProps={{
                                            textField: {
                                                required: false,
                                                error: !!errors?.regolamento_esercizio_data_inoltro,
                                                helperText: errors?.regolamento_esercizio_data_inoltro?.message,
                                                sx: {width: "100%"},
                                                autoComplete: "off",
                                                // disabled: !!richiestaConnessione.stato
                                            },
                                            field: {
                                                clearable: true,
                                            },
                                        }}
                                        onChange={(newValue: any) => {
                                            setEditing(true);
                                            setValue(
                                                "regolamento_esercizio_data_inoltro",
                                                newValue ? convertToDateTime(newValue)?.toFormat("yyyy-MM-dd") : "",
                                                {
                                                    shouldDirty: true,
                                                }
                                            );
                                            clearErrors("regolamento_esercizio_data_inoltro");
                                        }}
                                    />
                                )}
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={4}>
                                <Typography
                                    variant="caption"
                                    component="div"
                                    color="text.secondary"
                                    gutterBottom
                                    onClick={() => setEditing(true)}
                                >
                                    data convalida*:
                                </Typography>

                                {!editing ? (
                                    <Typography
                                        variant="body1"
                                        component="div"
                                        // color="text.secondary"
                                        gutterBottom
                                        onClick={() => setEditing(true)}
                                        sx={{
                                            color: fineLavoro.regolamento_esercizio_data_convalida
                                                ? theme.palette.text.secondary
                                                : theme.palette.divider,
                                        }}
                                    >
                                        {fineLavoro.regolamento_esercizio_data_convalida
                                            ? convertToDateTime(fineLavoro.regolamento_esercizio_data_convalida)?.setLocale("it").toFormat("EEEE dd LLLL yyyy")
                                            : "inserisci data convalida"}
                                    </Typography>
                                ) : (
                                    <DesktopDatePicker
                                        // components={{
                                        // 	OpenPickerIcon: ClockIcon,
                                        // }}
                                        // inputFormat="DD/MM/YY HH:mm"
                                        // mask={"__/__/__ __:__"}
                                        // label="Data FineLavoro"
                                        // {...register("data_ora_carico_pianificata")}
                                        value={convertToDateTime(fineLavoro.regolamento_esercizio_data_convalida)}
                                        slotProps={{
                                            textField: {
                                                required: false,
                                                error: !!errors?.regolamento_esercizio_data_convalida,
                                                helperText: errors?.regolamento_esercizio_data_convalida?.message,
                                                sx: {width: "100%"},
                                                autoComplete: "off",
                                                // disabled: !!richiestaConnessione.stato
                                            },
                                            field: {
                                                clearable: true,
                                            },
                                        }}
                                        onChange={(newValue: any) => {
                                            setEditing(true);
                                            setValue(
                                                "regolamento_esercizio_data_convalida",
                                                newValue ? convertToDateTime(newValue)?.toFormat("yyyy-MM-dd") : "",
                                                {
                                                    shouldDirty: true,
                                                }
                                            );
                                            clearErrors("regolamento_esercizio_data_convalida");
                                        }}
                                    />
                                )}
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={12}>
                                <Typography
                                    variant="caption"
                                    component="div"
                                    color="text.secondary"
                                    gutterBottom
                                    onClick={() => setEditing(true)}
                                >
                                    note:
                                </Typography>

                                {!editing ? (
                                    <Typography
                                        variant="body1"
                                        component="div"
                                        // color="text.secondary"
                                        gutterBottom
                                        onClick={() => setEditing(true)}
                                    >
                                        {fineLavoro.regolamento_esercizio_note}
                                    </Typography>
                                ) : (
                                    <TextField
                                        // label="Motivo Rigetto"
                                        // disabled={schedaCarico.numero_capi_pianificati}
                                        variant="outlined"
                                        // type="number"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        {...register("regolamento_esercizio_note")}
                                        error={!!errors?.regolamento_esercizio_note}
                                        helperText={errors?.regolamento_esercizio_note?.message?.toString()}
                                        autoComplete={"off"}
                                        multiline={true}
                                        rows={3}
                                        sx={{width: "100%"}}
                                        fullWidth
                                        // onBlur={handlerDetailChaingSubmit}
                                    />
                                )}
                            </Grid>
                        </Grid>
                        <Button
                            // disabled={!isDirty}
                            variant="outlined"
                            onClick={() => {
                                setEditing(false);
                                // reset();
                            }}
                            sx={{...(!editing && {display: "none"})}}
                        >
                            Annulla
                        </Button>
                        <Button
                            disabled={!isDirty}
                            variant="outlined"
                            type="submit"
                            sx={{...(!editing && {display: "none"}), ml: 1}}
                        >
                            Salva
                        </Button>


                    </CardContent>

                    <CardContent>
                        <AllegatiPage
                            iterConnessioneId={fineLavoro.iter_connessione || 0}
                            fineLavoriId={fineLavoro.id}
                            faseIterConnessione="RDE"
                            // richiestaConnessioneId={richiestaConnessione.id || 0}
                            consentiModifica={consentiModifica}
                        />

                    </CardContent>
                </Card>
            </form>
        </Box>
    );
};

export default IterConnessioneFineLavoroRegolamentoEsercizioForm;
