import React, { useState, useEffect } from "react";

import { RichiestaConnessione } from "./richiesteConnessioneSlice";

import { StatoConnessioneTypes } from "../../../../anagrafiche/kpi/kpiSlice";

import { DateTime } from "luxon";
import { convertToDateTime } from "components/common/dateUtils/convertToDateTime";
import { theme } from "../../../../theme";
import { styled } from "@mui/system";

import Stack from "@mui/material/Stack";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

import { Typography } from "@mui/material";
import IterConnessioneRichiestaConnessioneForm from "./IterConnessioneRichiestaConnessioneForm";
import AllegatiPage from "../../../../allegati/AllegatiPage";

const NumberChip = styled(Chip)({
	"&&": {
		height: "1.5rem",
		backgroundColor: "#1b75b1",
		color: "#e1e1e1",
		fontSize: "1.2rem",
		fontWeight: 500,
		paddingLeft: 0,
		paddingRight: 0,
		borderRadius: 5,
	},
	"& .MuiChip-label": {
		paddingLeft: "5px",
		paddingRight: "5px",
	},
});
interface IterConnessioneRichiestaConnessioneCardProps {
	richiestaConnessione: RichiestaConnessione;
	// parametri: Parametri;
	// expanded: string | false;
	consentiModifica: boolean;
	iterSemplificato: boolean;
	// handleChangeRichiestaConnessionePanel: (
	// 	richiestaConnessione: RichiestaConnessione
	// ) => (event: React.SyntheticEvent, isExpanded: boolean) => void;
}
const IterConnessioneRichiestaConnessioneCard = ({
	richiestaConnessione,
	// parametri,
	// expanded,
	consentiModifica,
	iterSemplificato,
}: // handleChangeRichiestaConnessionePanel,
IterConnessioneRichiestaConnessioneCardProps) => {
	const style = {
		position: "absolute" as "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		maxWidth: 400,
		width: "95%",
		bgcolor: "background.paper",
		border: "2px solid #000",
		boxShadow: 24,
		p: 4,
		pb: 1,
		textAlign: "center",
	};

	const [expanded, setExpanded] = useState<boolean>(
		!richiestaConnessione.richiesta_rigettata
	);

	return (
		<Accordion
			key={"richiestaConnessione_panel" + richiestaConnessione.id}
			sx={{
				backgroundColor: richiestaConnessione.richiesta_rigettata
					? "#19191920"
					: "inherit",
			}}
			expanded={expanded}
			onChange={() => setExpanded(!expanded)}
		>
			<AccordionSummary
				expandIcon={<ExpandMoreIcon />}
				aria-controls="richiesta_connessione_accordion"
				id="richiesta_connessione_accordion_header"
			>
				<Grid container spacing={2}>
					<Grid item xs={8} sm={4} md={4} lg={2}>
						{/* <Stack direction="row" spacing={2} justifyContent="flex-start"> */}
						<Typography>Richiesta n.</Typography>
					</Grid>
					<Grid item xs={4} sm={2} md={2} lg={1}>
						<NumberChip
							aria-label="richiesta_connessione"
							label={richiestaConnessione.id}
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={6} lg={2}>
						{richiestaConnessione.data_invio && (
							<Typography>
								del{" "}
								{convertToDateTime(richiestaConnessione.data_invio)?.setLocale("it").toFormat("dd/MM/yyyy")}
							</Typography>
						)}
					</Grid>
					<Grid item xs={12} sm={6} md={6} lg={7}>
						<Typography>
							{
								Object.entries(StatoConnessioneTypes).find(
									([key, value]) => key == richiestaConnessione.stato
								)?.[1]
							}
						</Typography>
						{/* </Stack> */}
					</Grid>
					<Grid item xs={12} sm={6} md={6} lg={5}>
						{/* <Stack direction="row" spacing={2} justifyContent="flex-start"> */}
						{richiestaConnessione.richiesta_rigettata && (
							<Typography>
								data rigetto:{" "}
								{convertToDateTime(richiestaConnessione.data_rigetto)?.setLocale("it").toFormat("dd/MM/yyyy")}
							</Typography>
						)}
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={7}>
						{richiestaConnessione.richiesta_rigettata && (
							<Typography>{richiestaConnessione.note_rilavorazione}</Typography>
						)}
						{/* </Stack> */}
					</Grid>
				</Grid>
			</AccordionSummary>
			<AccordionDetails
				sx={{
					py: 0,
					"&:last-child": { pb: 1 },
				}}
			>
				<IterConnessioneRichiestaConnessioneForm
					richiestaConnessione={richiestaConnessione}
					consentiModifica={
						!richiestaConnessione.richiesta_rigettata && consentiModifica
					}
					iterSemplificato={iterSemplificato}
				/>
			</AccordionDetails>
			<AccordionDetails
				sx={{
					mb: 2,
				}}
			>
				<Divider
					textAlign="left"
					sx={{ mt: 2, mb: 1, color: theme.palette.primary.main }}
				>
					Allegati
				</Divider>

				{richiestaConnessione.id && !["Z", "A", "B", "C", "D", "E"].includes(richiestaConnessione.stato||"") &&(
					<AllegatiPage
						// listaDocumentiPerTipo={richiestaConnessione.listaDocumentiPerTipo}
						iterConnessioneId={richiestaConnessione.iter_connessione || 0}
						faseIterConnessione="CRC"
						richiestaConnessioneId={richiestaConnessione.id || 0}
						consentiModifica={!richiestaConnessione.richiesta_rigettata}
					/>
				)}
				{richiestaConnessione.id && !["Z", "A", "B", "C", "D"].includes(richiestaConnessione.stato||"") && (
					<AllegatiPage
						// listaDocumentiPerTipo={richiestaConnessione.listaDocumentiPerTipo}
						iterConnessioneId={richiestaConnessione.iter_connessione || 0}
						faseIterConnessione="IRC"
						richiestaConnessioneId={richiestaConnessione.id || 0}
						consentiModifica={!richiestaConnessione.richiesta_rigettata}
					/>
				)}
				{richiestaConnessione.id && !["Z", "A", "B", "C"].includes(richiestaConnessione.stato||"") && (
					<AllegatiPage
						// listaDocumentiPerTipo={richiestaConnessione.listaDocumentiPerTipo}
						iterConnessioneId={richiestaConnessione.iter_connessione || 0}
						faseIterConnessione="PAG"
						richiestaConnessioneId={richiestaConnessione.id || 0}
						consentiModifica={!richiestaConnessione.richiesta_rigettata}
					/>
				)}
				{richiestaConnessione.id && !["Z", "A"].includes(richiestaConnessione.stato||"") && (
					<AllegatiPage
						// listaDocumentiPerTipo={richiestaConnessione.listaDocumentiPerTipo}
						iterConnessioneId={richiestaConnessione.iter_connessione || 0}
						faseIterConnessione="PRC"
						richiestaConnessioneId={richiestaConnessione.id || 0}
						consentiModifica={!richiestaConnessione.richiesta_rigettata}
					/>
				)}
				{richiestaConnessione.id && (
					<AllegatiPage
						// listaDocumentiPerTipo={richiestaConnessione.listaDocumentiPerTipo}
						iterConnessioneId={richiestaConnessione.iter_connessione || 0}
						faseIterConnessione="DDC"
						richiestaConnessioneId={richiestaConnessione.id || 0}
						consentiModifica={!richiestaConnessione.richiesta_rigettata}
					/>
				)}
			</AccordionDetails>
		</Accordion>
	);
};

export default IterConnessioneRichiestaConnessioneCard;
