import {Commessa, TipoIntervento} from "../commesseSlice";

export const prevedePod = (commessa: Commessa, tipiIntervento: TipoIntervento[]) => {
    let prevedePod: boolean = false;
    const tipiInterventoCommessa = tipiIntervento.filter((tipo) => commessa.tipi_intervento?.includes(tipo.id))
    for (const tipo of tipiInterventoCommessa) {
        if (tipo.prevede_pod) {
            prevedePod = true;
        }
    }
    return prevedePod;
}
