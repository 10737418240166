import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";

import * as utentiApi from "../../../api/utenti/utentiApi";
import { ACCESS_TOKEN, REFRESH_TOKEN } from "../../../api/apiUtils";
import { toast } from "react-toastify";
import {
	ErrorStatusTypes,
	ErrorsStack,
	parseErrorMessage,
} from "../../common/errorsDeclarations";

import { convertToDateTime } from "components/common/dateUtils/convertToDateTime";

// import { Lotto } from "../pianificazione/pianificazioneSlice";
// import { Allevamento } from "../anagrafiche/anagraficheSlice";
import { SankeyLink } from "recharts/types/util/types";
export interface User {
	id: number;
	username: string;
	first_name?: string;
	last_name?: string;
	email: string;
	last_login?: string;
}

export interface Utente {
	id: number | null;
	user: User | null;
	cliente: number | null;
	general_contractor: boolean;
	cer: number | null;
	// solo_lettura: boolean;
	errorsStack: ErrorsStack;
}

export interface Utenti {
	count: number;
	page: number;
	num_pages: number;
	next?: URL;
	previous?: URL;
	results: Utente[];
	errorsStack: ErrorsStack;
}

export interface UtentiStrutturaState {
	utenti: Utenti;
	utente_id: number | null;
	// lottiCommittente: LottiCommittente;
	// allevamentiCommittente: AllevamentiCommittente;
}

const initialState: UtentiStrutturaState = {
	utenti: {
		count: 0,
		page: 0,
		num_pages: 0,
		next: undefined,
		previous: undefined,
		results: [],
		errorsStack: { status: ErrorStatusTypes.OK },
	},
	utente_id: null,
};

export const fetchUtenti = createAsyncThunk(
	"utenti/fetchUtenti",
	async (parametri: {
		cliente?: number;
		cer?: number;
		produttore?: number;
		numeroRecord?: number;
		page?: number;
		search?: string;
	}) => {
		return await utentiApi.fetchUtenti(
			parametri?.cliente,
			parametri?.cer,
			parametri?.produttore,
			parametri?.numeroRecord,
			parametri?.page,
			parametri?.search
		);
	}
);

export const getUtente = createAsyncThunk(
	"utenti/getUtente",
	async (utente_id: number) => {
		return await utentiApi.getUtente(utente_id);
	}
);

export const saveUtente = createAsyncThunk(
	"utenti/saveUtente",
	async (utenteToSave: Utente, thunkApi) => {
		return await utentiApi.saveUtente(utenteToSave);
	}
);

export const deleteUtente = createAsyncThunk(
	"utenti/deleteUtente",
	async (utenteToDelete: Utente, thunkApi) => {
		return await utentiApi.deleteUtente(utenteToDelete);
	}
);

export const invitaUtente = createAsyncThunk(
	"utenti/invitaUtente",
	async (utente: Utente, thunkApi) => {
		return await utentiApi.invitaUtente(utente);
	}
);

export const utentiSlice = createSlice({
	name: "utenti",
	initialState,
	reducers: {
		setUtenteCorrente: (
			state,
			action: PayloadAction<{
				utente_id: number | null;
			}>
		) => {
			state.utente_id = action.payload.utente_id;
		},
	},
	extraReducers: (builder) => {
		// fetch utenti
		builder.addCase(fetchUtenti.pending, (state, action) => {
			state.utenti.errorsStack.status = ErrorStatusTypes.PENDING;
		});
		builder.addCase(fetchUtenti.fulfilled, (state, action) => {
			// state.lotti = action.payload;
			state.utenti = action.payload;
			state.utenti.errorsStack = { status: ErrorStatusTypes.OK };
		});
		builder.addCase(fetchUtenti.rejected, (state, action) => {
			state.utenti.errorsStack = parseErrorMessage(action.error);
		});

		// get utente
		///////// RIVEDERE /////////
		builder.addCase(getUtente.pending, (state, action) => {
			state.utenti.errorsStack.status = ErrorStatusTypes.PENDING;
		});
		builder.addCase(getUtente.fulfilled, (state, action) => {
			state.utenti.errorsStack.status = ErrorStatusTypes.OK;
			let prima_assente: boolean = true;
			state.utenti.results = state.utenti.results.map(
				(documentoDiTrasporto) => {
					if (documentoDiTrasporto.id == action.payload.id) {
						// return action.payload;
						prima_assente = false;
						return {
							...action.payload,
							data_ora_trasporto: convertToDateTime(
								action.payload.user.last_login
							)?.toISO(),
							errorsStack: {
								status: ErrorStatusTypes.OK,
								fieldsErrors: undefined,
							},
						};
					} else {
						return documentoDiTrasporto;
					}
				}
			);
			if (prima_assente) {
				state.utenti.results = [...state.utenti.results, action.payload];
			}
		});
		builder.addCase(getUtente.rejected, (state, action) => {
			state.utenti.errorsStack = parseErrorMessage(action.error);
		});

		// save utente
		builder.addCase(saveUtente.pending, (state, action) => {
			state.utenti.errorsStack = {
				status: ErrorStatusTypes.PENDING,
				saving: true,
			};
		});

		builder.addCase(saveUtente.fulfilled, (state, action) => {
			if (action.meta.arg.id) {
				state.utenti.results = state.utenti.results.map((utente) => {
					if (utente.id == action.payload.id) {
						return action.payload;
					} else {
						return utente;
					}
				});
			} else {
				state.utenti.results = [...state.utenti.results, action.payload];
			}
			state.utente_id = action.payload.id;

			toast.success("Utente salvato.");

			state.utenti.errorsStack = {
				status: ErrorStatusTypes.SUCCESS,
				saving: false,
			};
		});
		builder.addCase(saveUtente.rejected, (state, action) => {
			state.utenti.errorsStack = parseErrorMessage(action.error);
		});

		// invita utente
		builder.addCase(invitaUtente.pending, (state, action) => {
			state.utenti.errorsStack.status = ErrorStatusTypes.PENDING;
		});

		builder.addCase(invitaUtente.fulfilled, (state, action) => {
			// state.utentiEsterni.results = state.utentiEsterni.results.filter(
			// 	(documentoDiTrasporto) =>
			// 		documentoDiTrasporto.id != action.payload.documentoDiTrasporto_id
			// );
			toast.success("E-mail di invito inviata.");

			state.utenti.errorsStack.status = ErrorStatusTypes.OK;
		});

		builder.addCase(invitaUtente.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");

			state.utenti.errorsStack = parseErrorMessage(action.error);
		});
	},
});

// Action creators are generated for each case reducer function
export const { setUtenteCorrente } = utentiSlice.actions;

export const utentiSliceReducer = utentiSlice.reducer;
