import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import {
	IterConnessione,
	TipoTensioneTypes,
	saveIterConnessione,
	TipologiaEdiliziaTypes,
	TitoloPossessoTypes,
} from "../../iterConnessioneSlice";
import { useAppDispatch, RootState } from "../../../../../redux/store";
import {
	ErrorStatusTypes,
	ErrorsStack,
} from "../../../../common/errorsDeclarations";

import { theme } from "../../../../theme";

import { utenteAutorizzato } from "../../../../authentication/UtenteAutorizzato";
import { UtenteLogged } from "../../../../authentication/authenticationSlice";
import { Enea, saveEnea } from "./eneaSlice";
import { Comune } from "../../../../anagrafiche/comuni/comuniSlice";
import {
	Soggetto,
	getProduttore,
} from "components/anagrafiche/produttori/produttoriSlice";
import { convertToDateTime } from "components/common/dateUtils/convertToDateTime";

import { Gestore } from "../../../../anagrafiche/gestori/gestoriSlice";
import { useForm, SubmitHandler, Controller } from "react-hook-form";

import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import ReactHookFormSelect from "../../../../common/ReactHookFormSelect";
import MenuItem from "@mui/material/MenuItem";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { Routes_path } from "../../../../routerApp";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Alert from "../../../../common/Alert";
import Autocomplete from "@mui/material/Autocomplete";

import { Button, Divider } from "@mui/material";
import ClockIcon from "@mui/icons-material/AccessTime";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { DateTime } from "luxon";




interface IterConnessioneEneaDatiCardProps {
	iterConnessione: IterConnessione;
	produttore: Soggetto;
	// enea: Enea;
	// consentiModifica: boolean;
}

const IterConnessioneEneaDatiCard = ({
	iterConnessione,
	produttore,
}: // enea,
// consentiModifica = false,
IterConnessioneEneaDatiCardProps) => {
	const dispatch = useAppDispatch();

	useEffect(() => {
		iterConnessione.usufruttore &&
			dispatch(getProduttore(iterConnessione.usufruttore));
	}, [iterConnessione.usufruttore]);

	const usufruttore_anagrafica: Soggetto | undefined = useSelector<
		RootState,
		Soggetto | undefined
	>((state) =>
		state.produttoriState.produttori.results.find(
			(soggetto) => soggetto?.id == iterConnessione?.usufruttore
		)
	);
	const comune_nascita: Comune | undefined = useSelector<
		RootState,
		Comune | undefined
	>((state) =>
		state.comuniState.comuni.results.find(
			(comune) => comune?.id == usufruttore_anagrafica?.comune_nascita
		)
	);
	const comune_residenza: Comune | undefined = useSelector<
		RootState,
		Comune | undefined
	>((state) =>
		state.comuniState.comuni.results.find(
			(comune) => comune?.id == usufruttore_anagrafica?.comune
		)
	);

	return (
		<Card>
			<Paper elevation={3} sx={{ backgroundColor: "azure" }}>
				<CardContent>
					<Typography variant="h5" color="text.secondary" gutterBottom>
						Soggetto che usufruisce della detrazione
					</Typography>
					<Grid container spacing={2}>
						<Grid item xs={12} sm={6} md={6} lg={6}>
							<Typography variant="body2" display="inline">
								Nome:{" "}
							</Typography>
							<Typography display="inline" fontWeight="bold">
								{iterConnessione.usufruttore_descrizione}
							</Typography>
						</Grid>
						{/* <Grid item xs={12} sm={6} md={6} lg={6}>
							<Typography variant="body2" display="inline"></Typography>
							Cognome:{" "}
							<Typography display="inline" fontWeight="bold">
								{usufruttore_anagrafica?.cognome}
							</Typography>
						</Grid> */}
					</Grid>
					<Grid container spacing={2}>
						<Grid item xs={12} sm={12} md={12} lg={12}>
							<Typography variant="body2" display="inline">
								Luogo e data di nascita:{" "}
							</Typography>
							<Typography display="inline" fontWeight="bold">
								{comune_nascita?.nome + ` (${comune_nascita?.provincia})`} -{" "}
								{convertToDateTime(usufruttore_anagrafica?.data_nascita)?.setLocale("it").toFormat("DDDD")}
							</Typography>
						</Grid>
					</Grid>
					<Grid container spacing={2}>
						<Grid item xs={12} sm={12} md={12} lg={12}>
							<Typography variant="body2" display="inline">
								Codice Fiscale:{" "}
							</Typography>
							<Typography
								display="inline"
								fontWeight="bold"
								textTransform={"uppercase"}
							>
								{usufruttore_anagrafica?.codice_fiscale}
							</Typography>
						</Grid>
					</Grid>
					<Grid container spacing={2}>
						<Grid item xs={12} sm={12} md={12} lg={12}>
							<Typography variant="body2" display="inline">
								Indirizzo di residenza:{" "}
							</Typography>
							<Typography display="inline" fontWeight="bold">
								{usufruttore_anagrafica?.indirizzo}
								{", "}
								{usufruttore_anagrafica?.numero_civico}
								{" - "}
								{usufruttore_anagrafica?.cap}{" "}
								{comune_residenza?.nome + ` (${comune_residenza?.provincia})`}
							</Typography>
						</Grid>
					</Grid>
					<Divider sx={{ my: 1.2 }} />
					<Typography variant="h5" color="text.secondary" gutterBottom>
						{'Dati dell\'immobile oggetto dell\'intervento'}
					</Typography>
					<Grid container spacing={2}>
						<Grid item xs={12} sm={6} md={6} lg={6}>
							<Typography variant="body2" display="inline">
								Superficie calpestabile indicativa (mq):{" "}
							</Typography>
							<Typography display="inline" fontWeight="bold">
								{iterConnessione.pod_commessa?.superficie_calpestabile}
							</Typography>
						</Grid>
						<Grid item xs={12} sm={6} md={6} lg={6}>
							<Typography variant="body2" display="inline">
								Numero di unità immobiliari dell'edificio:{" "}
							</Typography>
							<Typography display="inline" fontWeight="bold">
								{iterConnessione.pod_commessa?.numero_unita_immobiliari}
							</Typography>
						</Grid>
					</Grid>
					<Grid container spacing={2}>
						<Grid item xs={12} sm={6} md={6} lg={6}>
							<Typography variant="body2" display="inline">
								Anno di costruzione (anche stimato):{" "}
							</Typography>
							<Typography display="inline" fontWeight="bold">
								{iterConnessione.pod_commessa?.anno_costruzione}
							</Typography>
						</Grid>
						<Grid item xs={12} sm={6} md={6} lg={6}>
							<Typography variant="body2" display="inline">
								Destinato ad uso residenziale:{" "}
							</Typography>
							<Typography display="inline" fontWeight="bold">
								{iterConnessione.pod_commessa?.destinazione_residenziale
									? "SI"
									: "NO"}
							</Typography>
						</Grid>
					</Grid>
					<Grid container spacing={2}>
						<Grid item xs={12} sm={6} md={6} lg={6}>
							<Typography variant="body2" display="inline">
								Titolo di possesso:{" "}
							</Typography>
							<Typography display="inline" fontWeight="bold">
								{
									Object.entries(TitoloPossessoTypes).find(
										([key, value]) =>
											key == iterConnessione.pod_commessa?.titolo_possesso
									)?.[1]
								}
							</Typography>
						</Grid>
						<Grid item xs={12} sm={6} md={6} lg={6}>
							<Typography variant="body2" display="inline">
								Tipologia edilizia:{" "}
							</Typography>
							<Typography display="inline" fontWeight="bold">
								{iterConnessione.pod_commessa?.destinazione_residenziale
									? "SI"
									: "NO"}
								{
									Object.entries(TipologiaEdiliziaTypes).find(
										([key, value]) =>
											key == iterConnessione.pod_commessa?.tipologia_edilizia
									)?.[1]
								}
							</Typography>
						</Grid>
						<Grid item xs={12} sm={6} md={6} lg={12}>
							<Typography variant="body2" display="inline">
								Note:{" "}
							</Typography>
							<Typography display="inline" fontWeight="bold">
								{iterConnessione.pod_commessa?.tipologia_edilizia_note}
							</Typography>
						</Grid>
					</Grid>
				</CardContent>
			</Paper>
		</Card>
	);
};

export default IterConnessioneEneaDatiCard;
