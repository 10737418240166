import {useSelector} from "react-redux";
import React, {useEffect, useState} from "react";

import {
    RichiestaConnessione,
    saveRichiestaConnessione,
} from "./richiesteConnessioneSlice";
import {FineLavoro, saveFineLavoro} from "./fineLavori/fineLavoriSlice";
import {Allaccio, saveAllaccio} from "./allaccio/allaccioSlice";
import {
    IterConnessione,
    presaInCarico,
    avviaProgetto,
    assegnaPratica,
} from "components/main/iterConnessioni/iterConnessioneSlice";
import {Soggetto} from "../../../../anagrafiche/produttori/produttoriSlice";

import {DateTime} from "luxon";

import {RootState, useAppDispatch} from "../../../../../redux/store";
import {TipoPratica} from "../../../../anagrafiche/tipiPratica/tipiPraticaSlice";

import RigettaRichiestaConnessioneForm from "./RigettaRichiestaConnessioneForm";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import {utenteAutorizzato} from "../../../../authentication/UtenteAutorizzato";
import {UtenteLogged} from "../../../../authentication/authenticationSlice";
import {PermissionTypes} from "../../../../authentication/permissionsGroups";

import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";

interface AzioniConnessioneCardProps {
    iterConnessione: IterConnessione;
    richiestaConnessione?: RichiestaConnessione;
    fineLavoro?: FineLavoro;
    allaccio?: Allaccio;
}

const AzioniConnessioneCard = ({
                                   iterConnessione,
                                   richiestaConnessione,
                                   fineLavoro,
                                   allaccio,
                               }: AzioniConnessioneCardProps) => {

    const dispatch = useAppDispatch();
    const [formRigetto, setFormRigetto] = useState<boolean>(false);

    const utente = useSelector<RootState, UtenteLogged>(
        (state) => state.authentication.utenteLogged
    );
    const tipiPratica = useSelector<RootState, TipoPratica[]>(
        (state) => state.tipiPraticaState.tipiPratica.results
    );
    return richiestaConnessione && iterConnessione.stato == "H" ? (
        <Card sx={{mt: 2, backgroundColor: "#ddd"}}>
            <CardContent>
                {iterConnessione?.iter_semplificato ? (
                    <Stack sx={{width: "100%"}} spacing={2}>
                        {!richiestaConnessione.connessione_confermabile
                            .connessione_confermabile && (
                            <Alert severity="error" sx={{mt: 1}}>
                                <AlertTitle>Attenzione manca:</AlertTitle>
                                <Typography
                                    variant="body2"
                                    sx={{whiteSpace: "pre-line"}}
                                >
                                    {richiestaConnessione.connessione_confermabile.dati_mancanti}
                                </Typography>
                            </Alert>
                        )}
                        <Button
                            variant="contained"
                            color="success"
                            size="large"
                            disabled={
                                !richiestaConnessione.connessione_confermabile
                                    .connessione_confermabile
                            }
                            sx={{width: "100%", mt: 1}}
                            onClick={() => {
                                richiestaConnessione &&
                                dispatch(
                                    saveRichiestaConnessione({
                                        richiestaConnessioneToSave: richiestaConnessione,
                                        azione: "conferma",
                                    })
                                );
                            }}
                        >
                            Segna come confermata
                        </Button>
                    </Stack>
                ) : (
                    <Stack sx={{width: "100%"}} spacing={2}>
                        {richiestaConnessione.stato == "Z" &&
                            !richiestaConnessione.preventivo_richiedibile
                                .preventivo_richiedibile && (
                                <Alert severity="error" sx={{mt: 1}}>
                                    <AlertTitle>Attenzione manca:</AlertTitle>
                                    <Typography
                                        variant="body2"
                                        // component="div"
                                        // color="text.secondary"
                                        // sx={{
                                        // 	color: theme?.palette?.error.main,
                                        // }}
                                        sx={{whiteSpace: "pre-line"}}
                                    >
                                        {richiestaConnessione.preventivo_richiedibile.dati_mancanti}
                                    </Typography>
                                </Alert>
                            )}




                        {["B", "C"].includes(richiestaConnessione.stato) &&
                            utenteAutorizzato(utente, [
                                PermissionTypes.GESTORI_ITER,
                                PermissionTypes.OPERATORI,
                            ]) && (
                                <Stack sx={{width: "100%"}} spacing={2}>
                                    {!richiestaConnessione.pagamento_richiedibile
                                        .pagamento_richiedibile && (
                                        <Alert severity="error" sx={{mt: 1}}>
                                            <AlertTitle>Attenzione manca:</AlertTitle>
                                            <Typography
                                                variant="body2"
                                                // component="div"
                                                // color="text.secondary"
                                                // sx={{
                                                // 	color: theme?.palette?.error.main,
                                                // }}
                                                sx={{whiteSpace: "pre-line"}}
                                            >
                                                {
                                                    richiestaConnessione.pagamento_richiedibile
                                                        .dati_mancanti
                                                }
                                            </Typography>
                                        </Alert>
                                    )}

                                    <Button
                                        variant="contained"
                                        size="large"
                                        disabled={
                                            !richiestaConnessione.pagamento_richiedibile
                                                .pagamento_richiedibile ||
                                            richiestaConnessione.pagamento_effettuato
                                                .pagamento_effettuato
                                        }
                                        sx={{width: "100%", mt: 1}}
                                        onClick={() => {
                                            richiestaConnessione &&
                                            dispatch(
                                                saveRichiestaConnessione({
                                                    richiestaConnessioneToSave: richiestaConnessione,
                                                    azione: "richiedi_pagamento",
                                                })
                                            );
                                        }}
                                    >
                                        richiedi pagamento
                                    </Button>
                                </Stack>
                            )}
                        {["D"].includes(richiestaConnessione.stato) &&
                            utenteAutorizzato(utente, [
                                PermissionTypes.GESTORI_ITER,
                                PermissionTypes.OPERATORI,
                                PermissionTypes.CLIENTI,
                                // PermissionTypes.GESTORI_ITER,
                            ]) && (
                                <>
                                    {!richiestaConnessione.pagamento_effettuato
                                        .pagamento_effettuato && (
                                        <Alert severity="error" sx={{mt: 1}}>
                                            <AlertTitle>Attenzione manca:</AlertTitle>
                                            <Typography
                                                variant="body2"
                                                // component="div"
                                                // color="text.secondary"
                                                // sx={{
                                                // 	color: theme?.palette?.error.main,
                                                // }}
                                                sx={{whiteSpace: "pre-line"}}
                                            >
                                                {
                                                    richiestaConnessione.pagamento_effettuato
                                                        .dati_mancanti
                                                }
                                            </Typography>
                                        </Alert>
                                    )}
                                    <Button
                                        variant="contained"
                                        size="large"
                                        sx={{width: "100%", mt: 1}}
                                        disabled={
                                            !richiestaConnessione.pagamento_effettuato
                                                .pagamento_effettuato
                                        }
                                        onClick={() => {
                                            richiestaConnessione &&
                                            dispatch(
                                                saveRichiestaConnessione({
                                                    richiestaConnessioneToSave: richiestaConnessione,
                                                    azione: "invia_accettazione",
                                                })
                                            );
                                        }}
                                    >
                                        invia attestazione di pagamento ad I43
                                    </Button>
                                </>
                            )}
                        {["E"].includes(richiestaConnessione.stato) &&
                            utenteAutorizzato(utente, [
                                PermissionTypes.GESTORI_ITER,
                                PermissionTypes.OPERATORI,
                                // PermissionTypes.GESTORI_ITER,
                            ]) && (
                                <>
                                    {!richiestaConnessione.accettazione_inoltrabile
                                        .accettazione_inoltrabile && (
                                        <Alert severity="error" sx={{mt: 1}}>
                                            <AlertTitle>Attenzione manca:</AlertTitle>
                                            <Typography
                                                variant="body2"
                                                // component="div"
                                                // color="text.secondary"
                                                // sx={{
                                                // 	color: theme?.palette?.error.main,
                                                // }}
                                                sx={{whiteSpace: "pre-line"}}
                                            >
                                                {
                                                    richiestaConnessione.accettazione_inoltrabile
                                                        .dati_mancanti
                                                }
                                            </Typography>
                                        </Alert>
                                    )}
                                    <Button
                                        variant="contained"
                                        size="large"
                                        disabled={
                                            !richiestaConnessione.accettazione_inoltrabile
                                                .accettazione_inoltrabile
                                        }
                                        sx={{width: "100%", mt: 1}}
                                        onClick={() => {
                                            richiestaConnessione &&
                                            dispatch(
                                                saveRichiestaConnessione({
                                                    richiestaConnessioneToSave: richiestaConnessione,
                                                    azione: "inoltrata_gestore",
                                                })
                                            );
                                        }}
                                    >
                                        segna come inoltrata al gestore
                                    </Button>
                                </>
                            )}
                    </Stack>
                )}
                {!formRigetto &&
                    utenteAutorizzato(utente, [
                        PermissionTypes.GESTORI_ITER,
                        PermissionTypes.OPERATORI,
                    ]) && (
                        <Button
                            variant="contained"
                            color="secondary"
                            size="large"
                            disabled={!richiestaConnessione}
                            sx={{width: "100%", mt: 1}}
                            onClick={() => {
                                setFormRigetto(!formRigetto);
                            }}
                        >
                            rigetta
                        </Button>
                    )}
                {richiestaConnessione && formRigetto && (
                    <RigettaRichiestaConnessioneForm
                        consentiModifica={true}
                        richiestaConnessione={richiestaConnessione}
                        annullaRigetto={() => setFormRigetto(false)}
                    />
                )}
            </CardContent>
        </Card>
    ) : (
        <>
            {!fineLavoro?.fine_lavori_completo.fine_lavori_completo && (
                <Alert severity="error" sx={{mt: 1}}>
                    <AlertTitle>Attenzione manca:</AlertTitle>
                    <Typography
                        variant="body2"
                        sx={{whiteSpace: "pre-line"}}
                    >
                        {fineLavoro?.fine_lavori_completo.dati_mancanti}
                    </Typography>
                </Alert>
            )}

            {iterConnessione.stato == "I" && (
                <Button
                    variant="contained"
                    size="large"
                    disabled={!fineLavoro?.fine_lavori_completo.fine_lavori_completo}
                    sx={{width: "100%", mt: 1}}
                    onClick={() => {
                        fineLavoro &&
                        dispatch(
                            saveFineLavoro({
                                fineLavoroToSave: fineLavoro,
                                azione: "concludi",
                            })
                        );
                    }}
                >
                    Concludi fase Fine Lavoro
                </Button>
            )}

            {!allaccio?.allaccio_completo.allaccio_completo && (
                <Alert severity="error" sx={{mt: 1}}>
                    <AlertTitle>Attenzione manca:</AlertTitle>
                    <Typography
                        variant="body2"
                        sx={{whiteSpace: "pre-line"}}
                    >
                        {allaccio?.allaccio_completo.dati_mancanti}
                    </Typography>
                </Alert>
            )}

            {iterConnessione.stato == "L" && (
                <Button
                    variant="contained"
                    size="large"
                    disabled={!allaccio?.allaccio_completo.allaccio_completo}
                    sx={{width: "100%", mt: 1}}
                    onClick={() => {
                        allaccio &&
                        dispatch(
                            saveAllaccio({
                                allaccioToSave: allaccio,
                                azione: "concludi",
                            })
                        );
                    }}
                >
                    Concludi fase Allaccio
                </Button>
            )}
        </>
    );
};

export default AzioniConnessioneCard;
