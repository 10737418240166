import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";

import {
	ErrorStatusTypes,
	ErrorsStack,
	parseErrorMessage,
} from "../../common/errorsDeclarations";
import * as produttoriApi from "../../../api/anagrafiche/produttoriApi";
import { toast } from "react-toastify";
import { Pod } from "../../main/pods/podsSlice";
import {
	ServizioAdempimenti,
	Adempimento,
} from "../../main/adempimenti/adempimentiSlice";
import { getIterConnessione } from "../../main/iterConnessioni/iterConnessioneSlice";

import { getCommessa } from "../../main/commesse/commesseSlice";
export enum TipoPersoneTypes {
	PF = "persona fisica",
	PG = "persona giuridica",
}

export interface IterConnessioneLight {
	id?: number | null | undefined;
	commessa?: string;
	codice_pod?: string;
	codice_pratica?: string;
	iter_semplificato: boolean;
	tipo_pratica?: string;
	cliente?: number;
	installatore?: number;
	indirizzo?: string;
	numero_civico?: string;
	cap?: string;
	comune?: number;
	stato: string;
	data_ultimo_stato?: string | Date;
	stato_richiesta_connessione?: string;
	errorsStack?: ErrorsStack;
}

export interface Soggetto {
	id?: number;
	nome?: string;
	cognome?: string;
	nominativo?: string;
	tipo_persona: "PF" | "PG";
	codice_fiscale?: string;
	ragione_sociale?: string;
	piva?: string;
	comune_nascita?: number | null;
	data_nascita?: string | Date;
	nazione_nascita?: string;
	indirizzo?: string;
	numero_civico?: string;
	cap?: string;
	comune?: number | null;
	anagrafica_completa?: boolean;
	cellulare?: number;
	telefono_fisso?: number;
	email?: string;
	email_alias?: string;
	gse_id?: string;
	gse_psw?: string;
	gaudi_id?: string;
	gaudi_psw?: string;
	numero_iter_connessioni?: number;
	iter_connessioni?: IterConnessioneLight[];
	numero_pods?: number;
	pods?: Pod[];
	servizi_adempimenti?: ServizioAdempimenti[];
	adempimenti?: Adempimento[];
	updated_at?: string;
	errorsStack?: ErrorsStack;
}

export interface Produttori {
	count: number;
	page: number;
	num_pages: number;
	next?: URL;
	previous?: URL;
	results: Soggetto[];
	errorsStack: ErrorsStack;
}

export interface ProduttoriStrutturaState {
	produttori: Produttori;
	soggettoCorrenteId?: number;
	produttoreCorrenteId?: number;
}

const initialState: ProduttoriStrutturaState = {
	produttori: {
		count: 0,
		page: 0,
		num_pages: 0,
		next: undefined,
		previous: undefined,
		results: [],
		errorsStack: { status: ErrorStatusTypes.OK },
	},
};

export const fetchProduttori = createAsyncThunk(
	"produttori/fetchProduttori",
	async (parametri: {
		numeroRecord?: number;
		page?: number;
		search?: string;
	}) => {
		return await produttoriApi.fetchProduttori(
			parametri.numeroRecord,
			parametri.page,
			parametri.search
		);
	}
);

export const getProduttore = createAsyncThunk(
	"produttori/getProduttore",
	async (produttoreId: number) => {
		return await produttoriApi.getProduttore(produttoreId);
	}
);

export const saveProduttore = createAsyncThunk(
	"produttori/saveProduttore",
	async (
		parametri: {
			produttoreToSave: Soggetto;
			commessa_id_da_agganciare_come_contraente?: number;
			commessa_id_da_agganciare_come_titolare?: number;
			commessa_id_da_agganciare_come_produttore?: number;
			iterConnessione_id_da_agganciare_come_produttore?: number;
			iterConnessione_id_da_agganciare_come_usufruttore?: number;
			iterConnessione_id_to_refresh?: number;
			azione?: string
		},
		thunkApi
	) => {
		return await produttoriApi
			.saveProduttore(
				parametri.produttoreToSave,
				parametri.commessa_id_da_agganciare_come_contraente,
				parametri.commessa_id_da_agganciare_come_titolare,
				parametri.commessa_id_da_agganciare_come_produttore,
				parametri.iterConnessione_id_da_agganciare_come_produttore,
				parametri.iterConnessione_id_da_agganciare_come_usufruttore,
				parametri.azione
			)
			.then((response) => {
				parametri.commessa_id_da_agganciare_come_contraente &&
					thunkApi.dispatch(
						getCommessa({commessaId: parametri.commessa_id_da_agganciare_come_contraente})
					);
				parametri.commessa_id_da_agganciare_come_titolare &&
					thunkApi.dispatch(
						getCommessa({commessaId: parametri.commessa_id_da_agganciare_come_titolare})
					);
				parametri.commessa_id_da_agganciare_come_produttore &&
					thunkApi.dispatch(
						getCommessa({commessaId: parametri.commessa_id_da_agganciare_come_produttore})
					);
				parametri.iterConnessione_id_da_agganciare_come_produttore &&
					thunkApi.dispatch(
						getIterConnessione({
							iterConnessioneId:
								parametri.iterConnessione_id_da_agganciare_come_produttore,
						})
					);
				parametri.iterConnessione_id_da_agganciare_come_usufruttore &&
					thunkApi.dispatch(
						getIterConnessione({
							iterConnessioneId:
								parametri.iterConnessione_id_da_agganciare_come_usufruttore,
						})
					);
				parametri.iterConnessione_id_to_refresh &&
					thunkApi.dispatch(
						getIterConnessione({
							iterConnessioneId:
								parametri.iterConnessione_id_to_refresh,
						})
					);
				return response;
			});
	}
);

export const deleteProduttore = createAsyncThunk(
	"produttori/deleteProduttore",
	async (produttoreToDelete: Soggetto, thunkApi) => {
		return await produttoriApi.deleteProduttore(produttoreToDelete);
	}
);

export const produttoriSlice = createSlice({
	name: "produttori",
	initialState,
	reducers: {
		resetProduttoreCorrente: (state) => {
			state.produttoreCorrenteId = undefined;
		},
	},
	extraReducers: (builder) => {
		// fetch Produttori
		builder.addCase(fetchProduttori.pending, (state, action) => {
			state.produttori.errorsStack = { status: ErrorStatusTypes.PENDING };
		});
		builder.addCase(fetchProduttori.fulfilled, (state, action) => {
			state.produttori = action.payload;
			state.produttoreCorrenteId = undefined;
			state.produttori.errorsStack = { status: ErrorStatusTypes.OK };
		});
		builder.addCase(fetchProduttori.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");
			state.produttori.errorsStack = {
				status: ErrorStatusTypes.ERROR,
				fieldsErrors: JSON.parse(action?.error?.message || ""),
			};
		});

		// get Soggetto
		builder.addCase(getProduttore.pending, (state, action) => {
			state.produttori.errorsStack = { status: ErrorStatusTypes.PENDING };
		});
		builder.addCase(getProduttore.fulfilled, (state, action) => {
			// state.produttori.results = state.produttori.results.map((produttore) => {
			// 	if (produttore.id == action.payload.id) {
			// 		return action.payload;
			// 	} else {
			// 		return produttore;
			// 	}
			// });

			state.produttori.results = state.produttori.results.filter(
				(produttore) => produttore.id != action.payload.id
			);
			state.produttori.results.push(action.payload);
			state.produttoreCorrenteId = action.payload.id;
			state.produttori.errorsStack = { status: ErrorStatusTypes.OK };
		});
		builder.addCase(getProduttore.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");
			state.produttori.errorsStack = {
				status: ErrorStatusTypes.ERROR,
				fieldsErrors: JSON.parse(action?.error?.message || ""),
			};
		});

		// save Soggetto
		builder.addCase(saveProduttore.pending, (state, action) => {
			state.produttori.errorsStack = { status: ErrorStatusTypes.PENDING };
		});
		builder.addCase(saveProduttore.fulfilled, (state, action) => {
			state.produttori.results = state.produttori.results.filter(
				(produttore) => produttore.id !== action.payload.id
			);

			state.produttori.results.push({
				...action.payload,
				errorsStack: { status: ErrorStatusTypes.SUCCESS },
			});
			state.produttoreCorrenteId = action.payload.id;
			state.produttori.errorsStack = { status: ErrorStatusTypes.SUCCESS };
			toast.success("Soggetto salvato.");
		});
		builder.addCase(saveProduttore.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");
			state.produttori.results = state.produttori.results.map((produttore) => {
				if (produttore.id == action.meta.arg.produttoreToSave.id) {
					return {
						...produttore,
						errorsStack: {
							status: ErrorStatusTypes.ERROR,
							fieldsErrors: JSON.parse(action?.error?.message || ""),
						},
					};
				} else {
					return produttore;
				}
			});
			state.produttori.errorsStack = {
				status: ErrorStatusTypes.ERROR,
				fieldsErrors: JSON.parse(action?.error?.message || ""),
			};
		});
		// cancella Soggetto
		builder.addCase(deleteProduttore.pending, (state, action) => {
			state.produttori.errorsStack = { status: ErrorStatusTypes.PENDING };
		});
		builder.addCase(deleteProduttore.fulfilled, (state, action) => {
			state.produttori.errorsStack = { status: ErrorStatusTypes.SUCCESS };
			state.produttori.results = state.produttori.results.filter(
				(produttore) => produttore.id != action.meta.arg.id
			);
			toast.success(action.payload.message || "Regime commerciale cancellato.");
		});
		builder.addCase(deleteProduttore.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");
			state.produttori.errorsStack = {
				status: ErrorStatusTypes.ERROR,
				fieldsErrors: JSON.parse(action?.error?.message || ""),
			};
		});
	},
});

// Action creators are generated for each case reducer function
export const { resetProduttoreCorrente } = produttoriSlice.actions;

export const produttoriSliceReducer = produttoriSlice.reducer;
