import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { NumericFormat } from "react-number-format";

import {
	IterConnessione,
	TipoTensioneTypes,
	saveIterConnessione,
} from "../../../iterConnessioneSlice";

import {
	Commessa,
	Prodotto,
	aggiungiProdotto,
	TipoProdottoTypes,
} from "../../../../commesse/commesseSlice";

import { useAppDispatch, RootState } from "../../../../../../redux/store";
import {
	ErrorStatusTypes,
	ErrorsStack,
} from "../../../../../common/errorsDeclarations";

import { theme } from "../../../../../theme";

import { utenteAutorizzato } from "../../../../../authentication/UtenteAutorizzato";
import { UtenteLogged } from "../../../../../authentication/authenticationSlice";
import { Cliente } from "../../../../../anagrafiche/clienti/clientiSlice";
import { Installatore } from "../../../../../anagrafiche/installatori/installatoriSlice";
import { RegimeCommerciale } from "../../../../../anagrafiche/regimiCommerciali/regimiCommercialiSlice";
import { TipoPratica } from "../../../../../anagrafiche/tipiPratica/tipiPraticaSlice";
import { TipoTensione } from "../../../../../anagrafiche/tipiTensione/tipiTensioneSlice";
import { Comune } from "../../../../../anagrafiche/comuni/comuniSlice";

import { Gestore } from "../../../../../anagrafiche/gestori/gestoriSlice";
import { useForm, SubmitHandler, Controller } from "react-hook-form";

import ReactHookFormSelect from "../../../../../common/ReactHookFormSelect";
import MenuItem from "@mui/material/MenuItem";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { Routes_path } from "../../../../../routerApp";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Alert from "../../../../../common/Alert";
import Typography from "@mui/material/Typography";
import Autocomplete from "@mui/material/Autocomplete";
import InputAdornment from "@mui/material/InputAdornment";

import { Button, Divider } from "@mui/material";
import { Soggetto } from "components/anagrafiche/produttori/produttoriSlice";

interface ProdottoFormProps {
	podCommessaId: number;
	iterConnessione?: IterConnessione;
	commessa?: Commessa;
	tipoProdotto: "MF" | "IN" | "SA" | "OT" | "PI" | "EV" | "AC" | "XX";
	handleClosInputProdotto: () => void;
	preEsistente: boolean;
}

const ProdottoForm = ({
	podCommessaId,
	iterConnessione,
	commessa,
	tipoProdotto,
	handleClosInputProdotto,
	preEsistente,
}: ProdottoFormProps) => {
	const {
		handleSubmit,
		register,
		formState: { errors, isDirty, isValid },
		setValue,
		watch,
		getValues,
		control,
		setError,
		clearErrors,
		reset,
	} = useForm<Prodotto>();

	const nuovoProdotto: Prodotto = {
		id: undefined,
		pod_commessa: podCommessaId,
		iterConnessione: iterConnessione?.id || undefined,
		tipo_prodotto: tipoProdotto,
		pre_esistente: preEsistente,
		marca: undefined,
		modello: undefined,
		quantita: undefined,
		potenza_unitaria: undefined,
		capacita_singola_batteria: undefined,
		capacita_totale: undefined,
	};
	const listaCampi = Object.keys(nuovoProdotto) as Array<keyof Prodotto>;

	const dispatch = useAppDispatch();

	const utente = useSelector<RootState, UtenteLogged>(
		(state) => state.authentication.utenteLogged
	);

	useEffect(() => {
		reset();
		listaCampi.map((field) => {
			setValue(field, nuovoProdotto[field]);
		});
	}, []);

	// useEffect(() => {
	// 	clearErrors();
	// 	listaCampi.forEach((field) => {
	// 		iterConnessione?.errorsStack?.fieldsErrors &&
	// 			iterConnessione.errorsStack.fieldsErrors[field] &&
	// 			setError(field, {
	// 				type: "server",
	// 				message: iterConnessione.errorsStack.fieldsErrors[field].toString(),
	// 			});
	// 	});
	// }, [
	// 	iterConnessione?.errorsStack?.fieldsErrors,
	// 	// iterConnessione.allevamento_pack?.errorsStack?.fieldsErrors,
	// ]);

	const handlerSaveProdotto = (prodotto: Prodotto) => {
		podCommessaId &&
			dispatch(
				aggiungiProdotto({
					prodotto: prodotto,
					pod_commessa: podCommessaId,
					iterconnessioneIdToRefresh: iterConnessione?.id,
					commessaIdToRefresh: commessa?.id,
				})
			);
		handleClosInputProdotto();
	};

	const handlerDetailChaingSubmit = () => {
		if (isValid && isDirty) {
			const formValues = getValues();
			handlerSaveProdotto(formValues);
		}
	};

	const onSubmit: SubmitHandler<Prodotto> = (prodotto) => {
		handlerSaveProdotto(prodotto);
	};

	return (
		<Box sx={{ mb: 1 }}>
			<form onSubmit={handleSubmit(onSubmit)}>
				{iterConnessione?.errorsStack?.fieldsErrors?.non_field_errors && (
					<Alert severity="error">
						{iterConnessione?.errorsStack?.fieldsErrors?.non_field_errors.toString()}
					</Alert>
				)}
				<Typography variant="h6" gutterBottom>
					Aggiungi{" "}
					{
						Object.entries(TipoProdottoTypes).find(
							([key, value]) => key == tipoProdotto
						)?.[1]
					}
				</Typography>
				<Grid container justifyContent="flex-start" spacing={2} sx={{ mb: 2 }}>
					<Grid item xs={12} sm={6} md={6} lg={4}>
						<TextField
							label="marca"
							// disabled={schedaCarico.numero_capi_pianificati}
							variant="outlined"
							InputLabelProps={{
								shrink: true,
							}}
							{...register("marca")}
							error={!!errors?.marca}
							helperText={errors?.marca?.message}
							autoComplete={"off"}
							sx={{ width: "100%" }}
							fullWidth
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={6} lg={4}>
						<Controller
							name="quantita"
							control={control}
							render={({ field }) => (
								<NumericFormat
									id="quantita"
									customInput={TextField}
									variant="outlined"
									label="quantità"
									InputLabelProps={{
										shrink: true,
									}}
									error={!!errors?.quantita}
									helperText={errors?.quantita?.message}
									// {...register("potenza_unitaria")}
									value={field.value}
									autoComplete="off"
									thousandSeparator="."
									decimalSeparator=","
									valueIsNumericString={true}
									onValueChange={(v) => {
										setValue("quantita", Number(v.value), {
											shouldDirty: true,
										});
									}}
									sx={{ width: "100%" }}
								/>
							)}
						/>
						{/* <TextField
							label={
								tipoProdotto == "SA" ? "quantità moduli batteria" : "quantità"
							}
							// disabled={schedaCarico.numero_capi_pianificati}
							variant="outlined"
							InputLabelProps={{
								shrink: true,
							}}
							{...register("quantita")}
							error={!!errors?.quantita}
							helperText={errors?.quantita?.message}
							autoComplete={"off"}
							InputProps={{
								inputMode: "numeric",
							}}
							sx={{ width: "100%" }}
							fullWidth
						/> */}
					</Grid>
					{/* {tipoProdotto == "SA" && (
						<Grid item xs={12} sm={6} md={6} lg={4}></Grid>
					)} */}

					{(tipoProdotto == "MF" || tipoProdotto == "IN") && (
						<Grid item xs={12} sm={6} md={6} lg={4}>
							<Controller
								name="potenza_unitaria"
								control={control}
								render={({ field }) => (
									<NumericFormat
										id="potenza_unitaria"
										customInput={TextField}
										variant="outlined"
										label="potenza unitaria"
										InputLabelProps={{
											shrink: true,
										}}
										error={!!errors?.potenza_unitaria}
										helperText={errors?.potenza_unitaria?.message}
										// {...register("potenza_unitaria")}
										value={field.value}
										autoComplete="off"
										prefix={tipoProdotto == "MF" ? "Wp " : "kW "}
										thousandSeparator="."
										decimalSeparator=","
										valueIsNumericString={true}
										onValueChange={(v) => {
											setValue("potenza_unitaria", Number(v.value), {
												shouldDirty: true,
											});
										}}
										sx={{ width: "100%" }}
									/>
								)}
							/>
						</Grid>
					)}
				</Grid>
				<Grid container justifyContent="flex-start" spacing={2} sx={{ mb: 2 }}>
					<Grid item xs={12} sm={6} md={6} lg={4}>
						<TextField
							label="modello"
							// disabled={schedaCarico.numero_capi_pianificati}
							variant="outlined"
							InputLabelProps={{
								shrink: true,
							}}
							{...register("modello")}
							error={!!errors?.modello}
							helperText={errors?.modello?.message}
							autoComplete={"off"}
							sx={{ width: "100%" }}
							fullWidth
						/>
					</Grid>
					{tipoProdotto == "SA" && (
						<Grid item xs={12} sm={6} md={6} lg={4}>
							<Controller
								name="capacita_singola_batteria"
								control={control}
								render={({ field }) => (
									<NumericFormat
										id="capacita_singola_batteria"
										customInput={TextField}
										variant="outlined"
										label="capacita singola batteria"
										InputLabelProps={{
											shrink: true,
										}}
										error={!!errors?.capacita_singola_batteria}
										helperText={errors?.capacita_singola_batteria?.message}
										// {...register("potenza_unitaria")}
										value={field.value}
										autoComplete="off"
										prefix="kWh "
										thousandSeparator="."
										decimalSeparator=","
										valueIsNumericString={true}
										onValueChange={(v) => {
											setValue("capacita_singola_batteria", Number(v.value), {
												shouldDirty: true,
											});
										}}
										sx={{ width: "100%" }}
									/>
								)}
							/>
						</Grid>
					)}

					{tipoProdotto != "SA" && (
						<Grid item xs={12} sm={6} md={6} lg={8}></Grid>
					)}
				</Grid>
				<Grid container justifyContent="flex-start" spacing={2} sx={{ mb: 2 }}>
					{tipoProdotto == "SA" && (
						<>
							<Grid item xs={12} sm={6} md={6} lg={4}>
								<Controller
									name="capacita_totale"
									control={control}
									render={({ field }) => (
										<NumericFormat
											id="capacita_totale"
											customInput={TextField}
											variant="outlined"
											label="capacita totale"
											InputLabelProps={{
												shrink: true,
											}}
											error={!!errors?.capacita_totale}
											helperText={errors?.capacita_totale?.message}
											// {...register("potenza_unitaria")}
											value={field.value}
											autoComplete="off"
											prefix="kWh "
											thousandSeparator="."
											decimalSeparator=","
											valueIsNumericString={true}
											onValueChange={(v) => {
												setValue("capacita_totale", Number(v.value), {
													shouldDirty: true,
												});
											}}
											sx={{ width: "100%" }}
										/>
									)}
								/>
							</Grid>
							<Grid item xs={12} sm={6} md={6} lg={4}>
								<Typography fontWeight={500} color="red">
									{" "}
									Nota: si consiglia di fare le foto dei seriali di ogni singolo
									modulo batteria{" "}
								</Typography>
							</Grid>
						</>
					)}
				</Grid>
				<Button
					// disabled={!isDirty}
					variant="outlined"
					onClick={() => {
						handleClosInputProdotto();
					}}
				>
					Annulla
				</Button>
				<Button
					disabled={!isDirty}
					variant="outlined"
					type="submit"
					sx={{ ml: 1 }}
				>
					Salva
				</Button>
			</form>
		</Box>
	);
};

export default ProdottoForm;
