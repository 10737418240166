import React, {useState, useEffect} from "react";
import {useSelector} from "react-redux";
import {RootState, useAppDispatch} from "../../redux/store";

import AllegatiUploadForm from "./AllegatiUploadForm";
import Tooltip from "@mui/material/Tooltip";
import {
    ErrorStatusTypes,
    // ErrorsStack,
    // parseErrorMessage,
} from "../common/errorsDeclarations";

import {
    Allegato,
    TipoDocumento,
    DocumentiPerTipo,
    AllegatiState,
    uploadAllegato,
    // fetchAllegati,
    fetchTipiDocumento,
    deleteAllegato,
    TipoFaseIterConnessioneTypes,
    TipoFaseCommessaTypes,
    DocumentiIterPerFase, DocumentiCommessaPerFase,
} from "./allegatiSlice";

import ConfermaAnnulla from "../common/ConfermaAnnulla";

import {fetchDocumentiIterPerFase,} from "../main/iterConnessioni/iterConnessioneSlice";
import {fetchDocumentiCommessePerFase} from "../main/commesse/commesseSlice"
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

import {Typography, Link, IconButton} from "@mui/material";

import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import DeleteIcon from "@mui/icons-material/Delete";

interface AllegatiPageProps {
    listaDocumentiPerTipo?: DocumentiPerTipo[];
    iterConnessioneId?: number;
    faseIterConnessione?: string;
    progettoId?: number;
    richiestaConnessioneId?: number;
    fineLavoriId?: number;
    allaccioId?: number;
    eneaId?: number;
    officinaElettricaId?: number;
    autorizzazioneComunaleId?: number;
    sopralluogoId?: number;
    sopralluogoElettricoId?: number;
    commessaId?: number;
    faseCommessa?: string;
    consentiModifica: boolean;
}

const AllegatiPage = ({
                          // listaDocumentiPerTipo,
                          iterConnessioneId,
                          faseIterConnessione,
                          progettoId,
                          richiestaConnessioneId,
                          fineLavoriId,
                          allaccioId,
                          eneaId,
                          officinaElettricaId,
                          autorizzazioneComunaleId,
                          sopralluogoId,
                          sopralluogoElettricoId,
                          commessaId,
                          faseCommessa,
                          consentiModifica = false,
                      }: AllegatiPageProps) => {
        const dispatch = useAppDispatch();

        const documentiIterPerFase: DocumentiIterPerFase | undefined = useSelector<
            RootState,
            DocumentiIterPerFase | undefined
        >((state) =>
            state.iterConnessioniState.documentiIterPerFase.find(
                (i) =>
                    i.iter_connessione_id == iterConnessioneId &&
                    i.fase_iter_connessione == faseIterConnessione
            )
        );

        const documentiCommessaPerFase: DocumentiCommessaPerFase | undefined = useSelector<
            RootState,
            DocumentiCommessaPerFase | undefined
        >((state) =>
            state.commesseState.documentiCommessaPerFase.find(
                (i) =>
                    i.commessa_id == commessaId &&
                    i.fase_commessa == faseCommessa
            )
        );

        useEffect(() => {
            dispatch(fetchTipiDocumento());
            iterConnessioneId && faseIterConnessione
            && dispatch(fetchDocumentiIterPerFase({iterConnessioneId: iterConnessioneId, faseIterConnessione,}))
            // : dispatch(resetDocumentiIterPerFase());

            commessaId && faseCommessa
            && dispatch(fetchDocumentiCommessePerFase({commessa_id: commessaId, fase_commessa: faseCommessa}))
            // : dispatch(resetDocumentiIterPerFase());

        }, [iterConnessioneId, faseIterConnessione, commessaId, faseCommessa]);

        const tipiDocumento: TipoDocumento[] = useSelector<
            RootState,
            TipoDocumento[]
        >((state) => state.allegati.tipiDocumento.results);


        const listaDocumentiPerTipo = () => {

            if (iterConnessioneId && faseIterConnessione) {
                return documentiIterPerFase?.lista_documenti_per_tipo
            }
            if (commessaId && faseCommessa) {
                return documentiCommessaPerFase?.lista_documenti_per_tipo
            }
            return []

        }

        const lista_completa = () => {
            if (iterConnessioneId && faseIterConnessione) {
                return documentiIterPerFase?.lista_completa
            }
            if (commessaId && faseCommessa) {
                return documentiCommessaPerFase?.lista_completa
            }
            return []
        }
        const titolo = () => {
            if (iterConnessioneId && faseIterConnessione) {
                return Object.entries(TipoFaseIterConnessioneTypes).find(
                    ([key]) => key == faseIterConnessione
                )?.[1]
            }
            if (commessaId && faseCommessa) {
                return Object.entries(TipoFaseCommessaTypes).find(
                    ([key]) => key == faseCommessa
                )?.[1]
            }
            return ""
        }

        const allegatoCorrente: AllegatiState | undefined = useSelector<
            RootState,
            AllegatiState | undefined
        >((state) => state.allegati.allegatoCorrente);


        const handleUploadAllegato = (allegato: Allegato, selectedFile: File | null) => {
            selectedFile &&
            dispatch(
                uploadAllegato({
                    allegato: allegato,
                    file: selectedFile,
                    faseIterConnessione: faseIterConnessione,
                    faseCommessa: faseCommessa,
                })
            );
        };

        const [allegatoSelezionato, setAllegatoSelezionato] = useState<Allegato>();

        // gestione chiusura modal su scelta "Annulla"
        const [openConferma, setOpenConferma] = useState(false);
        const handleConfermaClose = () => setOpenConferma(false);

        const handleDelete = (allegato: Allegato) => {
            dispatch(
                deleteAllegato({allegatoToDelete: allegato, faseIterConnessione, faseCommessa})
            );
            handleConfermaClose();
        };

        const handleModalopenConfermaOpen = (allegato: Allegato) => {
            setAllegatoSelezionato(allegato);
            setOpenConferma(true);
        };

        return (
            (listaDocumentiPerTipo()?.length || 0) > 0 &&
            <Card sx={{my: 2}}>

                <CardHeader
                    title={
                        <Typography variant="h5" color="white">
                            {`Allegati ${titolo()}`}
                        </Typography>
                    }
                    sx={{
                        backgroundColor: lista_completa()
                            ? "#249949"
                            : "#FF2222aa",
                    }}
                />
                <CardContent>
                    <Grid container spacing={2}>
                        {listaDocumentiPerTipo()?.map(
                            (tipoDocumento) => (
                                <Grid
                                    key={"tipoDoc_" + tipoDocumento.tipo_id}
                                    item
                                    xs={12}
                                    sm={6}
                                    md={4}
                                    lg={3}
                                >
                                    <Card
                                        sx={{
                                            mt: 2,
                                            minHeight: "100%",
                                            backgroundColor:
                                                tipoDocumento.allegati_presenti.length == 0 &&
                                                tipoDocumento.obbligo_tipo
                                                    ? "#FF222222"
                                                    : "inherit",
                                        }}
                                    >
                                        <CardContent>
                                            <Typography
                                                variant="h5"
                                                // sx={{ fontSize: 14 }}
                                                color="text.secondary"
                                                gutterBottom
                                            >
                                                {tipoDocumento.tipo}
                                            </Typography>
                                            <Box sx={{ml: 2}}>
                                                {tipoDocumento.allegati_presenti.filter(a=>(!richiestaConnessioneId || a.richiesta_connessione == richiestaConnessioneId)).filter(a=>(!progettoId || a.progetto == progettoId)).map((allegato) => (
                                                    <Stack
                                                        key={`allegato_${allegato.id}`}
                                                        direction="row"
                                                        justifyContent="space-between"
                                                        alignItems="flex-start"
                                                        spacing={1}
                                                    >
                                                        {" "}
                                                        <Tooltip title={allegato.descrizione}>
                                                            <Link
                                                                variant="body2"
                                                                href={allegato.file}
                                                                target="_blank"
                                                                key={"allegato_" + allegato.id}
                                                            >
                                                                {allegato.descrizione || "file"}
                                                            </Link>
                                                        </Tooltip>
                                                        {consentiModifica && tipoDocumento.modificabile && (
                                                            <IconButton
                                                                aria-label="delete"
                                                                size="small"
                                                                // onClick={() => handleDelete(allegato)}
                                                                onClick={() =>
                                                                    handleModalopenConfermaOpen(allegato)
                                                                }
                                                            >
                                                                <DeleteIcon/>
                                                            </IconButton>
                                                        )}
                                                    </Stack>
                                                ))}
                                                {/* {tipoDocumento.allegati_presenti.length == 0 &&
									(tipoDocumento.obbligo_tipo ? (
										<Typography color="red">Obbligatorio</Typography>
									) : (
										<Typography>Facoltativo</Typography>
									))} */}
                                            </Box>
                                            <Box>
                                                <Typography variant="caption" color="grey">
                                                    {tipoDocumento.descrizione}
                                                </Typography>
                                            </Box>
                                        </CardContent>
                                        {consentiModifica &&
                                            (iterConnessioneId || commessaId) &&
                                            tipoDocumento.modificabile && (
                                                <CardActions>
                                                    <AllegatiUploadForm key={`form_per_${officinaElettricaId}`}
                                                                        allegato={{
                                                                            id: null,
                                                                            tipo_documento: tipoDocumento.tipo_id,
                                                                            descrizione: "",
                                                                            iter_connessione: iterConnessioneId,
                                                                            progetto: progettoId,
                                                                            richiesta_connessione: richiestaConnessioneId,
                                                                            fine_lavori: fineLavoriId,
                                                                            allaccio: allaccioId,
                                                                            enea: eneaId,
                                                                            officina_elettrica: officinaElettricaId,
                                                                            autorizzazione_comunale: autorizzazioneComunaleId,
                                                                            sopralluogo: sopralluogoId,
                                                                            sopralluogo_elettrico: sopralluogoElettricoId,
                                                                            commessa: commessaId,
                                                                            autore: "",
                                                                            file: "",
                                                                            file_name: "",
                                                                        }}
                                                                        tipiDocumento={tipiDocumento}
                                                                        uploadAllegato={handleUploadAllegato}
                                                                        uploading={
                                                                            allegatoCorrente?.errorsStack.status ==
                                                                            ErrorStatusTypes.PENDING
                                                                        }
                                                    />
                                                </CardActions>
                                            )}
                                    </Card>

                                    <Box sx={{mt: 0}}>
                                        <Stack
                                            direction="row"
                                            justifyContent="space-between"
                                            alignItems="flex-start"
                                            spacing={2}
                                            sx={{mt: 0}}
                                        >
                                            <Box></Box>
                                            <Box></Box>
                                        </Stack>
                                    </Box>
                                </Grid>
                            )
                        )}
                        {allegatoSelezionato && (
                            <ConfermaAnnulla
                                handleAnnulla={handleConfermaClose}
                                handleConferma={() => handleDelete(allegatoSelezionato)}
                                domanda={
                                    "Sicuro di voler cancellare il file " +
                                    allegatoSelezionato?.file_name
                                }
                                open={openConferma}
                            />
                        )}
                    </Grid>
                </CardContent>
            </Card>
        )
            ;
    }
;

export default AllegatiPage;
