import React, { useEffect, useState } from "react";

import { useForm, SubmitHandler } from "react-hook-form";

import { TipoTensione } from "./tipiTensioneSlice";

import { ErrorsStack, ErrorStatusTypes } from "../../common/errorsDeclarations";

import { utenteAutorizzato } from "../../authentication/UtenteAutorizzato";
import { UtenteLogged } from "../../authentication/authenticationSlice";

import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";

import { Box } from "@mui/system";
import { Button } from "@mui/material";

import Stack from "@mui/material/Stack";
import { theme } from "../../theme";

interface TipiTensioneFormProps {
	tipoTensioneNuovo: TipoTensione;
	// tipiTensione: TipiTensione;
	saveTipoTensione: (tipoTensione: TipoTensione) => void;
	annulla: () => void;
}

const TipiTensioneForm = ({
	tipoTensioneNuovo,
	saveTipoTensione,
	annulla,
}: TipiTensioneFormProps) => {
	const {
		handleSubmit,
		register,
		formState: { errors, isDirty },
		setValue,
		watch,
		getValues,
		control,
		setError,
		clearErrors,
		reset,
	} = useForm<TipoTensione>();

	const listaCampi = Object.keys(tipoTensioneNuovo) as Array<
		keyof TipoTensione
	>;

	useEffect(() => {
		listaCampi.map((field) => {
			setValue(field, tipoTensioneNuovo[field]);
		});
	}, []);

	const onSubmit: SubmitHandler<TipoTensione> = (tipoTensione) => {
		saveTipoTensione(tipoTensione);
	};

	useEffect(() => {
		listaCampi.forEach((field) => {
			tipoTensioneNuovo.errorsStack?.fieldsErrors &&
				tipoTensioneNuovo.errorsStack.fieldsErrors[field] &&
				setError(field, {
					type: "server",
					message: tipoTensioneNuovo.errorsStack.fieldsErrors[field].toString(),
				});
		});
	}, [tipoTensioneNuovo?.errorsStack?.fieldsErrors]);

	return (
		<Box>
			<form onSubmit={handleSubmit(onSubmit)}>
				<Grid
					container
					// rowSpacing={1}
					columnSpacing={1}
					// justifyContent="space-between"
					// alignItems="flex-start"
				>
					<Grid
						item
						xs={12}
						md={4}
						sx={
							{
								// pt: "16px !important",
								// margin: 1,
							}
						}
					>
						<TextField
							id="nome"
							label="Nome"
							// disabled={getValues("confermato")}
							{...register("nome")}
							InputLabelProps={{
								shrink: true,
							}}
							variant="outlined"
							required={true}
							fullWidth
							type="string"
							autoComplete={"off"}
							error={!!errors?.nome}
							helperText={errors?.nome?.message}
						/>
					</Grid>
					<Grid
						item
						xs={12}
						md={4}
						sx={
							{
								// pt: "16px !important",
								// margin: 1,
							}
						}
					>
						<TextField
							id="descrizione"
							label="Descrizione"
							// disabled={getValues("confermato")}
							{...register("descrizione")}
							InputLabelProps={{
								shrink: true,
							}}
							variant="outlined"
							fullWidth
							type="string"
							autoComplete={"off"}
							error={!!errors?.descrizione}
							helperText={errors?.descrizione?.message}
						/>
					</Grid>
					<Grid item xs={12} md={4}>
						<Stack direction="row" justifyContent="flex-start" spacing={1}>
							<Button disabled={!isDirty} variant="contained" type="submit">
								Salva
							</Button>
							<Button variant="outlined" onClick={annulla}>
								Annulla
							</Button>
						</Stack>
					</Grid>
				</Grid>
			</form>
		</Box>
	);
};

export default TipiTensioneForm;
