import
    React, {useEffect} from "react";

import {RootState, useAppDispatch} from "../../../../../redux/store";

import DistintaProdottiBox from "../../../iterConnessioni/iterConnessione/datiPod/prodotti/DistintaProdottiBox";

import {Commessa, getCommessa, saveCommessa, TipoIntervento} from "../../commesseSlice";

import {Box} from "@mui/system";
import AllegatiPage from "../../../../allegati/AllegatiPage";
import Button from "@mui/material/Button";
import {useSelector} from "react-redux";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import {prevedePod} from "../prevedePod";
import {prevedeAmpliamento} from "../prevedeAmpliamento";

interface MaterialiTabProps {
    commessa: Commessa;
    consentiModifica: boolean;
}

const MaterialiTab = ({commessa, consentiModifica}: MaterialiTabProps) => {
    const dispatch = useAppDispatch();
    useEffect(() => {
        if (commessa.id) {
            dispatch(getCommessa({commessaId: commessa.id}));
        }
    }, []);


    const tipiIntervento = useSelector<RootState, TipoIntervento[]>(
        (state) => state.commesseState.tipiIntervento.results
    );

    return (
        <Box
            sx={{
                backgroundColor: "#fff",
                elevation: 20,
                p: 2,
            }}
        >
            {!prevedePod(commessa, tipiIntervento) && !commessa?.pod?.id &&
                <Paper elevation={3} sx={{p: 4, m: 4, backgroundColor: "#f97e6899"}}>
                    <Typography variant="h6">Questo tipo di intervento non provede POD.</Typography>
                    <Typography gutterBottom variant="body1">
                        Per poter agganciare dei materiali devi confermare che la
                        tipologia di intervento scelta è corretta.
                    </Typography>
                    <Typography gutterBottom variant="body2" >Tipologie di intervento: {tipiIntervento.filter(tipo=>commessa.tipi_intervento?.includes(tipo.id))?.map((tipo) => tipo.descrizione).join(", ")}</Typography>

                    <Button
                        onClick={() => {
                            dispatch(
                                saveCommessa({
                                    commessaToSave: commessa,
                                    azione: "genera_pod_fake_per_materiali",
                                })
                            );
                        }}
                        variant="contained">
                        Confermo la tipologia di intervento!
                    </Button>

                </Paper>
            }
            {commessa.stato == 'A' &&
                <Button
                    variant="contained"
                    onClick={() => {
                        dispatch(
                            saveCommessa({
                                commessaToSave: commessa,
                                azione: "attiva_amministrazione_per_acconto",
                            })
                        );
                    }}
                    disabled={!(commessa.stato == 'A' && commessa.stato_completato)}
                >
                    Attiva amministrazione per richiesta acconto
                </Button>
            }
            {!!commessa?.pod?.id && (
                <DistintaProdottiBox
                    podCommessaId={commessa.pod.id}
                    commessa={commessa}
                    consentiModifica={consentiModifica}
                    preEsistente={true}
                    prodotti={commessa.pod.prodotti}
                />
            )}
            {!!commessa?.pod?.id && (
                <DistintaProdottiBox
                    podCommessaId={commessa.pod.id}
                    commessa={commessa}
                    consentiModifica={consentiModifica}
                    preEsistente={false}
                    prodotti={commessa.pod.prodotti}
                />
            )}
            {!!commessa?.pod?.id && (
                <AllegatiPage consentiModifica={consentiModifica || false} commessaId={commessa.id}
                              faseCommessa={'MAT'}/>
            )}
        </Box>
    );
};

export default MaterialiTab;
