import React, {useState} from "react";
import {useAppDispatch} from "../../../../../redux/store";
import {theme} from "../../../../theme";

import ConfermaAnnulla from "../../../../common/ConfermaAnnulla";

import {
    IterConnessione,
    getIterConnessione,
    saveIterConnessione,
} from "../../iterConnessioneSlice";

import SoggettoPage from "../../../../anagrafiche/soggetti/SoggettoPage";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import {Box} from "@mui/system";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import AllegatiPage from "../../../../allegati/AllegatiPage";

interface IterConnessioneDatiProduttoreTabProps {
    iterConnessione: IterConnessione;
    consentiModifica: boolean;
}

const IterConnessioneDatiProduttoreTab = ({
                                              iterConnessione,
                                              consentiModifica,
                                          }: IterConnessioneDatiProduttoreTabProps) => {
    const dispatch = useAppDispatch();

    // gestione chiusura modal su scelta "Annulla"
    const [cambioProduttore, setCambioProduttore] = useState(false);
    const [cambioUsufruttore, setCambioUsufruttore] = useState(false);
    const handleConfermaClose = () => {
        setCambioProduttore(false);
        setCambioUsufruttore(false);
    };

    return (
        <Box
            sx={{
                backgroundColor: "#fff",
                elevation: 20,
                p: 2,
            }}
        >
            {!iterConnessione?.dati_produttore_completo?.dati_produttore_completo &&
                <Alert severity="error" sx={{mb:2}}>Attenzione dati macanti: {iterConnessione?.dati_produttore_completo?.dati_mancanti}</Alert>
            }
            <Card elevation={3} sx={{mb: 5}}>
                <CardHeader
                    sx={{backgroundColor: "#5ea1ed"}}
                    title={
                        <Stack
                            direction="row"
                            spacing={2}
                            sx={{mt: 2}}
                            justifyContent="space-between"
                        >
                            <Typography variant="h4">Produttore</Typography>
                            {!!iterConnessione?.produttore && (
                                <Button
                                    onClick={() => setCambioProduttore(true)}
                                    disabled={!consentiModifica}
                                    variant="outlined"
                                    color="secondary"
                                >
                                    Cambia Produttore
                                </Button>
                            )}
                        </Stack>
                    }
                />

                <CardContent>
                    <SoggettoPage
                        key={`soggetto-${iterConnessione?.produttore}`}
                        soggetto_id={iterConnessione?.produttore}
                        iterConnessione_id={iterConnessione.id || undefined}
                        come_produttore={true}
                        consentiModifica={consentiModifica}
                    />

                    {iterConnessione?.produttore && (
                        <AllegatiPage
                            faseIterConnessione="PRD"
                            iterConnessioneId={iterConnessione.id || 0}
                            consentiModifica={
                                iterConnessione.stato != "N" || consentiModifica
                            }
                        />
                    )}
                </CardContent>
            </Card>

            <ConfermaAnnulla
                handleAnnulla={handleConfermaClose}
                handleConferma={() => {
                    if (cambioProduttore) {
                        dispatch(
                            saveIterConnessione({
                                iterConnessioneToSave: {...iterConnessione, produttore: null},
                            })
                        );
                    }
                    if (cambioUsufruttore) {
                        dispatch(
                            saveIterConnessione({
                                iterConnessioneToSave: {
                                    ...iterConnessione,
                                    usufruttore: null,
                                },
                            })
                        );
                    }
                    handleConfermaClose();
                }}
                domanda={`Sicuro di voler sganciare ${
                    cambioProduttore ? "il Produttore" : "l'Usufruttore"
                } del POD da questa iterConnessione`}
                open={cambioProduttore || cambioUsufruttore}
            />

            <Card elevation={3}>
                <CardHeader
                    sx={{backgroundColor: "#ed875e33"}}
                    title={
                        <Stack
                            direction="row"
                            spacing={2}
                            sx={{mt: 2}}
                            justifyContent="space-between"
                        >
                            <Typography variant="h5">
                                Soggetto che usufruisce della detrazione
                            </Typography>
                            {!!iterConnessione?.usufruttore && (
                                <Button
                                    onClick={() => setCambioUsufruttore(true)}
                                    disabled={!consentiModifica}
                                    variant="outlined"
                                >
                                    Cambia Usufruttore
                                </Button>
                            )}
                        </Stack>
                    }
                />

                <CardContent key={iterConnessione.usufruttore}>
                    {iterConnessione?.produttore == iterConnessione.usufruttore ? (
                        <Typography variant="h4" sx={{mt: 2}}>
                            Uguale al Produttore
                        </Typography>
                    ) : (
                        <Box>

                            <SoggettoPage
                                soggetto_id={iterConnessione?.usufruttore}
                                iterConnessione_id={iterConnessione.id || undefined}
                                come_usufruttore={true}
                                consentiModifica={consentiModifica}
                            />

                            <AllegatiPage
                                faseIterConnessione="USF"
                                iterConnessioneId={iterConnessione.id || 0}
                                consentiModifica={iterConnessione.stato != "N" || consentiModifica}
                            />
                        </Box>

                    )}
                </CardContent>
            </Card>
            {/* <Divider
				textAlign="left"
				sx={{ mb: 2, color: theme.palette.primary.main }}
			>
				
			</Divider> */}
        </Box>
    );
};

export default IterConnessioneDatiProduttoreTab;
