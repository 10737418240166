import React from "react"
import {useSelector} from "react-redux";

import {Link} from "react-router-dom";
import {useAppDispatch, RootState} from "../../../../redux/store";

import {IterConnessione} from "../iterConnessioneSlice";
import {StatoIterTypes, StatiIter} from "../../../anagrafiche/kpi/kpiSlice";

import {convertToDateTime} from "components/common/dateUtils/convertToDateTime";

import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import {Typography} from "@mui/material";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";

interface IterConnessioneTimeLineProps {
    iterConnessione: IterConnessione;
}

const IterConnessioneTimeLine = ({
                                     iterConnessione,
                                 }: IterConnessioneTimeLineProps) => {
    const statiIter = useSelector<RootState, StatiIter>(
        (state) => state.kpiState.statiIter
    );
    return (
        <>
            <Timeline position="left">
                <TimelineItem>
                    <TimelineOppositeContent color="text.secondary">
                        {iterConnessione.data_richiesta_presa_carico &&
                            convertToDateTime(iterConnessione.data_richiesta_presa_carico)?.setLocale("it").toFormat("EEEE dd LLLL yyyy")}

                    </TimelineOppositeContent>
                    <TimelineSeparator>
                        <TimelineDot
                            color={iterConnessione.stato > "B" ? "success" : "secondary"}
                        >
                            {iterConnessione.stato == "B" && <PlayCircleOutlineIcon/>}
                        </TimelineDot>
                        <TimelineConnector/>
                    </TimelineSeparator>
                    <TimelineContent>
                        {
                            Object.entries(StatoIterTypes).find(
                                ([key, value]) => key == "C"
                            )?.[1]
                        }
                    </TimelineContent>
                </TimelineItem>
                <TimelineItem>
                    <TimelineOppositeContent color="text.secondary">
                        {iterConnessione.data_presa_carico &&
                            convertToDateTime(iterConnessione.data_presa_carico)?.setLocale("it").toFormat("EEEE dd LLLL yyyy")}
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                        <TimelineDot
                            color={
                                iterConnessione.stato > "C"
                                    ? "success"
                                    : iterConnessione.stato == "C"
                                        ? "secondary"
                                        : "grey"
                            }
                        >
                            {iterConnessione.stato == "C" && <PlayCircleOutlineIcon/>}
                        </TimelineDot>

                        <TimelineConnector/>
                    </TimelineSeparator>
                    <TimelineContent>
                        {
                            Object.entries(StatoIterTypes).find(
                                ([key, value]) => key == "D"
                            )?.[1]
                        }
                    </TimelineContent>
                </TimelineItem>
                <TimelineItem>
                    <TimelineOppositeContent color="text.secondary">
                        {iterConnessione.data_assegnazione &&
                            convertToDateTime(iterConnessione.data_assegnazione)?.setLocale("it").toFormat("EEEE dd LLLL yyyy")}
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                        <TimelineDot
                            color={
                                iterConnessione.stato > "D"
                                    ? "success"
                                    : iterConnessione.stato == "D"
                                        ? "secondary"
                                        : "grey"
                            }
                        >
                            {iterConnessione.stato == "D" && <PlayCircleOutlineIcon/>}
                        </TimelineDot>

                        <TimelineConnector/>
                    </TimelineSeparator>
                    <TimelineContent>
                        {
                            Object.entries(StatoIterTypes).find(
                                ([key, value]) => key == "E"
                            )?.[1]
                        }
                    </TimelineContent>
                </TimelineItem>
                <TimelineItem>
                    <TimelineOppositeContent color="text.secondary">
                        {iterConnessione.data_avvio_pratica &&
                            convertToDateTime(iterConnessione.data_avvio_pratica)?.setLocale("it").toFormat("EEEE dd LLLL yyyy")}
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                        <TimelineDot
                            color={
                                iterConnessione.stato > "E"
                                    ? "success"
                                    : iterConnessione.stato == "E"
                                        ? "secondary"
                                        : "grey"
                            }
                        >
                            {iterConnessione.stato == "E" && <PlayCircleOutlineIcon/>}
                        </TimelineDot>

                        <TimelineConnector/>
                    </TimelineSeparator>
                    <TimelineContent>
                        {
                            Object.entries(StatoIterTypes).find(
                                ([key, value]) => key == "F"
                            )?.[1]
                        }
                    </TimelineContent>
                </TimelineItem>
                <TimelineItem>
                    <TimelineOppositeContent color="text.secondary">
                        {iterConnessione.data_progetto &&
                            convertToDateTime(iterConnessione.data_progetto)?.setLocale("it").toFormat("EEEE dd LLLL yyyy")}
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                        <TimelineDot
                            color={
                                iterConnessione.stato > "F"
                                    ? "success"
                                    : iterConnessione.stato == "F"
                                        ? "secondary"
                                        : "grey"
                            }
                        >
                            {iterConnessione.stato == "F" && <PlayCircleOutlineIcon/>}
                        </TimelineDot>

                        <TimelineConnector/>
                    </TimelineSeparator>
                    <TimelineContent>
                        {
                            Object.entries(StatoIterTypes).find(
                                ([key, value]) => key == "G"
                            )?.[1]
                        }
                    </TimelineContent>
                </TimelineItem>
                <TimelineItem>
                    <TimelineOppositeContent color="text.secondary">
                        {iterConnessione.data_conferma_richiesta_connessione &&
                            convertToDateTime(iterConnessione.data_conferma_richiesta_connessione)?.setLocale("it").toFormat("EEEE dd LLLL yyyy")}
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                        <TimelineDot
                            color={
                                iterConnessione.stato > "H"
                                    ? "success"
                                    : iterConnessione.stato == "H"
                                        ? "secondary"
                                        : "grey"
                            }
                        >
                            {iterConnessione.stato == "H" && <PlayCircleOutlineIcon/>}
                        </TimelineDot>

                        <TimelineConnector/>
                    </TimelineSeparator>
                    <TimelineContent>
                        {
                            Object.entries(StatoIterTypes).find(
                                ([key, value]) => key == "H"
                            )?.[1]
                        }
                    </TimelineContent>
                </TimelineItem>
                <TimelineItem>
                    <TimelineOppositeContent color="text.secondary">
                        {iterConnessione.data_conclusione_fine_lavori &&
                            convertToDateTime(iterConnessione.data_conclusione_fine_lavori)?.setLocale("it").toFormat("EEEE dd LLLL yyyy")}
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                        <TimelineDot
                            color={
                                iterConnessione.stato > "I"
                                    ? "success"
                                    : iterConnessione.stato == "I"
                                        ? "secondary"
                                        : "grey"
                            }
                        >
                            {iterConnessione.stato == "I" && <PlayCircleOutlineIcon/>}
                        </TimelineDot>

                        <TimelineConnector/>
                    </TimelineSeparator>
                    <TimelineContent>
                        {
                            Object.entries(StatoIterTypes).find(
                                ([key, value]) => key == "I"
                            )?.[1]
                        }
                    </TimelineContent>
                </TimelineItem>
                <TimelineItem>
                    <TimelineOppositeContent color="text.secondary">
                        {iterConnessione.data_conclusione_allaccio &&
                            convertToDateTime(iterConnessione.data_conclusione_allaccio)?.setLocale("it").toFormat("EEEE dd LLLL yyyy")}
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                        <TimelineDot
                            color={
                                iterConnessione.stato > "L"
                                    ? "success"
                                    : iterConnessione.stato == "L"
                                        ? "secondary"
                                        : "grey"
                            }
                        >
                            {iterConnessione.stato == "L" && <PlayCircleOutlineIcon/>}
                        </TimelineDot>

                        <TimelineConnector/>
                    </TimelineSeparator>
                    <TimelineContent>
                        {
                            Object.entries(StatoIterTypes).find(
                                ([key, value]) => key == "L"
                            )?.[1]
                        }
                    </TimelineContent>
                </TimelineItem>
                <TimelineItem>
                    <TimelineOppositeContent color="text.secondary">
                        {iterConnessione.data_consegna &&
                            convertToDateTime(iterConnessione.data_consegna)?.setLocale("it").toFormat("EEEE dd LLLL yyyy")}
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                        <TimelineDot
                            color={
                                iterConnessione.stato == "N"
                                    ? "success"
                                    : iterConnessione.stato == "M"
                                        ? "secondary"
                                        : "grey"
                            }
                        >
                            {iterConnessione.stato == "M" && <PlayCircleOutlineIcon/>}
                        </TimelineDot>

                        <TimelineConnector/>
                    </TimelineSeparator>
                    <TimelineContent>
                        {iterConnessione.stato == "M"
                            ? Object.entries(StatoIterTypes).find(
                                ([key, value]) => key == "M"
                            )?.[1]
                            : Object.entries(StatoIterTypes).find(
                                ([key, value]) => key == "N"
                            )?.[1]}
                    </TimelineContent>
                </TimelineItem>
            </Timeline>
        </>
    );
};

export default IterConnessioneTimeLine;
