import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { RootState, useAppDispatch } from "../../../redux/store";
import { useLocation, Link, useParams } from "react-router-dom";

import { DateTime } from "luxon";


import { utenteAutorizzato } from "../../authentication/UtenteAutorizzato";
import { PermissionTypes } from "../../authentication/permissionsGroups";

import { UtenteLogged } from "../../authentication/authenticationSlice";
import { Comune } from "../../anagrafiche/comuni/comuniSlice";
import {
	IterConnessione,
	richiestaPresaInCarico,
	presaInCarico,
} from "../../main/iterConnessioni/iterConnessioneSlice";
import { Pod, getPod } from "./podsSlice";
// import IterConnessioneSpalla from "../IterConnessioneSpalla";
import AllegatiPage from "../../allegati/AllegatiPage";
import PodDatiGeneraliForm from "./PodDatiGeneraliForm";
import PodDistintaProdottiBox from "./podProdotti/PodDistintaProdottiBox";
import PodSezioneCard from "./podSezioni/PodSezioneCard";
import IterConnessioniProduttoreGrid from "../../../components/anagrafiche/produttori/IterConnessioniProduttoreGrid";

// import DistintaProdottiBox from "./prodotti/DistintaProdottiBox";
// import AzioniClienteCard from "./AzioniClienteCard";
// import AzioniGestoreCard from "./AzioniGestoreCard";
import Divider from "@mui/material/Divider";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";
import { Button, Paper } from "@mui/material";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";

import CardContent from "@mui/material/CardContent";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Stack from "@mui/material/Stack";
import { theme } from "../../theme";
import { Soggetto } from "components/anagrafiche/produttori/produttoriSlice";

interface PodPageProps {
	// iterConnessione: IterConnessione;
	pod?: Pod;
	utente?: UtenteLogged;
	produttore?: Soggetto;
	consentiModifica?: boolean;
}

const PodPage = () => {
	
	const dispatch = useAppDispatch();
	const utente = useSelector<RootState, UtenteLogged>(
		(state) => state.authentication.utenteLogged
	);
	const { pod_id } = useParams();

	const [podId, setPodId] = useState<number>(parseInt(pod_id || ""));

	useEffect(() => {
		if (podId) {
			dispatch(getPod(podId));
			// dispatch(getProgettoFromIter(iterId));
		}
	}, []);

	const pod: Pod | undefined = useSelector<RootState, Pod | undefined>(
		(state) => state.podsState.pods.results.find((pod) => pod?.id == podId)
	);

	// const [preEsistente, setPreEsistente] = useState<boolean>(
	// 	iterConnessione.iter_semplificato ? false : true
	// );

	const [refresh, setRefresh] = useState<boolean>(false);

	useEffect(() => {
		setRefresh(false);
	}, [refresh]);

	return (
		<Box
			sx={{
				// backgroundColor: "#fff",
				p: 2,
			}}
		>
			<Card elevation={2}>
				<CardHeader
					sx={{
						backgroundColor: theme.palette.primary.main,
						color: theme.palette.primary.contrastText,
					}}
					title={
						<Stack
							direction="row"
							justifyContent="flex-start"
							alignItems="flex-start"
							spacing={4}
							sx={{ mb: 1 }}
						>
							<Typography variant="h4" component="div" color="text.secondary">
								POD - {pod?.codice}
							</Typography>
							<Typography variant="h4" component="div" color="text.secondary">
								{pod?.produttore_descrizione}
							</Typography>
						</Stack>
					}
				/>
				<CardContent>
					{pod && (
						<PodDatiGeneraliForm
							pod={pod}
							// produttore={produttore}
							consentiModifica={
								utenteAutorizzato(utente, [
									PermissionTypes.GESTORI_ITER,
									PermissionTypes.CLIENTI,
									PermissionTypes.OPERATORI,
									// PermissionTypes.PROGETTISTI,
								]) || utenteAutorizzato(utente, [PermissionTypes.GESTORI_ITER])
							}
						/>
					)}
				</CardContent>
			</Card>
			<Grid container spacing={2} sx={{ mb: 4 }}>
				<Grid item xs={12} sm={12} md={12} lg={12} order={{ xs: 2, md: 1 }}>
					{pod && (
						<Stack spacing={2}>
							<Divider
								textAlign="left"
								// sx={{ mt: 63, mb: 1, color: theme.palette.primary.main }}
								sx={{ my: 2, color: theme.palette.primary.main }}
							>
								Distinta Prodotti
							</Divider>

							<PodDistintaProdottiBox
								pod={pod}
								// produttore={produttore}
								consentiModifica={
									utenteAutorizzato(utente, [
										PermissionTypes.GESTORI_ITER,
										PermissionTypes.CLIENTI,
										PermissionTypes.OPERATORI,
										// PermissionTypes.PROGETTISTI,
									]) ||
									utenteAutorizzato(utente, [PermissionTypes.GESTORI_ITER])
								}
								// preEsistente={false}
							/>

							<Divider
								textAlign="left"
								sx={{ my: 2, color: theme.palette.primary.main }}
							>
								Sezioni Impianto
							</Divider>

							{pod.sezioni?.map((sezione) => (
								<PodSezioneCard
									key={sezione.id}
									sezione={sezione}
									consentiModifica={
										utenteAutorizzato(utente, [
											PermissionTypes.GESTORI_ITER,
											PermissionTypes.CLIENTI,
											PermissionTypes.OPERATORI,
											// PermissionTypes.PROGETTISTI,
										]) ||
										utenteAutorizzato(utente, [PermissionTypes.GESTORI_ITER])
									}
								/>
							))}
						</Stack>
					)}
					<Divider sx={{ my: 3 }} />
					{!!pod?.numero_iter_connessioni_pod && pod.iter_connessioni && (
						<Box sx={{ my: 2 }}>
							<IterConnessioniProduttoreGrid
								iterConnessioni={pod.iter_connessioni}
							/>
						</Box>
					)}
				</Grid>

				{/* <Grid item xs={12} sm={6} md={4} lg={3} order={{ xs: 1, md: 2 }}>
					<IterConnessioneSpalla iterConnessione={iterConnessione} />

					{utenteAutorizzato(utente, [
						PermissionTypes.CLIENTI,
						PermissionTypes.OPERATORI,
						// PermissionTypes.GESTORI_ITER,
					]) && <AzioniClienteCard iterConnessione={iterConnessione} />}
					{utenteAutorizzato(utente, [PermissionTypes.GESTORI_ITER]) && (
						<AzioniGestoreCard iterConnessione={iterConnessione} />
					)}
				</Grid> */}
			</Grid>
		</Box>
	);
};

export default PodPage;
