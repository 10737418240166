import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, Link } from "react-router-dom";

import { RootState, useAppDispatch } from "../../../redux/store";
import { Routes_path } from "../../routerApp";
import {
	TipoTensione,
	TipiTensione,
	fetchTipiTensione,
	saveTipoTensione,
	deleteTipoTensione,
} from "./tipiTensioneSlice";
import ConfermaAnnulla from "../../common/ConfermaAnnulla";

import { DateTime } from "luxon";


import {
	ErrorStatusTypes,
	ErrorsStack,
	parseErrorMessage,
} from "../../common/errorsDeclarations";

import { utenteAutorizzato } from "../../authentication/UtenteAutorizzato";
import { UtenteLogged } from "../../authentication/authenticationSlice";

import AddIcon from "@mui/icons-material/Add";

import { Box } from "@mui/system";
import { Button } from "@mui/material";

import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Collapse from "@mui/material/Collapse";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { theme } from "../../theme";

import TipiTensioneForm from "./TipiTensioneForm";

import {
	DataGrid,
	GridToolbar,
	GridRowsProp,
	GridRowModel,
	GridColDef,
	GridCellEditStopParams,
	GridCellEditStopReasons,
	MuiEvent,
	GridRowId,
	GridPreProcessEditCellProps,
	useGridApiRef,
	GridEditCellProps,
	GridToolbarContainer,
	GridRenderCellParams,
	GridValueFormatterParams,
} from "@mui/x-data-grid";
import { getRowIdFromRowModel } from "@mui/x-data-grid/hooks/features/rows/gridRowsUtils";
import { idID } from "@mui/material/locale";

const TipiTensioneGrid = () => {
	
	const dispatch = useAppDispatch();
	const apiRef = useGridApiRef();

	const location = useLocation();
	const utente = useSelector<RootState, UtenteLogged>(
		(state) => state.authentication.utenteLogged
	);

	// caricamento dallo store
	const tipiTensione: TipiTensione = useSelector<RootState, TipiTensione>(
		(state) => state.tipiTensioneState.tipiTensione
	);

	useEffect(() => {
		dispatch(fetchTipiTensione());
	}, []);

	const [refresh, setRefresh] = useState<boolean>(false);

	// gestione chiusura modal su scelta "Annulla"
	const [openConferma, setOpenConferma] = React.useState(false);
	const handleConfermaClose = () => setOpenConferma(false);

	// gestione chiusura form nuovo elemento su azione "Annulla"
	const [formAttivo, setFormAttivo] = React.useState(false);
	const handleCloseForm = () => setFormAttivo(false);

	////////////////////////////////////

	useEffect(() => {
		setRefresh(false);
	}, [refresh]);

	////////////////////////////////////

	// definizione elemento nuovo vuoto con aggancio errorsStack
	const tipoTensioneNuovoFieldsErrors: ErrorsStack = useSelector<
		RootState,
		ErrorsStack
	>((state) => state.tipiTensioneState.tipiTensione.errorsStack);

	const [tipoTensioneNuovo, setTipoTensioneNuovo] =
		React.useState<TipoTensione>({
			id: undefined,
			nome: "",
			descrizione: "",
			errorsStack: tipoTensioneNuovoFieldsErrors,
		});

	useEffect(() => {
		setTipoTensioneNuovo({
			...tipoTensioneNuovo,
			errorsStack: tipoTensioneNuovoFieldsErrors,
		});
	}, [tipoTensioneNuovoFieldsErrors]);

	/////////////////////////////////////////

	// salvataggio nuovo elemento con hook di chiusura form se esito "success"
	const handlerSaveTipoTensione = (tipoTensioneToSave: TipoTensione) => {
		dispatch(saveTipoTensione(tipoTensioneToSave));
	};

	useEffect(() => {
		if (tipiTensione.errorsStack.status == ErrorStatusTypes.SUCCESS)
			setFormAttivo(false);
	}, [tipiTensione.errorsStack.status]);

	////////////////////////////////////////

	// gestione cancellazione elemento
	const handlerDeleteTipoTensione = (row_id: number) => {
		const tipoTensioneToDelete: TipoTensione | undefined =
			tipiTensione.results.find((tipoTensione) => tipoTensione.id == row_id);

		if (tipoTensioneToDelete != undefined) {
			dispatch(deleteTipoTensione(tipoTensioneToDelete));
			handleConfermaClose();
		}
	};

	const [idSelezionato, setIdSelezionato] = React.useState<number>(0);

	const nomeTipoTensioneSelezionato = (row_id: number) => {
		// console.log("newRow.GridRowId = ", newRow.id);
		// const focus = () => apiRef.current.setCellMode(newRow.id, "nome", "edit");
		const tipoTensioneSelezionato: TipoTensione | undefined =
			tipiTensione.results.find((tipoTensione) => tipoTensione.id == row_id);

		return tipoTensioneSelezionato?.nome;
	};

	const handleModalopenConfermaOpen = (id: number) => {
		setIdSelezionato(id);
		setOpenConferma(true);
	};

	////////////////////////////

	// gestione aggiornamento elemento nella propria row del Grid
	const processRowUpdate = React.useCallback(
		(newRow: GridRowModel, oldRow: GridRowModel) => {
			// console.log("newRow.GridRowId = ", newRow.id);
			// const focus = () => apiRef.current.setCellMode(newRow.id, "nome", "edit");
			const tipoTensioneDaCambiare: TipoTensione | undefined =
				tipiTensione.results.find(
					(tipoTensione) => tipoTensione.id == newRow.id
				);

			if (tipoTensioneDaCambiare != undefined) {
				const tipoTensioneToSave = {
					...tipoTensioneDaCambiare,
					nome: newRow.nome,
					descrizione: newRow.descrizione,
				};
				dispatch(saveTipoTensione(tipoTensioneToSave));
				// focus;
			}
			return newRow;
		},
		[tipiTensione]
	);

	// definizione struttura del Data Grid
	const rows: GridRowsProp = tipiTensione.results.map(
		(tipoTensione: TipoTensione) => ({
			id: tipoTensione.id,
			nome: tipoTensione.nome,
			descrizione: tipoTensione.descrizione,
			cancella_regime: tipoTensione.id,
		})
	);

	const columns: GridColDef[] = [
		{
			field: "nome",
			headerName: "Nome",
			flex: 0.6,
			editable: true,
			type: "string",
			cellClassName: "campo-editabile",
		},
		{
			field: "descrizione",
			headerName: "Descrizione",
			flex: 1.2,
			editable: true,
			type: "string",
			cellClassName: "campo-editabile",
		},
		{
			field: "cancella_regime",
			headerName: " ",
			flex: 0.02,

			renderCell: (params: GridRenderCellParams<number>) => (
				<IconButton
					key={params.value}
					aria-label="cancella regime"
					size="small"
					onClick={() => handleModalopenConfermaOpen(params.value || 0)}
				>
					<DeleteForeverIcon fontSize="inherit" />
				</IconButton>
			),
		},
	];

	const gridAutoHeight = true;

	// creazione Toolbar personalizzata del Data Grid
	function CustomToolbar() {
		const handleOpenFormToAdd = () => {
			setFormAttivo(true);
		};

		return (
			<Stack
				direction="row"
				justifyContent="space-between"
				alignItems="center"
				spacing={2}
			>
				<GridToolbar />
				<Button
					color="primary"
					startIcon={<AddIcon />}
					onClick={handleOpenFormToAdd}
				>
					Aggiungi nuovo tipo
				</Button>
			</Stack>
		);
	}

	return (
		<Box
			sx={{
				backgroundColor: "#fff",
				elevation: 20,
				p: 2,
				// "& .campo-editabile": {
				// 	backgroundColor:  "#bbbbbb11",
				// 	background: "linear-gradient(to right bottom, #f5f7f6, #dce3e0)",
				// 	// borderWidth: "5px",
				// 	// borderColor: "#ff91003d",
				// 	borderColor: "#0000003d",
				// 	// borderStyle: "outset",
				// 	borderWidth: 3,
				// 	borderStyle: "ridge",
				// 	color: "#1a3e72",
				// 	fontWeight: "600",
				// },
				"& .campo-errore": {
					backgroundColor: "rgba(124, 3, 0, 0.3)",
					color: "#ffffff",
					fontWeight: "600",
				},
			}}
		>
			<Collapse in={formAttivo}>
				<Box sx={{ padding: "10px" }}>
					{formAttivo && (
						<TipiTensioneForm
							tipoTensioneNuovo={tipoTensioneNuovo}
							annulla={handleCloseForm}
							// lotto_id={lotto.id || 0}
							// articoli={articoli.filter((articolo) =>
							// 	articolo.tipi_capo.includes(lotto.tipo_capo || 0)
							// )}
							// articoli={articoli}
							saveTipoTensione={handlerSaveTipoTensione}
							// deleteLavorazioneCommittente={deleteLavorazioneCommittente}
						/>
					)}
				</Box>
			</Collapse>
			<DataGrid
				rows={rows}
				// density="compact"
				columns={columns}
				components={{
					Toolbar: CustomToolbar,
				}}
				autoHeight={gridAutoHeight}
				// sx={{
				// 	width: "100%",
				// 	minHeight: gridAutoHeight ? "200px" : undefined,
				// 	padding: 0,
				// 	margin: 0,
				// 	fontSize: "0.75rem",
				// }}
				experimentalFeatures={{
					newEditingApi: true,
					preventCommitWhileValidating: true,
				}}
				processRowUpdate={processRowUpdate}
				onProcessRowUpdateError={(e) => alert(e)}
				onCellEditStop={(params: GridCellEditStopParams, event: MuiEvent) => {
					if (params.reason === GridCellEditStopReasons.cellFocusOut) {
						event.defaultMuiPrevented = true;
					}
				}}
				getRowId={(row) => row.id}
				hideFooterPagination={true}
			/>

			<ConfermaAnnulla
				handleAnnulla={handleConfermaClose}
				handleConferma={() => handlerDeleteTipoTensione(idSelezionato)}
				domanda={
					"Sicuro di voler cancellare il tipo tensione " +
					nomeTipoTensioneSelezionato(idSelezionato)
				}
				open={openConferma}
			/>
		</Box>
	);
};

export default TipiTensioneGrid;
