import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useParams, useNavigate } from "react-router-dom";

import { RootState, useAppDispatch } from "../../../redux/store";
import { Routes_path } from "../../routerApp";

import { DateTime } from "luxon";

import {
	ErrorStatusTypes,
	ErrorsStack,
	parseErrorMessage,
} from "../../common/errorsDeclarations";

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

import { utenteAutorizzato } from "../../authentication/UtenteAutorizzato";
import { UtenteLogged } from "../../authentication/authenticationSlice";
import {
	Soggetto,
	getProduttore,
	TipoPersoneTypes,
} from "../produttori/produttoriSlice";
import Accordion from "@mui/material/Accordion";
import Chip from "@mui/material/Chip";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SoggettoForm from "./SoggettoForm";
import NuovoSoggettoForm from "./NuovoSoggettoForm";
// import SoggettoBreadcrumbs from "./SoggettoBreadcrumbs";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";
import { Button, Divider } from "@mui/material";
import Stack from "@mui/material/Stack";
import { theme } from "../../theme";
import Paper from "@mui/material/Paper";

interface SoggettoPageProps {
	soggetto_id?: number | null;
	commessa_id?: number;
	iterConnessione_id?: number;

	come_contraente?: boolean;
	come_titolare?: boolean;
	come_produttore?: boolean;
	come_usufruttore?: boolean;
	consentiModifica?: boolean;
	ampliamento?: boolean;
}

const SoggettoPage = ({
	soggetto_id,
	commessa_id,
	iterConnessione_id,
	come_contraente = false,
	come_titolare = false,
	come_produttore = false,
	come_usufruttore = false,
	ampliamento,
	consentiModifica,
}: SoggettoPageProps) => {
	const dispatch = useAppDispatch();
	// const { soggetto_id, tipoPratica_id } = useParams();
	// const soggettoId: number = parseInt(soggetto_id || "");

	const [soggettoId, setSoggettoId] = useState<number | undefined | null>(
		soggetto_id
	);

	const location = useLocation();
	const navigate = useNavigate();
	const utente = useSelector<RootState, UtenteLogged>(
		(state) => state.authentication.utenteLogged
	);

	const soggettoCorrenteId = useSelector<RootState, number | undefined>(
		(state) => state.produttoriState.soggettoCorrenteId
	);

	useEffect(() => {
		!!soggetto_id && setSoggettoId(soggetto_id);
	}, [soggetto_id]);

	useEffect(() => {
		!!soggettoId && dispatch(getProduttore(soggettoId));
	}, [soggettoId]);

	const soggetto: Soggetto | undefined = useSelector<
		RootState,
		Soggetto | undefined
	>((state) =>
		state.produttoriState.produttori.results.find(
			(soggetto) => soggetto?.id == soggettoId
		)
	);
	const errorsStack: ErrorsStack = useSelector<RootState, ErrorsStack>(
		(state) => state.produttoriState.produttori.errorsStack
	);

	return (
		<>
			{/* <SoggettoBreadcrumbs soggetto={soggetto} /> */}
			<Box>
				<Backdrop
					sx={{
						color: "#fff",
						zIndex: (theme) => theme.zIndex.drawer + 1,
					}}
					open={errorsStack.status == ErrorStatusTypes.PENDING}
				>
					<CircularProgress color="inherit" />
				</Backdrop>
				<Stack direction="row" spacing={2} sx={{ mb: 2 }}>
					<Chip label={soggetto?.id} color="primary" variant="outlined" />
					<Typography variant="h5" color="text.secondary">
						{soggetto?.nominativo || "Nuovo soggetto"}
					</Typography>
					<Typography variant="caption" color="text.secondary">
						{soggetto?.tipo_persona == "PF"
							? "Persona fisica"
							: soggetto?.tipo_persona == "PG"
							? "Persona giuridica"
							: ""}
					</Typography>
				</Stack>
				<Divider sx={{ mb: 1 }} />
				<Box sx={{ mb: 2 }}>
					{soggettoId ? (
						soggetto ? (
							<SoggettoForm
								soggetto={soggetto}
								consentiModifica={consentiModifica}
								ampliamento={ampliamento}
								iterConnessione_id_to_refresh={iterConnessione_id}
							/>
						) : (
							<Box>non trovato</Box>
						)
					) : (
						<NuovoSoggettoForm
							commessa_id={commessa_id}
							iterConnessione_id={iterConnessione_id}
							come_contraente={come_contraente}
							come_titolare={come_titolare}
							come_produttore={come_produttore}
							come_usufruttore={come_usufruttore}
							consentiModifica={consentiModifica}
						/>
					)}
				</Box>
			</Box>
		</>
	);
};

export default SoggettoPage;
