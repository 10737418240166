import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { RootState, useAppDispatch } from "../../../../../redux/store";

import { DateTime } from "luxon";


import { utenteAutorizzato } from "../../../../authentication/UtenteAutorizzato";
import { PermissionTypes } from "../../../../authentication/permissionsGroups";

import { UtenteLogged } from "../../../../authentication/authenticationSlice";
import { IterConnessione } from "../../iterConnessioneSlice";
import { TipoServizio } from "../../../../anagrafiche/tipiServizio/tipiServizioSlice";
import IterConnessioneIbanForm from "./IterConnessioneIbanForm";
import { testoBottoneProseguimento } from "../../../../anagrafiche/produttori/testoBottoneProseguimento";
import { ServizioTypes } from "../../../../anagrafiche/kpi/kpiSlice";
import IterConnessioneSpalla from "../IterConnessioneSpalla";
import IterConnessioneAvvioIterForm from "./IterConnessioneAvvioIterForm";
import AllegatiPage from "../../../../allegati/AllegatiPage";
import AzioniClienteCard from "./AzioniClienteCard";
import AzioniGestoreCard from "./AzioniGestoreCard";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";
import { Button, CardHeader } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Stack from "@mui/material/Stack";
import { theme } from "../../../../theme";
import ConfermaAnnulla from "components/common/ConfermaAnnulla";
import { Soggetto } from "components/anagrafiche/produttori/produttoriSlice";

interface IterConnessioneAvvioIterTabProps {
	iterConnessione: IterConnessione;
	produttore?: Soggetto;
	utente: UtenteLogged;
	consentiModifica: boolean;
}

const IterConnessioneAvvioIterTab = ({
	iterConnessione,
	produttore,
	utente,
	consentiModifica = false,
}: IterConnessioneAvvioIterTabProps) => {
	
	const dispatch = useAppDispatch();
	const [preEsistente, setPreEsistente] = useState<boolean>(
		iterConnessione.iter_semplificato ? false : true
	);

	const tipiServizio = useSelector<RootState, TipoServizio[]>(
		(state) => state.tipiServizioState.tipiServizio.results
	);

	const [refresh, setRefresh] = useState<boolean>(false);

	useEffect(() => {
		setRefresh(false);
	}, [refresh]);

	// gestione chiusura modal su scelta "Annulla"
	const [openConferma, setOpenConferma] = useState(false);
	const handleConfermaClose = () => setOpenConferma(false);

	return (
		<Box>
			<Grid container spacing={2} sx={{ mb: 4 }}>
				<Grid item xs={12} sm={12} md={8} lg={9} order={{ xs: 2, md: 1 }}>
					<Card>
						<CardHeader
							title={
								<Stack
									spacing={2}
									direction="row"
									color="white"
									justifyContent="space-between"
								>
									<Typography variant="h4">
										Richiesta di avvio servizio{" "}
										{testoBottoneProseguimento(
											Object.entries(ServizioTypes).find(
												([key, value]) =>
													key ==
													tipiServizio.find(
														(ts) => ts.id == iterConnessione?.tipo_servizio
													)?.codice
											)?.[1]
										)}
									</Typography>
								</Stack>
							}
							sx={{ backgroundColor: "#22568e" }}
						/>
						<CardContent sx={{ backgroundColor: "#eeeeee" }}>
							<Grid container spacing={2}>
								{produttore && (
									<Grid item xs={12} lg={12}>
										<IterConnessioneAvvioIterForm
											iterConnessione={iterConnessione}
											produttore={produttore}
											consentiModifica={consentiModifica}
										/>
									</Grid>
								)}
								{/*<Grid item xs={12} lg={6}>*/}
								{/*	<IterConnessioneIbanForm*/}
								{/*		iterConnessione={iterConnessione}*/}
								{/*		consentiModifica={consentiModifica}*/}
								{/*	/>*/}
								{/*</Grid>*/}
							</Grid>

							<Grid
								container
								justifyContent="center"
								spacing={2}
								sx={{ mb: 2 }}
							>
								<Grid item xs={12} sm={6}>
									{utenteAutorizzato(utente, [
										PermissionTypes.CLIENTI,
										PermissionTypes.OPERATORI,
										// PermissionTypes.GESTORI_ITER,
									]) && <AzioniClienteCard iterConnessione={iterConnessione} />}
								</Grid>
							</Grid>
						</CardContent>
					</Card>

					{!!iterConnessione.pod_commessa?.id && (
						<AllegatiPage
							faseIterConnessione="AVV"
							iterConnessioneId={iterConnessione.id || 0}
							consentiModifica={
								iterConnessione.stato != "N" || consentiModifica
							}
						/>
					)}
				</Grid>
				<Grid item xs={12} sm={6} md={4} lg={3} order={{ xs: 1, md: 2 }}>
					<IterConnessioneSpalla iterConnessione={iterConnessione} />

					{utenteAutorizzato(utente, [PermissionTypes.GESTORI_ITER]) && (
						<AzioniGestoreCard iterConnessione={iterConnessione} />
					)}
				</Grid>
			</Grid>
		</Box>
	);
};

export default IterConnessioneAvvioIterTab;
