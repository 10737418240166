import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {useLocation, Link, useNavigate} from "react-router-dom";

import {RootState, useAppDispatch} from "../../../redux/store";
import {Routes_path} from "../../routerApp";

import {DateTime} from "luxon";
import {convertToDateTime} from "components/common/dateUtils/convertToDateTime";
import {
    ErrorStatusTypes,
    ErrorsStack,
    parseErrorMessage,
} from "../../common/errorsDeclarations";
import {utenteAutorizzato} from "../../authentication/UtenteAutorizzato";
import {UtenteLogged} from "../../authentication/authenticationSlice";
import {PermissionTypes} from "../../authentication/permissionsGroups";
import {
    OrdinamentoLista,
    PreferenzeLista,
    setPreferenzeListaCommesse,
} from "../../userPreference/userPreferenceSlice";

import CommessePageBreadcrumbs from "./CommessePageBreadcrumbs";
import {
    fetchCommesse,
    Commessa,
    CommesseState,
    saveCommessa,
    resetNuovaCommessaId,
    StatoCommessaTypes, TipoIntervento,
} from "./commesseSlice";
import {RegimeCommerciale} from "../../anagrafiche/regimiCommerciali/regimiCommercialiSlice";
import {
    StatoIterTypes,
    StatoConnessioneTypes,
    StatiIter,
    StatoIter,
} from "../../anagrafiche/kpi/kpiSlice";
import {
    Soggetto,
    resetProduttoreCorrente,
} from "../../anagrafiche/produttori/produttoriSlice";

// import IterConnessioneAlerChip from "./commessa/IterConnessioneAlerChip";
import {Cliente} from "../../anagrafiche/clienti/clientiSlice";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {Box} from "@mui/system";
import Chip from "@mui/material/Chip";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import AddIcon from "@mui/icons-material/Add";

import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import Pagination from "@mui/material/Pagination";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Select, {SelectChangeEvent} from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import {TipoPratica} from "components/anagrafiche/tipiPratica/tipiPraticaSlice";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import ReplayIcon from "@mui/icons-material/Replay";

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

const CommessePage = () => {

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    type LocationState = {
        statoCommessaId?: string;
        clienteId?: number;
        iterSemplificato?: string;
    };
    const location = useLocation();

    const preferenzeListaCommesse = useSelector<
        RootState,
        PreferenzeLista | undefined
    >((state) => state.userPreferenceState.preferenzeListaCommesse);

    const [statoCommessaId, setStatoCommessaId] = useState(
        location.state
            ? (location.state as LocationState).statoCommessaId
            : preferenzeListaCommesse?.stato
        //  : undefined
    );

    const [clienteId, setClienteIterId] = useState(
        location.state
            ? (location.state as LocationState).clienteId
            : preferenzeListaCommesse?.clienteIterId
        // : undefined
    );

    const [iterSemplificato, setIterSemplificato] = useState(
        location.state
            ? (location.state as LocationState).iterSemplificato
            : // : preferenzeListaCommesse.iterSemplificato
            undefined
    );

    const commesse = useSelector<RootState, CommesseState>(
        (state) => state.commesseState.commesse
    );
    const nuovaCommessaId = useSelector<RootState, number | undefined>(
        (state) => state.commesseState.nuovaCommessaId
    );
    const utente = useSelector<RootState, UtenteLogged>(
        (state) => state.authentication.utenteLogged
    );
    const statiIter = useSelector<RootState, StatiIter>(
        (state) => state.kpiState.statiIter
    );

    const clienti = useSelector<RootState, Cliente[]>(
        (state) => state.clientiState.clienti.results
    );
    const produtori = useSelector<RootState, Soggetto[]>(
        (state) => state.produttoriState.produttori.results
    );


    const tipiIntervento = useSelector<RootState, TipoIntervento[]>(
        (state) => state.commesseState.tipiIntervento.results
    );
    const tipiPratica = useSelector<RootState, TipoPratica[]>(
        (state) => state.tipiPraticaState.tipiPratica.results
    );

    const produttori = useSelector<RootState, Soggetto[]>(
        (state) => state.produttoriState.produttori.results
    );

    const [page, setPage] = useState<number>(preferenzeListaCommesse?.page || 1);
    const [numeroRecord, setNumeroRecord] = useState<number>(
        preferenzeListaCommesse?.numeroRecord || 10
    );
    const [search, setSearch] = useState<string | undefined>(undefined);

    const handleChangeNumeroRecord = (event: SelectChangeEvent) => {
        setNumeroRecord(Number(event.target.value));
    };

    const handlerPager = (event: React.ChangeEvent<unknown>, page: number) => {
        setPage(page);
    };

    const [refresh, setRefresh] = useState<boolean>(false);

    const [anchorElStatoFilter, setAnchorElStatoFilter] = useState(null);
    const [anchorElClienteFilter, setAnchorElClienteFilter] = useState(null);
    const [anchorElTipoIterFilter, setAnchorElTipoIterFilter] = useState(null);

    const handleStatoFilterClose = () => {
        setAnchorElStatoFilter(null);
        setAnchorElClienteFilter(null);
        setAnchorElTipoIterFilter(null);
        // onClose(selectedValue);
    };

    const [listaStatoId, setListaStatoId] = useState<string[]>([]);
    const toggleStato = (key: string) => {
        setListaStatoId((prevLista) => {
                if (prevLista.includes(key)) {
                    // Se la key è già presente nell'array, la rimuoviamo
                    return prevLista.filter((statoKey) => statoKey !== key);
                } else {
                    // Se la key non è presente, la aggiungiamo
                    return [...prevLista, key];
                }
            }
        );
        console.log("lista_stato_id: ", listaStatoId);
    };

    useEffect(() => {
        dispatch(
            fetchCommesse({
                // statoCommessa: statoCommessaId,
                listaStatoId: listaStatoId,
                cliente: clienteId,
                numeroRecord: numeroRecord,
                page: page,
                search: search,
            })
        );
        dispatch(
            setPreferenzeListaCommesse({
                numeroRecord: numeroRecord,
                page: page,
                // stato: statoCommessaId,
                clienteIterId: clienteId,
                iterSemplificato: iterSemplificato,
            })
        );
        dispatch(resetNuovaCommessaId());
        setRefresh(false);
    }, [
        page,
        numeroRecord,
        search,
        // statoCommessaId,
        clienteId,
        iterSemplificato,
        refresh,
    ]);

    useEffect(() => {
        !!nuovaCommessaId && navigate(Routes_path.COMMESSA + nuovaCommessaId);
        dispatch(resetNuovaCommessaId());
    }, [nuovaCommessaId]);

    const handlerCreateCommessa = () => {
        const nuovaCommessa: Commessa = {
            codice_commessa_legacy: "",
            referente: utente.id,
            data_avvio: new Date(),
            stato: "A",
            contraente_completo: false,
            titolare_completo: false,
            produttore_completo: false,
            pod_completo: false,
            materiali_completo: false
        };

        dispatch(saveCommessa({commessaToSave: nuovaCommessa}));
    };

    return (
        <Box
            sx={{
                backgroundColor: "#fff",
                elevation: 20,
                // p: 2,
                "& .campo-editabile": {
                    backgroundColor: "#bbbbbb11",
                    background: "linear-gradient(to right bottom, #f5f7f6, #dce3e0)",
                    // borderWidth: "5px",
                    borderColor: "#ff91003d",
                    // borderColor: "#0000003d",
                    // borderStyle: "outset",
                    borderWidth: 3,
                    borderStyle: "ridge",
                    color: "#1a3e72",
                    fontWeight: "600",
                },
                "& .campo-errore": {
                    backgroundColor: "rgba(124, 3, 0, 0.3)",
                    color: "#ffffff",
                    fontWeight: "600",
                },
            }}
        >
            <CommessePageBreadcrumbs/>
            <List sx={{mt: 2}}>
                <ListItem>
                    <ButtonGroup
                        variant="outlined"
                        aria-label="outlined button group"
                        sx={{mb: 2}}
                    >
                        <Button
                            color="primary"
                            startIcon={<ReplayIcon/>}
                            onClick={() => setRefresh(true)}
                        >
                            aggiorna
                        </Button>
                        <Button
                            color="primary"
                            startIcon={<AddIcon/>}
                            disabled={
                                !utenteAutorizzato(utente, [
                                    // PermissionTypes.GESTORI_ITER,
                                    PermissionTypes.GENERAL_CONTRACTOR,
                                    PermissionTypes.BCK_OFFICE_GENERAL_CONTRACTOR,
                                    // PermissionTypes.OPERATORI,
                                    // PermissionTypes.PROGETTISTI,
                                ])
                            }
                            onClick={(event) => {
                                event.preventDefault();
                                handlerCreateCommessa();
                            }}
                        >
                            Aggiungi nuova Commessa
                        </Button>
                    </ButtonGroup>
                </ListItem>
                <ListItemText
                    key={"Lista_saldo_pallet_committente"}
                    sx={{backgroundColor: "#1b75b1", color: "#ddd", mb: 1}}
                >
                    <Grid container spacing={{xs: 0.5, md: 1, lg: 2}} sx={{px: 2}}>
                        <Grid item xs={2} sm={2} md={1} lg={2}>
                            <Typography variant="h5" gutterBottom>
                                n°
                            </Typography>
                        </Grid>
                        <Grid item xs={3} sm={3} md={2} lg={2}>
                            <Typography variant="h5" gutterBottom>
                                Contraente
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={2}>
                            <Stack direction="row" spacing={1}>
                                <Typography variant="h5">Stato</Typography>
                                <IconButton
                                    color={listaStatoId.length > 0 ? "secondary" : "inherit"}
                                    onClick={(event: any) =>
                                        setAnchorElStatoFilter(event.currentTarget)
                                    }
                                >
                                    <FilterAltIcon sx={{mt: -0.5}}/>
                                </IconButton>

                                <Box style={{display: "flex", justifyContent: "space-between"}}>
                                    <Menu
                                        id="simple-menu"
                                        anchorEl={anchorElStatoFilter}
                                        keepMounted
                                        open={Boolean(anchorElStatoFilter)}
                                        onClose={handleStatoFilterClose}
                                    >
                                        <FormGroup sx={{px: 1}}>
                                            {Object.entries(StatoCommessaTypes).map(([key, value]) =>
                                                <FormControlLabel
                                                    key={`stato_${key}`}
                                                    control={<Checkbox
                                                        checked={listaStatoId.includes(key)}
                                                        onChange={() => toggleStato(key)}
                                                        inputProps={{'aria-label': 'controlled'}}
                                                    />}
                                                    label={value}
                                                />
                                            )}
                                        </FormGroup>
                                        <ButtonGroup sx={{mt: 1}}>
                                            <Button onClick={() => {
                                                setListaStatoId([]);
                                                setAnchorElStatoFilter(null);
                                                setRefresh(true)

                                            }}
                                                    variant="outlined"
                                            >
                                                Tutte
                                            </Button>

                                            <Button onClick={() => {
                                                setRefresh(true)
                                                handleStatoFilterClose()
                                            }}
                                                    variant="outlined"
                                            >
                                                Filtra
                                            </Button>
                                        </ButtonGroup>
                                    </Menu>
                                </Box>
                            </Stack>
                        </Grid>

                        <Grid item xs={3} sm={3} md={2} lg={4}>
                            <Typography variant="h5" gutterBottom>
                                Pod
                            </Typography>
                        </Grid>

                        <Grid item xs={6} sm={6} md={6} lg={1}>
                            <Typography variant="h5" gutterBottom>
                                Data Partenza
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={1}>
                            <Typography variant="h5" gutterBottom>
                                Data Fine
                            </Typography>
                        </Grid>
                    </Grid>
                </ListItemText>

                <Backdrop
                    sx={{
                        color: "#fff",
                        zIndex: (theme) => theme.zIndex.drawer + 1,
                    }}
                    open={
                        commesse.errorsStack.status == ErrorStatusTypes.PENDING
                        // errorsStack.status == ErrorStatusTypes.PENDING
                    }
                >
                    <CircularProgress color="inherit"/>
                </Backdrop>

                {commesse.results?.map((commessa, index) => (
                    <ListItemButton
                        // key={"iter_" + commessa.id}
                        key={index}
                        component={Link}
                        to={Routes_path.COMMESSA + commessa.id}
                        sx={{
                            color: "black",
                            textDecoration: "none",
                            backgroundColor: index % 2 === 0 ? "#fff" : "#f2f2f2",
                        }}
                    >
                        <Grid container spacing={{xs: 0.5, md: 1, lg: 2}} sx={{mb: 1}}>
                            <Grid item xs={6} sm={6} md={11} lg={2}>
                                {/* <Link
									to={Routes_path.COMMESSA + commessa.id}
									key={`commessa_${commessa.id}`}
								> */}
                                <Stack direction="row" spacing={1}>
                                    <Chip color="primary" variant="outlined" label={commessa?.id}/>

                                    <Typography
                                        variant="caption">{tipiIntervento.filter(tipo => commessa.tipi_intervento?.includes(tipo.id))?.map((tipo) => tipo.descrizione).join(", ")}</Typography>
                                    <Typography variant="h6"
                                                noWrap>{commessa.codice_commessa_legacy && ` (${commessa.codice_commessa_legacy})`}</Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} lg={2}>
                                <Typography noWrap>
                                    {commessa.contraente_descrizione}
                                </Typography>
                            </Grid>

                            <Grid item xs={6} sm={6} md={6} lg={2}>
                                {
                                    Object.entries(StatoCommessaTypes).find(
                                        ([key, value]) => key == commessa.stato
                                    )?.[1]
                                }

                                {/* <IterConnessioneAlerChip commessa={commessa} /> */}
                            </Grid>

                            <Grid item xs={6} sm={6} md={6} lg={4}>
                                <Typography noWrap>{commessa.pod_descrizione}</Typography>
                            </Grid>

                            <Grid item xs={6} sm={6} md={6} lg={1}>
                                {commessa.data_avvio
                                    ? convertToDateTime(commessa.data_avvio)?.setLocale("it").toFormat("dd/MM/yyyy")
                                    : ""}
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} lg={1}>
                                {commessa.data_conclusione
                                    ? convertToDateTime(commessa.data_conclusione)?.setLocale("it").toFormat("dd/MM/yyyy")
                                    : ""}
                            </Grid>
                        </Grid>
                    </ListItemButton>
                ))}

                <ListItem
                    sx={{
                        alignContent: "right",
                        width: "100%",
                        mt: 2,
                        borderTopStyle: "solid",
                        borderTopWidth: 1,
                    }}
                >
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        spacing={2}
                        sx={{width: "100%"}}
                    >
                        <Pagination
                            count={commesse.num_pages}
                            onChange={handlerPager}
                            page={page}
                        />
                        {statoCommessaId || clienteId ? (
                            <Typography noWrap>
                                Totale commesse filtrati:{"  "}
                                {commesse.count}
                            </Typography>
                        ) : (
                            <Typography noWrap>
                                Totale commesse:{"  "}
                                {commesse.count}
                            </Typography>
                        )}

                        <TextField
                            id="input-with-icon-textfield"
                            // label="TextField"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="end">
                                        <SearchIcon/>
                                    </InputAdornment>
                                ),
                            }}
                            variant="standard"
                            value={search}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setSearch(event.target.value);
                            }}
                        />
                        <FormControl>
                            <InputLabel id="demo-simple-select-label">Record</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={numeroRecord.toString()}
                                label="Age"
                                onChange={handleChangeNumeroRecord}
                                variant="outlined"
                            >
                                <MenuItem value={10}>10</MenuItem>
                                <MenuItem value={20}>20</MenuItem>
                                <MenuItem value={50}>50</MenuItem>
                            </Select>
                        </FormControl>
                    </Stack>
                </ListItem>
            </List>
        </Box>
    );
};

export default CommessePage;
