import React, { useState } from "react";

import { RootState, useAppDispatch } from "../../../../../../redux/store";

import { Commessa, saveCommessa } from "../../../commesseSlice";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import ConfermaAnnulla from "components/common/ConfermaAnnulla";
import Typography from "@mui/material/Typography";

interface ConfermaMaterialiCardProps {
	commessa: Commessa;
	consentiModifica: boolean;
}

const ConfermaMaterialiCard = ({
	commessa,
	consentiModifica,
}: ConfermaMaterialiCardProps) => {
	const dispatch = useAppDispatch();

	// gestione chiusura modal su scelta "Annulla"
	const [openConferma, setOpenConferma] = useState(false);
	const handleConfermaClose = () => setOpenConferma(false);
	const handleConfermaSave = () => {
		dispatch(saveCommessa({ commessaToSave: commessa, azione : "conferma_materiali" }));
		setOpenConferma(false);
	};


	return (
		<Box
			sx={{
				backgroundColor: "#fff",
				elevation: 20,
				p: 2,
			}}
		>
			{commessa?.pod?.prodotti &&
				commessa.pod.prodotti.filter((prod) => prod.pre_esistente == true)
					.length > 0 && (
					<Typography variant="h6" gutterBottom color="primary">
						Materiali Esistenti
					</Typography>
				)}
			{commessa?.pod?.prodotti
				?.filter((prod) => prod.pre_esistente == true)
				.map((prodotto, index) => (
					<Typography key={prodotto.id}>
						{prodotto.tipo_prodotto} {": marca "}
						{prodotto.marca}
						{" - modello "}
						{prodotto.modello}{" "}
						{prodotto.potenza_unitaria
							? " - potenza unit. " + prodotto.potenza_unitaria
							: ""}
						{prodotto.capacita_totale
							? " - capacità tot. " + prodotto.capacita_totale
							: ""}
						{" - n. "}
						{prodotto.quantita}
					</Typography>
				))}
			{commessa?.pod?.prodotti &&
				commessa.pod.prodotti.filter((prod) => prod.pre_esistente == false)
					.length > 0 && (
					<Typography
						variant="h6"
						gutterBottom
						color="primary"
						sx={{ mt: 1.5 }}
					>
						Materiali Nuovi
					</Typography>
				)}
			{commessa?.pod?.prodotti
				?.filter((prod) => prod.pre_esistente == false)
				.map((prodotto, index) => (
					<Typography key={prodotto.id}>
						{prodotto.tipo_prodotto} {": marca "}
						{prodotto.marca}
						{" - modello "}
						{prodotto.modello}{" "}
						{prodotto.potenza_unitaria
							? " - potenza unit. " + prodotto.potenza_unitaria
							: ""}
						{prodotto.capacita_totale
							? " - capacità tot. " + prodotto.capacita_totale
							: ""}
						{" - n. "}
						{prodotto.quantita}
					</Typography>
				))}
			<Grid container spacing={2} width={"100%"} sx={{ mb: 1 }}>
				<Grid item xs={12} sm={6} lg={10}></Grid>
				<Grid item xs={12} sm={6} lg={2}>
					{commessa.stato == "C" && (
						<Button
							variant="contained"
							color="primary"
							type="submit"
							disabled={!consentiModifica || !(commessa.stato == "C" && commessa.stato_completato)}
							sx={{ width: "100%" }}
							onClick={() => setOpenConferma(true)}
						>
							Conferma
						</Button>
					)}
				</Grid>
			</Grid>
			<ConfermaAnnulla
				handleAnnulla={handleConfermaClose}
				handleConferma={handleConfermaSave}
				domanda={
					"Operazione non reversibile. Sicuro di voler confermare i materiali"
				}
				open={openConferma}
			/>
		</Box>
	);
};

export default ConfermaMaterialiCard;
