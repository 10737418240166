import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";

import {
	ErrorStatusTypes,
	ErrorsStack,
	parseErrorMessage,
} from "../../common/errorsDeclarations";
import * as kpiApi from "../../../api/anagrafiche/kpiApi";
import { toast } from "react-toastify";

export enum ServizioTypes {
	SOP_FV = "SOP_FV", // Sopralluogo per impianto fotovoltaico
	ITERS_FV = "ITERS_FV", // Nuovo Iter Connessione per Impianti Nuovi, + schema elettrico + convenzione operatore dispacciamento (GSE)
	ITERO_FV = "ITERO_FV", // Nuovo Iter Connessione per ampliam. Impianti Esistenti + schema elettrico + convenzione operatore dispacciamento (GSE)
	ENEA_FV = "ENEA_FV", // Comunicazione su portale Enea
	OFF_FV = "OFF_FV", // Officina Elettrica (ADM) - Apertura nuova Officina Elettrica
	ADEM_FV = "ADEM_FV", // Gestione scadenze mantenim. esercizio impiant. fotov.
	FES_11 = "FES_11", // Full Engineering Service sino a 11,08 kWp
	FES_20 = "FES_20", // Full Engineering Service sino a 20 kWp
	FES_150 = "FES_150", // Full Engineering Service sino a 150 kWp
}

export enum StatoIterTypes {
	A = "da affidare",
	B = "pronta per richiesta presa in carico",
	C = "richiesta presa in carico",
	D = "presa in carico",
	E = "assegnazione",
	F = "avvio",
	G = "progetto",
	H = "connessione",
	I = "fine lavori",
	L = "allaccio",
	M = "in consegna",
	N = "consegnata",
}
export enum StatoConnessioneTypes {
	Z = "ddc da inviare",
	A = "in attesa di conferma",
	B = "in attesa di preventivo",
	C = "preventivo ricevuto",
	D = "preventivo in pagamento",
	E = "preventivo pagato",
	F = "inoltrata al gestore",
	G = "confermata",
	H = "rigettata",
}
export enum StatoAutorizzazioneComunaleTypes {
	A = "in corso",
	B = "conclusa",
}

export interface StatoIter {
	stato: string;
	stato_desc: string;
	count: number;
}
export interface StatoConnessione {
	stato: string;
	stato_desc: string;
	count: number;
}

export interface StatiIter {
	count: number;
	page: number;
	num_pages: number;
	next?: URL;
	previous?: URL;
	results: StatoIter[];
	errorsStack: ErrorsStack;
}

export interface StatiConnessioni {
	count: number;
	page: number;
	num_pages: number;
	next?: URL;
	previous?: URL;
	results: StatoConnessione[];
	errorsStack: ErrorsStack;
}
export interface ConteggioStati {
	in_lavorazione?: number;
	disponibile?: number;

	errorsStack: ErrorsStack;
}
export interface ProgettiEmessiKpi {
	progetti_emessi_kpi: number;
	errorsStack: ErrorsStack;
}
export interface ProgettiRilavoratiKpi {
	progetti_rilavorati_kpi: number;
	errorsStack: ErrorsStack;
}
export interface RichiestePreventiviKpi {
	richieste_preventivi_kpi: number;
	errorsStack: ErrorsStack;
}
export interface RichiesteRilavorateKpi {
	richieste_rilavorate_kpi: number;
	errorsStack: ErrorsStack;
}
export interface RegolamentoEsercizioKpi {
	regolamento_esercizio_kpi: number;
	errorsStack: ErrorsStack;
}

export interface RegolamentoRilavorateKpi {
	regolamento_rilavorate_kpi: number;
	errorsStack: ErrorsStack;
}

export interface KpiStrutturaState {
	statiIter: StatiIter;
	statiConnessioni: StatiIter;
	conteggioStatiSopralluoghi: ConteggioStati;
	conteggioStatiProgettii: ConteggioStati;
	conteggioStatiIter: ConteggioStati;
	conteggioPraticheOfficinaElettrica: ConteggioStati;
	conteggioAutorizzazioniComunali: ConteggioStati;
	conteggioDispacciamentoGse: ConteggioStati;
	conteggioPraticheEnea: ConteggioStati;
	progettiEmessiKpi: ProgettiEmessiKpi;
	progettiRilavoratiKpi: ProgettiRilavoratiKpi;
	richiestePreventiviKpi: RichiestePreventiviKpi;
	richiesteRilavorateKpi: RichiesteRilavorateKpi;
	regolamentoEsercizioKpi: RegolamentoEsercizioKpi;
	regolamentoRilavorateKpi: RegolamentoRilavorateKpi;
}

const initialState: KpiStrutturaState = {
	statiIter: {
		count: 0,
		page: 0,
		num_pages: 0,
		next: undefined,
		previous: undefined,
		results: [],
		errorsStack: { status: ErrorStatusTypes.OK },
	},
	statiConnessioni: {
		count: 0,
		page: 0,
		num_pages: 0,
		next: undefined,
		previous: undefined,
		results: [],
		errorsStack: { status: ErrorStatusTypes.OK },
	},
	conteggioStatiSopralluoghi: {
		in_lavorazione: undefined,
		disponibile: undefined,

		errorsStack: { status: ErrorStatusTypes.OK },
	},
	conteggioStatiProgettii: {
		in_lavorazione: undefined,
		disponibile: undefined,

		errorsStack: { status: ErrorStatusTypes.OK },
	},
	conteggioStatiIter: {
		in_lavorazione: undefined,
		disponibile: undefined,

		errorsStack: { status: ErrorStatusTypes.OK },
	},
	conteggioDispacciamentoGse: {
		in_lavorazione: undefined,
		disponibile: undefined,

		errorsStack: { status: ErrorStatusTypes.OK },
	},
	conteggioPraticheOfficinaElettrica: {
		in_lavorazione: undefined,
		disponibile: undefined,

		errorsStack: { status: ErrorStatusTypes.OK },
	},
	conteggioAutorizzazioniComunali: {
		in_lavorazione: undefined,
		disponibile: undefined,

		errorsStack: { status: ErrorStatusTypes.OK },
	},
	conteggioPraticheEnea: {
		in_lavorazione: undefined,
		disponibile: undefined,

		errorsStack: { status: ErrorStatusTypes.OK },
	},
	progettiEmessiKpi: {
		progetti_emessi_kpi: 0,
		errorsStack: { status: ErrorStatusTypes.OK },
	},
	progettiRilavoratiKpi: {
		progetti_rilavorati_kpi: 0,
		errorsStack: { status: ErrorStatusTypes.OK },
	},
	richiestePreventiviKpi: {
		richieste_preventivi_kpi: 0,
		errorsStack: { status: ErrorStatusTypes.OK },
	},
	richiesteRilavorateKpi: {
		richieste_rilavorate_kpi: 0,
		errorsStack: { status: ErrorStatusTypes.OK },
	},
	regolamentoEsercizioKpi: {
		regolamento_esercizio_kpi: 0,
		errorsStack: { status: ErrorStatusTypes.OK },
	},
	regolamentoRilavorateKpi: {
		regolamento_rilavorate_kpi: 0,
		errorsStack: { status: ErrorStatusTypes.OK },
	},
};

export const fetchStatiIter = createAsyncThunk(
	"kpi/fetchStatiIter",
	async () => {
		return await kpiApi.fetchStatiIter();
	}
);
export const fetchStatiConnessioni = createAsyncThunk(
	"kpi/fetchStatiConnessioni",
	async () => {
		return await kpiApi.fetchStatiConnessioni();
	}
);
export const fetchConteggioStatiSopralluoghi = createAsyncThunk(
	"kpi/fetchConteggioStatiSopralluoghi",
	async () => {
		return await kpiApi.fetchConteggioStatiSopralluoghi();
	}
);
export const fetchConteggioStatiProgetti = createAsyncThunk(
	"kpi/fetchConteggioStatiProgetti",
	async () => {
		return await kpiApi.fetchConteggioStatiProgetti();
	}
);
export const fetchConteggioStatiIter = createAsyncThunk(
	"kpi/fetchConteggioStatiIter",
	async () => {
		return await kpiApi.fetchConteggioStatiIter();
	}
);
export const fetchConteggioDispacciamentoGse = createAsyncThunk(
	"kpi/fetchConteggioDispacciamentoGse",
	async () => {
		return await kpiApi.fetchConteggioDispacciamentoGse();
	}
);
export const fetchConteggioPraticheEnea = createAsyncThunk(
	"kpi/fetchConteggioPraticheEnea",
	async () => {
		return await kpiApi.fetchConteggioPraticheEnea();
	}
);

export const fetchConteggioPraticheOfficinaElettrica = createAsyncThunk(
	"kpi/fetchConteggioPraticheOfficinaElettrica",
	async () => {
		return await kpiApi.fetchConteggioPraticheOfficinaElettrica();
	}
);
export const fetchConteggioPraticheAutorizzazioniComunali = createAsyncThunk(
	"kpi/fetchConteggioPraticheAutorizzazioniComunali",
	async () => {
		return await kpiApi.fetchConteggioPraticheAutorizzazioniComunali();
	}
);

export const progettiEmessiKpiFetch = createAsyncThunk(
	"kpi/progettiEmessiKpiFetch",
	async (parametri: { data_da?: Date; data_a?: Date; periodo?: string }) => {
		return await kpiApi.progettiEmessiKpiFetch(
			parametri.data_da,
			parametri.data_a,
			parametri.periodo
		);
	}
);

export const progettiRilavoratiKpiFetch = createAsyncThunk(
	"kpi/progettiRilavoratiKpiFetch",
	async (parametri: { data_da?: Date; data_a?: Date; periodo?: string }) => {
		return await kpiApi.progettiRilavoratiKpiFetch(
			parametri.data_da,
			parametri.data_a,
			parametri.periodo
		);
	}
);

export const richiestePreventiviKpiFetch = createAsyncThunk(
	"kpi/richiestePreventiviKpiFetch",
	async (parametri: { data_da?: Date; data_a?: Date; periodo?: string }) => {
		return await kpiApi.richiestePreventiviKpiFetch(
			parametri.data_da,
			parametri.data_a,
			parametri.periodo
		);
	}
);

export const richiesteRilavorateKpiFetch = createAsyncThunk(
	"kpi/richiesteRilavorateKpiFetch",
	async (parametri: { data_da?: Date; data_a?: Date; periodo?: string }) => {
		return await kpiApi.richiesteRilavorateKpiFetch(
			parametri.data_da,
			parametri.data_a,
			parametri.periodo
		);
	}
);

export const regolamentoEsercizioKpiFetch = createAsyncThunk(
	"kpi/regolamentoEsercizioKpiFetch",
	async (parametri: { data_da?: Date; data_a?: Date; periodo?: string }) => {
		return await kpiApi.regolamentoEsercizioKpiFetch(
			parametri.data_da,
			parametri.data_a,
			parametri.periodo
		);
	}
);

export const regolamentoRilavorateKpiFetch = createAsyncThunk(
	"kpi/regolamentoRilavorateKpiFetch",
	async (parametri: { data_da?: Date; data_a?: Date; periodo?: string }) => {
		return await kpiApi.regolamentoRilavorateKpiFetch(
			parametri.data_da,
			parametri.data_a,
			parametri.periodo
		);
	}
);

export const kpiSlice = createSlice({
	name: "gestori",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// fetch
		builder.addCase(fetchStatiIter.pending, (state, action) => {
			state.statiIter.errorsStack = { status: ErrorStatusTypes.PENDING };
		});
		builder.addCase(fetchStatiIter.fulfilled, (state, action) => {
			state.statiIter = action.payload;
			state.statiIter.errorsStack = { status: ErrorStatusTypes.OK };
		});
		builder.addCase(fetchStatiIter.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");
			state.statiIter.errorsStack = {
				status: ErrorStatusTypes.ERROR,
				fieldsErrors: JSON.parse(action?.error?.message || ""),
			};
		});
		// fetch fetchStatiConnessioni
		builder.addCase(fetchStatiConnessioni.pending, (state, action) => {
			state.statiConnessioni.errorsStack = {
				status: ErrorStatusTypes.PENDING,
			};
		});
		builder.addCase(fetchStatiConnessioni.fulfilled, (state, action) => {
			state.statiConnessioni = action.payload;
			state.statiConnessioni.errorsStack = {
				status: ErrorStatusTypes.OK,
			};
		});
		builder.addCase(fetchStatiConnessioni.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");
			state.statiConnessioni.errorsStack = {
				status: ErrorStatusTypes.ERROR,
				fieldsErrors: JSON.parse(action?.error?.message || ""),
			};
		});

		// fetch fetchConteggioStatiSopralluoghi
		builder.addCase(
			fetchConteggioStatiSopralluoghi.pending,
			(state, action) => {
				state.conteggioStatiSopralluoghi.errorsStack = {
					status: ErrorStatusTypes.PENDING,
				};
			}
		);
		builder.addCase(
			fetchConteggioStatiSopralluoghi.fulfilled,
			(state, action) => {
				state.conteggioStatiSopralluoghi = action.payload;
				state.conteggioStatiSopralluoghi.errorsStack = {
					status: ErrorStatusTypes.OK,
				};
			}
		);
		builder.addCase(
			fetchConteggioStatiSopralluoghi.rejected,
			(state, action) => {
				toast.error("Errore:" + action?.error?.message || "");
				state.conteggioStatiSopralluoghi.errorsStack = {
					status: ErrorStatusTypes.ERROR,
					fieldsErrors: JSON.parse(action?.error?.message || ""),
				};
			}
		);
		// fetch fetchConteggioStatiProgetti
		builder.addCase(fetchConteggioStatiProgetti.pending, (state, action) => {
			state.conteggioStatiProgettii.errorsStack = {
				status: ErrorStatusTypes.PENDING,
			};
		});
		builder.addCase(fetchConteggioStatiProgetti.fulfilled, (state, action) => {
			state.conteggioStatiProgettii = action.payload;
			state.conteggioStatiProgettii.errorsStack = {
				status: ErrorStatusTypes.OK,
			};
		});
		builder.addCase(fetchConteggioStatiProgetti.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");
			state.conteggioStatiProgettii.errorsStack = {
				status: ErrorStatusTypes.ERROR,
				fieldsErrors: JSON.parse(action?.error?.message || ""),
			};
		});
		// fetch fetchConteggioStatiIter
		builder.addCase(fetchConteggioStatiIter.pending, (state, action) => {
			state.conteggioStatiIter.errorsStack = {
				status: ErrorStatusTypes.PENDING,
			};
		});
		builder.addCase(fetchConteggioStatiIter.fulfilled, (state, action) => {
			state.conteggioStatiIter = action.payload;
			state.conteggioStatiIter.errorsStack = {
				status: ErrorStatusTypes.OK,
			};
		});
		builder.addCase(fetchConteggioStatiIter.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");
			state.conteggioStatiIter.errorsStack = {
				status: ErrorStatusTypes.ERROR,
				fieldsErrors: JSON.parse(action?.error?.message || ""),
			};
		});
		// fetch fetchConteggioDispacciamentoGse
		builder.addCase(
			fetchConteggioDispacciamentoGse.pending,
			(state, action) => {
				state.conteggioDispacciamentoGse.errorsStack = {
					status: ErrorStatusTypes.PENDING,
				};
			}
		);
		builder.addCase(
			fetchConteggioDispacciamentoGse.fulfilled,
			(state, action) => {
				state.conteggioDispacciamentoGse = action.payload;
				state.conteggioDispacciamentoGse.errorsStack = {
					status: ErrorStatusTypes.OK,
				};
			}
		);
		builder.addCase(
			fetchConteggioDispacciamentoGse.rejected,
			(state, action) => {
				toast.error("Errore:" + action?.error?.message || "");
				state.conteggioDispacciamentoGse.errorsStack = {
					status: ErrorStatusTypes.ERROR,
					fieldsErrors: JSON.parse(action?.error?.message || ""),
				};
			}
		);
		// fetch fetchConteggioPraticheEnea
		builder.addCase(fetchConteggioPraticheEnea.pending, (state, action) => {
			state.conteggioPraticheEnea.errorsStack = {
				status: ErrorStatusTypes.PENDING,
			};
		});
		builder.addCase(fetchConteggioPraticheEnea.fulfilled, (state, action) => {
			state.conteggioPraticheEnea = action.payload;
			state.conteggioPraticheEnea.errorsStack = {
				status: ErrorStatusTypes.OK,
			};
		});
		builder.addCase(fetchConteggioPraticheEnea.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");
			state.conteggioPraticheEnea.errorsStack = {
				status: ErrorStatusTypes.ERROR,
				fieldsErrors: JSON.parse(action?.error?.message || ""),
			};
		});
		// fetch fetch ConteggioPraticheOfficinaElettrica
		builder.addCase(
			fetchConteggioPraticheOfficinaElettrica.pending,
			(state, action) => {
				state.conteggioPraticheOfficinaElettrica.errorsStack = {
					status: ErrorStatusTypes.PENDING,
				};
			}
		);
		builder.addCase(
			fetchConteggioPraticheOfficinaElettrica.fulfilled,
			(state, action) => {
				state.conteggioPraticheOfficinaElettrica = action.payload;
				state.conteggioPraticheOfficinaElettrica.errorsStack = {
					status: ErrorStatusTypes.OK,
				};
			}
		);
		builder.addCase(
			fetchConteggioPraticheOfficinaElettrica.rejected,
			(state, action) => {
				toast.error("Errore:" + action?.error?.message || "");
				state.conteggioPraticheOfficinaElettrica.errorsStack = {
					status: ErrorStatusTypes.ERROR,
					fieldsErrors: JSON.parse(action?.error?.message || ""),
				};
			}
		);
		// fetch fetch AutorizzazioniComunali
		builder.addCase(
			fetchConteggioPraticheAutorizzazioniComunali.pending,
			(state, action) => {
				state.conteggioAutorizzazioniComunali.errorsStack = {
					status: ErrorStatusTypes.PENDING,
				};
			}
		);
		builder.addCase(
			fetchConteggioPraticheAutorizzazioniComunali.fulfilled,
			(state, action) => {
				state.conteggioAutorizzazioniComunali = action.payload;
				state.conteggioAutorizzazioniComunali.errorsStack = {
					status: ErrorStatusTypes.OK,
				};
			}
		);
		builder.addCase(
			fetchConteggioPraticheAutorizzazioniComunali.rejected,
			(state, action) => {
				toast.error("Errore:" + action?.error?.message || "");
				state.conteggioAutorizzazioniComunali.errorsStack = {
					status: ErrorStatusTypes.ERROR,
					fieldsErrors: JSON.parse(action?.error?.message || ""),
				};
			}
		);
		// fetch progettiEmessiKpiFetch
		builder.addCase(progettiEmessiKpiFetch.pending, (state, action) => {
			state.progettiEmessiKpi.errorsStack = {
				status: ErrorStatusTypes.PENDING,
			};
		});
		builder.addCase(progettiEmessiKpiFetch.fulfilled, (state, action) => {
			state.progettiEmessiKpi = action.payload;
			state.progettiEmessiKpi.errorsStack = {
				status: ErrorStatusTypes.OK,
			};
		});
		builder.addCase(progettiEmessiKpiFetch.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");
			state.progettiEmessiKpi.errorsStack = {
				status: ErrorStatusTypes.ERROR,
				fieldsErrors: JSON.parse(action?.error?.message || ""),
			};
		});
		// fetch progettiRilavoratiKpiFetch
		builder.addCase(progettiRilavoratiKpiFetch.pending, (state, action) => {
			state.progettiRilavoratiKpi.errorsStack = {
				status: ErrorStatusTypes.PENDING,
			};
		});
		builder.addCase(progettiRilavoratiKpiFetch.fulfilled, (state, action) => {
			state.progettiRilavoratiKpi = action.payload;
			state.progettiRilavoratiKpi.errorsStack = {
				status: ErrorStatusTypes.OK,
			};
		});
		builder.addCase(progettiRilavoratiKpiFetch.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");
			state.progettiRilavoratiKpi.errorsStack = {
				status: ErrorStatusTypes.ERROR,
				fieldsErrors: JSON.parse(action?.error?.message || ""),
			};
		});
		// fetch richiestePreventiviKpiFetch
		builder.addCase(richiestePreventiviKpiFetch.pending, (state, action) => {
			state.richiestePreventiviKpi.errorsStack = {
				status: ErrorStatusTypes.PENDING,
			};
		});
		builder.addCase(richiestePreventiviKpiFetch.fulfilled, (state, action) => {
			state.richiestePreventiviKpi = action.payload;
			state.richiestePreventiviKpi.errorsStack = {
				status: ErrorStatusTypes.OK,
			};
		});
		builder.addCase(richiestePreventiviKpiFetch.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");
			state.richiestePreventiviKpi.errorsStack = {
				status: ErrorStatusTypes.ERROR,
				fieldsErrors: JSON.parse(action?.error?.message || ""),
			};
		});
		// fetch richiesteRilavorateKpiFetch
		builder.addCase(richiesteRilavorateKpiFetch.pending, (state, action) => {
			state.richiesteRilavorateKpi.errorsStack = {
				status: ErrorStatusTypes.PENDING,
			};
		});
		builder.addCase(richiesteRilavorateKpiFetch.fulfilled, (state, action) => {
			state.richiesteRilavorateKpi = action.payload;
			state.richiesteRilavorateKpi.errorsStack = {
				status: ErrorStatusTypes.OK,
			};
		});
		builder.addCase(richiesteRilavorateKpiFetch.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");
			state.richiesteRilavorateKpi.errorsStack = {
				status: ErrorStatusTypes.ERROR,
				fieldsErrors: JSON.parse(action?.error?.message || ""),
			};
		});
		// fetch regolamentoEsercizioKpiFetch
		builder.addCase(regolamentoEsercizioKpiFetch.pending, (state, action) => {
			state.regolamentoEsercizioKpi.errorsStack = {
				status: ErrorStatusTypes.PENDING,
			};
		});
		builder.addCase(regolamentoEsercizioKpiFetch.fulfilled, (state, action) => {
			state.regolamentoEsercizioKpi = action.payload;
			state.regolamentoEsercizioKpi.errorsStack = {
				status: ErrorStatusTypes.OK,
			};
		});
		builder.addCase(regolamentoEsercizioKpiFetch.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");
			state.regolamentoEsercizioKpi.errorsStack = {
				status: ErrorStatusTypes.ERROR,
				fieldsErrors: JSON.parse(action?.error?.message || ""),
			};
		});
		// fetch regolamentoRilavorateKpiFetch
		builder.addCase(regolamentoRilavorateKpiFetch.pending, (state, action) => {
			state.regolamentoRilavorateKpi.errorsStack = {
				status: ErrorStatusTypes.PENDING,
			};
		});
		builder.addCase(
			regolamentoRilavorateKpiFetch.fulfilled,
			(state, action) => {
				state.regolamentoRilavorateKpi = action.payload;
				state.regolamentoRilavorateKpi.errorsStack = {
					status: ErrorStatusTypes.OK,
				};
			}
		);
		builder.addCase(regolamentoRilavorateKpiFetch.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");
			state.regolamentoRilavorateKpi.errorsStack = {
				status: ErrorStatusTypes.ERROR,
				fieldsErrors: JSON.parse(action?.error?.message || ""),
			};
		});
	},
});

// Action creators are generated for each case reducer function
export const {} = kpiSlice.actions;

export const kpiSliceReducer = kpiSlice.reducer;
