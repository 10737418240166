import { handleResponse, handleError, headerset } from "../apiUtils";
import { Cliente } from "../../components/anagrafiche/clienti/clientiSlice";

const baseUrl = (cliente_id?: number | null) => {
	if (cliente_id) {
		return process.env.API_URL + "/api/anagrafiche/clienti/" + cliente_id + "/";
	} else {
		return process.env.API_URL + "/api/anagrafiche/clienti/";
	}
};

export function fetchClienti(
	numeroRecord?: number,
	page?: number,
	search?: string
) {
	let url = baseUrl();
	if (numeroRecord != undefined || search != undefined || page != undefined) {
		url += "?";
	}
	if (numeroRecord != undefined) {
		url += `numero_record=${numeroRecord}&`;
	}
	if (page != undefined) {
		url += `page=${page}&`;
	}
	if (search != undefined) {
		url += `search=${search}`;
	}

	return fetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}

export function getCliente(clienteId: number) {
	let url = baseUrl(clienteId);
	return fetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}

export function saveCliente(clienteToSave: Cliente) {
	const cliente = {
		...clienteToSave,
	};

	return fetch(baseUrl(clienteToSave.id), {
		method: clienteToSave.id ? "PUT" : "POST", // PUT to update when id already exists.
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
		body: JSON.stringify({
			...cliente,
			// Parse authorId to a number (in case it was sent as a string).
			// id: parseInt(progetto.id, 10),
		}),
	})
		.then(handleResponse)
		.catch(handleError);
}

export function deleteCliente(clienteToDelete: Cliente) {
	return fetch(baseUrl(clienteToDelete.id), {
		method: "DELETE",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}
