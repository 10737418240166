import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";

import { useLocation, Link, useNavigate } from "react-router-dom";
import { Routes_path } from "../../../../routerApp";

import { useAppDispatch } from "../../../../../redux/store";

import ConfermaAnnulla from "../../../../common/ConfermaAnnulla";
import { DateTime } from "luxon";


import { Commessa, getCommessa, saveCommessa } from "../../commesseSlice";
import {
	Sopralluogo,
	SopralluoghiState,
	StatoSopralluogoTypes,
} from "components/main/sopralluoghi/sopralluoghiSlice";
import { VerificaVincoli } from "components/main/verificheVincoli/verificheVincoliSlice";

import SopralluoghiCommessaGrid from "./SopralluoghiCommessaGrid";
import VerificheVincoliCommessaGrid from "./VerificheVincoliCommessaGrid";
import Grid from "@mui/material/Grid";
import { Box, Divider, Typography } from "@mui/material";
import { theme } from "../../../../theme";
import IconButton from "@mui/material/IconButton";

interface AnalisiPreliminariCommessaGridProps {
	commessa: Commessa;
}

const AnalisiPreliminariCommessaGrid = ({
	commessa,
}: AnalisiPreliminariCommessaGridProps) => {
	

	const sopralluoghi = useSelector<RootState, Sopralluogo[]>(
		(state) => state.sopralluoghiState.sopralluoghi.results
	);

	const verificheVincoli = useSelector<RootState, VerificaVincoli[]>(
		(state) => state.verificheVincoliState.verificheVincoli.results
	);

	return (
		<>
			{sopralluoghi.length > 0 && (
				<SopralluoghiCommessaGrid commessa={commessa} />
			)}
			{verificheVincoli.length > 0 && (
				<VerificheVincoliCommessaGrid commessa={commessa} />
			)}
		</>
	);
};
export default AnalisiPreliminariCommessaGrid;
