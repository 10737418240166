import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { Utente } from "../../authentication/authenticationSlice";

import { useLocation } from "react-router-dom";
import { styled, useTheme } from "@mui/material/styles";
import { Link } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Routes_path } from "../../routerApp";
import { PermissionTypes } from "../../../components/authentication/permissionsGroups";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ElementoMenuControllato from "./ElementoMenuControllato";
import Divider from "@mui/material/Divider";
import RuleFolderIcon from "@mui/icons-material/RuleFolder";
import QrCode2Icon from "@mui/icons-material/QrCode2";
import ElectricBoltIcon from "@mui/icons-material/ElectricBolt";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import ContactEmergencyIcon from "@mui/icons-material/ContactEmergency";
import EngineeringIcon from "@mui/icons-material/Engineering";
import HandshakeIcon from "@mui/icons-material/Handshake";
import HomeIcon from "@mui/icons-material/Home";
import ElectricalServicesIcon from "@mui/icons-material/ElectricalServices";
import FolderCopyIcon from "@mui/icons-material/FolderCopy";
import ManageHistoryIcon from "@mui/icons-material/ManageHistory";
import EditRoadIcon from "@mui/icons-material/EditRoad";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import AssuredWorkloadIcon from "@mui/icons-material/AssuredWorkload";
import EuroIcon from "@mui/icons-material/Euro";
import ContactsIcon from "@mui/icons-material/Contacts";
import ChecklistRtlIcon from "@mui/icons-material/ChecklistRtl";
import RuleIcon from "@mui/icons-material/Rule";

import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import FlashAutoIcon from "@mui/icons-material/FlashAuto";

import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import ElectricMeterIcon from "@mui/icons-material/ElectricMeter";
import ContactPageIcon from "@mui/icons-material/ContactPage";
import InventoryIcon from "@mui/icons-material/Inventory";

const PREFIX = "SideMenu";

const classes = {
	iconSelected: `${PREFIX}-iconSelected`,
};

const styleListItem = { justifyContent: "center" };

interface SideMenuProps {
	open: boolean;
	tooggleDrawer: () => void;
}
const SideMenu = ({ open, tooggleDrawer }: SideMenuProps) => {
	const location = useLocation();

	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down("md"));
	// const matches = useMediaQuery("min-width:900px)");
	const mobiletooggleDrawer = () => {
		matches && tooggleDrawer();
	};

	const StyledList = styled(List)(() => ({
		[`& .${classes.iconSelected}`]: {
			color: theme.palette.info.main,
		},
	}));
	const utente = useSelector<RootState, Utente>(
		(state) => state.authentication.utenteLogged
	);

	return (
		<StyledList>
			<ListItem
				component={Link}
				to={Routes_path.HOME}
				key={"Home"}
				sx={styleListItem}
			>
				<ListItemIcon sx={{ minWidth: "auto" }}>
					<HomeIcon
						className={
							location.pathname == Routes_path.HOME ? classes.iconSelected : ""
						}
						onClick={mobiletooggleDrawer}
					/>
				</ListItemIcon>
				{open && (
					<ListItemText
						style={{ marginLeft: 12 }}
						primary={"Home"}
						className={location.pathname == "/" ? classes.iconSelected : ""}
						onClick={mobiletooggleDrawer}
					/>
				)}
			</ListItem>

			{/* <ElementoMenuControllato
				utente={utente}
				path={Routes_path.DASHBOARD}
				label="Dashboard"
				gruppiAutorizzati={[
					PermissionTypes.GESTORI_ITER,
					PermissionTypes.PROGETTISTI,
					PermissionTypes.OPERATORI,
				]}
				open={open}
				selected={location.pathname == Routes_path.DASHBOARD}
				tooggleDrawer={tooggleDrawer}
				Icon={DashboardIcon}
			/> */}

			<ElementoMenuControllato
				utente={utente}
				path={Routes_path.COMUNITA_ENERGETICHE}
				label="Comunità Energetiche"
				gruppiAutorizzati={[
					PermissionTypes.GESTORI_COMUNITA_ENERGETICHE,
					// PermissionTypes.OPERATORI,
					// PermissionTypes.GENERAL_CONTRACTOR,
				]}
				open={open}
				selected={location.pathname == Routes_path.COMUNITA_ENERGETICHE}
				tooggleDrawer={tooggleDrawer}
				Icon={Diversity3Icon}
			/>
			<ElementoMenuControllato
				utente={utente}
				path={Routes_path.COMMESSE}
				label="Commesse"
				gruppiAutorizzati={[
					// PermissionTypes.GESTORI_ITER,
					// PermissionTypes.OPERATORI,
					PermissionTypes.GENERAL_CONTRACTOR,
					PermissionTypes.BCK_OFFICE_GENERAL_CONTRACTOR,
				]}
				open={open}
				selected={location.pathname == Routes_path.COMMESSE}
				tooggleDrawer={tooggleDrawer}
				Icon={RuleFolderIcon}
			/>

			<ElementoMenuControllato
				utente={utente}
				path={Routes_path.LETTORE_CB}
				label="Scansione Prodotti"
				gruppiAutorizzati={[
					// PermissionTypes.GESTORI_ITER,
					// PermissionTypes.OPERATORI,
					PermissionTypes.ISTALLATORI_GENERAL_CONTRACTOR,
				]}
				open={open}
				selected={location.pathname == Routes_path.LETTORE_CB}
				tooggleDrawer={tooggleDrawer}
				Icon={QrCode2Icon}
			/>

			<ElementoMenuControllato
				utente={utente}
				path={Routes_path.PRODUTTORI}
				label="Produttori"
				gruppiAutorizzati={[
					PermissionTypes.GESTORI_ITER,
					PermissionTypes.PROGETTISTI,
					PermissionTypes.OPERATORI,
				]}
				open={open}
				selected={location.pathname == Routes_path.PRODUTTORI}
				tooggleDrawer={tooggleDrawer}
				Icon={ContactPageIcon}
			/>
			<ElementoMenuControllato
				utente={utente}
				path={Routes_path.PODS}
				label="POD"
				gruppiAutorizzati={[
					PermissionTypes.GESTORI_ITER,
					PermissionTypes.PROGETTISTI,
					PermissionTypes.OPERATORI,
				]}
				open={open}
				selected={location.pathname == Routes_path.PODS}
				tooggleDrawer={tooggleDrawer}
				Icon={ElectricMeterIcon}
			/>
			<ElementoMenuControllato
				utente={utente}
				path={Routes_path.ITER_CONNESSIONI}
				label="Iter Connessione"
				gruppiAutorizzati={[
					PermissionTypes.GESTORI_ITER,
					PermissionTypes.OPERATORI,
					PermissionTypes.CLIENTI,
					// PermissionTypes.PIANIFICATORI_LETTURA,
				]}
				open={open}
				selected={location.pathname == Routes_path.ITER_CONNESSIONI}
				tooggleDrawer={tooggleDrawer}
				Icon={ElectricalServicesIcon}
			/>

			<ElementoMenuControllato
				utente={utente}
				path={Routes_path.ANAGRAFICHE}
				label="Anagrafiche"
				gruppiAutorizzati={[
					PermissionTypes.GESTORI_ITER,
					PermissionTypes.OPERATORI,
					// PermissionTypes.PIANIFICATORI_LETTURA,
				]}
				open={open}
				selected={
					location.pathname == Routes_path.ANAGRAFICHE ||
					location.pathname == Routes_path.CLIENTI ||
					location.pathname == Routes_path.INSTALLATORI
				} // Routes_path.CLIENTI
				tooggleDrawer={tooggleDrawer}
				Icon={FolderCopyIcon}
				SottoMenu={
					<List component="div" disablePadding>
						<ListItem
							sx={{
								pl: 4,
								color:
									location.pathname == Routes_path.CLIENTI
										? "primary.main"
										: "#222",
							}}
							component={Link}
							to={Routes_path.CLIENTI}
							key={Routes_path.CLIENTI}
							// sx={styleListItem}
						>
							<ListItemIcon
								sx={{
									color:
										location.pathname == Routes_path.CLIENTI
											? "primary.main"
											: "#222",
								}}
							>
								<ContactEmergencyIcon />
							</ListItemIcon>
							<ListItemText primary="Clienti / GC" />
						</ListItem>
						<ListItem
							sx={{
								pl: 4,
								color:
									location.pathname == Routes_path.INSTALLATORI
										? "primary.main"
										: "#222",
							}}
							component={Link}
							to={Routes_path.INSTALLATORI}
							key={Routes_path.INSTALLATORI}
							// sx={styleListItem}
						>
							<ListItemIcon
								sx={{
									color:
										location.pathname == Routes_path.INSTALLATORI
											? "primary.main"
											: "#222",
								}}
							>
								<EngineeringIcon />
							</ListItemIcon>
							<ListItemText primary="Installatori" />
						</ListItem>
						<ListItem sx={{ pl: 4 }}>
							<ListItemIcon>
								<AssuredWorkloadIcon />
							</ListItemIcon>
							<ListItemText primary="Enti" />
						</ListItem>
						<ListItem sx={{ pl: 4 }}>
							<ListItemIcon>
								<HandshakeIcon />
							</ListItemIcon>
							<ListItemText primary="Reg. Commer." />
						</ListItem>
						<ListItem sx={{ pl: 4 }}>
							<ListItemIcon>
								<ElectricBoltIcon />
							</ListItemIcon>
							<ListItemText primary="Tipi Tensione" />
						</ListItem>
						<ListItem sx={{ pl: 4 }}>
							<ListItemIcon>
								<InventoryIcon />
							</ListItemIcon>
							<ListItemText primary="Tipi Pratica" />
						</ListItem>
					</List>
				}
			/>

			<ElementoMenuControllato
				utente={utente}
				path={Routes_path.SOPRALLUOGHI}
				label="Sopralluoghi"
				gruppiAutorizzati={[
					// PermissionTypes.ALL,
					PermissionTypes.GESTORI_ITER,
					PermissionTypes.GENERAL_CONTRACTOR,
					PermissionTypes.BCK_OFFICE_GENERAL_CONTRACTOR,
					PermissionTypes.PROGETTISTI,
					PermissionTypes.OPERATORI,
				]}
				open={open}
				selected={location.pathname == Routes_path.SOPRALLUOGHI}
				tooggleDrawer={tooggleDrawer}
				Icon={EditRoadIcon}
			/>

			<ElementoMenuControllato
				utente={utente}
				path={Routes_path.VERIFICHE_VINCOLI}
				label="Verifiche Vincoli"
				gruppiAutorizzati={[
					// PermissionTypes.ALL,
					PermissionTypes.GESTORI_ITER,
					PermissionTypes.GENERAL_CONTRACTOR,
					PermissionTypes.BCK_OFFICE_GENERAL_CONTRACTOR,
					PermissionTypes.PROGETTISTI,
					PermissionTypes.OPERATORI,
				]}
				open={open}
				selected={location.pathname == Routes_path.VERIFICHE_VINCOLI}
				tooggleDrawer={tooggleDrawer}
				Icon={RuleIcon}
			/>

			<ElementoMenuControllato
				utente={utente}
				path={Routes_path.RICHIESTE_CONNESSIONE}
				label="Richieste Connessione"
				gruppiAutorizzati={[
					PermissionTypes.GESTORI_ITER,
					PermissionTypes.OPERATORI,
					// PermissionTypes.PIANIFICATORI_LETTURA,
				]}
				open={open}
				selected={location.pathname == Routes_path.RICHIESTE_CONNESSIONE}
				tooggleDrawer={tooggleDrawer}
				Icon={AddShoppingCartIcon}
			/>
			<ElementoMenuControllato
				utente={utente}
				path={Routes_path.PRATICHE_COMUNALI}
				label="Pratiche Comunali"
				gruppiAutorizzati={[
					// PermissionTypes.ALL,
					PermissionTypes.GESTORI_ITER,
					PermissionTypes.OPERATORI,
					// PermissionTypes.PIANIFICATORI_LETTURA,
				]}
				open={open}
				selected={location.pathname == Routes_path.PRATICHE_COMUNALI}
				tooggleDrawer={tooggleDrawer}
				Icon={AccountBalanceIcon}
			/>

			<ElementoMenuControllato
				utente={utente}
				path={Routes_path.SCADENZE}
				label="Scadenziario Tarature"
				gruppiAutorizzati={[
					PermissionTypes.GESTORI_ITER,
					// PermissionTypes.PIANIFICATORI_LETTURA,
				]}
				open={open}
				selected={location.pathname == Routes_path.SCADENZE}
				tooggleDrawer={tooggleDrawer}
				Icon={ManageHistoryIcon}
			/>

			<ElementoMenuControllato
				utente={utente}
				path={Routes_path.FATTURABILI}
				label="Pratiche Fatturabili"
				gruppiAutorizzati={[
					PermissionTypes.GESTORI_ITER,
					// PermissionTypes.PIANIFICATORI_LETTURA,
				]}
				open={open}
				selected={location.pathname == Routes_path.FATTURABILI}
				tooggleDrawer={tooggleDrawer}
				Icon={EuroIcon}
			/>

			<ElementoMenuControllato
				utente={utente}
				path={Routes_path.ADEMPIMENTI}
				label="Adempimenti"
				gruppiAutorizzati={[
					PermissionTypes.GESTORI_ITER,
					PermissionTypes.OPERATORI,
					PermissionTypes.CLIENTI,
				]}
				open={open}
				selected={location.pathname == Routes_path.ADEMPIMENTI}
				tooggleDrawer={tooggleDrawer}
				Icon={FlashAutoIcon}
			/>

			<Divider />
			<ElementoMenuControllato
				utente={utente}
				path={Routes_path.UTENTI}
				label="Utenti"
				gruppiAutorizzati={[
					PermissionTypes.GESTORI_ITER,
					PermissionTypes.GESTORI_COMUNITA_ENERGETICHE,
					// PermissionTypes.PIANIFICATORI_LETTURA,
				]}
				open={open}
				selected={location.pathname == Routes_path.UTENTI}
				tooggleDrawer={tooggleDrawer}
				Icon={ContactsIcon}
			/>

			<ElementoMenuControllato
				utente={utente}
				path={Routes_path.TIPI_DOCUMENTO}
				label="Tipi Documento"
				gruppiAutorizzati={[
					PermissionTypes.AMMINISTRATORI_DOCUMENTI,
					// PermissionTypes.PIANIFICATORI_LETTURA,
				]}
				open={open}
				selected={location.pathname == Routes_path.TIPI_DOCUMENTO}
				tooggleDrawer={tooggleDrawer}
				Icon={ChecklistRtlIcon}
			/>
			{(utente.is_staff) && (
				<ListItem
					component="a"
					target="_blank"
					rel="noopener"
					href={process.env.API_URL + "/admin"}
					key="Admin_be"
					sx={styleListItem}
				>
					<ListItemIcon style={{ minWidth: "auto" }}>
						<ManageAccountsIcon
							className={
								location.pathname == process.env.API_URL + "/admin"
									? classes.iconSelected
									: ""
							}
							onClick={mobiletooggleDrawer}
						/>
					</ListItemIcon>
					{open && (
						<ListItemText
							style={{ marginLeft: 12, fontStyle: "italic" }}
							primary={"Admin"}
							className={
								location.pathname == process.env.API_URL + "/admin"
									? classes.iconSelected
									: ""
							}
							onClick={mobiletooggleDrawer}
						/>
					)}
				</ListItem>
			)}
		</StyledList>
	);
};

export default SideMenu;
