import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, Link, useParams } from "react-router-dom";

import { RootState, useAppDispatch } from "../../../../../redux/store";
import { Routes_path } from "../../../../routerApp";

import { DateTime } from "luxon";


import IterConnessioneRichiestaConnessioneStepper from "./IterConnessioneRichiestaConnessioneStepper";
import IterConnessioneSpalla from "../IterConnessioneSpalla";
import IterConnessioneRichiestaConnessioneCard from "./IterConnessioneRichiestaConnessioneCard";
import AzioniConnessioneCard from "./AzioniConnessioneCard";
import AzioniClienteConnessioneCard from "./AzioniClienteConnessioneCard";
import AzioniGestoreCard from "../avvioIter/AzioniGestoreCard";
import IterConnessioneFineLavoriTab from "./fineLavori/IterConnessioneFineLavoriBox";
import IterConnessioneAllaccioBox from "./allaccio/IterConnessioneAllaccioBox";
import { PermissionTypes } from "../../../../authentication/permissionsGroups";
import {
	FineLavoro,
	getFineLavoroFromIter,
} from "./fineLavori/fineLavoriSlice";
import { Allaccio, getAllaccioFromIter } from "./allaccio/allaccioSlice";

import { utenteAutorizzato } from "../../../../authentication/UtenteAutorizzato";
import { UtenteLogged } from "../../../../authentication/authenticationSlice";
import {
	RichiestaConnessione,
	fetchRichiesteConnessioneFromIter,
} from "./richiesteConnessioneSlice";
import { IterConnessione } from "../../iterConnessioneSlice";

import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import Grid from "@mui/material/Grid";
import { Box } from "@mui/system";

const steps = ["Preventivo", "Fine Lavori", "Allaccio"];

interface IterConnessioneRichiesteConnessioneTabProps {
	iterConnessione: IterConnessione;
	utente: UtenteLogged;

	consentiModifica: boolean;
}

const IterConnessioneRichiesteConnessioneTab = ({
	iterConnessione,
	utente,
	consentiModifica = false,
}: IterConnessioneRichiesteConnessioneTabProps) => {
	
	const dispatch = useAppDispatch();
	useEffect(() => {
		iterConnessione.id &&
			dispatch(fetchRichiesteConnessioneFromIter(iterConnessione.id));
	}, []);
	const [activeStep, setActiveStep] = useState(0);
	const [stepView, setStepView] = useState(0);

	const handleStep = (step: number) => {
		setActiveStep(step);
		setStepView(step);
	};
	const [completed, setCompleted] = useState<{
		[k: number]: boolean;
	}>({});

	const richiesteConnessioni: RichiestaConnessione[] = useSelector<
		RootState,
		RichiestaConnessione[]
	>((state) =>
		state.richiesteConnessioniState.richiesteConnessioni.results
			.slice()
			.sort((a, b) => {
				return b.id - a.id;
			})
	);

	useEffect(() => {
		iterConnessione.id && dispatch(getFineLavoroFromIter(iterConnessione.id));
		iterConnessione.id && dispatch(getAllaccioFromIter(iterConnessione.id));
	}, []);

	const fineLavoro: FineLavoro | undefined = useSelector<
		RootState,
		FineLavoro | undefined
	>((state) =>
		state.fineLavoriState.fineLavori.results.find(
			(fl) => fl.iter_connessione == iterConnessione.id || 0
		)
	);
	const allaccio: Allaccio | undefined = useSelector<
		RootState,
		Allaccio | undefined
	>((state) =>
		state.allacciState.allacci.results.find(
			(allac) => allac.iter_connessione == iterConnessione.id || 0
		)
	);

	useEffect(() => {
		iterConnessione.stato < "I"
			? handleStep(0)
			: iterConnessione.stato < "L"
			? handleStep(1)
			: iterConnessione.stato < "M"
			? handleStep(2)
			: handleStep(3);
	}, [iterConnessione]);

	return (
		<Box>
			<Grid container spacing={2} sx={{ mb: 2 }}>
				<Grid item xs={12} sm={12} md={8} lg={9}>
					<IterConnessioneRichiestaConnessioneStepper
						steps={steps}
						activeStep={activeStep}
						setActiveStep={setStepView}
					/>
					<Box
						key="preventivo"
						sx={{ display: stepView == 0 ? "inherit" : "none" }}
					>
						{richiesteConnessioni.map((richiesta) => (
							<IterConnessioneRichiestaConnessioneCard
								key={richiesta.id}
								richiestaConnessione={richiesta}
								consentiModifica={
									!richiesta.richiesta_rigettata && consentiModifica
								}
								iterSemplificato={iterConnessione.iter_semplificato}
							/>
						))}
					</Box>
					<Box
						key="fine_lavoro"
						sx={{ display: stepView == 1 ? "inherit" : "none" }}
					>
						{fineLavoro && (
							<IterConnessioneFineLavoriTab
								iterConnessione={iterConnessione}
								fineLavoro={fineLavoro}
								utente={utente}
								consentiModifica={consentiModifica}
							/>
						)}
					</Box>
					<Box
						key="allaccio"
						sx={{ display: stepView == 2 ? "inherit" : "none" }}
					>
						{allaccio && (
							<IterConnessioneAllaccioBox
								iterConnessione={iterConnessione}
								allaccio={allaccio}
								utente={utente}
								consentiModifica={consentiModifica}
							/>
						)}
					</Box>
				</Grid>

				<Grid item xs={12} sm={12} md={4} lg={3}>
					<IterConnessioneSpalla iterConnessione={iterConnessione} />

					{utenteAutorizzato(utente, [
						PermissionTypes.GESTORI_ITER,
						PermissionTypes.OPERATORI,
					]) && (
						<AzioniConnessioneCard
							iterConnessione={iterConnessione}
							richiestaConnessione={richiesteConnessioni.find(
								(richiesta) => richiesta.richiesta_rigettata == false
							)}
							fineLavoro={fineLavoro}
							allaccio={allaccio}
						/>
					)}
					{utenteAutorizzato(utente, [PermissionTypes.CLIENTI]) && (
						<AzioniClienteConnessioneCard
							iterConnessione={iterConnessione}
							richiestaConnessione={richiesteConnessioni.find(
								(richiesta) => richiesta.richiesta_rigettata == false
							)}
							fineLavoro={fineLavoro}
							allaccio={allaccio}
						/>
					)}
					{utenteAutorizzato(utente, [PermissionTypes.GESTORI_ITER]) && (
						<AzioniGestoreCard iterConnessione={iterConnessione} />
					)}
				</Grid>
			</Grid>
		</Box>
	);
};

export default IterConnessioneRichiesteConnessioneTab;
