import {Commessa} from "../../commesseSlice";
import {Soggetto,} from "components/anagrafiche/produttori/produttoriSlice";
import {convertToDateTime} from "../../../../common/dateUtils/convertToDateTime";


export const tabellaListaCodiciMatricola = (
    commessa: Commessa,
): string[][] => {
    const csvData: string[][] = [];

    csvData.push([
        "Titolare impianto:",
        commessa.titolare_immobile_descrizione?.toString() || "",
    ]);
    csvData.push([
        "POD:",
        commessa.pod_descrizione?.toString() || "",
    ]);
    csvData.push([
        "",
    ]);
    csvData.push([
        "TIPOLOGIA PRODOTTO", "MARCA", "MODELLO", "POTENZA", "N° SERIALE", "DATA INSTALLAZIONE",
    ]);

    commessa.codici_matricola?.map((codiceMatricola) => {
        csvData.push([
            codiceMatricola.tipo_prodotto.toString() || "",
            codiceMatricola.marca.toString() || "",
            codiceMatricola.modello.toString() || "",
            codiceMatricola.potenza.toString() || "",
            codiceMatricola.matricola.toString() || "",
            convertToDateTime(codiceMatricola.data_evento)?.setLocale("it").toFormat("dd/MM/yyyy") || "",
        ])
    });

    return csvData;
};
