import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, Link, useParams } from "react-router-dom";

import { RootState, useAppDispatch } from "../../../../redux/store";
import { Routes_path } from "../../../routerApp";

import { DateTime } from "luxon";

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

import {
	ErrorStatusTypes,
	ErrorsStack,
	parseErrorMessage,
} from "../../../common/errorsDeclarations";

import { PermissionTypes } from "../../../authentication/permissionsGroups";
import { utenteAutorizzato } from "../../../authentication/UtenteAutorizzato";
import { UtenteLogged } from "../../../authentication/authenticationSlice";
import {
	Utenti,
	Utente,
	fetchUtenti,
	UtentiStrutturaState,
} from "../../../authentication/utenti/utentiSlice";

import {
	StatoIterTypes,
	StatoConnessioneTypes,
	StatiIter,
	ServizioTypes,
} from "../../../anagrafiche/kpi/kpiSlice";
import {
	ComunitaEnergetica,
	getComunitaEnergetica,
	AllegatoComunitaEnergetica,
} from "../comunitaEnergeticheSlice";
import AllegatiGeneraliCerPage from "./AllegatiGeneraliCerPage";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import {
	VerificaVincoli,
	VerificheVincoliState,
	fetchVerificheVincoli,
} from "components/main/verificheVincoli/verificheVincoliSlice";

import {
	IterConnessioniState,
	getIterConnessione,
} from "components/main/iterConnessioni/iterConnessioneSlice";

import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import ArrowStepper from "../../../common/ArrowStepper";
import {
	testoBottoneProseguimento,
	passiInizioPratica,
} from "../../../anagrafiche/produttori/testoBottoneProseguimento";
import { theme } from "../../../theme";
import SoggettoPage from "../../../anagrafiche/soggetti/SoggettoPage";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";
import { Button } from "@mui/material";
import Stack from "@mui/material/Stack";
import { getProduttore } from "../../../anagrafiche/produttori/produttoriSlice";
import { Chip, Grid, Paper } from "@mui/material";
import { TextField } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import { EsponiStatoSintetico } from "components/common/EsponiStatoSintetico";

import InboxIcon from "@mui/icons-material/Inbox";
import DraftsIcon from "@mui/icons-material/Drafts";
import UtentiCerList from "./UtentiCerList";

const ComunitaEnergeticaPage = () => {
	const dispatch = useAppDispatch();
	const { comunitaEnergetica_id } = useParams();

	const utente = useSelector<RootState, UtenteLogged>(
		(state) => state.authentication.utenteLogged
	);

	const [comunitaEnergeticaId, setComunitaEnergeticaId] = useState<number>(
		utente?.cer || parseInt(comunitaEnergetica_id || "")
	);

	const location = useLocation();
	const commesseErrorsStack = useSelector<RootState, ErrorsStack>(
		(state) => state.commesseState.commesse.errorsStack
	);

	const comunitaEnergetica = useSelector<
		RootState,
		ComunitaEnergetica | undefined
	>((state) =>
		state.comunitaEnergeticheState.comunitaEnergetiche.results.find(
			(c) => c.id == comunitaEnergeticaId
		)
	);

	const inizialeMaiuscola = (testo: string) => {
		if (!testo) return ""; // Controlla se la stringa esiste
		return testo.charAt(0).toUpperCase() + testo.slice(1).toLowerCase();
	};

	// const nuovaComunitaEnergeticaId = useSelector<RootState, number | undefined>(
	// 	(state) => state.commesseState.nuovaComunitaEnergeticaId
	// );

	useEffect(() => {
		if (comunitaEnergeticaId) {
			dispatch(getComunitaEnergetica(comunitaEnergeticaId));
			// dispatch(fetchUtenti({ search: search }));
		}
	}, [comunitaEnergeticaId]);

	const utentiCer: Utente[] = useSelector<RootState, Utente[]>((state) =>
		state.utentiState.utenti.results.filter(
			(utente) => utente.cer == comunitaEnergeticaId
		)
	);

	const consentiModifica = utenteAutorizzato(utente, [
		PermissionTypes.GESTORI_ITER,
		PermissionTypes.OPERATORI,
		PermissionTypes.PROGETTISTI,
	]);

	return (
		<Box
			sx={{
				backgroundColor: "#fff",
				elevation: 20,
				p: 2,
			}}
		>
			<Backdrop
				sx={{
					color: "#fff",
					zIndex: (theme) => theme.zIndex.drawer + 1,
				}}
				open={!comunitaEnergetica}
			>
				<CircularProgress color="inherit" />
			</Backdrop>

			<Paper elevation={2} sx={{ p: 2, backgroundColor: "#eee" }}>
				{comunitaEnergetica ? (
					<Card sx={{ mt: 1, pb: 1 }}>
						<CardHeader
							title={
								<Box>
									<Typography variant="h2" color="white">
										{`Comunità Energetica ${comunitaEnergetica.nome}`}
									</Typography>
									<Typography variant="h3" color="#eee" gutterBottom>
										{comunitaEnergetica.descrizione}
									</Typography>
								</Box>
							}
							sx={{
								background:
									"linear-gradient(45deg, #56ab2f  30%,  #a8e063 90%)",
								// background: "linear-gradient(45deg, #03b0e2 30%, #f8ec06 90%)",
							}}
						/>
						<CardContent>
							<Grid container spacing={3}>
								<Grid item xs={12} md={12} lg={6}>
									<Card
										sx={{
											p: 2,
											background:
												"linear-gradient(45deg, #8bddf433  30%,  #03b0e211 90%)",
										}}
									>
										<CardHeader
											title={
												<Typography
													variant="h4"
													color="text.secondary"
													gutterBottom
												>
													Documenti Generali della Comunità Energetica
												</Typography>
											}
										/>
										<CardContent sx={{ px: 8, mt: 2 }}>
											<AllegatiGeneraliCerPage
												comunitaEnergetica={comunitaEnergetica}
											/>
										</CardContent>
									</Card>
								</Grid>
								<Grid item xs={12} md={12} lg={6}>
									<Card sx={{ p: 2, backgroundColor: "gainsboro" }}>
										<CardHeader
											title={
												// <Typography
												// 	variant="h4"
												// 	color="text.secondary"
												// 	gutterBottom
												// >
												// 	{"Membri della Comunità Energetica"}
												// </Typography>
												<Stack
													direction="row"
													justifyContent="space-between"
													alignItems="center"
													spacing={2}
												>
													<Typography
														variant="h4"
														color="text.secondary"
														gutterBottom
													>
														Membri della Comunità Energetica
													</Typography>
													<Button
														component={Link}
														variant="contained"
														to={{
															pathname: Routes_path.UTENTE_CER,
														}}
														state={{
															utente_id: null,
															cerPreset: Number(comunitaEnergetica_id),
														}}
													>
														Crea Utente
													</Button>
												</Stack>
											}
										/>

										<CardContent sx={{ px: 8, mt: 1 }}>
											<UtentiCerList cer={Number(comunitaEnergetica_id)} />
										</CardContent>
										{/* </CardContent> */}
									</Card>
								</Grid>
							</Grid>
						</CardContent>
					</Card>
				) : (
					<Paper sx={{ p: 2, backgroundColor: "lightgoldenrodyellow", mb: 2 }}>
						<Typography variant="h5" color="text.secondary" gutterBottom>
							<ReportGmailerrorredIcon />
							Errore caricamento Comunità Energetica
						</Typography>
					</Paper>
				)}
			</Paper>
		</Box>
	);
};

export default ComunitaEnergeticaPage;
