import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import {
	IterConnessione,
	getIterConnessione,
	saveIterConnessione,
} from "../../iterConnessioneSlice";

import { useAppDispatch, RootState } from "../../../../../redux/store";

import { theme } from "../../../../theme";

import { useForm, SubmitHandler, Controller } from "react-hook-form";
import FormControlLabel from "@mui/material/FormControlLabel";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import TextField from "@mui/material/TextField";

import Divider from "@mui/material/Divider";
import {
	Soggetto,
	saveProduttore,
} from "components/anagrafiche/produttori/produttoriSlice";


interface IterConnessioneAvvioIterFormProps {
	iterConnessione: IterConnessione;
	produttore: Soggetto;
	consentiModifica: boolean;
}

const IterConnessioneAvvioIterForm = ({
	iterConnessione,
	produttore,
	consentiModifica = false,
}: IterConnessioneAvvioIterFormProps) => {
	const {
		handleSubmit,
		register,
		formState: { errors, isDirty, isValid },
		setValue,
		watch,
		getValues,
		control,
		setError,
		clearErrors,
		reset,
	} = useForm<Soggetto>();

	const listaCampi = Object.keys(produttore) as Array<keyof Soggetto>;

	const tipiServizio = useSelector(
		(state: RootState) => state.tipiServizioState.tipiServizio.results
	);

	const [ampliamento, setAmpliamento] = useState<boolean | undefined>(
		iterConnessione.ampliamento ||
			tipiServizio.find((ts) => ts.id == iterConnessione.tipo_servizio)
				?.is_ampliamento
	);
	const [altroPod, setAltroPod] = useState<boolean | undefined>(
		iterConnessione.altro_pod
	);

	const dispatch = useAppDispatch();

	useEffect(() => {
		reset();
		listaCampi.map((field) => {
			setValue(field, produttore[field]);
		});
	}, [produttore, produttore.id]);

	useEffect(() => {
		iterConnessione.id && dispatch(getIterConnessione({iterConnessioneId:iterConnessione.id}))
	}, [produttore.gse_id, produttore.gse_psw, produttore.gaudi_id, produttore.gaudi_psw]);

	useEffect(() => {
		clearErrors();
		listaCampi.forEach((field) => {
			produttore?.errorsStack?.fieldsErrors &&
				produttore.errorsStack.fieldsErrors[field] &&
				setError(field, {
					type: "server",
					message: produttore.errorsStack.fieldsErrors[field].toString(),
				});
		});

		iterConnessione.ampliamento != undefined &&
			setAmpliamento(iterConnessione.ampliamento);

		iterConnessione.altro_pod != undefined &&
			setAltroPod(iterConnessione.altro_pod);
	}, [
		produttore?.errorsStack?.fieldsErrors,
		// iterConnessione.allevamento_pack?.errorsStack?.fieldsErrors,
	]);

	const handlerSaveProduttore = (produttore: Soggetto) => {
		consentiModifica;
		dispatch(
			saveProduttore({
				produttoreToSave: produttore,
			})
		);
	};

	const onSubmit: SubmitHandler<Soggetto> = (produttore) => {
		handlerSaveProduttore(produttore);
	};

	const handlerDetailChaingSubmit = () => {
		if (isValid && isDirty) {
			const formValues = getValues();
			handlerSaveProduttore(formValues);
		}
	};

	return (
		<Box sx={{ mb: 1 }}>
			<form onSubmit={handleSubmit(onSubmit)}>
				{(ampliamento ||
					altroPod ||
					produttore?.gse_id ||
					produttore?.gaudi_id ||
					iterConnessione.ampliamento ||
					tipiServizio.find((ts) => ts.id == iterConnessione.tipo_servizio)
						?.richiede_credenziali) && (
					<Grid container spacing={2} sx={{ mb: 2 }}>
						<Grid item xs={12} sm={12} md={6} lg={6}>
							<Divider
								textAlign="left"
								sx={{ mb: 2, color: theme.palette.primary.main }}
							>
								Credenziali portale GSE{" "}
							</Divider>
							<Grid container spacing={2} sx={{ mb: 2 }}>
								<Grid item xs={12} sm={6} md={6} lg={4}>
									<TextField
										label="gse id"
										disabled={!consentiModifica}
										variant="outlined"
										InputLabelProps={{
											shrink: true,
										}}
										{...register("gse_id")}
										required
										error={!!errors?.gse_id}
										helperText={errors?.gse_id?.message}
										autoComplete={"off"}
										sx={{ width: "100%" }}
										fullWidth
										onBlur={handlerDetailChaingSubmit}
									/>
								</Grid>
								<Grid item xs={12} sm={6} md={6} lg={4}>
									<TextField
										label="gse psw"
										disabled={!consentiModifica}
										variant="outlined"
										InputLabelProps={{
											shrink: true,
										}}
										{...register("gse_psw")}
										required
										error={!!errors?.gse_psw}
										helperText={errors?.gse_psw?.message}
										autoComplete={"off"}
										sx={{ width: "100%" }}
										fullWidth
										onBlur={handlerDetailChaingSubmit}
									/>
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={12} sm={12} md={6} lg={6}>
							<Divider
								textAlign="left"
								sx={{ mb: 2, color: theme.palette.primary.main }}
							>
								Credenziali portale Gaudì{" "}
							</Divider>
							<Grid container spacing={2} sx={{ mb: 2 }}>
								<Grid item xs={12} sm={6} md={6} lg={4}>
									<TextField
										label="gaudi id"
										disabled={!consentiModifica}
										variant="outlined"
										InputLabelProps={{
											shrink: true,
										}}
										{...register("gaudi_id")}
										error={!!errors?.gaudi_id}
										helperText={errors?.gaudi_id?.message}
										autoComplete={"off"}
										sx={{ width: "100%" }}
										fullWidth
										onBlur={handlerDetailChaingSubmit}
									/>
								</Grid>
								<Grid item xs={12} sm={6} md={6} lg={4}>
									<TextField
										label="gaudi psw"
										disabled={!consentiModifica}
										variant="outlined"
										InputLabelProps={{
											shrink: true,
										}}
										{...register("gaudi_psw")}
										error={!!errors?.gaudi_psw}
										helperText={errors?.gaudi_psw?.message}
										autoComplete={"off"}
										sx={{ width: "100%" }}
										fullWidth
										onBlur={handlerDetailChaingSubmit}
									/>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				)}
			</form>
		</Box>
	);
};

export default IterConnessioneAvvioIterForm;
