import React, { useEffect, useState, useRef } from "react";
import { LettoreCB, saveLettoreCB } from "./lettoreCBSlice";
import { useAppDispatch } from "../../../../redux/store";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import TextField from "@mui/material/TextField";
import { Button } from "@mui/material";

interface LettoreCBFormProps {
	lettoreCB: LettoreCB;
}

const LettoreCBForm = ({ lettoreCB }: LettoreCBFormProps) => {
	const {
		handleSubmit,
		register,
		formState: { errors, isDirty, isValid },
		setValue,
		watch,
		getValues,
		control,
		setError,
		clearErrors,
		reset,
	} = useForm<LettoreCB>();

	const codiceRef = useRef<HTMLInputElement>(null);

	useEffect(() => {
		const secondi = 0.5;
		const timeRefresh = secondi * 1000;
		const interval = setInterval(() => {
			if (codiceRef.current) {
				codiceRef.current.focus();
			}
		}, timeRefresh);
		if (codiceRef.current) {
			codiceRef.current.focus();
		}
		return () => clearInterval(interval);
	}, []);

	const listaCampi = Object.keys(lettoreCB) as Array<keyof LettoreCB>;

	const dispatch = useAppDispatch();

	const handlerSaveLettoreCB = (lettoreCB: LettoreCB) => {
		// setCodeBar(commessa);
		dispatch(saveLettoreCB(lettoreCB));
		reset();
	};

	useEffect(() => {
		listaCampi.map((field) => {
			setValue(field, lettoreCB[field]);
		});
	}, [lettoreCB]);

	const onSubmit: SubmitHandler<LettoreCB> = (lettoreCB) => {
		handlerSaveLettoreCB(lettoreCB);
		setValue("codeBar", "");
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<TextField
				label="codice"
				// disabled={schedaCarico.numero_capi_pianificati}
				variant="outlined"
				InputLabelProps={{
					shrink: true,
				}}
				{...register("codeBar")}
				error={!!errors?.codeBar}
				helperText={errors?.codeBar?.message}
				autoComplete={"off"}
				// sx={{ width: "100%" }}
				fullWidth
				// autoFocus
				size="small"
				inputRef={codiceRef}
			/>
			<Button
				disabled={!isDirty}
				variant="outlined"
				type="submit"
				sx={{ display: "none" }}
			>
				Salva
			</Button>
		</form>
	);
};

export default LettoreCBForm;
