import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {useAppDispatch, RootState} from "../../../../../../redux/store";
import {
    ErrorStatusTypes,
    ErrorsStack,
} from "../../../../../common/errorsDeclarations";

import {
    UtenteLogged,
    UtenteApplicativo,
} from "../../../../../authentication/authenticationSlice";
import {
    Commessa,
    saveCommessa,
    StatoCommessaTypes,
    TipoIntervento,
} from "../../../commesseSlice";

import ReactHookFormSelect from "../../../../../common/ReactHookFormSelect";
import {
    Checkbox,
    CircularProgress,
    FormControlLabel,
    FormHelperText,
} from "@mui/material";
import {useForm, SubmitHandler, Controller} from "react-hook-form";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import {Card, CardHeader, CardContent, Paper} from "@mui/material";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import {Button, Divider} from "@mui/material";
import {DesktopDatePicker} from "@mui/x-date-pickers/DesktopDatePicker";
import {Alert} from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Switch from "@mui/material/Switch";
import {convertToDateTime} from "../../../../../common/dateUtils/convertToDateTime";

interface FineLavoroFormProps {
    commessa: Commessa;
    consentiModifica: boolean;
}

const CompletamentoCommessaForm = ({
                                      commessa,
                                      consentiModifica = false,
                                  }: FineLavoroFormProps) => {
    const {
        handleSubmit,
        register,
        formState: {errors, isDirty, isValid},
        setValue,
        watch,
        getValues,
        control,
        setError,
        clearErrors,
        reset,
    } = useForm<Commessa>();

    const listaCampi = Object.keys(commessa) as Array<keyof Commessa>;

    const dispatch = useAppDispatch();


    const utente = useSelector<RootState, UtenteLogged>(
        (state) => state.authentication.utenteLogged
    );


    useEffect(() => {
        reset();
        console.log('reset')
        listaCampi.map((field) => {
            setValue(field, commessa[field]);
        });
        // setKeycodice_pod(makeid(5));
    }, [commessa.data_conclusione, commessa.id]);


    useEffect(() => {
        clearErrors();
        listaCampi.forEach((field) => {
            commessa?.errorsStack?.fieldsErrors &&
            commessa.errorsStack.fieldsErrors[field] &&
            setError(field, {
                type: "server",
                message: commessa.errorsStack.fieldsErrors[field].toString(),
            });
        });
    }, [
        commessa?.errorsStack?.fieldsErrors,
        // cliente.allevamento_pack?.errorsStack?.fieldsErrors,
    ]);



    const handlerSaveCommessa = (commessa: Commessa) => {
        consentiModifica &&
        dispatch(
            saveCommessa({
                commessaToSave: commessa,
            })
        );
    };
    const [commessaTerminata, setcommessaTerminata] = useState<boolean>(false);

    useEffect(() => {
        setcommessaTerminata(["N"].includes(commessa?.stato||""))
    }, [commessa.stato
    ]);


    useEffect(() => {
        if (isValid && isDirty) {
            const formValues = getValues();
            handlerSaveCommessa(formValues);
        }
    }, [
        watch("data_conclusione"),
    ]);



    return (
        <Box sx={{mb: 1}}>
                {commessa?.errorsStack?.fieldsErrors?.non_field_errors && (
                    <Alert severity="error">
                        {commessa?.errorsStack?.fieldsErrors?.non_field_errors.toString()}
                    </Alert>
                )}
                    <Grid container spacing={2} sx={{my: 2, justifyContent: "center",}}>
                        <Grid item xs={12} sm={6} md={6} lg={4}>
                            <DesktopDatePicker
                                value={convertToDateTime(commessa.data_conclusione)}
                                label="data conclusione commessa"
                                disabled={!consentiModifica}
                                slotProps={{
                                    textField: {
                                        required: false,
                                        error: !!errors?.data_conclusione,
                                        helperText: errors?.data_conclusione?.message,
                                        sx: {width: "100%"},
                                        autoComplete: "off",
                                        // disabled: !!richiestaConnessione.stato
                                    },
                                    field: {
                                        clearable: true,
                                    },
                                }}
                                onChange={(newValue: any) => {
                                    setValue(
                                        "data_conclusione",
                                        // newValue ? newValue.toJSDate() : "",
                                        newValue
                                            ? convertToDateTime(newValue)?.toFormat("yyyy-MM-dd")
                                            : "",
                                        {
                                            shouldDirty: true,
                                        }
                                    );
                                    clearErrors("data_conclusione");
                                }}
                            />
                        </Grid>

                    <Grid item lg={4}>
                        <Typography variant="h6">La commessa è terminata?</Typography>
                        <FormControlLabel
                            label={
                                <Typography fontSize="0.8rem" marginLeft="0.1rem">
                                    {commessaTerminata ? "SI" : "NO"}
                                </Typography>
                            }
                            control={
                                <Switch
                                    disabled={!consentiModifica}
                                    id="edit_sede_codice_pod"
                                    checked={commessaTerminata || false}
                                    onChange={(e, checked) => {
                                        setcommessaTerminata(checked);
                                    }}
                                    color="info"
                                    size="small"
                                />
                            }
                        />
                        {!!errors?.stampa_ddt && (
                            <FormHelperText error>
                                {errors?.stampa_ddt?.toString()}
                            </FormHelperText>
                        )}
                    </Grid>


                    <Grid item sm={12} lg={2}>
                        {commessa.stato == "M" &&
                            <Button
                                variant="contained"
                                color="primary"
                                disabled={!consentiModifica || !commessaTerminata|| !commessa.stato_completato}
                                sx={{width: "100%"}}
                                onClick={() => {
                                    dispatch(saveCommessa({
                                        commessaToSave: commessa,
                                        azione: "attiva_chiusura_commessa"
                                    }))
                                }}
                            >
                                Chiudi Commessa
                            </Button>
                        }
                    </Grid>
                </Grid>
        </Box>
    );
};

export default CompletamentoCommessaForm;
