import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import {
	ErrorStatusTypes,
	ErrorsStack,
	parseErrorMessage,
} from "../../common/errorsDeclarations";
import * as sopralluoghiApi from "../../../api/sopralluoghi/sopralluoghiApi";

import { toast } from "react-toastify";
import { DocumentiPerTipo } from "../../allegati/allegatiSlice";
import { getIterConnessione } from "../iterConnessioni/iterConnessioneSlice";
import { Commessa, getCommessa } from "../commesse/commesseSlice";

// import { useDispatch } from "react-redux";



export enum StatoSopralluogoTypes {
	A = "da affidare",
	B = "programmato",
	C = "eseguito",
}

export enum AccessoTettoTypes {
	A = "Scala",
	B = "Lucernaio",
	C = "Ponteggio",
	D = "Terrazzo",
	E = "Altro",
}

export enum TipoTettoTypes {
	A = "Legno + ventilato",
	B = "Legno",
	C = "Cemento con pignatte o tegole",
	D = "Tavelloni senza getto",
	E = "Travi varesi + tavelloni senza getto",
	F = "Lamiera grecata",
	G = "Lamiera Graffata",
	H = "Altro",
}

export enum TipoCoperturaTypes {
	A = "Coppo",
	B = "Tegola",
	C = "Altro",
}

export enum StatoCoperturaTypes {
	A = "Buono stato, copertura solida",
	B = "Copertura “fragile” da prevedere tegole/coppi di ricambio",
}

export enum TipoStaffaTypes {
	A = "Staffa tegola",
	B = "Staffa coppo",
	C = "Vitone",
	D = "Graffa",
	E = "Profilo per grecata",
	F = "Staffa tegola piana",
	G = "Altro sistema di staffaggio",
}

export enum TipoConnessioneTypes {
	M = "monofase",
	T = "trifase",
}

export enum ConnessioneDatiTypes {
	S = "Presente",
	N = "Assente",
	P = "Da prevedere",
}
export enum TipoConnessioneDatiTypes {
	W = "WI-FI",
	L = "LAN",
	G = "GSM",
}

export interface Sopralluogo {
	id?: number;
	iter_connessione?: number | null;
	esecutore?: number;
	commessa?: number | null;
	stato?: string;
	operatore?: number;
	cliente?: number;
	produttore?: number;
	produttore_descrizione?: string;
	data_sopralluogo?: Date | string;

	indirizzo?: string;
	numero_civico?: string;
	cap?: string;
	comune?: number;
	cellulare?: number;

	tipo_moduli?: string;
	tipo_batteria?: string;
	presenza_ev_charger?: boolean;
	ev_charger_marca?: string;
	presenza_ottimizzatori?: boolean;
	ottimizzatori_modello?: string;
	ottimizzatori_numero?: number;

	tipologia_edilizia?: string;
	tipologia_edilizia_altro_desc?: string;
	altezza_gronda?: number;
	altezza_abitazione?: number;
	numero_piani?: number;
	accesso_tetto?: string;
	accesso_tetto_altro_desc?: string;
	tipo_tetto?: string;
	tipo_tetto_altro_desc?: string;
	tipo_copertura?: string;
	tipo_copertura_altro_desc?: string;
	stato_copertura?: string;
	tipo_staffa?: string;
	servizio_i43?: boolean;

	listaDocumentiPerTipo?: DocumentiPerTipo[];

	errorsStack?: ErrorsStack;
}
export interface SopralluoghiState {
	count: number;
	page: number;
	num_pages: number;
	next?: URL;
	previous?: URL;
	results: Sopralluogo[];
	errorsStack: ErrorsStack;
}

export interface SopralluogoElettrico {
	id?: number;
	sopralluogo?: number;
	sezione_linea?: number;
	lunghezza_linea?: number;
	tipo_connessione?: string;

	presenza_sezionatore?: boolean;
	misura_sezionatore?: number;
	presenza_magnetotermico?: boolean;
	misura_magnetotermico?: number;
	presenza_differenziale?: boolean;
	misura_differenziale?: number;
	tipo_differenziale?: string;
	presenza_magnetotermico_differenziale?: boolean;
	misura_magnetotermico_differenziale_amper?: number;
	misura_magnetotermico_differenziale_milliamper?: number;
	tipo_magnetotermico_differenziale?: string;
	distributore_generale_altro_desc?: string;

	messa_terra?: boolean;
	sezione_messa_terra?: number;

	connessione_dati?: string;
	tipo_connessione_dati?: string;

	posizione_inverter?: string;
	distanza_quadro?: number;
	distanza_inverter_pannelli?: number;

	listaDocumentiPerTipo?: DocumentiPerTipo[];

	errorsStack?: ErrorsStack;
}
export interface SopralluoghiElettriciState {
	count: number;
	page: number;
	num_pages: number;
	next?: URL;
	previous?: URL;
	results: SopralluogoElettrico[];
	errorsStack: ErrorsStack;
}
export interface SopralluoghiStrutturaState {
	sopralluoghi: SopralluoghiState;
	sopralluoghiElettrici: SopralluoghiElettriciState;
	nuovoSopralluogoId?: number;
}

const initialState: SopralluoghiStrutturaState = {
	sopralluoghi: {
		count: 0,
		page: 0,
		num_pages: 0,
		next: undefined,
		previous: undefined,
		results: [],
		errorsStack: { status: ErrorStatusTypes.OK },
	},
	sopralluoghiElettrici: {
		count: 0,
		page: 0,
		num_pages: 0,
		next: undefined,
		previous: undefined,
		results: [],
		errorsStack: { status: ErrorStatusTypes.OK },
	},
};

export const fetchSopralluoghi = createAsyncThunk(
	"sopralluoghi/fetchSopralluoghi",
	async (parametri: {
		statoSopralluogo?: string;
		cliente?: number;
		commessa?: number;
		servizio_i43?: string;
		numeroRecord?: number;
		page?: number;
		search?: string;
	}) => {
		return await sopralluoghiApi.fetchSopralluoghi(
			parametri.statoSopralluogo,
			parametri.cliente,
			parametri.commessa,
			parametri.servizio_i43,
			parametri.numeroRecord,
			parametri.page,
			parametri.search
		);
	}
);

export const getSopralluogo = createAsyncThunk(
	"sopralluoghi/getSopralluogo",
	async (sopralluogoId: number) => {
		return await sopralluoghiApi.getSopralluogo(sopralluogoId);
	}
);

// export const fetchSopralluoghiFromIter = createAsyncThunk(
// 	"sopralluoghi/fetchSopralluoghiFromIter",
// 	async (iterId: number, thunkApi) => {
// 		return await sopralluoghiApi
// 			.fetchSopralluoghiFromIter(iterId)
// 			.then((response) => {
// 				iterId && thunkApi.dispatch(getIterConnessione(iterId));
// 				return response;
// 			});
// 	}
// );

export const saveSopralluogo = createAsyncThunk(
	"sopralluoghi/saveSopralluogo",
	async (
		parametri: {
			sopralluogoToSave: Sopralluogo;
			azione?: string;
		},
		thunkApi
	) => {
		return await sopralluoghiApi
			.saveSopralluogo(parametri.sopralluogoToSave, parametri.azione)
			.then((response) => {
				parametri.azione == "rigetta" &&
					thunkApi.dispatch(
						getSopralluogo(parametri.sopralluogoToSave.id || 0)
					);
				thunkApi.dispatch(
					getIterConnessione({
						iterConnessioneId:
							parametri.sopralluogoToSave.iter_connessione || 0,
					})
				);
				return response;
			});
	}
);

export const deleteSopralluogo = createAsyncThunk(
	"sopralluoghi/deleteSopralluogo",
	async (sopralluogoToDelete: Sopralluogo, thunkApi) => {
		return await sopralluoghiApi.deleteSopralluogo(sopralluogoToDelete);
	}
);

////////////////////////////////////////////////////////////////////////////////////
export const fetchSopralluoghiElettrici = createAsyncThunk(
	"sopralluoghi/fetchSopralluoghiElettrici",
	async (parametri: {
		statoSopralluogo?: string;
		cliente?: number;
		numeroRecord?: number;
		page?: number;
		search?: string;
	}) => {
		return await sopralluoghiApi.fetchSopralluoghiElettrici();
	}
);

export const getSopralluogoElettrico = createAsyncThunk(
	"sopralluoghi/getSopralluogoElettrico",
	async (sopralluogoElettricoId: number) => {
		return await sopralluoghiApi.getSopralluogoElettrico(
			sopralluogoElettricoId
		);
	}
);

export const saveSopralluogoElettrico = createAsyncThunk(
	"sopralluoghi/saveSopralluogoElettrico",
	async (
		parametri: {
			sopralluogoElettricoToSave: SopralluogoElettrico;
			azione?: string;
		},
		thunkApi
	) => {
		return await sopralluoghiApi
			.saveSopralluogoElettrico(
				parametri.sopralluogoElettricoToSave,
				parametri.azione
			)
			.then((response) => {
				parametri.azione == "rigetta" &&
					thunkApi.dispatch(
						getSopralluogoElettrico(
							parametri.sopralluogoElettricoToSave.id || 0
						)
					);
				thunkApi.dispatch(
					getSopralluogo(parametri.sopralluogoElettricoToSave.sopralluogo || 0)
				);
				return response;
			});
	}
);

export const deleteSopralluogoElettrico = createAsyncThunk(
	"sopralluoghi/deleteSopralluogoElettrico",
	async (sopralluogoElettricoToDelete: SopralluogoElettrico, thunkApi) => {
		return await sopralluoghiApi.deleteSopralluogoElettrico(
			sopralluogoElettricoToDelete
		);
	}
);

export const getSopralluogoElettricoFromSopralluogo = createAsyncThunk(
	"sopralluoghi/getSopralluogoElettricoFromSopralluogo",
	async (sopralluogoId: number, thunkApi) => {
		return await sopralluoghiApi
			.getSopralluogoElettricoFromSopralluogo(sopralluogoId)
			.then((response) => {
				sopralluogoId && thunkApi.dispatch(getSopralluogo(sopralluogoId));
				return response;
			});
	}
);

export const creaSopralluogoCommessa = createAsyncThunk(
	"commesse/creaSopralluogoCommessa",
	async (
		parametri: { commessa: Commessa; servizio_i43?: string },
		thunkApi
	) => {
		return await sopralluoghiApi
			.creaSopralluogoCommessa(parametri.commessa, parametri.servizio_i43)
			.then((response) => {
				parametri.commessa.id &&
					thunkApi.dispatch(getCommessa({commessaId:parametri.commessa.id}));
				return response;
			});
	}
);

export const sopralluoghiSlice = createSlice({
	name: "sopralluoghiState",
	initialState,
	reducers: {
		resetSopralluogoId: (state) => {
			state.nuovoSopralluogoId = undefined;
		},
	},
	extraReducers: (builder) => {
		// fetch Richieste Connessioni
		builder.addCase(fetchSopralluoghi.pending, (state, action) => {
			state.sopralluoghi.errorsStack = {
				status: ErrorStatusTypes.PENDING,
			};
		});
		builder.addCase(fetchSopralluoghi.fulfilled, (state, action) => {
			state.sopralluoghi = action.payload;
			state.sopralluoghi.errorsStack = { status: ErrorStatusTypes.OK };
		});
		builder.addCase(fetchSopralluoghi.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");
			state.sopralluoghi.errorsStack = {
				status: ErrorStatusTypes.ERROR,
				fieldsErrors: JSON.parse(action?.error?.message || ""),
			};
		});

		// get Sopralluogo
		builder.addCase(getSopralluogo.pending, (state, action) => {
			state.sopralluoghi.errorsStack = {
				status: ErrorStatusTypes.PENDING,
			};
		});
		builder.addCase(getSopralluogo.fulfilled, (state, action) => {
			state.sopralluoghi.results = state.sopralluoghi.results.filter(
				(richiesta) => richiesta.id != action.payload.id
			);
			state.sopralluoghi.results.push(action.payload);

			state.sopralluoghi.errorsStack = { status: ErrorStatusTypes.OK };
		});
		builder.addCase(getSopralluogo.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");
			state.sopralluoghi.errorsStack = {
				status: ErrorStatusTypes.ERROR,
				fieldsErrors: JSON.parse(action?.error?.message || ""),
			};
		});

		// get Sopralluogo from Iter
		// builder.addCase(fetchSopralluoghiFromIter.pending, (state, action) => {
		// 	state.sopralluoghi.errorsStack = {
		// 		status: ErrorStatusTypes.PENDING,
		// 	};
		// });
		// builder.addCase(fetchSopralluoghiFromIter.fulfilled, (state, action) => {
		// 	state.sopralluoghi = action.payload;
		// 	state.sopralluoghi.errorsStack = {
		// 		status: ErrorStatusTypes.OK,
		// 	};
		// });
		// builder.addCase(fetchSopralluoghiFromIter.rejected, (state, action) => {
		// 	toast.error("Errore:" + action?.error?.message || "");
		// 	state.sopralluoghi.errorsStack = {
		// 		status: ErrorStatusTypes.ERROR,
		// 		fieldsErrors: JSON.parse(action?.error?.message || ""),
		// 	};
		// });

		// save Sopralluogo
		builder.addCase(saveSopralluogo.pending, (state, action) => {
			state.sopralluoghi.errorsStack = {
				status: ErrorStatusTypes.PENDING,
			};
		});
		builder.addCase(saveSopralluogo.fulfilled, (state, action) => {
			state.sopralluoghi.results = state.sopralluoghi.results.filter(
				(richiesta) => richiesta.id != action.payload.id
			);
			state.sopralluoghi.results.push({
				...action.payload,
				errorsStack: { status: ErrorStatusTypes.SUCCESS },
			});
			state.sopralluoghi.errorsStack = {
				status: ErrorStatusTypes.SUCCESS,
			};

			// Solo se è nuova creazione ********************
			if (!action.meta.arg.sopralluogoToSave.id) {
				state.nuovoSopralluogoId = action.payload.id;
			}
			toast.success("Sopralluogo salvata.");
		});
		builder.addCase(saveSopralluogo.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");

			state.sopralluoghi.results = state.sopralluoghi.results.map(
				(richiesta) => {
					if (richiesta.id == action.meta.arg.sopralluogoToSave.id) {
						return {
							...richiesta,
							errorsStack: {
								status: ErrorStatusTypes.ERROR,
								fieldsErrors: JSON.parse(action?.error?.message || ""),
							},
						};
					} else {
						return richiesta;
					}
				}
			);
			state.sopralluoghi.errorsStack = {
				status: ErrorStatusTypes.ERROR,
			};
		});
		// cancella Sopralluogo
		builder.addCase(deleteSopralluogo.pending, (state, action) => {
			state.sopralluoghi.errorsStack = {
				status: ErrorStatusTypes.PENDING,
			};
		});
		builder.addCase(deleteSopralluogo.fulfilled, (state, action) => {
			state.sopralluoghi.errorsStack = {
				status: ErrorStatusTypes.SUCCESS,
			};
			state.sopralluoghi.results = state.sopralluoghi.results.filter(
				(richiesta) => richiesta.id != action.meta.arg.id
			);
			toast.success(action.payload.message || "Sopralluogo cancellata.");
		});
		builder.addCase(deleteSopralluogo.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");
			state.sopralluoghi.errorsStack = {
				status: ErrorStatusTypes.ERROR,
				fieldsErrors: JSON.parse(action?.error?.message || ""),
			};
		});
		////////////////////////////////////////////////////////////////////

		// fetch Richieste Connessioni
		builder.addCase(fetchSopralluoghiElettrici.pending, (state, action) => {
			state.sopralluoghi.errorsStack = {
				status: ErrorStatusTypes.PENDING,
			};
		});
		builder.addCase(fetchSopralluoghiElettrici.fulfilled, (state, action) => {
			state.sopralluoghiElettrici = action.payload;
			state.sopralluoghiElettrici.errorsStack = { status: ErrorStatusTypes.OK };
		});
		builder.addCase(fetchSopralluoghiElettrici.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");
			state.sopralluoghiElettrici.errorsStack = {
				status: ErrorStatusTypes.ERROR,
				fieldsErrors: JSON.parse(action?.error?.message || ""),
			};
		});

		// get Sopralluogo Elettrico
		builder.addCase(getSopralluogoElettrico.pending, (state, action) => {
			state.sopralluoghiElettrici.errorsStack = {
				status: ErrorStatusTypes.PENDING,
			};
		});
		builder.addCase(getSopralluogoElettrico.fulfilled, (state, action) => {
			state.sopralluoghiElettrici.results =
				state.sopralluoghiElettrici.results.filter(
					(soprEl) => soprEl.id != action.payload.id
				);
			state.sopralluoghiElettrici.results.push(action.payload);

			state.sopralluoghiElettrici.errorsStack = {
				status: ErrorStatusTypes.OK,
			};
		});
		builder.addCase(getSopralluogoElettrico.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");
			state.sopralluoghiElettrici.errorsStack = {
				status: ErrorStatusTypes.ERROR,
				fieldsErrors: JSON.parse(action?.error?.message || ""),
			};
		});

		// get Sopralluogo Elettrico from Sopralluogo
		builder.addCase(
			getSopralluogoElettricoFromSopralluogo.pending,
			(state, action) => {
				state.sopralluoghiElettrici.errorsStack = {
					status: ErrorStatusTypes.PENDING,
				};
			}
		);
		builder.addCase(
			getSopralluogoElettricoFromSopralluogo.fulfilled,
			(state, action) => {
				state.sopralluoghiElettrici.results =
					state.sopralluoghiElettrici.results.filter(
						(soprEl) => soprEl.id != action.payload.id
					);
				state.sopralluoghiElettrici.results.push(action.payload);

				state.sopralluoghiElettrici.errorsStack = {
					status: ErrorStatusTypes.OK,
				};
			}
		);
		builder.addCase(
			getSopralluogoElettricoFromSopralluogo.rejected,
			(state, action) => {
				toast.error("Errore:" + action?.error?.message || "");
				state.sopralluoghiElettrici.errorsStack = {
					status: ErrorStatusTypes.ERROR,
					fieldsErrors: JSON.parse(action?.error?.message || ""),
				};
			}
		);

		// save Sopralluogo
		builder.addCase(saveSopralluogoElettrico.pending, (state, action) => {
			state.sopralluoghiElettrici.errorsStack = {
				status: ErrorStatusTypes.PENDING,
			};
		});
		builder.addCase(saveSopralluogoElettrico.fulfilled, (state, action) => {
			state.sopralluoghiElettrici.results =
				state.sopralluoghiElettrici.results.filter(
					(sopEl) => sopEl.id != action.payload.id
				);
			state.sopralluoghiElettrici.results.push({
				...action.payload,
				errorsStack: { status: ErrorStatusTypes.SUCCESS },
			});
			state.sopralluoghiElettrici.errorsStack = {
				status: ErrorStatusTypes.SUCCESS,
			};

			// Solo se è nuova creazion
			toast.success("Sopralluogo salvata.");
		});

		builder.addCase(saveSopralluogoElettrico.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");

			state.sopralluoghiElettrici.results =
				state.sopralluoghiElettrici.results.map((sopEl) => {
					if (sopEl.id == action.meta.arg.sopralluogoElettricoToSave.id) {
						return {
							...sopEl,
							errorsStack: {
								status: ErrorStatusTypes.ERROR,
								fieldsErrors: JSON.parse(action?.error?.message || ""),
							},
						};
					} else {
						return sopEl;
					}
				});
			state.sopralluoghiElettrici.errorsStack = {
				status: ErrorStatusTypes.ERROR,
			};
		});
		// cancella Sopralluogo
		builder.addCase(deleteSopralluogoElettrico.pending, (state, action) => {
			state.sopralluoghiElettrici.errorsStack = {
				status: ErrorStatusTypes.PENDING,
			};
		});
		builder.addCase(deleteSopralluogoElettrico.fulfilled, (state, action) => {
			state.sopralluoghiElettrici.errorsStack = {
				status: ErrorStatusTypes.SUCCESS,
			};
			state.sopralluoghiElettrici.results =
				state.sopralluoghiElettrici.results.filter(
					(sopEl) => sopEl.id != action.meta.arg.id
				);
			toast.success(action.payload.message || "Sopralluogo cancellata.");
		});
		builder.addCase(deleteSopralluogoElettrico.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");
			state.sopralluoghiElettrici.errorsStack = {
				status: ErrorStatusTypes.ERROR,
				fieldsErrors: JSON.parse(action?.error?.message || ""),
			};
		});
		////////////////////////////////////////////////////////////////////
		// crea Sopralluogo da Commessa **************************
		builder.addCase(creaSopralluogoCommessa.pending, (state, action) => {
			state.sopralluoghi.errorsStack = { status: ErrorStatusTypes.PENDING };
		});
		builder.addCase(creaSopralluogoCommessa.fulfilled, (state, action) => {
			state.sopralluoghi.results = state.sopralluoghi.results.filter(
				(sopralluogo) => sopralluogo.id != action.payload.id
			);
			state.sopralluoghi.results.push({
				...action.payload,
				errorsStack: { status: ErrorStatusTypes.SUCCESS },
			});
			state.sopralluoghi.errorsStack = { status: ErrorStatusTypes.SUCCESS };
			toast.success("Sopralluogo salvato.");
		});
		builder.addCase(creaSopralluogoCommessa.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");

			state.sopralluoghi.errorsStack = {
				status: ErrorStatusTypes.ERROR,
			};
		});
	},
});

// Action creators are generated for each case reducer function
export const { resetSopralluogoId } = sopralluoghiSlice.actions;

export const sopralluoghiSliceReducer = sopralluoghiSlice.reducer;
