import React, { useEffect, useState } from "react";

import {
	IterConnessione,
	saveIterConnessione,
} from "../../iterConnessioneSlice";
import { useAppDispatch, RootState } from "../../../../../redux/store";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";

function makeid(length: number) {
	let result = "";
	const characters =
		"ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
	const charactersLength = characters.length;
	let counter = 0;
	while (counter < length) {
		result += characters.charAt(Math.floor(Math.random() * charactersLength));
		counter += 1;
	}
	return result;
}
interface IterConnessioneIbanFormProps {
	iterConnessione: IterConnessione;
	consentiModifica: boolean;
}

const IterConnessioneIbanForm = ({
	iterConnessione,
	consentiModifica = false,
}: IterConnessioneIbanFormProps) => {
	const {
		handleSubmit,
		register,
		formState: { errors, isDirty, isValid },
		setValue,
		watch,
		getValues,
		control,
		setError,
		clearErrors,
		reset,
	} = useForm<IterConnessione>();

	const listaCampi = Object.keys(iterConnessione) as Array<
		keyof IterConnessione
	>;

	const dispatch = useAppDispatch();

	const [keycodice_pod, setKeycodice_pod] = useState<string>(makeid(5));

	useEffect(() => {
		reset();
		listaCampi.map((field) => {
			setValue(field, iterConnessione[field]);
		});
		setKeycodice_pod(makeid(5));
	}, [iterConnessione, iterConnessione.id]);

	useEffect(() => {
		clearErrors();
		listaCampi.forEach((field) => {
			iterConnessione?.errorsStack?.fieldsErrors &&
				iterConnessione.errorsStack.fieldsErrors[field] &&
				setError(field, {
					type: "server",
					message: iterConnessione.errorsStack.fieldsErrors[field].toString(),
				});
		});
	}, [
		iterConnessione?.errorsStack?.fieldsErrors,
		// iterConnessione.allevamento_pack?.errorsStack?.fieldsErrors,
	]);

	const handlerSaveIterConnessione = (iterConnessione: IterConnessione) => {
		consentiModifica;
		dispatch(
			saveIterConnessione({
				iterConnessioneToSave: iterConnessione,
			})
		);
	};

	const onSubmit: SubmitHandler<IterConnessione> = (iterConnessione) => {
		handlerSaveIterConnessione(iterConnessione);
	};

	const handlerDetailChaingSubmit = () => {
		if (isValid && isDirty) {
			const formValues = getValues();
			handlerSaveIterConnessione(formValues);
		}
	};
	return (
		<Box sx={{ mb: 1 }}>
			<form onSubmit={handleSubmit(onSubmit)}>
				{!!iterConnessione.iter_semplificato && (
					<TextField
						label="iban addebito oneri di connessione"
						// disabled={!iterConnessione.codice_pod}
						variant="outlined"
						InputLabelProps={{
							shrink: true,
						}}
						{...register("iban_iter_connessione")}
						error={!!errors?.iban_iter_connessione}
						helperText={errors?.iban_iter_connessione?.message}
						onBlur={handlerDetailChaingSubmit}
						autoComplete={"off"}
						sx={{ width: "100%" }}
						fullWidth
					/>
				)}
			</form>
		</Box>
	);
};

export default IterConnessioneIbanForm;
