import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { NumericFormat } from "react-number-format";

import {
	SezioneProgetto,
	TipoSchemaTypes,
	deleteSezione,
	Progetto,
	saveSezioneProgetto,
} from "../progettiSlice";
import { useAppDispatch, RootState } from "../../../../../../redux/store";
import {
	ErrorStatusTypes,
	ErrorsStack,
} from "../../../../../common/errorsDeclarations";

import { theme } from "../../../../../theme";

import { utenteAutorizzato } from "../../../../../authentication/UtenteAutorizzato";
import { UtenteLogged } from "../../../../../authentication/authenticationSlice";
import { Cliente } from "../../../../../anagrafiche/clienti/clientiSlice";
import { Installatore } from "../../../../../anagrafiche/installatori/installatoriSlice";
import { RegimeCommerciale } from "../../../../../anagrafiche/regimiCommerciali/regimiCommercialiSlice";
import { TipoPratica } from "../../../../../anagrafiche/tipiPratica/tipiPraticaSlice";
import { TipoTensione } from "../../../../../anagrafiche/tipiTensione/tipiTensioneSlice";
import { Comune } from "../../../../../anagrafiche/comuni/comuniSlice";

import { Gestore } from "../../../../../anagrafiche/gestori/gestoriSlice";
import { useForm, SubmitHandler, Controller } from "react-hook-form";

import ReactHookFormSelect from "../../../../../common/ReactHookFormSelect";
import MenuItem from "@mui/material/MenuItem";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { Routes_path } from "../../../../../routerApp";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Alert from "../../../../../common/Alert";
import Typography from "@mui/material/Typography";
import Autocomplete from "@mui/material/Autocomplete";
import InputAdornment from "@mui/material/InputAdornment";

import { Button, Divider } from "@mui/material";
import { Soggetto } from "components/anagrafiche/produttori/produttoriSlice";

interface SezioneFormProps {
	progetto: Progetto;
	tipoSchema: "HYB" | "PRD" | "POS";
	handleClosInputSezioneProgetto: () => void;
}

const SezioneForm = ({
	progetto,
	tipoSchema,
	handleClosInputSezioneProgetto,
}: SezioneFormProps) => {
	const {
		handleSubmit,
		register,
		formState: { errors, isDirty, isValid },
		setValue,
		watch,
		getValues,
		control,
		setError,
		clearErrors,
		reset,
	} = useForm<SezioneProgetto>();

	const nuovaSezione: SezioneProgetto = {
		id: undefined,
		tipo_schema: tipoSchema,
		// progetto: 1,
	};
	const listaCampi = Object.keys(nuovaSezione) as Array<keyof SezioneProgetto>;

	const dispatch = useAppDispatch();

	const utente = useSelector<RootState, UtenteLogged>(
		(state) => state.authentication.utenteLogged
	);

	useEffect(() => {
		reset();
		listaCampi.map((field) => {
			setValue(field, nuovaSezione[field]);
		});
	}, []);

	useEffect(() => {
		clearErrors();
		listaCampi.forEach((field) => {
			progetto?.errorsStack?.fieldsErrors &&
				progetto.errorsStack.fieldsErrors[field] &&
				setError(field, {
					type: "server",
					message: progetto.errorsStack.fieldsErrors[field].toString(),
				});
		});
	}, [
		progetto?.errorsStack?.fieldsErrors,
		// progetto.allevamento_pack?.errorsStack?.fieldsErrors,
	]);

	const handlerSaveSezioneProgetto = (sezione: SezioneProgetto) => {
		progetto.id &&
			dispatch(
				saveSezioneProgetto({
					sezioneProgettoToSave: sezione,
					progettoId: progetto.id,
				})
			);
		handleClosInputSezioneProgetto();
	};

	const handlerDetailChaingSubmit = () => {
		if (isValid && isDirty) {
			const formValues = getValues();
			handlerSaveSezioneProgetto(formValues);
		}
	};

	const onSubmit: SubmitHandler<SezioneProgetto> = (sezione) => {
		handlerSaveSezioneProgetto(sezione);
	};

	return (
		<Box sx={{ mb: 1 }}>
			<form onSubmit={handleSubmit(onSubmit)}>
				{progetto?.errorsStack?.fieldsErrors?.non_field_errors && (
					<Alert severity="error">
						{progetto?.errorsStack?.fieldsErrors?.non_field_errors.toString()}
					</Alert>
				)}
				<Typography variant="h5" gutterBottom>
					Aggiungi{" "}
					{
						Object.entries(TipoSchemaTypes).find(
							([key, value]) => key == tipoSchema
						)?.[1]
					}
				</Typography>
				<Grid container spacing={2} sx={{ mb: 3 }}>
					{tipoSchema != "POS" && (
						<Grid item xs={12} sm={6} md={6} lg={4}>
							<Typography variant="h6" gutterBottom>
								Moduli Fotovoltaici
							</Typography>
							<Typography variant="caption" color="text.secondary">
								Potenza Generatore*:
							</Typography>
							<Controller
								name="potenza_generatore"
								control={control}
								render={({ field }) => (
									<NumericFormat
										id="potenza_generatore"
										customInput={TextField}
										variant="outlined"
										// label="potenza unitaria"
										InputLabelProps={{
											shrink: true,
										}}
										error={!!errors?.potenza_generatore}
										helperText={errors?.potenza_generatore?.message}
										// {...register("potenza_unitaria")}
										value={field.value}
										autoComplete="off"
										prefix="kWp "
										thousandSeparator="."
										decimalSeparator=","
										valueIsNumericString={true}
										onValueChange={(v) => {
											setValue("potenza_generatore", Number(v.value), {
												shouldDirty: true,
											});
										}}
										sx={{ width: "100%" }}
									/>
								)}
							/>
						</Grid>
					)}
					{tipoSchema != "POS" && (
						<Grid item xs={12} sm={6} md={6} lg={4}>
							<Typography variant="h6" gutterBottom>
								Inverter
							</Typography>
							<Typography variant="caption" color="text.secondary">
								Potenza Inverter*:
							</Typography>
							<Controller
								name="potenza_inverter"
								control={control}
								render={({ field }) => (
									<NumericFormat
										id="potenza_inverter"
										customInput={TextField}
										variant="outlined"
										// label="potenza unitaria"
										InputLabelProps={{
											shrink: true,
										}}
										error={!!errors?.potenza_inverter}
										helperText={errors?.potenza_inverter?.message}
										// {...register("potenza_unitaria")}
										value={field.value}
										autoComplete="off"
										prefix="kW "
										thousandSeparator="."
										decimalSeparator=","
										valueIsNumericString={true}
										onValueChange={(v) => {
											setValue("potenza_inverter", Number(v.value), {
												shouldDirty: true,
											});
										}}
										sx={{ width: "100%" }}
									/>
								)}
							/>

							<Typography variant="caption" color="text.secondary">
								Contributo ICC*:
							</Typography>
							<Controller
								name="contrib_ICC"
								control={control}
								render={({ field }) => (
									<NumericFormat
										id="contrib_ICC"
										customInput={TextField}
										variant="outlined"
										// label="potenza unitaria"
										InputLabelProps={{
											shrink: true,
										}}
										error={!!errors?.contrib_ICC}
										helperText={errors?.contrib_ICC?.message}
										// {...register("potenza_unitaria")}
										value={field.value}
										autoComplete="off"
										prefix="A "
										thousandSeparator="."
										decimalSeparator=","
										valueIsNumericString={true}
										onValueChange={(v) => {
											setValue("contrib_ICC", Number(v.value), {
												shouldDirty: true,
											});
										}}
										sx={{ width: "100%" }}
									/>
								)}
							/>
							<Typography variant="caption" color="text.secondary">
								Potenza CC:
							</Typography>
							<Controller
								name="potenza_cc_inverter"
								control={control}
								render={({ field }) => (
									<NumericFormat
										id="potenza_cc_inverter"
										customInput={TextField}
										variant="outlined"
										// label="potenza unitaria"
										InputLabelProps={{
											shrink: true,
										}}
										error={!!errors?.potenza_cc_inverter}
										helperText={errors?.potenza_cc_inverter?.message}
										// {...register("potenza_unitaria")}
										value={field.value}
										autoComplete="off"
										prefix="kW "
										thousandSeparator="."
										decimalSeparator=","
										valueIsNumericString={true}
										onValueChange={(v) => {
											setValue("potenza_cc_inverter", Number(v.value), {
												shouldDirty: true,
											});
										}}
										sx={{ width: "100%" }}
									/>
								)}
							/>
						</Grid>
					)}
					<Grid item xs={12} sm={6} md={6} lg={4}>
						<Typography variant="h6" gutterBottom>
							Accumulo
						</Typography>
						<Typography variant="caption" color="text.secondary">
							Potenza Nominale SDA*:
						</Typography>
						<Controller
							name="potenza_nominale_sda"
							control={control}
							render={({ field }) => (
								<NumericFormat
									id="potenza_nominale_sda"
									customInput={TextField}
									variant="outlined"
									// label="potenza unitaria"
									InputLabelProps={{
										shrink: true,
									}}
									error={!!errors?.potenza_nominale_sda}
									helperText={errors?.potenza_nominale_sda?.message}
									// {...register("potenza_unitaria")}
									value={field.value}
									autoComplete="off"
									prefix="kW "
									thousandSeparator="."
									decimalSeparator=","
									valueIsNumericString={true}
									onValueChange={(v) => {
										setValue("potenza_nominale_sda", Number(v.value), {
											shouldDirty: true,
										});
									}}
									sx={{ width: "100%" }}
								/>
							)}
						/>
						<Typography variant="caption" color="text.secondary">
							Potenza CC SDA*:
						</Typography>
						<Controller
							name="potenza_cc_sda"
							control={control}
							render={({ field }) => (
								<NumericFormat
									id="potenza_cc_sda"
									customInput={TextField}
									variant="outlined"
									// label="potenza unitaria"
									InputLabelProps={{
										shrink: true,
									}}
									error={!!errors?.potenza_cc_sda}
									helperText={errors?.potenza_cc_sda?.message}
									// {...register("potenza_unitaria")}
									value={field.value}
									autoComplete="off"
									prefix="kW "
									thousandSeparator="."
									decimalSeparator=","
									valueIsNumericString={true}
									onValueChange={(v) => {
										setValue("potenza_cc_sda", Number(v.value), {
											shouldDirty: true,
										});
									}}
									sx={{ width: "100%" }}
								/>
							)}
						/>
						<Typography variant="caption" color="text.secondary">
							Capacità*:
						</Typography>
						<Controller
							name="capacita"
							control={control}
							render={({ field }) => (
								<NumericFormat
									id="capacita"
									customInput={TextField}
									variant="outlined"
									// label="potenza unitaria"
									InputLabelProps={{
										shrink: true,
									}}
									error={!!errors?.capacita}
									helperText={errors?.capacita?.message}
									// {...register("potenza_unitaria")}
									value={field.value}
									autoComplete="off"
									prefix="kWh "
									thousandSeparator="."
									decimalSeparator=","
									valueIsNumericString={true}
									onValueChange={(v) => {
										setValue("capacita", Number(v.value), {
											shouldDirty: true,
										});
									}}
									sx={{ width: "100%" }}
								/>
							)}
						/>
						<Typography variant="caption" color="text.secondary">
							Tensione Nominale SDA*:
						</Typography>
						<Controller
							name="tensione_nominale_sda"
							control={control}
							render={({ field }) => (
								<NumericFormat
									id="tensione_nominale_sda"
									customInput={TextField}
									variant="outlined"
									// label="potenza unitaria"
									InputLabelProps={{
										shrink: true,
									}}
									error={!!errors?.tensione_nominale_sda}
									helperText={errors?.tensione_nominale_sda?.message}
									// {...register("potenza_unitaria")}
									value={field.value}
									autoComplete="off"
									prefix="V "
									thousandSeparator="."
									decimalSeparator=","
									valueIsNumericString={true}
									onValueChange={(v) => {
										setValue("tensione_nominale_sda", Number(v.value), {
											shouldDirty: true,
										});
									}}
									sx={{ width: "100%" }}
								/>
							)}
						/>
					</Grid>
				</Grid>
				<Button
					// disabled={!isDirty}
					variant="outlined"
					onClick={() => {
						handleClosInputSezioneProgetto();
					}}
				>
					Annulla
				</Button>
				<Button
					disabled={!isDirty}
					variant="outlined"
					type="submit"
					sx={{ ml: 1 }}
				>
					Salva
				</Button>
			</form>
		</Box>
	);
};

export default SezioneForm;
