import React, { useState } from "react";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { Routes_path } from "../../../../routerApp";

import { useAppDispatch, RootState } from "../../../../../redux/store";
import { useSelector } from "react-redux";

import ConfermaAnnulla from "../../../../common/ConfermaAnnulla";
import { Cliente } from "../../../../anagrafiche/clienti/clientiSlice";

import {
	Commessa,
	getCommessa,
	saveCommessa,
	creaIterCommessa,
} from "../../commesseSlice";
import { IterConnessione } from "../../../iterConnessioni/iterConnessioneSlice";
import {
	StatoIterTypes,
	StatoConnessioneTypes,
	StatiIter,
	StatoIter,
	ServizioTypes,
} from "../../../../anagrafiche/kpi/kpiSlice";
import { creaSopralluogoCommessa } from "../../../sopralluoghi/sopralluoghiSlice";
import { creaVerificaVincoliCommessa } from "../../../verificheVincoli/verificheVincoliSlice";
import AnalisiPreliminariCommessaGrid from "./AnalisiPreliminariCommessaGrid";
import IterConnessioniCommessaGrid from "./IterConnessioniCommessaGrid";
import SoggettoPage from "../../../../anagrafiche/soggetti/SoggettoPage";
import Button from "@mui/material/Button";
import { Box } from "@mui/system";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import AddIcon from "@mui/icons-material/Add";
import { theme } from "../../../../theme";
import ConfermaMaterialiCard from "./confermaMateriali/ConfermaMaterialiCard";
import AllegatiPage from "../../../../allegati/AllegatiPage";

interface AnalisiTabProps {
	commessa: Commessa;
	consentiModifica?: boolean;
}

const AnalisiTab = ({ commessa, consentiModifica }: AnalisiTabProps) => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const clienti = useSelector<RootState, Cliente[]>(
		(state) => state.clientiState.clienti.results
	);

	const handlerCreateIterConnessione = (servizio?: ServizioTypes) => {
		dispatch(creaIterCommessa({ commessa: commessa, tipo_servizio: servizio }));
	};
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [anchorElAnalisi, setAnchorElAnalisi] = useState<null | HTMLElement>(
		null
	);
	const open = Boolean(anchorEl);
	const openAnalisi = Boolean(anchorElAnalisi);
	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClickAnalisi = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorElAnalisi(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	const handleCloseAnalisi = () => {
		setAnchorElAnalisi(null);
	};

	// funzione di gestione sul click della riga che se è ricerca iter chiama la funzione azioneSuIter altrimenti naviga sulla pagina dell'iter
	const handleClickRow = (iter: IterConnessione) => {
		navigate(Routes_path.ITER_CONNESSIONE + iter.id);
	};
	return (
		<Box
			sx={{
				backgroundColor: "#fff",
				elevation: 20,
				p: 2,
			}}
		>
			{/* <Card elevation={3} sx={{ mb: 2 }}>
				<CardHeader
					sx={{ backgroundColor: "#a6ed5e" }}
					title={`Pratica General Contractor ${
						clienti.find((c) => c.id == commessa.cliente)?.ragione_sociale
					}`}
				/>

				<CardContent>
					<Button
						id="basic-button"
						color="primary"
						startIcon={<AddIcon />}
						aria-controls={open ? "basic-menu" : undefined}
						aria-haspopup="true"
						aria-expanded={open ? "true" : undefined}
						// onClick={handleClick}
					>
						Avvia pratica General Contractor
					</Button>
				</CardContent>
			</Card> */}
			<Card elevation={3} sx={{ mb: 2 }}>
				<CardHeader
					sx={{ backgroundColor: "#ed875e" }}
					title={`Analisi Preliminari`}
				/>

				<CardContent>
					<Button
						id="basic-button"
						color="primary"
						startIcon={<AddIcon />}
						aria-controls={openAnalisi ? "basic-menu-analisi" : undefined}
						aria-haspopup="true"
						aria-expanded={openAnalisi ? "true" : undefined}
						onClick={handleClickAnalisi}
					>
						Avvia Nuova Analisi Preliminare
					</Button>
					<Menu
						id="basic-menu-analisi"
						anchorEl={anchorElAnalisi}
						open={openAnalisi}
						onClose={handleCloseAnalisi}
						MenuListProps={{
							"aria-labelledby": "basic-button",
						}}
					>
						<MenuItem onClick={handleCloseAnalisi}>
							<Button
								variant="text"
								color="primary"
								onClick={() => {
									commessa?.id &&
										dispatch(
											creaSopralluogoCommessa({
												commessa: commessa,
												servizio_i43: "true",
											})
										);
								}}
							>
								Sopralluogo I43
							</Button>
						</MenuItem>
						<MenuItem onClick={handleCloseAnalisi}>
							<Button
								variant="text"
								color="primary"
								onClick={() => {
									commessa?.id &&
										dispatch(
											creaSopralluogoCommessa({
												commessa: commessa,
												servizio_i43: "false",
											})
										);
								}}
							>{`Sopralluogo ${
								clienti.find((c) => c.id == commessa.cliente)?.ragione_sociale
							}`}</Button>
						</MenuItem>
						<MenuItem onClick={handleCloseAnalisi}>
							<Button
								variant="text"
								color="primary"
								onClick={() => {
									commessa?.id &&
										dispatch(
											creaVerificaVincoliCommessa({
												commessa: commessa,
												servizio_i43: "true",
											})
										);
								}}
							>
								Verifica Vincoli I43
							</Button>
						</MenuItem>
						<MenuItem onClick={handleCloseAnalisi}>
							<Button
								variant="text"
								color="primary"
								onClick={() => {
									commessa?.id &&
										dispatch(
											creaVerificaVincoliCommessa({
												commessa: commessa,
												servizio_i43: "false",
											})
										);
								}}
							>
								{`Verifica Vincoli ${
									clienti.find((c) => c.id == commessa.cliente)?.ragione_sociale
								}`}
							</Button>
						</MenuItem>
					</Menu>
					<AnalisiPreliminariCommessaGrid commessa={commessa} />
					<AllegatiPage
						consentiModifica={!!consentiModifica}
						commessaId={commessa.id}
						faseCommessa={"ANP"}
					/>
				</CardContent>
			</Card>


			<Card elevation={3} sx={{ mb: 2 }}>
				<CardHeader
					sx={{ backgroundColor: "#d9d900" }}
					title={`Conferma Materiali`}
				/>
				<CardContent>
					<ConfermaMaterialiCard commessa={commessa} consentiModifica={true} />
				</CardContent>
			</Card>
			{commessa.stato &&
				["D", "E", "F", "G", "H", "I", "L", "M", "N"].includes(
					commessa.stato
				) && (
					<Card elevation={3} sx={{ mb: 2 }}>
						<CardHeader
							sx={{ backgroundColor: "#03afe3" }}
							title={`Servizi I43`}
						/>

						<CardContent>
							<Button
								id="basic-button"
								color="primary"
								startIcon={<AddIcon />}
								aria-controls={open ? "basic-menu" : undefined}
								aria-haspopup="true"
								aria-expanded={open ? "true" : undefined}
								onClick={handleClick}
							>
								Aggiungi nuovo servizio
							</Button>

							<Menu
								id="basic-menu"
								anchorEl={anchorEl}
								open={open}
								onClose={handleClose}
								MenuListProps={{
									"aria-labelledby": "basic-button",
								}}
							>
								<MenuItem onClick={handleClose}>
									<Button
										variant="text"
										color="primary"
										onClick={(event) => {
											handlerCreateIterConnessione(ServizioTypes.ITERS_FV);
										}}
									>
										Nuovo Iter Connessione per Impianti Nuovi
									</Button>
								</MenuItem>
								<MenuItem onClick={handleClose}>
									<Button
										variant="text"
										color="primary"
										onClick={(event) => {
											handlerCreateIterConnessione(ServizioTypes.ITERO_FV);
										}}
									>
										Nuovo Iter Connessione per ampliam. Impianti Esistenti
									</Button>
								</MenuItem>
								<MenuItem onClick={handleClose}>
									<Button
										variant="text"
										color="primary"
										onClick={(event) => {
											handlerCreateIterConnessione(ServizioTypes.FES_11);
										}}
									>
										Full Engineering Service sino a 11,08 kWp
									</Button>
								</MenuItem>
								<MenuItem onClick={handleClose}>
									<Button
										variant="text"
										color="primary"
										onClick={(event) => {
											handlerCreateIterConnessione(ServizioTypes.FES_20);
										}}
									>
										Full Engineering Service sino a 20 kWp
									</Button>
								</MenuItem>
								<MenuItem onClick={handleClose}>
									<Button
										variant="text"
										color="primary"
										onClick={(event) => {
											handlerCreateIterConnessione(ServizioTypes.FES_150);
										}}
									>
										Full Engineering Service sino a 150 kWp
									</Button>
								</MenuItem>
								<MenuItem onClick={handleClose}>
									<Button
										variant="text"
										color="primary"
										onClick={(event) => {
											handlerCreateIterConnessione(ServizioTypes.ENEA_FV);
										}}
									>
										Comunicazione su portale ENEA
									</Button>
								</MenuItem>
								<MenuItem onClick={handleClose}>
									<Button
										variant="text"
										color="primary"
										onClick={(event) => {
											handlerCreateIterConnessione(ServizioTypes.OFF_FV);
										}}
									>
										Officina Elettrica (ADM)
									</Button>
								</MenuItem>
								{/* <MenuItem onClick={handleClose}>
										<Button
											variant="text"
											color="primary"
											onClick={(event) => {
												handlerCreateIterConnessione(ServizioTypes.ADEM_FV);
											}}
										>
											Gestione scadenze mantenim. esercizio impiant. fotov.
										</Button>
									</MenuItem> */}
							</Menu>
							<IterConnessioniCommessaGrid commessa={commessa} />
						</CardContent>
					</Card>
				)}
		</Box>
	);
};

export default AnalisiTab;
