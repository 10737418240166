import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import {
	IterConnessione,
	getIterConnessione,
	saveIterConnessione,
} from "../../iterConnessioneSlice";

import { useAppDispatch, RootState } from "../../../../../redux/store";

import { theme } from "../../../../theme";

import { useForm, SubmitHandler, Controller } from "react-hook-form";
import FormControlLabel from "@mui/material/FormControlLabel";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import TextField from "@mui/material/TextField";

import Divider from "@mui/material/Divider";
import {
	Soggetto,
	saveProduttore,
} from "components/anagrafiche/produttori/produttoriSlice";


interface IterConnessioneAvvioIterFormProps {
	iterConnessione: IterConnessione;
	consentiModifica: boolean;
}

const IterConnessioneImpostazioneForm = ({
	iterConnessione,
	consentiModifica = false,
}: IterConnessioneAvvioIterFormProps) => {


	const tipiServizio = useSelector(
		(state: RootState) => state.tipiServizioState.tipiServizio.results
	);

	const [ampliamento, setAmpliamento] = useState<boolean | undefined>(
		iterConnessione.ampliamento ||
			tipiServizio.find((ts) => ts.id == iterConnessione.tipo_servizio)
				?.is_ampliamento
	);
	const [altroPod, setAltroPod] = useState<boolean | undefined>(
		iterConnessione.altro_pod
	);

	const dispatch = useAppDispatch();

	useEffect(() => {
		iterConnessione.ampliamento != undefined &&
		setAmpliamento(iterConnessione.ampliamento);

		iterConnessione.altro_pod != undefined &&
		setAltroPod(iterConnessione.altro_pod);
	}, [
		iterConnessione.ampliamento
	]);


	return (
		<Box sx={{ mb: 1 }}>
			<Grid container spacing={2} sx={{ mb: 2 }}>
				<Grid item xs={12} sm={12} md={6} lg={6}>
					<FormControl>
						<FormLabel id="radio-buttons-group-label">
							Si tratta di apliamento di impianto esistente?
						</FormLabel>
						<RadioGroup
							aria-labelledby="altri-impianti"
							name="radio-buttons-group"
							value={ampliamento}
							onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
								const ampliamento: boolean = event.target.value == "true";
								setAmpliamento(event.target.value == "true");
								const iterConnessioneToSave: IterConnessione = {
									...iterConnessione,
									ampliamento: ampliamento,
									iter_semplificato: !ampliamento,
								};
								dispatch(saveIterConnessione({ iterConnessioneToSave }));
							}}
						>
							<FormControlLabel value={true} control={<Radio />} label="SI" />
							<FormControlLabel value={false} control={<Radio />} label="NO" />
						</RadioGroup>
					</FormControl>
				</Grid>
				<Grid item xs={12} sm={12} md={6} lg={6}>
					<FormControl>
						<FormLabel id="radio-buttons-group-label">
							Il produttore ha altri impianti di produzione su altro POD*?
						</FormLabel>
						<RadioGroup
							aria-labelledby="altri-impiantil"
							name="radio-buttons-group"
							value={altroPod}
							onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
								setAltroPod(event.target.value == "true");
								const iterConnessioneToSave: IterConnessione = {
									...iterConnessione,
									altro_pod: event.target.value == "true",
								};
								dispatch(saveIterConnessione({ iterConnessioneToSave }));
							}}
						>
							<FormControlLabel value={true} control={<Radio />} label="SI" />
							<FormControlLabel value={false} control={<Radio />} label="NO" />
						</RadioGroup>
					</FormControl>
				</Grid>
			</Grid>
		</Box>
	);
};

export default IterConnessioneImpostazioneForm;
