import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../../../../redux/store";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { Routes_path } from "../../../../routerApp";

import { DateTime } from "luxon";


import { utenteAutorizzato } from "../../../../authentication/UtenteAutorizzato";
import { UtenteLogged } from "../../../../authentication/authenticationSlice";
import { IterConnessione } from "../../iterConnessioneSlice";
import {
	getAutorizzazioneComunaleFromIter,
	AutorizzazioneComunale,
} from "./autorizzazioneComunaleSlice";
import {
	ErrorStatusTypes,
	ErrorsStack,
} from "../../../../common/errorsDeclarations";

import IterConnessioneAutorizzazioneComunaleForm from "./AutorizzazioneComunaleForm";
import Divider from "@mui/material/Divider";
import AzioniAutorizzazioneComunaleCard from "./AzioniAutorizzazioneComunaleCard";
import AllegatiPage from "../../../../allegati/AllegatiPage";
import { PermissionTypes } from "../../../../authentication/permissionsGroups";
import AzioniGestoreCard from "../avvioIter/AzioniGestoreCard";

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import Pagination from "@mui/material/Pagination";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import { TipoPratica } from "components/anagrafiche/tipiPratica/tipiPraticaSlice";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import Stack from "@mui/material/Stack";
import { theme } from "../../../../theme";

interface IterConnessioneAutorizzazioneComunaleTabProps {
	iterConnessione: IterConnessione;
	utente: UtenteLogged;

	consentiModifica: boolean;
}

const IterConnessioneAutorizzazioneComunaleTab = ({
	iterConnessione,
	utente,
	consentiModifica = false,
}: IterConnessioneAutorizzazioneComunaleTabProps) => {
	
	const dispatch = useAppDispatch();
	const [tipoDocumentoId, setTipoDocumentoId] = useState<number | undefined>(
		undefined
	);
	const [page, setPage] = useState<number>(1);
	const [numeroRecord, setNumeroRecord] = useState<number>(10);
	const [search, setSearch] = useState<string | undefined>(undefined);
	const [anchorTipoDocumentoFilter, setAnchorTipoDocumentoFilter] =
		useState(null);

	const handleChangeNumeroRecord = (event: SelectChangeEvent) => {
		setNumeroRecord(Number(event.target.value));
	};

	const handleStatoFilterClose = () => {
		setAnchorTipoDocumentoFilter(null);
		// onClose(selectedValue);
	};

	useEffect(() => {
		iterConnessione.id &&
			dispatch(getAutorizzazioneComunaleFromIter(iterConnessione.id));
	}, []);

	const autorizzazioneComunale: AutorizzazioneComunale | undefined =
		useSelector<RootState, AutorizzazioneComunale | undefined>(
			(state) =>
				state.autorizzazioneComunaleState.autorizzazioniComunali.results.find(
					(autorizzazioneComunale) =>
						autorizzazioneComunale.iter_connessione == iterConnessione.id || 0
				)
			// state.autorizzazioneComunaleState.autorizzazioneComunale.results.slice().sort((a, b) => {
			// 	return b.id - a.id;
			// })
		);

	const autorizzazioniComunaliStatus: ErrorsStack | undefined = useSelector<
		RootState,
		ErrorsStack | undefined
	>(
		(state) =>
			state.autorizzazioneComunaleState.autorizzazioniComunali.errorsStack
		// state.eneaState.enea.results.slice().sort((a, b) => {
		// 	return b.id - a.id;
		// })
	);

	return (
		<Box>
			<Backdrop
				sx={{
					color: "#fff",
					zIndex: (theme) => theme.zIndex.drawer + 1,
				}}
				open={autorizzazioniComunaliStatus?.status == ErrorStatusTypes.PENDING}
			>
				<CircularProgress color="inherit" />
			</Backdrop>
			<Grid container spacing={2} sx={{ mb: 4 }}>
				<Grid item xs={12} sm={12} md={8} lg={9}>
					<Stack spacing={2}>
						{autorizzazioneComunale && (
							<IterConnessioneAutorizzazioneComunaleForm
								iterConnessione={iterConnessione}
								autorizzazioneComunale={autorizzazioneComunale}
								consentiModifica={consentiModifica}
							/>
						)}

						<Divider
							textAlign="left"
							sx={{ mt: 4, mb: 1, color: theme.palette.primary.main }}
						/>
						{autorizzazioneComunale?.id && (
							<AllegatiPage
								iterConnessioneId={autorizzazioneComunale.iter_connessione}
								faseIterConnessione="AUC"
								// listaDocumentiPerTipo={
								// 	autorizzazioneComunale.listaDocumentiPerTipo
								// }
								autorizzazioneComunaleId={autorizzazioneComunale.id || 0}
								consentiModifica={consentiModifica}
							/>
						)}
					</Stack>
				</Grid>
				<Grid item xs={6} sm={6} md={4} lg={3}>
					{autorizzazioneComunale && (
						<AzioniAutorizzazioneComunaleCard
							iterConnessione={iterConnessione}
							autorizzazioneComunale={autorizzazioneComunale}
							consentiModifica={consentiModifica}
						/>
					)}
					{utenteAutorizzato(utente, [PermissionTypes.GESTORI_ITER]) && (
						<AzioniGestoreCard iterConnessione={iterConnessione} />
					)}
				</Grid>
			</Grid>
		</Box>
	);
};

export default IterConnessioneAutorizzazioneComunaleTab;
