import { handleResponse, handleError, headerset } from "../apiUtils";
import { Utente } from "components/authentication/utenti/utentiSlice";

/// DA RIDEFINIRE in base a nuova struttura BE ////
const baseUrl = (documento_id?: number | null) => {
	let url: string = process.env.API_URL + "/api/utenti_applicativo/";

	if (documento_id) {
		url += +documento_id + "/";
	}

	return url;
};

export function fetchUtenti(
	cliente?: number,
	cer?: number,
	produttore?: number,
	numeroRecord?: number,
	page?: number,
	search?: string
) {
	let url = baseUrl();
	if (
		cliente != undefined ||
		cer != undefined ||
		numeroRecord != undefined ||
		search != undefined ||
		page != undefined
	) {
		url += "?";
	}
	if (cliente != undefined) {
		url += `cliente=${cliente}&`;
	}
	if (cer != undefined) {
		url += `cer=${cer}&`;
	}
	if (produttore != undefined) {
		url += `produttore=${produttore}&`;
	}
	if (numeroRecord != undefined) {
		url += `numero_record=${numeroRecord}&`;
	}
	if (page != undefined) {
		url += `page=${page}&`;
	}
	if (search != undefined) {
		url += `search=${search}`;
	}
	// data_da.toFormat('yyyy-MM-dd');
	return fetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}

export function getUtente(utente_id: number) {
	let url = baseUrl(utente_id);
	return fetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}

export function saveUtente(utenteToSave: Utente) {
	return fetch(baseUrl(utenteToSave.id), {
		method: utenteToSave.id ? "PUT" : "POST", // PUT to update when id already exists.
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},

		body: JSON.stringify({
			...utenteToSave,
			// Parse authorId to a number (in case it was sent as a string).
			// id: parseInt(progetto.id, 10),
		}),
	})
		.then(handleResponse)
		.catch(handleError);
}

export function deleteUtente(utenteToDelete: Utente) {
	return fetch(baseUrl(utenteToDelete.id), {
		method: "DELETE",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}

// ###########################

export function invitaUtente(utente: Utente) {
	const url: string = baseUrl() + "invia_invito/" + utente.id + "/";
	return fetch(url, {
		method: "PUT",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}

// ###########################
