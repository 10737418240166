import React, {
	useEffect,
	useState,
	useRef,
	ChangeEvent,
	FocusEvent,
	KeyboardEvent,
} from "react";
import { useSelector } from "react-redux";
import { useLocation, Link, useNavigate } from "react-router-dom";

import { RootState, useAppDispatch } from "../../../redux/store";
import { Routes_path } from "../../routerApp";

import { DateTime } from "luxon";
import { convertToDateTime } from "components/common/dateUtils/convertToDateTime";

import {
	ErrorStatusTypes,
	ErrorsStack,
	parseErrorMessage,
} from "../../common/errorsDeclarations";

import {
	OrdinamentoLista,
	PreferenzeLista,
	setPreferenzeListaIter,
} from "../../userPreference/userPreferenceSlice";
import {
	fetchIterConnessioni,
	IterConnessioniState,
	IterConnessione,
	resetNuovoIterConnessioneId,
	saveIterConnessione,
} from "./iterConnessioneSlice";
import { RegimeCommerciale } from "../../anagrafiche/regimiCommerciali/regimiCommercialiSlice";
import {
	StatoIterTypes,
	StatoConnessioneTypes,
	StatiIter,
	StatoIter,
	ServizioTypes,
} from "../../anagrafiche/kpi/kpiSlice";
import {
	Soggetto,
	resetProduttoreCorrente,
} from "../../anagrafiche/produttori/produttoriSlice";

import IterConnessioneAlerChip from "./iterConnessione/IterConnessioneAlerChip";
import { Cliente } from "../../anagrafiche/clienti/clientiSlice";
import { PermissionTypes } from "../../authentication/permissionsGroups";
import { utenteAutorizzato } from "../../authentication/UtenteAutorizzato";
import {
	UtenteLogged,
	Utente,
	UtenteApplicativo,
} from "../../authentication/authenticationSlice";
import {
	EsponiStatoSintetico,
	statiSintetici,
} from "components/common/EsponiStatoSintetico";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";
import Chip from "@mui/material/Chip";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import AddIcon from "@mui/icons-material/Add";

import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import Pagination from "@mui/material/Pagination";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuList from "@mui/material/MenuList";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import { TipoPratica } from "components/anagrafiche/tipiPratica/tipiPraticaSlice";
import { TipoServizio } from "../../anagrafiche/tipiServizio/tipiServizioSlice";

import FilterAltIcon from "@mui/icons-material/FilterAlt";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import ReplayIcon from "@mui/icons-material/Replay";

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

const produttoreFullName = (produttore?: Soggetto) => {
	if (produttore) {
		if (produttore.tipo_persona == "PF") {
			return `${produttore.cognome} ${produttore.nome}`;
		} else {
			return `${produttore.ragione_sociale}`;
		}
	} else {
		return "anonimo";
	}
};

const visualizzaStatoIter = (statoIter?: StatoIter) => {
	return statoIter && `${statoIter.stato_desc} (${statoIter.count})`;
};

interface IterConnessioniPageProps {
	openRicercaIter?: boolean;
	azioneSuIter?: (iter: IterConnessione) => void;
	// setOpenRicercaIter: (value: boolean) => void;
}

const IterConnessioniPage = ({
	openRicercaIter = false,
	azioneSuIter,
}: IterConnessioniPageProps) => {
	
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	type LocationState = {
		statoIterId?: string;
		clienteIterId?: number;
		iterSemplificato?: string;
		operatoreId?: number;
		progettistaId?: number;
	};
	const location = useLocation();
	const utente = useSelector<RootState, Utente>(
		(state) => state.authentication.utenteLogged
	);

	const preferenzeListaIter = useSelector<
		RootState,
		PreferenzeLista | undefined
	>((state) => state.userPreferenceState.preferenzeListaIter);

	const [statoIterId, setStatoIterId] = useState(
		location.state
			? (location.state as LocationState).statoIterId
			: // : preferenzeListaIter?.stato
			  undefined
	);

	const [clienteIterId, setClienteIterId] = useState(
		location.state
			? (location.state as LocationState).clienteIterId
			: preferenzeListaIter?.clienteIterId
	);

	const [iterSemplificato, setIterSemplificato] = useState(
		location.state
			? (location.state as LocationState).iterSemplificato
			: preferenzeListaIter?.iterSemplificato
	);

	const [operatoreId, setOperatoreId] = useState(
		location.state
			? (location.state as LocationState).operatoreId
			: preferenzeListaIter?.operatoreId
	);

	const [progettistaId, setProgettistaId] = useState(
		location.state
			? (location.state as LocationState).progettistaId
			: preferenzeListaIter?.progettistaId
	);

	const iterConnessioni = useSelector<RootState, IterConnessioniState>(
		(state) => state.iterConnessioniState.iterConnessioni
	);

	const nuovoIterConnessioneId = useSelector<RootState, number | undefined>(
		(state) => state.iterConnessioniState.nuovoIterConnessioneId
	);

	const statiIter = useSelector<RootState, StatiIter>(
		(state) => state.kpiState.statiIter
	);

	const clienti = useSelector<RootState, Cliente[]>(
		(state) => state.clientiState.clienti.results
	);

	const tipiPratica = useSelector<RootState, TipoPratica[]>(
		(state) => state.tipiPraticaState.tipiPratica.results
	);
	const tipiServizio = useSelector<RootState, TipoServizio[]>(
		(state) => state.tipiServizioState.tipiServizio.results
	);

	const produttori = useSelector<RootState, Soggetto[]>(
		(state) => state.produttoriState.produttori.results
	);

	const utentiApplicativo = useSelector<RootState, UtenteApplicativo[]>(
		(state) => state.authentication.utentiApplicativo.results
	);

	const [page, setPage] = useState<number>(preferenzeListaIter?.page || 1);
	const [numeroRecord, setNumeroRecord] = useState<number>(
		preferenzeListaIter?.numeroRecord || 10
	);
	const [search, setSearch] = useState<string | undefined>(undefined);
	const [ordinamento, setOrdinamento] = useState<OrdinamentoLista | undefined>(
		undefined
	);

	const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
		if (event.key === "Enter") {
			setRefresh(true);
		}
	};

	const handleChangeNumeroRecord = (event: SelectChangeEvent) => {
		setNumeroRecord(Number(event.target.value));
	};

	const handlerPager = (event: React.ChangeEvent<unknown>, page: number) => {
		setPage(page);
	};

	const [refresh, setRefresh] = useState<boolean>(false);

	const [anchorElStatoFilter, setAnchorElStatoFilter] = useState(null);
	const [anchorElClienteFilter, setAnchorElClienteFilter] = useState(null);
	const [anchorElTipoIterFilter, setAnchorElTipoIterFilter] = useState(null);
	const [anchorElOperatoreFilter, setAnchorElOperatoreFilter] = useState(null);
	const [anchorElProgettistaFilter, setAnchorElProgettistaFilter] =
		useState(null);

	const handleStatoFilterClose = () => {
		setAnchorElStatoFilter(null);
		setAnchorElClienteFilter(null);
		setAnchorElTipoIterFilter(null);
		setAnchorElOperatoreFilter(null);
		setAnchorElProgettistaFilter(null);
		// onClose(selectedValue);
	};

	useEffect(() => {
		dispatch(
			fetchIterConnessioni({
				statoIter: statoIterId,
				clienteIter: clienteIterId,
				iterSemplificato: iterSemplificato,
				operatore: operatoreId,
				progettista: progettistaId,
				numeroRecord: numeroRecord,
				page: page,
				search: search,
			})
		);
		dispatch(
			setPreferenzeListaIter({
				numeroRecord: numeroRecord,
				page: page,
				ordinamento: ordinamento,
				stato: statoIterId,
				clienteIterId: clienteIterId,
				iterSemplificato: iterSemplificato,
				operatoreId: operatoreId,
				progettistaId: progettistaId,
			})
		);
		setRefresh(false);
	}, [
		page,
		numeroRecord,
		// search,
		statoIterId,
		clienteIterId,
		iterSemplificato,
		operatoreId,
		progettistaId,
		refresh,
	]);

	useEffect(() => {
		!!nuovoIterConnessioneId &&
			navigate(Routes_path.ITER_CONNESSIONE + nuovoIterConnessioneId);
		dispatch(resetNuovoIterConnessioneId());
	}, [nuovoIterConnessioneId]);

	const handlerCreateIterConnessione = (servizio?: ServizioTypes) => {
		const tipoServizio = tipiServizio.find((s) => s.codice == servizio);

		const nuovaIterConnessione: IterConnessione = {
			// tipo_servizio: servizio,
			produttore: null,
			ampliamento: tipoServizio?.is_ampliamento,
			iter_semplificato: !tipoServizio?.is_ampliamento,
			tipo_servizio: tipoServizio?.id,
			stato: "A",
		};

		dispatch(
			saveIterConnessione({ iterConnessioneToSave: nuovaIterConnessione })
		);
	};

	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	// funzione di gestione sul click della riga che se è ricerca iter chiama la funzione azioneSuIter altrimenti naviga sulla pagina dell'iter
	const handleClickRow = (iter: IterConnessione) => {
		if (openRicercaIter) {
			azioneSuIter && azioneSuIter(iter);
		} else {
			navigate(Routes_path.ITER_CONNESSIONE + iter.id);
		}
	};

	return (
		<Box
			sx={{
				backgroundColor: "#fff",
				elevation: 20,
				// p: 2,
				"& .campo-editabile": {
					backgroundColor: "#bbbbbb11",
					background: "linear-gradient(to right bottom, #f5f7f6, #dce3e0)",
					// borderWidth: "5px",
					borderColor: "#ff91003d",
					// borderColor: "#0000003d",
					// borderStyle: "outset",
					borderWidth: 3,
					borderStyle: "ridge",
					color: "#1a3e72",
					fontWeight: "600",
				},
				"& .campo-errore": {
					backgroundColor: "rgba(124, 3, 0, 0.3)",
					color: "#ffffff",
					fontWeight: "600",
				},
			}}
		>
			<List sx={{ mt: 2 }}>
				<ListItem>
					<ButtonGroup
						variant="outlined"
						aria-label="outlined button group"
						sx={{ mb: 2 }}
					>
						<Button
							color="primary"
							startIcon={<ReplayIcon />}
							onClick={() => setRefresh(true)}
						>
							aggiorna
						</Button>
						{!openRicercaIter && (
							<>
								<Button
									id="basic-button"
									color="primary"
									startIcon={<AddIcon />}
									aria-controls={open ? "basic-menu" : undefined}
									aria-haspopup="true"
									aria-expanded={open ? "true" : undefined}
									onClick={handleClick}
								>
									Aggiungi nuovo Iter
								</Button>

								<Menu
									id="basic-menu"
									anchorEl={anchorEl}
									open={open}
									onClose={handleClose}
									MenuListProps={{
										"aria-labelledby": "basic-button",
									}}
								>
									<MenuItem onClick={handleClose}>
										<Button
											variant="text"
											color="primary"
											onClick={(event) => {
												handlerCreateIterConnessione(ServizioTypes.ITERS_FV);
											}}
										>
											Nuovo Iter Connessione per Impianti Nuovi
										</Button>
									</MenuItem>
									<MenuItem onClick={handleClose}>
										<Button
											variant="text"
											color="primary"
											onClick={(event) => {
												handlerCreateIterConnessione(ServizioTypes.ITERO_FV);
											}}
										>
											Nuovo Iter Connessione per ampliam. Impianti Esistenti
										</Button>
									</MenuItem>
									<MenuItem onClick={handleClose}>
										<Button
											variant="text"
											color="primary"
											onClick={(event) => {
												handlerCreateIterConnessione(ServizioTypes.FES_11);
											}}
										>
											Full Engineering Service sino a 11,08 kWp
										</Button>
									</MenuItem>
									<MenuItem onClick={handleClose}>
										<Button
											variant="text"
											color="primary"
											onClick={(event) => {
												handlerCreateIterConnessione(ServizioTypes.FES_20);
											}}
										>
											Full Engineering Service sino a 20 kWp
										</Button>
									</MenuItem>
									<MenuItem onClick={handleClose}>
										<Button
											variant="text"
											color="primary"
											onClick={(event) => {
												handlerCreateIterConnessione(ServizioTypes.FES_150);
											}}
										>
											Full Engineering Service sino a 150 kWp
										</Button>
									</MenuItem>
									<MenuItem onClick={handleClose}>
										<Button
											variant="text"
											color="primary"
											onClick={(event) => {
												handlerCreateIterConnessione(ServizioTypes.ENEA_FV);
											}}
										>
											Comunicazione su portale ENEA
										</Button>
									</MenuItem>
									<MenuItem onClick={handleClose}>
										<Button
											variant="text"
											color="primary"
											onClick={(event) => {
												handlerCreateIterConnessione(ServizioTypes.OFF_FV);
											}}
										>
											Officina Elettrica (ADM)
										</Button>
									</MenuItem>
									{/* <MenuItem onClick={handleClose}>
										<Button
											variant="text"
											color="primary"
											onClick={(event) => {
												handlerCreateIterConnessione(ServizioTypes.ADEM_FV);
											}}
										>
											Gestione scadenze mantenim. esercizio impiant. fotov.
										</Button>
									</MenuItem> */}
								</Menu>
							</>
						)}
						<TextField
							id="input-with-icon-textfield"
							// label="TextField"
							InputProps={{
								startAdornment: (
									<InputAdornment position="end">
										<SearchIcon />
									</InputAdornment>
								),
							}}
							variant="standard"
							value={search}
							onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
								setSearch(event.target.value);
							}}
							onBlur={() => setRefresh(true)}
							onKeyDown={handleKeyDown}
							sx={{ ml: 2 }}
						/>
					</ButtonGroup>
				</ListItem>

				<ListItemText
					key={"Lista_saldo_pallet_committente"}
					sx={{ backgroundColor: "#1b75b1", color: "#ddd", mb: 1 }}
				>
					<Grid
						container
						spacing={{ xs: 0.5, md: 1, lg: openRicercaIter ? 4 : 2 }}
						sx={{ px: 2 }}
					>
						<Grid item xs={6} sm={6} md={6} lg={openRicercaIter ? 2 : 1}>
							<Typography variant="h5" gutterBottom>
								Iter
							</Typography>
						</Grid>
						<Grid item xs={6} sm={6} md={6} lg={2}>
							<Typography variant="h5" gutterBottom>
								Soggetto
							</Typography>
						</Grid>
						<Grid item xs={6} sm={6} md={6} lg={2}>
							<Stack direction="row" spacing={1}>
								<Typography variant="h5" gutterBottom>
									Cliente
								</Typography>
								<IconButton
									color={clienteIterId ? "secondary" : "inherit"}
									onClick={(event: any) =>
										setAnchorElClienteFilter(event.currentTarget)
									}
								>
									<FilterAltIcon sx={{ mt: -0.5 }} />
								</IconButton>
							</Stack>
							<Box style={{ display: "flex", justifyContent: "space-between" }}>
								<Menu
									id="simple-menu"
									anchorEl={anchorElClienteFilter}
									keepMounted
									open={Boolean(anchorElClienteFilter)}
									onClose={handleStatoFilterClose}
								>
									{clienti.map((cliente) => (
										<MenuItem
											key={cliente.id}
											onClick={() => {
												setClienteIterId(cliente.id);
												setAnchorElClienteFilter(null);
											}}
											sx={{
												backgroundColor:
													cliente.id == clienteIterId ? "#aaa" : "inherit",
											}}
										>
											{cliente.ragione_sociale}
										</MenuItem>
									))}
									<MenuItem
										onClick={() => {
											setClienteIterId(undefined);
											setAnchorElClienteFilter(null);
										}}
										sx={{
											backgroundColor: !clienteIterId ? "#aaa" : "inherit",
										}}
									>
										tutti
									</MenuItem>
								</Menu>
							</Box>
						</Grid>
						<Grid item xs={6} sm={6} md={6} lg={1}>
							<Stack direction="row" spacing={1}>
								<Typography variant="h5" gutterBottom>
									Tipo Iter
								</Typography>
								<IconButton
									color={iterSemplificato ? "secondary" : "inherit"}
									onClick={(event: any) =>
										setAnchorElTipoIterFilter(event.currentTarget)
									}
								>
									<FilterAltIcon sx={{ mt: -0.5 }} />
								</IconButton>
							</Stack>
							<Box style={{ display: "flex", justifyContent: "space-between" }}>
								<Menu
									id="simple-menu"
									anchorEl={anchorElTipoIterFilter}
									keepMounted
									open={Boolean(anchorElTipoIterFilter)}
									onClose={handleStatoFilterClose}
								>
									<MenuItem
										onClick={() => {
											setIterSemplificato("False");
											setAnchorElTipoIterFilter(null);
										}}
										sx={{
											backgroundColor:
												iterSemplificato == "False" ? "#aaa" : "inherit",
										}}
									>
										ordinario
									</MenuItem>
									<MenuItem
										onClick={() => {
											setIterSemplificato("True");
											setAnchorElTipoIterFilter(null);
										}}
										sx={{
											backgroundColor:
												iterSemplificato == "True" ? "#aaa" : "inherit",
										}}
									>
										semplificato
									</MenuItem>
									<MenuItem
										onClick={() => {
											setIterSemplificato(undefined);
											setAnchorElTipoIterFilter(null);
										}}
										sx={{
											backgroundColor: !iterSemplificato ? "#aaa" : "inherit",
										}}
									>
										tutti
									</MenuItem>
								</Menu>
							</Box>
						</Grid>
						<Grid
							item
							xs={12}
							sm={12}
							md={12}
							lg={
								utenteAutorizzato(utente, [
									PermissionTypes.GESTORI_ITER,
									PermissionTypes.OPERATORI,
									PermissionTypes.PROGETTISTI,
								])
									? 2
									: 4
							}
						>
							<Typography variant="h5" gutterBottom>
								Tipo di pratica
							</Typography>
						</Grid>
						{!openRicercaIter && (
							<Grid item xs={6} sm={6} md={6} lg={1}>
								<Stack direction="row" spacing={1}>
									<Typography variant="h5">Stato</Typography>
									<IconButton
										color={statoIterId ? "secondary" : "inherit"}
										onClick={(event: any) =>
											setAnchorElStatoFilter(event.currentTarget)
										}
									>
										<FilterAltIcon sx={{ mt: -0.5 }} />
									</IconButton>
								</Stack>
								<Box
									style={{ display: "flex", justifyContent: "space-between" }}
								>
									<Menu
										id="simple-menu"
										anchorEl={anchorElStatoFilter}
										keepMounted
										open={Boolean(anchorElStatoFilter)}
										onClose={handleStatoFilterClose}
									>
										{statiSintetici.map((statoIter) => (
											<MenuItem
												key={`statoIter_${statoIter.descrizione}`}
												onClick={() => {
													setStatoIterId(statoIter.statiBase.toString());
													setAnchorElStatoFilter(null);
												}}
												// sx={{
												// 	backgroundColor:
												// 		statoIter.stato == statoIterId ? "#aaa" : "inherit",
												// }}
											>
												{statoIter.descrizione}
											</MenuItem>
										))}
										<MenuItem
											onClick={() => {
												setStatoIterId(undefined);
												setAnchorElStatoFilter(null);
											}}
											sx={{
												backgroundColor: !statoIterId ? "#aaa" : "inherit",
											}}
										>
											tutti
										</MenuItem>
									</Menu>
								</Box>
							</Grid>
						)}
						{!openRicercaIter && (
							<Grid item xs={6} sm={6} md={6} lg={1}>
								<Typography variant="h5" gutterBottom>
									Data stato
								</Typography>
							</Grid>
						)}
						{utenteAutorizzato(utente, [
							PermissionTypes.GESTORI_ITER,
							PermissionTypes.OPERATORI,
							PermissionTypes.PROGETTISTI,
						]) &&
							!openRicercaIter && (
								<>
									<Grid item xs={6} sm={6} md={6} lg={1}>
										<Stack direction="row" spacing={1}>
											<Typography variant="h5">Operatore</Typography>
											<IconButton
												color={operatoreId ? "secondary" : "inherit"}
												onClick={(event: any) =>
													setAnchorElOperatoreFilter(event.currentTarget)
												}
											>
												<FilterAltIcon sx={{ mt: -0.5 }} />
											</IconButton>
										</Stack>
										<Box
											style={{
												display: "flex",
												justifyContent: "space-between",
											}}
										>
											<Menu
												id="simple-menu"
												anchorEl={anchorElOperatoreFilter}
												keepMounted
												open={Boolean(anchorElOperatoreFilter)}
												onClose={handleStatoFilterClose}
											>
												{utentiApplicativo
													// filtra per utenti con permessi di operatore
													.filter((utente) =>
														utenteAutorizzato(utente.user, [
															PermissionTypes.OPERATORI,
															PermissionTypes.GESTORI_ITER,
														])
													)
													.map((operatore) => (
														<MenuItem
															key={`operatore_${operatore.id}`}
															onClick={() => {
																setOperatoreId(operatore.id);
																setAnchorElOperatoreFilter(null);
															}}
															sx={{
																backgroundColor:
																	operatore.id == operatoreId
																		? "#aaa"
																		: "inherit",
															}}
														>
															{operatore.user.full_name}
														</MenuItem>
													))}
												<MenuItem
													onClick={() => {
														setOperatoreId(undefined);
														setAnchorElOperatoreFilter(null);
													}}
													sx={{
														backgroundColor: !operatoreId ? "#aaa" : "inherit",
													}}
												>
													tutti
												</MenuItem>
											</Menu>
										</Box>
									</Grid>
									<Grid item xs={6} sm={6} md={6} lg={1}>
										<Stack direction="row" spacing={1}>
											<Typography variant="h5">Progettista</Typography>
											<IconButton
												color={progettistaId ? "secondary" : "inherit"}
												onClick={(event: any) =>
													setAnchorElProgettistaFilter(event.currentTarget)
												}
											>
												<FilterAltIcon sx={{ mt: -0.5 }} />
											</IconButton>
										</Stack>
										<Box
											style={{
												display: "flex",
												justifyContent: "space-between",
											}}
										>
											<Menu
												id="simple-menu"
												anchorEl={anchorElProgettistaFilter}
												keepMounted
												open={Boolean(anchorElProgettistaFilter)}
												onClose={handleStatoFilterClose}
											>
												{utentiApplicativo // filtra per utenti con permessi di operatore
													.filter((utente) =>
														utenteAutorizzato(utente.user, [
															// PermissionTypes.GESTORI_ITER,
															PermissionTypes.PROGETTISTI,
														])
													)
													.map((progettista) => (
														<MenuItem
															key={`progettista_${progettista.id}`}
															onClick={() => {
																setProgettistaId(progettista.id);
																setAnchorElProgettistaFilter(null);
															}}
															sx={{
																backgroundColor:
																	progettista.id == progettistaId
																		? "#aaa"
																		: "inherit",
															}}
														>
															{progettista.user.full_name}
														</MenuItem>
													))}
												<MenuItem
													onClick={() => {
														setProgettistaId(undefined);
														setAnchorElProgettistaFilter(null);
													}}
													sx={{
														backgroundColor: !progettistaId
															? "#aaa"
															: "inherit",
													}}
												>
													tutti
												</MenuItem>
											</Menu>
										</Box>
									</Grid>
								</>
							)}
					</Grid>
				</ListItemText>

				<Backdrop
					sx={{
						color: "#fff",
						zIndex: (theme) => theme.zIndex.drawer + 1,
					}}
					open={
						iterConnessioni.errorsStack.status == ErrorStatusTypes.PENDING
						// errorsStack.status == ErrorStatusTypes.PENDING
					}
				>
					<CircularProgress color="inherit" />
				</Backdrop>

				{iterConnessioni.results?.map((iterConnessione, index) => (
					<ListItemButton
						// key={"iter_" + iterConnessione.id}
						key={index}
						component={Link}
						onClick={() => handleClickRow(iterConnessione)}
						to={
							openRicercaIter
								? ""
								: Routes_path.ITER_CONNESSIONE + iterConnessione.id
						}
						sx={{
							color: "black",
							textDecoration: "none",
							backgroundColor: index % 2 === 0 ? "#fff" : "#f2f2f2",
						}}
					>
						<Grid container spacing={{ xs: 0.5, md: 1, lg: 2 }} sx={{ mb: 1 }}>
							<Grid item xs={6} sm={6} md={8} lg={openRicercaIter ? 2 : 1}>
								<Button onClick={() => handleClickRow(iterConnessione)}>
									{iterConnessione.codice_pratica}
								</Button>
							</Grid>
							<Grid item xs={6} sm={6} md={11} lg={2}>
								{iterConnessione.produttore_descrizione}
							</Grid>
							<Grid item xs={6} sm={6} md={6} lg={2}>
								<Typography noWrap>
									{
										clienti.find(
											(cliente) => cliente.id == iterConnessione.cliente
										)?.ragione_sociale
									}
								</Typography>
							</Grid>
							<Grid item xs={6} sm={6} md={1} lg={1}>
								{iterConnessione.iter_semplificato
									? "semplificato"
									: "ordinario"}
							</Grid>
							<Grid
								item
								xs={12}
								sm={12}
								md={12}
								lg={
									utenteAutorizzato(utente, [
										PermissionTypes.GESTORI_ITER,
										PermissionTypes.OPERATORI,
										PermissionTypes.PROGETTISTI,
									])
										? 2
										: 4
								}
							>
								<Typography noWrap>
									{
										tipiPratica.find(
											(tipoPratica) =>
												tipoPratica.id == iterConnessione.tipo_pratica
										)?.descrizione
									}
								</Typography>
							</Grid>
							{utenteAutorizzato(utente, [PermissionTypes.ALL]) &&
								!openRicercaIter && (
									<>
										<Grid item xs={6} sm={6} md={6} lg={1}>
											{/* {
											Object.entries(StatoIterTypes).find(
												([key, value]) => key == iterConnessione.stato
											)?.[1]
										} */}
											{EsponiStatoSintetico(iterConnessione?.stato)}
											{utenteAutorizzato(utente, [
												PermissionTypes.GESTORI_ITER,
												PermissionTypes.OPERATORI,
												PermissionTypes.PROGETTISTI,
											]) &&
												iterConnessione.stato == "H" && (
													<Box>
														{" (" +
															Object.entries(StatoConnessioneTypes).find(
																([key, value]) =>
																	key ==
																	iterConnessione.stato_richiesta_connessione
															)?.[1] +
															" )"}
													</Box>
												)}
											{["A", "B"].includes(iterConnessione.stato) &&
												iterConnessione.note_non_ricevibile && (
													<Chip
														label={`Ric. Integraz.`}
														color="warning"
														variant="filled"
														sx={{ ml: 1 }}
														icon={<ReportGmailerrorredIcon />}
													/>
												)}
											{utenteAutorizzato(utente, [
												PermissionTypes.GESTORI_ITER,
												PermissionTypes.OPERATORI,
												PermissionTypes.PROGETTISTI,
											]) && (
												<IterConnessioneAlerChip
													iterConnessione={iterConnessione}
												/>
											)}
										</Grid>

										<Grid item xs={6} sm={6} md={6} lg={1}>
											{iterConnessione.data_ultimo_stato
												? convertToDateTime(iterConnessione.data_ultimo_stato)?.setLocale("it").toFormat("dd/MM/yyyy")
												: ""}
										</Grid>
										{utenteAutorizzato(utente, [
											PermissionTypes.GESTORI_ITER,
											PermissionTypes.OPERATORI,
											PermissionTypes.PROGETTISTI,
										]) && (
											<>
												<Grid item xs={6} sm={6} md={6} lg={1}>
													{utentiApplicativo.find(
														(utente) =>
															utente.user.id == iterConnessione.operatore
													)?.user.full_name || "-"}
												</Grid>
												<Grid item xs={6} sm={6} md={6} lg={1}>
													{utentiApplicativo.find(
														(utente) =>
															utente.user.id == iterConnessione.progettista
													)?.user.full_name || "-"}
												</Grid>
											</>
										)}
									</>
								)}
						</Grid>
					</ListItemButton>
				))}

				<ListItem
					sx={{
						alignContent: "right",
						width: "100%",
						mt: 2,
						borderTopStyle: "solid",
						borderTopWidth: 1,
					}}
				>
					<Stack
						direction="row"
						justifyContent="space-between"
						alignItems="center"
						spacing={2}
						sx={{ width: "100%" }}
					>
						<Pagination
							count={iterConnessioni.num_pages}
							onChange={handlerPager}
							page={page}
						/>
						{statoIterId || clienteIterId ? (
							<Typography noWrap>
								Totale iter filtrati:{"  "}
								{iterConnessioni.count}
							</Typography>
						) : (
							<Typography noWrap>
								Totale iter:{"  "}
								{iterConnessioni.count}
							</Typography>
						)}

						<FormControl>
							<InputLabel id="demo-simple-select-label">Record</InputLabel>
							<Select
								labelId="demo-simple-select-label"
								id="demo-simple-select"
								value={numeroRecord.toString()}
								label="Age"
								onChange={handleChangeNumeroRecord}
								variant="outlined"
							>
								<MenuItem value={10}>10</MenuItem>
								<MenuItem value={20}>20</MenuItem>
								<MenuItem value={50}>50</MenuItem>
							</Select>
						</FormControl>
					</Stack>
				</ListItem>
			</List>
		</Box>
	);
};

export default IterConnessioniPage;
