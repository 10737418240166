import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";

import {
	ErrorStatusTypes,
	ErrorsStack,
	parseErrorMessage,
} from "../../common/errorsDeclarations";
import * as gestoriApi from "../../../api/anagrafiche/gestoriApi";
import { toast } from "react-toastify";

export interface Gestore {
	id?: number;
	ragione_sociale?: string;
	codici_distributore?: { id: number; codice_distributore: string }[];
	codice_distributore?: string;
	errorsStack?: ErrorsStack;
}

export interface Gestori {
	count: number;
	page: number;
	num_pages: number;
	next?: URL;
	previous?: URL;
	results: Gestore[];
	errorsStack: ErrorsStack;
}

export interface GestoriStrutturaState {
	gestori: Gestori;
}

const initialState: GestoriStrutturaState = {
	gestori: {
		count: 0,
		page: 0,
		num_pages: 0,
		next: undefined,
		previous: undefined,
		results: [],
		errorsStack: { status: ErrorStatusTypes.OK },
	},
};

export const fetchGestori = createAsyncThunk(
	"gestori/fetchGestori",
	async () => {
		return await gestoriApi.fetchGestori();
	}
);

export const getGestore = createAsyncThunk(
	"gestori/getGestore",
	async (gestoreId: number) => {
		return await gestoriApi.getGestore(gestoreId);
	}
);

export const saveGestore = createAsyncThunk(
	"gestori/saveGestore",
	async (gestoreToSave: Gestore, thunkApi) => {
		return await gestoriApi.saveGestore(gestoreToSave).then((response) => {
			thunkApi.dispatch(fetchGestori());
			return response;
		});
	}
);

export const deleteGestore = createAsyncThunk(
	"gestori/deleteGestore",
	async (gestoreToDelete: Gestore, thunkApi) => {
		return await gestoriApi.deleteGestore(gestoreToDelete);
	}
);

export const gestoriSlice = createSlice({
	name: "gestori",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// fetch Gestori
		builder.addCase(fetchGestori.pending, (state, action) => {
			state.gestori.errorsStack = { status: ErrorStatusTypes.PENDING };
		});
		builder.addCase(fetchGestori.fulfilled, (state, action) => {
			state.gestori = action.payload;
			state.gestori.errorsStack = { status: ErrorStatusTypes.OK };
		});
		builder.addCase(fetchGestori.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");
			state.gestori.errorsStack = {
				status: ErrorStatusTypes.ERROR,
				fieldsErrors: JSON.parse(action?.error?.message || ""),
			};
		});

		// get Gestore
		builder.addCase(getGestore.pending, (state, action) => {
			state.gestori.errorsStack = { status: ErrorStatusTypes.PENDING };
		});
		builder.addCase(getGestore.fulfilled, (state, action) => {
			state.gestori.results = state.gestori.results.map((gestore) => {
				if (gestore.id == action.payload.id) {
					return action.payload;
				} else {
					return gestore;
				}
			});
			state.gestori.errorsStack = { status: ErrorStatusTypes.OK };
		});
		builder.addCase(getGestore.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");
			state.gestori.errorsStack = {
				status: ErrorStatusTypes.ERROR,
				fieldsErrors: JSON.parse(action?.error?.message || ""),
			};
		});

		// save Gestore
		builder.addCase(saveGestore.pending, (state, action) => {
			state.gestori.errorsStack = { status: ErrorStatusTypes.PENDING };
		});
		builder.addCase(saveGestore.fulfilled, (state, action) => {
			state.gestori.results = state.gestori.results.map((gestore) => {
				if (gestore.id == action.payload.id) {
					return action.payload;
				} else {
					return gestore;
				}
			});
			state.gestori.errorsStack = { status: ErrorStatusTypes.SUCCESS };
			toast.success("Gestore salvato.");
		});
		builder.addCase(saveGestore.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");

			state.gestori.results = state.gestori.results.map((gestore) => {
				if (gestore.id == action.meta.arg.id) {
					return {
						...gestore,
						errorsStack: {
							status: ErrorStatusTypes.ERROR,
							fieldsErrors: JSON.parse(action?.error?.message || ""),
						},
					};
				} else {
					return gestore;
				}
			});
			state.gestori.errorsStack = {
				status: ErrorStatusTypes.ERROR,
			};
		});
		// cancella Gestore
		builder.addCase(deleteGestore.pending, (state, action) => {
			state.gestori.errorsStack = { status: ErrorStatusTypes.PENDING };
		});
		builder.addCase(deleteGestore.fulfilled, (state, action) => {
			state.gestori.errorsStack = { status: ErrorStatusTypes.SUCCESS };
			state.gestori.results = state.gestori.results.filter(
				(gestore) => gestore.id != action.meta.arg.id
			);
			toast.success(action.payload.message || "Gestore cancellato.");
		});
		builder.addCase(deleteGestore.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");
			state.gestori.errorsStack = {
				status: ErrorStatusTypes.ERROR,
				fieldsErrors: JSON.parse(action?.error?.message || ""),
			};
		});
	},
});

// Action creators are generated for each case reducer function
export const {} = gestoriSlice.actions;

export const gestoriSliceReducer = gestoriSlice.reducer;
