import React, {useEffect, useState} from "react";

import {useAppDispatch} from "../../../../../redux/store";

import {utenteAutorizzato} from "../../../../authentication/UtenteAutorizzato";
import {PermissionTypes} from "../../../../authentication/permissionsGroups";

import {UtenteLogged} from "../../../../authentication/authenticationSlice";
import {
    IterConnessione,
    saveIterConnessione,
} from "../../iterConnessioneSlice";
import IterConnessioneSpalla from "../IterConnessioneSpalla";
import AllegatiPage from "../../../../allegati/AllegatiPage";
import DistintaProdottiBox from "./prodotti/DistintaProdottiBox";
import AzioniClienteCard from "./AzioniClienteCard";
import AzioniGestoreCard from "./AzioniGestoreCard";
import PodIterconnessioneForm from "./PodIterconnessioneForm";
import NuovoPodCommessaForm from "../../../commesse/commessa/podCommessa/NuovoPodCommessaForm";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {Box} from "@mui/system";
import {Button, CardHeader} from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Stack from "@mui/material/Stack";
import {Soggetto} from "components/anagrafiche/produttori/produttoriSlice";
import ConfermaAnnulla from "components/common/ConfermaAnnulla";

interface IterConnessioneDatiPodTabProps {
    iterConnessione: IterConnessione;
    utente: UtenteLogged;
    produttore: Soggetto;
    consentiModifica: boolean;
}

const IterConnessioneDatiPodTab = ({
                                       iterConnessione,
                                       utente,
                                       produttore,
                                       consentiModifica = false,
                                   }: IterConnessioneDatiPodTabProps) => {

    const dispatch = useAppDispatch();

    const [refresh, setRefresh] = useState<boolean>(false);

    useEffect(() => {
        setRefresh(false);
    }, [refresh]);

    // gestione chiusura modal su scelta "Annulla"
    const [openConferma, setOpenConferma] = useState(false);
    const handleConfermaClose = () => setOpenConferma(false);

    return (
        <Box>
            <Grid container spacing={2} sx={{mb: 4}}>
                <Grid item xs={12} sm={12} md={8} lg={9} order={{xs: 2, md: 1}}>
                    <Card>
                        <CardHeader
                            title={
                                <Stack
                                    spacing={2}
                                    direction="row"
                                    color="white"
                                    justifyContent="space-between"
                                >
                                    <Typography variant="h4">POD</Typography>

                                    {!!iterConnessione?.pod_commessa && (
                                        <Button
                                            variant="outlined"
                                            disabled={!consentiModifica}
                                            onClick={() => setOpenConferma(true)}
                                        >
                                            Cambia Pod
                                        </Button>
                                    )}
                                </Stack>
                            }
                            sx={{backgroundColor: "#22568e"}}
                        />
                        <CardContent sx={{backgroundColor: "#eeeeee"}}>
                            {!iterConnessione?.pod_commessa?.id && (
                                <NuovoPodCommessaForm
                                    iterConnessione={iterConnessione}
                                    consentiModifica={consentiModifica}
                                />
                            )}
                            {!!iterConnessione?.pod_commessa?.id && (
                                <PodIterconnessioneForm
                                    pod={iterConnessione?.pod_commessa}
                                    iterConnessione={iterConnessione}
                                    produttore={produttore}
                                    consentiModifica={consentiModifica}
                                />
                            )}
                            <ConfermaAnnulla
                                handleAnnulla={handleConfermaClose}
                                handleConferma={() => {
                                    !!iterConnessione &&
                                    dispatch(
                                        saveIterConnessione({
                                            iterConnessioneToSave: iterConnessione,
                                            azione: "cambia_pod",
                                        })
                                    );
                                    handleConfermaClose();
                                }}
                                domanda={
                                    "Sicuro di voler sganciare l'usufruttore dell'immobile da questo iter connessione (ricordati di aggiornare in maniera coerente i dati del POD"
                                }
                                open={openConferma}
                            />

                            {(iterConnessione?.ampliamento) && !!iterConnessione.pod_commessa?.id && (
                                <DistintaProdottiBox
                                    podCommessaId={iterConnessione.pod_commessa.id}
                                    iterConnessione={iterConnessione}
                                    consentiModifica={consentiModifica}
                                    preEsistente={true}
                                    prodotti={iterConnessione.pod_commessa.prodotti}
                                />
                            )}
                            {!!iterConnessione.pod_commessa?.id && (
                                <DistintaProdottiBox
                                    podCommessaId={iterConnessione.pod_commessa?.id}
                                    iterConnessione={iterConnessione}
                                    consentiModifica={consentiModifica}
                                    preEsistente={false}
                                    prodotti={iterConnessione.pod_commessa.prodotti}
                                />
                            )}
                        </CardContent>
                    </Card>

                    {!!iterConnessione.pod_commessa?.id && (
                        <AllegatiPage
                            faseIterConnessione="POD"
                            iterConnessioneId={iterConnessione.id || 0}
                            consentiModifica={
                                iterConnessione.stato != "N" || consentiModifica
                            }
                        />
                    )}
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3} order={{xs: 1, md: 2}}>
                    <IterConnessioneSpalla iterConnessione={iterConnessione}/>

                    {!iterConnessione.dati_pod_completo?.dati_pod_completo && (
                        <Alert severity="error" sx={{ mt: 1 }}>
                            <AlertTitle>Attenzione manca:</AlertTitle>
                            <Typography
                                variant="body2"
                                sx={{ whiteSpace: "pre-line" }}
                            >
                                {iterConnessione.dati_pod_completo?.dati_mancanti}
                            </Typography>
                        </Alert>
                    )}
                    {utenteAutorizzato(utente, [PermissionTypes.CLIENTI,]) &&
                        <AzioniClienteCard iterConnessione={iterConnessione}/>}
                    {utenteAutorizzato(utente, [PermissionTypes.GESTORI_ITER, PermissionTypes.OPERATORI,]) && (
                        <AzioniGestoreCard iterConnessione={iterConnessione}/>
                    )}
                </Grid>
            </Grid>
        </Box>
    );
};

export default IterConnessioneDatiPodTab;
