import { handleResponse, handleError, headerset } from "../apiUtils";
import { Soggetto } from "../../components/anagrafiche/produttori/produttoriSlice";
import { DateTime } from "luxon";
import { convertToDateTime } from "components/common/dateUtils/convertToDateTime";

const baseUrl = (
	produttore_id?: number | null,
	commessa_id_da_agganciare_come_contraente?: number,
	commessa_id_da_agganciare_come_titolare?: number,
	commessa_id_da_agganciare_come_produttore?: number,
	iterConnessione_id_da_agganciare_come_produttore?: number,
	iterConnessione_id_da_agganciare_come_usufruttore?: number,
	azione?: string
) => {
	if (produttore_id) {
		let url = `${process.env.API_URL}/api/anagrafiche/produttori/${produttore_id}/`;
		if (!!azione) {
			url = url + `?azione=${azione}`;
		}
		return url
	} else {
		if (commessa_id_da_agganciare_come_contraente) {
			return (
				process.env.API_URL +
				"/api/anagrafiche/produttori/?commessa_id_da_agganciare_come_contraente=" +
				commessa_id_da_agganciare_come_contraente
			);
		} else if (commessa_id_da_agganciare_come_titolare) {
			return (
				process.env.API_URL +
				"/api/anagrafiche/produttori/?commessa_id_da_agganciare_come_titolare=" +
				commessa_id_da_agganciare_come_titolare
			);
		} else if (commessa_id_da_agganciare_come_produttore) {
			return (
				process.env.API_URL +
				"/api/anagrafiche/produttori/?commessa_id_da_agganciare_come_produttore=" +
				commessa_id_da_agganciare_come_produttore
			);
		} else if (iterConnessione_id_da_agganciare_come_produttore) {
			return (
				process.env.API_URL +
				"/api/anagrafiche/produttori/?iterConnessione_id_da_agganciare_come_produttore=" +
				iterConnessione_id_da_agganciare_come_produttore
			);
		} else if (iterConnessione_id_da_agganciare_come_usufruttore) {
			return (
				process.env.API_URL +
				"/api/anagrafiche/produttori/?iterConnessione_id_da_agganciare_come_usufruttore=" +
				iterConnessione_id_da_agganciare_come_usufruttore
			);
		}
		return process.env.API_URL + "/api/anagrafiche/produttori/";
	}
};

export function fetchProduttori(
	numeroRecord?: number,
	page?: number,
	search?: string
) {
	let url = baseUrl();
	if (numeroRecord != undefined || search != undefined || page != undefined) {
		url += "?";
	}
	if (numeroRecord != undefined) {
		url += `numero_record=${numeroRecord}&`;
	}
	if (page != undefined) {
		url += `page=${page}&`;
	}
	if (search != undefined) {
		url += `search=${search}`;
	}

	return fetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}

export function getProduttore(produttoreId: number) {
	let url = baseUrl(produttoreId);
	return fetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}

export function saveProduttore(
	produttoreToSave: Soggetto,
	commessa_id_da_agganciare_come_contraente?: number,
	commessa_id_da_agganciare_come_titolare?: number,
	commessa_id_da_agganciare_come_produttore?: number,
	iterConnessione_id_da_agganciare_come_produttore?: number,
	iterConnessione_id_da_agganciare_come_usufruttore?: number,
	azione?: string
) {
	const data_nascita = produttoreToSave.data_nascita
		? convertToDateTime(produttoreToSave.data_nascita)?.setLocale("it").toFormat("yyyy-MM-dd")
		: null;
	const produttore = {
		...produttoreToSave,
		data_nascita: data_nascita,
	};

	return fetch(
		baseUrl(
			produttoreToSave.id,
			commessa_id_da_agganciare_come_contraente,
			commessa_id_da_agganciare_come_titolare,
			commessa_id_da_agganciare_come_produttore,
			iterConnessione_id_da_agganciare_come_produttore,
			iterConnessione_id_da_agganciare_come_usufruttore,
			azione
		),
		{
			method: produttoreToSave.id ? "PUT" : "POST", // PUT to update when id already exists.
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: headerset().Authorization,
			},
			body: JSON.stringify({
				...produttore,
				// Parse authorId to a number (in case it was sent as a string).
				// id: parseInt(progetto.id, 10),
			}),
		}
	)
		.then(handleResponse)
		.catch(handleError);
}

export function deleteProduttore(produttoreToDelete: Soggetto) {
	return fetch(baseUrl(produttoreToDelete.id), {
		method: "DELETE",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}
