import React from "react";

import {RootState, useAppDispatch,} from "../../../../../../redux/store";

import {Commessa, saveCommessa} from "../../../commesseSlice";
import OrdineProdottoForm from "./OrdineProdottoForm";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";

import {ErrorStatusTypes} from "../../../../../common/errorsDeclarations";
import CircularProgress from "@mui/material/CircularProgress";
import {useSelector} from "react-redux";

interface OrdineMaterialiCardProps {
    commessa: Commessa;
    consentiModifica: boolean;
}

const OrdineMaterialiCard = ({
                                 commessa,
                                 consentiModifica,
                             }: OrdineMaterialiCardProps) => {
    const dispatch = useAppDispatch();

    const commesseStatus = useSelector<RootState, ErrorStatusTypes>(
        (state) => state.commesseState.commesse.errorsStack.status
    );

    return (
        <Box
            sx={{
                backgroundColor: "#fff",
                elevation: 20,
                p: 2,
            }}
        >
            {commesseStatus == ErrorStatusTypes.PENDING && consentiModifica ?
                <CircularProgress size={100} color="info" />
                :
                <Box>
                    {commessa?.pod?.prodotti
                             ?.filter((prod) => prod.pre_esistente == false)
                             .map((prodotto, index) => (
                                 <Box key={prodotto.id}>
                                     <OrdineProdottoForm
                                         prodotto={prodotto}
                                         commessa={commessa}
                                         consentiModifica={consentiModifica}
                                         index={index}
                                     />
                                 </Box>
                             ))}
                    {commessa.stato == "D" &&
                        <Grid container spacing={2} width={"100%"} sx={{mb: 1}}>
                            <Grid item xs={12} sm={6} lg={10}></Grid>
                            <Grid item xs={12} sm={6} lg={2}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    disabled={!consentiModifica || !commessa.stato_completato}
                                    onClick={() => {
                                        dispatch(
                                            saveCommessa({
                                                commessaToSave: commessa,
                                                azione: "attiva_pianificazione_cantiere",
                                            })
                                        );
                                    }}
                                    sx={{width: "100%"}}
                                >
                                    Attiva Pianificazione Cantiere
                                </Button>
                            </Grid>
                        </Grid>
                    }
                </Box>

            }


        </Box>
    );
};

export default OrdineMaterialiCard;
