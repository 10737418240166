import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {convertToDateTime} from "components/common/dateUtils/convertToDateTime";

import {FineLavoro, saveFineLavoro} from "../fineLavoriSlice";
import {useAppDispatch, RootState} from "../../../../../../../redux/store";
import {
    ErrorStatusTypes,
    ErrorsStack,
} from "../../../../../../common/errorsDeclarations";

import {theme} from "../../../../../../theme";

import {UtenteLogged} from "../../../../../../authentication/authenticationSlice";
import {useForm, SubmitHandler, Controller} from "react-hook-form";

import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Alert from "../../../../../../common/Alert";
import Typography from "@mui/material/Typography";
import Autocomplete from "@mui/material/Autocomplete";
import ClockIcon from "@mui/icons-material/AccessTime";
import {DateTimePicker} from "@mui/x-date-pickers/DateTimePicker";
import {DesktopDatePicker} from "@mui/x-date-pickers/DesktopDatePicker";
import InputAdornment from "@mui/material/InputAdornment";

import {Button, Divider} from "@mui/material";
import IterConnessioneFineLavoroGaudiForm from "../gaudi/IterConnessioneFineLavoroGaudiForm";
import AllegatiPage from "../../../../../../allegati/AllegatiPage";

interface IterConnessioneFineLavoroFormProps {
    fineLavoro: FineLavoro;
    consentiModifica: boolean;
    iterSemplificato: boolean;
}

const IterConnessioneFineLavoroIterAutorizzativoForm = ({
                                                            fineLavoro,
                                                            consentiModifica = false,
                                                            iterSemplificato = true,
                                                        }: IterConnessioneFineLavoroFormProps) => {
    const {
        handleSubmit,
        register,
        formState: {errors, isDirty, isValid},
        setValue,
        watch,
        getValues,
        control,
        setError,
        clearErrors,
        reset,
    } = useForm<FineLavoro>();

    // const listaCampi = Object.keys(fineLavoro) as Array<keyof FineLavoro>;
    const listaCampi: (
        "iter_autorizzativo_data_avvio" |
        "iter_autorizzativo_data_conclusione" |
        "iter_autorizzativo_data_avvio_costrizione" |
        "iter_autorizzativo_data_completamento_costruzione" |
        "iter_autorizzativo_note"
        )[] = ["iter_autorizzativo_data_avvio",
        "iter_autorizzativo_data_conclusione",
        "iter_autorizzativo_data_avvio_costrizione",
        "iter_autorizzativo_data_completamento_costruzione",
        "iter_autorizzativo_note"]

    const dispatch = useAppDispatch();

    const [editing, setEditingControlled] = useState(false);

    const setEditing = (switchModifica: boolean) => {
        consentiModifica && setEditingControlled(switchModifica);
    };
    const utente = useSelector<RootState, UtenteLogged>(
        (state) => state.authentication.utenteLogged
    );

    useEffect(() => {
        reset();
        listaCampi.map((field) => {
            setValue(field, fineLavoro[field]);
        });
    }, []);

    useEffect(() => {
        clearErrors();
        listaCampi.forEach((field) => {
            fineLavoro?.errorsStack?.fieldsErrors &&
            fineLavoro.errorsStack.fieldsErrors[field] &&
            setError(field, {
                type: "server",
                message: fineLavoro.errorsStack.fieldsErrors[field].toString(),
            });
        });
    }, [
        fineLavoro?.errorsStack?.fieldsErrors,
        // iterConnessione.allevamento_pack?.errorsStack?.fieldsErrors,
    ]);

    useEffect(() => {
        fineLavoro?.errorsStack?.status == ErrorStatusTypes.SUCCESS &&
        setEditing(false);
    }, [
        fineLavoro,
        // iterConnessione.allevamento_pack?.errorsStack?.fieldsErrors,
    ]);

    const handlerSaveFineLavoro = (fineLavoroDaForm: FineLavoro) => {
        const formValues = getValues();
        consentiModifica &&
        dispatch(saveFineLavoro({fineLavoroToSave: {...fineLavoro, ...formValues}}));
    };

    const onSubmit: SubmitHandler<FineLavoro> = (fineLavoro) => {
        handlerSaveFineLavoro(fineLavoro);
    };

    return (

        <Card elevation={3} sx={{mb: 2}}>
            <CardHeader
                sx={{
                    backgroundColor: fineLavoro.iter_autorizzativo_completo.iter_autorizzativo_completo
                        ? "#00aa0099"
                        : "#aa000099",
                }}
                // sx={{backgroundColor: "#377199"}}


                title={"Iter Autorizzativo"}
            />
            <CardContent>
                <form onSubmit={handleSubmit(onSubmit)}>
                    {fineLavoro?.errorsStack?.fieldsErrors?.non_field_errors && (
                        <Alert severity="error">
                            {fineLavoro?.errorsStack?.fieldsErrors?.non_field_errors.toString()}
                        </Alert>
                    )}
                    <Grid container spacing={2} sx={{mb: 2}}>
                        <Grid item xs={12} sm={6} md={6} lg={4}>
                            <Typography
                                variant="caption"
                                component="div"
                                color="text.secondary"
                                gutterBottom
                                onClick={() => setEditing(true)}
                            >
                                data avvio*:
                            </Typography>

                            {!editing ? (
                                <Typography
                                    variant="body1"
                                    component="div"
                                    // color="text.secondary"
                                    gutterBottom
                                    onClick={() => setEditing(true)}
                                    sx={{
                                        color: fineLavoro.iter_autorizzativo_data_avvio
                                            ? theme.palette.text.secondary
                                            : theme.palette.divider,
                                    }}
                                >
                                    {fineLavoro.iter_autorizzativo_data_avvio
                                        ? convertToDateTime(fineLavoro.iter_autorizzativo_data_avvio)?.setLocale("it").toFormat("EEEE dd LLLL yyyy")
                                        : "inserisci data avvio"}
                                </Typography>
                            ) : (
                                <DesktopDatePicker
                                    value={convertToDateTime(fineLavoro.iter_autorizzativo_data_avvio)}
                                    slotProps={{
                                        textField: {
                                            required: false,
                                            error: !!errors?.iter_autorizzativo_data_avvio,
                                            helperText: errors?.iter_autorizzativo_data_avvio?.message,
                                            sx: {width: "100%"},
                                            autoComplete: "off",
                                            // disabled: !!richiestaConnessione.stato
                                        },
                                        field: {
                                            clearable: true,
                                        },
                                    }}
                                    onChange={(newValue: any) => {
                                        setEditing(true);
                                        setValue(
                                            "iter_autorizzativo_data_avvio",
                                            newValue ? convertToDateTime(newValue)?.toFormat("yyyy-MM-dd") : "",
                                            {
                                                shouldDirty: true,
                                            }
                                        );
                                        clearErrors("iter_autorizzativo_data_avvio");
                                    }}
                                />
                            )}
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={4}>
                            <Typography
                                variant="caption"
                                component="div"
                                color="text.secondary"
                                gutterBottom
                                onClick={() => setEditing(true)}
                            >
                                data conclusione.*:
                            </Typography>

                            {!editing ? (
                                <Typography
                                    variant="body1"
                                    component="div"
                                    // color="text.secondary"
                                    gutterBottom
                                    onClick={() => setEditing(true)}
                                    sx={{
                                        color: fineLavoro.iter_autorizzativo_data_conclusione
                                            ? theme.palette.text.secondary
                                            : theme.palette.divider,
                                    }}
                                >
                                    {fineLavoro.iter_autorizzativo_data_conclusione
                                        ? convertToDateTime(fineLavoro.iter_autorizzativo_data_conclusione)?.setLocale("it").toFormat("EEEE dd LLLL yyyy")
                                        : "inserisci data conclusione"}
                                </Typography>
                            ) : (
                                <DesktopDatePicker
                                    value={convertToDateTime(fineLavoro.iter_autorizzativo_data_conclusione)}
                                    slotProps={{
                                        textField: {
                                            required: false,
                                            error: !!errors?.iter_autorizzativo_data_conclusione,
                                            helperText: errors?.iter_autorizzativo_data_conclusione?.message,
                                            sx: {width: "100%"},
                                            autoComplete: "off",
                                            // disabled: !!richiestaConnessione.stato
                                        },
                                        field: {
                                            clearable: true,
                                        },
                                    }}
                                    onChange={(newValue: any) => {
                                        setEditing(true);
                                        setValue(
                                            "iter_autorizzativo_data_conclusione",
                                            newValue ? convertToDateTime(newValue)?.toFormat("yyyy-MM-dd") : "",
                                            {
                                                shouldDirty: true,
                                            }
                                        );
                                        clearErrors("iter_autorizzativo_data_conclusione");
                                    }}
                                />
                            )}
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={4}></Grid>
                        <Grid item xs={12} sm={6} md={6} lg={4}>
                            <Typography
                                variant="caption"
                                component="div"
                                color="text.secondary"
                                gutterBottom
                                onClick={() => setEditing(true)}
                            >
                                data avvio costruzione.*:
                            </Typography>

                            {!editing ? (
                                <Typography
                                    variant="body1"
                                    component="div"
                                    // color="text.secondary"
                                    gutterBottom
                                    onClick={() => setEditing(true)}
                                    sx={{
                                        color:
                                            fineLavoro.iter_autorizzativo_data_avvio_costrizione
                                                ? theme.palette.text.secondary
                                                : theme.palette.divider,
                                    }}
                                >
                                    {fineLavoro.iter_autorizzativo_data_avvio_costrizione
                                        ? convertToDateTime(fineLavoro.iter_autorizzativo_data_avvio_costrizione)?.setLocale("it").toFormat("EEEE dd LLLL yyyy")
                                        : "inserisci data avvio costrizione"}
                                </Typography>
                            ) : (
                                <DesktopDatePicker
                                    value={convertToDateTime(fineLavoro.iter_autorizzativo_data_avvio_costrizione)}
                                    slotProps={{
                                        textField: {
                                            required: false,
                                            error: !!errors?.iter_autorizzativo_data_avvio_costrizione,
                                            helperText: errors?.iter_autorizzativo_data_avvio_costrizione?.message,
                                            sx: {width: "100%"},
                                            autoComplete: "off",
                                            // disabled: !!richiestaConnessione.stato
                                        },
                                        field: {
                                            clearable: true,
                                        },
                                    }}
                                    onChange={(newValue: any) => {
                                        setEditing(true);
                                        setValue(
                                            "iter_autorizzativo_data_avvio_costrizione",
                                            newValue ? convertToDateTime(newValue)?.toFormat("yyyy-MM-dd") : "",
                                            {
                                                shouldDirty: true,
                                            }
                                        );
                                        clearErrors("iter_autorizzativo_data_avvio_costrizione");
                                    }}
                                />
                            )}
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={4}>
                            <Typography
                                variant="caption"
                                component="div"
                                color="text.secondary"
                                gutterBottom
                                onClick={() => setEditing(true)}
                            >
                                data completamento costruzione.*:
                            </Typography>

                            {!editing ? (
                                <Typography
                                    variant="body1"
                                    component="div"
                                    // color="text.secondary"
                                    gutterBottom
                                    onClick={() => setEditing(true)}
                                    sx={{
                                        color:
                                            fineLavoro.iter_autorizzativo_data_completamento_costruzione
                                                ? theme.palette.text.secondary
                                                : theme.palette.divider,
                                    }}
                                >
                                    {fineLavoro.iter_autorizzativo_data_completamento_costruzione
                                        ? convertToDateTime(fineLavoro.iter_autorizzativo_data_completamento_costruzione)?.setLocale("it").toFormat("EEEE dd LLLL yyyy")
                                        : "inserisci data completamento costrizione"}
                                </Typography>
                            ) : (
                                <DesktopDatePicker
                                    value={convertToDateTime(fineLavoro.iter_autorizzativo_data_completamento_costruzione)}
                                    slotProps={{
                                        textField: {
                                            required: false,
                                            error: !!errors?.iter_autorizzativo_data_completamento_costruzione,
                                            helperText: errors?.iter_autorizzativo_data_completamento_costruzione?.message,
                                            sx: {width: "100%"},
                                            autoComplete: "off",
                                            // disabled: !!richiestaConnessione.stato
                                        },
                                        field: {
                                            clearable: true,
                                        },
                                    }}
                                    onChange={(newValue: any) => {
                                        setEditing(true);
                                        setValue(
                                            "iter_autorizzativo_data_completamento_costruzione",
                                            newValue ? convertToDateTime(newValue)?.toFormat("yyyy-MM-dd") : "",
                                            {
                                                shouldDirty: true,
                                            }
                                        );
                                        clearErrors(
                                            "iter_autorizzativo_data_completamento_costruzione"
                                        );
                                    }}
                                />
                            )}
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={4}></Grid>
                        <Grid item xs={12} sm={6} md={6} lg={12}>
                            <Typography
                                variant="caption"
                                component="div"
                                color="text.secondary"
                                gutterBottom
                                onClick={() => setEditing(true)}
                            >
                                note:
                            </Typography>

                            {!editing ? (
                                <Typography
                                    variant="body1"
                                    component="div"
                                    // color="text.secondary"
                                    gutterBottom
                                    onClick={() => setEditing(true)}
                                >
                                    {fineLavoro.iter_autorizzativo_note}
                                </Typography>
                            ) : (
                                <TextField
                                    // label="Motivo Rigetto"
                                    // disabled={schedaCarico.numero_capi_pianificati}
                                    variant="outlined"
                                    // type="number"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    {...register("iter_autorizzativo_note")}
                                    error={!!errors?.iter_autorizzativo_note}
                                    helperText={errors?.iter_autorizzativo_note?.message?.toString()}
                                    autoComplete={"off"}
                                    multiline={true}
                                    rows={3}
                                    sx={{width: "100%"}}
                                    fullWidth
                                    // onBlur={handlerDetailChaingSubmit}
                                />
                            )}
                        </Grid>
                    </Grid>
                    <Button
                        // disabled={!isDirty}
                        variant="outlined"
                        onClick={() => {
                            setEditing(false);
                            // reset();
                        }}
                        sx={{...(!editing && {display: "none"})}}
                    >
                        Annulla
                    </Button>
                    <Button
                        disabled={!isDirty}
                        variant="outlined"
                        type="submit"
                        sx={{...(!editing && {display: "none"}), ml: 1}}
                    >
                        Salva
                    </Button>
                </form>
            </CardContent>

            <CardContent>
                <AllegatiPage
                    // listaDocumentiPerTipo={richiestaConnessione.listaDocumentiPerTipo}
                    iterConnessioneId={fineLavoro.iter_connessione || 0}
                    fineLavoriId={fineLavoro.id}
                    faseIterConnessione="ITA"
                    // richiestaConnessioneId={richiestaConnessione.id || 0}
                    consentiModifica={consentiModifica}
                />

            </CardContent>

        </Card>


    );
};

export default IterConnessioneFineLavoroIterAutorizzativoForm;
