import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {useLocation, Link} from "react-router-dom";

import {RootState, useAppDispatch} from "../../../../redux/store";


import {LettoreCB, saveLettoreCB, getLettoreCB, deleteElementoProdotto} from "./lettoreCBSlice";

import {utenteAutorizzato} from "../../../authentication/UtenteAutorizzato";
import {UtenteLogged} from "../../../authentication/authenticationSlice";
import LettoreCBForm from "./LettoreCBForm";

// import CommessaCercaCreaForm from "./CommessaCercaCreaForm";
import LettoreCBreadcrumbs from "./LettoreCBreadcrumbs";
import Typography from "@mui/material/Typography";
import {Box} from "@mui/system";

import Stack from "@mui/material/Stack";
import {theme} from "../../../theme";
import Chip from "@mui/material/Chip";
import {ErrorStatusTypes} from "../../../common/errorsDeclarations";
import CircularProgress from "@mui/material/CircularProgress";

const LettoreCBPage = () => {
    const dispatch = useAppDispatch();

    // useEffect(() => {
    // 	dispatch(fetchCommesse());
    // }, []);

    const location = useLocation();

    const utente = useSelector<RootState, UtenteLogged>(
        (state) => state.authentication.utenteLogged
    );

    const lettoreCB = useSelector<RootState, LettoreCB>(
        (state) => state.lettoreCBSlice.lettoreCB
    );

    const handlerAzione = (azione: string) => {
        // setCodeBar(commessa);
        const nuovoLettoreCB: LettoreCB = {...lettoreCB, codeBar: azione};
        dispatch(saveLettoreCB(nuovoLettoreCB));
    };

    // useEffect(() => {
    // 	dispatch(getLettoreCB());
    // }, [utente, postazioni]);

    return (
        <>
            <Box sx={{display: {xs: "none", sm: "none", md: "block"}}}>
                <LettoreCBreadcrumbs/>
            </Box>
            <Box
                sx={{
                    backgroundColor: "#fff",
                    elevation: 20,
                    p: 0,
                    maxWidth: "sm",
                    pb: 1,
                }}
            >
                <Stack
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={0.5}
                >
                    <Typography variant="h6" component="div" color="text.secondary">
                        {lettoreCB.prodotto?.descrizione || "prodotto non attribuito"}
                    </Typography>
                    <Typography
                        variant="h6"
                        component="div"
                        color="text.secondary"
                        sx={{m: 0, p: 0}}
                    >
                        {`${lettoreCB.prodotto?.quantita_istanziata}/${lettoreCB.prodotto?.quantita_prevista}`}

                    </Typography>
                    {lettoreCB.errorsStack?.status === ErrorStatusTypes.PENDING ?
                        <CircularProgress />
                        :
                        <LettoreCBForm lettoreCB={lettoreCB}/>

                    }

                    <Stack direction="column" justifyContent="flex-start"
                           alignItems="center" spacing={0.5}>
                        {lettoreCB.prodotto?.elementi.map(elemento =>

                            <Chip
                                key={`elemento_${elemento.id}`}
                                label={elemento.matricola}
                                color="primary"
                                variant="outlined"
                                sx={{width: "100%"}}
                                onDelete={()=>{dispatch(deleteElementoProdotto(elemento))}}
                            />
                        )}


                    </Stack>
                </Stack>

            </Box>
        </>
    );
};

export default LettoreCBPage;
