import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import {
	ErrorStatusTypes,
	ErrorsStack, parseErrorMessage,
} from "../../../../common/errorsDeclarations";
import * as progettiApi from "../../../../../api/iterConnessioni/progettiApi";

import { toast } from "react-toastify";
import { DocumentiPerTipo } from "../../../../allegati/allegatiSlice";
import { getIterConnessione } from "../../iterConnessioneSlice";

// import { useDispatch } from "react-redux";



export enum TipoSchemaTypes {
	HYB = "Lato produzione DC",
	PRD = "Lato produzione AC",
	POS = "Lato post produzione AC",
}

export enum SistemaAccumuloTypes {
	AC = "Alternating Current",
	DC = "Direct Current",
}

export enum AlimentazioneSdaTypes {
	FR = "FV/Rete",
	F = "FV",
}
export interface SezioneProgetto {
	id?: number | null | undefined;
	progetto?: number;
	potenza_generatore?: number;
	potenza_inverter?: number;
	contrib_ICC?: number;
	potenza_cc_inverter?: number;
	potenza_nominale_sda?: number;
	potenza_cc_sda?: number;
	capacita?: number;
	tensione_nominale_sda?: number;
	tipo_schema?: "HYB" | "PRD" | "POS";
	errorsStack?: ErrorsStack;
}
export interface SopralluoghoProgetto {
	id: number;
	stato: string;
	iter_connessione: number;
	operatore?: number;
	data_sopralluogo?: string;
}
export interface Progetto {
	id: number;
	iter_connessione?: number;
	potenza_nominale?: number;
	potenza_immessa?: number;
	progetto_completo: {
		progetto_completo: boolean;
		dati_mancanti: string;
	};
	data_progetto?: Date | string;

	progetto_rigettato: boolean;
	data_rigetto?: Date | string;
	note_rilavorazione: string;
	listaDocumentiPerTipo?: DocumentiPerTipo[];
	sezioni: SezioneProgetto[];
	sopralluoghi: SopralluoghoProgetto[];
	errorsStack?: ErrorsStack;
}
export interface ProgettiState {
	count: number;
	page: number;
	num_pages: number;
	next?: URL;
	previous?: URL;
	results: Progetto[];
	errorsStack: ErrorsStack;
}
export interface ProgettiStrutturaState {
	progetti: ProgettiState;
}

const initialState: ProgettiStrutturaState = {
	progetti: {
		count: 0,
		page: 0,
		num_pages: 0,
		next: undefined,
		previous: undefined,
		results: [],
		errorsStack: { status: ErrorStatusTypes.OK },
	},
};

export const fetchProgetti = createAsyncThunk(
	"progetti/fetchProgetti",
	async () => {
		return await progettiApi.fetchProgetti();
	}
);

export const getProgetto = createAsyncThunk(
	"progetti/getProgetto",
	async (progettoId: number) => {
		return await progettiApi.getProgetto(progettoId);
	}
);

export const getProgettoFromIter = createAsyncThunk(
	"progetti/getProgettoFromIter",
	async (iterId: number) => {
		return await progettiApi.getProgettoFromIter(iterId);
	}
);

export const saveProgetto = createAsyncThunk(
	"progetti/saveProgetto",
	async (
		parametri: {
			progettoToSave: Progetto;
			azione?: string;
		},
		thunkApi
	) => {
		return await progettiApi
			.saveProgetto(parametri.progettoToSave, parametri.azione)
			.then((response) => {
				parametri.azione == "rigetta" &&
					thunkApi.dispatch(
						getProgettoFromIter(parametri.progettoToSave.iter_connessione || 0)
					);
				return response;
			});
	}
);

export const deleteProgetto = createAsyncThunk(
	"progetti/deleteProgetto",
	async (progettoToDelete: Progetto, thunkApi) => {
		return await progettiApi.deleteProgetto(progettoToDelete);
	}
);

export const deleteSezione = createAsyncThunk(
	"progetti/deleteSezione",
	async (sezioneToDelete: SezioneProgetto, thunkApi) => {
		return await progettiApi.deleteSezione(sezioneToDelete);
	}
);

export const saveSezioneProgetto = createAsyncThunk(
	"progetti/saveSezioneProgetto",
	async (
		parametri: { sezioneProgettoToSave: SezioneProgetto; progettoId: number },
		thunkApi
	) => {
		return await progettiApi.saveSezioneProgetto(
			parametri.sezioneProgettoToSave,
			parametri.progettoId
		);
	}
);

export const chiudiProgetto = createAsyncThunk(
	"progetti/chiudiProgetto",
	async (progetto: Progetto, thunkApi) => {
		return await progettiApi
			.chiudiProgetto(progetto.id || 0)
			.then((response) => {
				progetto.iter_connessione &&
					thunkApi.dispatch(
						getIterConnessione({ iterConnessioneId: progetto.iter_connessione })
					);
				return response;
			});
	}
);

export const creaSopralluogoProgetto = createAsyncThunk(
	"progetti/creaSopralluogoProgetto",
	async (progetto: Progetto, thunkApi) => {
		return await progettiApi
			.creaSopralluogoProgetto(progetto.id || 0)
			.then((response) => {
				progetto.iter_connessione &&
					thunkApi.dispatch(
						getIterConnessione({ iterConnessioneId: progetto.iter_connessione })
					);
				return response;
			});
	}
);

export const progettiSlice = createSlice({
	name: "progettiState",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// fetch Progetti
		builder.addCase(fetchProgetti.pending, (state, action) => {
			state.progetti.errorsStack = { status: ErrorStatusTypes.PENDING };
		});
		builder.addCase(fetchProgetti.fulfilled, (state, action) => {
			state.progetti = action.payload;
			state.progetti.errorsStack = { status: ErrorStatusTypes.OK };
		});
		builder.addCase(fetchProgetti.rejected, (state, action) => {
			state.progetti.errorsStack = parseErrorMessage(action.error);
		});

		// get Progetto
		builder.addCase(getProgetto.pending, (state, action) => {
			state.progetti.errorsStack = { status: ErrorStatusTypes.PENDING };
		});
		builder.addCase(getProgetto.fulfilled, (state, action) => {
			state.progetti.results = state.progetti.results.filter(
				(progetto) => progetto.id != action.payload.id
			);
			state.progetti.results.push(action.payload);

			state.progetti.errorsStack = { status: ErrorStatusTypes.OK };
		});
		builder.addCase(getProgetto.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");
			state.progetti.errorsStack =parseErrorMessage(action.error);
		});

		// get Progetto from Iter
		builder.addCase(getProgettoFromIter.pending, (state, action) => {
			state.progetti.errorsStack = { status: ErrorStatusTypes.PENDING };
		});
		builder.addCase(getProgettoFromIter.fulfilled, (state, action) => {
			state.progetti = action.payload;

			state.progetti.errorsStack = { status: ErrorStatusTypes.OK };
		});
		builder.addCase(getProgettoFromIter.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");
			state.progetti.errorsStack = parseErrorMessage(action.error);
		});

		// save Progetto
		builder.addCase(saveProgetto.pending, (state, action) => {
			state.progetti.errorsStack = { status: ErrorStatusTypes.PENDING };
		});
		builder.addCase(saveProgetto.fulfilled, (state, action) => {
			state.progetti.results = state.progetti.results.filter(
				(progetto) => progetto.id != action.payload.id
			);
			state.progetti.results.push({
				...action.payload,
				errorsStack: { status: ErrorStatusTypes.SUCCESS },
			});
			state.progetti.errorsStack = { status: ErrorStatusTypes.SUCCESS };
			toast.success("Progetto salvato.");
		});
		builder.addCase(saveProgetto.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");

			state.progetti.results = state.progetti.results.map((progetto) => {
				if (progetto.id == action.meta.arg.progettoToSave.id) {
					return {
						...progetto,
						errorsStack: parseErrorMessage(action.error)
					};
				} else {
					return progetto;
				}
			});
			state.progetti.errorsStack = parseErrorMessage(action.error);
		});
		// cancella Progetto
		builder.addCase(deleteProgetto.pending, (state, action) => {
			state.progetti.errorsStack = { status: ErrorStatusTypes.PENDING };
		});
		builder.addCase(deleteProgetto.fulfilled, (state, action) => {
			state.progetti.errorsStack = { status: ErrorStatusTypes.SUCCESS };
			state.progetti.results = state.progetti.results.filter(
				(progetto) => progetto.id != action.meta.arg.id
			);
			toast.success(action.payload.message || "Progetto cancellato.");
		});
		builder.addCase(deleteProgetto.rejected, (state, action) => {
			state.progetti.errorsStack =parseErrorMessage(action.error);
		});

		// cancella Sezione
		builder.addCase(deleteSezione.pending, (state, action) => {
			state.progetti.errorsStack = { status: ErrorStatusTypes.PENDING };
		});
		builder.addCase(deleteSezione.fulfilled, (state, action) => {
			state.progetti.errorsStack = { status: ErrorStatusTypes.SUCCESS };
			state.progetti.results = state.progetti.results.map((progetto) => {
				const sezionifiltrate = progetto.sezioni?.filter(
					(s) => s.id != action.meta.arg.id
				);
				return { ...progetto, sezioni: sezionifiltrate };
			});
			toast.success(action.payload.message || "Sezione cancellata.");
		});
		builder.addCase(deleteSezione.rejected, (state, action) => {
			state.progetti.errorsStack = parseErrorMessage(action.error);
		});

		// save Sezione Progetto
		builder.addCase(saveSezioneProgetto.pending, (state, action) => {
			state.progetti.errorsStack = { status: ErrorStatusTypes.PENDING };
		});
		builder.addCase(saveSezioneProgetto.fulfilled, (state, action) => {
			state.progetti.results = state.progetti.results.filter(
				(progetto) => progetto.id != action.payload.id
			);
			state.progetti.results.push({
				...action.payload,
				errorsStack: { status: ErrorStatusTypes.SUCCESS },
			});
			state.progetti.errorsStack = { status: ErrorStatusTypes.SUCCESS };
			toast.success("Sezione inserita.");
		});
		builder.addCase(saveSezioneProgetto.rejected, (state, action) => {
			state.progetti.results = state.progetti.results.map((iterConnessione) => {
				if (iterConnessione.id == action.meta.arg.progettoId) {
					return {
						...iterConnessione,
						errorsStack: parseErrorMessage(action.error)
					};
				} else {
					return iterConnessione;
				}
			});
			state.progetti.errorsStack = parseErrorMessage(action.error);
		});
		// chiudi progetto **************************
		builder.addCase(chiudiProgetto.pending, (state, action) => {
			state.progetti.errorsStack = { status: ErrorStatusTypes.PENDING };
		});
		builder.addCase(chiudiProgetto.fulfilled, (state, action) => {
			state.progetti.results = state.progetti.results.filter(
				(progetto) => progetto.id != action.payload.id
			);
			state.progetti.results.push({
				...action.payload,
				errorsStack: { status: ErrorStatusTypes.SUCCESS },
			});
			state.progetti.errorsStack = { status: ErrorStatusTypes.SUCCESS };
			toast.success("Progetto Completato.");
		});
		builder.addCase(chiudiProgetto.rejected, (state, action) => {
			state.progetti.errorsStack =parseErrorMessage(action.error);
		});
		// creaSopralluogoProgetto **************************
		builder.addCase(creaSopralluogoProgetto.pending, (state, action) => {
			state.progetti.errorsStack = { status: ErrorStatusTypes.PENDING };
		});
		builder.addCase(creaSopralluogoProgetto.fulfilled, (state, action) => {
			state.progetti.results = state.progetti.results.filter(
				(progetto) => progetto.id != action.payload.id
			);
			state.progetti.results.push({
				...action.payload,
				errorsStack: { status: ErrorStatusTypes.SUCCESS },
			});
			state.progetti.errorsStack = { status: ErrorStatusTypes.SUCCESS };
			toast.success("Progetto Completato.");
		});
		builder.addCase(creaSopralluogoProgetto.rejected, (state, action) => {
			state.progetti.errorsStack = parseErrorMessage(action.error);
		});
	},
});

// Action creators are generated for each case reducer function
export const {} = progettiSlice.actions;

export const progettiSliceReducer = progettiSlice.reducer;
