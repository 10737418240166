import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";

import {
	ErrorStatusTypes,
	ErrorsStack,
	parseErrorMessage,
} from "../../common/errorsDeclarations";
import * as tipiTensioneApi from "../../../api/anagrafiche/tipiTensioneApi";
import { toast } from "react-toastify";

export interface TipoTensione {
	id?: number;
	nome?: string;
	descrizione?: string;
	errorsStack?: ErrorsStack;
}

export interface TipiTensione {
	count: number;
	page: number;
	num_pages: number;
	next?: URL;
	previous?: URL;
	results: TipoTensione[];
	errorsStack: ErrorsStack;
}

export interface TipiTensioneStrutturaState {
	tipiTensione: TipiTensione;
}

const initialState: TipiTensioneStrutturaState = {
	tipiTensione: {
		count: 0,
		page: 0,
		num_pages: 0,
		next: undefined,
		previous: undefined,
		results: [],
		errorsStack: { status: ErrorStatusTypes.OK },
	},
};

export const fetchTipiTensione = createAsyncThunk(
	"tipiTensione/fetchTipiTensione",
	async () => {
		return await tipiTensioneApi.fetchTipiTensione();
	}
);

export const getTipoTensione = createAsyncThunk(
	"tipiTensione/getTipoTensione",
	async (tipoTensioneId: number) => {
		return await tipiTensioneApi.getTipoTensione(tipoTensioneId);
	}
);

export const saveTipoTensione = createAsyncThunk(
	"tipiTensione/saveTipoTensione",
	async (tipoTensioneToSave: TipoTensione, thunkApi) => {
		return await tipiTensioneApi
			.saveTipoTensione(tipoTensioneToSave)
			.then((response) => {
				thunkApi.dispatch(fetchTipiTensione());
				return response;
			});
	}
);

export const deleteTipoTensione = createAsyncThunk(
	"tipiTensione/deleteTipoTensione",
	async (tipoTensioneToDelete: TipoTensione, thunkApi) => {
		return await tipiTensioneApi.deleteTipoTensione(tipoTensioneToDelete);
	}
);

export const tipiTensioneSlice = createSlice({
	name: "tipiTensione",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// fetch Tipi Tensione
		builder.addCase(fetchTipiTensione.pending, (state, action) => {
			state.tipiTensione.errorsStack = {
				status: ErrorStatusTypes.PENDING,
			};
		});
		builder.addCase(fetchTipiTensione.fulfilled, (state, action) => {
			state.tipiTensione = action.payload;

			state.tipiTensione.errorsStack = { status: ErrorStatusTypes.OK };
		});
		builder.addCase(fetchTipiTensione.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");
			state.tipiTensione.errorsStack = {
				status: ErrorStatusTypes.ERROR,
				fieldsErrors: JSON.parse(action?.error?.message || ""),
			};
		});

		// get Tipo Tensione
		builder.addCase(getTipoTensione.pending, (state, action) => {
			state.tipiTensione.errorsStack = {
				status: ErrorStatusTypes.PENDING,
			};
		});
		builder.addCase(getTipoTensione.fulfilled, (state, action) => {
			state.tipiTensione.results = state.tipiTensione.results.map(
				(tipoTensione) => {
					if (tipoTensione.id == action.payload.id) {
						return action.payload;
					} else {
						return tipoTensione;
					}
				}
			);
			state.tipiTensione.errorsStack = { status: ErrorStatusTypes.OK };
		});
		builder.addCase(getTipoTensione.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");
			state.tipiTensione.errorsStack = {
				status: ErrorStatusTypes.ERROR,
				fieldsErrors: JSON.parse(action?.error?.message || ""),
			};
		});

		// save Tipo Tensione
		builder.addCase(saveTipoTensione.pending, (state, action) => {
			state.tipiTensione.errorsStack = {
				status: ErrorStatusTypes.PENDING,
			};
		});
		builder.addCase(saveTipoTensione.fulfilled, (state, action) => {
			state.tipiTensione.results = state.tipiTensione.results.map(
				(tipoTensione) => {
					if (tipoTensione.id == action.payload.id) {
						return {
							...action.payload,
							errorsStack: {
								status: ErrorStatusTypes.OK,
							},
						};
					} else {
						return tipoTensione;
					}
				}
			);
			state.tipiTensione.errorsStack = {
				status: ErrorStatusTypes.SUCCESS,
			};
			toast.success("Tipo Tensione salvato.");
		});
		builder.addCase(saveTipoTensione.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");

			state.tipiTensione.results = state.tipiTensione.results.map(
				(tipoTensione) => {
					if (tipoTensione.id == action.meta.arg.id) {
						return {
							...tipoTensione,
							errorsStack: {
								status: ErrorStatusTypes.ERROR,
								fieldsErrors: JSON.parse(action?.error?.message || ""),
							},
						};
					} else {
						return tipoTensione;
					}
				}
			);
			state.tipiTensione.errorsStack = {
				status: ErrorStatusTypes.ERROR,
				fieldsErrors: JSON.parse(action?.error?.message || ""),
			};
		});
		// cancella Tipo Tensione
		builder.addCase(deleteTipoTensione.pending, (state, action) => {
			state.tipiTensione.errorsStack = {
				status: ErrorStatusTypes.PENDING,
			};
		});
		builder.addCase(deleteTipoTensione.fulfilled, (state, action) => {
			state.tipiTensione.errorsStack = {
				status: ErrorStatusTypes.SUCCESS,
			};
			state.tipiTensione.results = state.tipiTensione.results.filter(
				(tipoTensione) => tipoTensione.id != action.meta.arg.id
			);
			toast.success(action.payload.message || "Tipo Tensione cancellato.");
		});
		builder.addCase(deleteTipoTensione.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");
			state.tipiTensione.errorsStack = {
				status: ErrorStatusTypes.ERROR,
				fieldsErrors: JSON.parse(action?.error?.message || ""),
			};
		});
	},
});

// Action creators are generated for each case reducer function
export const {} = tipiTensioneSlice.actions;

export const tipiTensioneSliceReducer = tipiTensioneSlice.reducer;
