import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, Link, useNavigate } from "react-router-dom";

import { RootState, useAppDispatch } from "../../../redux/store";
import { Routes_path } from "../../routerApp";

import { DateTime } from "luxon";

import TipiDocumentoPageBreadcrumbs from "./TipiDocumentoPageBreadcrumbs";
import {
	TipiDocumento,
	TipoDocumento,
	fetchTipiDocumento,
	TipoFaseIterConnessioneTypes,
	TipoFaseCommessaTypes,
	saveTipoDocumento,
} from "../allegatiSlice";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";
import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import Pagination from "@mui/material/Pagination";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import SearchIcon from "@mui/icons-material/Search";

const TipiDocumentoPage = () => {
	
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const location = useLocation();

	const tipiDocumento: TipiDocumento = useSelector<RootState, TipiDocumento>(
		(state) => state.allegati.tipiDocumento
	);

	const [page, setPage] = useState<number>(1);
	const [numeroRecord, setNumeroRecord] = useState<number>(10);
	const [search, setSearch] = useState<string | undefined>(undefined);

	useEffect(() => {
		dispatch(fetchTipiDocumento());
	}, [page, numeroRecord, search]);

	const handlerCreateTipoDocumento = () => {
		const nuovoTipoDocumento: TipoDocumento = {
			id: 0,
			nome: `Nuovo Tipo documento`,
		};
		dispatch(saveTipoDocumento({ tipoDocumentoToSave: nuovoTipoDocumento }));
	};

	return (
		<Box
			sx={{
				p: 2,
			}}
		>
			<Stack
				direction="row"
				justifyContent="space-between"
				alignItems="center"
				spacing={2}
			>
				<TipiDocumentoPageBreadcrumbs />

				<Button
					color="primary"
					variant="contained"
					startIcon={<AddIcon />}
					onClick={(event) => {
						event.preventDefault();
						handlerCreateTipoDocumento();
					}}
				>
					Aggiungi nuovo TipoDocumento
				</Button>
			</Stack>

			<Box
				sx={{
					backgroundColor: "#fff",
					elevation: 20,
					mt: 2,
				}}
			>
				<List>
					<ListItemText
						key={"Lista_campi_clienti"}
						sx={{ backgroundColor: "#1b75b1", color: "#ddd", mb: 1 }}
					>
						<Grid container spacing={{ xs: 0.5, md: 1, lg: 2 }} sx={{ pl: 2 }}>
							<Grid item xs={5} sm={6} md={4} lg={3}>
								<Typography variant="h5" gutterBottom>
									Tipo Documento
								</Typography>
							</Grid>
							<Grid item xs={7} sm={6} md={4} lg={5}>
								<Typography variant="h5" gutterBottom>
									descrizione
								</Typography>
							</Grid>
							<Grid
								item
								xs={5}
								sm={5}
								md={5}
								lg={2}
								sx={{ backgroundColor: "#12aa78" }}
							>
								<Typography variant="h5" gutterBottom>
									Fase Iter
								</Typography>
							</Grid>

							<Grid
								item
								xs={5}
								sm={5}
								md={5}
								lg={2}
								sx={{ backgroundColor: "#c11aa8" }}
							>
								<Typography variant="h5" gutterBottom>
									Fase Commessa
								</Typography>
							</Grid>
						</Grid>
					</ListItemText>

					{tipiDocumento.results?.map((tipoDocumento, index) => (
						<ListItemButton
							key={index}
							component={Link}
							to={Routes_path.TIPO_DOCUMENTO + tipoDocumento.id}
							sx={{
								color: "black",
								textDecoration: "none",
								backgroundColor: index % 2 === 0 ? "#fff" : "#f2f2f2",
							}}
						>
							<Grid
								container
								spacing={{ xs: 0.5, md: 1, lg: 2 }}
								sx={{ mb: 1 }}
							>
								<Grid item xs={5} sm={6} md={4} lg={3}>
									{`${tipoDocumento.id} - ${tipoDocumento.nome}`}
								</Grid>
								<Grid item xs={7} sm={6} md={4} lg={5}>
									{tipoDocumento.descrizione}
								</Grid>
								<Grid item xs={5} sm={5} md={5} lg={2}>
									{
										Object.entries(TipoFaseIterConnessioneTypes).find(
											([key, value]) =>
												key == tipoDocumento.fase_iter_connessione
										)?.[1]
									}
								</Grid>
								<Grid item xs={5} sm={5} md={5} lg={2}>
									{
										Object.entries(TipoFaseCommessaTypes).find(
											([key, value]) => key == tipoDocumento.fase_commessa
										)?.[1]
									}
								</Grid>
							</Grid>
						</ListItemButton>
					))}

					{/* <ListItem
						sx={{
							alignContent: "right",
							width: "100%",
							mt: 2,
							borderTopStyle: "solid",
							borderTopWidth: 1,
						}}
					>
						<Stack
							direction="row"
							justifyContent="space-between"
							alignItems="center"
							spacing={2}
							sx={{ width: "100%" }}
						>
							<Pagination
								count={tipiDocumento.num_pages}
								onChange={handlerPager}
								page={page}
							/>
							<Typography noWrap>
								Totale tipi:{"  "}
								{tipiDocumento.count}
							</Typography>

							<TextField
								id="input-with-icon-textfield"
								// label="TextField"
								InputProps={{
									startAdornment: (
										<InputAdornment position="end">
											<SearchIcon />
										</InputAdornment>
									),
								}}
								variant="standard"
								value={search}
								onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
									setSearch(event.target.value);
								}}
							/>

							<FormControl>
								<InputLabel id="demo-simple-select-label">Record</InputLabel>
								<Select
									labelId="demo-simple-select-label"
									id="demo-simple-select"
									value={numeroRecord.toString()}
									label="Age"
									onChange={handleChangeNumeroRecord}
									variant="outlined"
								>
									<MenuItem value={10}>10</MenuItem>
									<MenuItem value={20}>20</MenuItem>
									<MenuItem value={50}>50</MenuItem>
								</Select>
							</FormControl>
						</Stack>
					</ListItem> */}
				</List>
			</Box>
		</Box>
	);
};

export default TipiDocumentoPage;
