import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import {
	VerificaVincoli,
	saveVerificaVincoli,
	StatoVerificaVincoliTypes, deleteVerificaVincoli,
} from "../verificheVincoliSlice";
import { useAppDispatch, RootState } from "../../../../redux/store";
import {
	ErrorStatusTypes,
	ErrorsStack,
} from "../../../common/errorsDeclarations";
import { useNavigate } from 'react-router-dom';
import FormHelperText from "@mui/material/FormHelperText";
import FormControlLabel from "@mui/material/FormControlLabel";


import { utenteAutorizzato } from "../../../authentication/UtenteAutorizzato";

import {
	UtenteLogged,
	UtenteApplicativo, UtentiStaff,
} from "../../../authentication/authenticationSlice";
import { Cliente } from "../../../anagrafiche/clienti/clientiSlice";
import { Installatore } from "../../../anagrafiche/installatori/installatoriSlice";
import { RegimeCommerciale } from "../../../anagrafiche/regimiCommerciali/regimiCommercialiSlice";
import { TipoPratica } from "../../../anagrafiche/tipiPratica/tipiPraticaSlice";
import { TipoTensione } from "../../../anagrafiche/tipiTensione/tipiTensioneSlice";
import { Comune } from "../../../anagrafiche/comuni/comuniSlice";

import { Gestore } from "../../../anagrafiche/gestori/gestoriSlice";
import { useForm, SubmitHandler, Controller } from "react-hook-form";

import ReactHookFormSelect from "../../../common/ReactHookFormSelect";
import MenuItem from "@mui/material/MenuItem";
import Switch from "@mui/material/Switch";
import { Routes_path } from "../../../routerApp";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Alert from "../../../common/Alert";
import Typography from "@mui/material/Typography";
import Autocomplete from "@mui/material/Autocomplete";

import { Button, Divider } from "@mui/material";

import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

import {convertToDateTime} from "../../../common/dateUtils/convertToDateTime";
import {PermissionTypes} from "../../../authentication/permissionsGroups";

function makeid(length: number) {
	let result = "";
	const characters =
		"ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
	const charactersLength = characters.length;
	let counter = 0;
	while (counter < length) {
		result += characters.charAt(Math.floor(Math.random() * charactersLength));
		counter += 1;
	}
	return result;
}
interface VerificaVincoliFormProps {
	verifica_vincoli: VerificaVincoli;
	// produttore: Soggetto;
	consentiModifica: boolean;
}

const VerificaVincoliForm = ({
	verifica_vincoli,
	// produttore,
	consentiModifica = false,
}: VerificaVincoliFormProps) => {
	const {
		handleSubmit,
		register,
		formState: { errors, isDirty, isValid },
		setValue,
		watch,
		getValues,
		control,
		setError,
		clearErrors,
		reset,
	} = useForm<VerificaVincoli>();

	const listaCampi = Object.keys(verifica_vincoli) as Array<
		keyof VerificaVincoli
	>;

	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const [editing, setEditingControlled] = useState(!verifica_vincoli.id);

	const setEditing = (switchModifica: boolean) => {
		consentiModifica && setEditingControlled(switchModifica);
	};

	const [mostraNote, setMostraNote] = useState<boolean>(false);

	const utente = useSelector<RootState, UtenteLogged>(
		(state) => state.authentication.utenteLogged
	);

	const clienti = useSelector<RootState, Cliente[]>(
		(state) => state.clientiState.clienti.results
	);

	const utentiApplicativo = useSelector<RootState, UtenteApplicativo[]>(
		(state) => state.authentication.utentiApplicativo.results
	);

	const utentiStaff = useSelector<RootState, UtentiStaff | undefined>(
		(state) => state.authentication.utentiStaff
	);

	const operatoriVerifiche = (verifica_vincoli:VerificaVincoli) => {
		if (verifica_vincoli.servizio_i43) {
			return utentiStaff?.results.filter((utente) => !!utenteAutorizzato(utente,
				[PermissionTypes.OPERATORI, PermissionTypes.GESTORI_ITER, PermissionTypes.PROGETTISTI,])) || []
		} else {
			return utentiStaff?.results.filter((utente) => !!utenteAutorizzato(utente,
				[PermissionTypes.ISTALLATORI_GENERAL_CONTRACTOR, PermissionTypes.GENERAL_CONTRACTOR, PermissionTypes.BCK_OFFICE_GENERAL_CONTRACTOR,])) || []
		}
	}
	
	const installatori = useSelector<RootState, Installatore[]>(
		(state) => state.installatoriState.installatori.results
	);

	const regimiCommerciali = useSelector<RootState, RegimeCommerciale[]>(
		(state) => state.regimiCommercialiState.regimiCommerciali.results
	);
	const tipiPratica = useSelector<RootState, TipoPratica[]>(
		(state) => state.tipiPraticaState.tipiPratica.results
	);
	const tipiTensione = useSelector<RootState, TipoTensione[]>(
		(state) => state.tipiTensioneState.tipiTensione.results
	);

	const gestori = useSelector<RootState, Gestore[]>(
		(state) => state.gestoriState.gestori.results
	);

	const comuni = useSelector<RootState, Comune[]>(
		(state) => state.comuniState.comuni.results
	);

	const [comuneCorrente, setComuneCorrente] = useState<Comune | undefined>(
		undefined
	);

	useEffect(() => {
		setComuneCorrente(
			comuni.find((comuneScelto) => comuneScelto.id == getValues("comune"))
		);
	}, [comuni, getValues("comune"), verifica_vincoli.comune]);

	const [keyVerifica, setKeyVerifica] = useState<string>(makeid(5));
	const [annulla, setAnnulla] = useState<boolean>(false);

	useEffect(() => {
		reset();
		listaCampi.map((field) => {
			setValue(field, verifica_vincoli[field]);
		});
		setKeyVerifica(makeid(5));

		setAnnulla(false);
	}, [verifica_vincoli.id, annulla]);

	useEffect(() => {
		clearErrors();
		listaCampi.forEach((field) => {
			verifica_vincoli?.errorsStack?.fieldsErrors &&
				verifica_vincoli.errorsStack.fieldsErrors[field] &&
				setError(field, {
					type: "server",
					message: verifica_vincoli.errorsStack.fieldsErrors[field].toString(),
				});
		});
	}, [
		verifica_vincoli?.errorsStack?.fieldsErrors,
		// sopralluogo.allevamento_pack?.errorsStack?.fieldsErrors,
	]);

	useEffect(() => {
		verifica_vincoli?.errorsStack?.status == ErrorStatusTypes.SUCCESS &&
			setEditing(!verifica_vincoli.id);
	}, [
		verifica_vincoli,
		// sopralluogo.allevamento_pack?.errorsStack?.fieldsErrors,
	]);

	useEffect(() => {
		if (
			getValues("pratica_paesaggistica") == true ||
			getValues("autorizzativi_comunali") == true
		) {
			setMostraNote(true);
		}
		if (
			getValues("pratica_paesaggistica") == false &&
			getValues("autorizzativi_comunali") == false
		) {
			setMostraNote(false);
		}
	}, [getValues("pratica_paesaggistica"), getValues("autorizzativi_comunali")]);

	const handlerSaveVerificaVincoli = (verifica_vincoli: VerificaVincoli) => {
		consentiModifica &&
			dispatch(
				saveVerificaVincoli({
					verificaVincoliToSave: verifica_vincoli,
				})
			);
	};

	const onSubmit: SubmitHandler<VerificaVincoli> = (verifica_vincoli) => {
		handlerSaveVerificaVincoli(verifica_vincoli);
	};

	return (
		<Box sx={{ mb: 1 }}>
			<form onSubmit={handleSubmit(onSubmit)} key={keyVerifica}>
				{verifica_vincoli?.errorsStack?.fieldsErrors?.non_field_errors && (
					<Alert severity="error">
						{verifica_vincoli?.errorsStack?.fieldsErrors?.non_field_errors.toString()}
					</Alert>
				)}

				<Grid container spacing={2} sx={{ mb: 2 }}>
					<Grid item xs={12} sm={6} md={6} lg={4}>
						<ReactHookFormSelect
							name="cliente"
							label="Cliente"
							register={register}
							control={control}
							disabled={!consentiModifica}
							watch={watch}
							variant="outlined"
							error={errors?.cliente?.message}
							sx={{ width: "100%" }}
						>
							<MenuItem value="" key="cliente_vuoto">
								<em>Nessuno</em>
							</MenuItem>
							{clienti.map((cliente) => {
								return (
									<MenuItem key={cliente.id} value={cliente.id}>
										{cliente.ragione_sociale}
									</MenuItem>
								);
							})}
						</ReactHookFormSelect>
					</Grid>
					<Grid item xs={12} sm={6} md={6} lg={2}>
						<DesktopDatePicker
							label="data verifica"
							// components={{
							// 	OpenPickerIcon: ClockIcon,
							// }}
							// inputFormat="DD/MM/YY HH:mm"
							// mask={"__/__/__ __:__"}
							// label="Data Progetto"
							// {...register("data_ora_carico_pianificata")}
							value={convertToDateTime(verifica_vincoli.data_verifica)}

							disabled={!consentiModifica}
							slotProps={{
								textField: {
									required: false,
									error: !!errors?.data_verifica,
									helperText: errors?.data_verifica?.message,
									sx: { width: "100%" },
									autoComplete: "off",
									// disabled: !!richiestaConnessione.stato
								},
								field: {
									clearable: true,
								},
							}}
							onChange={(newValue: any) => {
								setEditing(true);
								setValue(
									"data_verifica",
									newValue ? convertToDateTime(newValue)?.toFormat("yyyy-MM-dd") : "",
									{
										shouldDirty: true,
									}
								);
								clearErrors("data_verifica");
							}}
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={6} lg={2}>
						<ReactHookFormSelect
							name="operatore"
							label="operatore"
							register={register}
							control={control}
							disabled={!consentiModifica}
							watch={watch}
							variant="outlined"
							error={errors?.operatore?.message}
							sx={{ width: "100%" }}
						>
							<MenuItem value="" key="operatore_vuoto">
								<em>Nessuno</em>
							</MenuItem>


							{operatoriVerifiche(verifica_vincoli).map((utente) => {
								return (
									<MenuItem key={utente.id} value={utente.id}>
										{utente.full_name}
									</MenuItem>
								);
							})}
							{utentiApplicativo
								.filter((utente) => utente.user.is_staff == true)
								.map((utente) => {
									return (
										<MenuItem key={utente.id} value={utente.user.id}>
											{utente.user.full_name}
										</MenuItem>
									);
								})}
						</ReactHookFormSelect>
					</Grid>
					<Grid item xs={12} sm={6} md={6} lg={2}>
						<ReactHookFormSelect
							name="stato_verifica"
							label="stato"
							register={register}
							control={control}
							disabled={!consentiModifica}
							watch={watch}
							variant="outlined"
							error={errors?.stato_verifica?.message}
							sx={{ width: "100%" }}
						>
							{/*<MenuItem value="" key="stato">*/}
							{/*	<em>Nessuno</em>*/}
							{/*</MenuItem>*/}

							{Object.entries(StatoVerificaVincoliTypes).map(
								([key, value]) => {
									return (
										<MenuItem key={key} value={key}>
											{value}
										</MenuItem>
									);
								}
							)}
							{utentiApplicativo
								.filter((utente) => utente.user.is_staff == true)
								.map((utente) => {
									return (
										<MenuItem key={utente.id} value={utente.user.id}>
											{utente.user.full_name}
										</MenuItem>
									);
								})}
						</ReactHookFormSelect>
					</Grid>
				</Grid>

				<Grid container spacing={2} sx={{ mb: 2 }}>
					<Grid item xs={9} sm={9} md={9} lg={4}>
						{verifica_vincoli.produttore_descrizione}
					</Grid>
				</Grid>

				<Grid container spacing={2} sx={{ mb: 2 }}>
					<Grid item xs={9} sm={9} md={9} lg={4}>
						<TextField
							label="indirizzo"
							variant="outlined"
							InputLabelProps={{
								shrink: true,
							}}
							disabled={!consentiModifica}
							{...register("indirizzo")}
							error={!!errors?.indirizzo}
							helperText={errors?.indirizzo?.message}
							autoComplete={"off"}
							sx={{ width: "100%" }}
							fullWidth
							onClick={() => setEditing(true)}
							// onBlur={handlerDetailChaingSubmit}
						/>
					</Grid>
					<Grid item xs={3} sm={3} md={3} lg={1}>
						<TextField
							variant="outlined"
							label="N# civico"
							InputLabelProps={{
								shrink: true,
							}}
							disabled={!consentiModifica}
							{...register("numero_civico")}
							error={!!errors?.numero_civico}
							helperText={errors?.numero_civico?.message}
							autoComplete={"off"}
							sx={{ width: "100%" }}
							fullWidth
							onClick={() => setEditing(true)}
							// onBlur={handlerDetailChaingSubmit}
						/>
					</Grid>
					<Grid item xs={4} sm={4} md={4} lg={1}>
						<TextField
							label="cap"
							variant="outlined"
							InputLabelProps={{
								shrink: true,
							}}
							disabled={!consentiModifica}
							{...register("cap")}
							error={!!errors?.cap}
							helperText={errors?.cap?.message}
							autoComplete={"off"}
							sx={{ width: "100%" }}
							fullWidth
							onClick={() => setEditing(true)}
							// onBlur={handlerDetailChaingSubmit}
						/>
					</Grid>
					<Grid item xs={8} sm={8} md={8} lg={4}>
						<Autocomplete
							key={comuneCorrente?.id}
							value={comuneCorrente}
							isOptionEqualToValue={(option, value) => option.id === value.id}
							onClick={() => setEditing(true)}
							onChange={(event: any, newValue: any) => {
								setValue("comune", newValue ? newValue.id : null, {
									shouldDirty: true,
								});
							}}
							disabled={!consentiModifica}
							id="comune"
							options={comuni}
							getOptionLabel={(option) => option.nome}
							renderOption={(props, option) => (
								<Box component="li" {...props}>
									{option.nome}
								</Box>
							)}
							renderInput={(params: any) => (
								<TextField
									variant="outlined"
									{...params}
									label="Comune"
									error={!!errors?.comune}
									helperText={errors?.comune?.message}
									onClick={() => setEditing(true)}
								/>
							)}
						/>
					</Grid>
				</Grid>

				<Grid container spacing={2} sx={{ mb: 2 }}>
					<Grid item xs={12} md={4}>
						<TextField
							id="cellulare"
							label="Cellulare"
							disabled={!consentiModifica}
							{...register("cellulare")}
							InputLabelProps={{
								shrink: true,
							}}
							variant="outlined"
							fullWidth
							type="number"
							autoComplete={"off"}
							error={!!errors?.cellulare}
							helperText={errors?.cellulare?.message}
						/>
					</Grid>
				</Grid>

				<Grid container spacing={2} sx={{ mb: 2 }}>
					<Grid item xs={6} sm={4} md={4} lg={4}>
						<FormControlLabel
							label={
								<Typography marginLeft="0.1rem">
									{watch("linea_vita")
										? "Necessita di Linea Vita"
										: "Non necessita di Linea Vita"}
								</Typography>
							}
							control={
								<Switch
									id="forza_codice_pod"
									checked={watch("linea_vita") || false}
									disabled={!consentiModifica}
									onChange={
										() =>
											setValue("linea_vita", !watch("linea_vita"), {
												shouldDirty: true,
											})
										// setForzaCambioGestorePod(!forzaCambioGestorePod)
									}
									color="warning"
									size="small"
								/>
							}
						/>
						{!!errors?.linea_vita && (
							<FormHelperText error>
								{errors?.linea_vita.toString()}
							</FormHelperText>
						)}
					</Grid>
					<Grid item xs={6} sm={4} md={4} lg={4}>
						<FormControlLabel
							label={
								<Typography marginLeft="0.1rem">
									{watch("pratica_paesaggistica")
										? "Necessita di Pratica Paesaggistica"
										: "Non necessita di Pratica Paesaggistica"}
								</Typography>
							}
							control={
								<Switch
									id="forza_codice_pod"
									disabled={!consentiModifica}
									checked={watch("pratica_paesaggistica") || false}
									onChange={
										() =>
											setValue(
												"pratica_paesaggistica",
												!watch("pratica_paesaggistica"),
												{
													shouldDirty: true,
												}
											)
										// setForzaCambioGestorePod(!forzaCambioGestorePod)
									}
									color="warning"
									size="small"
								/>
							}
						/>
						{!!errors?.pratica_paesaggistica && (
							<FormHelperText error>
								{errors?.pratica_paesaggistica.toString()}
							</FormHelperText>
						)}
					</Grid>
					<Grid item xs={6} sm={4} md={4} lg={4}>
						<FormControlLabel
							label={
								<Typography marginLeft="0.1rem">
									{watch("autorizzativi_comunali")
										? "Necessita di Autorizzativi Comunali"
										: "Non necessita di Autorizzativi Comunali"}
								</Typography>
							}
							control={
								<Switch
									id="forza_codice_pod"
									checked={watch("autorizzativi_comunali") || false}
									disabled={!consentiModifica}
									onChange={
										() =>
											setValue(
												"autorizzativi_comunali",
												!watch("autorizzativi_comunali"),
												{
													shouldDirty: true,
												}
											)
										// setForzaCambioGestorePod(!forzaCambioGestorePod)
									}
									color="warning"
									size="small"
								/>
							}
						/>
						{!!errors?.autorizzativi_comunali && (
							<FormHelperText error>
								{errors?.autorizzativi_comunali.toString()}
							</FormHelperText>
						)}
					</Grid>
					{mostraNote && (
						<Grid container spacing={2} sx={{ m: 2, ml: 1 }}>
							<Grid item xs={12} sm={12} md={12} lg={12}>
								<TextField
									label="note"
									// disabled={schedaCarico.numero_capi_pianificati}
									variant="outlined"
									InputLabelProps={{
										shrink: true,
									}}
									{...register("note")}
									rows={5}
									multiline={true}
									error={!!errors?.note}
									helperText={errors?.note?.message}
									autoComplete={"off"}
									sx={{
										width: "100%",
										textarea: {
											resize: "both",
										},
									}}
									// multiline={true}
									// rows={3}
									fullWidth
									// onBlur={handlerDetailChaingSubmit}
								/>
							</Grid>
						</Grid>
					)}
				</Grid>

				<Button
					disabled={!consentiModifica}
					variant="outlined"
					onClick={() => {
						setAnnulla(true);
					}}
				>
					Annulla
				</Button>
				<Button
					disabled={!consentiModifica}
					variant="outlined"
					type="submit"
					sx={{ ml: 1 }}
				>
					Salva
				</Button>
				<Button
					disabled={verifica_vincoli.stato_verifica != 'A'}
					variant="outlined"
					type="submit"

					sx={{ ml: 1 }}
					onClick={()=> {
						dispatch(deleteVerificaVincoli(verifica_vincoli)).then((result) => {
							if (deleteVerificaVincoli.fulfilled.match(result)) {
								navigate(Routes_path.HOME); // Reindirizza alla home se l'azione ha successo
							} else {
								console.error("Errore nella cancellazione");
							}
						});

					}}
				>
					Cancella
				</Button>
			</form>
		</Box>
	);
};

export default VerificaVincoliForm;
