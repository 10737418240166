import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";

import {PodCommessa} from "../../../commesse/commesseSlice";
import {IterConnessione, savePodIterconnessione,} from "components/main/iterConnessioni/iterConnessioneSlice";
import {useAppDispatch, RootState} from "../../../../../redux/store";
import {ErrorStatusTypes,} from "../../../../common/errorsDeclarations";

import {theme} from "../../../../theme";
import {UtenteLogged} from "../../../../authentication/authenticationSlice";
import {TipoTensione} from "../../../../anagrafiche/tipiTensione/tipiTensioneSlice";
import {Comune} from "../../../../anagrafiche/comuni/comuniSlice";

import {Gestore} from "../../../../anagrafiche/gestori/gestoriSlice";
import {RegimeCommerciale} from "../../../../anagrafiche/regimiCommerciali/regimiCommercialiSlice";

import {useForm, SubmitHandler, Controller} from "react-hook-form";

import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Alert from "../../../../common/Alert";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import Autocomplete from "@mui/material/Autocomplete";

import {Button, Divider} from "@mui/material";
import {Soggetto} from "components/anagrafiche/produttori/produttoriSlice";
import Checkbox from "@mui/material/Checkbox";
import FormHelperText from "@mui/material/FormHelperText";
import ReactHookFormSelect from "../../../../common/ReactHookFormSelect";
import MenuItem from "@mui/material/MenuItem";
import {TipologiaEdiliziaTypes, TitoloPossessoTypes} from "../../../pods/podsSlice";



function makeid(length: number) {
    let result = "";
    const characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
        counter += 1;
    }
    return result;
}

interface PodCommessaFormProps {
    pod: PodCommessa;
    iterConnessione?: IterConnessione;
    produttore?: Soggetto;
    consentiModifica?: boolean;
}

const PodCommessaForm = ({
                             pod,
                             iterConnessione,
                             produttore,
                             consentiModifica = false,
                         }: PodCommessaFormProps) => {
    const {
        handleSubmit,
        register,
        formState: {errors, isDirty, isValid},
        setValue,
        watch,
        getValues,
        control,
        setError,
        clearErrors,
        reset,
    } = useForm<PodCommessa>();

    const listaCampi = Object.keys(pod) as Array<keyof PodCommessa>;

    const dispatch = useAppDispatch();

    const [editing, setEditingControlled] = useState(false);

    const setEditing = (switchModifica: boolean) => {
        consentiModifica && setEditingControlled(switchModifica);
    };

    const utente = useSelector<RootState, UtenteLogged>(
        (state) => state.authentication.utenteLogged
    );

    const regimiCommerciali = useSelector<RootState, RegimeCommerciale[]>(
        (state) => state.regimiCommercialiState.regimiCommerciali.results
    );
    const tipiTensione = useSelector<RootState, TipoTensione[]>(
        (state) => state.tipiTensioneState.tipiTensione.results
    );

    const gestori = useSelector<RootState, Gestore[]>(
        (state) => state.gestoriState.gestori.results
    );

    const comuni = useSelector<RootState, Comune[]>(
        (state) => state.comuniState.comuni.results
    );

    const [comuneCorrente, setComuneCorrente] = useState<Comune | undefined>(
        undefined
    );

    const commesseStatus = useSelector<RootState, ErrorStatusTypes>(
        (state) => state.commesseState.commesse.errorsStack.status
    );
    useEffect(() => {
        setComuneCorrente(
            comuni.find((comuneScelto) => comuneScelto.id == getValues("comune"))
        );
    }, [comuni, getValues("comune"), pod]);

    const [keycodice_pod, setKeycodice_pod] = useState<string>(makeid(5));

    useEffect(() => {
        reset();
        listaCampi.map((field) => {
            setValue(field, pod[field]);
        });
        // setKeycodice_pod(makeid(5));
    }, [editing, pod, pod.id]);

    useEffect(() => {
        clearErrors();
        listaCampi.forEach((field) => {
            pod?.errorsStack?.fieldsErrors &&
            pod.errorsStack.fieldsErrors[field] &&
            setError(field, {
                type: "server",
                message: pod.errorsStack.fieldsErrors[field].toString(),
            });
        });
    }, [
        pod?.errorsStack?.fieldsErrors,
        // commessa.allevamento_pack?.errorsStack?.fieldsErrors,
    ]);

    const handlerSavePod = (pod: PodCommessa) => {
        if (iterConnessione && consentiModifica) {
            dispatch(
                savePodIterconnessione({
                    iterConnessione: iterConnessione,
                    podCommessaToSave: pod,
                })
            );
        }
    };

    const onSubmit: SubmitHandler<PodCommessa> = (pod) => {
        handlerSavePod(pod);
    };

    const handlerDetailChaingSubmit = () => {
        if (isValid && isDirty) {
            const formValues = getValues();
            handlerSavePod(formValues);
        }
    };

    useEffect(() => {
        if (isValid && isDirty) {
            const formValues = getValues();
            if (formValues.id) {
                console.log('formValues==', formValues)
                handlerSavePod(formValues);
            }
        }
    }, [
        watch("comune"),
        watch("titolo_possesso"),
        watch("tipologia_edilizia"),
        watch("regime_commerciale"),
        watch("gestore_rete"),
        watch("tipo_tensione"),
        // watch("destinazione_residenziale"),
    ]);

    const [editSedecodice_pod, setEditSedecodice_pod] = useState(
        produttore?.indirizzo != pod.indirizzo ||
        produttore?.numero_civico != pod.numero_civico ||
        produttore?.cap != pod.cap ||
        produttore?.comune != pod.comune
    );

    return (
        <Box sx={{mb: 1}}>
            <form onSubmit={handleSubmit(onSubmit)}>
                {pod?.errorsStack?.fieldsErrors?.non_field_errors && (
                    <Alert severity="error">
                        {pod?.errorsStack?.fieldsErrors?.non_field_errors.toString()}
                    </Alert>
                )}
                <Grid container spacing={2} sx={{my: 2}}>
                    <Grid item xs={12} sm={6} md={6} lg={4}>
                        <TextField
                            label="Codice POD"
                            disabled={true}
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            {...register("codice")}
                            error={!!errors?.codice}
                            helperText={errors?.codice?.message}
                            autoComplete={"off"}
                            sx={{width: "100%"}}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4}>
                        <TextField
                            label="iban accredito GSE"
                            disabled={!consentiModifica}
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            {...register("iban_accredito_gse")}
                            error={!!errors?.iban_accredito_gse}
                            helperText={errors?.iban_accredito_gse?.message}
                            autoComplete={"off"}
                            sx={{width: "100%"}}
                            fullWidth
                            onBlur={handlerDetailChaingSubmit}
                        />
                    </Grid>
                </Grid>
                <Divider
                    textAlign="left"
                    sx={{mb: 2, color: theme.palette.primary.main}}
                >
                    Indirizzo del pod
                </Divider>
                <Grid container spacing={2} sx={{mb: 2}}>
                    <Grid item xs={9} sm={9} md={9} lg={4}>
                        <TextField
                            label="indirizzo"
                            disabled={!editSedecodice_pod || !consentiModifica}
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            {...register("indirizzo")}
                            error={!!errors?.indirizzo}
                            helperText={errors?.indirizzo?.message}
                            autoComplete={"off"}
                            sx={{width: "100%"}}
                            fullWidth
                            onBlur={handlerDetailChaingSubmit}
                        />
                    </Grid>
                    <Grid item xs={3} sm={3} md={3} lg={1}>
                        <TextField
                            label="numero civico"
                            disabled={!editSedecodice_pod || !consentiModifica}
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            {...register("numero_civico")}
                            error={!!errors?.numero_civico}
                            helperText={errors?.numero_civico?.message}
                            autoComplete={"off"}
                            sx={{width: "100%"}}
                            fullWidth
                            onBlur={handlerDetailChaingSubmit}
                        />
                    </Grid>
                    <Grid item xs={4} sm={4} md={4} lg={1}>
                        <TextField
                            label="cap"
                            disabled={!editSedecodice_pod || !consentiModifica}
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            {...register("cap")}
                            error={!!errors?.cap}
                            helperText={errors?.cap?.message}
                            autoComplete={"off"}
                            sx={{width: "100%"}}
                            fullWidth
                            onBlur={handlerDetailChaingSubmit}
                        />
                    </Grid>
                    <Grid item xs={7} sm={7} md={7} lg={3}>
                        <Autocomplete
                            key={comuneCorrente?.id}
                            disabled={!editSedecodice_pod || !consentiModifica}
                            value={comuneCorrente}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            onClick={() => setEditing(true)}
                            onChange={(event: any, newValue: any) => {
                                setValue("comune", newValue ? newValue.id : null, {
                                    shouldDirty: true,
                                });
                            }}
                            id="comune"
                            options={comuni}
                            getOptionLabel={(option) => option.nome}
                            renderOption={(props, option) => (
                                <Box component="li" {...props}>
                                    {option.nome}
                                </Box>
                            )}
                            renderInput={(params: any) => (
                                <TextField
                                    variant="outlined"
                                    {...params}
                                    // label="Comune"
                                    error={!!errors?.comune}
                                    helperText={errors?.comune?.message}
                                    onClick={() => setEditing(true)}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={7} sm={7} md={7} lg={2}>
                        <Typography
                            variant="body1"
                            component="div"
                            color="text.secondary"
                            gutterBottom
                            onClick={() => setEditing(true)}
                        >
                            {comuneCorrente?.provincia}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={1}>
                        <>
                            <Typography
                                variant="caption"
                                component="div"
                                color="text.secondary"
                                gutterBottom
                                onClick={() => setEditing(true)}
                            >
                                Diverso dal Produttore:
                            </Typography>
                            <FormControlLabel
                                label={
                                    <Typography fontSize="0.8rem" marginLeft="0.1rem">
                                        {editSedecodice_pod ? "SI" : "NO"}
                                    </Typography>
                                }
                                control={
                                    <Switch
                                        id="edit_sede_codice_pod"
                                        checked={editSedecodice_pod || false}
                                        onChange={() => setEditSedecodice_pod(!editSedecodice_pod)}
                                        color="info"
                                        size="small"
                                    />
                                }
                            />
                        </>
                    </Grid>
                </Grid>
                <Divider
                    textAlign="left"
                    sx={{my: 2, color: theme.palette.primary.main}}
                >
                    Dati catastali del POD
                </Divider>
                <Grid container spacing={2} sx={{mb: 2}}>
                    {/*<Grid item xs={6} sm={4} md={3} lg={2}>*/}
                    {/*    <TextField*/}
                    {/*        label="foglio catastaleeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee"*/}
                    {/*        disabled={!consentiModifica}*/}
                    {/*        variant="outlined"*/}
                    {/*        InputLabelProps={{*/}
                    {/*            shrink: true,*/}
                    {/*        }}*/}
                    {/*        {...register("foglio_catastale")}*/}
                    {/*        error={!!errors?.foglio_catastale}*/}
                    {/*        helperText={errors?.foglio_catastale?.message}*/}
                    {/*        autoComplete={"off"}*/}
                    {/*        sx={{width: "100%"}}*/}
                    {/*        fullWidth*/}
                    {/*        onBlur={handlerDetailChaingSubmit}*/}
                    {/*    />*/}
                    {/*</Grid>*/}
                    {/*<Grid item xs={6} sm={4} md={3} lg={2}>*/}
                    {/*    <TextField*/}
                    {/*        label="particella catastale"*/}
                    {/*        disabled={!consentiModifica}*/}
                    {/*        variant="outlined"*/}
                    {/*        InputLabelProps={{*/}
                    {/*            shrink: true,*/}
                    {/*        }}*/}
                    {/*        {...register("particella_catastale")}*/}
                    {/*        error={!!errors?.particella_catastale}*/}
                    {/*        helperText={errors?.particella_catastale?.message}*/}
                    {/*        autoComplete={"off"}*/}
                    {/*        sx={{width: "100%"}}*/}
                    {/*        fullWidth*/}
                    {/*        onBlur={handlerDetailChaingSubmit}*/}
                    {/*    />*/}
                    {/*</Grid>*/}
                    {/*<Grid item xs={6} sm={4} md={3} lg={2}>*/}
                    {/*    <TextField*/}
                    {/*        label="sub particella catastale"*/}
                    {/*        disabled={!consentiModifica}*/}
                    {/*        variant="outlined"*/}
                    {/*        InputLabelProps={{*/}
                    {/*            shrink: true,*/}
                    {/*        }}*/}
                    {/*        {...register("sub_particella_catastale")}*/}
                    {/*        error={!!errors?.sub_particella_catastale}*/}
                    {/*        helperText={errors?.sub_particella_catastale?.message}*/}
                    {/*        autoComplete={"off"}*/}
                    {/*        sx={{width: "100%"}}*/}
                    {/*        fullWidth*/}
                    {/*        onBlur={handlerDetailChaingSubmit}*/}
                    {/*    />*/}
                    {/*</Grid>*/}
                    <Grid item xs={6} sm={4} md={3} lg={2}>
                        <TextField
                            label="superficie calpestabile"
                            disabled={!consentiModifica}
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            {...register("superficie_calpestabile")}
                            error={!!errors?.superficie_calpestabile}
                            helperText={errors?.superficie_calpestabile?.message}
                            autoComplete={"off"}
                            sx={{width: "100%"}}
                            fullWidth
                            onBlur={handlerDetailChaingSubmit}
                        />
                    </Grid>
                    <Grid item xs={6} sm={4} md={3} lg={2}>
                        <TextField
                            label="numero unita immobiliari"
                            disabled={!consentiModifica}
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            {...register("numero_unita_immobiliari")}
                            error={!!errors?.numero_unita_immobiliari}
                            helperText={errors?.numero_unita_immobiliari?.message}
                            autoComplete={"off"}
                            sx={{width: "100%"}}
                            fullWidth
                            onBlur={handlerDetailChaingSubmit}
                        />
                    </Grid>
                    <Grid item xs={6} sm={4} md={3} lg={2}>
                        <TextField
                            label="anno costruzione"
                            disabled={!consentiModifica}
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            {...register("anno_costruzione")}
                            error={!!errors?.anno_costruzione}
                            helperText={errors?.anno_costruzione?.message}
                            autoComplete={"off"}
                            sx={{width: "100%"}}
                            fullWidth
                            onBlur={handlerDetailChaingSubmit}
                        />
                    </Grid>

                    <Grid item xs={6} sm={4} md={3} lg={2}>
                        <FormControlLabel
                            value={watch("destinazione_residenziale")}
                            checked={watch("destinazione_residenziale") || false}
                            control={<Checkbox/>}
                            label={
                                watch("destinazione_residenziale")
                                    ? "destinazione residenziale"
                                    : "destinazione non residenziale"
                            }
                            labelPlacement="end"
                            onChange={(e, checked) => {
                                const podCommessaToSave: PodCommessa = {...pod, destinazione_residenziale: checked}
                                iterConnessione && dispatch(
                                    savePodIterconnessione({
                                        iterConnessione: iterConnessione,
                                        podCommessaToSave: podCommessaToSave,
                                    })
                                );
                            }}
                            disabled={!consentiModifica}
                            style={{padding: 0}}
                        />
                        {!!errors?.destinazione_residenziale && (
                            <FormHelperText error>
                                {errors?.destinazione_residenziale.toString()}
                            </FormHelperText>
                        )}
                    </Grid>
                    <Grid item xs={6} sm={4} md={3} lg={2}>
                        <ReactHookFormSelect
                            name="titolo_possesso"
                            label="titolo possesso"
                            disabled={!consentiModifica}
                            register={register}
                            control={control}
                            watch={watch}
                            variant="outlined"
                            error={errors?.titolo_possesso?.message}
                            sx={{width: "100%"}}
                        >
                            <MenuItem value="">
                                <em>Nessuno</em>
                            </MenuItem>

                            {Object.entries(TitoloPossessoTypes).map(([key, value]) => {
                                return (
                                    <MenuItem key={key} value={key}>
                                        {value}
                                    </MenuItem>
                                );
                            })}
                        </ReactHookFormSelect>
                    </Grid>
                    <Grid item xs={6} sm={4} md={3} lg={2}>
                        <ReactHookFormSelect
                            name="tipologia_edilizia"
                            label="tipologia edilizia"
                            disabled={!consentiModifica}
                            register={register}
                            control={control}
                            watch={watch}
                            variant="outlined"
                            error={errors?.tipologia_edilizia?.message}
                            sx={{width: "100%"}}
                        >
                            <MenuItem value="">
                                <em>Nessuno</em>
                            </MenuItem>

                            {Object.entries(TipologiaEdiliziaTypes).map(([key, value]) => {
                                return (
                                    <MenuItem key={key} value={key}>
                                        {value}
                                    </MenuItem>
                                );
                            })}
                        </ReactHookFormSelect>
                    </Grid>
                    <Grid item lg={12}>
                        <TextField
                            label="note"
                            disabled={!consentiModifica}
                            variant="outlined"
                            multiline
                            rows={3}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            {...register("tipologia_edilizia_note")}
                            error={!!errors?.tipologia_edilizia_note}
                            helperText={errors?.tipologia_edilizia_note?.message}
                            autoComplete={"off"}
                            sx={{width: "100%"}}
                            fullWidth
                            onBlur={handlerDetailChaingSubmit}
                        />
                    </Grid>
                </Grid>

                <Box>
                    {(isDirty ||
                        commesseStatus == ErrorStatusTypes.SUCCESS ||
                        commesseStatus == ErrorStatusTypes.ERROR) && (
                        <Typography
                            sx={{
                                color: isDirty
                                    ? theme.palette.warning.main
                                    : commesseStatus == ErrorStatusTypes.SUCCESS
                                        ? theme.palette.success.main
                                        : commesseStatus == ErrorStatusTypes.ERROR
                                            ? theme.palette.error.main
                                            : theme.palette.warning.main,
                            }}
                            variant="caption"
                        >
                            {commesseStatus == ErrorStatusTypes.PENDING && (
                                <CircularProgress size={5}/>
                            )}

                            {isDirty
                                ? "Modifiche non salvate"
                                : commesseStatus == ErrorStatusTypes.SUCCESS
                                    ? "Salvato"
                                    : commesseStatus == ErrorStatusTypes.ERROR
                                        ? "Errore"
                                        : ""}
                        </Typography>
                    )}
                </Box>
            </form>
        </Box>
    );
};

export default PodCommessaForm;
