import React, {useEffect} from "react";
import {useAppDispatch} from "../../../../../redux/store";
import {Link} from "react-router-dom";
import {Commessa, getCommessa} from "../../commesseSlice";
import OrdineMaterialiCard from "./ordineMateriali/OrdineMaterialiCard";
import PianificazioneCantiereForm from "./pianificazioneCantiere/PianificazioneCantiereForm";
import AvvisoMerceProntaForm from "./arrivoMercePronta/AvvisoMerceProntaForm";
import PreparazioneCantiereForm from "./preparazioneCantiere/PreparazioneCantiereForm";
import Box from "@mui/material/Box";

import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import AllegatiPage from "../../../../allegati/AllegatiPage";
import InstallazioneInCorsoForm from "./installazioneInCorso/InstallazioneInCorsoForm";
import {convertToDateTime} from "../../../../common/dateUtils/convertToDateTime";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import PrintIcon from "@mui/icons-material/Print";
import {Routes_path} from "components/routerApp";
import {tabellaListaCodiciMatricola} from "./tabellaListaCodiciMatricola";
import {CSVLink, CSVDownload} from "react-csv";
import LinearProgress from "@mui/material/LinearProgress";


interface EsecuzioneTabProps {
    commessa: Commessa;
    consentiModifica: boolean;
    consentiModificaSoloGenralContractor: boolean;
}

const EsecuzioneTab = ({
                           commessa,
                           consentiModifica,
                           consentiModificaSoloGenralContractor,
                       }: EsecuzioneTabProps) => {
    const dispatch = useAppDispatch();
    useEffect(() => {
        if (commessa.id) {
            dispatch(getCommessa({commessaId: commessa.id}));
        }
    }, []);

    return (
        <Box
            sx={{
                backgroundColor: "#fff",
                elevation: 20,
                p: 2,
            }}
        >
            <Card elevation={3} sx={{mb: 2}}>
                <CardHeader
                    sx={{
                        backgroundColor: commessa.stato != "D" ? "#249949" : "#FF2222aa",
                        color: "#dee",
                    }}
                    title={"Ordine Materiali"}
                />
                <CardContent>
                    <OrdineMaterialiCard
                        commessa={commessa}
                        consentiModifica={
                            consentiModificaSoloGenralContractor && commessa.stato == "D"
                        }
                    />
                </CardContent>
            </Card>
            {/*{["E", "F", "G", "H", "I", "L", "M", "N"].includes(*/}
            {/*    commessa.stato || ""*/}
            {/*) && (*/}
            <Card elevation={3} sx={{mb: 2}}>
                <CardHeader
                    sx={{
                        backgroundColor: ["F", "G", "H", "I", "L", "M", "N"].includes(
                            commessa.stato || ""
                        ) ? "#249949" : "#FF2222aa",
                        color: "#dee",
                    }}
                    title={"Pianificazione Cantiere"}
                />

                <CardContent>
                    <PianificazioneCantiereForm
                        commessa={commessa}
                        consentiModifica={consentiModifica && ['D', 'E'].includes(commessa.stato || 'N')}
                    />
                </CardContent>
            </Card>
            {/*)}*/}
            {/*{["F", "G", "H", "I", "L", "M", "N"].includes(*/}
            {/*    commessa.stato || ""*/}
            {/*) && (*/}
            <Card elevation={3} sx={{mb: 2}}>
                <CardHeader
                    sx={{
                        backgroundColor: ["G", "H", "I", "L", "M", "N"].includes(
                            commessa.stato || ""
                        ) ? "#249949" : "#FF2222aa",
                        color: "#dee",
                    }}
                    title={`Avviso Merce Pronta (ammin. avvertita ${convertToDateTime(
                        commessa.data_pronta_per_fattura_amp
                    )
                        ?.setLocale("it")
                        .toFormat("EEEE dd LLLL yyyy")})`}
                />

                <CardContent>
                    <AvvisoMerceProntaForm
                        commessa={commessa}
                        consentiModifica={consentiModificaSoloGenralContractor && commessa.stato == "F"}
                    />
                </CardContent>
            </Card>
            {/*)}*/}
            {/*{["G", "H", "I", "L", "M", "N"].includes(commessa.stato || "") && (*/}
            <Card elevation={3} sx={{mb: 2}}>
                <CardHeader
                    sx={{
                        backgroundColor: ["H", "I", "L", "M", "N"].includes(commessa.stato || "") ? "#249949" : "#FF2222aa",
                        color: "#dee",
                    }}
                    title={
                        <Stack direction="row" spacing={2} justifyContent="space-between">
                            <Typography>
                                Preparazione Cantiere
                            </Typography>
                            <Button
                                color="primary"
                                variant="contained"
                                startIcon={<PrintIcon/>}
                                component={Link}
                                to={{
                                    pathname: Routes_path.BARCODE_PRODOTTO + commessa.id,
                                }}
                                // sx={{ bottom: "2px" }}
                                size="small"
                            >Stampa BarCode Materiali</Button>

                        </Stack>
                    }
                />

                <CardContent>
                    <PreparazioneCantiereForm
                        commessa={commessa}
                        consentiModifica={consentiModifica && commessa.stato == "G"}
                    />
                    <AllegatiPage
                        consentiModifica={consentiModifica && ['D', 'E', 'F', 'G'].includes(commessa?.stato || 'N')}
                        commessaId={commessa.id}
                        faseCommessa={"PCA"}
                    />
                </CardContent>
            </Card>
            {/*)}*/}
            {/*{["H", "I", "L", "M", "N"].includes(commessa.stato || "") && (*/}
            <Card elevation={3} sx={{mb: 2}}>
                <CardHeader
                    sx={{
                        backgroundColor: ["I", "L", "M", "N"].includes(commessa.stato || "") ? "#249949" : "#FF2222aa",
                        color: "#dee",
                    }}
                    title={<Stack direction="row" justifyContent="space-between">
                        <Typography>Fase Cantiere</Typography>
                        <Button variant="contained" sx={{mt: 2, color: "#333"}}>
                            {tabellaListaCodiciMatricola.length > 0 ? (
                                <CSVLink
                                    data={tabellaListaCodiciMatricola(
                                        commessa,
                                    )}
                                    style={{color: "#222"}}
                                >
                                    Lista Codici Matricola
                                </CSVLink>
                            ) : (
                                <LinearProgress color="inherit"/>
                            )}
                        </Button>
                    </Stack>}
                />

                <CardContent>
                    <InstallazioneInCorsoForm
                        commessa={commessa}
                        consentiModifica={consentiModifica && commessa.stato == "H"}
                    />
                    <AllegatiPage
                        consentiModifica={consentiModifica && commessa.stato == "H"}
                        commessaId={commessa.id}
                        faseCommessa={"EXC"}
                    />
                </CardContent>
            </Card>
            {/*)}*/}
        </Box>
    );
};

export default EsecuzioneTab;
