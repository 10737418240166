import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../../../redux/store";

// import { Committente } from "../../anagrafiche/anagraficheSlice";
import { Cliente } from "../../../anagrafiche/clienti/clientiSlice";

import { useForm, SubmitHandler, FieldError, set } from "react-hook-form";
import ReactHookFormSelect from "../../../common/ReactHookFormSelect";
import Autocomplete from "@mui/material/Autocomplete";

import { ComunitaEnergetica } from "../../../main/comunitaEnergetiche/comunitaEnergeticheSlice";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

import { Utente, User, saveUtente, invitaUtente } from "../utentiSlice";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import SendIcon from "@mui/icons-material/Send";

import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
// import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

import { Button } from "@mui/material";
import Grid from "@mui/material/Grid";
import * as console from "node:console";

interface UtenteFormProps {
	utente: Utente;
	clienti: Cliente[];
	comunita_energetiche: ComunitaEnergetica[];
	clientePreset?: number;
	cerPreset?: number;
}

const UtenteForm = ({
	utente,
	clienti,
	comunita_energetiche,
	clientePreset,
	cerPreset,
}: UtenteFormProps) => {
	const {
		handleSubmit,
		register,
		formState: { errors, isDirty },
		setValue,
		watch,
		getValues,
		control,
		setError,
		clearErrors,
		reset,
	} = useForm<Utente>();
	const dispatch = useAppDispatch();
	const listaCampi = Object.keys(utente) as Array<keyof Utente>;
	useEffect(() => {
		listaCampi.map((field) => {
			setValue(field, utente[field]);
		});
	}, []);


	useEffect(() => {
		if (!utente?.user?.username) {
			const nome = getValues("user.first_name");
			const cognome = getValues("user.last_name");
			nome &&
			cognome &&
			setValue("user.username", (nome + "." + cognome).toLowerCase());
		}
	}, [watch("user.first_name"), watch("user.last_name")]);

	useEffect(() => {
		clearErrors();
		listaCampi.forEach((field) => {
			utente?.errorsStack?.fieldsErrors &&
				utente.errorsStack.fieldsErrors[field] &&
				setError(field, {
					type: "server",
					message: utente.errorsStack.fieldsErrors[field].toString(),
				});
		});

		// aggiungiamo il controllo su email perchè è un sotto campo di user
		utente?.errorsStack?.fieldsErrors &&
			utente.errorsStack.fieldsErrors["email"] &&
			setError("user.email", {
				type: "server",
				message: utente.errorsStack.fieldsErrors["email"].toString(),
			});
		utente?.errorsStack?.fieldsErrors &&
			utente.errorsStack.fieldsErrors["username"] &&
			setError("user.username", {
				type: "server",
				message: utente.errorsStack.fieldsErrors["username"].toString(),
			});
	}, [utente?.errorsStack?.fieldsErrors]);

	const handlerInvitaUtente = () => {
		utente?.user?.email && dispatch(invitaUtente(utente));
	};


	const [clienteCorrente, setClienteCorrente] = useState<Cliente | undefined>();
	const [cerCorrente, setCerCorrente] = useState<
		ComunitaEnergetica | undefined
	>();

	useEffect(() => {
		if (!utente.id && clientePreset && !clienteCorrente?.id) {
			setValue("cliente", clientePreset);
			setClienteCorrente(
				clienti.find((cliente) => cliente.id == clientePreset || null)
			);
		} else {
			setClienteCorrente(
				clienti.find((cliente) => cliente.id == getValues("cliente") || null)
			);
		}
	}, [watch("cliente")]);

	useEffect(() => {
		if (!utente.id && cerPreset && !cerCorrente?.id) {
			setValue("cer", cerPreset);
			setCerCorrente(
				comunita_energetiche.find((cer) => cer.id == cerPreset || null)
			);
		} else {
			setCerCorrente(
				comunita_energetiche.find((cer) => cer.id == getValues("cer") || null)
			);
		}
	}, [watch("cer")]);

	const onSubmit: SubmitHandler<Utente> = (utente) => {
		// const formValues = getValues();
		// console.log(utente)
		dispatch(saveUtente(utente));
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<Grid
				container
				rowSpacing={1}
				sx={{ my: 1 }}
				columnSpacing={{ xs: 1 }}
				justifyContent="center"
				// alignItems="flex-start"
			>
				{/* --{instanceOfUser(errors?.user)}-- */}
				<Grid item xs={6}>
					<TextField
						id="user.username"
						label="username"
						{...register("user.username")}
						error={!!errors?.user?.username}
						helperText={errors?.user?.username?.message}
						disabled={true}
						InputLabelProps={{
							shrink: true,
						}}
						variant="outlined"
						autoComplete={"off"}
						sx={{ width: "100%", bgcolor: "white" }}
					/>
				</Grid>

				<Grid item xs={6}>
					<TextField
						id="user.email"
						label="user.email"
						{...register("user.email", {
							required: "Campo obbligatorio",
							pattern: {
								value: /\S+@\S+\.\S+/,
								message: "valore dell'email non valido",
							},
						})}
						error={!!errors?.user?.email}
						helperText={errors?.user?.email?.message}
						InputLabelProps={{
							shrink: true,
						}}
						required={true}
						variant="outlined"
						autoComplete={"off"}
						sx={{ width: "100%", bgcolor: "white" }}
					/>
				</Grid>
				<Grid item xs={6}>
					<TextField
						id="user.first_name"
						label="Nome"
						{...register("user.first_name")}
						// error={!!errors?.user.first_name}
						// helperText={errors?.user.first_name?.message}
						required={true}
						InputLabelProps={{
							shrink: true,
						}}
						variant="outlined"
						autoComplete={"off"}
						sx={{ width: "100%", bgcolor: "white" }}
					/>
				</Grid>
				<Grid item xs={6}>
					<TextField
						id="user.last_name"
						label="Cognome"
						{...register("user.last_name")}
						// error={!!errors?.user.last_name}
						// helperText={errors?.user.last_name?.message}
						required={true}
						InputLabelProps={{
							shrink: true,
						}}
						variant="outlined"
						autoComplete={"off"}
						sx={{ width: "100%", bgcolor: "white" }}
					/>
				</Grid>

				<Grid item xs={12}>
					<Divider sx={{ my: 3 }} textAlign="left">
						Cliente / General Contractor
					</Divider>
				</Grid>
				<Grid item xs={6}>
					<Autocomplete
						// multiple
						key={`cliente_${clienteCorrente?.id}`}
						// disabled={getValues("confermato")}
						value={clienteCorrente}
						isOptionEqualToValue={(option, value) => option.id === value.id}
						onChange={(event: any, newValue: any) => {
							// setValue("cliente", newValue?.id || 0);
							setValue("cliente", newValue ? newValue.id : null, {
								shouldDirty: true,
							});
						}}
						id="cliente"
						options={clienti}
						getOptionLabel={(option) =>
							option.ragione_sociale || "senza ragione sociale"
						}
						renderOption={(props, option) => (
							<Box component="li" {...props}>
								{option.ragione_sociale}
							</Box>
						)}
						renderInput={(params: any) => (
							<TextField
								variant="outlined"
								{...params}
								label="cliente"
								error={!!errors?.cliente}
								helperText={errors?.cliente?.message}
							/>
						)}
					/>
				</Grid>
				<Grid item xs={6}>
					<Typography variant="h6">il cliente gestisce le commesse in Sinergie43?</Typography>

					<FormControlLabel
						label={
							<Typography fontSize="0.8rem">
								{getValues("general_contractor")
									? "Si"
									: "No"}
							</Typography>
						}
						control={
							<Switch
								id="general_contractor"
								disabled={!watch("cliente")}
								checked={watch("general_contractor") || false}
								onChange={(
									event: React.ChangeEvent<HTMLInputElement>,
									newValue: any
								) => {
									setValue("general_contractor", newValue, {
										shouldDirty: true,
									});
								}}
								color="info"
								size="small"
							/>
						}
					/>
				</Grid>

				<Grid item xs={12}>
					<Divider sx={{ my: 3 }} textAlign="left">
						Membro CER
					</Divider>
				</Grid>
				<Grid item xs={6}>
					<Autocomplete
						// multiple
						key={`cer_${cerCorrente?.id}`}
						// disabled={getValues("confermato")}
						value={cerCorrente}
						isOptionEqualToValue={(option, value) => option.id === value.id}
						onChange={(event: any, newValue: any) => {
							// setValue("cliente", newValue?.id || 0);
							setValue("cer", newValue ? newValue.id : null, {
								shouldDirty: true,
							});
						}}
						id="cer"
						options={comunita_energetiche}
						getOptionLabel={(option) => option.nome || "senza nome"}
						renderOption={(props, option) => (
							<Box component="li" {...props}>
								{option.nome}
							</Box>
						)}
						renderInput={(params: any) => (
							<TextField
								variant="outlined"
								{...params}
								label="cer"
								error={!!errors?.cer}
								helperText={errors?.cer?.message}
							/>
						)}
					/>
				</Grid>
				<Grid item xs={6}></Grid>
			</Grid>
			<Stack direction="row" spacing={2} justifyContent="center" sx={{ mt: 2 }}>
				<Button disabled={!isDirty} variant="contained" type="submit">
					Salva
				</Button>

				<Button
					variant="contained"
					onClick={handlerInvitaUtente}
					endIcon={<SendIcon />}
					disabled={!utente.user?.email}
				>
					Manda invito a {utente.user?.email || "..."}
				</Button>
			</Stack>
		</form>
	);
};

export default UtenteForm;
