import { useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Routes_path } from "../../../../routerApp";

import {
	IterConnessione,
	presaInCarico,
	avviaProgetto,
	assegnaPratica,
	deleteIterConnessione,
} from "components/main/iterConnessioni/iterConnessioneSlice";
import { Soggetto } from "../../../../anagrafiche/produttori/produttoriSlice";

import { DateTime } from "luxon";

import { RootState, useAppDispatch } from "../../../../../redux/store";

import IterConnessioneNoteInterneForm from "./IterConnessioneNoteInterneForm";

import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import ConfermaAnnulla from "../../../../common/ConfermaAnnulla";

import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
interface AzioniGestoreCardProps {
	iterConnessione: IterConnessione;
}

const AzioniGestoreCard = ({ iterConnessione }: AzioniGestoreCardProps) => {
	
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const produttore: Soggetto | undefined = useSelector<
		RootState,
		Soggetto | undefined
	>((state) =>
		state.produttoriState.produttori.results.find(
			(produttore) => produttore?.id == iterConnessione?.produttore
		)
	);

	const [openConferma, setOpenConferma] = useState(false);
	const handleConfermaClose = () => setOpenConferma(false);
	return (
		<Card sx={{ mt: 2, backgroundColor: "#ddd" }}>
			<CardContent>
				<Stack sx={{ width: "100%" }} spacing={2}>
					<IterConnessioneNoteInterneForm iterConnessione={iterConnessione} />
					<CardContent>
						<Button
							color="secondary"
							variant="contained"
							size="large"
							sx={{ width: "100%" }}
							onClick={() => setOpenConferma(true)}
						>
							Cancella Iter Connessione
						</Button>
						<ConfermaAnnulla
							handleAnnulla={handleConfermaClose}
							handleConferma={() => {
								dispatch(deleteIterConnessione(iterConnessione));
								navigate(`${Routes_path.ITER_CONNESSIONI}`, {
									replace: true,
								});
							}}
							domanda={"Sicuro di voler cancellare la richiesta"}
							open={openConferma}
						/>
					</CardContent>
				</Stack>
			</CardContent>
		</Card>
	);
};

export default AzioniGestoreCard;
