import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, Link, useNavigate } from "react-router-dom";

import { RootState, useAppDispatch } from "../../../redux/store";
import { Routes_path } from "../../routerApp";
import {
	UtenteLogged,
	Utente,
	UtenteApplicativo,
} from "../../authentication/authenticationSlice";

import { DateTime } from "luxon";

import {
	ErrorStatusTypes,
	ErrorsStack,
	parseErrorMessage,
} from "../../common/errorsDeclarations";
import { utenteAutorizzato } from "../../authentication/UtenteAutorizzato";
import { PermissionTypes } from "../../authentication/permissionsGroups";
import {
	OrdinamentoLista,
	PreferenzeLista,
	setPreferenzeListaComunitaEnergetiche,
} from "../../userPreference/userPreferenceSlice";

import ComunitaEnergetichePageBreadcrumbs from "./ComunitaEnergetichePageBreadcrumbs";
import {
	fetchComunitaEnergetiche,
	ComunitaEnergetica,
	ComunitaEnergeticheState,
} from "./comunitaEnergeticheSlice";
import { RegimeCommerciale } from "../../anagrafiche/regimiCommerciali/regimiCommercialiSlice";
import {
	StatoIterTypes,
	StatoConnessioneTypes,
	StatiIter,
	StatoIter,
} from "../../anagrafiche/kpi/kpiSlice";
import {
	Soggetto,
	resetProduttoreCorrente,
} from "../../anagrafiche/produttori/produttoriSlice";

// import IterConnessioneAlerChip from "./comunitaEnergetica/IterConnessioneAlerChip";
import { Cliente } from "../../anagrafiche/clienti/clientiSlice";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";
import Chip from "@mui/material/Chip";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import AddIcon from "@mui/icons-material/Add";

import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import Pagination from "@mui/material/Pagination";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import { TipoPratica } from "components/anagrafiche/tipiPratica/tipiPraticaSlice";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import ReplayIcon from "@mui/icons-material/Replay";

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import {convertToDateTime} from "../../common/dateUtils/convertToDateTime";

const produttoreFullName = (produttore?: Soggetto) => {
	if (produttore) {
		if (produttore.tipo_persona == "PF") {
			return `${produttore.cognome} ${produttore.nome}`;
		} else {
			return `${produttore.ragione_sociale}`;
		}
	} else {
		return "anonimo";
	}
};

const visualizzaStatoIter = (statoIter?: StatoIter) => {
	return statoIter && `${statoIter.stato_desc} (${statoIter.count})`;
};

const ComunitaEnergetichePage = () => {
	
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	type LocationState = {
		statoComunitaEnergeticaId?: string;
		clienteId?: number;
		iterSemplificato?: string;
	};
	const location = useLocation();

	const preferenzeListaComunitaEnergetiche = useSelector<
		RootState,
		PreferenzeLista | undefined
	>((state) => state.userPreferenceState.preferenzeListaComunitaEnergetiche);

	const utentiApplicativo = useSelector<RootState, UtenteApplicativo[]>(
		(state) => state.authentication.utentiApplicativo.results
	);

	const [statoComunitaEnergeticaId, setStatoComunitaEnergeticaId] = useState(
		location.state
			? (location.state as LocationState).statoComunitaEnergeticaId
			: preferenzeListaComunitaEnergetiche?.stato
		//  : undefined
	);

	const [clienteId, setClienteIterId] = useState(
		location.state
			? (location.state as LocationState).clienteId
			: preferenzeListaComunitaEnergetiche?.clienteIterId
		// : undefined
	);

	const comunitaEnergetiche = useSelector<RootState, ComunitaEnergeticheState>(
		(state) => state.comunitaEnergeticheState.comunitaEnergetiche
	);
	const utente = useSelector<RootState, UtenteLogged>(
		(state) => state.authentication.utenteLogged
	);
	const statiIter = useSelector<RootState, StatiIter>(
		(state) => state.kpiState.statiIter
	);

	const clienti = useSelector<RootState, Cliente[]>(
		(state) => state.clientiState.clienti.results
	);
	const produtori = useSelector<RootState, Soggetto[]>(
		(state) => state.produttoriState.produttori.results
	);

	const tipiPratica = useSelector<RootState, TipoPratica[]>(
		(state) => state.tipiPraticaState.tipiPratica.results
	);

	const produttori = useSelector<RootState, Soggetto[]>(
		(state) => state.produttoriState.produttori.results
	);

	const [page, setPage] = useState<number>(
		preferenzeListaComunitaEnergetiche?.page || 1
	);
	const [numeroRecord, setNumeroRecord] = useState<number>(
		preferenzeListaComunitaEnergetiche?.numeroRecord || 10
	);
	const [search, setSearch] = useState<string | undefined>(undefined);

	const handleChangeNumeroRecord = (event: SelectChangeEvent) => {
		setNumeroRecord(Number(event.target.value));
	};

	const handlerPager = (event: React.ChangeEvent<unknown>, page: number) => {
		setPage(page);
	};

	const [refresh, setRefresh] = useState<boolean>(false);

	const [anchorElStatoFilter, setAnchorElStatoFilter] = useState(null);
	const [anchorElClienteFilter, setAnchorElClienteFilter] = useState(null);
	const [anchorElTipoIterFilter, setAnchorElTipoIterFilter] = useState(null);

	const handleStatoFilterClose = () => {
		setAnchorElStatoFilter(null);
		setAnchorElClienteFilter(null);
		setAnchorElTipoIterFilter(null);
		// onClose(selectedValue);
	};

	useEffect(() => {
		dispatch(
			fetchComunitaEnergetiche({
				numeroRecord: numeroRecord,
				page: page,
				search: search,
			})
		);
		dispatch(
			setPreferenzeListaComunitaEnergetiche({
				numeroRecord: numeroRecord,
				page: page,
				stato: statoComunitaEnergeticaId,
			})
		);
		setRefresh(false);
		setRefresh(false);
	}, [
		page,
		numeroRecord,
		search,
		statoComunitaEnergeticaId,
		clienteId,
		refresh,
	]);

	return (
		<Box
			sx={{
				backgroundColor: "#fff",
				elevation: 20,
				// p: 2,
				"& .campo-editabile": {
					backgroundColor: "#bbbbbb11",
					background: "linear-gradient(to right bottom, #f5f7f6, #dce3e0)",
					// borderWidth: "5px",
					borderColor: "#ff91003d",
					// borderColor: "#0000003d",
					// borderStyle: "outset",
					borderWidth: 3,
					borderStyle: "ridge",
					color: "#1a3e72",
					fontWeight: "600",
				},
				"& .campo-errore": {
					backgroundColor: "rgba(124, 3, 0, 0.3)",
					color: "#ffffff",
					fontWeight: "600",
				},
			}}
		>
			<ComunitaEnergetichePageBreadcrumbs />
			<List sx={{ mt: 2 }}>
				<ListItem>
					<ButtonGroup
						variant="outlined"
						aria-label="outlined button group"
						sx={{ mb: 2 }}
					>
						<Button
							color="primary"
							startIcon={<ReplayIcon />}
							onClick={() => setRefresh(true)}
						>
							aggiorna
						</Button>
					</ButtonGroup>
				</ListItem>
				<ListItemText
					key={"Lista_saldo_pallet_committente"}
					sx={{ backgroundColor: "#1b75b1", color: "#ddd", mb: 1 }}
				>
					<Grid container spacing={{ xs: 0.5, md: 1, lg: 2 }} sx={{ px: 2 }}>
						<Grid item xs={2} sm={2} md={1} lg={4}>
							<Stack direction="row" spacing={3}>
								<Typography variant="h5" gutterBottom>
									n°
								</Typography>
								<Typography variant="h5" gutterBottom>
									CER
								</Typography>
							</Stack>
						</Grid>
						<Grid item xs={3} sm={3} md={2} lg={4}>
							<Typography variant="h5" gutterBottom>
								Refente
							</Typography>
						</Grid>

						<Grid item xs={6} sm={6} md={6} lg={2}>
							<Typography variant="h5" gutterBottom>
								Data Costituzione
							</Typography>
						</Grid>
					</Grid>
				</ListItemText>

				<Backdrop
					sx={{
						color: "#fff",
						zIndex: (theme) => theme.zIndex.drawer + 1,
					}}
					open={
						comunitaEnergetiche.errorsStack.status == ErrorStatusTypes.PENDING
						// errorsStack.status == ErrorStatusTypes.PENDING
					}
				>
					<CircularProgress color="inherit" />
				</Backdrop>

				{comunitaEnergetiche.results?.map((comunitaEnergetica, index) => (
					<ListItemButton
						// key={"iter_" + comunitaEnergetica.id}
						key={index}
						component={Link}
						to={Routes_path.COMUNITA_ENERGETICA + comunitaEnergetica.id}
						sx={{
							color: "black",
							textDecoration: "none",
							backgroundColor: index % 2 === 0 ? "#fff" : "#f2f2f2",
						}}
					>
						<Grid container spacing={{ xs: 0.5, md: 1, lg: 2 }} sx={{ mb: 1 }}>
							<Grid item xs={6} sm={6} md={11} lg={2}>
								{/* <Link
									to={Routes_path.COMMESSA + comunitaEnergetica.id}
									key={`comunitaEnergetica_${comunitaEnergetica.id}`}
								> */}
								<Stack direction="row" spacing={1}>
									<Chip
										color="primary"
										variant="outlined"
										label={comunitaEnergetica?.id}
									/>
									<Typography variant="h6" noWrap>
										{comunitaEnergetica.nome}
									</Typography>
								</Stack>
							</Grid>
							<Grid item xs={6} sm={6} md={6} lg={2}>
								<Typography noWrap>{comunitaEnergetica.descrizione}</Typography>
							</Grid>

							<Grid item xs={6} sm={6} md={6} lg={4}>
								<Typography noWrap>
									{utentiApplicativo.find(
										(utente) => utente.user.id == comunitaEnergetica.referente
									)?.user.full_name || "-"}
								</Typography>
							</Grid>

							<Grid item xs={6} sm={6} md={6} lg={2}>
								{comunitaEnergetica.data_creazione_cer
									? convertToDateTime(comunitaEnergetica.data_creazione_cer)?.setLocale("it").toFormat("dd/MM/yyyy")
									: ""}
							</Grid>
						</Grid>
					</ListItemButton>
				))}

				<ListItem
					sx={{
						alignContent: "right",
						width: "100%",
						mt: 2,
						borderTopStyle: "solid",
						borderTopWidth: 1,
					}}
				>
					<Stack
						direction="row"
						justifyContent="space-between"
						alignItems="center"
						spacing={2}
						sx={{ width: "100%" }}
					>
						<Pagination
							count={comunitaEnergetiche.num_pages}
							onChange={handlerPager}
							page={page}
						/>
						{statoComunitaEnergeticaId || clienteId ? (
							<Typography noWrap>
								Totale comunitaEnergetiche filtrati:{"  "}
								{comunitaEnergetiche.count}
							</Typography>
						) : (
							<Typography noWrap>
								Totale comunitaEnergetiche:{"  "}
								{comunitaEnergetiche.count}
							</Typography>
						)}

						<TextField
							id="input-with-icon-textfield"
							// label="TextField"
							InputProps={{
								startAdornment: (
									<InputAdornment position="end">
										<SearchIcon />
									</InputAdornment>
								),
							}}
							variant="standard"
							value={search}
							onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
								setSearch(event.target.value);
							}}
						/>
						<FormControl>
							<InputLabel id="demo-simple-select-label">Record</InputLabel>
							<Select
								labelId="demo-simple-select-label"
								id="demo-simple-select"
								value={numeroRecord.toString()}
								label="Age"
								onChange={handleChangeNumeroRecord}
								variant="outlined"
							>
								<MenuItem value={10}>10</MenuItem>
								<MenuItem value={20}>20</MenuItem>
								<MenuItem value={50}>50</MenuItem>
							</Select>
						</FormControl>
					</Stack>
				</ListItem>
			</List>
		</Box>
	);
};

export default ComunitaEnergetichePage;
