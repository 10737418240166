import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../../redux/store";

import { Cliente, fetchClienti } from "../../anagrafiche/clienti/clientiSlice";
import UtentiList from "./UtentiList";
// import CommittentiList from "./CommittentiList";

import { Routes_path } from "../../routerApp";

import {
	ComunitaEnergetica,
	fetchComunitaEnergetiche,
} from "../../main/comunitaEnergetiche/comunitaEnergeticheSlice";

import Stack from "@mui/material/Stack";

import UtentiPageBreadcrumbs from "./UtentiPageBreadcrumbs";

import { Box } from "@mui/system";
import Button from "@mui/material/Button";
import { Card, CardContent, CardHeader, Typography } from "@mui/material";

interface UtentiPageProps {}

const UtentiPage = () => {
	const dispatch = useAppDispatch();

	// const utenti: Utenti = useSelector<RootState, Utenti>(
	// 	(state) => state.utentiState.utenti
	// );

	const clienti: Cliente[] = useSelector<RootState, Cliente[]>(
		(state) => state.clientiState.clienti.results
	);

	const comunita_energetiche: ComunitaEnergetica[] = useSelector<
		RootState,
		ComunitaEnergetica[]
	>((state) => state.comunitaEnergeticheState.comunitaEnergetiche.results);

	const [refresh, setRefresh] = React.useState<boolean>(false);

	useEffect(() => {
		dispatch(fetchClienti({ numeroRecord: 1000 }));
		dispatch(fetchComunitaEnergetiche({ numeroRecord: 1000 }));
		setRefresh(false);
		// dispatch(
		// 	fetchUtenti({ numeroRecord: numeroRecord, page: page, search: search })
		// );
		// dispatch(
		// 	setPreferenzeListaUtenti({
		// 		numeroRecord: numeroRecord,
		// 		page: page,
		// 	})
		// );
	}, [refresh]);

	return (
		<Box sx={{ p: 2 }}>
			<UtentiPageBreadcrumbs />
			{/* <Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={utenti.errorsStack.status == ErrorStatusTypes.PENDING}
			>
				<CircularProgress color="inherit" />
			</Backdrop> */}
			<Card>
				<CardHeader
					sx={{ backgroundColor: "#b0cab0" }}
					title={
						<Stack
							direction="row"
							justifyContent="space-between"
							alignItems="center"
							spacing={1}
						>
							<Typography variant="h4" color="white" gutterBottom>
								Lista Utenti
							</Typography>

							<Button
								component={Link}
								variant="contained"
								to={{
									pathname: Routes_path.UTENTE,
								}}
								state={{
									utente_id: null,
								}}
							>
								crea Utente
							</Button>
						</Stack>
					}
				/>
				<CardContent>
					<UtentiList
						// utenti={utenti}
						clienti={clienti}
						comunita_energetiche={comunita_energetiche}
					/>
				</CardContent>
			</Card>
		</Box>
	);
};

export default UtentiPage;
