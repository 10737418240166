import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, Link } from "react-router-dom";

import { RootState, useAppDispatch } from "../../redux/store";
import { Routes_path } from "../routerApp";
import RegimiCommercialiGrid from "./regimiCommerciali/RegimiCommercialiGrid";
import InstallatoriPage from "./installatori/InstallatoriPage";
import TipiTensioneGrid from "./tipiTensione/TipiTensioneGrid";
import TipiPraticaGrid from "./tipiPratica/TipiPraticaGrid";
import ClientiPage from "./clienti/ClientiPage";
import GestoriGrid from "./gestori/GestoriGrid";

import { DateTime } from "luxon";


import { ErrorStatusTypes } from "../common/errorsDeclarations";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

import { UtenteLogged } from "../authentication/authenticationSlice";

import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";

import Stack from "@mui/material/Stack";
import { theme } from "../theme";

const AnagrafichePage = () => {
	
	const dispatch = useAppDispatch();

	const location = useLocation();
	const utente = useSelector<RootState, UtenteLogged>(
		(state) => state.authentication.utenteLogged
	);

	const [refresh, setRefresh] = useState<boolean>(false);

	useEffect(() => {
		setRefresh(false);
	}, [refresh]);

	const [currentTab, setCurrentTab] = useState("1");
	const handleTabChange = (event: any, newValue: string) => {
		setCurrentTab(newValue);
	};
	return (
		<Box
			sx={{
				backgroundColor: "#fff",
				elevation: 20,
				p: 2,
			}}
		>
			<Backdrop
				sx={{
					color: "#fff",
					zIndex: (theme) => theme.zIndex.drawer + 1,
				}}
				open={
					false
					// lotto.errorsStack.status == ErrorStatusTypes.PENDING ||
					// errorsStack.status == ErrorStatusTypes.PENDING
				}
			>
				<CircularProgress color="inherit" />
			</Backdrop>
			<Stack
				direction="row"
				justifyContent="flex-start"
				alignItems="flex-start"
				spacing={4}
				sx={{ mb: 1 }}
			>
				<Typography
					variant="h4"
					// component="div"
					// color="text.secondary"
					gutterBottom
				>
					Gestione Anagrafiche
				</Typography>
			</Stack>

			<TabContext value={currentTab}>
				<Box
					sx={{
						borderBottom: 1,
						borderColor: "divider",
					}}
				>
					<TabList
						onChange={handleTabChange}
						aria-label="lab API tabs example"
						variant="scrollable"
						scrollButtons="auto"
					>
						<Tab label="CLIENTI/GENERAL CONTRACTOR" value="1" />
						<Tab label="INSTALLATORI" value="3" />
						<Tab label="ENTI" value="4" />
						<Tab label="REGIMI COMMERCIALI" value="5" />
						<Tab label="TIPI TENSIONE" value="6" />
						<Tab label="TIPI PRATICA" value="7" />
					</TabList>
				</Box>
				<TabPanel value="1">
					<Box sx={{ minHeight: 50 }}>
						{/* <Typography variant="h4">Anagrafiche Clienti</Typography> */}
						<ClientiPage />
					</Box>
				</TabPanel>
				<TabPanel value="3">
					<Box sx={{ minHeight: 50 }}>
						<InstallatoriPage />
					</Box>
				</TabPanel>
				<TabPanel value="4">
					<Box sx={{ minHeight: 50 }}>
						{/* <Typography variant="h4">Anagrafiche Enti</Typography> */}
						<Box>
							<GestoriGrid />
						</Box>
					</Box>
				</TabPanel>
				<TabPanel value="5">
					<Box sx={{ minHeight: 50 }}>
						{/* <Typography variant="h4">Anagrafiche Regimi Commerciali</Typography> */}
					</Box>
					<Box>
						<RegimiCommercialiGrid />
					</Box>
				</TabPanel>
				<TabPanel value="6">
					<Box sx={{ minHeight: 50 }}>
						{/* <Typography variant="h4">Anagrafiche Tipi Tensione</Typography> */}
					</Box>
					<Box>
						<TipiTensioneGrid />
					</Box>
				</TabPanel>
				<TabPanel value="7">
					<Box sx={{ minHeight: 50 }}>
						{/* <Typography variant="h4">Anagrafiche Tipi Pratica</Typography> */}
					</Box>
					<Box>
						<TipiPraticaGrid />
					</Box>
				</TabPanel>
			</TabContext>
		</Box>
	);
};

export default AnagrafichePage;
