import React from "react";
import ReactPDF from "@react-pdf/renderer";
import { DateTime } from "luxon";

import { convertToDateTime } from "components/common/dateUtils/convertToDateTime";

import { Text, View, StyleSheet, Image } from "@react-pdf/renderer";
import { IterConnessione } from "../../iterConnessioneSlice";

import { Soggetto } from "../../../../anagrafiche/produttori/produttoriSlice";
import { Comune } from "../../../../anagrafiche/comuni/comuniSlice";

const logoI43 = require("../../../../../../static/images/logo/logo-I43_lungo.png");

// Create styles
const styles = StyleSheet.create({
	page: {
		backgroundColor: "#fff",
		paddingTop: 20,
	},
	section: {
		marginTop: 10,
		marginLeft: 10,
		marginRight: 10,
		padding: 10,
	},
	sectionIntestazione: {
		// width: "100%",
		marginTop: 5,
		marginLeft: 10,
		marginRight: 10,
		padding: 10,
		paddingTop: 10,
		paddingBottom: 7,
		backgroundColor: "#eee",
		color: "#ffffff",
	},
	text: {
		fontSize: 11,
		fontStyle: "normal",
	},
});

interface NuovaIstanzaPdfProps {
	iterConnessione: IterConnessione;
	produttore: Soggetto;
	comune: Comune;
}

const NuovaIstanzaPdf = ({
	iterConnessione,
	produttore,
	comune,
}: NuovaIstanzaPdfProps) => (
	<View
		style={{
			flexDirection: "column",
			justifyContent: "space-between",
			height: 820,
		}}
		key={"iterConnessione_" + iterConnessione.id}
	>
		<View>
			<View
				style={{
					flexDirection: "row",
					justifyContent: "space-between",
					marginBottom: 0,
					padding: 30,
				}}
			>
				<View
					style={{
						flexDirection: "column",
						justifyContent: "space-between",
						marginTop: 0,
						paddingTop: 0,
					}}
				>
					<Text style={{ fontSize: 14, color: "#03024d" }}>
						{produttore.ragione_sociale}
					</Text>
					<Text style={{ fontSize: 14, color: "#03024d" }}>
						{produttore.indirizzo},{produttore.numero_civico}
					</Text>
					<Text style={{ fontSize: 14, color: "#03024d" }}>
						{comune.nome} ({comune.provincia})
					</Text>

					<Text style={{ fontSize: 14, color: "#03024d" }}>
						{produttore.cellulare}, {produttore.telefono_fisso}
					</Text>
					<Text style={{ fontSize: 30, color: "#fff" }}> </Text>
				</View>
				<View
					style={{
						flexDirection: "column",
						justifyContent: "space-between",
						paddingTop: 0,
					}}
				>
					<Text style={{ fontSize: 30, color: "#fff" }}> </Text>
					<Text style={{ fontSize: 14, color: "#03024d" }}>
						AGENZIA DELLE OFFICINA_ELETTRICA
					</Text>
					<Text style={{ fontSize: 14, color: "#03024d" }}>
						UFFICIO DELLE OFFICINA_ELETTRICA DI PADOVA
					</Text>
					<Text style={{ fontSize: 14, color: "#03024d" }}>
						Corso Stati uniti, 16
					</Text>

					<Text style={{ fontSize: 14, color: "#03024d" }}>35127 - PADOVA</Text>
					{/* <Text
					style={[
						styles.text,
						{
							fontWeight: "bold",
							marginBottom: 1,
							paddingBottom: 0,
							fontSize: 20,
						},
					]}
				>
					{"I43 "}
					<Image
						src={logoI43}
						style={{
							width: 30,
							height: 30,
							// margin: 0,
							padding: 0,
							paddingLeft: "10px",
							marginLeft: "3px",
						}}
					/>
				</Text> */}
				</View>
			</View>
			<View
				style={{
					flexDirection: "row",
					justifyContent: "space-between",
					padding: 30,
				}}
			>
				<Text style={[styles.text, { paddingTop: "6px" }]}>
					OGGETTO: istanza per il rilascio della Licenza di Officina Elettrica
					per l’esercizio di un impianto di produzione di energia elettrica a
					mezzo impianti fotovoltaici in autoconsumo (Regime SSP-GSE) a seguito
					di costruzione e connessione alla rete elettrica di E-Distribuzione
					Spa avvenuto in data 20/03/2023.
				</Text>
			</View>
			<View
				style={{
					flexDirection: "row",
					justifyContent: "space-between",
					paddingLeft: 30,
					paddingRight: 30,
				}}
			>
				<Text style={[styles.text, { paddingTop: "6px" }]}>
					Il sottoscritto {produttore.nome} {produttore.cognome} nato a{" "}
					{comune.nome} ({comune.provincia}) il{" "}
					{convertToDateTime(produttore.data_nascita)?.setLocale("it").toFormat("dd LLLL yyyy")}
					, codice fiscale:{" "}
					{produttore.codice_fiscale}, residente a........
				</Text>
			</View>
			<View
				style={{
					flexDirection: "row",
					justifyContent: "center",
					paddingLeft: 30,
					paddingRight: 30,
				}}
			>
				<Text style={[styles.text, { paddingTop: "6px", fontSize: 18 }]}>
					CHIEDE
				</Text>
			</View>
			<View
				style={{
					flexDirection: "row",
					justifyContent: "center",
					paddingLeft: 30,
					paddingRight: 30,
				}}
			>
				<Text style={[styles.text, { paddingTop: "6px" }]}>
					il rilascio della licenza per l’esercizio di un impianto di produzione
					di energia elettrica da fonte fotovoltaica della potenza nominale di
					{iterConnessione.pod_commessa?.potenza_disponibile}, in regime di SSP,
					installato {iterConnessione.pod_commessa?.indirizzo} ,
					{iterConnessione.pod_commessa?.numero_civico}
					{iterConnessione.pod_commessa?.comune}
					Borgoricco (PD), ai sensi dell’art. 53 bis del TUA, e la vidimazione
					del registro delle letture per l’anno in corso.
				</Text>
			</View>

			<View
				style={[
					styles.section,
					{ marginTop: 0, paddingLeft: 30, paddingRight: 30 },
				]}
			>
				<Text style={[styles.text, { textTransform: "uppercase" }]}>
					Luogo e data
				</Text>
				<Text
					style={[styles.text, { marginTop: 10, textTransform: "uppercase" }]}
				>
					{"___________________ : ____________________"}
				</Text>
			</View>
			<View style={[styles.section, { marginLeft: 250 }]}>
				<Text style={styles.text}>FIRMA E TIMBROFIRMA E TIMBRO</Text>
				<Text style={[styles.text, { marginLeft: 50, marginTop: 30 }]}>
					..............................................
				</Text>
			</View>
		</View>
		<View style={[styles.section, { borderWidth: 0.5, marginTop: 2 }]}>
			<Text style={styles.text}>
				Recapiti del referente tecnico: ing. Daniele De Monte – 3665916571 –
				d.demonte@i43.it
			</Text>
		</View>

		<View style={[styles.section, { marginTop: 20 }]}>
			<Text style={[styles.text, { marginTop: 5 }]}>
				In allegato alla presente (documenti siglati o firmati dal leg.
				rappresentante):
			</Text>
			<Text style={[styles.text]}>
				• Denuncia di prima istanza in bollo (16,00€) in duplice copia;
			</Text>
			<Text style={[styles.text]}>
				• N°1 marca da bollo da 16,00 per la Licenza di esercizio;
			</Text>
			<Text style={[styles.text]}>
				• Copia di documento di riconoscimento in corso di validità del legale
				rappresentante;
			</Text>
			<Text style={[styles.text]}>
				• Istanza per richiesta vidimazione del registro delle letture di
				energia elettrica;
			</Text>
			<Text style={[styles.text]}>• Copia registro anno 2023 da vidimare;</Text>
			<Text style={[styles.text]}>
				• Attestazione versamento di € 23,24 – copia bonifico su iban
				IT91Z0100003245221001141901 per Padova – Causale “rilascio licenza
				energia elettrica – capo I° capitolo 1419”;
			</Text>
			<Text style={[styles.text]}>
				• Schema elettrico sottoscritto dal progettista;
			</Text>
			<Text style={[styles.text]}>
				• Lay-out della disposizione dei pannelli in copertura;
			</Text>
			<Text style={[styles.text]}>
				• Copia della Dichiarazione di Conformità dell’impianto ai sensi del DM
				37 del 2008;
			</Text>
			<Text style={[styles.text]}>
				• Copia estratto di mappa scala 1:2000 del sito;
			</Text>
			<Text style={[styles.text]}>
				• Copia dei Verbali di Attivazione dell’impianto del Gestore di Rete;
			</Text>
			<Text style={[styles.text]}>
				• Verifica Taratura Contatore Energia Prodotta*
			</Text>
			<Text style={[styles.text]}>
				• Dichiarazione sostitutiva di atto notorio;
			</Text>
			<Text style={[styles.text]}>
				• Copia Visura Camerale (stralcio ove si evince l’indirizzo della sede e
				dell’officina)
			</Text>
			<Text style={[styles.text]}>
				* verrà consegnato in Dogana Appena Rilasciato dall’Ente Certificatore
			</Text>
		</View>
		<Text break />
	</View>
);

export default NuovaIstanzaPdf;
