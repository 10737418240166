import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { Cliente, saveCliente } from "./clientiSlice";
import { useAppDispatch, RootState } from "../../../redux/store";
import { useNavigate } from "react-router-dom";

import { useForm, SubmitHandler, Controller } from "react-hook-form";
import FormControlLabel from "@mui/material/FormControlLabel";
import ReactHookFormSelect from "../../common/ReactHookFormSelect";
import { Comune } from "../comuni/comuniSlice";
import {
	IterConnessione,
	saveIterConnessione,
} from "../../main/iterConnessioni/iterConnessioneSlice";
import MenuItem from "@mui/material/MenuItem";

import { Routes_path } from "../../routerApp";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import Stack from "@mui/material/Stack";

import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

import Alert from "../../common/Alert";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import FormHelperText from "@mui/material/FormHelperText";

import { Button, Divider } from "@mui/material";
import { theme } from "../../theme";

interface ClienteFormProps {
	cliente: Cliente;
}

const ClienteForm = ({ cliente }: ClienteFormProps) => {
	const {
		handleSubmit,
		register,
		formState: { errors, isDirty, isValid },
		setValue,
		watch,
		getValues,
		control,
		setError,
		clearErrors,
		reset,
	} = useForm<Cliente>();

	const listaCampi = Object.keys(cliente) as Array<keyof Cliente>;

	const dispatch = useAppDispatch();

	const navigate = useNavigate();
	const [pesonaFisica, setPesonaFisica] = useState<boolean>(true);

	const comuni = useSelector<RootState, Comune[]>(
		(state) => state.comuniState.comuni.results
	);

	const [comuneCorrente, setComuneCorrente] = useState<Comune | undefined>(
		undefined
	);

	useEffect(() => {
		reset();
		listaCampi.map((field) => {
			setValue(field, cliente[field]);
		});
	}, []);

	useEffect(() => {
		setComuneCorrente(
			comuni.find((comuneScelto) => comuneScelto.id == getValues("comune"))
		);
	}, [comuni, getValues("comune")]);

	useEffect(() => {
		clearErrors();
		listaCampi.forEach((field) => {
			cliente?.errorsStack?.fieldsErrors &&
				cliente.errorsStack.fieldsErrors[field] &&
				setError(field, {
					type: "server",
					message: cliente.errorsStack.fieldsErrors[field].toString(),
				});
		});
	}, [
		cliente?.errorsStack?.fieldsErrors,
		// produttore.allevamento_pack?.errorsStack?.fieldsErrors,
	]);

	const handlerSaveCliente = (cliente: Cliente) => {
		dispatch(saveCliente(cliente));
	};

	const onSubmit: SubmitHandler<Cliente> = (cliente) => {
		handlerSaveCliente(cliente);
	};

	return (
		<Box sx={{ my: 1, pt: 2 }}>
			<form onSubmit={handleSubmit(onSubmit)}>
				{cliente?.errorsStack?.fieldsErrors?.non_field_errors && (
					<Alert severity="error">
						{cliente?.errorsStack?.fieldsErrors?.non_field_errors.toString()}
					</Alert>
				)}
				<Grid
					container
					spacing={2}
					sx={{ mb: 2, ...(!pesonaFisica && { display: "none" }) }}
				>
					<Grid item xs={12} sm={6} md={6} lg={5}>
						<TextField
							label="ragione sociale"
							required
							variant="outlined"
							InputLabelProps={{
								shrink: true,
							}}
							{...register("ragione_sociale")}
							error={!!errors?.ragione_sociale}
							helperText={errors?.ragione_sociale?.message}
							autoComplete={"off"}
							sx={{ width: "100%" }}
							fullWidth
							// onBlur={handlerDetailChaingSubmit}
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={6} lg={2}>
						<TextField
							label="p.iva"
							// disabled={schedaCarico.numero_capi_pianificati}
							variant="outlined"
							InputLabelProps={{
								shrink: true,
							}}
							{...register("piva")}
							error={!!errors?.piva}
							helperText={errors?.piva?.message}
							autoComplete={"off"}
							sx={{ width: "100%" }}
							fullWidth
							// onBlur={handlerDetailChaingSubmit}
						/>
					</Grid>

					<Grid item xs={12} sm={6} md={6} lg={2}>
						<TextField
							label="numero abilitazione professionale"
							// disabled={schedaCarico.numero_capi_pianificati}
							variant="outlined"
							InputLabelProps={{
								shrink: true,
							}}
							{...register("numero_abilitazione_professionale")}
							error={!!errors?.numero_abilitazione_professionale}
							helperText={errors?.numero_abilitazione_professionale?.message}
							autoComplete={"off"}
							sx={{ width: "100%" }}
							fullWidth
							// onBlur={handlerDetailChaingSubmit}
						/>
					</Grid>
					<Grid item xs={12} sm={4} md={4} lg={2}>
						<FormControlLabel
							value={
								watch("general_contractor_abilitato_commesse") ||
								cliente?.general_contractor_abilitato_commesse
							}
							checked={watch("general_contractor_abilitato_commesse") || false}
							control={<Checkbox />}
							label={
								watch("general_contractor_abilitato_commesse")
									? "General Contractor Abilitato Commesse"
									: "Cliente Privato o GC non abilitato commesse"
							}
							labelPlacement="end"
							onChange={(e, checked) => {
								setValue("general_contractor_abilitato_commesse", checked, {
									shouldDirty: true,
								});
							}}
							// disabled={!consentiModifica}
							style={{ padding: 0 }}
						/>
						{!!errors?.general_contractor_abilitato_commesse && (
							<FormHelperText error>
								{errors?.general_contractor_abilitato_commesse.toString()}
							</FormHelperText>
						)}
					</Grid>
				</Grid>
				<Grid
					container
					spacing={2}
					sx={{ mb: 2, ...(!pesonaFisica && { display: "none" }) }}
				>
					<Grid item xs={12} sm={6} md={6} lg={2}>
						<TextField
							label="indirizzo"
							// disabled={schedaCarico.numero_capi_pianificati}
							variant="outlined"
							InputLabelProps={{
								shrink: true,
							}}
							{...register("indirizzo")}
							error={!!errors?.indirizzo}
							helperText={errors?.indirizzo?.message}
							autoComplete={"off"}
							sx={{ width: "100%" }}
							fullWidth
							// onBlur={handlerDetailChaingSubmit}
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={6} lg={2}>
						<TextField
							label="cap"
							// disabled={schedaCarico.numero_capi_pianificati}
							variant="outlined"
							InputLabelProps={{
								shrink: true,
							}}
							{...register("cap")}
							error={!!errors?.cap}
							helperText={errors?.cap?.message}
							autoComplete={"off"}
							sx={{ width: "100%" }}
							fullWidth
							// onBlur={handlerDetailChaingSubmit}
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={6} lg={2}>
						<Autocomplete
							key={comuneCorrente?.id}
							// disabled={getValues("confermato")}
							value={comuneCorrente}
							isOptionEqualToValue={(option, value) => option.id === value.id}
							onChange={(event: any, newValue: any) => {
								setValue("comune", newValue ? newValue.id : null, {
									shouldDirty: true,
								});
							}}
							id="comune"
							options={comuni}
							getOptionLabel={(option) => option.nome}
							renderOption={(props, option) => (
								<Box component="li" {...props}>
									{option.nome}
								</Box>
							)}
							renderInput={(params: any) => (
								<TextField
									variant="outlined"
									{...params}
									label="Comune"
									error={!!errors?.comune}
									helperText={errors?.comune?.message}
								/>
							)}
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={6} lg={2}>
						<Typography
							variant="body1"
							component="div"
							color="text.secondary"
							gutterBottom
						>
							{comuneCorrente?.provincia}
						</Typography>
					</Grid>
				</Grid>
				<Grid
					container
					spacing={2}
					sx={{ mb: 2, ...(!pesonaFisica && { display: "none" }) }}
				>
					<Grid item xs={12} sm={6} md={6} lg={2}>
						<TextField
							label="codice fiscale"
							// disabled={schedaCarico.numero_capi_pianificati}
							variant="outlined"
							InputLabelProps={{
								shrink: true,
							}}
							{...register("codice_fiscale")}
							error={!!errors?.codice_fiscale}
							helperText={errors?.codice_fiscale?.message}
							autoComplete={"off"}
							sx={{ width: "100%" }}
							fullWidth
							// onBlur={handlerDetailChaingSubmit}
						/>
					</Grid>

					<Grid item xs={12} sm={6} md={6} lg={2}>
						<TextField
							label="pec"
							// disabled={schedaCarico.numero_capi_pianificati}
							variant="outlined"
							InputLabelProps={{
								shrink: true,
							}}
							{...register("pec")}
							error={!!errors?.pec}
							helperText={errors?.pec?.message}
							autoComplete={"off"}
							sx={{ width: "100%" }}
							fullWidth
							// onBlur={handlerDetailChaingSubmit}
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={6} lg={2}>
						<TextField
							label="codice sdi"
							// disabled={schedaCarico.numero_capi_pianificati}
							variant="outlined"
							InputLabelProps={{
								shrink: true,
							}}
							{...register("codice_sdi")}
							error={!!errors?.codice_sdi}
							helperText={errors?.codice_sdi?.message}
							autoComplete={"off"}
							sx={{ width: "100%" }}
							fullWidth
							// onBlur={handlerDetailChaingSubmit}
						/>
					</Grid>
				</Grid>
				<Divider
					textAlign="left"
					sx={{ mb: 2, color: theme.palette.primary.main }}
				>
					Contatto operativo
				</Divider>

				<Grid container spacing={2} sx={{ mb: 2 }}>
					<Grid item xs={12} sm={6} md={6} lg={4}>
						<TextField
							label="nominativo operativo"
							// disabled={schedaCarico.numero_capi_pianificati}
							variant="outlined"
							InputLabelProps={{
								shrink: true,
							}}
							{...register("nominativo_contatto_operativo")}
							error={!!errors?.nominativo_contatto_operativo}
							helperText={errors?.nominativo_contatto_operativo?.message}
							autoComplete={"off"}
							sx={{ width: "100%" }}
							fullWidth
							// onBlur={handlerDetailChaingSubmit}
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={6} lg={4}>
						<TextField
							label="telefono operativo"
							// disabled={schedaCarico.numero_capi_pianificati}
							variant="outlined"
							InputLabelProps={{
								shrink: true,
							}}
							{...register("telefono_operativo")}
							error={!!errors?.telefono_operativo}
							helperText={errors?.telefono_operativo?.message}
							autoComplete={"off"}
							sx={{ width: "100%" }}
							fullWidth
							// onBlur={handlerDetailChaingSubmit}
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={6} lg={4}>
						<TextField
							label="email operativa"
							// disabled={schedaCarico.numero_capi_pianificati}
							variant="outlined"
							InputLabelProps={{
								shrink: true,
							}}
							{...register("email_operativo")}
							error={!!errors?.email_operativo}
							helperText={errors?.email_operativo?.message}
							autoComplete={"off"}
							sx={{ width: "100%" }}
							fullWidth
							// onBlur={handlerDetailChaingSubmit}
						/>
					</Grid>
				</Grid>
				<Divider
					textAlign="left"
					sx={{ mb: 2, color: theme.palette.primary.main }}
				>
					Contatto amministrativo
				</Divider>

				<Grid container spacing={2} sx={{ mb: 2 }}>
					<Grid item xs={12} sm={6} md={6} lg={4}>
						<TextField
							label="nominativo amministrativo"
							// disabled={schedaCarico.numero_capi_pianificati}
							variant="outlined"
							InputLabelProps={{
								shrink: true,
							}}
							{...register("nominativo_contatto_amministrativo")}
							error={!!errors?.nominativo_contatto_amministrativo}
							helperText={errors?.nominativo_contatto_amministrativo?.message}
							autoComplete={"off"}
							sx={{ width: "100%" }}
							fullWidth
							// onBlur={handlerDetailChaingSubmit}
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={6} lg={4}>
						<TextField
							label="telefono amministrativo"
							// disabled={schedaCarico.numero_capi_pianificati}
							variant="outlined"
							InputLabelProps={{
								shrink: true,
							}}
							{...register("telefono_amministrativo")}
							error={!!errors?.telefono_amministrativo}
							helperText={errors?.telefono_amministrativo?.message}
							autoComplete={"off"}
							sx={{ width: "100%" }}
							fullWidth
							// onBlur={handlerDetailChaingSubmit}
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={6} lg={4}>
						<TextField
							label="email amministrativa"
							// disabled={schedaCarico.numero_capi_pianificati}
							variant="outlined"
							InputLabelProps={{
								shrink: true,
							}}
							{...register("email_amministrativo")}
							error={!!errors?.email_amministrativo}
							helperText={errors?.email_amministrativo?.message}
							autoComplete={"off"}
							sx={{ width: "100%" }}
							fullWidth
							// onBlur={handlerDetailChaingSubmit}
						/>
					</Grid>
				</Grid>

				<Stack direction="row" spacing={2}>
					<Button
						variant="outlined"
						component={Link}
						to={{
							pathname: Routes_path.ANAGRAFICHE,
						}}
						// endIcon={<KeyboardReturnIcon />}
					>
						Annulla
					</Button>
					<Button
						disabled={!isDirty}
						variant="outlined"
						type="submit"
						// sx={{ display: "none" }}
					>
						Salva
					</Button>
				</Stack>
			</form>
		</Box>
	);
};

export default ClienteForm;
