import React, {useEffect, useState, KeyboardEvent,} from "react";
import {useSelector} from "react-redux";
import {useLocation, Link, useNavigate} from "react-router-dom";

import {RootState, useAppDispatch} from "../../../../redux/store";
import {Routes_path} from "../../../routerApp";

import {DateTime} from "luxon";
import {convertToDateTime} from "components/common/dateUtils/convertToDateTime";

import {
    ErrorStatusTypes,
    ErrorsStack,
} from "../../../common/errorsDeclarations";

import {
    PreferenzeLista,
    setPreferenzeListaFatturabili,
} from "../../../userPreference/userPreferenceSlice";
import {fetchPratiche, Pratica, PraticheState} from "../amministrazioneSlice";
import {
    StatoIterTypes,
    StatoConnessioneTypes,
    StatiIter,
    StatoIter,
} from "../../../anagrafiche/kpi/kpiSlice";
import {
    Soggetto,
    resetProduttoreCorrente,
} from "../../../anagrafiche/produttori/produttoriSlice";

import {Cliente} from "../../../anagrafiche/clienti/clientiSlice";
import {PermissionTypes} from "../../../authentication/permissionsGroups";
import {utenteAutorizzato} from "../../../authentication/UtenteAutorizzato";
import {
    UtenteLogged,
    Utente,
} from "../../../authentication/authenticationSlice";
import {
    EsponiStatoSintetico,
    statiSintetici,
} from "components/common/EsponiStatoSintetico";
import NavigazioneMesiToolbar from "../../../common/dateUtils/NavigazioneMesiToolbar";
import LinearProgress from "@mui/material/LinearProgress";

import {CSVLink, CSVDownload} from "react-csv";
import {tabellaListaPraticheFatturabiliMese} from "./tabellaListaPraticheFatturabiliMese";
import {
    RecuperaDataCorrente,
    FissaDataCorrente,
} from "../../../common/dateUtils/RecuperaDataCorrente";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {Box} from "@mui/system";
import Chip from "@mui/material/Chip";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import AddIcon from "@mui/icons-material/Add";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";

import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import Pagination from "@mui/material/Pagination";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Select, {SelectChangeEvent} from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import {TipoPratica} from "components/anagrafiche/tipiPratica/tipiPraticaSlice";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import ReplayIcon from "@mui/icons-material/Replay";

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

const produttoreFullName = (produttore?: Soggetto) => {
    if (produttore) {
        if (produttore.tipo_persona == "PF") {
            return `${produttore.cognome} ${produttore.nome}`;
        } else {
            return `${produttore.ragione_sociale}`;
        }
    } else {
        return "anonimo";
    }
};

const visualizzaStatoIter = (statoIter?: StatoIter) => {
    return statoIter && `${statoIter.stato_desc} (${statoIter.count})`;
};

const PraticheFatturabiliPage = () => {

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    type LocationState = {
        statoIterId?: string;
        clienteIterId?: number;
        iterSemplificato?: string;
    };
    const location = useLocation();
    const utente = useSelector<RootState, Utente>(
        (state) => state.authentication.utenteLogged
    );

    const preferenzeListaFatturabili = useSelector<
        RootState,
        PreferenzeLista | undefined
    >((state) => state.userPreferenceState.preferenzeListaFatturabili);

    const [statoIterId, setStatoIterId] = useState(
        location.state ? (location.state as LocationState).statoIterId : undefined
    );

    const [clienteIterId, setClienteIterId] = useState(
        location.state
            ? (location.state as LocationState).clienteIterId
            : preferenzeListaFatturabili?.clienteIterId
    );

    const [iterSemplificato, setIterSemplificato] = useState(
        location.state
            ? (location.state as LocationState).iterSemplificato
            : undefined
    );

    const pratiche = useSelector<RootState, PraticheState>(
        (state) => state.amministrazioneState.pratiche
    );

    const statiIter = useSelector<RootState, StatiIter>(
        (state) => state.kpiState.statiIter
    );

    const clienti = useSelector<RootState, Cliente[]>(
        (state) => state.clientiState.clienti.results
    );

    const tipiPratica = useSelector<RootState, TipoPratica[]>(
        (state) => state.tipiPraticaState.tipiPratica.results
    );

    const produttori = useSelector<RootState, Soggetto[]>(
        (state) => state.produttoriState.produttori.results
    );

    const [page, setPage] = useState<number>(
        preferenzeListaFatturabili?.page || 1
    );
    const [numeroRecord, setNumeroRecord] = useState<number>(
        preferenzeListaFatturabili?.numeroRecord || 100
    );
    const [search, setSearch] = useState<string | undefined>(undefined);

    const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "Enter") {
            setRefresh(true);
        }
    };

    const handleChangeNumeroRecord = (event: SelectChangeEvent) => {
        setNumeroRecord(Number(event.target.value));
    };

    const handlerPager = (event: React.ChangeEvent<unknown>, page: number) => {
        setPage(page);
    };

    const [refresh, setRefresh] = useState<boolean>(false);

    const [anchorElStatoFilter, setAnchorElStatoFilter] = useState(null);
    const [anchorElClienteFilter, setAnchorElClienteFilter] = useState(null);
    const [anchorElTipoIterFilter, setAnchorElTipoIterFilter] = useState(null);

    const recupera_data_corrente = RecuperaDataCorrente(location);

    const [data, setData] = useState<DateTime | null>(recupera_data_corrente);

    const handleStatoFilterClose = () => {
        setAnchorElStatoFilter(null);
        setAnchorElClienteFilter(null);
        setAnchorElTipoIterFilter(null);
        // onClose(selectedValue);
    };

    useEffect(() => {
        data && FissaDataCorrente(data);
        const data_da = convertToDateTime(data)?.startOf("month").toJSDate()

        const data_a = convertToDateTime(data)?.endOf("month").toJSDate()

        dispatch(
            fetchPratiche({
                data_da: data_da,
                data_a: data_a,
                clienteIter: clienteIterId,
                numeroRecord: numeroRecord,
                page: page,
                search: search,
            })
        );
        dispatch(
            setPreferenzeListaFatturabili({
                numeroRecord: numeroRecord,
                page: page,
                // stato: statoIterId,
                clienteIterId: clienteIterId,
                // iterSemplificato: iterSemplificato,
                // operatoreId: operatoreId,
                // progettistaId: progettistaId,
            })
        );
        setRefresh(false);
    }, [
        page,
        numeroRecord,
        // search,
        statoIterId,
        clienteIterId,
        iterSemplificato,
        refresh,
        data,
    ]);

    const handlerCreateIterConnessione = () => {
        dispatch(resetProduttoreCorrente());

        navigate(Routes_path.PRODUTTORE);
    };

    return (
        <Box
            sx={{
                backgroundColor: "#fff",
                elevation: 20,
                // p: 2,
                "& .campo-editabile": {
                    backgroundColor: "#bbbbbb11",
                    background: "linear-gradient(to right bottom, #f5f7f6, #dce3e0)",
                    // borderWidth: "5px",
                    borderColor: "#ff91003d",
                    // borderColor: "#0000003d",
                    // borderStyle: "outset",
                    borderWidth: 3,
                    borderStyle: "ridge",
                    color: "#1a3e72",
                    fontWeight: "600",
                },
                "& .campo-errore": {
                    backgroundColor: "rgba(124, 3, 0, 0.3)",
                    color: "#ffffff",
                    fontWeight: "600",
                },
            }}
        >
            <NavigazioneMesiToolbar
                titolo={"Report Pratiche Fatturabili"}
                setData={setData}
                data={data}
                refresh={() => {
                    setRefresh(true);
                }}
            />
            <List sx={{mt: 2}}>
                <ListItem>
                    <ButtonGroup
                        variant="outlined"
                        aria-label="outlined button group"
                        sx={{mb: 2}}
                    >
                        <Button
                            color="primary"
                            startIcon={<ReplayIcon/>}
                            onClick={() => setRefresh(true)}
                        >
                            aggiorna
                        </Button>
                        <Button
                            color="primary"
                            startIcon={<FormatListBulletedIcon/>}
                            // onClick={(event) => {
                            // 	event.preventDefault();
                            // 	handlerCreateIterConnessione();
                            // }}
                        >
                            {pratiche.results.length > 0 ? (
                                <CSVLink
                                    data={tabellaListaPraticheFatturabiliMese(pratiche.results)}
                                    style={{color: "#222"}}
                                >
                                    Scarica lista
                                </CSVLink>
                            ) : (
                                <LinearProgress color="inherit"/>
                            )}
                        </Button>
                    </ButtonGroup>
                </ListItem>
                <ListItemText
                    key={"Lista_saldo_pallet_committente"}
                    sx={{backgroundColor: "#1b75b1", color: "#ddd", mb: 1}}
                >
                    <Grid container spacing={{xs: 0.5, md: 1, lg: 2}} sx={{px: 2}}>
                        <Grid item xs={6} sm={6} md={6} lg={2}>
                            <Typography variant="h5" gutterBottom>
                                Commessa
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={1}>
                            <Typography variant="h5" gutterBottom>
                                Soggetto
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={1}>
                            <Stack direction="row" spacing={1}>
                                <Typography variant="h5" gutterBottom>
                                    Cliente
                                </Typography>
                                <IconButton
                                    color={clienteIterId ? "secondary" : "inherit"}
                                    onClick={(event: any) =>
                                        setAnchorElClienteFilter(event.currentTarget)
                                    }
                                >
                                    <FilterAltIcon sx={{mt: -0.5}}/>
                                </IconButton>
                            </Stack>
                            <Box style={{display: "flex", justifyContent: "space-between"}}>
                                <Menu
                                    id="simple-menu"
                                    anchorEl={anchorElClienteFilter}
                                    keepMounted
                                    open={Boolean(anchorElClienteFilter)}
                                    onClose={handleStatoFilterClose}
                                >
                                    {clienti.map((cliente) => (
                                        <MenuItem
                                            key={cliente.id}
                                            onClick={() => {
                                                setClienteIterId(cliente.id);
                                                setAnchorElClienteFilter(null);
                                            }}
                                            sx={{
                                                backgroundColor:
                                                    cliente.id == clienteIterId ? "#aaa" : "inherit",
                                            }}
                                        >
                                            {cliente.ragione_sociale}
                                        </MenuItem>
                                    ))}
                                    <MenuItem
                                        onClick={() => {
                                            setClienteIterId(undefined);
                                            setAnchorElClienteFilter(null);
                                        }}
                                        sx={{
                                            backgroundColor: !clienteIterId ? "#aaa" : "inherit",
                                        }}
                                    >
                                        tutti
                                    </MenuItem>
                                </Menu>
                            </Box>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={1}>
                            <Stack direction="row" spacing={1}>
                                <Typography variant="h5" gutterBottom>
                                    Tipo Servizio
                                </Typography>
                                {/* <IconButton
									color={iterSemplificato ? "secondary" : "inherit"}
									onClick={(event: any) =>
										setAnchorElTipoIterFilter(event.currentTarget)
									}
								>
									<FilterAltIcon sx={{ mt: -0.5 }} />
								</IconButton> */}
                            </Stack>
                            {/* <Box style={{ display: "flex", justifyContent: "space-between" }}>
								<Menu
									id="simple-menu"
									anchorEl={anchorElTipoIterFilter}
									keepMounted
									open={Boolean(anchorElTipoIterFilter)}
									onClose={handleStatoFilterClose}
								>
									<MenuItem
										onClick={() => {
											setIterSemplificato("False");
											setAnchorElTipoIterFilter(null);
										}}
										sx={{
											backgroundColor:
												iterSemplificato == "False" ? "#aaa" : "inherit",
										}}
									>
										ordinario
									</MenuItem>
									<MenuItem
										onClick={() => {
											setIterSemplificato("True");
											setAnchorElTipoIterFilter(null);
										}}
										sx={{
											backgroundColor:
												iterSemplificato == "True" ? "#aaa" : "inherit",
										}}
									>
										semplificato
									</MenuItem>
									<MenuItem
										onClick={() => {
											setIterSemplificato(undefined);
											setAnchorElTipoIterFilter(null);
										}}
										sx={{
											backgroundColor: !iterSemplificato ? "#aaa" : "inherit",
										}}
									>
										tutti
									</MenuItem>
								</Menu>
							</Box> */}
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={2}>
                            <Typography variant="h5" gutterBottom>
                                Tipo di pratica
                            </Typography>
                        </Grid>

                        <Grid item xs={6} sm={6} md={6} lg={1}>
                            <Typography variant="h5">Data Richiesta</Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={1}>
                            <Typography variant="h5">Data Allaccio</Typography>
                        </Grid>

                        <Grid item xs={6} sm={6} md={6} lg={1}>
                            <Typography variant="h5" gutterBottom>
                                Tipo Fattura
                            </Typography>
                        </Grid>
                    </Grid>
                </ListItemText>

                <Backdrop
                    sx={{
                        color: "#fff",
                        zIndex: (theme) => theme.zIndex.drawer + 1,
                    }}
                    open={
                        pratiche.errorsStack.status == ErrorStatusTypes.PENDING
                        // errorsStack.status == ErrorStatusTypes.PENDING
                    }
                >
                    <CircularProgress color="inherit"/>
                </Backdrop>

                {pratiche.results?.map((pratica, index) => (
                    <ListItemButton
                        // key={"iter_" + iterConnessione.id}
                        key={index}
                        component={Link}
                        to={Routes_path.ITER_CONNESSIONE + pratica.numero_iter}
                        sx={{
                            color: "black",
                            textDecoration: "none",
                            backgroundColor: index % 2 === 0 ? "#fff" : "#f2f2f2",
                        }}
                    >
                        <Grid container spacing={{xs: 0.5, md: 1, lg: 2}} sx={{px: 2}}>
                            <Grid item xs={6} sm={6} md={6} lg={2}>
                                {pratica.commessa}
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} lg={1}>
                                {pratica.produttore}
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} lg={1}>
                                {pratica.cliente}
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} lg={1}>
                                {pratica.tipo_pratica}
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={2}>
                                {pratica.tipo_servizio}
                            </Grid>

                            <Grid item xs={6} sm={6} md={6} lg={1}>
                                {pratica.data_richiesta_presa_carico &&
                                    convertToDateTime(pratica.data_richiesta_presa_carico)?.setLocale("it").toFormat("dd/MM/yyyy")}
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} lg={1}>
                                {" "}
                                {pratica.data_alaccio &&
                                    convertToDateTime(pratica.data_alaccio)?.setLocale("it").toFormat("dd/MM/yyyy")}
                            </Grid>

                            <Grid item xs={6} sm={6} md={6} lg={1}>
                                {pratica.fatturabilità}
                            </Grid>
                        </Grid>
                    </ListItemButton>
                ))}

                <ListItem
                    sx={{
                        alignContent: "right",
                        width: "100%",
                        mt: 2,
                        borderTopStyle: "solid",
                        borderTopWidth: 1,
                    }}
                >
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        spacing={2}
                        sx={{width: "100%"}}
                    >
                        <Pagination
                            count={pratiche.num_pages}
                            onChange={handlerPager}
                            page={page}
                        />
                        {statoIterId || clienteIterId ? (
                            <Typography noWrap>
                                Totale pratiche filtrate:{"  "}
                                {pratiche.count}
                            </Typography>
                        ) : (
                            <Typography noWrap>
                                Totale pratiche:{"  "}
                                {pratiche.count}
                            </Typography>
                        )}

                        <TextField
                            id="input-with-icon-textfield"
                            // label="TextField"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="end">
                                        <SearchIcon/>
                                    </InputAdornment>
                                ),
                            }}
                            variant="standard"
                            value={search}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setSearch(event.target.value);
                            }}
                            onBlur={() => setRefresh(true)}
                            onKeyDown={handleKeyDown}
                        />
                        <FormControl>
                            <InputLabel id="demo-simple-select-label">Record</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="numero-record"
                                value={numeroRecord.toString()}
                                label="numero record"
                                onChange={handleChangeNumeroRecord}
                                variant="outlined"
                            >
                                <MenuItem value={30}>30</MenuItem>
                                <MenuItem value={50}>50</MenuItem>
                                <MenuItem value={100}>100</MenuItem>
                                <MenuItem value={200}>200</MenuItem>
                            </Select>
                        </FormControl>
                    </Stack>
                </ListItem>
            </List>
        </Box>
    );
};

export default PraticheFatturabiliPage;
