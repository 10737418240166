import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useParams, useNavigate } from "react-router-dom";

import { RootState, useAppDispatch } from "../../../redux/store";
import { Routes_path } from "../../routerApp";

import { DateTime } from "luxon";


import { ErrorStatusTypes } from "../../common/errorsDeclarations";

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

import { utenteAutorizzato } from "../../authentication/UtenteAutorizzato";
import { UtenteLogged } from "../../authentication/authenticationSlice";
import { Installatore, getInstallatore } from "./installatoriSlice";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InstallatoreForm from "./InstallatoreForm";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";
import { Button, Divider } from "@mui/material";
import Stack from "@mui/material/Stack";
import { theme } from "../../theme";

const InstallatorePage = () => {
	
	const dispatch = useAppDispatch();
	const { installatore_id } = useParams();
	// const produttoreId: number = parseInt(produttore_id || "");

	const [installatoreId, setInstallatoreId] = useState<number>(
		parseInt(installatore_id || "")
	);

	const location = useLocation();
	const navigate = useNavigate();

	const utente = useSelector<RootState, UtenteLogged>(
		(state) => state.authentication.utenteLogged
	);

	const installatoreCorrenteId = useSelector<RootState, number | undefined>(
		(state) => state.installatoriState.installatoreCorrenteId
	);

	useEffect(() => {
		installatoreId && dispatch(getInstallatore(installatoreId));
	}, []);

	useEffect(() => {
		installatoreCorrenteId && setInstallatoreId(installatoreCorrenteId);

		installatoreCorrenteId &&
			navigate(`${Routes_path.INSTALLATORE}${installatoreCorrenteId}`, {
				replace: true,
			});
	}, [installatoreCorrenteId]);

	const installatore: Installatore | undefined = useSelector<
		RootState,
		Installatore | undefined
	>((state) =>
		state.installatoriState.installatori.results.find(
			(installatore) => installatore?.id == installatoreId
		)
	);

	const installatoreNuovo: Installatore = {
		id: undefined,
		ragione_sociale: "",
	};
	return (
		<>
			{/* <ProduttoreBreadcrumbs produttore={produttore} /> */}
			<Box
				sx={{
					backgroundColor: "#fff",
					elevation: 20,
					p: 2,
				}}
			>
				<Backdrop
					sx={{
						color: "#fff",
						zIndex: (theme) => theme.zIndex.drawer + 1,
					}}
					open={
						false
						// lotto.errorsStack.status == ErrorStatusTypes.PENDING ||
						// errorsStack.status == ErrorStatusTypes.PENDING
					}
				>
					<CircularProgress color="inherit" />
				</Backdrop>

				<Stack
					direction="row"
					justifyContent="flex-start"
					alignItems="flex-start"
					spacing={4}
					sx={{ mb: 1 }}
				>
					{installatore ? (
						<>
							<Typography variant="h3" component="div" color="text.secondary">
								{installatore?.id}
							</Typography>
							<Typography variant="h3" component="div" color="text.secondary">
								{installatore?.ragione_sociale}
							</Typography>
						</>
					) : (
						<Typography variant="h3" component="div" color="text.secondary">
							Nuovo Installatore
						</Typography>
					)}
				</Stack>

				<Divider sx={{ mb: 1 }} />
				<Box>
					<InstallatoreForm
						key={`InstallatoreForm_${installatore?.id || "nuovo"}`}
						installatore={installatore || installatoreNuovo}
					/>
				</Box>
			</Box>
		</>
	);
};

export default InstallatorePage;
