import React from "react";
// import { coloreGiorno } from "../../../pianificazione/coloreGiorno";
import {convertToDateTime} from "components/common/dateUtils/convertToDateTime";
import {Commessa, getCommessa, Prodotto, PodCommessa, TipoIntervento, TipoProdottoTypes} from "../../../commesseSlice";
import JsBarcode from "jsbarcode";
import logoSinergie from "../../../../../../../static/images/logo/logo-I43_biglia.png";

import {
    Text,
    View,
    StyleSheet,
    Image,
    PDFViewer,
    Page,
    Document,
} from "@react-pdf/renderer";


import {theme} from "components/theme";
import Typography from "@mui/material/Typography";
import {useSelector} from "react-redux";
import {RootState} from "../../../../../../redux/store";


const barcode = (codice: string) => {
    const canvas = document.createElement("canvas");
    JsBarcode(canvas, codice,  {
        width: 4, // Aumenta la larghezza delle barre
        height: 200, // Regola l'altezza
        displayValue: true, // Mostra il valore sotto il codice a barre
        margin: 10, // Margine intorno al codice
        fontSize: 18 // Dimensione del testo sotto il codice
    });
    return canvas.toDataURL();
};


// Create styles
const styles = StyleSheet.create({
    page: {
        backgroundColor: "#fff",
        padding: 20,
    },
    section: {
        // marginTop: 10,
        marginLeft: 10,
        marginRight: 10,
        padding: 10,
        borderWidth: 1,
    },
    sectionIntestazione: {
        // width: "100%",
        marginTop: 5,
        marginLeft: 10,
        marginRight: 10,
        padding: 10,
        // paddingTop: 10,
        // paddingBottom: 10,
        // backgroundColor: "#777777",
        color: "#000",
        borderWidth: 0.5,
    },
    titolo: {
        fontSize: 20,
        marginBottom: 10,
        fontStyle: "bold",
    },
    text: {
        fontSize: 14,
        fontStyle: "normal",
    },
    textDettaglioProtesi: {
        fontSize: 12,
        fontStyle: "normal",
    },
});
//
// const proveOrdinate = (prove: ProvaPaziente[]) => {
// 	const proveOrdinate = [...prove].sort((a, b) => (b.id || 0) - (a.id || 0));
// 	return proveOrdinate;
// };
// const mask = 'Sinergie_Prodotto_id_'
const mask = 'SiPrId'

interface FoglioCommessaPdfProps {
    commessa: Commessa;
}

const FoglioBarcodeProdottiPdf = ({
                                      commessa,
                                  }: // schedaCarico,
                                      FoglioCommessaPdfProps) => {

    const tipiIntervento = useSelector<RootState, TipoIntervento[]>(
        (state) => state.commesseState.tipiIntervento.results
    );
    return (
        <PDFViewer width={"100%"} height={"100%"}>
            <Document>
                <Page size="A4" style={styles.page} key={"commessa" + commessa.id}>
                    <View
                        style={{
                            flexDirection: "column",
                            // height: 820,
                        }}
                    >
                        <View style={{flexDirection: "row"}}>
                            <View style={{flexDirection: "column", width: "90%"}}>
                                <Text
                                    style={styles.text}>Commessa n°{commessa.id}: {commessa.produttore_descrizione}</Text>
                                <Text
                                    style={[styles.text, {marginBottom: 30}]}>{tipiIntervento.filter(tipo => commessa.tipi_intervento?.includes(tipo.id))?.map((tipo) => tipo.descrizione).join(", ")}</Text>
                            </View>
                            <View style={{flexDirection: "column", width: "10%"}}>

                                <Image
                                    src={logoSinergie}
                                    style={{
                                        width: 30,
                                        height: 30,
                                        // alignContent: "flex-start",

                                        // margin: 0,
                                        padding: 0,
                                        // paddingLeft: "10px",
                                        // marginLeft: "3px",
                                    }}
                                />
                            </View>
                        </View>

                        {commessa.pod?.prodotti?.filter(p => p.pre_esistente == false).map((prodotto: Prodotto) => (
                            <View style={[styles.section,]} key={"prodotto" + prodotto.id}>
                                <View style={{flexDirection: "row", justifyContent: "space-between",}}>
                                    <Text style={styles.text}>{
                                        Object.entries(TipoProdottoTypes).find(
                                            ([key]) => key == prodotto.tipo_prodotto
                                        )?.[1]
                                    }</Text>
                                    <Text style={styles.text}>{prodotto.marca} - {prodotto.modello}</Text>
                                    <Text style={styles.text}>Quantità: {prodotto.quantita}</Text>
                                </View>
                                <View
                                    style={[
                                        {
                                            flexDirection: "row",
                                            justifyContent: "center",
                                            marginTop: 20,
                                            paddingLeft: 200,
                                            paddingRight: 200,
                                        },
                                    ]}
                                >
                                    <Image src={barcode(`${mask}${prodotto.id}`)} />
                                </View>
                            </View>

                        ))}
                    </View>
                </Page>
            </Document>
        </PDFViewer>
    )
        ;
};

export default FoglioBarcodeProdottiPdf;
