import { handleResponse, handleError, headerset } from "../apiUtils";
import { Comune } from "../../components/anagrafiche/comuni/comuniSlice";

const baseUrl = (comune_id?: number | null) => {
	return process.env.API_URL + "/api/anagrafiche/comuni/";
};

export function fetchComuni(ultimoAggiornamento?: string, nextUrl?: string) {
	let url = baseUrl();
	if (ultimoAggiornamento != undefined && ultimoAggiornamento != "") {
		url += "?ultimo_aggiornamento=" + ultimoAggiornamento;
	}
	if (nextUrl != undefined && nextUrl != "") {
		// url = process.env.API_URL + nextUrl;
		url = nextUrl;
	}
	return fetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}
