import * as React from "react";
import { Routes_path } from "../../../../routerApp";
import { useNavigate, Path, Link } from "react-router-dom";

import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import HomeIcon from "@mui/icons-material/Home";
import IconButton from "@mui/material/IconButton";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import FlashAutoIcon from "@mui/icons-material/FlashAuto";
import HandshakeIcon from "@mui/icons-material/Handshake";

interface BreadcrumbsProps {
	identificativoPosizione?: string | number;
}

const ServizioAdempimentiPageBreadcrumbs = ({
	identificativoPosizione,
}: BreadcrumbsProps) => {
	return (
		<Breadcrumbs aria-label="breadcrumb">
			<IconButton
				key={"home"}
				aria-label="modifica lavorazione"
				size="small"
				component={Link}
				to={{
					pathname: Routes_path.HOME,
				}}
				// state={{
				// 	lavorazine_id: params.value,
				// }}
			>
				<HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
				Home
			</IconButton>
			<IconButton
				key={"home"}
				aria-label="modifica lavorazione"
				size="small"
				component={Link}
				to={{
					pathname: Routes_path.ADEMPIMENTI,
				}}
			>
				<FlashAutoIcon sx={{ mr: 0.5 }} fontSize="inherit" />
				Adempimenti
			</IconButton>
			<IconButton
				key={"home"}
				aria-label="modifica lavorazione"
				size="small"
				component={Link}
				to={{
					pathname: Routes_path.ADEMPIMENTI,
				}}
			>
				<HandshakeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
				Contratti Adempimenti
			</IconButton>
			<Typography
				sx={{ display: "flex", alignItems: "center" }}
				color="text.primary"
			>
				<HandshakeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
				Adempimento {identificativoPosizione}
			</Typography>
		</Breadcrumbs>
	);
};

export default ServizioAdempimentiPageBreadcrumbs;
