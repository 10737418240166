import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, Link, useNavigate } from "react-router-dom";

import { RootState, useAppDispatch } from "../../../redux/store";
import { Routes_path } from "../../routerApp";

import { DateTime } from "luxon";
import { convertToDateTime } from "components/common/dateUtils/convertToDateTime";

import {
	ErrorStatusTypes,
	ErrorsStack,
	parseErrorMessage,
} from "../../common/errorsDeclarations";

import {
	PreferenzeLista,
	setPreferenzeListaScadenze,
} from "../../userPreference/userPreferenceSlice";

import { fetchScadenze, Scadenza, ScadenzeState } from "./scadenzeSlice";
import {
	StatoIterTypes,
	StatoConnessioneTypes,
	StatiIter,
	StatoIter,
} from "../../anagrafiche/kpi/kpiSlice";
import {
	Soggetto,
	resetProduttoreCorrente,
} from "../../anagrafiche/produttori/produttoriSlice";

import { Cliente } from "../../anagrafiche/clienti/clientiSlice";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";
import Chip from "@mui/material/Chip";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import AddIcon from "@mui/icons-material/Add";

import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import Pagination from "@mui/material/Pagination";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import { TipoPratica } from "components/anagrafiche/tipiPratica/tipiPraticaSlice";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import ReplayIcon from "@mui/icons-material/Replay";

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

const scaduta = (data?: string | Date) => {
	const dataScadenza = convertToDateTime(data); // Manteniamo Luxon DateTime
	const oggi = DateTime.now(); // Data di oggi con Luxon

	// Se dataScadenza esiste, confronta direttamente con oggi
	return dataScadenza ? dataScadenza <= oggi : false;
};

const inScadenza = (giorniPrevisione: number, data?: string | Date) => {
	const dataScadenza = convertToDateTime(data)?.toJSDate();
	let oggi = new Date();
	oggi.setDate(oggi.getDate() + giorniPrevisione);
	if (dataScadenza){
		return dataScadenza <= oggi;
	}	else {
		return false;
	}
};

const ScadenzePage = () => {
	
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	type LocationState = {
		clienteIterId?: number;
	};
	const location = useLocation();

	const preferenzeListaScadenze = useSelector<
		RootState,
		PreferenzeLista | undefined
	>((state) => state.userPreferenceState.preferenzeListaScadenze);

	const scadenze = useSelector<RootState, ScadenzeState>(
		(state) => state.scadenzeState.scadenze
	);

	const [page, setPage] = useState<number>(preferenzeListaScadenze?.page || 1);
	const [numeroRecord, setNumeroRecord] = useState<number>(
		preferenzeListaScadenze?.numeroRecord || 10
	);
	const [search, setSearch] = useState<string | undefined>(undefined);

	const handleChangeNumeroRecord = (event: SelectChangeEvent) => {
		setNumeroRecord(Number(event.target.value));
	};

	const clienti = useSelector<RootState, Cliente[]>(
		(state) => state.clientiState.clienti.results
	);
	const handlerPager = (event: React.ChangeEvent<unknown>, page: number) => {
		setPage(page);
	};

	const [refresh, setRefresh] = useState<boolean>(false);
	const [giorniPrevisione, setGiorniPrevisione] = useState<number>(90);

	const [clienteIterId, setClienteIterId] = useState(
		location.state
			? (location.state as LocationState).clienteIterId
			: preferenzeListaScadenze?.clienteIterId
	);

	const [anchorElClienteFilter, setAnchorElClienteFilter] = useState(null);

	const [anchorElStatoFilter, setAnchorElStatoFilter] = useState(null);

	const handleStatoFilterClose = () => {
		setAnchorElStatoFilter(null);
		// onClose(selectedValue);
	};

	useEffect(() => {
		dispatch(
			fetchScadenze({
				giorni_previsione: giorniPrevisione,
				clienteIter: clienteIterId,
				numeroRecord: numeroRecord,
				page: page,
				search: search,
			})
		);
		dispatch(
			setPreferenzeListaScadenze({
				numeroRecord: numeroRecord,
				page: page,
				// stato: statoIterId,
				clienteIterId: clienteIterId,
				// iterSemplificato: iterSemplificato,
				// operatoreId: operatoreId,
				// progettistaId: progettistaId,
			})
		);
		setRefresh(false);
	}, [clienteIterId, page, numeroRecord, search, giorniPrevisione, refresh]);

	useEffect(() => {
		setRefresh(false);
	}, [refresh]);

	return (
		<Box
			sx={{
				backgroundColor: "#fff",
				elevation: 20,
				// p: 2,
				"& .campo-editabile": {
					backgroundColor: "#bbbbbb11",
					background: "linear-gradient(to right bottom, #f5f7f6, #dce3e0)",
					// borderWidth: "5px",
					borderColor: "#ff91003d",
					// borderColor: "#0000003d",
					// borderStyle: "outset",
					borderWidth: 3,
					borderStyle: "ridge",
					color: "#1a3e72",
					fontWeight: "600",
				},
				"& .campo-errore": {
					backgroundColor: "rgba(124, 3, 0, 0.3)",
					color: "#ffffff",
					fontWeight: "600",
				},
			}}
		>
			<List sx={{ mt: 2 }}>
				<ListItem>
					<ButtonGroup
						variant="outlined"
						aria-label="outlined button group"
						sx={{ mb: 2 }}
					>
						<Button
							color="primary"
							startIcon={<ReplayIcon />}
							onClick={() => setRefresh(true)}
						>
							aggiorna
						</Button>
					</ButtonGroup>
				</ListItem>
				<ListItemText
					key={"Lista_saldo_pallet_committente"}
					sx={{ backgroundColor: "#1b75b1", color: "#ddd", mb: 1 }}
				>
					<Grid container spacing={{ xs: 0.5, md: 1, lg: 2 }} sx={{ px: 2 }}>
						<Grid item xs={3} sm={3} md={2} lg={1}>
							<Stack direction="row" spacing={1}>
								<Typography variant="h5">Iter</Typography>
								<IconButton
									color={giorniPrevisione ? "secondary" : "inherit"}
									onClick={(event: any) =>
										setAnchorElStatoFilter(event.currentTarget)
									}
								>
									<FilterAltIcon sx={{ mt: -0.5 }} />
								</IconButton>
							</Stack>
							<Box style={{ display: "flex", justifyContent: "space-between" }}>
								<Menu
									id="simple-menu"
									anchorEl={anchorElStatoFilter}
									keepMounted
									open={Boolean(anchorElStatoFilter)}
									onClose={handleStatoFilterClose}
								>
									{Object.entries([30, 60, 90, 120]).map(([key, value]) => {
										return (
											<MenuItem
												key={`statoIter_${key}`}
												value={key}
												onClick={() => {
													setGiorniPrevisione(value);
													setAnchorElStatoFilter(null);
												}}
												sx={{
													backgroundColor:
														value == giorniPrevisione ? "#aaa" : "inherit",
												}}
											>
												scadenze in {value} gg
											</MenuItem>
										);
									})}
									<MenuItem
										onClick={() => {
											setGiorniPrevisione(1900);
											setAnchorElStatoFilter(null);
										}}
										sx={{
											backgroundColor:
												giorniPrevisione == 1900 ? "#aaa" : "inherit",
										}}
									>
										tutti
									</MenuItem>
								</Menu>
							</Box>
						</Grid>
						<Grid item xs={3} sm={3} md={2} lg={2}>
							<Stack direction="row" spacing={1}>
								<Typography variant="h5" gutterBottom>
									Cliente
								</Typography>
								<IconButton
									color={clienteIterId ? "secondary" : "inherit"}
									onClick={(event: any) =>
										setAnchorElClienteFilter(event.currentTarget)
									}
								>
									<FilterAltIcon sx={{ mt: -0.5 }} />
								</IconButton>
							</Stack>
							<Box style={{ display: "flex", justifyContent: "space-between" }}>
								<Menu
									id="simple-menu"
									anchorEl={anchorElClienteFilter}
									keepMounted
									open={Boolean(anchorElClienteFilter)}
									onClose={handleStatoFilterClose}
								>
									{clienti.map((cliente) => (
										<MenuItem
											key={cliente.id}
											onClick={() => {
												setClienteIterId(cliente.id);
												setAnchorElClienteFilter(null);
											}}
											sx={{
												backgroundColor:
													cliente.id == clienteIterId ? "#aaa" : "inherit",
											}}
										>
											{cliente.ragione_sociale}
										</MenuItem>
									))}
									<MenuItem
										onClick={() => {
											setClienteIterId(undefined);
											setAnchorElClienteFilter(null);
										}}
										sx={{
											backgroundColor: !clienteIterId ? "#aaa" : "inherit",
										}}
									>
										tutti
									</MenuItem>
								</Menu>
							</Box>
						</Grid>
						<Grid item xs={3} sm={3} md={2} lg={2}>
							<Typography variant="h5" gutterBottom>
								Soggetto
							</Typography>
						</Grid>
						<Grid item xs={3} sm={3} md={2} lg={1}>
							<Typography variant="h5" gutterBottom>
								Potenza nominale
							</Typography>
						</Grid>
						<Grid item xs={3} sm={3} md={2} lg={1}>
							<Typography variant="h5" gutterBottom>
								Data Allaccio
							</Typography>
						</Grid>
						<Grid item xs={3} sm={3} md={2} lg={1}>
							<Typography variant="h5" gutterBottom>
								Ultima taratura SPI
							</Typography>
						</Grid>
						<Grid item xs={3} sm={3} md={2} lg={1}>
							<Typography variant="h5" gutterBottom>
								Scadenza taratura SPI
							</Typography>
						</Grid>
						<Grid item xs={3} sm={3} md={2} lg={1}>
							<Typography variant="h5" gutterBottom>
								Ultima taratura CONTATORI
							</Typography>
						</Grid>
						<Grid item xs={3} sm={3} md={2} lg={1}>
							<Typography variant="h5" gutterBottom>
								Scadenza taratura CONTATORI
							</Typography>
						</Grid>
					</Grid>
				</ListItemText>

				<Backdrop
					sx={{
						color: "#fff",
						zIndex: (theme) => theme.zIndex.drawer + 1,
					}}
					open={
						scadenze.errorsStack.status == ErrorStatusTypes.PENDING
						// errorsStack.status == ErrorStatusTypes.PENDING
					}
				>
					<CircularProgress color="inherit" />
				</Backdrop>

				{scadenze.results?.map((scadenza, index) => (
					<ListItemButton
						// key={"iter_" + scadenza.id}
						key={index}
						// component={Link}
						// to={Routes_path.SOPRALLUOGO + scadenza.id}
						sx={{
							color: "black",
							textDecoration: "none",
							backgroundColor: index % 2 === 0 ? "#fff" : "#f2f2f2",
						}}
					>
						<Grid container spacing={{ xs: 0.5, md: 1, lg: 2 }} sx={{ mb: 1 }}>
							<Grid item xs={3} sm={3} md={2} lg={1}>
								<Link
									to={Routes_path.ITER_CONNESSIONE + scadenza.iter_connessione}
									key={`Iter_Soprelluogo_${scadenza.iter_connessione}`}
								>
									{scadenza.iter_connessione}
								</Link>
							</Grid>

							<Grid item xs={3} sm={3} md={2} lg={2}>
								<Typography noWrap>{scadenza.cliente_desc}</Typography>
							</Grid>
							<Grid item xs={3} sm={3} md={2} lg={2}>
								<Typography noWrap>{scadenza.produttore_desc}</Typography>
							</Grid>
							<Grid item xs={3} sm={3} md={2} lg={1}>
								<Typography noWrap>
									{scadenza.potenza_nominale
										? Intl.NumberFormat("it-IT", {
												maximumFractionDigits: 2,
										  })
												.format(scadenza.potenza_nominale || 0)
												.toString() + " kW"
										: ""}
								</Typography>
							</Grid>
							<Grid item xs={3} sm={3} md={2} lg={1}>
								<Typography noWrap>
									{scadenza.alaccio_data
										? convertToDateTime(scadenza.alaccio_data)?.setLocale("it").toFormat("dd/MM/yyyy")
										: ""}
								</Typography>
							</Grid>
							<Grid item xs={3} sm={3} md={2} lg={1}>
								<Typography noWrap>
									{scadenza.data_ultima_taratura_spi
										? convertToDateTime(scadenza.data_ultima_taratura_spi)?.setLocale("it").toFormat("dd/MM/yyyy")
										: ""}
								</Typography>
							</Grid>
							<Grid item xs={3} sm={3} md={2} lg={1}>
								<Typography
									noWrap
									sx={{
										backgroundColor:
											giorniPrevisione == 1900
												? "inherit"
												: inScadenza(giorniPrevisione, scadenza.scadenza_spi)
												? "yellow"
												: "inherit",
										color: scaduta(scadenza.scadenza_spi) ? "red" : "inherit",
									}}
								>
									{scadenza.scadenza_spi
										? convertToDateTime(scadenza.scadenza_spi)?.setLocale("it").toFormat("dd/MM/yyyy")
										: ""}
								</Typography>
							</Grid>
							<Grid item xs={3} sm={3} md={2} lg={1}>
								<Typography noWrap>
									{scadenza.data_ultima_taratura_contatori
										? convertToDateTime(scadenza.data_ultima_taratura_contatori)?.setLocale("it").toFormat("dd/MM/yyyy") : ""}
								</Typography>
							</Grid>
							<Grid item xs={3} sm={3} md={2} lg={1}>
								<Typography
									noWrap
									sx={{
										backgroundColor:
											giorniPrevisione == 1900
												? "inherit"
												: inScadenza(
														giorniPrevisione,
														scadenza.scadenza_contatori
												  )
												? "yellow"
												: "inherit",
										color: scaduta(scadenza.scadenza_contatori)
											? "red"
											: "inherit",
									}}
								>
									{scadenza.scadenza_contatori
										? convertToDateTime(scadenza.scadenza_contatori)?.setLocale("it").toFormat("dd/MM/yyyy")
										: ""}
								</Typography>
							</Grid>
						</Grid>
					</ListItemButton>
				))}

				<ListItem
					sx={{
						alignContent: "right",
						width: "100%",
						mt: 2,
						borderTopStyle: "solid",
						borderTopWidth: 1,
					}}
				>
					<Stack
						direction="row"
						justifyContent="space-between"
						alignItems="center"
						spacing={2}
						sx={{ width: "100%" }}
					>
						<Pagination
							count={scadenze.num_pages}
							onChange={handlerPager}
							page={page}
						/>
						<Typography noWrap>
							Totale scadenza:{"  "}
							{scadenze.count}
						</Typography>

						<TextField
							id="input-with-icon-textfield"
							// label="TextField"
							InputProps={{
								startAdornment: (
									<InputAdornment position="end">
										<SearchIcon />
									</InputAdornment>
								),
							}}
							variant="standard"
							value={search}
							onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
								setSearch(event.target.value);
							}}
						/>
						<FormControl>
							<InputLabel id="demo-simple-select-label">Record</InputLabel>
							<Select
								labelId="demo-simple-select-label"
								id="demo-simple-select"
								value={numeroRecord.toString()}
								label="Age"
								onChange={handleChangeNumeroRecord}
								variant="outlined"
							>
								<MenuItem value={10}>10</MenuItem>
								<MenuItem value={20}>20</MenuItem>
								<MenuItem value={50}>50</MenuItem>
							</Select>
						</FormControl>
					</Stack>
				</ListItem>
			</List>
		</Box>
	);
};

export default ScadenzePage;
