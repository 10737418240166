import React, { useState } from "react";
import { Utente } from "../../authentication/authenticationSlice";

import { PermissionTypes } from "../../authentication/permissionsGroups";

import { styled, useTheme } from "@mui/material/styles";
import { Link } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import { utenteAutorizzato } from "../../authentication/UtenteAutorizzato";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";

import Chip from "@mui/material/Chip";
import Tooltip from "@mui/material/Tooltip";
import { Button } from "@mui/material";

const PREFIX = "SideMenu";

const classes = {
	iconSelected: `${PREFIX}-iconSelected`,
};

const styleListItem = { justifyContent: "center" };

interface ElementoMenuControllatoProps {
	utente: Utente;
	path: string;
	label: string;
	gruppiAutorizzati: PermissionTypes[];
	open: boolean;
	selected: boolean;
	Icon: React.ElementType;
	tooggleDrawer: () => void;
	SottoMenu?: JSX.Element;
}
const ElementoMenuControllato = ({
	utente,
	path,
	label,
	open,
	gruppiAutorizzati,
	selected,
	Icon,
	tooggleDrawer,
	SottoMenu,
}: ElementoMenuControllatoProps) => {
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down("md"));
	// const matches = useMediaQuery("min-width:900px)");
	const mobiletooggleDrawer = () => {
		matches && tooggleDrawer();
	};

	const [sottoMenuAperto, setSottoMenuAperto] = useState(false);

	const handleClick = () => {
		setSottoMenuAperto(!sottoMenuAperto);
	};

	return (
		<>
			{utenteAutorizzato(utente, gruppiAutorizzati) && (
				<>
					<ListItem
						component={!SottoMenu || !open ? Link : Button}
						onClick={!!SottoMenu || !open ? handleClick : () => {}}
						to={path}
						key={path}
						sx={styleListItem}
					>
						<ListItemIcon style={{ minWidth: "auto" }}>
							<Tooltip title={label}>
								{/* <Badge badgeContent={8} color="error"> */}
								<Icon
									sx={{ color: selected ? "primary.main" : "inherit" }}
									// className={selected ? classes.iconSelected : ""}
									// onClick={mobiletooggleDrawer}
								/>
								{/* </Badge> */}
							</Tooltip>
						</ListItemIcon>

						{/* <Chip
							label="39"
							color="primary"
							sx={{ ml: 0 }}
							variant="outlined"
							size="small"
						/> */}

						{open && (
							<ListItemText
								style={{ marginLeft: 12 }}
								primary={label}
								sx={{ color: selected ? "primary.main" : "#222" }}
								// className={selected ? classes.iconSelected : ""}
								onClick={mobiletooggleDrawer}
							/>
						)}
						{!!SottoMenu &&
							open &&
							(sottoMenuAperto ? <ExpandLess /> : <ExpandMore />)}
					</ListItem>
					{!!SottoMenu && open && (
						<Collapse in={sottoMenuAperto} timeout="auto" unmountOnExit>
							{SottoMenu}
						</Collapse>
					)}
				</>
			)}
		</>
	);
};

export default ElementoMenuControllato;
