import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {useLocation, Link, useParams} from "react-router-dom";

import {RootState, useAppDispatch} from "../../../../redux/store";

import {DateTime} from "luxon";


import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import {Routes_path} from "../../../routerApp";

import {PermissionTypes} from "../../../authentication/permissionsGroups";
import {utenteAutorizzato} from "../../../authentication/UtenteAutorizzato";
import {UtenteLogged} from "../../../authentication/authenticationSlice";
import {
    Sopralluogo,
    getSopralluogo,
    saveSopralluogo,
    StatoSopralluogoTypes,
    AccessoTettoTypes,
    TipoTettoTypes,
    TipoCoperturaTypes,
    StatoCoperturaTypes,
    TipoStaffaTypes,
} from "../sopralluoghiSlice";
import {
    IterConnessione,
    getIterConnessione,
} from "../../iterConnessioni/iterConnessioneSlice";
import {Commessa, getCommessa} from "../../commesse/commesseSlice";
import {TipoPratica} from "../../../anagrafiche/tipiPratica/tipiPraticaSlice";
import SopralluogoEdificioTab from "./sopralluogoEdificio/SopralluogoEdificioTab";
import SopralluogoElettricoTab from "./sopralluogoElettrico/SopralluogoElettricoTab";
import AzioniSopralluogoCard from "./AzioniSopralluogoCard";

import {Cliente} from "../../../anagrafiche/clienti/clientiSlice";
import {Soggetto} from "../../../anagrafiche/produttori/produttoriSlice";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import AccoppiaIterSopralluogo from "./accoppiaIter/AccoppiaIterSopralluogo";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

import Typography from "@mui/material/Typography";
import {Box} from "@mui/system";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import {getProduttore} from "../../../anagrafiche/produttori/produttoriSlice";
import {Button, Grid, ButtonGroup} from "@mui/material";
import LinkOffIcon from "@mui/icons-material/LinkOff";
import {
    ErrorStatusTypes,
    ErrorsStack,
} from "../../../common/errorsDeclarations";

const SopralluogoPage = () => {

    const dispatch = useAppDispatch();
    const {sopralluogo_id} = useParams();

    const [sopralluogoId, setSopralluogoId] = useState<number>(
        parseInt(sopralluogo_id || "")
    );

    type LocationState = {
        iterConnessioneId?: number;
    };
    const location = useLocation();
    const state = location.state as LocationState;
    const iterConnessioneId = state?.iterConnessioneId;

    const utente = useSelector<RootState, UtenteLogged>(
        (state) => state.authentication.utenteLogged
    );

    const commesse = useSelector<RootState, Commessa[]>(
        (state) => state.commesseState.commesse.results
    );

    const nuovoSopralluogoId = useSelector<RootState, number | undefined>(
        (state) => state.sopralluoghiState.nuovoSopralluogoId
    );

    useEffect(() => {
        if (sopralluogoId) {
            dispatch(getSopralluogo(sopralluogoId));
        }
    }, []);

    useEffect(() => {
        nuovoSopralluogoId && setSopralluogoId(nuovoSopralluogoId);
    }, [nuovoSopralluogoId]);

    const sopralluogo: Sopralluogo | undefined = useSelector<
        RootState,
        Sopralluogo | undefined
    >((state) =>
        state.sopralluoghiState.sopralluoghi.results.find(
            (sopralluogo) => sopralluogo?.id == sopralluogoId
        )
    );

    const sopralluogoStatus = useSelector<RootState, ErrorStatusTypes>(
        (state) => state.sopralluoghiState.sopralluoghi.errorsStack.status
    );

    const nuovoSopralluogo: Sopralluogo = {
        iter_connessione: iterConnessioneId,
    };

    useEffect(() => {
        sopralluogo?.iter_connessione &&
        dispatch(
            getIterConnessione({iterConnessioneId: sopralluogo?.iter_connessione})
        );
    }, [sopralluogo?.iter_connessione]);

    const iterConnessione: IterConnessione | undefined = useSelector<
        RootState,
        IterConnessione | undefined
    >((state) =>
        state.iterConnessioniState.iterConnessioni.results.find(
            (iterConnessione) =>
                iterConnessione?.id == (sopralluogo?.iter_connessione || 0)
        )
    );

    useEffect(() => {
        sopralluogo?.commessa && dispatch(getCommessa({commessaId: sopralluogo?.commessa}));
    }, [sopralluogo?.commessa]);

    const commessa: Commessa | undefined = useSelector<
        RootState,
        Commessa | undefined
    >((state) =>
        state.commesseState.commesse.results.find(
            (commessa) => commessa?.id == (sopralluogo?.commessa || 0)
        )
    );

    const cliente: Cliente | undefined = useSelector<
        RootState,
        Cliente | undefined
    >((state) =>
        state.clientiState.clienti.results.find(
            (cliente) => cliente?.id == sopralluogo?.cliente
        )
    );

    const [currentTab, setCurrentTab] = useState("1");
    const handleTabChange = (event: any, newValue: string) => {
        setCurrentTab(newValue);
    };

    const [openRicercaIter, setOpenRicercaIter] = useState(false);

    // useEffect(() => {
    // 	sopralluogo &&
    // 		["A", "B", "C"].includes(sopralluogo.stato) &&
    // 		setCurrentTab("1");
    // 	sopralluogo &&
    // 		["D", "E"].includes(sopralluogo.stato) &&
    // 		setCurrentTab("2");
    // 	sopralluogo &&
    // 		["F"].includes(sopralluogo.stato) &&
    // 		setCurrentTab("3");
    // 	sopralluogo &&
    // 		["G", "H", "I", "L"].includes(sopralluogo.stato) &&
    // 		setCurrentTab("4");
    // 	sopralluogo &&
    // 		["M", "N"].includes(sopralluogo.stato) &&
    // 		setCurrentTab("7");
    // }, [sopralluogo?.stato]);
    return (
        <Box
            sx={{
                backgroundColor: "#fff",
                elevation: 20,
                p: 2,
            }}
        >
            <Backdrop
                sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={sopralluogoStatus == ErrorStatusTypes.PENDING}
            >
                <CircularProgress color="inherit"/>
            </Backdrop>
            {/* <Stack
				direction="row"
				justifyContent="flex-start"
				alignItems="flex-start"
				spacing={4}
				sx={{ mb: 1 }}
			> */}
            <Grid container spacing={1}>
                <Grid item xs={6} sm={6} md={6} lg={2}>
                    <Typography variant="h4" color="text.secondary" gutterBottom>
                        Sopralluogo
                        <Chip
                            color="primary"
                            variant="outlined"
                            label={sopralluogo?.id}
                            sx={{ml: 1}}
                        />
                    </Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={3}>
                    {iterConnessione ? (
                        <ButtonGroup variant="outlined">
                            <Button
                                component={Link}
                                to={Routes_path.ITER_CONNESSIONE + iterConnessione?.id}
                                key={`iterConnessione_${iterConnessione?.id}`}
                            >
                                iter connessione: {iterConnessione?.codice_pratica}
                            </Button>
                            <Button
                                color="warning"
                                startIcon={<LinkOffIcon/>}
                                onClick={() => {
                                    dispatch(
                                        saveSopralluogo({
                                            sopralluogoToSave: {
                                                ...sopralluogo,
                                                iter_connessione: null,
                                            },
                                        })
                                    );
                                }}
                            ></Button>
                        </ButtonGroup>
                    ) : (
                        <Button
                            variant="outlined"
                            color="info"
                            startIcon={<ReportGmailerrorredIcon/>}
                            onClick={() => setOpenRicercaIter(true)}
                        >
                            collega ad iter connessione
                        </Button>
                    )}

                    {sopralluogo && (
                        <AccoppiaIterSopralluogo
                            sopralluogo={sopralluogo}
                            openRicercaIter={openRicercaIter}
                            setOpenRicercaIter={setOpenRicercaIter}
                        />
                    )}
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={2}>
                    {commessa && (
                        <Button
                            component={Link}
                            variant="outlined"
                            to={Routes_path.COMMESSA + sopralluogo?.commessa}
                            key={`commessa_${sopralluogo?.commessa}`}
                        >
                            commessa:{commessa?.commessa_descrizione}
                        </Button>
                    )}
                </Grid>
                <Grid item xs={4} sm={4} md={3} lg={2}>
                    <Typography variant="h5">{cliente?.ragione_sociale}</Typography>
                </Grid>
                <Grid item xs={4} sm={4} md={3} lg={2}>
                    <Typography variant="h5">
                        {sopralluogo?.produttore_descrizione}
                    </Typography>
                </Grid>
                <Grid item xs={4} sm={4} md={4} lg={1}>
                    <Typography variant="h5">
                        {
                            Object.entries(StatoSopralluogoTypes).find(
                                ([key, value]) => key == sopralluogo?.stato
                            )?.[1]
                        }
                    </Typography>
                </Grid>
            </Grid>

            <TabContext value={currentTab}>
                <Box
                    sx={{
                        borderBottom: 1,
                        borderColor: "divider",
                    }}
                >
                    <TabList
                        onChange={handleTabChange}
                        aria-label="lab API tabs example"
                        variant="scrollable"
                        scrollButtons="auto"
                    >
                        <Tab label="EDIFICIO, COPERTURA E SOTTOCOPERTURA" value="1"/>
                        {/* <Tab label="2.Misure per posa su falda" value="2" disabled={true} /> */}
                        <Tab
                            label="Impianto elettrico"
                            value="3"
                            disabled={!sopralluogo?.id}
                        />
                        {/* <Tab label="4.Lay-out componenti" value="4" disabled={true} /> */}
                        {/* <Tab label="5.EV charger" value="5" disabled={true} /> */}

                        {/* {utenteAutorizzato(utente, [
							PermissionTypes.GESTORI_ITER,
							PermissionTypes.OPERATORI,
							PermissionTypes.PROGETTISTI,
						]) && <Tab label="6. Firma Cliente" value="6" disabled={true} />} */}
                    </TabList>
                </Box>
                <TabPanel value="1">
                    {sopralluogoStatus != ErrorStatusTypes.PENDING ? (
                        <SopralluogoEdificioTab
                            sopralluogo={sopralluogo || nuovoSopralluogo}
                            utente={utente}
                            consentiModifica={utenteAutorizzato(utente, [PermissionTypes.GESTORI_ITER, PermissionTypes.PROGETTISTI,])
                                || (utenteAutorizzato(utente, [PermissionTypes.GENERAL_CONTRACTOR, PermissionTypes.BCK_OFFICE_GENERAL_CONTRACTOR,])
                                && sopralluogo?.cliente == utente.cliente
                                && !sopralluogo?.servizio_i43
                            )}
                        />
                    ) : (
                        <CircularProgress color="inherit"/>
                    )}
                </TabPanel>
                {/* <TabPanel value="2">
					{sopralluogo && produttore ? (
						<SopralluogoDatiPraticaTab
							sopralluogo={sopralluogo}
							produttore={produttore}
							consentiModifica={utenteAutorizzato(utente, [
								PermissionTypes.GESTORI_ITER,
								PermissionTypes.OPERATORI,
							])}
						/>
					) : (
						<CircularProgress color="inherit" />
					)}
				</TabPanel> */}
                <TabPanel value="3">
                    {sopralluogo ? (
                        <SopralluogoElettricoTab
                            sopralluogo={sopralluogo}
                            utente={utente}
                            consentiModifica={utenteAutorizzato(utente, [PermissionTypes.GESTORI_ITER, PermissionTypes.PROGETTISTI,])
                                || (utenteAutorizzato(utente, [PermissionTypes.GENERAL_CONTRACTOR, PermissionTypes.BCK_OFFICE_GENERAL_CONTRACTOR,])
                                    && sopralluogo?.cliente == utente.cliente
                                    && !sopralluogo?.servizio_i43
                                )}
                        />
                    ) : (
                        <CircularProgress color="inherit"/>
                    )}
                </TabPanel>
                {/* <TabPanel value="4">
					{sopralluogo ? (
						<SopralluogoRichiesteConnessioneTab
							sopralluogo={sopralluogo}
							utente={utente}
							consentiModifica={utenteAutorizzato(utente, [
								PermissionTypes.GESTORI_ITER,
								PermissionTypes.OPERATORI,
							])}
						/>
					) : (
						<CircularProgress color="inherit" />
					)}
				</TabPanel> */}
                {/* <TabPanel value="5">
					{sopralluogo ? (
						<SopralluogoEneaTab
							sopralluogo={sopralluogo}
							utente={utente}
							consentiModifica={utenteAutorizzato(utente, [
								PermissionTypes.GESTORI_ITER,
								PermissionTypes.OPERATORI,
							])}
						/>
					) : (
						<CircularProgress color="inherit" />
					)}
				</TabPanel> */}
                {/* <TabPanel value="6">
					{sopralluogo && produttore?.tipo_persona == "PG" && (
						<SopralluogoOfficinaElettricaTab
							sopralluogo={sopralluogo}
							utente={utente}
							consentiModifica={utenteAutorizzato(utente, [
								PermissionTypes.GESTORI_ITER,
								PermissionTypes.OPERATORI,
							])}
						/>
					)}
				</TabPanel> */}
            </TabContext>
        </Box>
    );
};

export default SopralluogoPage;
