import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";

import { useLocation, Link, useNavigate } from "react-router-dom";
import { Routes_path } from "../../../../routerApp";

import { useAppDispatch } from "../../../../../redux/store";

import ConfermaAnnulla from "../../../../common/ConfermaAnnulla";
import { DateTime } from "luxon";
import { convertToDateTime } from "components/common/dateUtils/convertToDateTime";

import { Cliente } from "../../../../anagrafiche/clienti/clientiSlice";

import { Commessa, getCommessa, saveCommessa } from "../../commesseSlice";
import {
	Sopralluogo,
	SopralluoghiState,
	StatoSopralluogoTypes,
} from "components/main/sopralluoghi/sopralluoghiSlice";
import {
	VerificaVincoli,
	StatoVerificaVincoliTypes,
} from "components/main/verificheVincoli/verificheVincoliSlice";
import Grid from "@mui/material/Grid";
import { Box, Divider, Typography } from "@mui/material";
import { theme } from "../../../../theme";
import IconButton from "@mui/material/IconButton";

interface VerificheVincoliCommessaGridProps {
	commessa: Commessa;
}

const VerificheVincoliCommessaGrid = ({
	commessa,
}: VerificheVincoliCommessaGridProps) => {
	

	type LocationState = {
		statoVerificaId?: string;
		clienteId?: number;
		iterSemplificato?: string;
	};
	const location = useLocation();

	const sopralluoghi = useSelector<RootState, Sopralluogo[]>(
		(state) => state.sopralluoghiState.sopralluoghi.results
	);

	const verificheVincoli = useSelector<RootState, VerificaVincoli[]>(
		(state) => state.verificheVincoliState.verificheVincoli.results
	);

	const clienti = useSelector<RootState, Cliente[]>(
		(state) => state.clientiState.clienti.results
	);

	return (
		<>
			{verificheVincoli.length > 0 && (
				<Box>
					<Divider
						textAlign="left"
						sx={{ my: 2, color: theme.palette.primary.main }}
					>
						Verifiche Vincoli
					</Divider>

					<Grid container spacing={0.5}>
						<Grid item xs={12} sm={12} md={12} lg={2}>
							<Typography
								variant="subtitle1"
								// color="text.secondary"
								gutterBottom
								// onClick={() => setEditing(true)}
								sx={{
									color: theme.palette.text.secondary,
								}}
							>
								Verifica
							</Typography>
						</Grid>
						<Grid item xs={12} sm={12} md={12} lg={2}>
							<Typography
								variant="subtitle1"
								// color="text.secondary"
								gutterBottom
								// onClick={() => setEditing(true)}
								sx={{
									color: theme.palette.text.secondary,
								}}
							>
								Eseguita da
							</Typography>
						</Grid>
						<Grid item xs={12} sm={12} md={12} lg={2}>
							<Typography
								variant="subtitle1"
								// color="text.secondary"
								gutterBottom
								// onClick={() => setEditing(true)}
								sx={{
									color: theme.palette.text.secondary,
								}}
							>
								Iter Connessione
							</Typography>
						</Grid>
						<Grid item xs={12} sm={12} md={12} lg={3}>
							<Typography
								variant="subtitle1"
								// color="text.secondary"
								gutterBottom
								// onClick={() => setEditing(true)}
								sx={{
									color: theme.palette.text.secondary,
								}}
							>
								Stato
							</Typography>
						</Grid>
						<Grid item xs={12} sm={12} md={12} lg={3}>
							<Typography
								variant="subtitle1"
								// color="text.secondary"
								gutterBottom
								// onClick={() => setEditing(true)}
								sx={{
									color: theme.palette.text.secondary,
								}}
							>
								Data Verifica
							</Typography>
						</Grid>
					</Grid>
					{verificheVincoli
						.filter((verifica) => verifica.commessa == commessa.id)
						.map((verifica: VerificaVincoli, index) => (
							<Grid
								container
								spacing={0.5}
								key={index}
								sx={{
									backgroundColor: index % 2 === 0 ? "#eeeeee" : "inherit",
								}}
							>
								<Grid item xs={12} sm={12} md={12} lg={2}>
									<IconButton
										key={verifica.id}
										aria-label="vai a verifica"
										size="small"
										component={Link}
										to={{
											pathname: Routes_path.VERIFICA_VINCOLI + verifica.id,
										}}
										// state={{
										// 	lavorazine_id: params.value,
										// }}
									>
										<Typography
											variant="body1"

											// color="text.secondary"
											// onClick={() => setEditing(true)}
										>
											{verifica.id}
										</Typography>
									</IconButton>
								</Grid>
								<Grid item xs={12} sm={12} md={12} lg={2}>
									<Typography
										variant="body1"
										// sx={{ fontSize: "0.8rem" }}
										// color="text.secondary"
										// onClick={() => setEditing(true)}
									>
										{verifica.servizio_i43
											? "I43"
											: clienti.find(
													(cliente) => cliente.id == verifica.cliente
											  )?.ragione_sociale}
									</Typography>
								</Grid>
								<Grid item xs={12} sm={12} md={12} lg={2}>
									<Typography
										variant="body1"
										// sx={{ fontSize: "0.8rem" }}
										// color="text.secondary"
										// onClick={() => setEditing(true)}
									>
										{verifica.iter_connessione || ""}
									</Typography>
								</Grid>
								<Grid item xs={12} sm={12} md={12} lg={3}>
									<Typography
										variant="body1"
										// color="text.secondary"
										// onClick={() => setEditing(true)}
									>
										{
											Object.entries(StatoVerificaVincoliTypes).find(
												([key, value]) => key == verifica.stato_verifica
											)?.[1]
										}
									</Typography>
								</Grid>
								<Grid item xs={12} sm={12} md={12} lg={3}>
									<Typography
										variant="body1"
										// color="text.secondary"
										// onClick={() => setEditing(true)}
									>
										{verifica.data_verifica
											? convertToDateTime(verifica.data_verifica)?.setLocale("it").toFormat("dd/MM/yyyy")
											: ""}
									</Typography>
								</Grid>
							</Grid>
						))}
				</Box>
			)}
		</>
	);
};
export default VerificheVincoliCommessaGrid;
