import { handleResponse, handleError, headerset } from "../apiUtils";
import { TipoPratica } from "../../components/anagrafiche/tipiPratica/tipiPraticaSlice";

const baseUrl = (tipo_pratica_id?: number | null) => {
	if (tipo_pratica_id) {
		return (
			process.env.API_URL +
			"/api/anagrafiche/tipi_pratica/" +
			tipo_pratica_id +
			"/"
		);
	} else {
		return process.env.API_URL + "/api/anagrafiche/tipi_pratica/";
	}
};

export function fetchTipiPratica() {
	let url = baseUrl();

	return fetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}

export function getTipoPratica(tipoPraticaId: number) {
	let url = baseUrl(tipoPraticaId);
	return fetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}

export function saveTipoPratica(tipoPraticaToSave: TipoPratica) {
	const tipoPratica = {
		...tipoPraticaToSave,
	};

	return fetch(baseUrl(tipoPraticaToSave.id), {
		method: tipoPraticaToSave.id ? "PUT" : "POST", // PUT to update when id already exists.
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
		body: JSON.stringify({
			...tipoPratica,
			// Parse authorId to a number (in case it was sent as a string).
			// id: parseInt(progetto.id, 10),
		}),
	})
		.then(handleResponse)
		.catch(handleError);
}

export function deleteTipoPratica(tipoPraticaToDelete: TipoPratica) {
	return fetch(baseUrl(tipoPraticaToDelete.id), {
		method: "DELETE",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}
