import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import {
	ErrorStatusTypes,
	ErrorsStack,
	parseErrorMessage,
} from "../../common/errorsDeclarations";
import * as adempimentiApi from "../../../api/adempimenti/adempimentiApi";
import * as serviziAdempimentiApi from "../../../api/adempimenti/serviziAdempimentiApi";

import { toast } from "react-toastify";
// import { useDispatch } from "react-redux";
import { AllegatoAdempimenti } from "./allegatiAdempimenti/allegatiAdempimentiSlice";
import { getProduttore } from "../../anagrafiche/produttori/produttoriSlice";
import { Navigate } from "react-router-dom";
import RouterApp, { Routes_path } from "components/routerApp";

import { useNavigate } from "react-router-dom";



export interface TipoAdempimento {
	id?: number;
	nome?: string;
	descrizione?: string;
}

export interface TipiAdempimento {
	count: number;
	page: number;
	num_pages: number;
	next?: URL;
	previous?: URL;
	results: TipoAdempimento[];
	errorsStack: ErrorsStack;
}

export enum StatoAdempimentoTypes {
	P = "Programmato",
	A = "Assegnato",
	C = "Completato",
}

export interface PodAdempimento {
	id: number;
	codice: string;
}
export interface ServizioAdempimenti {
	id?: number;
	produttore?: number;
	produttore_descrizione?: string;
	cliente?: number;
	cliente_descrizione?: string;
	data_validita_da?: Date | string;
	data_validita_a?: Date | string;
	in_corso?: boolean;
	descrizione?: string;
	errorsStack?: ErrorsStack;
}

export interface ServiziAdempimentiState {
	count: number;
	page: number;
	num_pages: number;
	next?: URL;
	previous?: URL;
	results: ServizioAdempimenti[];
	errorsStack: ErrorsStack;
}

export interface Adempimento {
	id?: number;
	stato: "P" | "A" | "C";
	produttore?: number;
	produttore_descrizione?: string;
	cliente?: number;
	cliente_descrizione?: string;
	pods?: number[];
	pods_pack?: PodAdempimento[];
	operatore?: number;
	data_scadenza?: Date | string;
	data_pianificata?: Date | string;
	data_completamento?: Date | string;
	tipo_adempimento?: number;
	allegati_adempimento?: AllegatoAdempimenti[];
	note?: string;
	errorsStack?: ErrorsStack;
}

export interface AdempimentiState {
	count: number;
	page: number;
	num_pages: number;
	next?: URL;
	previous?: URL;
	results: Adempimento[];
	errorsStack: ErrorsStack;
}

export interface AdempimentiStrutturaState {
	tipiAdempimento: TipiAdempimento;
	adempimenti: AdempimentiState;
	serviziAdempimenti: ServiziAdempimentiState;
	nuovoAdempimentoId?: number;
	nuovoServizioAdempimentiId?: number;
}

const initialState: AdempimentiStrutturaState = {
	tipiAdempimento: {
		count: 0,
		page: 0,
		num_pages: 0,
		next: undefined,
		previous: undefined,
		results: [],
		errorsStack: { status: ErrorStatusTypes.OK },
	},
	adempimenti: {
		count: 0,
		page: 0,
		num_pages: 0,
		next: undefined,
		previous: undefined,
		results: [],
		errorsStack: { status: ErrorStatusTypes.OK },
	},
	serviziAdempimenti: {
		count: 0,
		page: 0,
		num_pages: 0,
		next: undefined,
		previous: undefined,
		results: [],
		errorsStack: { status: ErrorStatusTypes.OK },
	},
};

export const fetchTipiAdempimento = createAsyncThunk(
	"adempimenti/fetchTipiAdempimento",
	async () => {
		return await adempimentiApi.fetchTipiAdempimento();
	}
);

export const fetchAdempimenti = createAsyncThunk(
	"adempimenti/fetchAdempimenti",
	async (parametri: {
		stato_adempimento?: string;
		cliente?: number;
		produttore?: number;
		pod?: number;
		tipo_adempimento?: number;
		numeroRecord?: number;
		page?: number;
		search?: string;
		data_da?: Date;
		data_a?: Date;
	}) => {
		return await adempimentiApi.fetchAdempimenti(
			parametri.stato_adempimento,
			parametri.cliente,
			parametri.produttore,
			parametri.pod,
			parametri.tipo_adempimento,
			parametri.numeroRecord,
			parametri.page,
			parametri.search,
			parametri.data_da,
			parametri.data_a
		);
	}
);

export const getAdempimento = createAsyncThunk(
	"adempimenti/getAdempimento",
	async (adempimentoId: number) => {
		return await adempimentiApi.getAdempimento(adempimentoId);
	}
);

export const saveAdempimento = createAsyncThunk(
	"adempimenti/saveAdempimento",
	async (
		parametri: {
			adempimentoToSave: Adempimento;
			azione?: string;
		},
		thunkApi
	) => {
		return await adempimentiApi
			.saveAdempimento(parametri.adempimentoToSave, parametri.azione)
			.then((response) => {
				parametri.adempimentoToSave.produttore &&
					thunkApi.dispatch(
						getProduttore(parametri.adempimentoToSave.produttore)
					);
				return response;
			});
	}
);

export const deleteAdempimento = createAsyncThunk(
	"adempimenti/deleteAdempimento",
	async (adempimentoToDelete: Adempimento, thunkApi) => {
		return await adempimentiApi
			.deleteAdempimento(adempimentoToDelete)
			.then((response) => {
				return response;
			});
	}
);

////////////////////////////////////////////////////////////////////////////////////
export const fetchServiziAdempimenti = createAsyncThunk(
	"adempimenti/fetchServiziAdempimenti",
	async (parametri: {
		cliente?: number;
		produttore?: number;
		numeroRecord?: number;
		page?: number;
		search?: string;
	}) => {
		return await serviziAdempimentiApi.fetchServiziAdempimenti(
			parametri.cliente,
			parametri.produttore,
			parametri.numeroRecord,
			parametri.page,
			parametri.search
		);
	}
);

export const getServizioAdempimenti = createAsyncThunk(
	"adempimenti/getServizioAdempimenti",
	async (servizioAdempimentiId: number) => {
		return await serviziAdempimentiApi.getServizioAdempimenti(
			servizioAdempimentiId
		);
	}
);

export const saveServizioAdempimenti = createAsyncThunk(
	"adempimenti/saveServizioAdempimenti",
	async (
		parametri: {
			servizioAdempimentiToSave: ServizioAdempimenti;
			azione?: string;
		},
		thunkApi
	) => {
		return await serviziAdempimentiApi
			.saveServizioAdempimenti(
				parametri.servizioAdempimentiToSave,
				parametri.azione
			)
			.then((response) => {
				parametri.servizioAdempimentiToSave.produttore &&
					thunkApi.dispatch(
						getProduttore(parametri.servizioAdempimentiToSave.produttore)
					);
				return response;
			});
	}
);

export const deleteServizioAdempimenti = createAsyncThunk(
	"adempimenti/deleteServizioAdempimenti",
	async (servizioAdempimentiToDelete: ServizioAdempimenti, thunkApi) => {
		return await serviziAdempimentiApi.deleteServizioAdempimenti(
			servizioAdempimentiToDelete
		);
	}
);

////////////////////////////////////////////////////////////////////////////////////

export const adempimentiSlice = createSlice({
	name: "adempimentiState",
	initialState,
	reducers: {
		resetNuovoAdempimentoId: (state) => {
			state.nuovoAdempimentoId = undefined;
		},
		resetNuovoServizioAdempimentiId: (state) => {
			state.nuovoServizioAdempimentiId = undefined;
		},
	},
	extraReducers: (builder) => {
		// fetch Tipi Adempimento
		builder.addCase(fetchTipiAdempimento.pending, (state, action) => {
			state.tipiAdempimento.errorsStack = {
				status: ErrorStatusTypes.PENDING,
			};
		});
		builder.addCase(fetchTipiAdempimento.fulfilled, (state, action) => {
			state.tipiAdempimento = action.payload;
			state.tipiAdempimento.errorsStack = { status: ErrorStatusTypes.OK };
		});
		builder.addCase(fetchTipiAdempimento.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");
			state.tipiAdempimento.errorsStack = parseErrorMessage(action.error);
		});

		// fetch Adempimenti
		builder.addCase(fetchAdempimenti.pending, (state, action) => {
			state.adempimenti.errorsStack = {
				status: ErrorStatusTypes.PENDING,
			};
		});
		builder.addCase(fetchAdempimenti.fulfilled, (state, action) => {
			state.adempimenti = action.payload;
			state.adempimenti.errorsStack = { status: ErrorStatusTypes.OK };
		});
		builder.addCase(fetchAdempimenti.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");
			state.adempimenti.errorsStack = parseErrorMessage(action.error);
		});

		// get Adempimento
		builder.addCase(getAdempimento.pending, (state, action) => {
			state.adempimenti.errorsStack = {
				status: ErrorStatusTypes.PENDING,
			};
		});
		builder.addCase(getAdempimento.fulfilled, (state, action) => {
			state.adempimenti.results = state.adempimenti.results.filter(
				(adempimento) => adempimento.id != action.payload.id
			);
			state.adempimenti.results.push(action.payload);

			state.adempimenti.errorsStack = { status: ErrorStatusTypes.OK };
		});
		builder.addCase(getAdempimento.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");
			state.adempimenti.errorsStack = parseErrorMessage(action.error);
		});

		// save Adempimento
		builder.addCase(saveAdempimento.pending, (state, action) => {
			state.adempimenti.errorsStack = {
				status: ErrorStatusTypes.PENDING,
			};
		});
		builder.addCase(saveAdempimento.fulfilled, (state, action) => {
			state.adempimenti.results = state.adempimenti.results.filter(
				(adempimento) => adempimento.id != action.payload.id
			);
			state.adempimenti.results.push({
				...action.payload,
				errorsStack: { status: ErrorStatusTypes.SUCCESS },
			});
			state.adempimenti.errorsStack = {
				status: ErrorStatusTypes.SUCCESS,
			};

			// Solo se è nuova creazione ********************
			if (!action.meta.arg.adempimentoToSave.id) {
				state.nuovoAdempimentoId = action.payload.id;
			}
			toast.success("Adempimento salvato.");
		});
		builder.addCase(saveAdempimento.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");

			state.adempimenti.results = state.adempimenti.results.map(
				(adempimento) => {
					if (adempimento.id == action.meta.arg.adempimentoToSave.id) {
						return {
							...adempimento,
							errorsStack: parseErrorMessage(action.error),
						};
					} else {
						return adempimento;
					}
				}
			);
			state.adempimenti.errorsStack = parseErrorMessage(action.error);
		});
		// cancella Adempimento
		builder.addCase(deleteAdempimento.pending, (state, action) => {
			state.adempimenti.errorsStack = {
				status: ErrorStatusTypes.PENDING,
			};
		});
		builder.addCase(deleteAdempimento.fulfilled, (state, action) => {
			state.adempimenti.errorsStack = {
				status: ErrorStatusTypes.SUCCESS,
			};
			state.adempimenti.results = state.adempimenti.results.filter(
				(adempimento) => adempimento.id != action.meta.arg.id
			);
			toast.success(action.payload.message || "Adempimento cancellato.");
		});
		builder.addCase(deleteAdempimento.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");
			state.adempimenti.errorsStack = parseErrorMessage(action.error);
		});

		// fetch Servizi Adempimenti
		builder.addCase(fetchServiziAdempimenti.pending, (state, action) => {
			state.serviziAdempimenti.errorsStack = {
				status: ErrorStatusTypes.PENDING,
			};
		});
		builder.addCase(fetchServiziAdempimenti.fulfilled, (state, action) => {
			state.serviziAdempimenti = action.payload;
			state.serviziAdempimenti.errorsStack = { status: ErrorStatusTypes.OK };
		});
		builder.addCase(fetchServiziAdempimenti.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");
			state.serviziAdempimenti.errorsStack = parseErrorMessage(action.error);
		});

		// get Servizio Adempimenti
		builder.addCase(getServizioAdempimenti.pending, (state, action) => {
			state.serviziAdempimenti.errorsStack = {
				status: ErrorStatusTypes.PENDING,
			};
		});
		builder.addCase(getServizioAdempimenti.fulfilled, (state, action) => {
			state.serviziAdempimenti.results =
				state.serviziAdempimenti.results.filter(
					(servizioAdempimenti) => servizioAdempimenti.id != action.payload.id
				);
			state.serviziAdempimenti.results.push(action.payload);

			state.serviziAdempimenti.errorsStack = { status: ErrorStatusTypes.OK };
		});
		builder.addCase(getServizioAdempimenti.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");
			state.serviziAdempimenti.errorsStack = parseErrorMessage(action.error);
		});

		// save Servizio Adempimenti
		builder.addCase(saveServizioAdempimenti.pending, (state, action) => {
			state.serviziAdempimenti.errorsStack = {
				status: ErrorStatusTypes.PENDING,
			};
		});
		builder.addCase(saveServizioAdempimenti.fulfilled, (state, action) => {
			state.serviziAdempimenti.results =
				state.serviziAdempimenti.results.filter(
					(servizioAdempimenti) => servizioAdempimenti.id != action.payload.id
				);
			state.serviziAdempimenti.results.push({
				...action.payload,
				errorsStack: { status: ErrorStatusTypes.SUCCESS },
			});
			state.serviziAdempimenti.errorsStack = {
				status: ErrorStatusTypes.SUCCESS,
			};

			// Solo se è nuova creazione ********************
			if (!action.meta.arg.servizioAdempimentiToSave.id) {
				state.nuovoServizioAdempimentiId = action.payload.id;
				toast.success("Servizio Adempimenti attivato.");
			} else {
				toast.success("Servizio Adempimenti salvato.");
			}
		});
		builder.addCase(saveServizioAdempimenti.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");

			state.serviziAdempimenti.results = state.serviziAdempimenti.results.map(
				(servizioAdempimenti) => {
					if (
						servizioAdempimenti.id ==
						action.meta.arg.servizioAdempimentiToSave.id
					) {
						return {
							...servizioAdempimenti,
							errorsStack: parseErrorMessage(action.error),
						};
					} else {
						return servizioAdempimenti;
					}
				}
			);
			state.serviziAdempimenti.errorsStack = parseErrorMessage(action.error);
		});

		// cancella ServizioAdempimenti
		builder.addCase(deleteServizioAdempimenti.pending, (state, action) => {
			state.serviziAdempimenti.errorsStack = {
				status: ErrorStatusTypes.PENDING,
			};
		});
		builder.addCase(deleteServizioAdempimenti.fulfilled, (state, action) => {
			state.serviziAdempimenti.errorsStack = {
				status: ErrorStatusTypes.SUCCESS,
			};
			state.serviziAdempimenti.results =
				state.serviziAdempimenti.results.filter(
					(servizioAadempimenti) =>
						servizioAadempimenti.id != action.meta.arg.id
				);
			toast.success(
				action.payload.message || "Servizio Adempimenti cancellato."
			);
		});
		builder.addCase(deleteServizioAdempimenti.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");
			state.serviziAdempimenti.errorsStack = parseErrorMessage(action.error);
		});
	},
});
export const { resetNuovoAdempimentoId, resetNuovoServizioAdempimentiId } =
	adempimentiSlice.actions;

export const adempimentiSliceReducer = adempimentiSlice.reducer;
