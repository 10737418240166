import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { Installatore, saveInstallatore } from "./installatoriSlice";
import { useAppDispatch, RootState } from "../../../redux/store";
import { useNavigate } from "react-router-dom";

import { useForm, SubmitHandler, Controller } from "react-hook-form";
import FormControlLabel from "@mui/material/FormControlLabel";
import ReactHookFormSelect from "../../common/ReactHookFormSelect";
import { Comune } from "../comuni/comuniSlice";
import {
	IterConnessione,
	saveIterConnessione,
} from "../../main/iterConnessioni/iterConnessioneSlice";
import MenuItem from "@mui/material/MenuItem";

import { Routes_path } from "../../routerApp";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import Stack from "@mui/material/Stack";

import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

import Alert from "../../common/Alert";
import Typography from "@mui/material/Typography";

import { Button, Divider } from "@mui/material";
import { theme } from "../../theme";

interface InstallatoreFormProps {
	installatore: Installatore;
}

const InstallatoreForm = ({ installatore }: InstallatoreFormProps) => {
	const {
		handleSubmit,
		register,
		formState: { errors, isDirty, isValid },
		setValue,
		watch,
		getValues,
		control,
		setError,
		clearErrors,
		reset,
	} = useForm<Installatore>();

	const listaCampi = Object.keys(installatore) as Array<keyof Installatore>;

	const dispatch = useAppDispatch();

	const navigate = useNavigate();

	useEffect(() => {
		reset();
		listaCampi.map((field) => {
			setValue(field, installatore[field]);
		});
	}, []);

	useEffect(() => {
		clearErrors();
		listaCampi.forEach((field) => {
			installatore?.errorsStack?.fieldsErrors &&
				installatore.errorsStack.fieldsErrors[field] &&
				setError(field, {
					type: "server",
					message: installatore.errorsStack.fieldsErrors[field].toString(),
				});
		});
	}, [
		installatore?.errorsStack?.fieldsErrors,
		// produttore.allevamento_pack?.errorsStack?.fieldsErrors,
	]);

	const handlerSaveInstallatore = (installatore: Installatore) => {
		dispatch(saveInstallatore(installatore));
	};

	const onSubmit: SubmitHandler<Installatore> = (installatore) => {
		handlerSaveInstallatore(installatore);
	};

	return (
		<Box sx={{ my: 1, pt: 2 }}>
			<form onSubmit={handleSubmit(onSubmit)}>
				{installatore?.errorsStack?.fieldsErrors?.non_field_errors && (
					<Alert severity="error">
						{installatore?.errorsStack?.fieldsErrors?.non_field_errors.toString()}
					</Alert>
				)}
				<Grid
					container
					// rowSpacing={1}
					columnSpacing={1}
					// justifyContent="space-between"
					// alignItems="flex-start"
				>
					<Grid
						item
						xs={12}
						md={4}
						sx={{
							// pt: "16px !important",
							mb: 1,
						}}
					>
						<TextField
							id="ragione_sociale"
							label="Ragione Sociale"
							// disabled={getValues("confermato")}
							{...register("ragione_sociale")}
							InputLabelProps={{
								shrink: true,
							}}
							variant="outlined"
							required={true}
							fullWidth
							type="string"
							autoComplete={"off"}
							error={!!errors?.ragione_sociale}
							helperText={errors?.ragione_sociale?.message}
						/>
					</Grid>
					<Grid
						item
						xs={12}
						md={4}
						sx={{
							// pt: "16px !important",
							mb: 1,
						}}
					>
						<TextField
							id="nome"
							label="Nome Referente"
							// disabled={getValues("confermato")}
							{...register("nome")}
							InputLabelProps={{
								shrink: true,
							}}
							variant="outlined"
							// required={true}
							fullWidth
							type="string"
							autoComplete={"off"}
							error={!!errors?.nome}
							helperText={errors?.nome?.message}
						/>
					</Grid>
					<Grid
						item
						xs={12}
						md={4}
						sx={{
							// pt: "16px !important",
							mb: 1,
						}}
					>
						<TextField
							id="cognome"
							label="Cognome Referente"
							// disabled={getValues("confermato")}
							{...register("cognome")}
							InputLabelProps={{
								shrink: true,
							}}
							variant="outlined"
							fullWidth
							type="string"
							autoComplete={"off"}
							error={!!errors?.cognome}
							helperText={errors?.cognome?.message}
						/>
					</Grid>
					<Grid
						item
						xs={12}
						md={4}
						sx={{
							// pt: "16px !important",
							mb: 1,
						}}
					>
						<TextField
							id="cellulare"
							label="Cellulare"
							// disabled={getValues("confermato")}
							{...register("cellulare")}
							InputLabelProps={{
								shrink: true,
							}}
							variant="outlined"
							fullWidth
							type="number"
							autoComplete={"off"}
							error={!!errors?.cellulare}
							helperText={errors?.cellulare?.message}
						/>
					</Grid>
					<Grid
						item
						xs={12}
						md={4}
						sx={{
							// pt: "16px !important",
							mb: 1,
						}}
					>
						<TextField
							id="telefono_fisso"
							label="Telefono fisso"
							// disabled={getValues("confermato")}
							{...register("telefono_fisso")}
							InputLabelProps={{
								shrink: true,
							}}
							variant="outlined"
							fullWidth
							type="number"
							autoComplete={"off"}
							error={!!errors?.telefono_fisso}
							helperText={errors?.telefono_fisso?.message}
						/>
					</Grid>
					<Grid
						item
						xs={12}
						md={4}
						sx={{
							// pt: "16px !important",
							mb: 1,
						}}
					>
						<TextField
							id="email"
							label="Email"
							// disabled={getValues("confermato")}
							{...register("email")}
							InputLabelProps={{
								shrink: true,
							}}
							variant="outlined"
							fullWidth
							type="string"
							autoComplete={"off"}
							error={!!errors?.email}
							helperText={errors?.email?.message}
						/>
					</Grid>
				</Grid>
				<Stack direction="row" spacing={2}>
					<Button
						variant="outlined"
						component={Link}
						to={{
							pathname: Routes_path.ANAGRAFICHE,
						}}
						// endIcon={<KeyboardReturnIcon />}
					>
						Annulla
					</Button>
					<Button
						disabled={!isDirty}
						variant="outlined"
						type="submit"
						// sx={{ display: "none" }}
					>
						Salva
					</Button>
				</Stack>
				{/* </Grid> */}
			</form>
		</Box>
	);
};

export default InstallatoreForm;
