import React, { useState } from "react";

import { RootState, useAppDispatch } from "../../../../../redux/store";

import ConfermaAnnulla from "../../../../common/ConfermaAnnulla";
import AccontoForm from "./AccontoForm";

import { Commessa, saveCommessa } from "../../commesseSlice";
import { Soggetto } from "../../../../anagrafiche/produttori/produttoriSlice";
import Alert from "../../../../common/Alert";

import SoggettoPage from "../../../../anagrafiche/soggetti/SoggettoPage";
import Button from "@mui/material/Button";
import { Box } from "@mui/system";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import AllegatiPage from "../../../../allegati/AllegatiPage";

interface AccontoTabProps {
	commessa: Commessa;
	consentiModifica: boolean;
}

const AccontoTab = ({ commessa, consentiModifica }: AccontoTabProps) => {
	const dispatch = useAppDispatch();

	// gestione chiusura modal su scelta "Annulla"
	const [openConferma, setOpenConferma] = useState(false);
	const handleConfermaClose = () => setOpenConferma(false);

	return (
		<Box
			sx={{
				backgroundColor: "#fff",
				elevation: 20,
				p: 2,
			}}
		>
			{!!commessa?.id && (
				<Grid container spacing={2} sx={{ mt: 2 }}>
					<Grid item lg={3}></Grid>
					<Grid item lg={6}>
						<Card elevation={3} sx={{ pb: 2, mb: 2 }}>
							<AccontoForm
								commessa={commessa}
								consentiModifica={consentiModifica}
							/>
						</Card>
					</Grid>
					<Grid item lg={3}></Grid>
				</Grid>
			)}

			{/* {!!commessa?.pod && (
				<Card sx={{ mt: 2 }}>
					<CardHeader
						title={
							<Typography variant="h5" color="white">
								Allegati
							</Typography>
						}
						sx={{ backgroundColor: "#249949" }}
					/>
					<CardContent>
						<AllegatiPage
							listaDocumentiPerTipo={commessa.pod?.listaDocumentiPerTipo || []}
							commessaId={commessa.id || 0}
							consentiModifica={consentiModifica}
						/>
					</CardContent>
				</Card>
			)} */}
		</Box>
	);
};

export default AccontoTab;
